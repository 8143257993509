import moment from 'moment'

export default {
  currency: input => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(Number(input))
  },
  date: date => {
    const formatosFecha = [
      'DD/MM/YYYY',
      'YYYY-MM-DD',
      'DD-MM-YYYY',
      'YYYY-MM-DD HH:mm:ss.SSS',
    ]
    return moment(date, formatosFecha, true).format('YYYY-MM-DD')
    // const fechaFormateada = fechaMoment.format('DD/MM/YYYY HH:mm');
    // return fechaFormateada;
  },
  phone: phone => {
    const digitsOnly = phone.replace(/\D/g, '')

    // Check if the input string has at least 8 digits
    if (digitsOnly.length >= 8) {
      // Split the digits into two parts (first four and last four)
      const firstPart = digitsOnly.slice(0, 4)
      const secondPart = digitsOnly.slice(4, 8)

      // Combine the parts with a hyphen separator
      const formattedString = `${firstPart}-${secondPart}`

      return formattedString
    } else {
      // If the input string doesn't have enough digits, return the original string
      return phone
    }
  },
  formatNumberThousands: (number, locale = 'en-US') => {
    // Verificar si el número es mayor a 1000
    return number?.toLocaleString(locale)
  },
}

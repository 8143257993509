import React from 'react'

function CrediCardGreen({color}) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6665 3.33325H3.33317C2.40817 3.33325 1.67484 4.07492 1.67484 4.99992L1.6665 14.9999C1.6665 15.9249 2.40817 16.6666 3.33317 16.6666H16.6665C17.5915 16.6666 18.3332 15.9249 18.3332 14.9999V4.99992C18.3332 4.07492 17.5915 3.33325 16.6665 3.33325ZM15.8332 14.9999H4.1665C3.70817 14.9999 3.33317 14.6249 3.33317 14.1666V9.99992H16.6665V14.1666C16.6665 14.6249 16.2915 14.9999 15.8332 14.9999ZM16.6665 6.66659H3.33317V4.99992H16.6665V6.66659Z"
        fill="#026E18"
      />
    </svg>
  )
}

export default CrediCardGreen

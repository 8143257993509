import produce from 'immer';

const initialState = {
  movement: {},
  movements: [],
  Loadingmovement: false,
  movementError: false,
  financia: {},
  financias: [],
  LoadingFinancia: false,
  FinanciaError: false,
};

const MovimientoReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'MOVEMENT_STATUS_SUCCESS':
        draft.movements = action.payload;
        break;
      case 'MOVEMENT_STATUS_ERROR':
        draft.movementError = action.payload;
        break;
      case 'MOVEMENT_STATUS_SHOW':
        draft.Loadingmovement = true;
        break;
      case 'MOVEMENT_STATUS_HIDE':
        draft.Loadingmovement = false;
        break;
      case 'MOVEMENT_STATUS_CLEAN':
        draft.movements = [];
        break;
      case 'EXTRAF_STATUS_SUCCESS':
        draft.financias = action.payload;
        break;
      case 'EXTRAF_STATUS_ERROR':
        draft.FinanciaError = action.payload;
        break;
      case 'EXTRAF_STATUS_SHOW':
        draft.LoadingFinancia = true;
        break;
      case 'EXTRAF_STATUS_HIDE':
        draft.LoadingFinancia = false;
        break;
      case 'EXTRAF_STATUS_CLEAN':
        draft.financias = [];
        break;
      default:
        return state;
    }
  });

export default MovimientoReducer;

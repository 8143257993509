import styled from '@emotion/styled'
import React from 'react'
import AsideCharacter from '../../components/Aside/AsideCharacter.js'
import ButtonNext from '../../components/Button/ButtonNext.js'
import Text from '../../components/Typography/Text.js'
/**
 * Pantalla cuando un usuario es rechazado por el motor de decicion
 * @param {*} props
 * @returns
 */
const FinancialError = () => {
  return (
    // <Layout>
    //   <BannerComponent />
    //   <Layout>
    //     <HeaderComponent />
    //     <DivFinancial>
    //       <LogoFinancialError src={CentralLogo} />
    //     </DivFinancial>
    //     <DivContainerDescription>
    //       <H1FinancialError>
    //         Hemos revisado los datos y por el momento no podemos continuar con
    //         la solicitud.
    //       </H1FinancialError>
    //       <H1FinancialErrorMessage>
    //         ¡Esperamos poder atenderte en el futuro!
    //       </H1FinancialErrorMessage>
    //     </DivContainerDescription>
    //     <DivFooterFinancial>
    //       <ButtonFinancial>
    //         <a
    //           href="https://www.cmfinanciera.com/s/formas-de-pago"
    //           target="_blank">
    //           CONOCE MÁS DE CMF
    //         </a>
    //       </ButtonFinancial>
    //     </DivFooterFinancial>
    //   </Layout>
    // </Layout>

    <Container>
      <Wrapper style={{flex: 1, height: '100%'}}>
        <DivContainer>
          <Text textType="title" style={{fontSize: '3.2rem'}}>
            ¡Gracias por tu preferencia!
          </Text>
          <Text textType="subtitle" style={{fontSize: '2.5rem'}}>
            Hemos revisado tus datos y por el momento
          </Text>
          <Text textType="subtitle" style={{fontSize: '2.5rem', marginTop: -8}}>
            no podemos continuar con la solicitud
          </Text>
          <Text style={{marginTop: 50}} textType="paragraphs">
            ¡Esperamos poder atenderte en el futuro!
          </Text>
          <ButtonNext
            background="green"
            border="green"
            href="https://www.cmfinanciera.com/s/formas-de-pago"
            style={{marginTop: 80, zIndex: 100}}>
            Salir
          </ButtonNext>
        </DivContainer>
      </Wrapper>

      <Wrapper
        style={{width: '100%', height: 300, bottom: 0, position: 'absolute'}}>
        <AsideCharacter type="stand" color="white" positionY="bottom" />
      </Wrapper>
    </Container>
  )
}

const DivContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: '15%',
}))

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const Container = styled('div')(() => ({
  display: 'flex',
  height: '100vh',
}))

export default FinancialError

import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import SupportScreen from './Component/SupportScreen';

// Styled components
const Heading = styled.span`
  @media (min-width: 412px) {
    font-size: 40px;
  }
  @media (min-width: 1024px) {
    font-size: 60px;
  }
`;

const Title = styled.span`
  @media (min-width: 412px) {
    font-size: 25px;
    line-height: '2rem';
  }
  @media (min-width: 1024px) {
    font-size: 40px;
    line-height: '4rem';
  }
`;

const Subtitle = styled.div`
  line-height: '4rem' @media (min-width: 412px) {
    font-size: 20px;
  }
  @media (min-width: 1024px) {
    font-size: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }
`;
/**
 * React component for the Request In Revision screen.
 * This component displays a support screen with a heading, title, subtitle, and a button.
 * It also includes a function to handle the "Salir" button click event.
 */
const RequestInRevision = () => {
  const {maxDay, onboardingPrepago} = useSelector(
    state => state.RequestCardReducer,
  );

  /**
   * Function to handle the "Salir" button click event.
   * It creates a new link element, sets its href and target attributes,
   * appends a text node as its child, and triggers a click event on the link.
   */
  const onConfirm = () => {
    const URL_CMF_PAYMENT_METHOD =
      'https://www.cmfinanciera.com/s/formas-de-pago';
    const link = document.createElement('a');
    link.href = URL_CMF_PAYMENT_METHOD;
    link.target = '_blank';
    const linkText = document.createTextNode('Salir');
    link.appendChild(linkText);
    link.click();
  };
  const textTDC = 'Tu solicitud de crédito será revisada para aprobación';
  const textPrepaid = 'Tu solicitud de prepago será revisada para aprobación';

  return (
    <SupportScreen
      showDiv={true}
      buttonText="Salir"
      heading={<Heading>¡Gracias por tu preferencia!</Heading>}
      title={<Title>{onboardingPrepago ? textPrepaid : textTDC}</Title>}
      subtitle1={
        <Subtitle>
          <span>
            Basados en la información que nos has proporcionado, revisaremos tu
            solicitud y{' '}
          </span>
          <span>
            te estaremos contactando en las siguientes {maxDay} horas hábiles.
          </span>
        </Subtitle>
      }
      onConfirm={onConfirm}
    />
  );
};

export default RequestInRevision;

import Dialog from '@mui/material/Dialog'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Maintenance from 'src/containers/OnboardingCredito/Maintenance'
import './banner_style.css'
import usePrevious from './usePrevious'

export default function MaintenanceModal({origin}) {
  const {banner} = useSelector(state => state.RequestCardReducer)
  const [currentDate, setcurrentDate] = useState(null)
  const [validDate, setValidDate] = useState(null)
  const [activate, setActivate] = useState(false)
  const prevvalidDate = usePrevious(validDate)
  let filteredBanner = []
  useEffect(() => {
    const date = new Date().toLocaleString('sv-SE', {
      timeZone: 'America/Panama',
    })
    setValidDate(moment(date).format('YYYY/MM/DD HH:mm:ss'))
  }, [])
  useEffect(() => {
    if (validDate != prevvalidDate) {
      if (
        currentDate >=
          moment(filteredBanner?.initialDate).format('YYYY/MM/DD HH:mm') &&
        currentDate <
          moment(filteredBanner?.finalDate).format('YYYY/MM/DD HH:mm') &&
        filteredBanner?.bannerDescription === origin
      ) {
        setActivate(true)
      } else {
        setActivate(false)
      }
    }
  }, [validDate])

  setTimeout(() => {
    const date = new Date().toLocaleString('sv-SE', {
      timeZone: 'America/Panama',
    })
    setValidDate(moment(date).format('YYYY/MM/DD HH:mm:ss'))
    setcurrentDate(moment(date).format('YYYY/MM/DD HH:mm'))
  }, 2000)

  // Función para obtener el objeto con la fecha más reciente (considerando lastModifiedDate o createdDate) y bannerDescription igual a "origin"
  function getLatestPhoneObject(data) {
    let latestPhoneObject = null

    for (let i = 0; i < data.length; i++) {
      const currentLastModifiedDate = data[i].lastModifiedDate
      const currentCreatedDate = data[i].createdDate
      const currentBannerDescription = data[i].bannerDescription

      if (currentBannerDescription === origin) {
        if (currentLastModifiedDate === null) {
          // Si lastModifiedDate es null, comparamos con createdDate
          if (
            currentCreatedDate &&
            (!latestPhoneObject ||
              currentCreatedDate > latestPhoneObject.createdDate)
          ) {
            latestPhoneObject = data[i]
          }
        } else {
          // Si lastModifiedDate no es null, comparamos directamente
          const currentDate = new Date(currentLastModifiedDate)
          const latestDate = new Date(
            latestPhoneObject?.lastModifiedDate ||
              latestPhoneObject?.createdDate,
          )

          if (!latestPhoneObject || currentDate > latestDate) {
            latestPhoneObject = data[i]
          }
        }
      }
    }

    return latestPhoneObject
  }

  // Llamamos a la función para obtener el objeto con la fecha más reciente y bannerDescription igual a "origin"
  filteredBanner = getLatestPhoneObject(banner)

  return (
    <Dialog style={{ zIndex: 100 }} fullScreen open={activate}>
      <Maintenance message={filteredBanner?.message ?? ''} />
    </Dialog>
  )
}

import {Box, Typography} from '@mui/material';
import React from 'react';
import Button from 'src/components/Button/ButtonCommon';
import {BreakPointTheme} from 'src/theme/breakpoints';
import styled from 'styled-components';
import backgroundImageMobile from '../../../assets/images/onboardingCredito/Enmascarar grupo 5.png';
import backgroundImage from '../../../assets/images/onboardingCredito/Grupo 8256.png';

const Heading = styled(Box)(({type}) => ({
  fontSize: '25px',
  fontWeight: '500',
  color: '#011E41',
  textAlign: 'center',
  whiteSpace: 'pre-line',
  [BreakPointTheme.breakpoints.up('md')]: {
    fontWeight: '700',
    fontSize: '36px',
  },
  ...(type === 'type2' && {
    marginTop: '0.25rem',
    [BreakPointTheme.breakpoints.up('md')]: {
      fontSize: '30px',
    },
  }),
}));

const Subtitle = styled(Typography)(({type}) => ({
  fontSize: '18px',
  textAlign: 'center',
  whiteSpace: 'pre-line',
  marginTop: '1rem',
  marginBottom: '0',
  ...(type === 'type1' && {
    color: '#030303',
    fontSize: '30px',
    marginTop: '20px',
    [BreakPointTheme.breakpoints.up('sm1')]: {
      color: '#011E41',
      fontSize: '35px',
      marginTop: '38px',
    },
  }),
  ...(type === 'type2' && {
    color: '#030303',
    fontSize: '16px',
    marginTop: '25px',
    [BreakPointTheme.breakpoints.down('sm1')]: {
      fontSize: '16px',
      marginTop: '5px',
      width: '100%',
    },
    [BreakPointTheme.breakpoints.up('sm1')]: {
      fontSize: '24px',
      marginTop: '28px',
      width: '70%',
    },
  }),
}));

const Content = styled(Box)(() => ({
  backgroundColor: '#F2F4F7',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100vh',
  [BreakPointTheme.breakpoints.up('md')]: {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
  },
}));

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '50px 40px 0 20px',
  width: '100%',
  [BreakPointTheme.breakpoints.down('sm1')]: {
    padding: '15px 40px 0 20px',
  },
  [BreakPointTheme.breakpoints.up('md')]: {
    padding: '0px 1rem',
    alignItems: 'center',
    gridColumn: 'span 9 / span 9',
  },
}));

const WrapperButtom = styled(Box)(() => ({
  marginTop: '10px',
  [BreakPointTheme.breakpoints.up('md')]: {
    maxWidth: '184px',
    marginTop: '32px',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const Line = styled(Box)(() => ({
  position: 'absolute',
  backgroundColor: '#026E18',
  width: '20px',
  minHeight: '100vh',
  right: 0,
  [BreakPointTheme.breakpoints.up('md')]: {
    width: '34px',
  },
}));

const ImgContent = styled(Box)(() => ({
  '.image-md': {
    display: 'block',
  },
  '.image-lg': {
    display: 'none',
  },
  position: 'absolute',
  right: 0,
  bottom: 0,
  height: '100%',
  maxHeight: '288px',
  maxWidth: '190px',
  zIndex: 99,
  [BreakPointTheme.breakpoints.up('md')]: {
    maxWidth: '400px',
    maxHeight: 'unset',
    '.image-md': {
      display: 'none',
    },
    '.image-lg': {
      display: 'block',
    },
  },
  [BreakPointTheme.breakpoints.down('sm1')]: {
    maxHeight: '213px',
    maxWidth: '140px',
    margin: '10px',
  },
  [BreakPointTheme.breakpoints.down('xsm')]: {
    maxWidth: '120px',
    height: 'auto',
    margin: '10px',
  },
}));

function CommonPage({
  buttonClasses = '',
  buttonOnClick,
  textColor = 'text-white',
  mainDivClasses,
  bg = 'greenBtnBg',
  labels = {},
  styleHeading = {},
  styleTitle = {},
  styleSubtitle1 = {},
  styleSubtitle2 = {},
  styleSubtitle3 = {},
  planChubb,
}) {
  const {
    headingLabel,
    titleLabel,
    subtitleLabel,
    subtitleLabel2,
    subtitleLabel3,
    buttonLabel,
  } = labels;
  return (
    <>
      <Content>
        <Wrapper>
          {headingLabel && (
            <Heading type={'type1'} {...styleHeading}>
              {headingLabel}
            </Heading>
          )}
          {titleLabel && (
            <Heading type={'type2'} {...styleTitle}>
              {titleLabel}
            </Heading>
          )}
          {subtitleLabel && planChubb !== null && (
            <Subtitle type={'type1'} {...styleSubtitle1}>
              {subtitleLabel}
            </Subtitle>
          )}
          {subtitleLabel2 && (
            <Subtitle type={'type2'} {...styleSubtitle2}>
              {subtitleLabel2}
            </Subtitle>
          )}
          {subtitleLabel3 && (
            <Subtitle type={'type2'} {...styleSubtitle2}>
              {subtitleLabel3}
            </Subtitle>
          )}
          <WrapperButtom>
            <Button
              label={buttonLabel}
              textColor={textColor}
              bg={bg}
              className={buttonClasses}
              onClick={buttonOnClick}
            />
          </WrapperButtom>
        </Wrapper>
        <ImgContent>
          <img
            src={backgroundImageMobile}
            alt="backgroundImage"
            className="object-cover image-md"
          />
          <img
            src={backgroundImage}
            alt="backgroundImage"
            className="object-cover image-lg"
          />
        </ImgContent>
        <Line />
      </Content>
    </>
  );
}

export default CommonPage;

import { alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: 48,
        },
        //customVariant
        customVariant: {
          // Cambia aquí los estilos para la variante personalizada
          backgroundColor: 'white', // Fondo blanco
          color: 'black', // Letras negras
          '&:hover': {
            backgroundColor: theme.palette.grey[400], // Cambia el color al hacer hover
          },
        },
        // contained
        contained: {
          boxShadow: theme.customShadows.z8,
          background:
            'linear-gradient(51deg, rgba(147, 213, 0, 0.00) -30.69%, #93D500 154.97%), #247E00 !important',
          '&:hover': {
            background:
              'linear-gradient(51deg, rgba(147, 213, 0, 0.00) -30.69%, #93D500 154.97%), #247E00 !important',
            backgroundColor:
              'linear-gradient(51deg, rgba(147, 213, 0, 0.00) -30.69%, #93D500 154.97%), #247E00 !important',
          },
          '&.Mui-disabled': {
            color: '#FFF !important',
            background: '#cccccc !important',
          },
        },
        onboarding: {
          boxShadow: theme.customShadows.z8,
          color: '#FFF',
          background:
            'transparent linear-gradient(270deg, #53A600 0%, #399001 100%) no-repeat padding-box !important',
          '&.Mui-disabled': {
            color: '#FFF !important',
            background: '#cccccc !important',
          },
        },
        onboardingOutline: {
          boxShadow: theme.customShadows.z8,
          background: '#FFF 0% 0% no-repeat padding-box !important',
          border: '2px solid #53a600',
          color: '#026E18',
        },
        // borderContained
        borderContained: {
          border: '2px solid #53A600',
          background: '#FFF',
          opacity: 1,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedDisabled: {
          background: 'red', // Color de fondo para deshabilitado
          color: 'white', // Color del texto para deshabilitado
          '&:hover': {
            backgroundColor: 'red', // Cambia el color de fondo en hover
          },
        },
        containedPrimary: {
          background: theme.palette.gradients.button,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        outlinedInherit: {
          border: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  }
}

export const selectOptions1 = [
    {
        label: 'País de residencia',
        vale: 'País de residencia'
    },
    {
        label: 'Panamá',
        value: 'Panamá'
    },
    {
        label: 'Colombia',
        value: 'Colombia'
    },
    {
        label: 'Venezuela',
        vale: 'Venezuela'
    }
]

export const selectOptions2 = [
    {
        label: 'Veraguas',
        value: 'Veraguas'
    },
    {
        label: 'Panamá',
        value: 'Panamá'
    },
    {
        label: 'Chiriquí',
        value: 'Chiriquí'
    },
    {
        label: 'Coclé',
        vale: 'Coclé'
    }
]

// Almirante Bocas del Toro Changuinola Chiriquí Grande
export const selectOptions3 = [
    {
        label: 'Almirante',
        vale: 'Almirante'
    },
    {
        label: 'Bocas del Toro',
        value: 'Bocas del Toro'
    },
    {
        label: 'Changuinola',
        value: 'Changuinola'
    },
    {
        label: 'Chiriquí Grande',
        vale: 'Chiriquí Grande'
    }

]

// Alcalde Díaz Ancón Betania Bella Vista

export const selectOptions4 = [
    {
        label: 'Alcalde Díaz',
        vale: 'Alcalde Díaz'
    },
    {
        label: 'Ancón',
        value: 'Ancón'
    },
    {
        label: 'Betania',
        value: 'Betania'
    },
    {
        label: 'Bella Vista',
        vale: 'Bella Vista'
    }
]

// Soltero Casado Divorciado Viudo
export const selectOptions5 = [
    {
        label: 'Soltero',
        value: 'Soltero'
    },
    {
        label: 'Casado',
        value: 'Casado'
    },
    {
        label: 'Divorciado',
        value: 'Divorciado'
    },
    {
        label: 'Viudo',
        value: 'Viudo'
    }
]

// Administrador Contador Diseñador Programador
export const selectOptions6 = [
    {
        label: 'Administrador',
        value: 'Administrador'
    },
    {
        label: 'Contador',
        value: 'Contador'
    },
    {
        label: 'Diseñador',
        value: 'Diseñador'
    },
    {
        label: 'Programador',
        value: 'Programador'
    }
]

// Administrador Contador Diseñador Programador
export const selectOptions7 = [
    {
        label: 'Administrador',
        value: 'Administrador'
    },
    {
        label: 'Contador',
        value: 'Contador'
    },
    {
        label: 'Diseñador',
        value: 'Diseñador'
    },
    {
        label: 'Programador',
        value: 'Programador'
    }
]

/// Enero Febrero Marzo Abril
export const selectOptions8 = [
    {
        label: 'Enero',
        value: '01'
    },
    {
        label: 'Febrero',
        value: '02'
    },
    {
        label: 'Marzo',
        value: '03'
    },
    {
        label: 'Abril',
        value: '04'
    },
    {
        label: 'Mayo',
        value: '05'
    },
    {
        label: 'Junio',
        value: '06'
    },
    {
        label: 'Julio',
        value: '07'
    },
    {
        label: 'Agosto',
        value: '08'
    },
    {
        label: 'Septiembre',
        value: '09'
    },
    {
        label: 'Octubre',
        value: '10'
    },
    {
        label: 'Noviembre',
        value: '11'
    },
    {
        label: 'Diciembre',
        value: '12'
    }
]

// 2023 2022 2021 2020
export const selectOptions9 = [
    {
        label: '2023',
        value: '2023'
    },
    {
        label: '2022',
        value: '2022'
    },
    {
        label: '2021',
        value: '2021'
    },
    {
        label: '2020',
        value: '2020'
    }
]

// Chile Argentina Colombia Venezuela
export const selectOptions10 = [
    {
        label: 'Chile',
        value: 'Chile'
    },
    {
        label: 'Argentina',
        value: 'Argentina'
    },
    {
        label: 'Colombia',
        value: 'Colombia'
    },
    {
        label: 'Venezuela',
        value: 'Venezuela'
    }
]
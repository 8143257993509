import React from 'react'
import GrupoWhite from '../../../assets/images/onboardingCredito/Grupo 8159.png'
import backgroundImage from '../../../assets/images/onboardingCredito/Grupo 8256.png'
import backgroundImageMobile from '../../../assets/images/onboardingCredito/Enmascarar grupo 5.png'
import Button from 'src/components/Button/ButtonCommon'

function CommonPage({
  buttonClasses = '',
  buttonMainClasses,
  buttonOnClick,
  buttonText,
  heading,
  headingClasses,
  mainDivClasses,
  styleSubtitle3 = {},
  subtitle1,
  subtitle1Classes,
  subtitle2,
  subtitle2Classes,
  subtitle3,
  subtitle3Classes,
  title,
  textColor = 'text-white',
  bg = 'greenBtnBg',
}) {
  return (
    <>
      <div
        className={`grid grid-cols-12 min-h-[100vh] bg-[#F2F4F7] absolute w-full relative overflow-hidden ${mainDivClasses}`}>
        <div className="lg:col-span-9 col-span-12 flex flex-col border-r-[5%] border-[#026E18] items-center lg:justify-center justify-start text-white lg:px-4">
          <div
            className={`lg:text-4xl text-3xl text-[#011e41] font-bold text-center tracking-wider lg:mt-0 mt-20 ${headingClasses}`}>
            {heading}
          </div>
          {title && (
            <div className="lg:text-5xl text-3xl flex justify-center font-semibold bg-[#011e41] text-white py-2 lg:rounded-lg mt-7 tracking-wide lg:px-16 lg:w-auto lg:ml-0 ml-10 w-full">
              <span className="lg:ml-0 -ml-10">{title}</span>
              <span className="lg:mt-1 mt-2 lg:ml-4 ml-2 lg:w-[100px] w-[57px]">
                <img src={GrupoWhite} alt="" className="object-cover" />
              </span>
            </div>
          )}

          <div
            className={`lg:text-3xl text-3xl flex lg:font-medium font-semibold text-center text-[#011e41] p-3 rounded-lg ${subtitle1Classes}`}>
            {subtitle1}
          </div>
          <p
            className={`lg:text-2xl lg:mt-10 mt-5 text-center text-black tracking-wide font-light lg:mx-11 mx-11 ${subtitle2Classes}`}>
            {subtitle2}
          </p>
          <p
            className={`lg:text-2xl mt-4 text-center text-black tracking-wide font-light lg:mx-11 mx-11 ${subtitle3Classes}`} style={styleSubtitle3}>
            {subtitle3}
          </p>

          <div
            className={`flex item-center lg:w-48 md:w-96 w-full lg:px-1 px-6 justify-center lg:mt-5 ${buttonMainClasses}`}>
            <Button
              label={buttonText}
              textColor={textColor}
              bg={bg}
              className={buttonClasses}
              onClick={buttonOnClick}
            />
          </div>
        </div>

        <div className="absolute lg:block hidden right-0 z-[99] min-h-[100vh] max-w-[400px]">
          <img src={backgroundImage} alt="" className="object-cover" />
        </div>

        <div className="lg:hidden block col-span-12 h-[300px] z-[99]">
          <div className="flex justify-end">
            <img src={backgroundImageMobile} alt="" className="h-[300px]" />
          </div>
        </div>

        <div className="absolute bg-secondary min-h-[100%] lg:px-[17px] px-[10px] right-0"></div>
      </div>
    </>
  )
}

export default CommonPage

import { Box } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CorporateCard from './CorporateCard';
import InfoBanner from './InfoBanner';
import NonCorporateCard from './propertyAndPrepaid';

export const CreditCard = () => {
  const {productCorporate} = useSelector(state => state.productReducer);
  const {listActiveProduct, selectedProduct} = useSelector(
    state => state.ActiveProductReducer,
  );
  
  const dispatch = useDispatch();

  const nonCorporateItems = listActiveProduct.filter(item => !item.isCorporate);

  const handleCardClick = cardDetails => {
    dispatch({
      type: 'SELECTED_PRODUCT',
      payload: cardDetails,
    });
  };

  return (
    <Box
      sx={{
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        display: 'flex',
      }}
      data-testid="credit-card-container">
      <Box
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          gap: '8px',
          position: 'relative',
        }}
        data-testid="credit-card-inner-container">
        {productCorporate?.isCorporate && (
          <CorporateCard
            key={productCorporate.customerProductId}
            item={productCorporate}
            isSelected={
              productCorporate.customerProductId ===
              selectedProduct?.customerProductId
            }
            onClick={handleCardClick}
            click={true}
            isShowBlock={false}
            isDetailsPage={false}
          />
        )}
        {nonCorporateItems.map(item => (
          <NonCorporateCard
            key={item.customerProductId}
            item={item}
            isSelected={
              item.customerProductId === selectedProduct?.customerProductId
            }
            onClick={handleCardClick}
            click={true}
            isShowBlock={false}
            isDetailsPage={false}
          />
        ))}
        <InfoBanner />
      </Box>
    </Box>
  );
};

export default CreditCard;

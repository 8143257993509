import { Skeleton } from 'antd'
import React from 'react'
export const SkeletonCMF = () => {
  return (
    <div style={{display: 'flex', flexDirection: 'column',}}>
      <div>
        <Skeleton.Input active size='large' block />
        <Skeleton.Input active size='large' block />
        <Skeleton.Input active size='large' block />
        <Skeleton.Input active size='large' block />
      </div>
      <br/>
      <br/>
      <br/>
      <div style={{ width: '70%', margin: '0 auto'}}>
        <Skeleton.Input active size='large' block />
        <Skeleton.Input active size='large' block />
        <Skeleton.Input active size='large' block />
        <Skeleton.Input active size='large' block />
        <Skeleton.Input active size='large' block />
        <Skeleton.Input active size='large' block />
        <Skeleton.Input active size='large' block />
        <Skeleton.Input active size='large' block />
        <Skeleton.Input active size='large' block />
      </div>
      <br/>
      <br/>
      <div style={{width: '70%', display: 'flex', flexDirection: 'row', margin: '0 auto'}}>
        <div style={{paddingRight: 8}}>
          <Skeleton.Input active size='large' block />
          <Skeleton.Input active size='large' block />
          <Skeleton.Input active size='large' block />
          <Skeleton.Input active size='large' block />
          <Skeleton.Input active size='large' block />
        </div>
        <div style={{paddingLeft: 8}}>
          <Skeleton.Input active size='large' block />
          <Skeleton.Input active size='large' block />
          <Skeleton.Input active size='large' block />
          <Skeleton.Input active size='large' block />
          <Skeleton.Input active size='large' block />
        </div>
      </div>
    </div>
  )
}
export default SkeletonCMF

import { message, notification } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import StepWizard from 'react-step-wizard';
import 'src/App.css';
import ButtonFloat from 'src/components/Button/floatingButtton/whastapp';
import PrepagoProductExists from 'src/containers/OnboardingPrepago/PrepagoProductExists';
import PrepagoProductStop from 'src/containers/OnboardingPrepago/PrepagoProductStop';
import PrepagoRedirectApp from 'src/containers/OnboardingPrepago/PrepagoRedirectApp';
import PrepagoRequestInRevision from 'src/containers/OnboardingPrepago/PrepagoRequestInRevision';
import IdleHandler from 'src/functions/IdleHandler';
import 'src/index.css';
import MaintenanceModal from '../../components/Modals/maintenanceModal';
import { clearData, clearOrigin } from '../../store/actions/KO.Actions';
import {
  codeReferidos,
  getBannerActive,
  getClearBannerActive,
  getConfigOnboard,
  getSettings,
  getSwitchStatusMotor2,
  resetFormRequest,
} from '../../store/actions/requestCard.actions';
import { DIALOG_TYPES } from '../../utils/Constants';
import ActividadLaboral from './ActividadLaboral';
import ApprovedRequest from './ApprovedRequest';
import ChubbScreen from './ChubbScreens/ChubbScreens';
import CreditApproved from './CreditApproved';
import { DataReviewAPC } from './DataReviewAPC';
import ErrorScreen from './ErrorScreen';
import IdentityMissingFields from './IdentityMissingFields';
import ProductApproved from './ProductApproved';
import ProductExist from './ProductExist';
import ProductPendingApproved from './ProductPendingApproved';
import ReadAndSingPDF from './ReadAndSingPDF';
import ReadPdfPrepago from './ReadPdfPrepago';
import Recommendation from './Recommendations';
import RequestInRevision from './RequestInRevision';
import { RequestRejected } from './RequestRejected';
import ScanID from './ScanID';
import { VerificationHasTD } from './VerificationHasTD';
import VerifyMailPhone from './VerifyMailPhone';
import Welcome from './Welcome';
import SCREENS from './screens';

message.config({
  top: 30,
  duration: null,
  maxCount: 1,
});

class IndexCardRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout: 30000,
      showModal: false,
      reload: 0,
      isTimedOut: false,
      visible: false,
      Istimer: 1,
      codeRefer: '',
      urlActive: '',
    };
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }
  _onAction(e) {
    this.setState({isTimedOut: false});
  }

  _onActive(e) {
    this.setState({reload: 0});
    this.setState({isTimedOut: false});
  }

  _onIdle = e => {
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      this.setState({Istimer: 2});
    } else {
      this.setState({isTimedOut: true});
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  componentDidMount() {
    this.props.clearBannerActive();
    this.initProps();
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    let URLactual = window.location.origin;
    this.setState({urlActive: URLactual});

    if (
      window.location.search.length > 5 &&
      window.location.search.includes('?ref')
    ) {
      let code = window.location.search.slice(5);
      this.props.codeReferidos(code);
    } else if (window.location.search.length === 0) {
      this.props.codeReferidos(null);
    } else {
      this.props.codeReferidos(null);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isTimedOut !== this.state.isTimedOut) {
      window.onbeforeunload = null;
      window.location.href = `${this.state.urlActive}/cardrequest`;
    }
    if (prevProps?.dialog?.id !== this.props?.dialog?.id) {
      const {message: messageDialog, dialogType} = this.props.dialog;

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

      if (dialogType === DIALOG_TYPES.SUCCESS) {
        const openNotificationWithIcon = type => {
          notification[type]({
            message: 'Notificación',
            description: messageDialog,
          });
        };
        openNotificationWithIcon('success');
      } else {
        const openNotificationWithIcon = type => {
          return notification[type]({
            message: 'Notificación',
            description: messageDialog,
          });
        };
        openNotificationWithIcon('error');
      }
    }
  }

  async initProps() {
    this.props.getBannerActive();
    this.props.clearBannerActive();
    this.props.getSettings();
    this.props.getConfigOnboard();
    this.props.getSwitchStatusMotor2();
  }

  render() {
    window.onbeforeunload = function() {
      window.setTimeout(function() {}, 0);
      return 'Alert!';
    };
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CMF construye tus sueños</title>
        </Helmet>
        <IdleHandler onIdle={this._onIdle} />
        <StepWizard
          initialStep={SCREENS.Welcome}
          isLazyMount={true}
          isHashEnabled={false}
          transitions={{
            enterRight: 'fadeIn',
            enterLeft: 'fadeIn',
            exitRight: 'fadeOut',
            exitLeft: 'fadeOut',
          }}>
          <Welcome />
          <Recommendation />
          <VerifyMailPhone />
          <ScanID canReturn={true} />
          <DataReviewAPC canReturn={false} />
          <ActividadLaboral />
          <ChubbScreen />
          <CreditApproved />
          <ReadAndSingPDF canReturn={true} />
          <ErrorScreen />
          <RequestRejected />
          <RequestInRevision />
          <ApprovedRequest />
          <VerificationHasTD />
          <ProductExist />
          <IdentityMissingFields />
          <PrepagoProductExists />
          <PrepagoRequestInRevision />
          <PrepagoRedirectApp />
          <ReadPdfPrepago />
          <PrepagoProductStop />
          <ProductApproved />
          <ProductPendingApproved />
        </StepWizard>
        <MaintenanceModal origin="TDC" />
        <ButtonFloat />
      </>
    );
  }
}

IndexCardRequest.propTypes = {
  getSwitchStatusMotor2: PropTypes.func,
  clearBannerActive: PropTypes.func,
  clearData: PropTypes.func,
  codeReferidos: PropTypes.func,
  dialog: PropTypes.shape({
    dialogType: PropTypes.any,
    id: PropTypes.any,
    message: PropTypes.any,
  }),
  getActivityTypes: PropTypes.func,
  getBannerActive: PropTypes.func,
  getConfigOnboard: PropTypes.func,
  getSettings: PropTypes.func,
  path: PropTypes.shape({
    toUpperCase: PropTypes.func,
  }),
  resetForm: PropTypes.func,
};

const mapStateToProps = state => {
  const {dialog, form, banner, valueMotorCreditoV2} = state.RequestCardReducer;
  const {path} = state.Phone;

  return {
    valueMotorCreditoV2,
    dialog,
    path,
    banner,
    formRequest: form,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSwitchStatusMotor2: () => dispatch(getSwitchStatusMotor2()),
    resetForm: () => dispatch(resetFormRequest()),
    getSettings: () => dispatch(getSettings()),
    clearOrigin: () => dispatch(clearOrigin()),
    clearData: () => dispatch(clearData()),
    codeReferidos: code => dispatch(codeReferidos(code)),
    getBannerActive: () => dispatch(getBannerActive()),
    clearBannerActive: () => dispatch(getClearBannerActive()),
    getConfigOnboard: () => dispatch(getConfigOnboard()),
  };
};

const IndexCard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndexCardRequest);

export default IndexCard;

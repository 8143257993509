import { Box } from "@mui/material";
import React from "react";

function Icon({checked}) {
  return <Box sx={{display: 'flex', justifyContent: 'center'}}>
    {
      checked ?
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              fill="#7EC700"
              d="M12 17.52l4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72 3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41-1.89-4.46c-.34-.81-1.5-.81-1.84 0L9.19 8.88l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5z"
            ></path>
        </svg>
      :
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <rect
                width="16.4"
                height="16.4"
                x="3.8"
                y="3.8"
                stroke="var(--specific-light-content-white)"
                strokeOpacity="0.8"
                strokeWidth="1.6"
                rx="5.2"
            ></rect>
        </svg>
    }
  </Box>
}

export default Icon;

import {Box} from '@mui/material'
import React from 'react'

const LoadingIcons = () => {
  return (
    <Box>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.5001 1.66675V5.00008M10.5001 15.0001V18.3334M5.50008 10.0001H2.16675M18.8334 10.0001H15.5001M16.3988 15.8988L14.0417 13.5417M16.3988 4.1667L14.0417 6.52372M4.60139 15.8988L6.95841 13.5417M4.60139 4.1667L6.95841 6.52372"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Box>
  )
}

export default LoadingIcons

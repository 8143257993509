import styled from '@emotion/styled'
import {Modal} from 'antd'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
// import {useHistory} from 'react-router-dom'
import AsideCharacter from '../../components/Aside/AsideCharacter.js'
import ButtonNext from '../../components/Button/ButtonNext.js'
import Text from '../../components/Typography/Text.js'
import {SCREENS} from './screens.js'
const ResultEvaluation = props => {
  const {result} = useSelector(state => state.Phone)
  const {maxLoanLimit, checkTe} = useSelector(state => state.RequestCardReducer)
  const {resultTE} = useSelector(state => state.RequestCardReducer)
  const [openModal, setOpenModal] = useState(false)

  const renderCategory = () => {
    if (checkTe) {
      if (result?.complete && resultTE) {
        return (
          <DivContainer>
            <Text
              textType="title"
              style={{fontSize: '3rem', paddingBottom: 16}}>
              ¡Felicitaciones!
            </Text>
            <Text textType="highlight">Tu credimóvil CMF</Text>
            <Text textType="subtitle">
              fue aprobado por un máximo de ${`${maxLoanLimit?.toFixed(2)}`}.
            </Text>
            <Text style={{marginTop: 125}} textType="paragraphs">
              ¿Quieres continuar con el financiamiento del celular y firma de
              contrato?
            </Text>
            <ButtonNext
              background="green"
              border="green"
              data-testid="next-credimovil"
              onClick={() => setOpenModal(true)}
              style={{marginTop: 16}}>
              Continuar
            </ButtonNext>
          </DivContainer>
        )
       
      } else {
        return (
          <DivContainer>
            <Text textType="title" style={{fontSize: '3.2rem'}}>
              ¡Gracias por tu preferencia!
            </Text>
            <Text
              textType="subtitle"
              style={{fontSize: '2.5rem', width: '77%'}}>
              Hemos revisado tus datos y por el momento no podemos continuar con
              la solicitud
            </Text>
            <Text style={{marginTop: 50, width: '78%'}} textType="paragraphs">
              ¡Esperamos poder atenderte en el futuro!
            </Text>
            <ButtonNext
              background="green"
              border="green"
              href="https://www.cmfinanciera.com/s/formas-de-pago"
              style={{marginTop: 80, zIndex: 100}}>
              Salir
            </ButtonNext>
          </DivContainer>
        )
      }
    } else {
      if (result?.complete) {
        return (
          <DivContainer>
            <Text
              textType="title"
              style={{fontSize: '3rem', paddingBottom: 16}}>
              ¡Felicitaciones!
            </Text>
            <Text textType="highlight">Tu credimóvil CMF</Text>
            <Text textType="subtitle">
              fue aprobado por un máximo de ${`${maxLoanLimit?.toFixed(2)}`}.
            </Text>
            <Text style={{marginTop: 125}} textType="paragraphs">
              ¿Quieres continuar con el financiamiento del celular y firma de
              contrato?
            </Text>
            <ButtonNext
              background="green"
              border="green"
              data-testid="next-credimovil"
              onClick={() => setOpenModal(true)}
              style={{marginTop: 16}}>
              Continuar
            </ButtonNext>
          </DivContainer>
        )
      } else {
        return (
          <DivContainer>
            <Text textType="title" style={{fontSize: '3.2rem'}}>
              ¡Gracias por tu preferencia!
            </Text>
            <Text
              textType="subtitle"
              style={{fontSize: '2.5rem', width: '77%'}}>
              Hemos revisado tus datos y por el momento no podemos continuar con
              la solicitud
            </Text>
            <Text style={{marginTop: 50, width: '78%'}} textType="paragraphs">
              ¡Esperamos poder atenderte en el futuro!
            </Text>
            <ButtonNext
              background="green"
              border="green"
              href="https://www.cmfinanciera.com/s/formas-de-pago"
              style={{marginTop: 80, zIndex: 100}}>
              Salir
            </ButtonNext>
          </DivContainer>
        )
      }
    }
  }

  return (
    <>
      {result?.complete ? (
        <Container>
          <ModalMain
            centered
            open={openModal}
            footer={false}
            onCancel={() => setOpenModal(false)}>
            <div
              style={{
                widows: '50%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <Text
                textType="paragraphs"
                style={{
                  margin: 0,
                  lineHeight: 1,
                  textAlign: 'center !important',
                }}>
                Ahora vamos a elegir el valor del celular
              </Text>
              <Text
                textType="paragraphs"
                style={{textAlign: 'center !important', marginTop: 0}}>
                y las cuotas en las que lo deseas pagar
              </Text>
              <ButtonNext
                background="green"
                border="green"
                data-testid="select-phone"
                style={{height: 60, width: 200, marginLeft: 6}}
                onClick={() => props.goToStep(SCREENS.CotizadorPhone)}>
                Continuar
              </ButtonNext>
            </div>
          </ModalMain>
          <Wrapper style={{flex: 1, height: '100%', justifyContent: 'center'}}>
            {renderCategory()}
          </Wrapper>

          <Wrapper style={{flex: 0.3}}>
            <AsideCharacter type="success" color="white" positionX="right" />
          </Wrapper>
        </Container>
      ) : (
        <Container>
          <Wrapper style={{flex: 1, height: '100%', justifyContent: 'center'}}>
            <DivContainer>{renderCategory()}</DivContainer>
          </Wrapper>

          <Wrapper
            style={{
              width: '100%',
              height: 300,
              bottom: 0,
              position: 'absolute',
            }}>
            <AsideCharacter type="stand" color="white" positionY="bottom" />
          </Wrapper>
        </Container>
      )}
    </>
  )
}

const DivContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}))

const ModalMain = styled(Modal)(() => ({
  width: '50% !important',
  '.ant-modal-content': {
    // width: '50vw',
    borderRadius: '0px !important',
    height: 'calc(60vh - 20px) !important',
    margin: 'auto',
  },
  '.ant-modal-body': {
    margin: ' auto',
    display: ' flex',
    height: ' 100%',
    justifyContent: ' center',
    alignItems: ' center',
  },
  '.ant-modal-centered .ant-modal': {
    width: '50% !important',
  },
}))

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}))

export default ResultEvaluation

import produce from 'immer'
import {
  ACTIVATE_PRODUCTCRED,
  DATA_SCORE_MATRIX_SUCCESS,
  FORM_REQUEST_UPDATE_CREDI,
  GET_SCORE_ONBOARDING_SUCCES,
  SET_APC_ID,
  SET_LATIN_DATA_SUCCESS,
} from '../../constrants/reducers'
const initState = {
  CurrentOnboarding: {},
  Dialog: {
    status: false,
    FinalDialog: false,
    list: false,
    navigationPress: false,
  },
  Onboardings: [],
  OnboardingDetails: {},
  PdfInfo: {},
  LoadingPdf: false,
  LatinData: {},

  Matrix: {},

  IdOnboarding: null, //650
  productId: null,
  customerId: null,
  productIsActiveCred: false,

  mapDireccion: {},
  LoadingMaps: false,

  address: {},
  statusOnboar: null,
  apcId: null,
  planChubb: null,
  existProduct: null,
}

const KOReducer = (state = initState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FORM_REQUEST_UPDATE_CREDI:
        draft.onboardingSaved = action.onboardingSaved
        draft.IdOnboarding = action.IdOnboarding
        draft.customerId = action.customerId
        draft.productId = action.productId
        break
      case 'CREATE_UPDATE_ONBOARDING_SUCCES':
        draft.CurrentOnboarding = action.ob
        draft.statusOnboar = action.status
        break
      case 'EDIT_PERSONAL_ADDRESS':
        draft.address = action.payload
        break
      case 'CLEAR_ONBOARING':
        draft.apcId = null
        draft.IdOnboarding = null
        draft.CurrentOnboarding = {}
        draft.Matrix = null
        draft.LatinData = null
        break
      case DATA_SCORE_MATRIX_SUCCESS:
        draft.Matrix = action.matriz
        break
      case GET_SCORE_ONBOARDING_SUCCES:
        draft.acpId = action.payload
        break
      case SET_LATIN_DATA_SUCCESS:
        draft.LatinData = action.LatinData
        break
      case SET_APC_ID:
        draft.acpId = action.payload
        break
      case ACTIVATE_PRODUCTCRED:
        draft.productIsActiveCred = true
        break
      case 'CLEAR_CURRENT_ONBOARDING':
        draft.CurrentOnboarding = initState.CurrentOnboarding
        break
      case 'APPEND_LICENCE_TO_ONBOARDING':
        draft.CurrentOnboarding = {
          ...draft.CurrentOnboarding,
          licenseInformation: action.payload,
        }
        break
      case 'APPEND_SIGNATURE_TO_ONBOARDING':
        draft.CurrentOnboarding.images.push(action.payload)
        break
      case 'APPEND_EMPLOYMENT_TO_ONBOARDING':
        draft.CurrentOnboarding.employment = []
        draft.CurrentOnboarding.employment.push(action.payload)
        break
      case 'APPEND_CONTRACT_IMAGE_TO_ONBOARDING':
        draft.OnboardingDetails.images.push(action.payload)
        break
      case 'GET_ONBOARDINGS_SUCCESS':
        draft.Onboardings = action.payload
        break
      case 'CLEAR_ONBOARDING':
        draft.Onboardings = []
        break
      case 'GET_ONBOARDING_DETAILS_SUCCESS':
        draft.OnboardingDetails = action.payload
        break
      case 'GET_PDF_PRINT_LOADING_SHOW':
        draft.LoadingPdf = true
        break
      case 'GET_PDF_PRINT_SUCCESS':
        draft.PdfInfo = action.payload
        break
      case 'GET_PDF_PRINT_HIDE':
        draft.LoadingPdf = false
        break
      case 'CLEAR_ONBOARDING_DETAILS':
        draft.OnboardingDetails = {}
        break

      case 'RESET_ONBOARDING_DIALOG':
        draft.Dialog = initState.Dialog
        break
      case 'ADDRESS_DIRECCION_MAP':
        draft.mapDireccion = action.map
        break
      case 'LOAD_MAPS_DIRECCTION_SHOW':
        draft.LoadingMaps = true
        break
      case 'LOAD_MAPS_DIRECCTION_HIDE':
        draft.LoadingMaps = false
        break
      case 'SET_VARIABLE_CHUBB':
        draft.planChubb = action.payload
        break
      // case RESET_STATE_SCREEN:
      //   draft.OriginSign = {};
      //   draft.base64Terms = null;
      //   draft.documentBase64 = null;
      //   draft.documentId = {};
      //   draft.matrizD = {};
      //   draft.onboardingGet = {};
      //   draft.singIdData = null;
      //   draft.statusOnboarindSucces = null;
      //   break;
      case 'EXIST_PRODUCT':
        draft.existProduct = action.payload
        break
      default:
        return state
    }
  })

export default KOReducer

import React from 'react'
import ButtonNext from 'src/components/Button/ButtonNext'
const canReturnMobile = (props, step) => {
  const previousStep = () => {
    props.goToStep(step)
  }
  return (
    <ButtonNext
    background="white"
    border='green'
      loading={false}
      disabled={false}
      onClick={previousStep.bind(this)}>
      Volver
    </ButtonNext>
  )
}
export default canReturnMobile

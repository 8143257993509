import SCREENS from 'src/containers/OnboardingCredito/screens'
import { CHANGE_CURRENT_STEP } from '../../constrants/reducers'

export const setStep = step => {
  return dispatch => {
    dispatch({type: CHANGE_CURRENT_STEP, step: step})
  }
}

export const checkStep = (step, goToStep) => {
  return (_, getState) => {
    const {
      step: {currentStep},
    } = getState()

    if (currentStep >= SCREENS.ErrorScreen) {
      goToStep(currentStep)
    } else if (step > currentStep) {
      goToStep(currentStep)
    }
  }
}

import { UpOutlined } from '@ant-design/icons';
import { styled } from '@material-ui/core/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Button, TextField } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CodeValidator from 'src/containers/cmf_linea/ResetPassword/CodeValidator';
import {
  ResetPasswordSendEmail,
  changePassword,
} from 'src/store/actions/LoginAction';
import {
  BASE_COLORS,
  FONT_SIZE,
  FONT_WEIGHT,
  messageCMF,
} from 'src/theme/constants';
import * as yup from 'yup';
import lock from '../../../assets/images/cmf_linea/lock-open.svg';
import whatsapp from '../../../assets/images/cmf_linea/whatsapp.svg';
import { ContainerData, ContainerMaterial } from '../Pages/ContainerHome';
const ContainerConfig = styled(Box)(({theme}) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

const ButtonVerification = styled(Button)(({theme}) => ({
  marginTop: '20px',
  width: '40%',
  borderRadius: '50px',
  height: '3.5rem',
  color: BASE_COLORS.white,
  background:
    'linear-gradient(50.7deg, rgba(85, 172, 0, 0) -30.69%, #7EC700 154.97%), #247E00',
  fontFamily: 'Red Hat Text',
  fontWeight: FONT_WEIGHT.medium,
  fontSize: FONT_SIZE.md,
  '&:hover': {
    fontSize: FONT_SIZE.md,
    color: BASE_COLORS.white,
    background:
      'linear-gradient(50.7deg, rgba(85, 172, 0, 0) -30.69%, #7EC700 154.97%), #247E00',
    fontFamily: 'Red Hat Text',
    fontWeight: FONT_WEIGHT.medium,
  },
  '& span': {
    color: BASE_COLORS.white,
    fontFamily: 'Red Hat Text',
    fontWeight: FONT_WEIGHT.medium,
    fontSize: FONT_SIZE.lg,
  },
  [theme.breakpoints.between('xs', 'md')]: {
    width: '100%',
  },
  [theme.breakpoints.between('lg', 'xxl')]: {
    width: '40%',
  },
}))

const DivIcons = styled('div')(({theme}) => ({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#f1f8f3',
  borderRadius: '150px',
  width: '36px',
  height: '36px',
  marginRight: '15px',
}))

const Details = styled(AccordionDetails)(({theme}) => ({
  [theme.breakpoints.between('xs', 'md')]: {
    width: '90%',
  },
  [theme.breakpoints.between('lg', 'xxl')]: {
    width: '80%',
  },
}))
const RedditTextField = styled(props => (
  <TextField InputProps={{disableUnderline: true}} {...props} />
))(({theme}) => ({
  '& .MuiInputAdornment-root': {
    marginTop: '0!important',
  },

  '& .MuiInputLabel-root': {
    position: 'absolute',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    padding: '5px',
  },
  '& .MuiFilledInput-root': {
    borderRadius: '16px',
    border: '1px solid var(--specific-light-stroke-l, #D0D5DD)',
    background: 'var(--specific-light-background-white, #FFF)',
    overflow: 'hidden',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main,
    },
  },
}))
export default function SecurityProfile() {
  const [showPassword, setShowPassword] = useState(false)
  const [activeCode, setActiveCode] = useState(false)
  const {userSF} = useSelector(
    state => state.AuthenticationReducer?.CurrentUser,
  )
  const {customerId} = useSelector(
    state => state.AuthenticationReducer.CurrentUser,
  )
  const [passwordSave, setPasswordSave] = useState(null)
  const dispatch = useDispatch()
  const {ResetLoading} = useSelector(state => state.AuthenticationReducer)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const {tokenCMF} = useSelector(
    state => state.AuthenticationReducer.CurrentUser,
  )
  const navigate = useNavigate()
  const onSuccess = succes => {
    if (succes) {
      setActiveCode(true)
    } else {
      setActiveCode(false)
    }
  }
  const OnFinshed = success => {
    if (success) {
      navigate('/home')
    }
  }
  const validationSchemaBank = yup.object({
    password: yup
      .string('Introduce el codigo')
      .required('Contraseña es requerido')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$/,
        'Su contraseña debe tener un mínimo de ocho caracteres y contener al menos una letra mayúscula, una letra minúscula, un caracter especial y un número.',
      ),
    newPassword: yup
      .string('Introduce el contraseña')
      .required('Contraseña es requerido')
      .oneOf([yup.ref('password')], 'Las contraseñas deben ser iguales'),
  })
  const handleClickShowPassword = () => setShowPassword(show => !show)
  const handleClickShowNewPassword = () => setShowNewPassword(show => !show)

  const formikPassword = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
    },
    validationSchema: validationSchemaBank,
    onSubmit: values => {
      setPasswordSave(values.newPassword)
      dispatch(ResetPasswordSendEmail(userSF[0]?.Email, onSuccess))
    },
  })
  const onNextPassword = success => {
    if (success) {
      dispatch(changePassword(customerId, passwordSave, tokenCMF, OnFinshed))
    }
  }
  return (
    <ContainerMaterial maxWidth="xl">
      <ContainerData>
        <Typography
          variant="H5"
          sx={{
            padding: '8px',
          }}>
          Seguridad de la Cuenta
        </Typography>
        <ContainerConfig>
          <Accordion
            style={{
              width: '100%',
              borderRadius: '20px',
              marginTop: '20px',
              '& MuiPaper-root-MuiAccordion-root.Mui-expanded:before': {
                opacity: '0',
              },
            }}>
            <AccordionSummary
              style={{
                '& MuiPaper-root-MuiAccordion-root.Mui-expanded:before': {
                  opacity: '0',
                },
              }}
              aria-controls="panel2a-content"
              id="panel2a-header"
              expandIcon={<UpOutlined />}>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <DivIcons>
                  <img src={lock} style={{width: '16px'}} alt='Lock'></img>
                </DivIcons>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      marginBottom: 0,
                    }}>
                    Cambiar mi contraseña
                  </Typography>
                  <Typography>***********</Typography>
                </div>
              </div>
            </AccordionSummary>
            <Details>
              {activeCode ? (
                <CodeValidator
                  onNextPassword={onNextPassword}
                  email={userSF[0]?.Email}
                  origin="reset"
                />
              ) : (
                <form
                  onSubmit={formikPassword.handleSubmit}
                  style={{width: '100%'}}>
                  <Box>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <RedditTextField
                        fullWidth
                        variant="filled"
                        label="Nueva contraseña"
                        placeholder="**********"
                        name="password"
                        value={formikPassword.values.password}
                        onChange={formikPassword.handleChange}
                        onBlur={formikPassword.handleBlur}
                        error={
                          formikPassword.touched.password &&
                          Boolean(formikPassword.errors.password)
                        }
                        helperText={
                          formikPassword.touched.password &&
                          formikPassword.errors.password
                        }
                        style={{marginTop: 11}}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end">
                                {showPassword ? (
                                  <Visibility
                                    style={{color: '#026E18', margin: '7px'}}
                                  />
                                ) : (
                                  <VisibilityOff
                                    style={{color: '#026E18', margin: '7px'}}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <RedditTextField
                        fullWidth
                        variant="filled"
                        label="Confirmar nueva contraseña"
                        placeholder="**********"
                        style={{marginTop: 11}}
                        name="newPassword"
                        value={formikPassword.values.newPassword}
                        onChange={formikPassword.handleChange}
                        onBlur={formikPassword.handleBlur}
                        error={
                          formikPassword.touched.newPassword &&
                          Boolean(formikPassword.errors.newPassword)
                        }
                        helperText={
                          formikPassword.touched.newPassword &&
                          formikPassword.errors.newPassword
                        }
                        type={showNewPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNewPassword}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end">
                                {showNewPassword ? (
                                  <Visibility
                                    style={{color: '#026E18', margin: '7px'}}
                                  />
                                ) : (
                                  <VisibilityOff
                                    style={{color: '#026E18', margin: '7px'}}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <ButtonVerification
                      variant="contained"
                      type="submit"
                      loading={ResetLoading}>
                      Guardar Cambios
                    </ButtonVerification>
                  </Box>
                </form>
              )}
            </Details>
          </Accordion>
          <Typography
            variant="H5"
            sx={{
              padding: '8px',
              margin: '1rem 0 .5rem 0 !important',
            }}>
            Soporte
          </Typography>
          <Accordion
            expanded
            style={{
              width: '100%',
              borderRadius: '20px',
              marginTop: '0',
            }}>
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header">
              <div style={{display: 'flex', flexDirection: 'row'}}>
                {/* <DivIcons>
                  <img src={error} style={{width: '16px'}}></img>
                </DivIcons> */}
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      marginBottom: 0,
                    }}>
                    Reportar un problema
                  </Typography>
                  <Typography>Contacta a nuestro equipo de soporte</Typography>
                </div>
              </div>
            </AccordionSummary>
            <Details>
              <Box component="form">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '2px',
                  }}>
                  <div>
                    <Typography>
                      Al continuar, se abrirá la aplicación de WhatsApp y
                      entrarás en contacto con uno de nuestros agentes, quienes
                      podrán ayudarte en tiempo real.
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '10px 0',
                      alignItems: 'center',
                    }}>
                    <DivIcons>
                      <img src={whatsapp} style={{width: '16px'}} alt='Whatsapp'></img>
                    </DivIcons>
                    <Typography
                      style={{
                        color:
                          'var(--specific-light-semantic-brand-secondary-verde, #026E18)',
                        fontSize: '20px',
                        fontWeight: '500',
                        lineHeight: '24px',
                        letterSpacing: '-0.4px',
                      }}>
                      {messageCMF.Whatsapp}
                    </Typography>
                  </div>
                  <ButtonVerification
                    href="whatsapp://send?phone=+50765978134"
                    variant="contained">
                    Ir a Whatsapp
                  </ButtonVerification>
                </div>
              </Box>
            </Details>
          </Accordion>
          {/* </div> */}
        </ContainerConfig>
      </ContainerData>
    </ContainerMaterial>
  )
}

import { Modal } from 'antd'
import PropTypes from 'prop-types'
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react'
import './index.css'

import Quagga from 'quagga'
import styled from 'styled-components'
import ButtonNext from '../Button/ButtonNext'
import ImeiCapture from '../ImeiCapture'

const DivFooterBotton = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignContent: 'center',
  width: '100%',
  margin: 'auto',
}))

var imeiCodes = []
var imeiScanneds = []

const ImeiScan = forwardRef((props, ref) => {
  const imeiCaptureRef = useRef(null)
  const scannerRef = useRef(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [imeis, setImeis] = useState([])
  const [selectedImei, setSelectedImei] = useState([])

  const {onSelected} = props
  let imeiCodes = []
  let imeiScanneds = []

  useEffect(() => {
    destroy()
    if (modalOpen) {
      init()
    }
  }, [modalOpen])

  useEffect(() => {
    if (imeis.length > 0) {
      if (!selectedImei) {
        selectImei(imeis[0].code)
      }
    }
  }, [imeis])

  const addImei = imei => {
    if (!imeiCodes.includes(imei.code)) {
      const newImeis = [...imeiScanneds, imei]
      imeiCodes.push(imei.code)
      imeiScanneds = newImeis
      setImeis(imeiScanneds)
    }
  }

  const selectImei = imei => {
    setSelectedImei(imei)
  }

  const init = async () => {
    imeiCodes = []
    imeiScanneds = []
    setSelectedImei('')
    setImeis([])
    try {
      Quagga.init(
        {
          inputStream: {
            type: 'LiveStream',
            target: scannerRef.current,
            constraints: {
              facingMode: 'environment', // or user
              width: 1920,
              height: 1080,
            },
          },
          locator: {
            patchSize: 'large', // x-small, small, medium, large, x-large
            halfSample: true,
          },
          numOfWorkers: 2,
          frequency: 10,
          decoder: {
            readers: [
              {
                format: 'code_128_reader',
                config: {},
              },
            ],
          },
          locate: true,
        },
        function(err) {
          if (err) {
            
            return
          }
          Quagga.start()
        },
      )

      Quagga.onProcessed(function(result) {
        var drawingCtx = Quagga.canvas.ctx.overlay,
          drawingCanvas = Quagga.canvas.dom.overlay
        if (result) {
          if (result.boxes) {
            drawingCtx.clearRect(
              0,
              0,
              parseInt(drawingCanvas.getAttribute('width')),
              parseInt(drawingCanvas.getAttribute('height')),
            )
            result.boxes
              .filter(function(box) {
                return box !== result.box
              })
              .forEach(function(box) {
                Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {
                  color: 'green',
                  lineWidth: 2,
                })
              })
          }

          if (
            result.codeResult &&
            result.codeResult.code &&
            !imeiCodes.includes(result.codeResult.code)
          ) {
            Quagga.ImageDebug.drawPath(
              result.line,
              {x: 'x', y: 'y'},
              drawingCtx,
              {color: 'red', lineWidth: 3},
            )
          }
        }
      })

      Quagga.onDetected(function(result) {
        debounce(() => onDetectedImei(result), 200)
      })

      return destroy
    } catch (ex) {
      
    }
  }

  const onDetectedImei = result => {
    if (!result?.codeResult) return
    var code = result.codeResult.code
    if (!imeiCodes.includes(code) && IMEIValid(code)) {
      var canvasPreview = document.createElement('canvas')
      var ctx = canvasPreview.getContext('2d')
      var image = new Image()

      // When the image has loaded, draw it to the canvas
      image.onload = function() {
        canvasPreview.width = image.width
        canvasPreview.height = image.height
        // draw image as background
        ctx.drawImage(image, 0, 0)

        addImei({
          code: code,
          preview: canvasPreview.toDataURL(),
        })
      }

      // Now set the source of the image that we want to load
      image.src = Quagga.canvas.dom.image.toDataURL()
    }
  }

  let interval
  const debounce = (cb, delay = 250) => {
    clearTimeout(interval)
    interval = setTimeout(() => {
      cb.call()
    }, delay)
  }

  const IMEIValid = imei => {
    if (isNaN(imei)) {
      return false
    }
    if (imei.length >= 14 && imei.length <= 17) {
      if (verifyCheckDigit(imei)) {
        return true
      }
    }
    return false
  }

  //https://en.wikipedia.org/wiki/International_Mobile_Equipment_Identity#Check_digit_computation
  function verifyCheckDigit(imei) {
    let checkDigit = imei.substring(imei.length - 1)
    let rest = imei.substring(0, imei.length - 1)
    let digits = rest.split('')
    let sum = 0
    for (let i = 0; i < digits.length; i++) {
      const digit = parseInt(digits[i])
      let numbers
      if ((i + 1) % 2 === 0) {
        let doubled = (digit * 2).toString()
        numbers = doubled.split('')
      } else {
        numbers = [digit]
      }

      for (let j = 0; j < numbers.length; j++) {
        const number = numbers[j]
        sum = sum + parseInt(number)
      }
    }

    if ((sum + parseInt(checkDigit)) % 10 === 0) {
      return true
    }
    return false
  }

  const destroy = () => {
    try {
      Quagga.stop()
    } catch (error) {}
  }

  // Export methods for call from parent component
  useImperativeHandle(ref, () => ({
    // Calling this method start scan
    startScan() {
      setModalOpen(true)
    },
    isValidImei(imei) {
      return IMEIValid(imei)
    },
    isValidImeiFormat(imei) {
      return verifyCheckDigit(imei)
    },
  }))

  const onSelectedItem = () => {
    onSelected(selectedImei)
    setModalOpen(false)
  }

  const RenderImei = ({code, preview, selected, imei, selectImei}) => {
    return (
      <li
        role='button'
        className={`imei-captured ${selected ? 'imei-captured--selected' : ''}`}
        onClick={() => selectImei(imei)}>
        <img src={preview} alt="" />
        <div style={{fontSize: '16px', textShadow: '2px 2px 2px rgba(0.5)'}}>
          {code}
        </div>
      </li>
    )
  }

  RenderImei.propType = {
    code: PropTypes.element.isRequired,
    preview: PropTypes.element.isRequired,
  }

  return (
    <>
      <Modal
        centered
        wrapClassName="container-scan"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        width={690}
        okButtonProps={{
          disabled: !selectedImei,
        }}
        closable={false}
        onOk={() => onSelectedItem()}
        footer={
          <DivFooterBotton>
            <ButtonNext
              background="white"
              border="green"
              onClick={() => setModalOpen(false)}>
              Cancelar
            </ButtonNext>
            <ButtonNext
              background="white"
              border="green"
              onClick={() => {
                setModalOpen(false)
                imeiCaptureRef.current.startScan()
              }}>
              Desde Imagen
            </ButtonNext>
            <ButtonNext
              background="green"
              border="white"
              disabled={!selectedImei}
              onClick={() => onSelectedItem()}>
              Aceptar
            </ButtonNext>
          </DivFooterBotton>
        }>
        <div className="scan-header">
          <p>Acerca el codigo de barras a la pantalla para su escaneo</p>
        </div>
        <div className="barcode-selector__wrapper">
          <div ref={scannerRef} className="component-barcode-scanner" />
          {imeis && imeis.length > 0 && (
            <ul className="barcode-selector__results">
              {imeis.map((imei, index) => (
                <RenderImei
                  key={index}
                  {...imei}
                  selected={selectedImei === imei.code}
                  imei={imei.code}
                  selectImei={selectImei}
                />
              ))}
            </ul>
          )}
        </div>
      </Modal>
      <ImeiCapture ref={imeiCaptureRef} onSelected={imei => onSelected(imei)} />
    </>
  )
})

ImeiScan.propType = {
  setImei: PropTypes.element.isRequired,
}

ImeiScan.defaultProps = {
  setImei: () => {},
}

ImeiScan.displayName = 'ImeiScan'

export default ImeiScan

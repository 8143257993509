import { CheckCircleFilled } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Modal } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AsideLogo from '../../components/Aside/AsideLogo'
import ButtonNext from '../../components/Button/ButtonNext'
import Text from '../../components/Typography/Text'
import {
  onboardingValidateAge,
} from '../../store/actions/phoneAction'
import { getActivityTypes } from '../../store/actions/requestCard.actions'
import {
  BRANCH_OFFICES,
  GENDER_TYPE,
  SELLER_ID_TYPE,
  SYSTEM_TYPES,
} from '../../utils/Constants'
import { openNotificationWithIcon } from '../../utils/Functions'
import { SCREENS } from './screens'

const IdentityConfirmed = props => {
  const [isVisible, setVisible] = useState(true)
  const [loading, showLoading] = useState(false)
  const [loadings, setLoading] = useState(false)
  const {
    form,
    countriesBirthTranslate,
    nationalitiesTranslate,
    onboardingId,
  } = useSelector(state => state.RequestCardReducer)
  async function nextStep() {
    setLoading(true)
    setLoading(false)
    props.goToStep(SCREENS.ApcTerms)
  }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getActivityTypes())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const dispacth = useDispatch()
  const onSuccess = success => {
    showLoading(false)
    if (success) {
      setVisible(false)
    } else {
      setVisible(true)
    }
  }

  const handleNext = () => {
    if (form?.isDocumentNotExpired === false) {
      openNotificationWithIcon(
        'warning',
        'No puedes continuar con el proceso porque tu documento de identidad está expirado.',
      )
    } else {
      handleUpdate()
    }
  }

  const handleUpdate = async () => {
    const payload = {
      id: onboardingId,
      typeIdentityId: '8F3C2EF0-F0D2-4FF0-9863-218D3D494D56',
      branchOfficeId: BRANCH_OFFICES.COCHEZ,
      sellerId: SELLER_ID_TYPE,
      approvedBy: SELLER_ID_TYPE,
      approvedDate: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
      genderId: form.gender === 1 ? GENDER_TYPE.MALE : GENDER_TYPE.FEMALE,
      countryId: form.country,
      countryBirthId: form.countryOfBirth,
      nationalityId: form.nationality,
      systemId: SYSTEM_TYPES.CMF,
      identityNumber: form.identity,
      firstName: form.firstName,
      secondName: form.secondName || null,
      firstSurname: form.lastName,
      secondSurname: form.secondLastName,
      dateOfBirth: form.birthdate,
      IDExpirationDate: form.IDExpirationDate,
      images: [
        {
          typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
          description: 'Cedula Frontal',
          link: form.frontIdentityImage,
          thumbnails: null,
          createdBy: 'CMF',
        },
        {
          typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
          description: 'Cedula Reverso',
          link: form.backIdentityImage,
          thumbnails: null,
          createdBy: 'CMF',
        },
        {
          typeImageId: 'A7398CE9-96CA-41AE-9513-AE233C6FEB02',
          description: 'Biometrico',
          link: form.faceImage,
          thumbnails: null,
          createdBy: 'CMF',
        },
      ],
      createdBy: 'CMF',
      LastModifiedBy: 'CMF',
    }
    showLoading(true)
    dispacth(onboardingValidateAge(payload, onSuccess.bind(this)))
  }

  const renderConfirmationModal = () => {
    const nacionalidad = nationalitiesTranslate.filter(function(value) {
      return value?.value?.toUpperCase() === form?.nationality?.toUpperCase()
    })
    const paisNacimiento = countriesBirthTranslate.filter(function(value) {
      return value?.value?.toUpperCase() === form?.countryOfBirth?.toUpperCase()
    })
    const nacimiento = paisNacimiento[0]?.label
    const nacionalidadData = nacionalidad[0]?.label

    const dateOfBirth = moment(form?.birthdate).format('DD-MMM-YYYY')
    let resNaci
    if (JSON.stringify(nacionalidad) == '[]') {
      resNaci = false
    }
    let res
    if (JSON.stringify(paisNacimiento) == '[]') {
      res = false
    }
    return (
      <StyleModal closable={false} centered open={isVisible} footer={false}>
        <ModalContainer>
          <CheckCircleFilled
            style={{
              marginTop: 24,
              paddingBottom: 16,
              fontSize: 35,
              color: 'var(--color-primary-main)',
            }}
          />
          <Text textType="subtitle" style={{fontSize: '1.4rem'}}>
            Confirma que tus datos son los correctos
          </Text>
          <InfoWrapper>
            <InfoData>
              <strong>Nombres: </strong>
              {form?.firstName ?? ""}{' '}
              {form?.secondName ?? ""}
            </InfoData>
            <InfoData>
              <strong> Apellidos: </strong>
              {form?.lastName ?? ""}{' '}
              {form?.secondLastName ?? ""}
            </InfoData>
            <InfoData>
              <strong> Número de cédula: </strong> {form?.identity}
            </InfoData>
            <InfoData>
              <strong> Fecha de nacimiento:</strong>
              {dateOfBirth ?? ""}
            </InfoData>
            {res === false ? (
              <div></div>
            ) : (
              <InfoData>
                <strong> País de nacimiento: </strong> {nacimiento}
              </InfoData>
            )}
            {resNaci === false ? (
              <div></div>
            ) : (
              <InfoData>
                <strong> Nacionalidad: </strong> {nacionalidadData}
              </InfoData>
            )}
          </InfoWrapper>
        </ModalContainer>

        <ButtonsContainer>
          <ButtonNext
            data-testid="yes-confirmed"
            background="green"
            border="green"
            loading={loading}
            style={{height: 60, width: 125, marginRight: 6}}
            onClick={handleNext}>
            Si
          </ButtonNext>
          <ButtonNext
            background="white"
            border="green"
            style={{height: 60, width: 125, marginLeft: 6}}
            onClick={() => {
              setVisible(true)
              props.goToStep(SCREENS.ScanID)
            }}>
            No
          </ButtonNext>
        </ButtonsContainer>
      </StyleModal>
    )
  }

  return (
    <>
      {renderConfirmationModal()}
      <Container>
        <Wrapper style={{flex: 0.2}}>
          <AsideLogo
            positionX="left"
            positionY="top"
            color="green"
            hugColor="#011E41"
          />
        </Wrapper>

        <Wrapper
          style={{
            flex: 1,
            background: 'rgb(41, 164, 56)',
            height: '100%',
            justifyContent: 'center',
          }}>
          <DivContainer>
            <Text textType="title" style={{color: 'var(--fondo-blanco)'}}>
              ¡Listo! Hemos verificado tu identidad
            </Text>
            <Text
              textType="paragraphs"
              style={{
                color: 'var(--fondo-blanco)',
                marginTop: 32,
                paddingBottom: 32,
              }}>
              Ahora puedes continuar con la solicitud de tu crédito CMF
            </Text>
            <ButtonNext
              background="white"
              border="white"
              loading={loadings}
              data-testid="next-confirmed"
              onClick={() => nextStep()}>
              Continuar
            </ButtonNext>
          </DivContainer>
        </Wrapper>
      </Container>
    </>
  )
}

const DivContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginBottom: 100,
}))

const StyleModal = styled(Modal)(() => ({
  '.ant-modal-content': {
    borderRadius: '0px !important',
  },
}))

const ModalContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const InfoWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: 16,
  paddingBottom: 24,
}))

const InfoData = styled('p')(() => ({
  fontSize: '1rem',
  textAlign: 'left',
  marginBottom: 8,
}))

const ButtonsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  paddingBottom: 45,
}))

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}))

export default IdentityConfirmed

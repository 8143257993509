import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
export default function Benefits() {
  return (
    <Box>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box sx={{flexGrow: 1}}>
            <Typography>
              <a
                style={{
                  color: '#667085',
                  fontFamily: 'Red Hat Text',
                  fontWeight: '700',
                }}>
                ✓
              </a>{' '}
              Precios especiales en productos seleccionados en{' '}
              <a
                href="https://www.novey.com.pa/ccrz__CCPage?pagekey=catalogos&cclcl=es"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: '#026E18',
                  fontFamily: 'Red Hat Text',
                  fontWeight: '700',
                  textDecorationLine: 'underline',
                }}>
                Novey
              </a>{' '}
              y{' '}
              <a
                href="https://www.cochezycia.com/ccrz__CCPage?pagekey=catalogos&cclcl=es"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: '#026E18',
                  fontFamily: 'Red Hat Text',
                  fontWeight: '700',
                  textDecorationLine: 'underline',
                }}>
                Cochez
              </a>
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box sx={{flexGrow: 1}}>
            <Typography>
              <a
                style={{
                  color: '#667085',
                  fontFamily: 'Red Hat Text',
                  fontWeight: '700',
                }}>
                ✓
              </a>{' '}
              Doble puntaje en
              <a
                href="https://www.novey.com.pa/ccrz__ProductList?categoryId=a0KDm000001o3PwMAI&cclcl=es&cartId=c891748c-78d5-449c-a8ef-d7c3cd054251"
                target="_blank"
                style={{
                  color: '#026E18',
                  fontFamily: 'Red Hat Text',
                  fontWeight: '700',
                  textDecorationLine: 'underline',
                }} rel="noreferrer">
                {' '}
                Puntos Gordos
              </a>
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box sx={{flexGrow: 1}}>
            <Typography>
              {' '}
              <a
                style={{
                  color: '#667085',
                  fontFamily: 'Red Hat Text',
                  fontWeight: '700',
                }}>
                ✓
              </a>{' '}
              Sin costo de membresía
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box sx={{flexGrow: 1}}>
            <Typography>
              <a
                style={{
                  color: '#667085',
                  fontFamily: 'Red Hat Text',
                  fontWeight: '700',
                }}>
                ✓
              </a>{' '}
              Financia tus compras en nuestros comercios aliados
              <a
                style={{
                  color: '#667085',
                  fontFamily: 'Red Hat Text',
                  fontWeight: '700',
                }}>
                {' '}
                Novey, Cochez
              </a>{' '}
              y{' '}
              <a
                style={{
                  color: '#667085',
                  fontFamily: 'Red Hat Text',
                  fontWeight: '700',
                }}>{`Kohler Signature Store by Cochez`}</a>
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box sx={{flexGrow: 1}}>
            <Typography>
              <a
                style={{
                  color: '#667085',
                  fontFamily: 'Red Hat Text',
                  fontWeight: '700',
                }}>
                ✓
              </a>{' '}
              Puedes realizar tus compras y pagarlas en cuotas fijas de{' '}
              <a
                style={{
                  color: '#667085',
                  fontFamily: 'Red Hat Text',
                  fontWeight: '700',
                }}>
                {' '}
                6
              </a>{' '}
              a{' '}
              <a
                style={{
                  color: '#667085',
                  fontFamily: 'Red Hat Text',
                  fontWeight: '700',
                }}>
                {' '}
                36
              </a>{' '}
              meses
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Box>
  )
}

import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { BreakPointTheme } from 'src/theme/breakpoints';

const ButtonMainOK = styled(LoadingButton)(({style = {}}) => ({
  height: '7vh',
  maxHeight: '60px',
  width: '274px',
  font: '600 normal normal 25px/26px Red Hat Text',
  fontSize: '25px',
  textDecoration: 'none',
  borderRadius: '38px',
  fontFamily: 'Red Hat Text',
  textTransform: 'none',
  '&:disabled': {
    background: 'transparent linear-gradient(270deg, #D0D0D0 0%, #B0B0B0 100%)', // Cambia el color de fondo para el estado deshabilitado
    color: '#A0A0A0', // Cambia el color del texto para el estado deshabilitado
    cursor: 'not-allowed', // Cambia el cursor para indicar que el botón está deshabilitado
    opacity: 0.5, // Ajusta la opacidad si es necesario
  },
  [BreakPointTheme.breakpoints.down('xl')]: {
    width: '244px',
    height: '3.5rem',
    fontSize: '20px',
  },
  [BreakPointTheme.breakpoints.down('lg1')]: {
    width: '200px',
    height: '3.5rem',
    fontSize: '1rem',
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    width: '200px',
    height: '56px',
    fontSize: '1rem',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    width: '22vw',
    height: '60px',
    fontSize: '1.2rem',
    marginBottom: '2rem',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '90%',
    height: '54px',
    maxHeight: '60px',
    fontSize: '1.2rem',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  [BreakPointTheme.breakpoints.down('ax')]: {
    width: '90%',
    height: '54px',
    fontSize: '1.2rem',
    marginBottom: '1rem',
    marginTop: '0.5rem',
  },
  ...style,
}));
const ButtonNextOnboarding = props => {
  return (
    <ButtonMainOK onClick={props.onClick} {...props}>
      {props.children}
    </ButtonMainOK>
  );
};
export default ButtonNextOnboarding;

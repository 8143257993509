/**
 * @flow
 */
import produce from 'immer';
import {
  GET_GENERAL_INFORMATION_SUCCESS,
  GET_GENERAL_INFORMATION_ERROR,
  GET_GENERAL_INFORMATION_LOADING_SHOW,
  GET_GENERAL_INFORMATION_LOADING_HIDE,
  GET_FINANCIAL_INSTITUTION_SUCCESS,
} from '../../constrants/reducers';

export type initStateProps = {
  TableProductsInfo: any,
  error: string,
  Loading: boolean,
  headers: any,
};

const initState: initStateProps = {
  TableProductsInfo: {},
  error: '',
  Loading: false,
  headers: {},
  FinancialInstitution: [],
  loadPasskit: false,
};

const financial = (state: initStateProps = initState, action: any) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_GENERAL_INFORMATION_LOADING_SHOW:
        draft.Loading = true;
        break;
      case GET_GENERAL_INFORMATION_LOADING_HIDE:
        draft.Loading = false;
        break;
      case GET_GENERAL_INFORMATION_SUCCESS:
        draft.TableProductsInfo = action.payload;
        draft.headers = action.headers;
        break;
      case GET_GENERAL_INFORMATION_ERROR:
        draft.error = action.payload;
        break;
      case GET_FINANCIAL_INSTITUTION_SUCCESS:
        draft.FinancialInstitution = action.payload;
        break;
      case 'LOAD_PASSKITSEND':
        draft.loadPasskit = true;
        break;
      case 'HIDE_PASSKITSEND':
        draft.loadPasskit = false;
        break;
      default:
        return state;
    }
  });

export default financial;

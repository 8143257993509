import { Box } from "@mui/material";
import React from "react";

function Icon() {
  return <Box sx={{display: 'flex', justifyContent: 'center'}}>
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
      <path d="M31.9997 5.33398C17.2797 5.33398 5.33301 17.2807 5.33301 32.0007C5.33301 46.7207 17.2797 58.6673 31.9997 58.6673C46.7197 58.6673 58.6663 46.7207 58.6663 32.0007C58.6663 17.2807 46.7197 5.33398 31.9997 5.33398ZM24.773 43.4407L15.1997 33.8673C14.1597 32.8273 14.1597 31.1473 15.1997 30.1073C16.2397 29.0673 17.9197 29.0673 18.9597 30.1073L26.6663 37.7873L45.013 19.4407C46.053 18.4007 47.733 18.4007 48.773 19.4407C49.813 20.4807 49.813 22.1607 48.773 23.2007L28.533 43.4407C27.5197 44.4807 25.813 44.4807 24.773 43.4407Z" fill="#D9D9D9"/>
      <path d="M31.9997 5.33398C17.2797 5.33398 5.33301 17.2807 5.33301 32.0007C5.33301 46.7207 17.2797 58.6673 31.9997 58.6673C46.7197 58.6673 58.6663 46.7207 58.6663 32.0007C58.6663 17.2807 46.7197 5.33398 31.9997 5.33398ZM24.773 43.4407L15.1997 33.8673C14.1597 32.8273 14.1597 31.1473 15.1997 30.1073C16.2397 29.0673 17.9197 29.0673 18.9597 30.1073L26.6663 37.7873L45.013 19.4407C46.053 18.4007 47.733 18.4007 48.773 19.4407C49.813 20.4807 49.813 22.1607 48.773 23.2007L28.533 43.4407C27.5197 44.4807 25.813 44.4807 24.773 43.4407Z" fill="url(#paint0_linear_380_38245)"/>
      <defs>
        <linearGradient id="paint0_linear_380_38245" x1="13.7318" y1="45.695" x2="50.3302" y2="11.8363" gradientUnits="userSpaceOnUse">
          <stop stopColor="#026E18"/>
          <stop offset="0.70961" stopColor="#55AC00"/>
          <stop offset="1" stopColor="#93D500"/>
        </linearGradient>
      </defs>
    </svg>
  </Box>
}

export default Icon;

import {
  RESET_STATE,
  SET_ALL_MOVEMENTS,
  SET_ERROR_MOVEMENTS,
  SET_LOADING_DATA_ACCOUNT,
  SET_LOADING_MOVEMENTS,
  SET_PRODUCT_CORPORATE,
  SET_PRODUCT_DATA,
  SET_SELECTED_PRODUCT
} from './../actions/actions';

const initialState = {
  selectedProduct: null,
  productData: {},
  productCorporate: [],
  movementsList: [],
  loadingMovements: false,
  loadingDataAccount: false,
  errorMovements: false,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload,
      };
    case SET_PRODUCT_DATA:
      return {
        ...state,
        productData: {
          ...state.productData,
          [action.payload.productId]: action.payload.data,
        },
      };
    case SET_PRODUCT_CORPORATE:
      return {
        ...state,
        productCorporate: action.payload.data,
      };
    case SET_ALL_MOVEMENTS:
      return {
        ...state,
        movementsList: action.payload.data,
      };
    case SET_LOADING_MOVEMENTS:
      return {
        ...state,
        loadingMovements: action.payload.data,
      };
    case SET_LOADING_DATA_ACCOUNT:
      return {
        ...state,
        loadingDataAccount: action.payload.data,
      };
    case SET_ERROR_MOVEMENTS:
      return {
        ...state,
        errorMovements: action.payload.data,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default productReducer;

import { TextField, Typography, styled } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mailCMF from 'src/assets/images/cmf_linea/mailLoginCMF.svg';
import { ResetPasswordSendEmail } from 'src/store/actions/LoginAction';
import * as yup from 'yup';
import '../../../App.css';
import * as reset from './resetPassword.style';
const RedditTextField = styled(props => (
  <TextField InputProps={{disableUnderline: true}} {...props} />
))(({theme}) => ({
  '& .MuiInputAdornment-root': {
    marginTop: '0!important',
  },
  '& .MuiFormLabel-root-MuiInputLabel-root': {
    left: '30px',
  },
  '& .MuiInputBase-input-MuiFilledInput-input': {
    paddingLeft: '3px !important',
  },

  '& .MuiInputLabel-root': {
    position: 'absolute',
    left: '30px',
    marginLeft: '14px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
  },
  '& .MuiFilledInput-root': {
    // display: "flex",
    // height: "56px",
    // padding: "0px 16px 0px 4px",
    // flexDirection: "column",
    // alignItems: "flex-start",
    // alignSelf: "stretch",
    borderRadius: '16px',
    border: '1px solid var(--specific-light-stroke-l, #D0D5DD)',
    background: 'var(--specific-light-background-white, #FFF)',

    overflow: 'hidden',
    // borderRadius: 4,
    // backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
    // border: '1px solid',
    // borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}))

const SendEmailReset = ({onSuccess, email}) => {
  const dispatch = useDispatch()
  const {ResetLoading} = useSelector(state => state.AuthenticationReducer)
  const validationSchemaBank = yup.object({
    email: yup
      .string('Introduce tu correo')
      .required('Campo requerido')
      .matches(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
        'Correo Electrónico no es válido',
      ),
  })
  const formikEmail = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchemaBank,
    onSubmit: values => {
      email(values.email)
      dispatch(ResetPasswordSendEmail(values.email, onSuccess))
    },
  })

  return (
    <>
      <reset.text>
        <Typography variant="titleLogin">Crear / Cambiar mi contraseña</Typography>
      </reset.text>
      <reset.textReset>
        <Typography variant="textGray">
        Ingresa tu correo electrónico registrado en CMF. Es el mismo que usaste para solicitar la tarjeta digital CMF.
        </Typography>
      </reset.textReset>
      <form onSubmit={formikEmail.handleSubmit} style={{width: '100%'}}>
        <reset.ContainerInput style={{marginTop: '5px'}}>
          <reset.textReset></reset.textReset>
          <RedditTextField
            disableUnderline
            fullWidth
            id="reddit-inputs"
            variant="filled"
            style={{marginTop: 11, marginBottom: 24}}
            type="text"
            name="email"
            label="Correo electrónico"
            placeholder="nombre@gmail.com"
            value={formikEmail.values.email}
            onChange={formikEmail.handleChange}
            onBlur={formikEmail.handleBlur}
            error={
              formikEmail.touched.email && Boolean(formikEmail.errors.email)
            }
            helperText={formikEmail.touched.email && formikEmail.errors.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={mailCMF}
                    style={{width: '1.5rem', margin: '0px 8px'}}
                    alt="Email"
                  />
                </InputAdornment>
              ),
            }}
          />
        </reset.ContainerInput>
        <reset.ContainerButton>
          <reset.ButtonReset
            loading={ResetLoading}
            variant="contained"
            type="submit">
            Continuar
          </reset.ButtonReset>
        </reset.ContainerButton>
      </form>
    </>
  )
}
export default SendEmailReset

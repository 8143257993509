/**
 * @flow
 */
import produce from 'immer';
import {
  GET_BALANCE_SUCCESS,
  GET_BALANCE_ERROR,
  GET_BALANCE_LOADING_SHOW,
  GET_BALANCE_LOADING_HIDE,
} from '../../constrants/reducers';

export type initStateProps = {
  BalanceInfo: any,
  error: string,
  Loading: boolean,
};

const initState: initStateProps = {
  BalanceInfo: {},
  error: '',
  Loading: false,
};

const balance = (state: initStateProps = initState, action: any) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_BALANCE_LOADING_SHOW:
        draft.Loading = true;
        break;
      case GET_BALANCE_LOADING_HIDE:
        draft.Loading = false;
        break;
      case GET_BALANCE_SUCCESS:
        draft.BalanceInfo = action.payload;
        break;
      case GET_BALANCE_ERROR:
        draft.error = action.err;
        break;
      default:
        return state;
    }
  });

export default balance;

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Stack, Typography, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import floatingMacBookMockup from 'src/assets/images/cmf_linea/floatingMacBookMockup.png';
import floatingPhone from 'src/assets/images/cmf_linea/flyingPhoneMockup.png';
import TitleResponsive from 'src/components/TitleInput/SubtitleResponsive';
import { BreakPointTheme } from 'src/theme/breakpoints';
import { PRODUCTS_TYPES, URL_APP } from 'src/utils/Constants';
import { ContainerMaterial } from '../Pages/ContainerHome';
const Title1 = styled('div')(() => ({
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  gap: '2.06rem',
  zIndex: 0,
}));

const TextButton = styled('div')(() => ({
  borderRadius: 'var(--br-5xl)',
  backgroundColor: 'var(--specific-light-background-white)',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: '0.38rem 0.88rem',
  gap: 'var(--gap-5xs)',
  zIndex: 1,
  fontSize: 'var(--detail-m-size)',
  color: 'var(--specific-light-content-black)',
  position: 'absolute',
  bottom: '15px',
  [BreakPointTheme.breakpoints.down('sm')]: {
    padding: '0',
  },
}));
const Image61Icon = styled('img')(() => ({
  position: 'absolute',
  margin: '0 !important',
  top: '1rem',
  right: '0',
  width: '261.27px',
  height: '230.67px',
  objectFit: 'cover',
  transformOrigin: 'center',
  transform: 'rotate(-4.817deg)',
  zIndex: 2,
  [BreakPointTheme.breakpoints.up('lg')]: {
    width: 'auto',
    height: '230.67px',
    right: '-7%',
  },
  [BreakPointTheme.breakpoints.up('xl')]: {
    width: 'auto',
    height: 'inherit',
    right: '-10%',
  },
  [BreakPointTheme.breakpoints.between('sm', 'md')]: {
    width: 'auto',
    height: 'inherit',
    right: '-22%',
  },
  [BreakPointTheme.breakpoints.between('md', 'lg')]: {
    width: 'auto',
    height: 'inherit',
    right: '-9%',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    position: 'absolute',
    margin: '0 !important',
    top: '-3rem',
    right: '-5%',
    width: 'auto',
    height: '90%',
    objectFit: 'cover',
    zIndex: 2,
    transformOrigin: 'center',
    transform: 'rotate(-4.817deg)',
  },
}));

const Image62Icon = styled('img')(() => ({
  position: 'absolute',
  margin: '0 !important',
  top: '90px',
  left: '50px',
  objectFit: 'cover',
  zIndex: 3,
  [BreakPointTheme.breakpoints.up('xl')]: {
    top: '90px',
    width: 'auto',
    height: '80%',
    left: '50px',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    top: '20px',
    left: '10px',
    height: '70%',
  },
}));

const InfoTitle = styled('div')(() => ({
  position: 'relative',
  letterSpacing: '-0.02em',
  lineHeight: '1.5rem',
  fontWeight: 500,
  display: 'inline-block',
  width: '15.81rem',
  flexShrink: 0,
}));

const Funciones = styled('div')(() => ({
  top: '11rem',
  left: '21.6rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  fontSize: 'var(--heading-s-medium-size)',
  color: 'var(--specific-light-background-white)',
  [BreakPointTheme.breakpoints.between('xxs', 'sm')]: {
    width: '100%',
    margin: '0',
  },
}));
const ContainerImages = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  position: 'absolute',
  width: '400px',
  right: '10%',
  [BreakPointTheme.breakpoints.between('xs', 'sm', 'md')]: {
    width: '335px',
    right: '35px',
    bottom: '0',
  },
  [BreakPointTheme.breakpoints.between('sm', 'md')]: {
    width: '335px',
    right: '15%',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: '335px',
    //
  },
}));
const InfoCard = styled('div')(() => ({
  flex: 1,
  borderRadius: '20px',
  background: `linear-gradient(to top right, #388d00, #71ba01)`,
  height: '24.75rem',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: '16px',
  boxSizing: 'border-box',
  position: 'relative',
  [BreakPointTheme.breakpoints.up('xl')]: {
    height: '21.75rem',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    width: '90vw',
  },
}));
const Row = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  [BreakPointTheme.breakpoints.between('xs', 'sm')]: {
    flexDirection: 'column',
  },
}));

const Column = styled('div')(() => ({
  width: '50%',
  height: '100%',
}));

const BoxPhoneFloating = styled('div')(() => ({
  height: '178.29px',
  width: '178.29px',
}));

const BoxDesktopFloating = styled('div')(() => ({
  height: '230.67px',
  width: '261.27px',
}));

const PromocionesCMF = () => {
  const [style, setStyle] = useState({});
  const {selectedProduct} = useSelector(state => state.ActiveProductReducer);
  const {isCorporate, productTypeId} = useSelector(
    state => state.ActiveProductReducer.selectedProduct,
  );
  const ref = useRef({x: 0, y: 0});

  const lerp = (start, end, amt) => {
    return (1 - amt) * start + amt * end;
  };
  const handleMouseMove = event => {
    const {clientX, clientY, currentTarget} = event;
    const {left, top, width, height} = currentTarget.getBoundingClientRect();
    const x = (clientX - (left + width / 2)) / 20;
    const y = (top + height / 2 - clientY) / 20;

    // Interpolar hacia el nuevo valor
    ref.current.x = lerp(ref.current.x, x, 0.1);
    ref.current.y = lerp(ref.current.y, y, 0.1);

    setStyle({
      transform: `translateX(${ref.current.x}px) translateY(${-ref.current
        .y}px)`,
      transition: 'transform 0.2s cubic-bezier(0, 0, 0, 1.03) 0s',
    });
  };
  const handleMouseLeave = () => {
    setStyle({
      transform: 'none',
      transition: 'transform 0.2s cubic-bezier(0, 0, 0, 1.03) 0s',
    });
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (isCorporate && selectedProduct?.productTypeId?.toUpperCase() !== PRODUCTS_TYPES.PREPP) {
      navigate('/home');
    }
  }, [selectedProduct]);

  return (
    <ContainerMaterial maxWidth="xl">
      <Stack
        spacing={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          [BreakPointTheme.breakpoints.only('xxs')]: {
            width: '97%',
            margin: '5px',
          },
          [BreakPointTheme.breakpoints.only('sm')]: {
            width: '80%',
            margin: '5px',
          },
          [BreakPointTheme.breakpoints.only('md')]: {
            width: '100%',
          },
          [BreakPointTheme.breakpoints.only('lg')]: {
            width: '75%',
          },
          [BreakPointTheme.breakpoints.up('lg')]: {
            width: '65%',
          },
        }}>
        <TitleResponsive
          fontSize="24px"
          text="Promociones"
          color="#101828CC"
          textAlign="left"
          lineHeight="28px"
          fontWeitgh={500}
        />
        <Box>
          <Funciones
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}>
            <InfoCard>
              <Row>
                <Column>
                  <Title1>
                    <InfoTitle>
                      <TitleResponsive
                        fontSize="24px"
                        text="Mira todas las Promociones en nuestro sitio web"
                        color="#FFFFFF"
                        textAlign="left"
                        letterSpacing="-2%"
                        lineHeight="28px"
                        fontWeitgh={500}
                      />
                    </InfoTitle>
                  </Title1>
                  <TextButton>
                    <Button
                      component="a"
                      href={URL_APP.referral}
                      target="_blank"
                      sx={{
                        borderRadius: '24px',
                        [BreakPointTheme.breakpoints.down('sm')]: {
                          height: '30px',
                          width: '132px',
                          padding: '0',
                        },
                      }}
                      variant="customVariant"
                      endIcon={
                        <ArrowForwardIcon
                          sx={{
                            color: 'primary.dark',
                          }}
                        />
                      }>
                      <Typography variant="H6">Visítalo aquí</Typography>
                    </Button>
                  </TextButton>
                </Column>
                <Column style={{margin: '0 50px 0 0'}}>
                  <ContainerImages
                    style={{...style, transition: 'transform 0.2s ease-out'}}>
                    <BoxPhoneFloating>
                      <Image62Icon alt="" src={floatingPhone} />
                    </BoxPhoneFloating>
                    <BoxDesktopFloating>
                      <Image61Icon alt="" src={floatingMacBookMockup} />
                    </BoxDesktopFloating>
                  </ContainerImages>
                </Column>
              </Row>
            </InfoCard>
          </Funciones>
        </Box>
        {/* <TitleResponsive
          fontSize="16px"
          text="Pasos para agregar tu cupón de descuento al comprar"
          color="#000"
          textAlign="left"
          fontWeitgh={500}
        />
        <Box
          sx={{
            display: 'flex',
            [BreakPointTheme.breakpoints.between('sm', 'md')]: {
              flexWrap: 'wrap',
            },
            [BreakPointTheme.breakpoints.down('sm')]: {
              flexWrap: 'wrap',
            },
          }}>
          <CardPromociones
            image={searchPromo}
            text={textoUrl}
            url="https://www.cochezycia.com/"
            urlLink="https://www.novey.com.pa/"
            textUrl="cochezycia.com"
            textLink="novey.com.pa"
          />

          <CardPromociones
            image={shopping}
            text="Agrega tu productos al carrito de compras"
          />
          <CardPromociones
            image={discount}
            text="En la parte inferior izquierda agrega el código del cupón"
          />
          <CardPromociones
            image={moneyCheck}
            text="Tu descuento se cargará al valor total de tu compra"
          />
        </Box> */}
      </Stack>
    </ContainerMaterial>
  );
};

export default PromocionesCMF;

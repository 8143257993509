import SupportScreen from 'src/containers/OnboardingCredito/Component/SupportScreen';
import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

// Styled components
const Heading = styled.span`
  @media (min-width: 1024px) {
    font-size: 40px;
  }
`;

const Title = styled.span`
  @media (min-width: 1024px) {
    font-size: 34px;
  }
`;

const Subtitle = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }
  .span {
    font-size: 20px;
  }
`;
/**
 * React component for the Request In Revision screen.
 * This component displays a support screen with a heading, title, subtitle, and a button.
 * It also includes a function to handle the "Salir" button click event.
 */
const PrepagoRequestInRevision = () => {
  const {maxDay} = useSelector(state => state.RequestCardReducer);

  /**
   * Function to handle the "Salir" button click event.
   * It creates a new link element, sets its href and target attributes,
   * appends a text node as its child, and triggers a click event on the link.
   */
  const onConfirm = () => {
    const URL_CMF_PAYMENT_METHOD =
      'https://www.cmfinanciera.com/s/formas-de-pago';
    const link = document.createElement('a');
    link.href = URL_CMF_PAYMENT_METHOD;
    link.target = '_blank';
    const linkText = document.createTextNode('Salir');
    link.appendChild(linkText);
    link.click();
  };

  return (
    <SupportScreen
      showDiv={true}
      buttonText="Salir"
      heading={<Heading>¡Gracias por tu preferencia!</Heading>}
      title={
        <Title>
          Tu solicitud de tarjeta prepago será revisada para aprobación
        </Title>
      }
      subtitle1={
        <Subtitle>
          <span>
            Basados en la información que nos has proporcionado, revisaremos tu
            solicitud y{' '}
          </span>
          <span>
            te estaremos contactando en las siguientes {maxDay} horas hábiles.
          </span>
        </Subtitle>
      }
      onConfirm={onConfirm}
    />
  );
};

export default PrepagoRequestInRevision;

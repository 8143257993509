import React from 'react'
import styled from '@emotion/styled'

const Title = styled('h1')(({textType}) => ({
  all: 'unset',
  textAlign: 'center',
  fontFamily: 'Gilroy',
  ...(textType === 'title' && {
    color: 'var(--color-primary-main)',
    fontSize: '2.5rem',
  }),
  ...(textType === 'highlight' && {
    color: 'var(--fondo-blanco)',
    fontSize: '4rem',
    paddingLeft: '12%',
    paddingRight: '12%',
    lineHeight: 1.4,
    background: 'var(--color-primary-main)',
  }),
  ...(textType === 'subtitle' && {
    color: 'var(--color-primary-main)',
    fontSize: '2rem',
  }),
}))

const Paragraphs = styled('p')(() => ({
  textAlign: 'center',
  fontFamily: 'Gilroy-Light',
  fontSize: '1.5rem',
  color: 'black',
}))
const ParagraphsBold = styled('p')(() => ({
  textAlign: 'center',
  fontFamily: 'Nunito',
  fontSize: '18px',
  color: 'black',
  fontWeight:'bold'
}))
export default ({textType, style, children}) => {
  const renderText = () => {
    switch (textType) {
      case 'title':
      case 'subtitle':
      case 'highlight':
        return (
          <Title style={style} textType={textType}>
            {children}
          </Title>
        )

      case 'paragraphs':
        return <Paragraphs style={style}>{children}</Paragraphs>
      case 'paragraphs_bold':
        return <ParagraphsBold style={style}>{children}</ParagraphsBold>
      default: return <span>{children}</span>
    }
  }
  return renderText()
}

import React, { forwardRef, useState } from 'react'
import ModalCommon from '../../../components/Modal/ModalCommon'
import "../../../App.css";

const ModalProcessing = forwardRef(
  ({onConfirm, onClose, headingText, bodyText}, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(true)

    const show = () => {
      setIsModalOpen(true)
    }

    return (
      <ModalCommon
        isOpen={isModalOpen}
        // onHide={hide}
        onShow={show}
        ref={ref}
        modalClassName={{
          overlay: 'bg-[rgba(0,0,0,0.8)]',
          modal:
            'rounded-xl shadow-4 -3 7 0 border border-2 border-gray-300 relative w-[90%] max-w-[486px] md:h-[303px] m-0',
          closeButton: 'hidden',
        }}
        showBackButton={false}
        showCloseButton={false}
        bodyClassName="my-8 flex flex-col items-center justify-center">
        <div className="w-full md:w-[80%] flex items-center justify-center flex-col ">
          <div className="m-4">
            <div className="dot-bricks"></div>
          </div>
          <span className="text-[#011E41] text-lg font-bold mt-[12px] md:mt-[26px] text-center">
            {headingText}
          </span>
          <span className="text-[#011E41] text-lg font-bold text-center">
            {bodyText}
          </span>
        </div>
      </ModalCommon>
    )
  },
)

export default ModalProcessing

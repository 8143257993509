import { Modal } from 'antd'
import 'cropperjs/dist/cropper.css'
import PropTypes from 'prop-types'
import Quagga from 'quagga'
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react'
import { Cropper } from 'react-cropper'
import styled from 'styled-components'
import ButtonNext from '../Button/ButtonNext'
import './index.css'

const cropperOptions = {
  style: {height: 400, width: '100%'},
  zoomTo: 0.5,
  initialAspectRatio: 5 / 1,
  preview: '',
  viewMode: 1,
  minCropBoxHeight: 50,
  minCropBoxWidth: 100,
  background: false,
  responsive: true,
  autoCropArea: 1,
  checkOrientation: true,
  guides: true,
  cropBoxResizable: false,
  dragMode: 'move', // none, move
  cropBoxMovable: false,
}

const DivFooterBotton = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignContent: 'center',
  width: '100%',
  margin: 'auto',
}))

var imeiCodes = []
var imeiScanneds = []

const ImeiCapture = forwardRef((props, ref) => {
  const cropperRef = useRef(null)
  const fileInputRef = useRef(null)
  const [cropOpen, setCropOpen] = useState(false)
  const [imeis, setImeis] = useState([])
  const [cropper, setCropper] = useState()
  const [image, setImage] = useState('')
  const [width, setWidth] = useState(1)
  const [selectedImei, setSelectedImei] = useState([])

  const {onSelected} = props

  useEffect(() => {
    if (imeis.length > 0) {
      if (!selectedImei) {
        selectImei(imeis[0].code)
      }
    }
  }, [imeis])

  const addImei = imei => {
    if (!imeiCodes.includes(imei.code)) {
      const newImeis = [...imeiScanneds, imei]
      imeiCodes.push(imei.code)
      imeiScanneds = newImeis
      setImeis(imeiScanneds)
    }
  }

  const selectImei = imei => {
    setSelectedImei(imei)
  }

  const init = async () => {
    imeiCodes = []
    imeiScanneds = []
    setSelectedImei('')
    setImeis([])

    const fileInput = document.createElement('input')
    fileInput.id = Math.random()
    fileInput.type = 'file'
    fileInput.accept = 'image/*'
    fileInput.capture = ''
    fileInput.style.position = 'fixed'
    document.body.appendChild(fileInput)
    fileInput.onchange = function(event) {
      selectImage(event.target.files)
      document.body.removeChild(fileInput)
    }
    fileInput.click()
  }

  const selectImage = async files => {
    if (files.length > 0) {
      setCropOpen(true)
      const file = files[0]
      const fileURL = URL.createObjectURL(file)
      setImage(fileURL)
      processImage(fileURL)
    }
  }

  const processImage = fileURL => {
    debounce(() => {
      try {
        Quagga.decodeSingle(
          {
            decoder: {
              readers: ['code_128_reader'], // List of active readers
            },
            /*inputStream: {
                size: 1920, // restrict input-size to be 800px in width (long-side)
              },*/
            locate: true, // try to locate the barcode in the image
            src: fileURL, // or 'data:image/jpg;base64,' + data
          },
          function(result) {
            if (result?.codeResult) {
              if (IMEIValid(result.codeResult.code)) {
                addImei({
                  code: result.codeResult.code,
                  preview: fileURL,
                })
              }
            }
          },
        )
      } catch (error) {
        
      }
    }, 500)
  }
  let interval
  const debounce = (cb, delay = 250) => {
    clearTimeout(interval)
    interval = setTimeout(() => {
      cb.call()
    }, delay)
  }

  const IMEIValid = imei => {
    if (isNaN(imei)) {
      return false
    }
    if (imei.length >= 15 && imei.length <= 17) {
      if (verifyCheckDigit(imei)) {
        return true
      }
    }
    return false
  }

  //https://en.wikipedia.org/wiki/International_Mobile_Equipment_Identity#Check_digit_computation
  function verifyCheckDigit(imei) {
    let checkDigit = imei.substring(imei.length - 1)
    let rest = imei.substring(0, imei.length - 1)
    let digits = rest.split('')
    let sum = 0
    for (let i = 0; i < digits.length; i++) {
      const digit = parseInt(digits[i])
      let numbers
      if ((i + 1) % 2 === 0) {
        let doubled = (digit * 2).toString()
        numbers = doubled.split('')
      } else {
        numbers = [digit]
      }

      for (let j = 0; j < numbers.length; j++) {
        const number = numbers[j]
        sum = sum + parseInt(number)
      }
    }

    if ((sum + parseInt(checkDigit)) % 10 === 0) {
      return true
    }
    return false
  }

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper
    processImage(cropper.getCroppedCanvas().toDataURL())
  }

  // Export methods for call from parent component
  useImperativeHandle(ref, () => ({
    // Calling this method start scan
    startScan() {
      init()
    },
  }))

  const onSelectedItem = () => {
    onSelected(selectedImei)
    setCropOpen(false)
  }

  const RenderImei = ({code, preview, selected, imei, selectImei}) => {
    return (
      <li
        role='button'
        className={`imei-captured ${selected ? 'imei-captured--selected' : ''}`}
        onClick={() => selectImei(imei)}>
        <img src={preview} alt="" />
        <div style={{fontSize: '16px', textShadow: '2px 2px 2px rgba(0.5)'}}>
          {code}
        </div>
      </li>
    )
  }

  RenderImei.propType = {
    code: PropTypes.element.isRequired,
    preview: PropTypes.element.isRequired,
  }

  return (
    <Modal
      centered
      wrapClassName="container-scan"
      open={cropOpen}
      onCancel={() => setCropOpen(false)}
      width={1024}
      okButtonProps={{
        disabled: imeis.length === 0,
      }}
      closable={false}
      footer={
        <DivFooterBotton>
          <ButtonNext
            background="white"
            border="green"
            onClick={() => setCropOpen(false)}>
            Cancelar
          </ButtonNext>
          <ButtonNext
            background="green"
            border="white"
            disabled={!selectedImei}
            onClick={() => onSelectedItem()}>
            Aceptar
          </ButtonNext>
        </DivFooterBotton>
      }>
      <div className="scan-header">
        <p>Acerca el codigo de barras a la pantalla para su escaneo</p>
      </div>
      <div className="barcode-selector__wrapper">
        <Cropper
          ref={cropperRef}
          style={{height: 400, width: '100%'}}
          zoomTo={width / 100}
          initialAspectRatio={cropperOptions.initialAspectRatio || 3 / 4}
          preview=""
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={instance => {
            setCropper(instance)
          }}
          guides={false}
          center={false}
          cropBoxResizable={cropperOptions.cropBoxResizable}
          dragMode={cropperOptions.dragMode}
          crop={onCrop}
          cropBoxMovable={cropperOptions.cropBoxMovable}
        />
        {imeis && imeis.length > 0 && (
          <ul className="barcode-selector__results">
            {imeis.map((imei, index) => (
              <RenderImei
                key={index}
                {...imei}
                selected={selectedImei === imei.code}
                imei={imei.code}
                selectImei={selectImei}
              />
            ))}
          </ul>
        )}
      </div>
    </Modal>
  )
})

ImeiCapture.propType = {
  setImei: PropTypes.element.isRequired,
}

ImeiCapture.defaultProps = {
  setImei: () => {},
}

ImeiCapture.displayName = 'ImeiCapture'

export default ImeiCapture

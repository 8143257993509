import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import PropTypes from 'prop-types'
import * as React from 'react'
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{display: 'flex', height: '3rem'}}>
      <Box
        sx={{
          textAlign: 'center',
          maxWidth: '100%',
          width: '12rem',
          margin: '1rem',
        }}>
        <LinearProgress
          variant="determinate"
          sx={{
            height: '.5rem',
            borderRadius: '1rem',
          }}
          {...props}
        />
      </Box>
    </Box>
  )
}

export default LinearProgressWithLabel
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
}

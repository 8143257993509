import React from 'react'
import {CheckCircleFilled} from '@ant-design/icons'
import {Input} from 'antd'
import styled from '@emotion/styled'
import './index.css'

const InputStyled = styled(Input)(() => ({
  all: 'unset',
  width: '100%',
  height: 18,
  padding: 14,
  borderColor: '#aaa',
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 3,
  backgroundColor: '#fff',
  flexGrow: 1,
  display: 'inline-flex',
  fontFamily: 'Gilroy-Light'
}))

export default function InputWithIcon(props) {
  return (
    <InputStyled
      className="input--with-icon"
      type="text"
      value={props.value}
      onChange={e => props.onChange(e.target.value)}
      suffix={
        <CheckCircleFilled
          style={{
            color: '#3DAE2B',
            fontSize: '30px',
          }}
        />
      }
      {...props}
    />
  )
}

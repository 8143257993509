import {keyframes} from '@emotion/react';

export const BORDER_RADIUS = '7px';

export const BASE_COLORS = {
  background: '#F2F4F7',
  white: '#ffffff',
  black: '#000000',
  green: '#479c00',
  darkgreen: '#026E18',
  orange: '#ffb155',
  gray: '#646974',
  lightgrey: '#c5d3e0',
  violet: '#ee82ee',
  lightgreen: '#89dca0',
  pink: '#ffc0cb',
  blue: '#0000ff',
  skyblue: '#35a0dc',
  red: '#ff5252',
};

export const LAYOUT = {
  mobile: {
    paddingVertical: '0.75rem',
    paddingHorizontal: '1rem',
    headerHeight: '3.5rem',
    headerPadding: '1rem',
  },
  desktop: {
    paddingVertical: '1.25rem',
    paddingHorizontal: '2rem',
    headerHeight: '5.625rem',
    paddingForm: '0vh 4vh 0px 4vh',
    paddingFormMobile: '0vh 1vh 0px 1vh',
  },
};

export const FONT_FAMILY = {
  main: 'Red Hat Text',
  light: 'RedHatTextLight',
  italic: 'RedHatTextLightItalic',
  regular: 'RedHatTextRegular',
};

export const FONT_SIZE = {
  xxs: '0.75rem',
  xs: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  xxl: '1.5rem',
  xxxl: '1.625rem',
  xxxxl: '2rem',
};
export const BUTTON_SIZE = {
  primary: '7vh',
};

export const FONT_WEIGHT = {
  thin: '100',
  extraLight: '200',
  light: '300',
  regular: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
  extraBold: '800',
  black: '900',
};

export const BREAKPOINTS = {
  xxs: 150,
  xs: 360,
  sm: 568,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1920,
};

const getMedia = breakpoint => `(min-width: ${breakpoint}px)`;
export const media = {
  xxs: getMedia(BREAKPOINTS.xxs),
  xs: getMedia(BREAKPOINTS.xs),
  sm: getMedia(BREAKPOINTS.sm),
  md: getMedia(BREAKPOINTS.md),
  lg: getMedia(BREAKPOINTS.lg),
  xl: getMedia(BREAKPOINTS.xl),
  xxl: getMedia(BREAKPOINTS.xxl),
};

export const messageCMF = {
  copyright: `Copyright©2023 CM Financiera, S.A Todos los derechos reservados`,
  promoNovey: `Las ofertas son válidas desde el 1 de Agosto hasta el 31 de Agosto de 2023. Vigencia de promociones deben ser verificadas en las bases de cada promoción.`,
  Whatsapp: '+507 6597-8134',
};

const waveAnimation = keyframes`
0% {
  background-position: -100% 0;
}
50% {
  background-position: 100% 0;
}
100% {
  background-position: -100% 0;
}
`;

export const skeletonSx = {
  borderRadius: '20px',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage:
      'linear-gradient(90deg, rgb(26 23 23 / 20%) 0%, rgb(197 188 188 / 40%) 50%, rgb(49 42 42 / 20%) 100%)',
    backgroundSize: '300% 100%',
    animation: `${waveAnimation} 1s infinite`,
    zIndex: 1,
  },
  '& > span': {
    position: 'relative',
    zIndex: 2,
  },
};

import styled from '@emotion/styled'
import React from 'react'
// import HugLogo from 'src/assets/images/onboardingCredito/hug-Logotype.webp'
import { BreakPointTheme } from 'src/theme/breakpoints'
import AsideLogo from './AsideLogo'
import HugLogo from './hugLogo'
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 0.2,
  justifyContent: 'space-between',
  [BreakPointTheme.breakpoints.down('md')]: {
    display: 'none',
  },
}))
const WrapperSide = ({background}) => {
  return (
    <Wrapper
      style={{
        background: background,
      }}>
      <AsideLogo
        positionX="left"
        positionY="top"
        color={background === 'rgb(255, 255, 255)' ? 'green' : '#FFF'}
        hugColor={background === 'rgb(255, 255, 255)' ? '#011e41' : '#FFF'}
      />
      <HugLogo
        style={{
          width: '75%',
        }}
        color={background === 'rgb(255, 255, 255)' ? 'none' : '#FFF'}
        fill={background === 'rgb(255, 255, 255)' ? '#62b800' : 'none'}
      />
    </Wrapper>
  )
}
export default WrapperSide

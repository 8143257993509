import React from 'react';
import WrapperSide from 'src/components/Aside/Wrapper';
import ButtonNextOnboarding from 'src/components/Button/ButtonNextOnboarding';
import CMFLogo from 'src/components/Logos/CMF';
import SpaceBr from 'src/components/Space/space';
import SubtitleResponsiveOnboarding from 'src/components/TitleInput/SubtitleResponsiveOnboarding';
import TitleResponsiveOnboarding from 'src/components/TitleInput/TitleResponsiveOnboarding';
import {BreakPointTheme} from 'src/theme/breakpoints';
import styled from 'styled-components';
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}));
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
const ContainerInfo = styled('div')(() => ({
  display: 'flex',
  height: '50%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  width: '70%',
  [BreakPointTheme.breakpoints.down('sm')]: {
    margin: '15vh auto auto auto',
    width: '90%',
    height: '40vh',
  },
}));
const ContainerInfoSub = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  width: '70%',
  [BreakPointTheme.breakpoints.down('sm')]: {
    margin: '15vh auto auto auto',
    width: '90%',
    height: '40vh',
  },
}));

const PrepagoProductStop = () => {
  const onClose = () => {
    window.location.reload();
  };
  return (
    <Container>
      <WrapperSide background="rgb(255, 255, 255)" />
      <Wrapper
        style={{
          flex: 1,
          height: '100%',
          justifyContent: 'center',
          background:
            'radial-gradient(circle at top left, #024114, transparent 30%),radial-gradient(circle at top right, #017617, transparent 30%),radial-gradient(circle at bottom left, #026D17, transparent 30%),radial-gradient(circle at bottom right, #026D17, transparent 30%),   linear-gradient(135deg, #026D17 0%, #0B7021 20%, #026C18 40%, #015517 60%, #024315 80%, #025918 100%)',
        }}>
        <center>
          <CMFLogo />
        </center>

        <ContainerInfo>
          <TitleResponsiveOnboarding
            minSize={28}
            maxSize={75}
            text="No podemos continuar con tu solicitud porque ya tienes una tarjeta prepago"
            color="#fff"
            fontFamily="Red Hat Text"
            style={{marginBottom: '40px'}}
          />
          <SpaceBr minSize={545} />
          <ContainerInfoSub>
            <SubtitleResponsiveOnboarding
              minSize={25}
              maxSize={35}
              text="Si tienes alguna consulta, comunícate con nosotros al correo electrónico servicioalcliente@cmf.com.pa"
              color="#fff"
              fontFamily="Red Hat Text"
              style={{marginBottom: '40px'}}
            />
          </ContainerInfoSub>
          <ButtonNextOnboarding
            variant="onboardingOutline"
            success
            loading={false}
            disabled={false}
            onClick={onClose.bind(this)}>
            Salir
          </ButtonNextOnboarding>
        </ContainerInfo>
      </Wrapper>
    </Container>
  );
};

export default PrepagoProductStop;

import React from 'react'
import styled from '@emotion/styled'

export default ({text, selected, onClick}) => {
    return(
        <Wrapper style={selected ? {
            background: 'var(--color-primary-main) 0% 0% no-repeat padding-box',
            borderColor: 'transparent',
            color: 'white'
        } : null} onClick={() => onClick ? onClick() : null}>
            {text}
        </Wrapper>
    )
}
const Wrapper = styled('button')(() => ({
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: '1rem',
    minWidth: 65,
    margin: 6,
    borderColor: '#aaa',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 55,
    // padding: '10px 8px',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 14,
    paddingRight: 14,
    ':hover': {
        background: 'var(--color-primary-main) 0% 0% no-repeat padding-box',
        borderColor: 'transparent',
        color: 'white'
    },
}))
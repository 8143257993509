// component
import React from 'react'
import Card from './../../../../assets/icons/nabvar/credit_card.svg'
import Promo from './../../../../assets/icons/nabvar/new_releases.svg'
import HomeIcon from '@mui/icons-material/Home'
// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Inicio',
    path: '/home',
    icon: <HomeIcon />,
  },
  {
    title: 'Pagar',
    path: '/home/products',
    icon: <img src={Card} alt='Card'/>,
  },
  {
    title: 'Promociones',
    path: '/home/promotions',
    icon: <img src={Promo} alt='Promo' />,
  },
]

export default navConfig

/**
 * Preloads an image by creating a new Image object and setting the src attribute.
 * This function helps to load the image ahead of time, so it appears immediately
 * when it's needed in the UI.
 *
 * @param {string} url - The URL of the image to preload.
 */
const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
  };
  
  export default preloadImage;
  
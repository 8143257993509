import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {Fragment} from 'react'
import CardsAvailable from './CardsAvailable'
import DetailsCard from './DetailsCard'
const steps = ['Part A', 'Part B', 'Part C']
const PagarHome = props => {
  const {CuentaDesen, DisponibleML, LimCreditoML} = useSelector(
    state => state.ActiveProductReducer.generalvtc,
  )

  const [activeStep, setActiveStep] = useState(1)

  const handleNext = newValues => {
    setActiveStep(activeStep + 1)
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <CardsAvailable
            CuentaDesen={CuentaDesen}
            DisponibleML={DisponibleML}
            LimCreditoML={LimCreditoML}
            onClick={handleNext}
          />
        )
      case 1:
        return (
          <DetailsCard
          CuentaDesen={CuentaDesen}
          DisponibleML={DisponibleML}
          LimCreditoML={LimCreditoML}
          onClick={handleNext}
          />
        )
      default:
        throw new Error('Mis-step!')
    }
  }

  return (
    <div className="MultiStepForm">
      <Fragment>
        {activeStep === steps.length ? (
          <p>You're done!</p>
        ) : (
          <Fragment> {getStepContent(activeStep)} </Fragment>
        )}
      </Fragment>
    </div>
  )
}

export default PagarHome

import { styled } from '@material-ui/core/styles'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'
import PersonIcon from '@mui/icons-material/Person'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import { Box } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useSelector } from 'react-redux'
import { BreakPointTheme } from 'src/theme/breakpoints'
import formats from 'src/utils/formats'
import mailOutline from '../../../assets/images/cmf_linea/mail-outline.svg'
import { ContainerData, ContainerMaterial } from '../Pages/ContainerHome'

const DivIcons = styled('div')(({theme}) => ({
  display: 'flex',
  width: '36px',
  height: '36px',
  padding: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  borderRadius: '52px',
  background: '#f1f8f3',
}))
export default function ConfigCredentials() {
  const {CurrentUser} = useSelector(state => state.AuthenticationReducer)
  return (
    <ContainerMaterial maxWidth="xl">
      <ContainerData>
        <Typography
          variant="H5"
          sx={{
            padding: '8px',
          }}>
          Información de mi perfil
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'start',
            width: '100%',
            [BreakPointTheme.breakpoints.down('sm')]: {
              alignItems: 'center',
            },
          }}>
          <Accordion
            expanded
            disableGutters
            style={{
              width: '95%',
              borderRadius: '20px',
              marginTop: '20px',
            }}>
            <Box
              sx={{
                display: 'flex',
                padding: '16px',
                alignItems: 'center',
                alignSelf: 'stretch',
                gap: '12px',
              }}>
              <DivIcons>
                <PersonIcon
                  sx={{
                    width: '16px',
                    height: '16px',
                    flexShrink: 0,
                    color: '#026E18',
                  }}
                />
              </DivIcons>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginBottom: 0,
                  }}>
                  Nombre
                </Typography>
                <Typography>
                  {CurrentUser?.userSF[0]?.FirstName +
                    ' ' +
                    CurrentUser?.userSF[0]?.LastName}
                </Typography>
              </div>
            </Box>
          </Accordion>
          <Accordion
            expanded
            disableGutters
            style={{
              width: '95%',
              borderRadius: '20px',
              marginTop: '20px',
            }}>
            <Box
              sx={{
                display: 'flex',
                padding: '16px',
                alignItems: 'center',
                alignSelf: 'stretch',
                gap: '12px',
              }}>
              <DivIcons>
                <PermContactCalendarIcon
                  sx={{
                    width: '16px',
                    height: '16px',
                    flexShrink: 0,
                    color: '#026E18',
                  }}
                />
              </DivIcons>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginBottom: 0,
                  }}>
                  Cédula
                </Typography>
                <Typography>
                  {CurrentUser?.accountSF[0]?.Documento_de_identidad__c}
                </Typography>
              </div>
            </Box>
          </Accordion>
          <Accordion
            expanded
            disableGutters
            style={{
              width: '95%',
              borderRadius: '20px',
              marginTop: '20px',
            }}>
            <Box
              sx={{
                display: 'flex',
                padding: '16px',
                alignItems: 'center',
                alignSelf: 'stretch',
                gap: '12px',
              }}>
              <DivIcons>
                <PhoneAndroidIcon
                  sx={{
                    width: '16px',
                    height: '16px',
                    flexShrink: 0,
                    color: '#026E18',
                  }}
                />
              </DivIcons>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginBottom: 0,
                  }}>
                  Celular
                </Typography>
                <Typography>
                  {formats.phone(CurrentUser?.accountSF[0]?.Phone ?? '')}
                </Typography>
              </div>
            </Box>
          </Accordion>
          <Accordion
            expanded
            disableGutters
            style={{
              width: '95%',
              borderRadius: '20px',
              marginTop: '20px',
            }}>
            <Box
              sx={{
                display: 'flex',
                padding: '16px',
                alignItems: 'center',
                alignSelf: 'stretch',
                gap: '12px',
              }}>
              <DivIcons>
                <img src={mailOutline} style={{width: '16px'}} alt='Mail outline'></img>
              </DivIcons>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    marginBottom: 0,
                  }}>
                  Correo Electrónico
                </Typography>
                <Typography>{CurrentUser?.userSF[0]?.Email}</Typography>
              </div>
            </Box>
          </Accordion>
        </Box>
      </ContainerData>
    </ContainerMaterial>
  )
}

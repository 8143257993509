import ArticleIcon from '@mui/icons-material/Article';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import PersonIcon from '@mui/icons-material/Person';
import ReplayIcon from '@mui/icons-material/Replay';
import {
  Box,
  Button,
  Pagination,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useResponsive from 'src/hooks/useResponsive';
import { setAllMovements, setErrorMovements } from 'src/store/actions/actions';
import { getMovementsByDate } from 'src/store/actions/MovimientoAction';
import { BreakPointTheme } from 'src/theme/breakpoints';
import { skeletonSx } from 'src/theme/constants';
import { PRODUCTS_TYPES } from 'src/utils/Constants';
import formats from 'src/utils/formats';
import {
  TransactionTypeDecorator,
  formatearFecha,
  getTransactionTypeDescription,
} from 'src/utils/Functions';
import DownloadMovements from './downloadMovements';
import './style.css';

export function ObtenerIcono(valor) {
  switch (valor) {
    case 'Pagos':
      return (
        <CreditCardIcon
          sx={{
            color: 'primary.dark',
            width: '18px',
            height: '18px',
          }}
        />
      );
    case 'Envio':
      return (
        <PersonIcon
          sx={{
            color: 'primary.dark',
            width: '18px',
            height: '18px',
          }}
        />
      );
    case 'COM': //Volcan
    case 'Compra':
      return (
        <LocalMallIcon
          sx={{
            color: 'primary.dark',
            width: '18px',
            height: '18px',
          }}
        />
      );
    case 'Pagos - Acreditación por Remesa':
      return (
        <CreditCardIcon
          sx={{
            color: 'primary.dark',
            width: '18px',
            height: '18px',
          }}
        />
      );
    case 'Reverso de Compra':
      return (
        <CreditCardIcon
          sx={{
            color: 'primary.dark',
            width: '18px',
            height: '18px',
          }}
        />
      );
    default:
      return (
        <AttachMoneyIcon
          sx={{
            color: 'primary.dark',
            width: '18px',
            height: '18px',
          }}
        />
      );
  }
}

export default function Movimientos({path, all, myData, rendering}) {
  const {movementsList, loadingMovements, errorMovements} = useSelector(
    state => state.productReducer,
  );
  const {selectedProduct} = useSelector(state => state.ActiveProductReducer);
  const prevSelectedProductRef = React.useRef(selectedProduct);
  const isDesktop = useResponsive('up', 'md');
  const textoDownload = isDesktop ? 'Descargar estado de cuenta' : '';
  const dispatch = useDispatch();
  const [readDate, setDate] = useState({
    year: 0,
    month: 0,
    day: 0,
    moment: null,
  });
  const location = useLocation();

  const isValidRoute = location.pathname === '/home/movements';

  const [mappedCount] = useState(0);

  const itemsPerPage = 10;

  const [page, setPage] = useState(1);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const renderMovements = movementsList?.slice(
    (page - 1) * itemsPerPage,
    (page - 1) * itemsPerPage + itemsPerPage,
  );

  async function fetchData(year, month) {
    dispatch(getMovementsByDate(selectedProduct, year, month));
  }

  useEffect(() => {
    if (prevSelectedProductRef.current !== selectedProduct) {
      setDate({
        year: 0,
        month: 0,
        day: 0,
        moment: null,
      });

      dispatch(setErrorMovements(false));
      dispatch(setAllMovements([]));
      prevSelectedProductRef.current = selectedProduct;
    }
    if (path === 'home') {
      fetchData(0, 0);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (isValidRoute && rendering) {
      dispatch(setAllMovements([]));
    }
  }, [isValidRoute]);

  const handleDateChange = newDate => {
    dispatch(setErrorMovements(false));
    dispatch(setAllMovements([]));
    if (!newDate) return null;
    const daysInMonth = moment(newDate).daysInMonth();
    const momentDate = moment(newDate);
    const selectedMonth = momentDate.format('M');
    const selectedYear = momentDate.format('YYYY');

    fetchData(selectedYear, selectedMonth);
    setDate({
      year: selectedYear,
      month: selectedMonth,
      day: daysInMonth,
      moment: momentDate,
    });
  };

  const reloadMovement = () => {
    if (path === 'home') {
      fetchData(0, 0);
    } else {
      fetchData(readDate.year, readDate.month);
    }
  };

  const listStyle = {
    maxHeight: !path ? '450px' : '350px',
    overflowY: 'auto',
  };

  const handleDateChanges = value => {
    if (!path) {
      myData(value);
    }
  };

  const renderElements = () => {
    let moventsdata =
      renderMovements !== null ? renderMovements : movementsList;
    if (!moventsdata || (moventsdata && moventsdata.length === 0)) {
      return (
        <>
          {errorMovements ? (
            <Paper elevation={3} style={{padding: '20px', textAlign: 'center'}}>
              <Skeleton
                variant="text"
                width="100%"
                height={40}
                animation="wave"
                sx={skeletonSx}
              />
              <Skeleton
                variant="rectangular"
                width="100%"
                height={100}
                animation="wave"
              />
              <Skeleton
                variant="text"
                width="100%"
                height={40}
                animation="wave"
              />
              <Button
                variant="contained"
                data-testid="reintentar"
                startIcon={<ReplayIcon />}
                onClick={reloadMovement}>
                Reintentar
              </Button>
            </Paper>
          ) : (
            <Box
              sx={{
                position: 'relative',
                borderRadius: '24px',
                backgroundColor: '#fff',
                width: '100%',
                height: '238.86px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
              <Box
                sx={{
                  position: 'relative',
                  borderRadius: '50%',
                  backgroundColor: '#f5fbee',
                  width: '6rem',
                  height: '93.03px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <ArticleIcon
                  sx={{
                    width: '100%',
                    height: '48px',
                    color: '#E2F9C8',
                  }}
                />
              </Box>
              <br />
              <Typography variant="h6">
                {readDate.month === 0 && path !== 'home'
                  ? 'Selecciona el mes que deseas revisar'
                  : path === 'home'
                  ? 'No se han encontrado movimientos recientes'
                  : 'No tiene movimientos para el mes seleccionado'}
              </Typography>
            </Box>
          )}
        </>
      );
    }
    const renderMovementView =
      moventsdata && moventsdata.slice(mappedCount, mappedCount + itemsPerPage);

    if (renderMovementView === undefined) return null;
    return renderMovementView.map(value => {
      if (value === undefined) return null;
      return (
        <>
          <ListItem
            key={value}
            secondaryAction={
              <Box
                sx={{
                  textAlign: 'end',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Typography variant="H6">
                  {`${
                    TransactionTypeDecorator[value?.TipoMov]
                  }${formats.currency(value?.MtoTra)}`}
                </Typography>
                <Typography variant="H4">
                  {formatearFecha(value?.FechaOrigen, 'MM/DD/YYYY')}
                </Typography>
              </Box>
            }
            onClick={() => {
              handleDateChanges(value);
            }}
            disablePadding>
            <ListItemButton
              sx={{
                paddingTop: 2.5,
                paddingBottom: 2.5,
                borderRadius: 4,
              }}>
              <ListItemAvatar
                sx={{
                  minWidth: '40px',
                  display: 'flex',
                  width: '40px',
                  height: '40px',
                  marginRight: 2,
                  padding: '8px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexShrink: 0,
                  borderRadius: '52px',
                  background: '#F9FAFB',
                }}>
                {ObtenerIcono(value?.Transaccion)}
              </ListItemAvatar>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  [BreakPointTheme.breakpoints.down('sm')]: {
                    width: '60%',
                  },
                }}>
                <Typography variant="H6">{value?.Descripcion}</Typography>
                <Typography variant="H4">
                  {getTransactionTypeDescription(value?.Transaccion)}
                </Typography>
              </Box>
            </ListItemButton>
          </ListItem>
          {/* <div> */}
        </>
      );
    });
  };

  return (
    <>
      {all && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 2,
            marginBottom: 2,
          }}>
          <DatePicker
            value={readDate.moment}
            label={readDate.month === 0 ? 'Seleccionar mes' : 'Filtrar'}
            openTo="month"
            views={['year', 'month']}
            onChange={handleDateChange}
            disableFuture
            sx={{
              [BreakPointTheme.breakpoints.down('sm')]: {
                width: '70%',
              },
            }}
          />
          {selectedProduct?.productTypeId?.toUpperCase() !==
            PRODUCTS_TYPES.PREPP && (
            <DownloadMovements
              texto={textoDownload}
              style={{
                [BreakPointTheme.breakpoints.down('sm')]: {
                  width: '30%',
                },
              }}
              dates={readDate}
              origin={selectedProduct?.isCorporate}
              data={movementsList}
            />
          )}
        </Box>
      )}
      <List
        dense
        style={listStyle}
        sx={{
          width: '100%',
          maxWidth: '100%',
        }}>
        {loadingMovements ? (
          <Skeleton variant="rectangular" width="100%" sx={skeletonSx}>
            <div style={{height: '15rem'}} />
          </Skeleton>
        ) : (
          <>{renderElements()}</>
        )}
      </List>

      {!loadingMovements && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: '8px',
          }}>
          {movementsList && movementsList.length > 0 && (
            <>
              <Pagination
                sx={{
                  color: '#229A16',
                }}
                count={Math.ceil(movementsList?.length / itemsPerPage)}
                onChange={handlePageChange}
                page={page}
              />
            </>
          )}
        </Box>
      )}
    </>
  );
}

Movimientos.propTypes = {
  all: PropTypes.any,
  myData: PropTypes.func,
  path: PropTypes.string,
  rendering: PropTypes.any,
};

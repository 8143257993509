import React from 'react'
import LogoCMF from 'src/assets/images/onboardingCredito/logoCMF.png'
import { BreakPointTheme } from 'src/theme/breakpoints'
import styled from 'styled-components'
const ImgLogo = styled('img')(() => ({
  [BreakPointTheme.breakpoints.up('md')]: {
    display: 'none',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    width: '111px',
    height: '38px',
    marginTop: 28,
  },
}))
export default function CMFLogo() {
  return <ImgLogo src={LogoCMF} />
}

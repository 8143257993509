import React from 'react'
import PropTypes from 'prop-types'
import {useMemo} from 'react'
// @mui
import {CssBaseline} from '@mui/material'
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles'
//
import palette from './palette'
import shadows, {customShadows} from './shadows'
import typography from './typography'
import GlobalStyles from './globalStyles'
import componentsOverride from './overrides'
// import breakpoints from './breakpoints'

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node,
}

export default function ThemeConfig({children}) {
  const themeOptions = useMemo(
    () => ({
      palette: {...palette.light, mode: 'light'},
      shape: {borderRadius: 6},
      typography,
      // breakpoints: {values: {...breakpoints}},
      shadows: shadows.light,
      customShadows: customShadows.light,
    }),
    [],
  )

  const theme = createTheme(themeOptions)
  
  theme.components = componentsOverride(theme)
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

import * as React from 'react'
const HugLogo = ({color,fill}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="220"
    height="360"
    viewBox="0 0 220 360">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rect\xE1ngulo_2887"
          data-name="Rect\xE1ngulo 2887"
          width="220"
          height="360"
          transform="translate(0 720)"
          fill={fill}
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <rect
          id="Rect\xE1ngulo_2866"
          data-name="Rect\xE1ngulo 2866"
          width="280.247"
          height="380.814"
          fill={fill}
        />
      </clipPath>
      <clipPath id="clip-path-4">
        <rect
          id="Rect\xE1ngulo_2863"
          data-name="Rect\xE1ngulo 2863"
          width="181.749"
          height="186.095"
          fill={fill}
        />
      </clipPath>
      <clipPath id="clip-path-5">
        <rect
          id="Rect\xE1ngulo_2864"
          data-name="Rect\xE1ngulo 2864"
          width="153.026"
          height="256.605"
          fill={fill}
        />
      </clipPath>
    </defs>
    <g
      id="Enmascarar_grupo_5"
      data-name="Enmascarar grupo 5"
      transform="translate(0 -720)"
      clipPath="url(#clip-path)">
      <g
        id="Grupo_8169"
        data-name="Grupo 8169"
        transform="translate(-65.258 722.844)">
        <g id="Grupo_8168" data-name="Grupo 8168" clipPath="url(#clip-path-2)">
          <g id="Grupo_8167" data-name="Grupo 8167">
            <g
              id="Grupo_8166"
              data-name="Grupo 8166"
              clipPath="url(#clip-path-2)">
              <path
                id="Trazado_4364"
                data-name="Trazado 4364"
                d="M126.848,194.712,7.42,70.989a26.388,26.388,0,0,1,.65-37.34l4.389-4.227a104.842,104.842,0,0,1,148.256,2.6l90.112,93.321a104.872,104.872,0,0,1-2.6,148.273l-12.681,12.248-67.571,65.3a105.675,105.675,0,0,1-149.392-2.6l-6.287-6.5a26.387,26.387,0,0,1,.65-37.34L126.9,194.712Z"
                transform="translate(0 0)"
                fill={fill}
                stroke={color}
                stroke-width="2"
              />
              <g
                id="Grupo_8162"
                data-name="Grupo 8162"
                transform="translate(4.878 194.709)"
                opacity={0.8}
                style={{
                  mixBlendMode: 'multiply',
                  isolation: 'isolate',
                }}>
                <g id="Grupo_8161" data-name="Grupo 8161">
                  <g
                    id="Grupo_8160"
                    data-name="Grupo 8160"
                    clipPath="url(#clip-path-4)">
                    <path
                      id="Trazado_4365"
                      data-name="Trazado 4365"
                      d="M19.736,394.511l-6.287-6.5a26.386,26.386,0,0,1,.65-37.339L128.054,240.656l33.813,35.009a92.383,92.383,0,0,1,2.059,126.108,105.575,105.575,0,0,1-144.191-7.316"
                      transform="translate(-6.029 -240.657)"
                      fill={fill}
                      stroke={color}
                      stroke-width="2"
                    />
                  </g>
                </g>
              </g>
              <g
                id="Grupo_8165"
                data-name="Grupo 8165"
                transform="translate(126.894 99.279)"
                opacity={0.4}
                style={{
                  mixBlendMode: 'multiply',
                  isolation: 'isolate',
                }}>
                <g id="Grupo_8164" data-name="Grupo 8164">
                  <g
                    id="Grupo_8163"
                    data-name="Grupo 8163"
                    clipPath="url(#clip-path-5)">
                    <path
                      id="Trazado_4366"
                      data-name="Trazado 4366"
                      d="M255.619,122.707l24.6,25.525a105.7,105.7,0,0,1-2.6,149.41l-12.138,11.706-67.571,65.3c-1.68,1.626-3.413,3.2-5.2,4.661A92.381,92.381,0,0,0,190.649,253.2L156.837,218.2l98.782-95.434Z"
                      transform="translate(-156.838 -122.707)"
                      fill={fill}
                      stroke={color}
                      stroke-width="2"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default HugLogo

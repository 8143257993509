import React, {Component} from 'react'
import './style.css'

export default class ProgressBar extends Component {
  getClassStatus(status) {
    if (this.props.active > status) {
      return 'completed'
    } else if (status === this.props.active) {
      return 'active'
    } else {
      return ''
    }
  }
  render() {
    return (
      <div className="wrapper-progress">
        <div className="container-progress-bar">
          <ul className="progressbar">
            <li className={this.getClassStatus(1)}>
              <p>VERIFICACIÓN DE DATOS</p>
            </li>
            <li className={this.getClassStatus(2)}>
              <p>INFORMACIÓN PERSONAL</p>
            </li>
            <li className={this.getClassStatus(3)}>
              <p>INFORMACIÓN PROFESIONAL</p>
            </li>
            <li className={this.getClassStatus(4)}>
              <p>PREGUNTAS DE SELECCIÓN</p>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

import React from 'react'
import {RejectedScreen} from './helpers'

export const RequestRejected = () => {
  const onClose = () => {
    window.location.href = 'https://www.cmfinanciera.com/s/formas-de-pago'
  }

  return (
    <RejectedScreen
      buttonText="Salir"
      buttonOnClick={onClose}
      heading="¡Gracias por tu preferencia!"
      title={
        <>
          <span>Hemos revisado tus datos y por el momento no</span>{' '}
          <span> podemos continuar con la solicitud</span>
        </>
      }
      subtitle1="¡Esperamos poder atenderte en el futuro!"
      classNameSubtitleMobile="p-1"
    />
  )
}

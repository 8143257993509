import { createTheme } from '@mui/material/styles';

const BreakPointTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xxs: 150,
      xsm: 376,
      sm: 600,
      sm1: 768,
      md: 900,
      lg: 1200,
      lg1: 1300, //Tablet
      xl: 1536,
      xxl: 1920, // Desk
      alter: 1021
    },
  },
});

export { BreakPointTheme };


import PropTypes from 'prop-types'
import React from 'react'
// @mui
import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material'
import { styled } from '@mui/material/styles'
// utils
import LogoCMF from 'src/assets/images/logos/logo-mobile.svg'
//
import { useNavigate } from 'react-router-dom'
import { BreakPointTheme } from 'src/theme/breakpoints'
import AccountPopover from './AccountPopover'

// ----------------------------------------------------------------------

const NAV_WIDTH = 280

const HEADER_MOBILE = 64

const HEADER_DESKTOP = 92

const StyledRoot = styled(AppBar)(({theme}) => ({
  // ...bgBlur({color: theme.palette.background.default}),
  backgroundColor: 'transparent',
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
  [BreakPointTheme.breakpoints.between('md','lg')]: {
    width: 'auto',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    backgroundColor: '#F2F4F7',
  },
}))

const StyledToolbar = styled(Toolbar)(({theme}) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
    backgroundColor: 'transparent',
    position: 'absolute',
    width: '100%',
  },
  [BreakPointTheme.breakpoints.between('md','lg')]: {
    width: '25%',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    padding: '16px',
  },
}))

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
}

export default function Header({onOpenNav}) {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/home') // Usa history.push para navegar a '/dashboard'
  }
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={handleClick}
          sx={{
            mr: 1,
            // color: 'text.primary',
            display: {md: 'none'},
          }}>
          <img
            alt='Logo CMF'
            src={LogoCMF}
            style={{
              width: '85.99px',
              // height: '32px',
            }}
          />
        </IconButton>
        <Box sx={{flexGrow: 1}} />

        <Stack
          direction="row"
          alignItems="center"
          sx={{
            paddingTop: '40p',
          }}
          spacing={{
            xs: 0.5,
            sm: 1,
          }}>
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  )
}

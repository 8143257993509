/**
 * @flow
 */
import produce from 'immer';
import {
  GET_PDF_BALANCE_SUCCESS,
  GET_PDF_BALANCE_ERROR,
  GET_PDF_BALANCE_LOADING_SHOW,
  GET_PDF_BALANCE_LOADING_HIDE,
} from '../../constrants/reducers';

export type initStateProps = {
  PdfInfo: any,
  error: string,
  Loading: boolean,
};

const initState: initStateProps = {
  PdfInfo: {},
  error: '',
  Loading: false,
};

const pdfBalance = (state: initStateProps = initState, action: any) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_PDF_BALANCE_LOADING_SHOW:
        draft.Loading = true;
        break;
      case GET_PDF_BALANCE_LOADING_HIDE:
        draft.Loading = false;
        break;
      case GET_PDF_BALANCE_SUCCESS:
        draft.PdfInfo = action.payload;
        break;
      case GET_PDF_BALANCE_ERROR:
        draft.error = action.err;
        break;
      default:
        return state;
    }
  });

export default pdfBalance;

import {StepConnector, stepConnectorClasses, styled} from '@mui/material'

const ColorlibConnector = styled(StepConnector)(({theme}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 25,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#9EA1A8',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#9EA1A8',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    height: 2,
    border: 0,
    maxWidth: 'calc(100% - 30px)',

    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#9EA1A8',
    borderRadius: 10,
  },
  // for small size
  '@media (max-width: 600px)': {
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 20,
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 0.5,
      maxWidth: '100%',
      margin: '0px',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: '#9EA1A8',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: '#9EA1A8',
        maxWidth: 'calc(100% - 10px)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderRadius: 1,
      maxWidth: 'calc(100% - 0px)',
      marginLeft: 2,
    },
  },
}))

export default ColorlibConnector

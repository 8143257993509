import styled from '@emotion/styled'
import { createTheme } from '@mui/material'
import React from 'react'
import Logo from './Logo'
const theme = createTheme()
const Aside = styled('aside')(({positionY, positionX, color}) => ({
  all: 'unset',
  color: 'white',
  padding: 42,
  [theme.breakpoints.down('md')]: {
    width: '6rem',
  },
  ...(positionY === 'top' && {
    marginTop: 24,
  }),
  ...(positionY === 'center' && {}),
  ...(positionY === 'bottom' && {}),

  ...(positionX === 'left' && {}),
  ...(positionX === 'right' && {}),
  ...(positionX === 'center' && {}),

  ...(color === 'white' && {
    color: 'white',
  }),
  ...(color === 'green' && {
    color: 'rgb(41, 164, 56)',
  }),
}))

/**
 * Renders a logo inside an Aside component.
 * @param {Object} props - The component props.
 * @param {string} props.positionY - The vertical position of the Aside component.
 * @param {string} props.positionX - The horizontal position of the Aside component.
 * @param {string} props.color - The color of the Aside component.
 * @param {Object} props.style - The style object to apply to the Aside component.
 * @param {string} props.hugColor - The color of the Logo component.
 * @returns {JSX.Element} - The rendered component.
 */
export default ({positionY, positionX, color, style, hugColor}) => {
  return (
    <Aside
      positionX={positionX}
      positionY={positionY}
      color={color}
      style={style}>
      <Logo hug="#93d500" color={hugColor} />
    </Aside>
  )
}

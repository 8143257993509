import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Col, Input, Layout, Row, Select } from 'antd'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import BannerComponent from '../../components/Banner/sider.component.js'
import HeaderComponent from '../../components/Header/header.onboarding'
import ProgressBar from '../../components/ProgressBar'
import { CreateLatinData } from '../../store/actions/KO.Actions'
import {
  UpdateOnboardingEvaluationPreap,
  createdOnboardingCreditOrPreca,
  editFormRequest,
  evalutionService,
  getBannerActive,
} from '../../store/actions/requestCard.actions'
import {
  GENDER_TYPE,
  ORIGIN,
  SELLER_ID_TYPE,
  SYSTEM_TYPES
} from '../../utils/Constants'
import { fieldType, formFields } from './FormRequest.config'
import ValidateEmail from './ValidateEmail'
import ValidatePhone from './ValidatePhone'
// import {withRouter} from 'react-router-dom'
import styled from '@emotion/styled'
import ButtonBack from '../../components/Button/ButtonBack'
import ButtonOk from '../../components/Button/ButtonOk'
import FooterOnboar from '../../components/Footer/FooterOnboarding'
import { checkStep, setStep } from '../../store/actions/Steps.Action'
const FormItem = Form.Item
const {Option} = Select
export const SelectMain = styled(Select)(() => ({
  '.ant-select-selector': {
    width: ' 100%',
    height: '3.5rem !important',
    padding: ' 0 11px',
  },
  '.ant-select-selection-search-input': {
    height: '3rem !important',
    margin: 'auto !important',
  },
  '.ant-select-arrow ': {
    color: ' var(--color-primary-main)',
    // borderWidth: ' 520px',

    'svg ': {
      display: ' inline-block',
      strokeWidth: ' 17%',
      stroke: ' var(--color-primary-main)',
      color: ' var(--color-primary-main)',
    },
  },
  '  .ant-select-selection__rendered': {
    display: 'contents',
    padding: '5px',
  },
  '.ant-select-focused .ant-select-single': {
    margin: 'auto',
  },
  '.ant-select-selector .ant-select-selection-placeholder': {
    margin: 'auto',
  },
  '.ant-select-selector .ant-select-selection-item': {
    margin: 'auto',
  },
  '.ant-select-selection-selected-value ': {
    width: ' 86% !important',
    position: ' absolute',
    top: ' 50%',
    right: ' 9px',
    left: ' 15px',
    maxWidth: ' 100%',
    height: ' 20px',
    marginTop: ' -10px',
    overflow: ' hidden',
    lineHeight: ' 20px',
    whiteSpace: ' nowrap',
    textAlign: ' left',
    textOverflow: ' ellipsis',
  },
  '@media (min-width: 1201px) and (max-width: 1535px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 901px) and (max-width: 1200px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 601px) and (max-width: 900px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '4em !important',
    },
  },
  '@media (max-width:600px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '3rem !important',
    },
  },
}))
class ActividadLaboralDefault extends Component {
  state = {
    hasBack: false,
    isValid: false,
    isVisible: false,
    apcCheck: false,
    isOpenEmailValidation: false,
    isOpenPhoneValidation: false,
    formFields: {
      activityType: {
        ...formFields.activityType,
        value: this.props.formRequest.activityType,
        options: this.props.activityTypes,
      },
      cellphone: {
        ...formFields.cellphone,
        value: this.props.formRequest.cellphone,
        readonly: true,
        handleClick: () => this.setState({isOpenPhoneValidation: true}),
      },
      email: {
        ...formFields.email,
        value: this.props.formRequest.email,
        readonly: true,
        handleClick: () => this.setState({isOpenEmailValidation: true}),
      },
    },
  }
  async onPressSubmit(e) {
    e.preventDefault()
    const isValid = await this.isValidForm()
    if (isValid) {
      await this.onSubmit()
    } else {
    }
  }
  /**
   * @description: se envia datos de formulario
   */
  onSubmit = async () => {
    this.setState({loading: true})
    const {formRequest, origin, onboardingId} = this.props
    const createdBy = SELLER_ID_TYPE
    const branchOfficeId = 'BCC30BB5-2C99-4A34-84CE-01141397FC58'
    let phones = []

    if (formRequest.cellphone) {
      phones.push({
        id: 1,
        typePhone: 'CE',
        area: '507',
        phone: formRequest.cellphone,
        isDefault: true,
        createdBy: createdBy,
        extension: null,
        lastModifiedBy: null,
      })
    }

    if (formRequest.phone) {
      phones.push({
        typePhone: 'CA',
        area: '507',
        phone: formRequest.phone,
        isDefault: false,
        createdBy: createdBy,
      })
    }
    const form = {
      id: onboardingId,
      typeIdentityId: '8F3C2EF0-F0D2-4FF0-9863-218D3D494D56',
      branchOfficeId: branchOfficeId,
      sellerId: SELLER_ID_TYPE,
      approvedBy: SELLER_ID_TYPE,
      approvedDate: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
      genderId:
        formRequest.gender === 1 ? GENDER_TYPE.MALE : GENDER_TYPE.FEMALE,
      countryId: formRequest.country,
      countryBirthId: formRequest.countryOfBirth,
      nationalityId: formRequest.nationality,
      systemId: SYSTEM_TYPES.CMF,
      identityNumber: formRequest.identity,
      firstName: formRequest.firstName,
      secondName: formRequest.secondName || null,
      firstSurname: formRequest.lastName,
      secondSurname: formRequest.secondLastName,
      dateOfBirth: formRequest.birthdate,
      phones: phones,
      // employment: [
      //   {
      //     typeWorkActivity: formRequest.activityType,
      //     createdBy: createdBy,
      //   },
      // ],
      emails: [
        {
          typeEmail: 'PE',
          email: formRequest.email,
          isDefault: true,
          createdBy: createdBy,
        },
      ],
      images: [
        {
          typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
          description: 'Cedula Frontal',
          link: formRequest.frontIdentityImage,
          thumbnails: null,
          createdBy: 'CMF',
        },
        {
          typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
          description: 'Cedula Reverso',
          link: formRequest.backIdentityImage,
          thumbnails: null,
          createdBy: 'CMF',
        },
        {
          typeImageId: 'A7398CE9-96CA-41AE-9513-AE233C6FEB02',
          description: 'Biometrico',
          link: formRequest.faceImage,
          thumbnails: null,
          createdBy: 'CMF',
        },
      ],
      createdBy: 'CMF',
      LastModifiedBy: 'CMF',
    }
    if (origin === ORIGIN.CREDITO || origin === ORIGIN.PRECALIFICADO) {
      this.callApiCredi(form)
    } else {
      this.props.UpdateOnboardingEvaluationPreap(
        form,
        this.onSuccess.bind(this),
      )
    }
  }
  callApiCredi(payload) {
    this.props.createdOnboardingCreditOrPreca(
      payload,
      this.onSuccess.bind(this),
    )
  }
  onSuccess(success) {
    this.setState({loading: false})
  }
  componentDidUpdate(prevProps) {
    /* on change country */
    const {setFieldsValue} = this.props.form

    if (prevProps.formRequest.email !== this.props.formRequest.email) {
      setFieldsValue({email: this.props.formRequest.email})
    }
    if (prevProps.formRequest.cellphone !== this.props.formRequest.cellphone) {
      setFieldsValue({cellphone: this.props.formRequest.cellphone})
    }
  }

  componentDidMount() {
    const {origin} = this.props
    // this.props.checkStep(SCREENS.ActividadLaboral, this.props.goToStep)
    this.props.getBannerActive()
    if (origin === ORIGIN.CREDITO || origin === ORIGIN.PRECALIFICADO) {
      this.props.CreateLatinData()
    }
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 300)
  }
  async isValidForm() {
    try {
      const {form} = this.props
      await form.validateFieldsAndScroll()
      this.setState({isValid: true})
      return true
    } catch (e) {
      this.setState({isValid: false})
      return false
    }
  }

  /* handles */
  handleChange(e) {
    this.props.EditForm({[e.target.id]: e.target.value})
  }
  handleRadioChange(key, e) {
    this.props.EditForm({[key]: e.target.value})
  }
  handleDateChange(key, date, dateString) {
    this.props.EditForm({[key]: dateString})
  }

  autocompleteDisabled() {
    /* resolve all compatibility explorers with autocomplete */
    if (!this.state.autocompleteDisabled) {
      let i
      const el = document.getElementsByClassName('ant-select-search__field')
      for (i = 0; i < el.length; i++) {
        el[i].setAttribute('autocomplete', 'registration-select')
      }
      this.setState({autocompleteDisabled: true})
    }
  }
  async handleActivityTypeChange(key, payload) {
    const {activityType} = payload
    this.props.EditForm(payload)
    if (key === 'activityType' && activityType === 'JU') {
      this.props.resetProfessionalFields()
      this.props.form.resetFields('profession')
      this.props.form.resetFields('occupation')
    }
  }
  /* renders forms */
  _renderField(group, key) {
    const onchange = this.handleChange.bind(this)
    switch (formFields[key]?.type) {
      case fieldType.TEXT:
        return (
          <Input
            autocomplete="off"
            className="input"
            required={this.state[group][key].required}
            type="text"
            id={key}
            onChange={onchange}
            onClick={this.state[group][key]?.handleClick}
            readOnly={this.state[group][key]?.readonly ? true : false}
            name={key}
            placeholder={this.state[group][key]?.placeholder || ''}
            onFocus={this.autocompleteDisabled.bind(this)}
          />
        )

      case fieldType.DROPDOWN:
        return (
          <SelectMain
            autocomplete="off"
            id={key}
            required={this.state[group][key].required}
            onSelect={value => this.props.EditForm({[key]: value})}
            name={key}
            options={this.state[group][key]?.options}
            placeholder={this.state[group][key]?.placeholder || ''}
            optionFilterProp={'label'}
            onFocus={this.autocompleteDisabled.bind(this)}>
            {(this.state[group][key]?.options || []).map(item => {
              return (
                <Option value={item.value} label={item.label}>
                  {item.label}
                </Option>
              )
            })}
          </SelectMain>
        )
      default:
        return (
          <Input
            autocomplete="off"
            type="text"
            className="input"
            required={this.state[group][key].required}
            id={key}
            onChange={onchange}
            onClick={this.state[group][key]?.handleClick}
            name={key}
            placeholder={this.state[group][key]?.placeholder || ''}
            readOnly={this.state[group][key]?.readonly ? true : false}
            onFocus={this.autocompleteDisabled.bind(this)}
          />
        )
    }
  }
  /* render field in every react state */
  renderField(fieldName) {
    const {getFieldDecorator} = this.props.form
    return (
      <FormItem key={'K_' + fieldName}>
        {getFieldDecorator(fieldName, {
          initialValue: this.state.formFields[fieldName].value,
          rules: this.state.formFields[fieldName]?.rules || [],
          // validateTrigger: 'onPressSubmit',
        })(this._renderField('formFields', fieldName))}
      </FormItem>
    )
  }

  /* modal for email validation */
  renderModalEmailValidation() {
    const {formRequest} = this.props
    const _fullname = `${formRequest.firstName || ''} ${formRequest.lastName ||
      ''}`
    return (
      <ValidateEmail
        title="Validación de Correo Electrónico"
        visible={this.state.isOpenEmailValidation}
        fullName={_fullname}
        initialValue={this.state.formFields?.email?.value}
        onClose={() => this.setState({isOpenEmailValidation: false})}
      />
    )
  }

  /* modal for phone validation */
  renderModalPhoneValidation() {
    return (
      <ValidatePhone
        title="Validación de Teléfono Celular"
        visible={this.state.isOpenPhoneValidation}
        initialValue={this.state.formFields?.cellphone?.value}
        onClose={() => this.setState({isOpenPhoneValidation: false})}
      />
    )
  }

  previousStep() {
    // this.props.goToStep(SCREENS.IdentityConfirmed)
  }

  renderBackButton() {
    return this.props.canReturn ? (
      <ButtonBack
        success
        loading={false}
        disabled={false}
        size="large"
        onClick={this.previousStep.bind(this)}
        data="Volver"
      />
    ) : (
      <div></div>
    )
  }
  render() {
    const {getFieldDecorator} = this.props.form
    const {result} = this.props
    return (
      <>
        <div id="content">
          {this.renderModalEmailValidation()}
          {this.renderModalPhoneValidation()}
          <Layout>
            <BannerComponent name={'paso1'} />
            <Layout>
              <HeaderComponent name={'paso1'} />
              <ProgressBar active={1} />
              <Form
                autoComplete="off"
                className="politicalForm"
                onSubmit={this.onSubmit.bind(this)}>
                <Row className="spaceRow">
                  <Col xl={12} lg={12} sm={24} xs={24}>
                    Valida tu número de celular
                    {this.renderField('cellphone')}
                  </Col>
                </Row>
                <Row className="spaceRow">
                  <Col xl={12} lg={12} sm={24} xs={24}>
                    Valida tu correo electrónico
                    {this.renderField('email')}
                  </Col>
                </Row>
                {result?.jsonAnswer !== 'CREDIT' && (
                  <Row className="spaceRow">
                    <Col xl={12} lg={12} sm={24} xs={24}>
                      Indica cual es tu actividad laboral
                      <FormItem key={'K_activityType'} style={{marginTop: 15}}>
                        {getFieldDecorator(
                          'activityType',
                          this.state.formFields['activityType'].value
                            ? {
                                initialValue: this.state.formFields[
                                  'activityType'
                                ].value,
                                rules: formFields['activityType']?.rules,
                              }
                            : {rules: formFields['activityType']?.rules},
                        )(this._renderField('formFields', 'activityType'))}
                      </FormItem>
                    </Col>
                  </Row>
                )}
              </Form>
              <FooterOnboar
                back={this.renderBackButton()}
                next={
                  <ButtonOk
                    success
                    loading={this.state.loading}
                    disabled={false}
                    size="large"
                    data-testid="btn-verify-info"
                    onClick={this.onPressSubmit.bind(this)}
                    data="Siguiente"
                  />
                }
              />
            </Layout>
          </Layout>
        </div>
      </>
    )
  }
}
const mapStateToProps = state => {
  const {
    form,
    requestVideoId,
    activityTypes,
    professions,
    occupations,
    origin,
    onboardingId,
  } = state.RequestCardReducer
  const {CurrentOnboarding, acpId} = state.KOReducer
  const {path, result} = state.Phone
  return {
    formRequest: form,
    requestVideoId,
    activityTypes,
    professions,
    occupations,
    origin: origin,
    onboardingId,
    CurrentOnboarding,
    apc: acpId,
    path,
    result,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    EditForm: formFields => dispatch(editFormRequest(formFields)),
    evalutionService: (employment, onSuccess) =>
      dispatch(evalutionService(employment, onSuccess)),
    CreateLatinData: () => dispatch(CreateLatinData()),
    createdOnboardingCreditOrPreca: (payload, onSuccess) =>
      dispatch(createdOnboardingCreditOrPreca(payload, onSuccess)),
    getBannerActive: () => dispatch(getBannerActive()),
    setStep: step => dispatch(setStep(step)),
    checkStep: (step, goToStep) => dispatch(checkStep(step, goToStep)),
    UpdateOnboardingEvaluationPreap: (form, onComplete) =>
      dispatch(UpdateOnboardingEvaluationPreap(form, onComplete)),
  }
}

const ActividadLaboral = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActividadLaboralDefault)

export default Form.create()((ActividadLaboral))

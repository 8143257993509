import { styled } from '@material-ui/core/styles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import UpdateIcon from '@mui/icons-material/Update';
import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import SwipeableDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import axios from 'axios';
import { useFormik } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { API_KEY, Cmf_Base } from 'src/Data/Api';
import solicitarExtra from 'src/assets/images/cmf_linea/solicitarExtra.svg';
import CardAcciones from 'src/containers/cmf_linea/inicio_cmf/cardAcciones';
import { getPlants } from 'src/store/actions/ActiveProductAction';
import {
  createExtraCredit,
  getFinancialInstitution,
} from 'src/store/actions/financial.actions';
import { BreakPointTheme } from 'src/theme/breakpoints';
import { IDENTITY_TYPES } from 'src/utils/Constants';
import formats from 'src/utils/formats';
import * as yup from 'yup';
import CheckCircleIcon from '../../assets/icons/cmf_linea/svgs/check-circle';
import CheckboxIcon from '../../assets/icons/cmf_linea/svgs/checkbox';
import * as container from '../../containers/cmf_linea/auth/login.cmf.style';
import CustomStyledBox from '../Box/BoxCustom';
import ButtonBackModal from '../Button/ButtonBackModal';
import { ButtonCheckbox } from '../Button/ButtonCheckbox';
import InstallmentSimulationDetailItem from '../List/InstallmentSimulationDetailItem';
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;
  const [value, setValue] = useState('');

  React.useEffect(() => {
    // Restablecer el valor cuando el componente se monta
    setValue('');

    // Puedes agregar más lógica de reinicio aquí si es necesario
  }, []);
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      value={value} // Utiliza el valor del estado local
      onValueChange={values => {
        setValue(values.value); // Actualiza el estado local con el nuevo valor
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      // thousandSeparator
      maxLength={5}
      valueIsNumericString
      prefix="$"
    />
  );
});
const globalTerms = {
  alignSelf: 'stretch',
  color: 'rgba(16, 24, 40, 0.64)',
  fontFamily: 'Red Hat Text',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px /* 128.571% */',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  cursor: 'grab',
};
const NumericFormatCustomBank = React.forwardRef(
  function NumericFormatCustomBank(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        maxLength={21}
        valueIsNumericString
        allowNegative={false}
      />
    );
  },
);
const StyleBox = styled('div')(({ type }) => ({
  ...(type === 'Button' && {
    [BreakPointTheme.breakpoints.up('xxs')]: {
      display: 'flex',
      // justifyContent: 'center',
      width: '100%',
    },
  }),
}));
const BoxOption = styled(Paper)(({ theme }) => ({
  width: '100%',
  maxWidth: '100%' /* Para evitar que el contenido se desborde */,
  whiteSpace: 'nowrap' /* Evitar desbordamiento de texto */,
  overflow: 'hidden' /* Evitar desbordamiento de contenido */,
  textOverflow:
    'ellipsis' /* Mostrar puntos suspensivos en lugar de contenido desbordado */,
  [BreakPointTheme.breakpoints.down('sm')]: {
    height: '100%',
    overflowX: '50%',
  },
  [BreakPointTheme.breakpoints.up('md')]: {
    // height: '20rem',
  },
}));
const TextFields = styled(props => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiInputAdornment-root': {
    marginTop: '0!important',
  },
  '& .MuiFormLabel-root-MuiInputLabel-root': {
    left: '0px',
  },
  '& .MuiInputBase-input-MuiFilledInput-input': {
    paddingLeft: '3px !important',
  },

  '& .MuiInputLabel-root': {
    position: 'absolute',
    left: '0px',
    marginLeft: '0px',
    color: 'rgba(16, 24, 40, 0.54)',
    fontFamily: 'Red Hat Text',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px /* 133.333% */',
  },
  '& .MuiFilledInput-root': {
    borderRadius: '16px',
    border: '1px solid #D0D5DD',
    background: '#FFF',
    overflow: 'hidden',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
    },
    '&:after': {
      borderBottom: 0,
    },
  },
}));
const CustomAmountTextField = styled(props => (
  <TextField
    autoComplete="off"
    InputProps={{ disableUnderline: true }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiInputAdornment-root': {
    marginTop: '0!important',
  },
  '& .MuiFormLabel-root-MuiInputLabel-root': {
    left: '30px',
  },
  '& .MuiInputBase-input-MuiFilledInput-input': {
    paddingLeft: '3px !important',
  },
  '& .MuiInputLabel-root': {
    position: 'absolute',
    left: '30px',
    marginLeft: '14px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
  },
  '& .MuiFilledInput-root': {
    borderRadius: '16px',
    border: '1px solid  #D0D5DD',
    background: 'var(--specific-light-background-white, #FFF)',
    overflow: 'hidden',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },

    '&.Mui-focused': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledCheckbox = styled(Checkbox)({
  //   borderRadius: '40%',
  width: '18px',
  height: '18px',
  borderColor: '#101828CC',
  '& .MuiSvgIcon-root': {
    // fontSize: 70,
    borderRadius: 6,
  },
});
const labelStyles = {
  marginLeft: '0px',
  color: 'rgba(16, 24, 40, 0.54)',
  fontFamily: 'Red Hat Text',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px /* 133.333% */',
};
export default function SwipeableTemporaryDrawer({
  onClose,
  showModal,
  closeModal,
  opens,
  onLoading,
  loadExtraFinancial,
}) {
  const [tempOption, setTempOption] = useState(null);
  const [selectPlan, setSelectPlant] = useState(null);
  const [selectCode, setSelectcode] = useState(null);
  const banks = useSelector(state => state.financial.FinancialInstitution);
  const [termsAndCoditionAccepts, setTermsAndCoditionAccepts] = useState(null);
  const [MainSelectedOption, setMainSelectedOption] = useState(null);
  const loadCreateExtraCredit = useSelector(
    state => state.ActiveProductReducer.loadCreateExtraCredit,
  );

  const { userSF, accountSF } = useSelector(
    state => state.AuthenticationReducer?.CurrentUser,
  );

  const identification = useSelector(
    state =>
      state.AuthenticationReducer?.CurrentUser?.accountSF[0]
        ?.Documento_de_identidad__c,
  );
  const typeIdentity = accountSF[0]?.Tipo_de_documento_de_identidad__c;
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectAmount, setSelectedAmount] = useState(0);
  const [payloadValue, setPayloadValue] = useState(null);
  const Plans = useSelector(state => state.ActiveProductReducer.calculator);
  const { loadAmount, systemId } = useSelector(state => state.ActiveProductReducer);
  const [actualStep, setActualStep] = useState(0);
  const [extraCreditResponse, setExtracreditResponse] = useState([]);

  const handleClose = () => {
    getExtracredit();
  };
  const getExtracredit = async () => {
    onLoading(true);
    try {
      const payload = {
        typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
        identification: identification,
      };
      const { data } = await axios.get(
        `${Cmf_Base}api/Customers/GetCustomerDetailbyTypeIdentityandIdentification2?typeIdentityId=${payload.typeIdentityId}&identification=${payload.identification}`,
        {
          headers: {
            Apikey: API_KEY,
          },
        },
      );
      if (
        data.complete &&
        data.jsonAnswer &&
        Array.isArray(data.jsonAnswer.customerFinancialProposalsDTO) &&
        data.jsonAnswer.customerFinancialProposalsDTO.length > 0 &&
        data.jsonAnswer.customerFinancialProposalsDTO.some(credit => credit.typeProduct === 'EXTRAFINANCIAL')
      ) {
        onClose();
        const infoMapExtra = data?.jsonAnswer?.customerFinancialProposalsDTO?.filter(
          credit => credit?.typeProduct === 'EXTRAFINANCIAL',
        );
        setExtracreditResponse(infoMapExtra);
      } else {
        NotificationManager.warning(
          'No tienes Extra Crédito disponible en este momento',
        );
      }
    } catch (error) {
      NotificationManager.error(
        'Oops algo ha salido mal, por favor intente de nuevo.',
      );
    } finally {
      onLoading(false);
    }
  };
  const handleCloses = () => {
    if (closeModal) {
      closeModal();
      setTempOption(null);
      setSelectPlant(null);
      setTermsAndCoditionAccepts(null);
      setMainSelectedOption(null);
      setSelectedOption(null);
      setSelectedAmount(0);
      setActualStep(0);
      formikBank.resetForm();
      formik.resetForm();
      setExtracreditResponse([]);
    }
  };
  // // Función para restablecer todos los estados
  const resetAllStates = () => {
    setTempOption(null);
    setSelectPlant(null);
    setTermsAndCoditionAccepts(null);
    setMainSelectedOption(null);
    setSelectedOption(null);
    setSelectedAmount(0);
    setActualStep(0);
    setExtracreditResponse([]);
  };
  const dispatch = useDispatch();
  React.useEffect(() => {
    resetAllStates();
    dispatch(getFinancialInstitution());
  }, []);
  React.useEffect(() => {
    if (opens) {
      getExtracredit();
    }
  }, [opens]);
  const onSuccess = Success => {
    if (Success) {
      const steps =
        selectedOption === 1 ? StepperAmount.length : StepperCustomAmount.length;
      if (actualStep < steps - 1) {
        setActualStep(actualStep + 1);
      }
    }
  };

  const activeExtracredit = () => {
    if (payloadValue !== null && payloadValue !== undefined) {
      dispatch(createExtraCredit(payloadValue, onSuccess));
    } else {
      NotificationManager.error(
        'Opps algo ha salido mal, por favor intente de nuevo.',
      );
    }
  };
  const validationSchema = yup.object({
    amount: yup
      .string('Introduce un monto')
      .required('Campo requerido')
      .matches(/^\d{1,5}$/, 'Ingresar formato valido $12345')
      .test(
        'checkPrice',
        'El Valor debe ser menor o igual al monto aprobado',
        function (value) {
          if (
            parseInt(value) <= parseInt(extraCreditResponse[0]?.amount) &&
            parseInt(value) > 0
          ) {
            return true; // Válido
          }
          return false; // No válido
        },
      ),
  });
  const validationSchemaBank = yup.object({
    bank: yup.string('Introduce un monto').required('Banco es requerido'),
    cuenta: yup
      .string('Introduce un monto')
      .required('Cuenta de banco es requerido'),
    typeAccount: yup
      .string('Introduce un monto')
      .required('Tipo de cuenta es requerido'),
  });
  const [voucherData, setVoucherData] = useState([]);

  const handleCheckboxChange = (index, option) => {
    setSelectedOption(index);
    setSelectcode(option.uniqueCode);
    setSelectPlant([
      {
        title: 'Monto de Operación',
        value: formats.formatNumberThousands(option?.balanceAmount),
      },
      {
        title: 'Cantidad de Cuotas',
        value: option?.loanTerm,
      },
      {
        title: 'Monto Mensual',
        value: formats.formatNumberThousands(option?.monthlyQuota),
      },
      {
        title: 'Tasa de Interés',
        value: (parseFloat(option?.interestPct) * 100).toFixed(2) + '%',
      },
    ]);
    setVoucherData([
      {
        title: 'Monto de Operación',
        value: formats.formatNumberThousands(option?.balanceAmount),
      },
      {
        title: 'Cantidad de Cuotas',
        value: option?.loanTerm,
      },
      {
        title: 'Monto Mensual',
        value: formats.formatNumberThousands(option?.monthlyQuota?.toFixed(2)),
      },
      {
        title: 'Fecha',
        value: moment(option?.lastModifiedDate).format('MM/DD/YYYY'),
      },
      {
        title: 'Hora',
        value: moment().format('hh:mm A'),
      },
      // {
      //   title: 'Próximo Pago',
      //   value: moment(option.startDate).format('MM/DD/YYYY'),
      // },
    ]);
  };
  const optionsAmount = [
    {
      title: 'Monto Máximo Aprobado',
      subtitle: formats.formatNumberThousands(extraCreditResponse[0]?.amount),
    },
    {
      title: 'Otro Monto',
      subtitle: 'Introduce el monto personalizado',
    },
  ];

  const toggleDrawer = open => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    if (open === false) {
      setActualStep(0);
    }
    // setOpen(open)
  };

  const handleNextStep = e => {
    e.preventDefault();
    const steps =
      selectedOption === 1 ? StepperAmount.length : StepperCustomAmount.length;
    if (actualStep < steps - 1) {
      setActualStep(actualStep + 1);
    }
  };
  const onFinished = success => {
    if (success) {
      setMainSelectedOption(0);
    }
  };
  const onNexts = success => {
    if (success) {
      const steps =
        selectedOption === 1 ? StepperAmount.length : StepperCustomAmount.length;
      if (actualStep < steps - 1) {
        setActualStep(actualStep + 1);
      }
    }
  };
  const handleCheckAmount = e => {
    if (e === 0) {
      dispatch(
        getPlants(
          {
            customerProductId: extraCreditResponse[0]?.customerProductId,
            amountoToEvaluate: parseFloat(extraCreditResponse[0]?.amount),
          },
          onFinished,
        ),
      );
    } else {
      setMainSelectedOption(e);
    }
  };
  const handlePreviousStep = e => {
    if (actualStep > 0) {
      setActualStep(actualStep - 1);
    } else if (actualStep === 0) {
      setMainSelectedOption(null);
      setSelectedOption(null);
      setTempOption(null);
    }
  };

  const Amount = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          justifyContent: 'space-between',
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
        }}>
        <Typography variant="titleBanner">Solicitar Extra Crédito</Typography>
        <Typography variant="subtitleBanner">Selecciona el monto</Typography>
        <IconButton
          style={{
            position: 'absolute',
            width: 28,
            height: 28,
            borderRadius: 8,
            right: 28,
            borderWidth: 1.6,
            borderStyle: 'solid',
            borderColor: '#D0D5DD',
          }}
          onClick={handleCloses}>
          <CloseRoundedIcon
            fontSize="small"
            sx={{ color: 'var(--specific-light-content-black)', margin: 0.4 }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '16px 24px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          alignSelf: 'stretch',
        }}>
        {optionsAmount.map((option, index) => (
          <ButtonCheckbox
            key={index}
            icon={
              <AttachMoneyIcon
                sx={{
                  color: '#026E18',
                }}
              />
            }
            title={option.title}
            subtitle={option.subtitle}
            isSelected={tempOption === index}
            onCheckboxChange={() => {
              setTempOption(index);
              setSelectedAmount(
                parseInt(option?.subtitle?.replace(/,/g, ''), 10),
              );
            }}
          />
        ))}
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          padding: '16px',
          marginTop: 'auto',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          alignSelf: 'stretch',
        }}>
        <LoadingButton
          variant="contained"
          disabled={tempOption === null}
          loading={loadAmount}
          sx={{
            display: 'flex',
            height: '56px',
            padding: '0px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
            borderRadius: '100px',
          }}
          onClick={() => {
            // setMainSelectedOption(tempOption)
            // tempOption === 0 &&
            handleCheckAmount(tempOption);
            // dispatch(
            //   getPlants({
            //     customerProductId: customerProductId,
            //     amountoToEvaluate: parseFloat(amount),
            //   }),
            // )
          }}>
          <Typography variant="buttonStyle">Continuar</Typography>
        </LoadingButton>
      </Box>
    </CustomStyledBox>
  );

  const Installment = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
        }}>
        <Typography variant="titleBanner">Solicitar Extra Crédito</Typography>
        <Typography variant="subtitleBanner">
          Selecciona tu plazo de cuotas
        </Typography>
        <IconButton
          style={{
            position: 'absolute',
            width: 28,
            height: 28,
            borderRadius: 8,
            right: 28,
            borderWidth: 1.6,
            borderStyle: 'solid',
            borderColor: '#D0D5DD',
          }}
          onClick={handleCloses}>
          <CloseRoundedIcon
            fontSize="small"
            sx={{ color: 'var(--specific-light-content-black)', margin: 0.4 }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '16px 24px',
          flexDirection: 'column',
          WebkitAlignItems: 'flex-start',
          WebkitBoxAlign: 'flex-start',
          msFlexAlign: 'flex-start',
          alignItems: 'unset',
          gap: '8px',
          WebkitAlignSelf: 'stretch',
          msFlexItemAlign: 'stretch',
          alignSelf: 'stretch',
          WebkitFlex: '1',
          msFlex: '1',
          flex: 1,
          overflow: 'hidden',
        }}>
        <Box
          sx={{
            flexDirection: 'column',
            width: '100%',
          }}>
          <Box
            sx={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              setSelectPlant(null);
              setSelectedOption(null);
              handlePreviousStep();
            }}>
            <ButtonBackModal
              types="Modal"
              onClick={() => {
                setSelectPlant(null);
                setSelectedOption(null);
                handlePreviousStep();
              }}
              texto="Volver"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <Typography variant="amountStyle">
              {formats.formatNumberThousands(selectAmount)}
            </Typography>
            <Typography variant="subtitleAmountDetails">
              Monto Escogido
            </Typography>
          </Box>
        </Box>
        <BoxOption>
          <List style={{ overflow: 'auto', height: '100%' }}>
            {Plans &&
              Plans?.map((option, index) => (
                <Box
                  style={{
                    marginTop: 8,
                    marginBottom: 8,
                  }}>
                  <ButtonCheckbox
                    key={index}
                    step={true}
                    iconStyle={{
                      width: '2.5rem',
                      height: '2.5rem',
                      marginTop: 4,
                      marginBottom: 4,
                      marginRight: 10,
                    }}
                    icon={<UpdateIcon sx={{ color: '#026E18' }} />}
                    style={{ paddingTop: 4, paddingBottom: 4 }}
                    title={`${option.loanTerm} Meses`}
                    subtitle={option.subtitle}
                    highlight={option.highlight}
                    isSelected={selectedOption === index}
                    onCheckboxChange={() => handleCheckboxChange(index, option)}
                  />
                </Box>
              ))}
          </List>
        </BoxOption>
        <Box
          sx={{
            display: 'flex',
            // flex: 0.5,
            // padding: '16px',
            marginTop: 'auto',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            alignSelf: 'stretch',
            justifyContent: 'flex-end',
          }}>
          <Button
            variant="contained"
            disabled={selectedOption === null}
            sx={{
              display: 'flex',
              height: '56px',
              padding: '0px 16px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              alignSelf: 'stretch',
              borderRadius: '100px',
            }}
            onClick={handleNextStep}>
            <Typography variant="buttonStyle">Continuar</Typography>
          </Button>
        </Box>
      </Box>
      {/* <Divider /> */}
    </CustomStyledBox>
  );

  const InstallmentSimulation = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
        }}>
        <Typography variant="titleBanner">Solicitar Extra Crédito</Typography>
        <Typography variant="subtitleBanner">
          Simulación de pago en cuotas
        </Typography>
        <IconButton
          style={{
            position: 'absolute',
            width: 28,
            height: 28,
            borderRadius: 8,
            right: 28,
            borderWidth: 1.6,
            borderStyle: 'solid',
            borderColor: '#D0D5DD',
          }}
          onClick={handleCloses}>
          <CloseRoundedIcon
            fontSize="small"
            sx={{ color: 'var(--specific-light-content-black)', margin: 0.4 }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '16px 24px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '24px',
          alignSelf: 'stretch',
          flex: 1,
        }}>
        <Box sx={{ flexDirection: 'column', width: '100%' }}>
          <Box
            sx={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={handlePreviousStep}>
            <ButtonBackModal
              types="Modal"
              onClick={handlePreviousStep}
              texto="Volver"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Typography variant="amountStyle">
              {selectPlan && selectPlan[2]?.value}
            </Typography>
            <Typography variant="subtitleAmountDetails">{`Por ${selectPlan &&
              selectPlan[1]?.value} meses`}</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            background: 'var(--global-gray-10)',
            padding: 2,
            width: '100%',
            borderRadius: 2.5,
          }}>
          {loadAmount ? (
            <div></div>
          ) : (
            <InstallmentSimulationDetailItem data={selectPlan} />
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            // flex: 0.5,
            // padding: '16px',
            marginTop: 'auto',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            alignSelf: 'stretch',
          }}>
          <Typography
            variant="infoTermsDisplay"
            sx={{
              margin: '0 2rem !important',
              marginBottom: '1rem !important',
            }}>
            Esta es una simulación del pago a cuotas. Si decides Continuar, se
            realizará la solicitud.
          </Typography>
          <Button
            variant="contained"
            sx={{
              display: 'flex',
              height: '56px',
              padding: '0px 16px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              alignSelf: 'stretch',
              borderRadius: '100px',
            }}
            onClick={handleNextStep}>
            <Typography variant="buttonStyle">Continuar</Typography>
          </Button>
        </Box>
      </Box>
      {/* <Divider /> */}
    </CustomStyledBox>
  );

  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      setSelectedAmount(parseInt(values.amount));
      dispatch(
        getPlants(
          {
            customerProductId: extraCreditResponse[0]?.customerProductId,
            amountoToEvaluate: parseFloat(values.amount),
          },
          onNexts,
        ),
      );
    },
  });
  const formikBank = useFormik({
    initialValues: {
      bank: '',
      cuenta: '',
      typeAccount: '',
    },
    validationSchema: validationSchemaBank,
    onSubmit: values => {
      const dataBank = banks.filter(e => e.name === values.bank);
      if (Array.isArray(dataBank) && dataBank.length > 0) {
        const extractDataBank = {
          financialInstitutionId: dataBank[0].financialInstitutionId,
          codRuta: dataBank[0].codRuta,
        };
        const payloadIntra = {
          ...extractDataBank,
          bankAccountNumber: values.cuenta,
          amountApproved: parseInt(selectAmount),
          customerFinancialProposalId:
            extraCreditResponse[0]?.customerFinancialProposalId,
          customerId: extraCreditResponse[0]?.customerId,
          customerProductId: extraCreditResponse[0]?.customerProductId,
          createdBy: extraCreditResponse[0]?.customerId,
          typePlanFinancialProd: '100006001EX', // Esto esta quemado por que este es el plan de contabilidad de extrafinanimiento FIN.
          typePay: 'C', // Esto esta quemado por que este es el tipo de cuenta de extrafinanimiento FIN.
          TypeBankAccount: values.typeAccount,
          CodExtraCredit: selectCode,
        };
        setPayloadValue(payloadIntra);
        const steps =
          selectedOption === 1
            ? StepperAmount.length
            : StepperCustomAmount.length;
        if (actualStep < steps - 1) {
          setActualStep(actualStep + 1);
        }
      } else {
        NotificationManager.error(
          'Opps algo ha salido mal, por favor intentelo de nuevo.',
        );
      }
    },
  });
  const Account = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
        }}>
        <Typography variant="titleBanner">Solicitar Extra Crédito</Typography>
        <Typography variant="subtitleBanner">
          Introduce tu cuenta bancaria
        </Typography>
        <IconButton
          style={{
            position: 'absolute',
            width: 28,
            height: 28,
            borderRadius: 8,
            right: 28,
            borderWidth: 1.6,
            borderStyle: 'solid',
            borderColor: '#D0D5DD',
          }}
          onClick={handleCloses}>
          <CloseRoundedIcon
            fontSize="small"
            sx={{ color: 'var(--specific-light-content-black)', margin: 0.4 }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '16px 24px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '24px',
          alignSelf: 'stretch',
          flex: 1,
        }}>
        <Box sx={{ flexDirection: 'column', width: '100%' }}>
          <Box
            sx={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={handlePreviousStep}>
            <ButtonBackModal
              types="Modal"
              onClick={handlePreviousStep}
              texto="Volver"
            />
          </Box>
        </Box>
        <form
          onSubmit={formikBank.handleSubmit}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
          }}>
          <Box sx={{ width: '100%' }}>
            <FormControl
              variant="filled"
              fullWidth
              focused
              sx={{
                marginTop: '11px',
              }}>
              <InputLabel htmlFor="filled-select" style={labelStyles}>
                Seleccionar tipo de cuenta
              </InputLabel>
              <Select
                disableUnderline
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                InputProps={{
                  style: {
                    borderBottom: 'none', // Elimina la línea de abajo
                  },
                }}
                sx={{
                  bgcolor: '#FFF !important',
                  borderRadius: '16px',
                  border: '1px solid #D0D5DD',
                  '& fieldset': {
                    border: 'none', // Elimina la línea de abajo
                  },
                  '& .MuiSelect-root::after': {
                    display: 'none', // Puedes sobrescribir el estilo de ::after aquí
                  },
                  '& select': {
                    padding: 6,
                    '&:after': { borderBottom: 0 },
                  },
                }}
                name="typeAccount"
                label="Banco destino"
                value={formikBank.values.typeAccount}
                onChange={formikBank.handleChange}
                onBlur={formikBank.handleBlur}
                error={false}
                helperText={
                  formikBank.touched.typeAccount &&
                  formikBank.errors.typeAccount
                }>
                <MenuItem value="CORRIENTE">CUENTA CORRIENTE</MenuItem>
                <MenuItem value="AHORRO">CUENTA AHORRO</MenuItem>
              </Select>
              <FormHelperText
                sx={{
                  color: 'error.main',
                }}>
                {formikBank.touched.typeAccount &&
                  formikBank.errors.typeAccount}
              </FormHelperText>
            </FormControl>
            <FormControl
              variant="filled"
              fullWidth
              focused
              sx={{
                marginTop: '11px',
              }}>
              <InputLabel htmlFor="filled-select" style={labelStyles}>
                Seleccionar banco
              </InputLabel>
              <Select
                disableUnderline
                fullWidth
                label="Selecciona una opción"
                name="bank"
                sx={{
                  bgcolor: '#FFF !important',
                  borderRadius: '16px',
                  border: '1px solid #D0D5DD',
                  '& select': {
                    padding: 6,
                    '&:after': { borderBottom: 0 },
                  },
                }}
                value={formikBank.values.bank}
                onChange={formikBank.handleChange}
                onBlur={formikBank.handleBlur}
                error={
                  formikBank.touched.bank && Boolean(formikBank.errors.bank)
                }
                helperText={formikBank.touched.bank && formikBank.errors.bank}
                inputProps={{
                  id: 'filled-select',
                  style: {
                    borderBottom: 'none', // Elimina la línea de abajo
                  },
                }}>
                {banks.map(bank => {
                  return (
                    <MenuItem key={bank.id} value={bank.name}>
                      {bank.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText
                sx={{
                  color: 'error.main',
                }}>
                {formikBank.touched.bank && formikBank.errors.bank}
              </FormHelperText>
            </FormControl>
            <TextFields
              focused
              fullWidth
              label="Ingresar número de cuenta"
              placeholder="0346 4465 4233 0675"
              id="reddit-input"
              variant="filled"
              style={{
                marginTop: 11,
              }}
              name="cuenta"
              value={formikBank.values.cuenta}
              onChange={formikBank.handleChange}
              onBlur={formikBank.handleBlur}
              error={
                formikBank.touched.cuenta && Boolean(formikBank.errors.cuenta)
              }
              helperText={formikBank.touched.cuenta && formikBank.errors.cuenta}
              InputProps={{
                inputComponent: NumericFormatCustomBank,
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              // flex: 0.5,
              // padding: '16px',
              marginTop: 'auto',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '8px',
              alignSelf: 'stretch',
            }}>
            <Typography
              variant="infoTermsDisplay"
              sx={{
                marginBottom: '1rem !important',
              }}>
              Al proporcionar estos datos confirmas que esta cuenta bancaria es
              la correcta para recibir tu extra crédito.
            </Typography>
            <LoadingButton
              variant="contained"
              loading={loadAmount}
              type="submit"
              sx={{
                display: 'flex',
                height: '56px',
                padding: '0px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                alignSelf: 'stretch',
                borderRadius: '100px',
              }}
            // onClick={handleNextStep}
            >
              <Typography variant="buttonStyle">Continuar</Typography>
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </CustomStyledBox>
  );
  const Voucher = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
        }}>
        <Typography variant="titleBanner">Solicitar Extra Crédito</Typography>
        <Typography variant="subtitleBanner">Comprobante</Typography>
        <IconButton
          style={{
            position: 'absolute',
            width: 28,
            height: 28,
            borderRadius: 8,
            right: 28,
            borderWidth: 1.6,
            borderStyle: 'solid',
            borderColor: '#D0D5DD',
          }}
          onClick={handleCloses}>
          <CloseRoundedIcon
            fontSize="small"
            sx={{ color: 'var(--specific-light-content-black)', margin: 0.4 }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '16px 24px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '24px',
          alignSelf: 'stretch',
          flex: 1,
        }}>
        <Box sx={{ flexDirection: 'column', width: '100%' }}>
          <Box
            sx={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={handlePreviousStep}>
            <ButtonBackModal
              types="Modal"
              onClick={handlePreviousStep}
              texto="Volver"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 2,
            }}>
            <CheckCircleIcon />
            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <Typography variant="alertSubtitle">
                ¡Extra crédito solicitado!
              </Typography>
              <Typography variant="alertTitle">
                Recibirás el crédito solicitado en 72 horas hábiles.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            background: 'var(--global-gray-10)',
            padding: 2,
            width: '100%',
            borderRadius: 2.5,
          }}>
          <InstallmentSimulationDetailItem data={voucherData} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            // flex: 0.5,
            // padding: '16px',
            marginTop: 'auto',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            alignSelf: 'stretch',
          }}>
          <Button
            variant="contained"
            sx={{
              display: 'flex',
              height: '56px',
              padding: '0px 16px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              alignSelf: 'stretch',
              borderRadius: '100px',
            }}
            onClick={handleCloses}>
            <Typography variant="buttonStyle">Continuar</Typography>
          </Button>
        </Box>
      </Box>
      {/* <Divider /> */}
    </CustomStyledBox>
  );
  const [windowHeight, setWindowHeight] = useState(0);

  React.useEffect(() => {
    // Función para actualizar la altura de la ventana
    const updateWindowHeight = () => {
      const newHeight = window.innerHeight;
      setWindowHeight(newHeight);
    };

    // Agregar un event listener para actualizar la altura de la ventana cuando cambie el tamaño de la ventana
    window.addEventListener('resize', updateWindowHeight);

    // Llama a la función para obtener la altura inicial de la ventana
    updateWindowHeight();

    // Elimina el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', updateWindowHeight);
    };
  }, []);

  // Calcula el 50% de la altura de la ventana
  const halfHeight = windowHeight * 0.5;
  const TermsAndConditions = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
        }}>
        <Typography variant="titleBanner">Términos y condiciones</Typography>
        <IconButton
          style={{
            position: 'absolute',
            width: 28,
            height: 28,
            borderRadius: 8,
            right: 28,
            borderWidth: 1.6,
            borderStyle: 'solid',
            borderColor: '#D0D5DD',
          }}
          onClick={handleCloses}>
          <CloseRoundedIcon
            fontSize="small"
            sx={{ color: 'var(--specific-light-content-black)', margin: 0.4 }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '16px 24px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '24px',
          alignSelf: 'stretch',
          flex: 1,
        }}>
        <Box sx={{ flexDirection: 'column', width: '100%' }}>
          <Box
            sx={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              // cursor: 'pointer',
            }}
            onClick={handlePreviousStep}>
            <ButtonBackModal
              types="Modal"
              onClick={handlePreviousStep}
              texto="Volver"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 2,
            }}>
            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <Typography variant="titleBanner" textAlign="center">
                TÉRMINOS Y CONDICIONES DE EXTRA CRÉDITO
              </Typography>
              {/* <Typography variant="titleBanner">Cambio a Cuotas</Typography> */}
            </Box>
          </Box>
        </Box>
        <Paper
          sx={{
            height: `${halfHeight + 40}px`,
          }}>
          <Box
            sx={{
              height: '100%',
              overflowY: 'scroll',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              textAlign: 'justify',
            }}>
            <a
              style={{
                alignSelf: 'stretch',
                color: 'rgba(16, 24, 40, 0.64)',
                fontFamily: 'Red Hat Text',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '18px /* 128.571% */',
                cursor: 'grab',
              }}>
              Los siguientes enunciados regulan la relación entre CM FINANCIERA,
              S.A., (en adelante “EL EMISOR”) y el TARJETAHABIENTE TITULAR de
              una tarjeta de crédito de EL EMISOR, que utilice el programa de
              extra crédito, (en adelante “EL TARJETAHABIENTE”), en adición a lo
              estipulado en el Contrato de Uso y Emisión de Tarjeta de Crédito,
              los Términos y sus anexos{' '}
            </a>
            <a
              style={{
                textDecorationLine: 'underline',
                color: '#000',
                fontWeight: '600',
                cursor: 'grab',
              }}>
              I. Términos Generales del Extra Crédito{' '}
            </a>
            <a
              style={{
                color: '#000',
                fontWeight: '600',
                cursor: 'grab',
              }}>
              A. Requisitos
            </a>
            <a
              style={{
                alignSelf: 'stretch',
                color: 'rgba(16, 24, 40, 0.64)',
                fontFamily: 'Red Hat Text',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '18px',
                cursor: 'grab',
              }}>
              <br />
              Para aplicar e utilizar el progama de Extra Crédito, EL
              TARJETAHABIENTE deberá cumplir con los siguientes requisitos a
              saber:
              <br /> 1. Tener la Tarjeta CMF activa, con mínimo de 6 meses de
              buen manejo <br />
              2. Cumplir con la política de crédito interna de CMF
              <br />
              Toda solicitud realizada por EL TARJETAHABIENTE para acceder al
              programa de Extra Crédito, estará sujeta a la aprobación final de
              EL EMISOR, según las políticas de crédito.
            </a>
            <a
              style={{
                color: '#000',
                fontWeight: '600',
                cursor: 'grab',
              }}>
              {' '}
              B. Condiciones
            </a>
            <a
              style={{
                color: '#000',
                fontWeight: '600',
                ...globalTerms,
              }}>
              Las condiciones del progarama de Extra Crédito, son:
            </a>
            <a
              style={{
                alignSelf: 'stretch',
                color: 'rgba(16, 24, 40, 0.64)',
                fontFamily: 'Red Hat Text',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '18px /* 128.571% */',
                cursor: 'grab',
              }}>
              <br /> 1. EL TARJETAHABIENTE tendrá un plazo para pagar el Extra
              Crédito de 6 hasta 60 meses, según sea aprobado por EL EMISOR.
              <br />
              2. La tasa de interés del Extra Crédito, será la misma que
              mantiene el producto de tarjeta de crédito CMF de EL
              TARJETAHABIENTE. EL EMISOR mantiene el tarifario vigente en su
              página web, lo cual reconoce y acepta EL TARJETAHABIENTE, al
              recibir la aprobación del Extra Crédito.. <br />
              3. El Extra Crédito se hará efectivo siempre y cuando EL
              TARJETAHABIENTE se mantenga al día en los pagos correspondientes a
              su tarjeta de crédito CMF. <br />
              4. El Extra Crédito se hará efectivo en un periodo no mayor de 72
              horas, posterior a la aprobación del mismo, en la cuenta a nombre
              de EL TARJETAHABIENTE.
              <br />
              5. El Extra Crédito tiene un cargo por servicio del cinco por
              ciento (5%) + ITBMS. El cargo por servicio, es calculado sobre el
              monto otorgado y es cargado una única vez en el estado de cuenta
              de EL TARJETAHABIENTE, en el mes inmediatamente siguiente al
              desembolso del Extra Crédito. <br />
              6. El Extra Crédito tiene cargo mensual de FECI 1%, si aplica,
              para los que mantengan un saldo mensual igual o mayor a $5,000.00,
              y se verá detallado en el estado de cuenta de la tarjeta de
              crédito. <br />
              7. El Extra Crédito cuenta con seguro de vida (saldo deudor),
              dicho seguro es calculado sobre el saldo adeudado del Extra
              Crédito. EL EMISOR mantiene el tarifario vigente en su página web,
              lo cual reconoce y acepta EL TARJETAHABIENTE, al recibir la
              aprobación del Extra Crédito.
            </a>
            <a
              style={{
                textDecorationLine: 'underline',
                color: '#000',
                fontWeight: '600',
                cursor: 'grab',
              }}>
              C. Proceso de extra crédito
            </a>
            <a
              style={{
                alignSelf: 'stretch',
                color: 'rgba(16, 24, 40, 0.64)',
                fontFamily: 'Red Hat Text',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '18px /* 128.571% */',
                cursor: 'grab',
              }}>
              <br />
              1. EL TARJETAHABIENTE valida si mantiene un Extra Crédito pre
              aprobado, mediante llamada, correo electrónico o con su usuario de
              la página web o App CMF de EL EMISOR. <br />
              2. Para solicitar el Extra Créidto, EL TARJETAHABIENTE ingresa en
              la página web o App CMF de EL EMISOR, donde encontrará una
              pantalla emergente en la que se reflejará el ofrecimiento del
              Extra Crédito y podrá solicitar la aprobación del mismo, de
              acuerdo a los términos y condiciones detallados en la página web o
              App CMF de EL EMISOR. <br />
              3. Al momento de solicitar el Extra Crédito, EL TARJETAHABIENTE
              coloca el monto que desea recibir y escoge el plazo a pagar (6 a
              60 meses). <br />
              4. EL TARJETAHABIENTE, debe completar los datos solicitados,
              incluyendo el número de cuenta a la que se debe hacer el depósito
              con el dinero aceptado. <br />
              5. Posteriormente, EL EMISOR revisará y aceptará o rechazará la
              solicitud de Extra Crédito de EL TARJETAHABIENTE, según las
              políticas de crédito de EL EMISOR.
              <br />
              6. De ser aprobado el Extra Crédito, EL TARJETAHABIENTE recibirá
              el dinero indicado en el numeral 4 del literal A del presente
              Reglamento. En el caso que la cuenta de bancaria que proporcionó
              EL TARJETAHABIENTE para recibir el desembolso del Extra Crédito se
              encuentre afectada por un gravamen, pignoración, sobregiro,
              secuestro y/o cualquier otra orden judicial, EL EMISOR no es
              responsable por la cobertura de los fondos.
            </a>{' '}
            <a
              style={{
                textDecorationLine: 'underline',
                color: '#000',
                fontWeight: '600',
              }}>
              D. Aceptación de Condiciones
            </a>
            <a style={globalTerms}>
              <br />
              1. EL TARJETAHABIENTE al aceptar el Extra Crédito estará aceptando
              las condiciones generales establecidas en el presente Reglamento,
              en concordancia con el Contrato de Uso y Emisión de Tarjeta de
              Crédito y sus anexos. <br />
              2. Una vez se haga efectivo el Extra Crédito EL TARJETAHABIENTE es
              responsable de realizar los pagos de forma mensual en las mismas
              fechas de pago de la tarjeta de crédito CMF. <br />
              3. EL TARJETAHABIENTE podrá realizar pagos extraordinarios al
              saldo del Extra Crédito, y la cuota mensual será recalculada con
              el nuevo saldo del mismo. <br />
              4. EL TARJETAHABIENTE podrá realizar la cancelación anticipada del
              Extra Crédito sin cargos de penalidad. <br />
              5. Es obligación de EL TARJETAHABIENTE que utilice el programa, el
              conocer y cumplir con todas las reglas, políticas y condiciones
              del mismo. <br />
              6. En caso de terminación total o parcial del Extra Crédito, EL
              EMISOR comunicará a EL TARJETAHABIENTE con treinta (30) días de
              anticipación. Una vez vencido el término anterior, EL EMISOR no
              tendrá́ ni asumirá́ ninguna responsabilidad civil o legal por las
              cuotas pendientes de pago por parte de EL TARJETAHABIENTE, las
              cuales deberán ser canceladas en su totalidad en el tiempo
              definido. <br />
              7. Las reglas generales, así como, el programa de Extra Crédito
              podrá́ ser variado o eliminado, total o parcialmente por EL EMISOR
              a su discreción y cuyo comunicado será́ informado a EL
              TARJETAHABIENTE con un mínimo de treinta (30) días de
              anticipación, sin tener EL EMISOR ninguna responsabilidad civil o
              legal hacia EL TARJETAHABIENTE pasada la fecha estipulada. Estos
              comunicados será realizados a través del envío de un correo a la
              dirección de correo electrónico proporcionada por EL
              TARJETAHABIENTE de acuerdo con lo establecido en el Contrato de
              Uso y Emisión de Tarjeta de Crédito y sus anexos.
            </a>
          </Box>
        </Paper>
        <Box
          sx={{
            display: 'flex',
            // flex: 0.5,
            // padding: '16px',
            marginTop: 'auto',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            alignSelf: 'stretch',
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <StyledCheckbox
              checked={termsAndCoditionAccepts}
              onChange={({
                nativeEvent: {
                  target: { checked },
                },
              }) => setTermsAndCoditionAccepts(checked)}
              icon={<CheckboxIcon checked={false} />}
              checkedIcon={<CheckboxIcon checked={true} />}
            />
            <div
              style={{
                marginLeft: '8px',
                color: '#101828CC',
                fontFamily: 'Red Hat Text',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '16px',
                letterSpacing: '-0.2px',
                marginRight: '.1rem',
              }}>
              He leído y acepto los
            </div>
            <div
              style={{
                color: '#026E18',
                fontFamily: 'Red Hat Text',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '16px',
                letterSpacing: '-0.2px',
              }}>
              Términos y Condiciones
            </div>
          </Box>
          <LoadingButton
            variant="contained"
            loading={loadCreateExtraCredit}
            disabled={!termsAndCoditionAccepts}
            sx={{
              display: 'flex',
              height: '56px',
              padding: '0px 16px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              alignSelf: 'stretch',
              borderRadius: '100px',
            }}
            onClick={activeExtracredit}>
            <Typography variant="buttonStyle">Continuar</Typography>
          </LoadingButton>
        </Box>
      </Box>
    </CustomStyledBox>
  );

  const StepperInial = [
    {
      screen: 'Amount',
      component: Amount,
    },
  ];

  const CustomAccount = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
        }}>
        <Typography variant="titleBanner">Solicitar Extra Crédito</Typography>
        <Typography variant="subtitleBanner">
          Ingresa el monto personalizado
        </Typography>
        <IconButton
          style={{
            position: 'absolute',
            width: 28,
            height: 28,
            borderRadius: 8,
            right: 28,
            borderWidth: 1.6,
            borderStyle: 'solid',
            borderColor: '#D0D5DD',
          }}
          onClick={handleCloses}>
          <CloseRoundedIcon
            fontSize="small"
            sx={{ color: 'var(--specific-light-content-black)', margin: 0.4 }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '16px 24px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '24px',
          alignSelf: 'stretch',
          flex: 1,
        }}>
        <Box sx={{ flexDirection: 'column', width: '100%' }}>
          <Box
            sx={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={handlePreviousStep}>
            <ButtonBackModal
              types="Modal"
              onClick={handlePreviousStep}
              texto="Volver"
            />
          </Box>
        </Box>
        <form
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
          }}
          onSubmit={formik.handleSubmit}>
          <Box sx={{ padding: 0, width: '100%', marginTop: 0 }}>
            <container.divFiel>
              <CustomAmountTextField
                fullWidth
                label="Monto a solicitarse"
                id="reddit-input"
                variant="filled"
                style={{ marginTop: 11 }}
                placeholder="1000"
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">
                      <MonetizationOnIcon
                        fontSize="small"
                        sx={{
                          color:
                            'var(--specific-light-semantic-brand-secondary)',
                          margin: '0px 8px',
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </container.divFiel>
          </Box>

          <Box
            sx={{
              display: 'flex',
              // flex: 0.5,
              // padding: '16px',
              marginTop: 'auto',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '8px',
              alignSelf: 'stretch',
            }}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={loadAmount}
              sx={{
                display: 'flex',
                height: '56px',
                padding: '0px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                alignSelf: 'stretch',
                borderRadius: '100px',
              }}>
              <Typography variant="buttonStyle">Continuar</Typography>
            </LoadingButton>
          </Box>
        </form>
      </Box>
      {/* <Divider /> */}
    </CustomStyledBox>
  );

  const StepperCustomAmount = [
    {
      screen: 'CustomAccount',
      component: CustomAccount,
    },
    {
      screen: 'Installment',
      component: Installment,
    },
    {
      screen: 'InstallmentSimulation',
      component: InstallmentSimulation,
    },
    {
      screen: 'Account',
      component: Account,
    },
    {
      screen: 'TermsAndConditions',
      component: TermsAndConditions,
    },
    {
      screen: 'Voucher',
      component: Voucher,
    },
  ];
  const StepperAmount = [
    {
      screen: 'Installment',
      component: Installment,
    },
    {
      screen: 'InstallmentSimulation',
      component: InstallmentSimulation,
    },
    {
      screen: 'Account',
      component: Account,
    },
    {
      screen: 'TermsAndConditions',
      component: TermsAndConditions,
    },
    {
      screen: 'Voucher',
      component: Voucher,
    },
  ];
  return (
    <>
      <StyleBox type="s">
        {['right'].map(anchor => (
          <React.Fragment key={anchor}>
            <SwipeableDrawer
              sx={{
                '& .MuiDrawer-paper': {
                  boxShadow: 'none',
                  backgroundColor: '#00000000',
                },
              }}
              anchor="right"
              open={showModal}
              onOpen={toggleDrawer(true)}>
              {MainSelectedOption === 0
                ? StepperAmount[actualStep]['component'](anchor)
                : MainSelectedOption === 1
                  ? StepperCustomAmount[actualStep]['component'](anchor)
                  : StepperInial[actualStep]['component'](anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </StyleBox>
      {systemId !== 'VOLCAN' &&
        <CardAcciones
          image={solicitarExtra}
          text="Solicitar Extra Crédito"
          onClick={handleClose}
          loading={loadExtraFinancial}
        />}
    </>
  );
}

import {Typography, Box} from '@material-ui/core'
import React from 'react'
import {BASE_COLORS, FONT_SIZE, FONT_WEIGHT} from 'src/theme/constants'
import {styled} from '@material-ui/core/styles'
import ArrowBack from '../../assets/images/cmf_linea/arrowBack.svg'
const Container = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '1rem',
  minWidth: '48px',
  height: '48px',
}))
const ButtonBack = styled(Box)(({types}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  ...(types === 'Page' && {
    minWidth: '48px',
    height: '48px',
    borderRadius: '16px',
    color: BASE_COLORS.green,
    backgroundColor: BASE_COLORS.white,

    '&:hover': {
      fontSize: FONT_SIZE.md,
      color: BASE_COLORS.green,
      background: BASE_COLORS.white,
      fontFamily: 'Red Hat Text',
      fontWeight: FONT_WEIGHT.medium,
    },
    '& span': {
      color: BASE_COLORS.green,
      fontFamily: 'Red Hat Text',
      fontWeight: FONT_WEIGHT.medium,
      fontSize: FONT_SIZE.lg,
    },
  }),
  ...(types === 'Modal' && {
    minWidth: '48px',
    height: '48px',
    borderRadius: '16px',
    color: BASE_COLORS.green,
    backgroundColor: 'var(--global-gray-10, #F9FAFB)',
    '&:hover': {
      fontSize: FONT_SIZE.md,
      color: BASE_COLORS.green,
      background: 'var(--global-gray-10, #F9FAFB)',
      fontFamily: 'Red Hat Text',
      fontWeight: FONT_WEIGHT.medium,
    },
    '& span': {
      color: BASE_COLORS.green,
      fontFamily: 'Red Hat Text',
      fontWeight: FONT_WEIGHT.medium,
      fontSize: FONT_SIZE.lg,
    },
  }),
}))
export const VolverTypo = styled(Typography)(({theme}) => ({
  fontSize: '18px',
  fontStyle: 'normal',
  margin: '0px 15px',
  color: '#1018288A',
  fontWeight: 500,
  lineHeight: '22px',
  letterSpacing: '-0.36px',
  fontFamily: 'Red Hat Text',
  cursor: 'pointer',
}))
const ButtonBackModal = props => {
  return (
    <Container types={props.types}>
      <ButtonBack onClick={props.onClick} {...props}>
        <img src={ArrowBack} style={{width: '24px', height: '24px'}} alt="arrow back"></img>
      </ButtonBack>
      <VolverTypo>{props.texto}</VolverTypo>
    </Container>
  )
}

export default ButtonBackModal

import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'

const Paragraph = styled('p')(({style}) => ({
  ...style,
  '.ant-modal-body': {
    width: '70%',
  },
}))

function SubtitleResponsiveOnboarding({minSize, maxSize, text, color, fontFamily, textAlign, style = {}}) {
  const [fontSize, setFontSize] = useState(null)
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth
      let calculatedFontSize = null

      if (screenWidth <= 300) {
        calculatedFontSize = minSize
      } else if (screenWidth >= 1920) {
        calculatedFontSize = maxSize
      } else {
        // Linear interpolation between minSize and maxSize
        const ratio = (screenWidth - 300) / (1920 - 300)
        calculatedFontSize = minSize + ratio * (maxSize - minSize)
      }

      setFontSize(calculatedFontSize)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [minSize, maxSize])
  const fontSizeInEm = fontSize / 18
  return (
    <Paragraph
      style={{
        fontSize: `${fontSizeInEm}em`,
        color: color,
        fontFamily: fontFamily,
        textAlign: textAlign,
        ...style,
      }}>
      {text}
    </Paragraph>
  )
}

export default SubtitleResponsiveOnboarding

export const colorStyle = isVerified => {
  if (!isVerified) {
    return '#666B76 !important'
  }
  return '#FFFFFF !important'
}

/**
 * Validates the given email address against a regular expression.
 *
 * @param {string} email - The email address to validate.
 * @returns {boolean} - True if the email is valid, false otherwise.
 */
export const isEmail = email => {
  if (typeof email !== 'string') return false

  const emailRegex = /^[\w.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i
  return emailRegex.test(email)
}

export const isCode = code => {
  const emailRegex = /^[0-9]{6}$/
  return emailRegex.test(code)
}

/**
 * Validates if the given string is a valid 8-digit phone number.
 *
 * @param {string} phone - The phone number to validate.
 * @returns {boolean} - Returns `true` if the phone number is valid, otherwise `false`.
 */
export const isPhoneNumber = phone => {
  const phoneRegex = /^6\d{7}$/
  return phoneRegex.test(phone)
}

export const selectOptions1 = [
  {
    label: 'País de residencia',
    vale: 'País de residencia',
  },
]

export const selectOptions2 = [
  {
    label: 'Veraguas',
    value: 'Veraguas',
  },
  {
    label: 'Panamá',
    value: 'Panamá',
  },
  {
    label: 'Chiriquí',
    value: 'Chiriquí',
  },
  {
    label: 'Coclé',
    vale: 'Coclé',
  },
]

// Almirante Bocas del Toro Changuinola Chiriquí Grande
export const selectOptions3 = [
  {
    label: 'Almirante',
    vale: 'Almirante',
  },
  {
    label: 'Bocas del Toro',
    value: 'Bocas del Toro',
  },
  {
    label: 'Changuinola',
    value: 'Changuinola',
  },
  {
    label: 'Chiriquí Grande',
    vale: 'Chiriquí Grande',
  },
]

// Alcalde Díaz Ancón Betania Bella Vista

export const selectOptions4 = [
  {
    label: 'Alcalde Díaz',
    vale: 'Alcalde Díaz',
  },
  {
    label: 'Ancón',
    value: 'Ancón',
  },
  {
    label: 'Betania',
    value: 'Betania',
  },
  {
    label: 'Bella Vista',
    vale: 'Bella Vista',
  },
]

// Soltero Casado Divorciado Viudo
export const selectOptions5 = [
  {
    label: 'Soltero',
    value: 'Soltero',
  },
  {
    label: 'Casado',
    value: 'Casado',
  },
  {
    label: 'Divorciado',
    value: 'Divorciado',
  },
  {
    label: 'Viudo',
    value: 'Viudo',
  },
]

// Administrador Contador Diseñador Programador
export const selectOptions6 = [
  {
    label: 'Administrador',
    value: 'Administrador',
  },
  {
    label: 'Contador',
    value: 'Contador',
  },
  {
    label: 'Diseñador',
    value: 'Diseñador',
  },
  {
    label: 'Programador',
    value: 'Programador',
  },
]

// Administrador Contador Diseñador Programador
export const selectOptions7 = [
  {
    label: 'Administrador',
    value: 'Administrador',
  },
  {
    label: 'Contador',
    value: 'Contador',
  },
  {
    label: 'Diseñador',
    value: 'Diseñador',
  },
  {
    label: 'Programador',
    value: 'Programador',
  },
]

/// Enero Febrero Marzo Abril
export const selectOptions8 = [
  {
    label: 'Enero',
    value: 'Enero',
  },
  {
    label: 'Febrero',
    value: 'Febrero',
  },
  {
    label: 'Marzo',
    value: 'Marzo',
  },
  {
    label: 'Abril',
    value: 'Abril',
  },
]

// 2023 2022 2021 2020
export const selectOptions9 = [
  {
    label: '2023',
    value: '2023',
  },
  {
    label: '2022',
    value: '2022',
  },
  {
    label: '2021',
    value: '2021',
  },
  {
    label: '2020',
    value: '2020',
  },
]

// Chile Argentina Colombia Venezuela
export const selectOptions10 = [
  {
    label: 'Chile',
    value: 'Chile',
  },
  {
    label: 'Argentina',
    value: 'Argentina',
  },
  {
    label: 'Colombia',
    value: 'Colombia',
  },
  {
    label: 'Venezuela',
    value: 'Venezuela',
  },
]

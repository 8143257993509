import React from 'react'
import {Box, Step, StepLabel, Stepper} from '@mui/material'
import ColorlibStepIcon from './componets/ColorlibStepIcon'
import ColorlibConnector from './componets/ColorlibConnector'

/**
 * The MultiSelectBar component is a stepper component that displays a list of steps with labels and
 * icons.
 * @category Components
 * @param {number} verificationStep - The current step of the multi-step form.
 * @param {Array.<String>} steps - The steps of the multi-step form.
 * @returns The MultiSelectBar component is returning a JSX element. It is rendering a Box component
 * with a Stepper component inside. The Stepper component is using the activeStep prop to determine the
 * current step, and the alternativeLabel prop to display the steps as labels instead of numbers. It
 * also uses a custom connector component called ColorlibConnector.
 */
const MultiSelectBar = ({verificationStep = 0, steps = []}) => {
  return (
    <Box sx={{width: '100%', display: 'flex'}}>
      <Stepper
        activeStep={verificationStep}
        alternativeLabel
        connector={<ColorlibConnector />}
        sx={{width: '100%'}}>
        {steps?.length > 0 &&
          steps?.map((label, index) => (
            <Step
              key={label}
              sx={{
                fontSize: '10px !important',
                '& .MuiStepLabel-root .Mui-active': {
                  color: '#026E18',
                  fontWeight: 'normal'
                },
              }}>
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                StepIconProps={{title: index + 1}}
                sx={{fontSize: '10px !important', color: '#9EA1A8'}}>
                {label}
              </StepLabel>
            </Step>
          ))}
      </Stepper>
    </Box>
  )
}

export default MultiSelectBar

import React, { useEffect, useState } from 'react';

function CountdownTimer({minutes, onFinish}) {
  // Estado para almacenar el tiempo restante en segundos
  const [timeLeft, setTimeLeft] = useState(minutes * 60);

  useEffect(() => {
    // Si el tiempo restante es 0, no hacer nada
    if (timeLeft <= 0) {
      if (onFinish) onFinish();
      return;
    }

    // Configura el temporizador para actualizar el tiempo cada segundo
    const timer = setInterval(() => {
      setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
    }, 1000);

    // Limpia el temporizador cuando el componente se desmonte o el tiempo llegue a 0
    return () => clearInterval(timer);
  }, [timeLeft]);

  // Convierte los segundos restantes en minutos y segundos
  const minutesLeft = Math.floor(timeLeft / 60);
  const secondsLeft = timeLeft % 60;

  return (
    <div>
      <p
        style={{
          textAlign: 'center',
          color: ['16px', '#026E18'],
          display: 'flex',
          fontWeight: 'bold',
          justifyContent: 'center',
          '@media (min-width: 768px)': {marginTop: '5px'},
        }}>
        {minutesLeft}:{secondsLeft.toString().padStart(2, '0')}
      </p>
    </div>
  );
}

export default CountdownTimer;

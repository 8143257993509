import { styled } from '@material-ui/core/styles'
import { Divider, Typography } from '@mui/material'
import React from 'react'

const ContainerBodyDetail = styled('div')(({theme}) => ({
  borderRadius: 20,
  paddingHorizontal: 20,
  // backgroundColor: BRANDCOLORS.GRAY10,
  paddingVertical: 5,
}))

const View = styled('div')(({theme}) => ({}))

const ItemDetail = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 15,
  marginBottom: 15,
  marginLeft: 20,
  marginRight: 20,
}))

export default ({data}) => {
  
  return (
    <ContainerBodyDetail>
      {data&&data.map((d, index) => {
        if (index >= 0 && index < data.length - 1) {
          return (
            <View key={index.toString()}>
              <ItemDetail key={index.toString()}>
                <Typography variant="detailsExtra">{d.title}</Typography>
                <Typography variant='detailsAmount'>{d.value}</Typography>
              </ItemDetail>
              <Divider />
            </View>
          )
        } else {
          return (
            <View key={index.toString()}>
              <ItemDetail>
                <Typography variant="detailsExtra">{d.title}</Typography>
                <Typography variant='detailsAmount'>{d.value}</Typography>
              </ItemDetail>
            </View>
          )
        }
      })}
    </ContainerBodyDetail>
  )
}

import produce from 'immer';

const initialState = {
  loadCreatedFactoring: false,
  getDataFactoring: [],
  catalogActivity: [],
  loadingSaveInfo: false,

  loadCreatedDignatary: false,
  getDataDignatary: [],
  loadingSaveInfoDignatary: false,
  getDataTypesDignataries: [],
  infoDignatarySelected: []
};

const FactoringReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'GET_LOAD_ENTERPRISE_DATA':
        draft.loadCreatedFactoring = action.payload;
        break;
      case 'GET_ENTERPRISE_DATA':
        draft.getDataFactoring = action.payload;
        break;
      case 'GET_ENTERPRISE_DATA_CLEAN':
        draft.getDataFactoring = [];
        break;
      case 'GET_CATALOG_TYPE_ACTIVITY':
        draft.catalogActivity = action.payload;
        break;
      case 'LOADING_SAVE_INFO':
        draft.loadingSaveInfo = action.payload;
        break;
      case 'GET_LOAD_DIGNATARY_DATA':
        draft.loadCreatedDignatary = action.payload;
        break;
      case 'GET_DIGNATARY_DATA':
        draft.getDataDignatary = action.payload;
        break;
      case 'GET_TYPES_DIGNATARIES':
        draft.getDataTypesDignataries = action.payload;
        break;
      case 'GET_DIGNATARY_DATA_CLEAN':
        draft.getDataDignatary = [];
        break;
      case 'LOADING_SAVE_DIGNATARY_INFO':
        draft.loadingSaveInfoDignatary = action.payload;
        break;
      case 'INFO_DIGNATARY_SELECTED':
        draft.infoDignatarySelected = action.payload;
        break; 
      default: break;
    }
  });
export default FactoringReducer;

import PropTypes from "prop-types"
import {Typography} from '@mui/material'
import React from 'react'

function TitleResponsive({
  fontSize,
  text,
  color,
  textAlign,
  fontWeitgh,
  lineHeight,
  letterSpacing,
}) {

  return (
    <Typography
      style={{
        fontSize: fontSize,
        color: color,
        fontFamily: 'Red Hat Text',
        textAlign: textAlign,
        fontWeight: fontWeitgh,
        lineHeight: lineHeight,
        letterSpacing: letterSpacing,
      }}>
      {text}
    </Typography>
  )
}

TitleResponsive.propTypes = {
  color: PropTypes.any,
  fontSize: PropTypes.any,
  fontWeitgh: PropTypes.any,
  letterSpacing: PropTypes.any,
  lineHeight: PropTypes.any,
  text: PropTypes.any,
  textAlign: PropTypes.any
}

export default TitleResponsive

import { LoadingButton } from '@mui/lab'
import { Box, styled } from '@mui/material'
import React from 'react'
import { Modal } from 'react-responsive-modal'
import WrapperSide from 'src/components/Aside/Wrapper'
import CMFLogo from 'src/components/Logos/CMF'
import TitleResponsiveOnboarding from 'src/components/TitleInput/TitleResponsiveOnboarding'
import 'src/containers/OnboardingCredito/Modals/stylesModal.css'
import { BreakPointTheme } from 'src/theme/breakpoints'
import BackArrow from '../../assets/images/onboardingCredito/backArrow.svg'
import exclamationSVG from '../../assets/images/onboardingCredito/exclamation.svg'
import SCREENS from './screens'
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}))
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))
const IdentityMissingFields = props => {
  const goToScanId = () => props.goToStep(SCREENS.ScanID)
  const goToWelcome = () => props.goToStep(SCREENS.Welcome)
  const renderConfirmationModal = () => {
    return (
      <Modal
        open={true}
        onClose={() => { }}
        showCloseIcon={false}
        classNames={{
          modal: 'identity__confirmed',
        }}
        center>
        <div
          role='button'
          className="cursor-pointer md:hidden absolute left-5 bg-[#F2F4F7] inline-block p-2 text-white rounded-lg">
          <img src={BackArrow} alt="Back arrow" />
        </div>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '0px', // This sets the margin-top to 0 pixels
            '@media (min-width: 768px)': {
              // This is an example breakpoint for 'md:' in Tailwind CSS
              marginTop: '0px',
            },
            [BreakPointTheme.breakpoints.down('sm')]: {
              height: '83%',
              justifyContent: 'space-evenly',
            },
          }}
          style={{ margin: '1rem 0 .3rem 0' }}>
          <div className="w-[90%] md:w-[90%] flex items-center justify-center flex-col mt-[0.3em]!">
            <img
              src={exclamationSVG}
              alt="Exclamation SVG"
              style={{
                height: '59px',
                width: '59px',
              }}
            />
            <TitleResponsiveOnboarding
              minSize={30}
              maxSize={35}
              text="Lo sentimos, ha ocurrido un error al intentar leer su información biométrica. ¿Desea reintentar?"
              color="#011E41"
              fontFamily="Red Hat Text"
            />
            <Box
              sx={{
                marginTop: '32px', // Equivalent to mt-[32px]
                width: '100%', // Equivalent to w-full

                // Media queries for different breakpoints
                [BreakPointTheme.breakpoints.up('md')]: {
                  marginTop: '1rem',
                },
              }}>

              <div className="flex justify-center gap-[14px]">
                <LoadingButton
                  data-testid="btn-continue-modal"
                  sx={{
                    outline: 'none', // Equivalent to outline-none
                    color: 'white', // Equivalent to text-white
                    borderRadius: '9999px', // Equivalent to rounded-full
                    display: 'flex', // Equivalent to flex
                    alignItems: 'center', // Equivalent to items-center
                    justifyContent: 'center', // Equivalent to justify-center
                    height: '60px', // Default height, equivalent to h-[60px]
                    fontSize: '20px', // Default font size, equivalent to text-[20px]
                    width: '100%', // Default width, equivalent to w-full
                    font: 'normal normal normal 25px/22px Red Hat Text',
                    // Media queries for different breakpoints
                    [BreakPointTheme.breakpoints.up('md')]: {
                      fontSize: '25px', // md:text-[25px]
                      height: '76px', // md:h-[76px]
                      width: '50%', // md:w-[50%]
                    },
                    [BreakPointTheme.breakpoints.up('lg')]: {
                      width: '30%', // lg:w-[30%]
                    },
                  }}
                  style={{ background: 'var(--linear-bg)' }}
                  onClick={goToScanId}>
                  Si
                </LoadingButton>
                <LoadingButton
                  sx={{
                    outline: 'none', // Equivalent to outline-none
                    backgroundColor: 'white', // Equivalent to bg-white
                    border: '2px solid',
                    borderColor: 'var(--light-green)', // Make sure to define --light-green in your CSS
                    color: '#026E18', // Equivalent to the given hex color
                    borderRadius: '9999px', // Equivalent to rounded-full
                    display: 'flex', // Equivalent to flex
                    alignItems: 'center', // Equivalent to items-center
                    justifyContent: 'center', // Equivalent to justify-center
                    height: '60px', // Default height, equivalent to h-[60px]
                    fontSize: '20px', // Default font size, equivalent to text-[20px]
                    width: '100%', // Default width, equivalent to w-full
                    font: 'normal normal normal 25px/22px Red Hat Text',
                    // Media queries for different breakpoints
                    [BreakPointTheme.breakpoints.up('md')]: {
                      fontSize: '25px', // md:text-[25px]
                      height: '76px', // md:h-[76px]
                      width: '50%', // md:w-[50%]
                    },
                    [BreakPointTheme.breakpoints.up('lg')]: {
                      width: '30%', // lg:w-[30%]
                    },
                  }}
                  onClick={goToWelcome}>
                  No
                </LoadingButton>
              </div>
            </Box>
          </div>
        </Box>
        <p
          style={{
            marginTop: ' 20px',
            marginBottom: '20px',
            color: 'rgba(2, 110, 24, 1)',
            textAlign: ' center',
            letterSpacing: ' 0px',
            opacity: ' 1',
          }}>
          <strong>¿Necesitas asistencia en tu proceso? </strong>
          <br></br> contáctanos a servicioalcliente@cmf.com.pa
        </p>
      </Modal>
    )
  }
  return (
    <>
      {renderConfirmationModal()}
      <Container>
        <WrapperSide background="rgb(255, 255, 255)" />
        <Wrapper
          style={{
            flex: 1,
            height: '100%',
            justifyContent: 'center',
            background:
              'linear-gradient(rgb(3 37 12), rgb(6 73 21), rgb(11 113 29))',
          }}>
          <center>
            <CMFLogo />
          </center>
        </Wrapper>
      </Container>
    </>
  )
}

export default IdentityMissingFields

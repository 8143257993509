import React from 'react'
import styled from '@emotion/styled'
import AsideLogo from '../../components/Aside/AsideLogo'
import Text from '../../components/Typography/Text'
import ButtonNext from '../../components/Button/ButtonNext'
const DivContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginBottom: 100,
}))

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}))
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const ThanksError = () => {
  const onClose = () => {
    window.location.reload()
  }
  return (
    <Container>
      <Wrapper style={{flex: 0.2}}>
        <AsideLogo positionX="left" positionY="top" color="green" />
      </Wrapper>
      <Wrapper
        style={{
          flex: 1,
          background: 'rgb(41, 164, 56)',
          height: '100%',
          justifyContent: 'center',
        }}>
        <DivContainer>
          <Text textType="title" style={{ color: 'var(--fondo-blanco)' }}>
            Gracias por tu interés en el crédito CMF
          </Text>
          <Text
            textType="paragraphs"
            style={{
              color: 'var(--fondo-blanco)',
              marginTop: 22,
              paddingBottom: 22
            }}>
            No podemos proceder con tu solicitud en estos momentos
          </Text>
          <ButtonNext
            background="white"
            border='white'
            onClick={onClose.bind(this)}
          >
            Salir
          </ButtonNext>
        </DivContainer>
      </Wrapper>
    </Container>
  )
}

export default ThanksError

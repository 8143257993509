import React, {forwardRef, useImperativeHandle} from 'react'
import ImageBackArrow from '../../../assets/images/onboardingCredito/backArrow.svg'
import ImageClose from '../../../assets/images/onboardingCredito/close.svg'
import ModalRender from './render'
const ModalCommon = forwardRef(
  (
    {
      isOpen = false,
      onHide = () => {},
      onShow = () => {},
      children,
      modalClassName,
      modalProps = {},
      closeButtonImage = ImageClose,
      backButtonImage = ImageBackArrow,
      closeClassName = 'cursor-pointer hidden absolute right-5 md:inline-block text-white rounded-full',
      backClassName = 'cursor-pointer md:hidden absolute left-5 bg-[#F2F4F7] inline-block p-2 text-white rounded-lg',
      bodyClassName = 'mt-[91px] md:mt-[52px] flex flex-col items-center justify-center',
      bodyProps = {},
      showCloseButton = true,
      showBackButton = true,
    },
    ref = null,
  ) => {
    useImperativeHandle(ref, () => ({
      hide: onHide,
      show: onShow,
    }))

    const classNames = modalClassName || {
      closeButton: 'hidden',
      modal:
        'shadow-custom border border-2 border-gray-300 relative w-full h-full sx:overflow-y-scroll md:w-[80%] md:h-auto md:overflow-y-scroll lg:w-[719px] lg:h-auto lg:h-[701px] lg:overflow-y-hidden m-0 ',
      overlay: 'bg-[rgba(0,0,0,0.8)]',
    }

    return (
      <ModalRender
        backClassName={backClassName}
        backButtonImage={backButtonImage}
        bodyClassName={bodyClassName}
        bodyProps={bodyProps}
        closeClassName={closeClassName}
        closeButtonImage={closeButtonImage}
        children={children}
        modalClassName={modalClassName}
        modalProps={modalProps}
        isOpen={isOpen}
        onHide={onHide}
        showBackButton={showBackButton}
        showCloseButton={showCloseButton}
        classNames={classNames}
      />
    )
  },
)

export default ModalCommon

import { LoadingButton } from '@mui/lab';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
} from '@mui/material';
import React from 'react';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
export const Section = styled('section')(({theme}) => ({
  width: '100%',
  height: '100% !important',
}))

export const TextFieldComponent = styled(TextField)(({theme}) => ({
  height: '55px',
  width: '100%',
  borderRadius: '8px',
  fontSize: '16px',
  background: '#FFFFFF 0% 0% no-repeat padding-box !important',
  opacity: '1',
  '@media (max-width: 1024px)': {
    height: '45px',
  },
  outline: 'none !important',
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    border: 'none !important',
    borderRadius: '8px',
  },
  '& .MuiOutlinedInput-root': {
    height: '100%',
    '& fieldset': {
      border: '1px solid #D0D5DD',
      borderRadius: '8px',
    },
    '&:hover fieldset': {
      border: '1px solid #D0D5DD',
      borderRadius: '8px',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #D0D5DD',
      borderRadius: '8px',
    },
    '.MuiOutlinedInput-input': {
      fontSize: '14px',
      '@media (max-width: 1024px)': {
        fontSize: '14px',
      },
      '@media (max-width: 375px)': {
        fontSize: '14px',
      },
    },
  },
}))

export const MyInputLabel = styled(InputLabel)(({theme}) => ({
  font: 'normal normal normal 14px/16px Red Hat Text !important',
  top: '5px',
  fontFamily: 'Red Hat Text',
  color: '#707070 !important',
  '@media (max-width: 1024px)': {
    top: '0px',
  },
  '&.MuiInputLabel-shrink': {
    top: '3px!important',
    fontSize: 'unset !important',
  },
}))

export const SelectComponent = styled(Select)(({theme}) => ({
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  border: 'none',
  borderRadius: '8px',
  outline: 'none',
  width: '100%',
  height: '55px',
  fontSize: '16px',
  '@media (max-width: 1024px)': {
    height: '45px',
  },
  '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
    fontSize: '14px',
    '@media (max-width: 1024px)': {
      fontSize: '14px',
    },
    '@media (max-width: 375px)': {
      fontSize: '14px',
    },
  },
}))

export const FormControlData = styled(FormControl)(({theme}) => ({
  '.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled': {
    background: '#F5F5F5 0% 0% no-repeat padding-box',
    borderRadius: '15px',
  },
  'MuiFormLabel-root MuiInputLabel-rootMui-disabled': {
    color: '#D0D5DD !important',
  },
}))

export const MyBox = styled(Box)(({theme, noColumn }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
  '@media (max-width: 1024px)': {
    gap: '15px',
    flexDirection: noColumn ? 'row' : 'column',
  },
}))
export const MyBoxAPC = styled(Box)(({theme, noColumn }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'start',
  gap: '16px',
  '@media (max-width: 1024px)': {
    gap: '15px',
    flexDirection: noColumn ? 'row' : 'column',
  },
  '@media (max-width: 768px)': {
    gap: '5px',
    flexDirection: noColumn ? 'row' : 'column',
  },
}))

export const MyMenuItem = styled(MenuItem)(({theme}) => ({
  height: '30px',
  color: '#6E6F71 !important',
  font: 'normal normal normal 16px/34px Red Hat Text !important',
}))

export const Button = styled('button')(({theme}) => ({
  width: '207px',
  height: '70px',
  background:
    'transparent linear-gradient(270deg, #53A600 0%, #399001 100%) 0% 0% no-repeat padding-box',
  borderRadius: '5px 50px 50px 5px',
  opacity: 1,
  color: '#FFFFFF',
}))

export const ButtonBack = styled(LoadingButton)(({theme}) => ({
  width: '207px',
  height: '70px',
  opacity: 1,
  color: '#399001',
  border: '2px solid #53A600',
  borderRadius: '50px',
  fontSize: '20px !important',
  fontWeight: 'normal !important',
  font: 'normal normal normal Red Hat Text !important',
  '@media (max-width: 1024px)': {
    fontSize: '20px !important',
    width: '100%',
    height: '60px',
  },
  '&.MuiButtonBase-root.MuiButton-root': {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
  },
}))

export const ButtonNext = styled(ButtonBack)(({theme}) => ({
  color: '#FFFFFF',
  border: 'none',
  '&.MuiButtonBase-root.MuiButton-root': {
    background:
      'transparent linear-gradient(89deg, #399001 0%, #53A600 100%) 0% 0% no-repeat padding-box ',
  },
}))

export const NumericInput = React.forwardRef(function TextMaskCustom(
  props,
  ref,
) {
  const {onChange, ...other} = props
  return (
    <IMaskInput
      {...other}
      mask="00000000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={value => onChange({target: {name: props.name, value}})}
      overwrite
    />
  )
})

export const NumericResidencialInput = React.forwardRef(function TextMaskCustom(
  props,
  ref,
) {
  const {onChange, ...other} = props

  // Define la máscara basada en la primera cifra
  const mask = props.value && props.value[0] === '6' ? '00000000' : '0000000'

  return (
    <IMaskInput
      {...other}
      mask={mask}
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={value => onChange({target: {name: props.name, value}})}
      overwrite
    />
  )
})

export const CodeVerification = React.forwardRef(function TextMaskCustom(
  props,
  ref,
) {
  const {onChange, ...other} = props
  return (
    <IMaskInput
      {...other}
      mask="000000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={value => onChange({target: {name: props.name, value}})}
      overwrite
    />
  )
})

export const CurrencyInput = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const {onChange, ...other} = props
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      maxLength={8}
    />
  )
})
export const CurrencyInputSalary = React.forwardRef(
  function NumericFormatCustom(props, ref) {
    const {onChange, ...other} = props
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        minLength={3}
        maxLength={5}
        min={300}
        max={20000}
      />
    )
  },
)

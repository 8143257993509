import produce from 'immer'

const initialState = {
  loadSendPrice: false,
  getDataPhone: null,
  getDataOnboardinEvaluation: [],
  loadEvaluation: false,
  result: null,
  onboardingPhone: [],
  formUpdate: [],
  deviceId: null,
  path: null,
  loadCotizador: false,
  cotizadorId: null,
  cotizadorBase64: null,
  onboardingActive: [],
  json: [],
  sellerIdentification: null,
  typeError: null,
  productId: null,
  seonState: null
}

const PhoneReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'GET_LOAD_SEND_PRICE':
        draft.loadSendPrice = action.payload
        break
      case 'GET_DATA_PHONE':
        draft.getDataPhone = action.payload
        break
      case 'SET_SELLER_IDENTIFICATION':
        draft.sellerIdentification = action.payload
        break
      case 'SET_SELLER_IDENTIFICATION_CLEAN':
        draft.sellerIdentification = null
        draft.cotizadorBase64 = null
        draft.cotizadorId = null
        break
      case 'GET_LOAD_ONBOARDING_EVALUATION':
        draft.loadEvaluation = action.payload
        break
      case 'GET_DATA_ONBOARDING_EVALUATION':
        draft.getDataOnboardinEvaluation = action.payload
        break
      case 'GET_PHONE_DATA_CLEAN':
        draft.getDataPhone = null
        break
      case 'RESULT_EVALUATION':
        draft.result = action.payload
        break
      case 'CREATED_ONBOARDING_PHONE':
        draft.onboardingPhone = action.payload
        break
      case 'SET_DATA_UPDATE':
        draft.formUpdate = action.payload
        break
      case 'CLEAN_FORM_UPDATE':
        draft.formUpdate = []
        break
      case 'SET_DEVICE_ID':
        draft.deviceId = action.payload
        break
      case 'SET_URL_PATH':
        draft.path = action.payload
        break
      case 'GET_LOAD_TO_PDF_COTIZADOR':
        draft.loadCotizador = action.payload
        break
      case 'GET_PDF_COTIZADOR_SUCCESS':
        draft.loadCotizador = action.payload
        break
      case 'DOCUMENT_UPLOADED_COTIZACION_SUCCESS':
        draft.cotizadorBase64 = action.payload
        break
      case 'DOCUMENT_COTIZACIONID_SUCCESS':
        draft.cotizadorId = action.cotizadorId
        break
      case 'RESET_ONBOARDING_PHONE':
        draft.getDataPhone = null
        draft.getDataOnboardinEvaluation = {}
        draft.result = null
        draft.onboardingPhone = []
        draft.formUpdate = []
        draft.deviceId = null
        draft.path = null
        break

      case 'ONBOARDING_ACTIVE':
        draft.onboardingActive = action.payload
        break
      case 'ONBOARDING_ACTIVE_DATA':
        draft.json = action.payload
        break
      case 'ONBOARDING_NEW':
        draft.onboardingActive = action.payload
        break
      case 'ERROR':
        draft.typeError = action.payload
        break
      case 'PRODUCT_PHONE':
        draft.productId = action.payload
        break
      case 'SEON_APROVEE':
        draft.seonState = action.payload
        break
      case 'SEON_REVIEW':
        draft.seonState = action.payload
        break
      case 'SEON_REJECTED':
        draft.seonState = action.payload
        break
      default: break;
    }
  })
export default PhoneReducer

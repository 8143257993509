import {Cmf_Base, Cmf_Base02, EID_Base, TOKEN} from '../Data/Api';
/* LOADING TYPES */
export const loadingTypes = {
  EMAIL_FIELD: 'emailField',
  CELLPHONE_FIELD: 'cellphoneField',
  UPLOAD_DOCUMENT: 'uploadDocument',
};

export const passwordPattern = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
);
export const WhatsApp = '6597-8134';
export const ORIGIN = {
  CREDITO: 'CREDI',
  PREAPROBADO: 'PREAP',
  PRECALIFICADO: 'PRECA',
  CREDIMOVIL: 'PHONE',
  PREPAGO: 'PPAGO',
};
export const PRODUCTS_TYPES = {
  REMESA: '40d9da50-18a7-428d-aa3a-75cbd6df93a0',
  CREDIT_PRODUCTO: 'ba25d296-148d-47b5-90a8-4589fa700e7c',
  TEKY: '98a43473-76aa-4393-95b3-b94248cb9701',
  PREPP: '0092ED98-90BC-4245-B0AB-14701E648B91',
  PREPAGO: '0092ED98-90BC-4245-B0AB-14701E648B91',
};

export const COUNTRIES_TYPES = {
  PANAMA: '2D3D33C2-3401-40A1-858B-AE0140B0D376',
  label: 'Panamá',
};

export const DIALOG_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const TYPE_PHONE = {
  PA: '+507',
};

export const STATUS_SCORE = {
  APROBADO: 'APROBADO',
  RECHAZADO: 'RECHAZADO',
};

export const SELLER_ID_TYPE = '8db06a50-268e-4ffd-b6ac-fdb368f81a91';

export const GENDER_TYPE = {
  MALE: '55ACBCF4-BE83-4F03-8B5E-5760FC92B59B',
  FEMALE: '41ECBCF4-BE83-4F03-8B5E-5760FC92B59B',
};

export const STATUS_SIGNATURES = {
  Requested: 'Requested',
  AwaitingConfirmation: 'AwaitingConfirmation',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  Expired: 'Expired',
  Failed: 'Failed',
};

export const PDFTYPES = {
  CREDIMOVIL: 'NEWCTRQA',
};

export const TYPES_IMG = {
  Firma: 'A954139C-8D35-4C81-B475-54C41BB974C7',
  Text: 'Firma',
};

export const ONBOARDING_STATUS = {
  PENDING_FOR_APPROVE: '4F7852F1-8DEF-4214-B03A-59822A2E9CDA',
  APPROVED: '1B8D01EA-246B-4620-B428-5CBA62123208',
  REJECTED: '221308ED-7CDF-4C8E-ABFE-5F33F688AC77',
  APC: '2B4DA5E3-C65D-405C-925E-7267DD00E84F',
  ACTIVE: 'E3257A79-E872-4753-A8C8-D441747AAA5B',
  IN_PROCESS: '57F226E8-E006-49FA-8F76-E8DF989E60CB',
  INCOMPLETE: '210F851A-E4DE-46AC-8DC8-F388C09E4D2C',
  PREAPROBADO: '705FFA13-4BB2-49A1-A2DA-B7F24BF6C129',
};

export const IDENTITY_TYPES = {
  LICENCE: '438EF2ED-7C3A-4322-AC84-0964A455753E',
  DOCUMENT_ID: '8F3C2EF0-F0D2-4FF0-9863-218D3D494D56',
  PASSPORT: '3C88AF1B-BEC2-4533-9A25-80E3226841F7',
  RUC: 'E8C46303-196C-4139-AE0C-FDFAAAF71ADB',
};

export const BRANCH_OFFICES = {
  DORADO_TUMBA_MUERTO: 'BCC30BB5-2C99-4A34-84CE-01141397FC58',
  LA_SIESTA: '73FDB9A1-25D1-4AEB-9FE3-015B897FE70D',
  NOVEY_F_SUR: '042ADB15-D690-4AB7-8190-0519D235A166',
  NOVEY_ALBROOK: 'D6FB2D04-4E7D-4754-92A0-0EE21E39297E',
  NOVEY_BRISAS_DEL_GOLF: '4694B5EC-4995-444B-AD52-11ED859BA0D9',
  NOVEY_PLAYA_BLANCA: '4550F834-EA20-43E0-8061-144060B81852',
  VILLA_LUCRE: 'CF9B5CCF-BA03-4339-91B9-14F4E6202E27',
  CHORRERA: '5E3D5459-7199-4744-8869-1C1CB5E2C07E',
  _24_DICIEMBRE: '6FA60156-BA7D-44CB-BC5C-2D4C0BE57555',
  SAN_MIGUELITO: 'D464CEED-B199-4FCA-9087-2E9FE4DCF93B',
  CALLE_50: '77B1C1A1-E1E4-4BF6-88D3-398A837F5F90',
  CALIDONIA: '52E1E3F3-4D59-495B-8C05-3DB618BF21BB',
  LAS_TABLAS: '019197DC-9A46-477D-9FF0-5ABD768F6A78',
  NOVEY_SANTIAGO: '1D03A631-4E4E-4B31-A497-5CC5D0F3B203',
  PEDREGAL: '16F03146-52C4-4C97-A7C1-5E9AFC67627B',
  COSTA_VERDE: '048747C8-C4DC-4810-BCF7-607FBA5DDAE9',
  SANTIAGO: '2ED5F775-B287-4FE5-AF4B-61DCE3FE5501',
  PAITILLA: '5FD4C883-931B-40A0-A75B-6245B72E88A5',
  BRISAS_DEL_GOLF: '97D2DFC8-791D-444C-8C42-74683D2E82A2',
  VISTA_ALEGRE: '91B64C99-98BE-4AD9-95A4-786BB32977FD',
  NOVEY_CHITRE: '37271F3C-F2D6-4293-B685-786E82423073',
  LAS_AMERICAS: '8CA579C0-BBC2-427C-8A1D-79F7C324F81F',
  NOVEY_VISTA_ALEGRE: 'F100CB04-0F61-4F2A-9C2F-7DFE721966D1',
  NOVEY_PENENOME: 'EA5664C4-F9E9-4B9F-8F4D-7EA1037D0416',
  CHITRE: 'B0246A8E-97A2-4953-BEFD-7F4AACD5A839',
  DAVID: '751CB010-5083-44FF-92CC-9753474EFC4E',
  VIA_ESPANA: 'C95CDD47-5C6B-4132-B39A-ACA9A624F7DC',
  CORONADO: '08860184-0212-4A65-B7E3-B3A0A03E8143',
  NOVEY_PARQUE_LEFREVRE: 'DCEDFEEA-CB70-404E-BE61-B78ABF8E3B84',
  AV_BALBOA: 'B3893B23-CE52-453B-AE25-B85F6BF99568',
  NOVEY_COLON: '4879F92B-F42C-418C-B266-B9D0A99549A1',
  NOVEY_CORONADO: 'A28F04FC-07CB-4E04-8A2E-BBC7878A81BC',
  LA_RIVIERA: '4A4F4085-DB08-4B17-B33E-BC0F1DE94FD5',
  BUGABA: '07E61027-0815-4590-B3AB-C26A520FBA1C',
  NOVEY_LA_DONA: 'BD72E10D-E397-47D6-85BB-C4A9792043D1',
  CHANIS: 'D226E2B2-FAE8-4020-8DAD-C7EA2CCCE83D',
  NOVEY_LOS_ANGELES: 'E63B234F-279A-4DA9-AC83-C91EF61FD764',
  NOVEY_COSTA_DEL_ESTE: '4A70EA8B-95DE-4ACD-94F6-CAA90F4FA0A1',
  NOVEY_COSTA_VERDE: '74D93B73-D491-4AEE-8C05-D4E8B4B62F6F',
  NOVEY_VERSALLES: '504406EC-C809-4860-94B2-DFC7D3933123',
  COCHEZ_COLON: '2FE41A5F-67C8-496F-B6A9-E479488EE555',
  NOVEY_OBARRIO: '5A4A0B3C-CD73-4BE2-801B-E66047A122DC',
  NOVEY_DAVID: '973DD233-3B0D-4C3E-A538-E9490B74C5F9',
  NOVEY_CENTENARIO: '62A33D1F-D773-48F3-A2D7-F103F0F5A3CD',
  PENENOME: '9C886D48-FCBE-423A-A35A-F35CCDBFF99B',
  NOVEY_PANAMA_PACIFICO: '23D4DB8B-891C-4611-B1B8-F4A2D847AA0C',
  COCHEZ: '0429A560-163C-49F9-B103-1BCA39B5B34F',
};

export const SYSTEM_TYPES = {
  LATIN_DATA: 'B8419DFB-A2A6-4FE8-B57D-135E4F9C6A99',
  iVend: '4FF02FC9-60A7-4616-B42D-36CDC0922C50',
  ACCUITY: '0EEC4432-1BFC-41F0-BFFE-4A354CB219EC',
  Salesforce: '73B07B08-6722-4267-A3C4-9CDA4EAF2764',
  Passkit: 'E1A59684-CBEA-430B-9B60-A8CD88A84EE8',
  SAP: '90441025-6DEF-46CF-85CC-B2180DF52F1E',
  Aludra: '17D93202-977A-4698-84F2-C23906A55958',
  NAF: '88B0B113-7EB9-4EBA-A57B-C6D1F9E85E6B',
  Versatec: '04C9D4DF-BBC0-4425-9F26-E2E470944CDE',
  CMF: '0A7593E0-C585-4207-A030-F17E795D1567',
};

export const PAN_TYPES = {
  PAN2023: 'pan_id_2023',
  PANE2023: 'pan_rp_2023',
  PANE2010: 'pan_id_2010',
  PANE2012: 'pan_rp_2012',
};

export const CREATED_BY = {
  cmf_web: 'CMF_WEB',
};

export const SYSTEM = {
  VOLCAN: 'F036B39F-F7DA-49B2-A2B2-14F1DD9FC3E1',
  VERSATECT: 'A7593E0-C585-4207-A030-F17E795D1567',
};

export const MESSAGE_VALIDATION = {
  active_product_pdf: 'El cliente ya tiene un OnBoarding activo.',
  onboardingPending:
    'El cliente tiene un onboarding de crédito pendiente de aprobación',
  onboardingApprove: 'El cliente tiene un onboarding de crédito aprobado',
  twilio_max_attemps: 'Max send attempts reached',
};
/* Make and enviroment for this */
const baseUrlEtrust = EID_Base;
const baseUrl = Cmf_Base;
const baseUrl02 = Cmf_Base02;
const token = TOKEN;
export const APP = {
  companyName: 'CMF',
  isFakeSignature: false,
  fakeSignature: '32599425-409b-43ec-a98a-109da154c42d',
  fakeScan: false,
  fakeEmail: false,
  fakePhone: false,
  electronicId: {
    tokenAuth: token,
    endpoints: {
      includeJS: `${baseUrlEtrust}/js/videoid-3.x/videoid.js`,
      videoId: `${baseUrlEtrust}/v2/videoid.request`,
      onsite: `${baseUrlEtrust}/v2/identities/onsite`,
      getIdentityId: `${baseUrlEtrust}/v2/identities`,
      documentData: id => `${baseUrlEtrust}/v2/videoid/${id}`,
      uploadDocument: `${baseUrlEtrust}/v2/documents`,
      signatureSms: `${baseUrlEtrust}/v2/signatures/request/sms`,
      signatureCall: `${baseUrlEtrust}/v2/signatures/request/voice`,
      signSms: `${baseUrlEtrust}/v2/signatures/sign/sms`,
      signByCall: `${baseUrlEtrust}/v2/signatures/sign/voice`,
      signatureVerify: (type, requestId) =>
        `${baseUrlEtrust}/v2/signatures/request/${type}/${requestId}`,
    },
  },
  cmf02: {
    endpoints: {
      apiChubb: `${baseUrl02}api/chubb`,
      registerComplete: `${baseUrl02}api/chubb/registerComplete`,
    },
  },
  cmf: {
    PUSHER_APP_KEY: 'cbce334919eeb6758e9a',
    PUSHER_CHANNEL: 'channel_cmf',
    EVENT_NAME: 'event_confirm_email',
    endpoints: {
      apiChubb: `${baseUrl}api/chubb`,
      getOnBoardingBatch: `${baseUrl}api/onboarding/GetOnboardingPreAproveByTypedIdentityAndIdentity`,
      CreateOnboardingPre: `${baseUrl}api/onboarding/CreateOnboardingPre`,
      UpdateOnboardingPre: `${baseUrl}api/onboarding/UpdateOnboardingPrePre`,
      ActiveOnboardingPre: `${baseUrl}api/onboarding/ActiveOnboardingPre`,
      activateOnboardingMatrix: `${baseUrl}api/onboarding/ActiveOnboardingMatrix`,
      activateProductTDC: `${baseUrl}api/Customers/ActiveProductCredTDC`,
      generateAPC: `${baseUrl}api/score/apc`,
      changeStatus: `${baseUrl}api/onboarding/ChangeStatus`,
      generateScoreMatrix: (onboardingId, createdBy) =>
        `${baseUrl}api/onboarding/GenerateScoreMatrixToAprobe/${onboardingId}/${createdBy}`,
      sendEmail: `${baseUrl}api/EmailServices/signup`,
      emailIsVerified: `${baseUrl}api/EmailServices/EmailIsVerified`,
      customer: (type, numberIdentity) =>
        `${baseUrl}api/Customers/${type}/${numberIdentity}`,
      getPDF: `${baseUrl}api/print/getOnlyContract`,
      getCotizadorPdf: `${baseUrl}api/print/getOnlyQuotation`,
      activateProduct: `${baseUrl}api/Customers/ActiveProductCredTDC`,
      sendVerify: `${baseUrl}api/EmailServices/sendVerify`,
      confirmVerify: `${baseUrl}api/EmailServices/confirmVerify`,
      logger: `${baseUrl}api/onboarding/CreateTransactionAnswer`,
      sendMailVerify: `${baseUrl}api/EmailServices/sendEmailverify`,
      confirmMailVerify: `${baseUrl}api/EmailServices/confirmEmailVerify`,
      confirmSmsVerify: `${baseUrl}api/EmailServices/confirmVerify`,
      sendCallVerify: `${baseUrl}api/EmailServices/sendPhoneCallverify`,
      confirmCallVerify: `${baseUrl}api/EmailServices/confirmPhoneCallVerify`,
      bannerActive: `${baseUrl}api/banner`,
      getCustomer: `${baseUrl}api/Customers/GetCustomerDetailbyTypeIdentityandIdentification`,
    },
  },
};

export const URL_PHONE = 'c9pUQ4TnM3FYF9mxj1Bh2E06F8W1mX';
export const URL_CARD = 'https://portal.cmf.com.pa/cardrequest';

export const URL_APP = {
  android:
    'https://play.google.com/store/apps/details?id=com.cmfinanciera.wallet',
  apple: 'https://apps.apple.com/pa/app/cmf-app/id6451213803',
  referral: 'https://www.cmf.com.pa/promociones',
};

export const TimerQr = 60;
export const TIMER_SECONDS = 300;
export const TIMER_SECONDS_RESEND = 30;
export const MAX_ATTEMPTS = 6;
export const MAX_ATTEMPTS_MESSAGE =
  'Cantidad de intentos superados. Por favor intenta nuevamente en 1 hora';
export const EXPIRED_OTP_MESSAGE =
  'El código ingresado ya expiró. Por favor, solicita un nuevo código';

export const stamp = {
  locations: {
    prepago: {
      page: 1,
      left: 210,
      top: 625,
      width: 200,
      height: 60,
    },
    default: {
      page: 1,
      left: 240,
      top: 715,
      width: 200,
      height: 60,
    },
  },
};

export const PREPAID_URL = '32155ca2-95c5-40f6-85c6-4397258c0916';

export const PRODUCT_ID = {
  isCardTDC: {
    Clasica_CMF_1_A: '0AB0F0A9-D6B8-403B-B3C7-153FA0317E12',
    CMF_VIP: '744F2646-81B9-44B9-A43B-3D481EC4C5A0',
    Clasica_CMF_1: 'D5E45242-756E-4328-BBFB-FAB1E996DAC3',
    Clasica_CMF_2: '427B3C92-E1E2-4EFC-9E16-97A6224392B3',
    Clasica_CMF_3: 'EA8C7CA6-EBDC-4231-A3C2-620F77755C7E',
    Clasica_CMF_4: 'D7646503-5C78-4C25-8E7F-A527B13A7CB5',
    Clasica_CMF_5: '23A791FF-0953-4996-BE9B-C1CF664C851E',
    Clasica_CMF_6: '776682F8-3B1D-4C17-90FD-E9F6D37CC856',
    REWRITE_3_CMF: '3C442506-D51B-4F80-865D-59DBF495C4EE',
    CMF_Ley_81: '8667BE9C-3C02-4752-9BAF-B2686CDA38EC',
    CMF_RED_PRO: '356024EC-E1B4-4AB1-B555-C72043CF4341',
    CMF_PROYECTO: 'D09E54B3-4CCE-423C-977E-AE04AA68F995',
  },
  isCardPrepago: {
    MASTERCARD_PREPAGO: '3E93A6BE-E295-4928-8CB6-1140DE1244B4',
  },
  isCardTDCMastercard: {
    MASTERCARD_EXECUTIVE_BUSINESS: '23ABCE45-8689-4DE3-9458-0BB826754C05',
    MASTERCARD_PLATINUM: 'C72B4B90-C983-4DAF-9AB5-29EF748C53AC',
    CMF_COCHEZ_CORPORATIVA: 'ABDE1531-C140-42FF-87E7-3EC2ACFF9B75',
    MASTERCARD_GOLD: '2517C335-C000-4D4B-8804-4BA9B5896387',
  },
  isCardTDCMastercardBlack: {
    MASTERCARD_BLACK: '45DEFAC0-8C05-493A-ABA9-B1EE347DB5D8',
  },
  Link_Personalizados: 'DEA3394B-0F0A-4259-B809-7AE9D2CED21B',
  Credimovil: '98A43473-76AA-4393-95B3-B94248CB9701',
  REMESA: 'B153DE7D-304A-42CA-94F3-0C84AC1A2093',
};
export const MASTERCARD_PRODUCTS = [
  '3E93A6BE-E295-4928-8CB6-1140DE1244B4', // PREPAID
  // '45DEFAC0-8C05-493A-ABA9-B1EE347DB5D8', // BLACK
  // '2517C335-C000-4D4B-8804-4BA9B5896387', // GOLD
  // 'C72B4B90-C983-4DAF-9AB5-29EF748C53AC', // PLATINUM
  // '23ABCE45-8689-4DE3-9458-0BB826754C05', // BUSINESS
];

import { Divider, Skeleton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { PRODUCTS_TYPES } from 'src/utils/Constants';
import formats from 'src/utils/formats';

export const Estado = ({dataCurrent}) => {
  const {loadV, selectedProduct} = useSelector(
    state => state.ActiveProductReducer,
  );

  let fechaOriginal = moment(dataCurrent?.FecMaxPago, 'DD/MM/YYYY');
  let fechaModificada = fechaOriginal.subtract(1, 'months').set('date', 23);
  let formattedDate = fechaModificada.format('DD/MM/YYYY');

  return (
    <Box sx={{width: '100%'}}>
      {loadV ? (
        <Skeleton variant="rectangular" width="100%">
          <div style={{height: '10rem'}} />
        </Skeleton>
      ) : (
        <Stack spacing={2}>
          {!selectedProduct?.isCorporate &&
            selectedProduct?.productTypeId?.toUpperCase() !==
              PRODUCTS_TYPES.PREPP && (
              <React.Fragment>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <Typography>Fecha máxima de pago</Typography>
                  <Typography variant="h7">
                    {moment(dataCurrent?.FecMaxPago, 'DD/MM/YYYY').format(
                      'DD/MM/YYYY',
                    )}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <Typography>Crédito Disponible</Typography>
                  <Typography variant="h7">
                    {formats.currency(
                      dataCurrent?.DisponibleML?.toFixed(2) ?? 0,
                    )}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <Typography>Consumo del mes</Typography>
                  <Typography variant="h7">
                    {formats.currency(
                      dataCurrent?.ConsumoMesML?.toFixed(2) ?? 0,
                    )}
                  </Typography>
                </Box>
                <Divider />
              </React.Fragment>
            )}
          {selectedProduct?.productTypeId?.toUpperCase() ===
            PRODUCTS_TYPES.PREPP && (
            <React.Fragment>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Disponible</Typography>
                <Typography variant="h7">
                  {formats.currency(dataCurrent?.DisponibleML?.toFixed(2) ?? 0)}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Monto de última recarga</Typography>
                <Typography variant="h7">
                  {formats.currency(
                    dataCurrent?.MontoUltimaRecarga?.toFixed(2) ?? 0,
                  )}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Fecha de última recarga</Typography>
                <Typography variant="h7">
                  {moment(dataCurrent?.FechaUltimaRecarga).format('DD/MM/YYYY')}
                </Typography>
              </Box>
              <Divider />
            </React.Fragment>
          )}
          {selectedProduct?.isCorporate && (
            <React.Fragment>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Número de tarjeta</Typography>
                <Typography variant="h7">{dataCurrent?.Tarjeta}</Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Nombre de la tarjeta</Typography>
                <Typography variant="h7">
                  {selectedProduct?.fullName}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Fecha corte</Typography>
                <Typography variant="h7">{formattedDate}</Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Saldo anterior</Typography>
                <Typography variant="h7">
                  {formats.currency(
                    dataCurrent?.SdoUltCorteMN?.toFixed(2) ?? 0,
                  )}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Saldo a corte</Typography>
                <Typography variant="h7">
                  {formats.currency(dataCurrent?.SaldoFinalML?.toFixed(2) ?? 0)}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Pago de contado</Typography>
                <Typography variant="h7">
                  {formats.currency(
                    dataCurrent?.PagocontadoML?.toFixed(2) ?? 0,
                  )}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Pago mínimo</Typography>
                <Typography variant="h7">
                  {formats.currency(dataCurrent?.PagoMinimoML?.toFixed(2) ?? 0)}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typography>Fecha máxima de pago</Typography>
                <Typography variant="h7">
                  {moment(dataCurrent?.FecMaxPago, 'DD/MM/YYYY').format(
                    'DD/MM/YYYY',
                  )}
                </Typography>
              </Box>
            </React.Fragment>
          )}
        </Stack>
      )}
    </Box>
  );
};

Estado.propTypes = {
  dataCurrent: PropTypes.shape({
    ConsumoMesML: PropTypes.shape({
      toFixed: PropTypes.func,
    }),
    DisponibleML: PropTypes.shape({
      toFixed: PropTypes.func,
    }),
    FecMaxPago: PropTypes.any,
    FechaUltimaRecarga: PropTypes.any,
    MontoUltimaRecarga: PropTypes.shape({
      toFixed: PropTypes.func,
    }),
    PagoMinimoML: PropTypes.shape({
      toFixed: PropTypes.func,
    }),
    PagocontadoML: PropTypes.shape({
      toFixed: PropTypes.func,
    }),
    SaldoFinalML: PropTypes.shape({
      toFixed: PropTypes.func,
    }),
    SdoUltCorteMN: PropTypes.shape({
      toFixed: PropTypes.func,
    }),
    Tarjeta: PropTypes.any,
  }),
};
export default Estado;

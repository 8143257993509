import React, {Component} from 'react'
import AllPagesPDFViewer from './all-pages'
import './style-pdf.css'

//TODO -- hacer que el scroll sobre el objeto accione el boton next next del PDF
export default class PDFView extends Component {
  shouldComponentUpdate() {
    if (this.props.document) {
      return false
    }
  }

  render() {
    return (
      <>
        <div className="max-h-[45vh] mb-10 overflow-scroll overflow-x-hidden">
          <AllPagesPDFViewer
            pdf={'data:application/pdf;base64,' + this.props.document}
          />
        </div>
      </>
    )
  }
}

import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './style.module.css'
import CongratsSignFirstSection from './CongratsSignFirstSection'
import CongratsSignGreenLine from './CongratsSignGreenLine'
import CongratsSignAvatar from './CongratsSignAvatar'

const CongratsSign = () => {
  const navigate = useNavigate()
  const handleButton = () => {
    navigate('/')
  }

  return (
    <div className={styles.mainContainer}>
      <CongratsSignFirstSection onClick={handleButton} />
      <CongratsSignGreenLine />
      <CongratsSignAvatar />
    </div>
  )
}

export default CongratsSign
import styles from './style.module.css'

/**
 * CongratsSignGreenLine Component displays a green line.
 * 
 * This component renders a green line as a decorative element.
 * 
 * @component
 * @example
 * return (
 *   <CongratsSignGreenLine />
 * )
 */
const CongratsSignGreenLine = () => {
  return (
    <div className={styles.greenLine}></div>
  );
}

export default CongratsSignGreenLine;
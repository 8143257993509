import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UAParser } from 'ua-parser-js';
import Loading from '../../assets/images/loading-animated.gif';
import { logsOnboarding } from '../../store/actions/requestCard.actions';
import { APP } from '../../utils/Constants';
import { ConsoleHelper } from '../../utils/Functions';
import './style.css';

export const ELECTRONIC_SCAN_TYPE = {
  CEDULA_NACIONAL: 259,
  CEDULA_EXTRANJERA: 261,
};

export const LANGUAGES = {
  en: 'en',
  es: 'es',
};

const parser = new UAParser();
const result = parser.getResult();
class ElectronicaID extends Component {
  state = {
    api_token: null,
    documentType: this.props.documentType,
    videoManagement: null,
    loading: true,
    videoId: null,
    videoAreaName: 'videoArea' + moment().format('x'),
    loadingEID: false,
  };

  constructor(props) {
    super(props);
    /* add script on load */
    let id = this.props.routeCreateUser.newCode;

    if (!window.EID) {
      ConsoleHelper('FIRST LOAD SCRIPTS');
      const url_script = APP.electronicId.endpoints.includeJS;
      const script = document.createElement('script');
      script.id = 'myDynamicScript';
      script.async = true;
      script.onload = function () { };

      script.addEventListener('error', function () {
        logsOnboarding(
          id ? id : '',
          '',
          'VIDEO_ID_SCRIPT',
          'Fallo en cargar Script',
          '',
          false,
        );
      });

      script.src = url_script;
      document.body.appendChild(script);
    }
  }

  async fetchVideoId() {
    let id = this.props.routeCreateUser.newCode;
    try {
      const response = await axios.post(
        APP.electronicId.endpoints.videoId,
        {},
        {
          headers: {
            Authorization: this.props.keyAuth,
          },
        },
      );
      logsOnboarding(id ? id : '', '', 'VIDEO_ID_REQUEST', response, '', true);
      return response.data || {};
    } catch (err) {
      this.props.logsOnboarding(
        id ? id : '',
        '',
        'VIDEO_ID_REQUEST',
        err,
        '',
        false,
      );
      return {};
    }
  }

  async fetchDocumentData(videoId) {
    let id = this.props.routeCreateUser.newCode;
    try {
      const response = await axios.get(
        APP.electronicId.endpoints.documentData(videoId),
        {
          headers: {
            Authorization: this.props.keyAuth,
          },
        },
      );
      logsOnboarding(id ? id : '', '', 'VIDEO_ID_FETCH', response, '', true);
      this.setState({ loadingEID: true });
      return response.data || {};
    } catch (err) {
      logsOnboarding(id ? id : '', '', 'VIDEO_ID_FETCH', err, '', false);
      return {};
    }
  }
  checkVideoManagement() {
    if (!window.EID) {
      setTimeout(() => {
        this.checkVideoManagement();
      }, 500);
    } else {
      this.setState({ videoManagement: window.EID });
      this.createVideoConfig();
    }
  }
  async componentWillUnmount() {
    const { videoId } = this.state;
    if (videoId) {
      videoId.destroy();
    }
    ConsoleHelper('objeto video id', videoId);
  }
  componentDidMount() {
    setTimeout(() => {
      this.checkVideoManagement();
    }, 500);
  }

  async createVideoConfig() {
    let id = this.props.routeCreateUser.newCode;
    const EID = this.state.videoManagement;
    const videoIdString = await this.fetchVideoId();
    const api_token = videoIdString.authorization;

    if (!api_token) {
      return;
    }
    const lang = this.props.lang || LANGUAGES.es;
    const idArea = '#' + this.state.videoAreaName;

    if (EID) {
      const videoId = EID.videoId(idArea, { lang });
      this.setState({ api_token, videoId }, () => this.initVideo());
    }
    logsOnboarding(
      id ? id : '',
      '',
      'VIDEO_CREATED_CONFIG',
      videoIdString,
      '',
      true,
    );
  }

  async initVideo() {
    let id = this.props.routeCreateUser.newCode;
    const { videoId } = this.state;
    videoId.start({
      authorization: this.state.api_token,
      idType: this.state.documentType,
    });

    videoId.on(
      'completed',
      async function (video) {
        ConsoleHelper('success video', video);
        const rsp = await this.fetchDocumentData(video.id);
        if (!rsp.id) {
          this.props.onError(
            'Sistema biométrico, no pudimos validar el documento, intenta nuevamente.',
          );
        } else {
          this.props.onComplete(rsp);
        }
      }.bind(this),
    );

    videoId.on(
      'failed',
      function (error) {
        if (this.props.onError) {
          this.props.onError(error);
        }
        logsOnboarding(id ? id : '', '', 'VIDEO_ID_ON', {
          errorEID: error,
          deviceInfo: result
        }, '', false);
      }.bind(this),
    );

    videoId.on(
      'phaseStarting',
      async function (phase) {
        if (this.props.onChange) {
          this.props.onChange(phase);
        }
        phase.continue();
        logsOnboarding(id ? id : '', '', 'VIDEO_ID_PHESE', phase, '', true);
      }.bind(this),
    );

    // setTimeout(() => {
    this.setState({ loading: false });
    // }, 2300);
  }
  render() {
    return (
      <div
        id={this.state.videoAreaName}
        style={{
          height: this.props.height || 100,
          width: this.props.width || 100,
        }}>
        {this.state.loading === true || this.state.loadingEID === true ? (
          <div className="scan-area">
            <img
              alt="ElectronicID Loading"
              src={Loading}
              className="eid-loading-img"
            />
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { routeCreateUser } = state.RequestCardReducer;
  return {
    routeCreateUser,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    logsOnboarding: (process, uniqueCode, newCode, stringToSave, isComplete) =>
      dispatch(
        logsOnboarding(process, uniqueCode, newCode, stringToSave, isComplete),
      ),
  };
};
ElectronicaID.propTypes = {
  documentType: PropTypes.number,
  keyAuth: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onComplete: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
  lang: PropTypes.string,
  origin: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(ElectronicaID);

import axios from 'axios';
import { IDENTITY_TYPES } from '../utils/Constants';
import { API_KEY, Cmf_Base } from './Api';
const headers = {
  headers: {
    Apikey: API_KEY,
    'content-type': 'application/json',
  },
}
const Client = axios.create({baseURL: Cmf_Base})
export const UpdateOnboardingCrePre = async payload =>
  await Client.post(`api/onboarding/UpdateOnboardingCrePre`, payload, headers)
export const getCustomerDetails = async identityNumber =>
  await Client.get(
    `api/Customers/GetCustomerDetailbyTypeIdentityandIdentification?typeIdentityId=${IDENTITY_TYPES.DOCUMENT_ID}&identification=${identityNumber}`,
    headers,
  )
export const getclientActive = async identityNumber =>
  await Client.get(
    `api/onboarding/GetOnboardingPreAproveByTypedIdentityAndIdentity/${IDENTITY_TYPES.DOCUMENT_ID}/${identityNumber}`,
    headers,
  )
export const OnboardingEvaluation = async payload =>
  await Client.post(`api/onboarding/OnboardingEvaluation`, payload, headers)
export const CreateLatinDataRange = async payload =>
  await Client.post(`api/onboarding/CreateLatinDataRange`, payload, headers)
export const updateAtomCredimovil = async (id, origin, payload) =>
  await Client.put(`api/onboarding/atom/${id}/${origin}`, payload, headers)
export const  updateCredimovilOnboarding = async (id, payload) =>
  await Client.put(
    `${Cmf_Base}api/onboarding/atom/${id}/CREDI`,
    payload,
    headers,
  )

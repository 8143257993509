import styled from '@emotion/styled'
import {Col, Form, Input, Row} from 'antd'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import AsideLogo from '../../components/Aside/AsideLogo.js'
import ButtonNext from '../../components/Button/ButtonNext.js'
import InputMessage from '../../components/Calculator/InputMessage.js'
import FooterOnboar from '../../components/Footer/FooterOnboarding.js'
import ProgressBar from '../../components/ProgressBar/index.js'
import {
  SEONEvaluationPhone,
  updateVerifyInfo,
} from '../../store/actions/phoneAction.js'
import {
  GENDER_TYPE,
  IDENTITY_TYPES,
  SELLER_ID_TYPE,
  SYSTEM_TYPES,
} from '../../utils/Constants.js'
import {formFields} from '../CardRequest/FormRequest.config.js'
import ValidateEmail from '../CardRequest/ValidateEmail.js'
import ValidatePhone from '../CardRequest/ValidatePhone.js'
import canReturnMobile from './canReturnMobile.js'
import {SCREENS} from './screens.js'

const InputMain = styled(Input)(() => ({
  '&.ant-input': {
    height: ' 3.5rem !important',
    padding: ' 0 11px',
  },
  '@media (min-width: 1201px) and (max-width: 1535px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 901px) and (max-width: 1200px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 601px) and (max-width: 900px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '4em !important',
    },
  },
  '@media (max-width:600px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '3rem !important',
    },
  },
}))
const FormMain = styled(Form)(() => ({
  '&.ant-form': {
    padding: '0 5%',
    margin: '0 0 auto 0',
  },
}))
const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
})

const VerifyInfo = props => {
  const dispatch = useDispatch()
  const [isOpenEmailValidation, setOpenMail] = useState(false)
  const [isOpenPhoneValidation, setOpenPhone] = useState(false)
  const [sessionData, setSessionData] = useState('')
  const formRequest = useSelector(state => state.RequestCardReducer.form)
  const {onboardingId, origin, checkSeon} = useSelector(
    state => state.RequestCardReducer,
  )
  const [loading, setLoading] = useState(false)
  const branchOfficeId = useSelector(
    state => state.GeneralInformationReducer.selectedBranchOfficeId,
  )
  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 300)
  }, [])
  useEffect(() => {
    window.seon.getBase64Session(function(data) {
      if (data) {
        setSessionData(data)
      } else {
        console.log('Failed to retrieve session data.')
      }
    })
  }, [])
  const [form] = Form.useForm()
  const onFinish = values => {}
  const onSuccess = success => {
    setLoading(false)
    if (success) {
      if (checkSeon) {
        dispatch(SEONEvaluationPhone(onboardingId, origin, sessionData))
        props.goToStep(SCREENS.PersonalInformation)
      } else {
        props.goToStep(SCREENS.PersonalInformation)
      }
    }
  }
  const onCheck = async () => {
    try {
      await form.validateFields()
      setLoading(true)
      const createdBy = SELLER_ID_TYPE
      let phones = []

      if (formRequest.cellphone) {
        phones.push({
          id: 1,
          typePhone: 'CE',
          area: '507',
          phone: formRequest.cellphone,
          isDefault: true,
          createdBy: SELLER_ID_TYPE,
          extension: null,
          lastModifiedBy: null,
        })
      }

      if (formRequest.phone) {
        phones.push({
          typePhone: 'CA',
          area: '507',
          phone: formRequest.phone,
          isDefault: false,
          createdBy: SELLER_ID_TYPE,
        })
      }

      const payload = {
        id: onboardingId,
        typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
        branchOfficeId: branchOfficeId,
        sellerId: SELLER_ID_TYPE,
        approvedBy: SELLER_ID_TYPE,
        approvedDate: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
        genderId:
          formRequest.gender === 1 ? GENDER_TYPE.MALE : GENDER_TYPE.FEMALE,
        countryId: formRequest.country,
        countryBirthId: formRequest.countryOfBirth,
        nationalityId: formRequest.nationality,
        systemId: SYSTEM_TYPES.CMF,
        identityNumber: formRequest.identity,
        firstName: formRequest.firstName,
        secondName: formRequest.secondName || null,
        firstSurname: formRequest.lastName,
        secondSurname: formRequest.secondLastName,
        dateOfBirth: formRequest.birthdate,
        phones: phones,
        emails: [
          {
            typeEmail: 'PE',
            email: formRequest.email,
            isDefault: true,
            createdBy: createdBy,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
          },
        ],
        images: [
          {
            typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
            description: 'Cedula Frontal',
            link: formRequest.frontIdentityImage,
            thumbnails: null,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
            createdBy: 'CMF',
          },
          {
            typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
            description: 'Cedula Reverso',
            link: formRequest.backIdentityImage,
            thumbnails: null,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
            createdBy: 'CMF',
          },
          {
            typeImageId: 'A7398CE9-96CA-41AE-9513-AE233C6FEB02',
            description: 'Biometrico',
            link: formRequest.faceImage,
            thumbnails: null,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
            createdBy: 'CMF',
          },
        ],
        createdBy: 'CMF',
        LastModifiedBy: 'CMF',
      }
      dispatch(updateVerifyInfo(payload, onSuccess))
    } catch (errorInfo) {
      setLoading(false)
    }
  }
  useEffect(() => {
    //companyInfo -> dynamic obj fetched from API
    form.setFieldsValue({email: formRequest.email})
  }, [formRequest.email, form])
  useEffect(() => {
    //companyInfo -> dynamic obj fetched from API
    form.setFieldsValue({cellphone: formRequest.cellphone})
  }, [formRequest.cellphone, form])

  /* modal for email validation */
  const renderModalEmailValidation = () => {
    const _fullname = `${formRequest.firstName || ''} ${formRequest.lastName ||
      ''}`
    return (
      <ValidateEmail
        title="Validación de Correo Electrónico"
        visible={isOpenEmailValidation}
        fullName={_fullname}
        // initialValue={this.state.formFields?.email?.value}
        onClose={() => setOpenMail(false)}
      />
    )
  }

  /* modal for phone validation */
  const renderModalPhoneValidation = () => {
    return (
      <ValidatePhone
        title="Validación de Teléfono Celular"
        visible={isOpenPhoneValidation}
        // initialValue={this.state.formFields?.cellphone?.value}
        onClose={() => setOpenPhone(false)}
      />
    )
  }
  const handleOpenCell = () => {
    setOpenPhone(true)
  }
  return (
    <Container>
      {renderModalEmailValidation()}
      {renderModalPhoneValidation()}
      <Wrapper style={{flex: 0.2, background: 'rgb(41, 164, 56)'}}>
        <AsideLogo
          positionX="left"
          positionY="top"
          color="white"
          hugColor="#FFF"
        />
      </Wrapper>

      <Wrapper
        style={{
          flex: 1,
          height: '100%',
          justifyContent: 'center',
        }}>
        <ProgressBar active={1} />
        <FormMain
          name="basic"
          form={form}
          autoComplete="off"
          initialValues={{
            cellphone: formRequest?.cellphone,
            email: formRequest?.email,
          }}
          onFinish={onFinish}>
          <Row gutter={[16, 60]}>
            <Col xl={12} xs={24} lg={12} offset={1}>
              Valida tu número de celular
              <Form.Item name="cellphone" rules={formFields.cellphone?.rules}>
                <InputMain
                  onClick={handleOpenCell}
                  data-testid="inpt-cellphone"
                  // onChange={handleChange}
                  placeholder="Teléfono Celular"
                />
              </Form.Item>
              {formRequest?.cellphone && (
                <InputMessage message={`Número de celular validado`} />
              )}
            </Col>
            <Col xl={12} xs={24} lg={12} offset={1}>
              Valida tu correo electrónico
              <Form.Item name="email" rules={formFields.email?.rules}>
                <InputMain
                  data-testid="inpt-mail"
                  onClick={() => {
                    setOpenMail(true)
                  }}
                  placeholder="Correo electrónico"
                />
              </Form.Item>
              {formRequest?.email && (
                <InputMessage message="Correo electrónico validado" />
              )}
            </Col>
          </Row>
        </FormMain>
        <FooterOnboar
          back={props.canReturn ? canReturnMobile(props, 8) : null}
          next={
            <ButtonNext
              background="green"
              border="green"
              success
              data-testid="btn-verify-info"
              loading={loading}
              size="large"
              onClick={onCheck}>
              Continuar
            </ButtonNext>
          }
        />
      </Wrapper>
    </Container>
  )
}

export default VerifyInfo

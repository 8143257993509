import produce from 'immer';

const initialState = {
  account: {},
  accounts: [],
  Loadingaccounts: false,
  AccountError: false,
  points: {},
  pointsData: [],
  Loadingpoints: false,
  PointsError: false,
  extra: {},
  extraData: [],
  Loadingextra: false,
  ExtraError: false,
  pointZero: {},
  pointsZeroData: [],
  LoadingZeroData: true,
  ZeroError: false,
};

const EstadoDeCuentaReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'ACCOUNT_STATUS_SUCCESS':
        draft.accounts = action.payload;
        break;
      case 'ACCOUNT_STATUS_ERROR':
        draft.AccountError = action.payload;
        break;
      case 'ACCOUNT_STATUS_SHOW':
        draft.Loadingaccounts = true;
        break;
      case 'ACCOUNT_STATUS_HIDE':
        draft.Loadingaccounts = false;
        break;
      case 'POINTS_ACCOUNT_SUCCESS':
        draft.points = action.payload;
        break;
      case 'POINTS_ACCOUNT_SHOW':
        draft.Loadingpoints = true;
        break;
      case 'POINTS_ACCOUNT_HIDE':
        draft.Loadingpoints = false;
        break;
      case 'POINTS_ACCOUNT_ERROR':
        draft.PointsError = action.payload;
        break;
      case 'EXTRA_ACCOUNT_SHOW':
        draft.Loadingextra = true;
        break;
      case 'EXTRA_ACCOUNT_SUCCESS':
        draft.extra = action.payload;
        break;
      case 'EXTRA_ACCOUNT_ERROR':
        draft.ExtraError = action.payload;
        break;
      case 'EXTRA_ACCOUNT_HIDE':
        draft.Loadingextra = false;
        break;
      case 'POINTS_ACCOUNT_ZERO_SHOW':
        draft.LoadingZeroData = true;
        break;
      case 'POINTS_ACCOUNT_ZERO_HIDE':
        draft.LoadingZeroData = false;
        break;
      case 'POINTS_ACCOUNT_ZERO_SUCCESS':
        draft.pointZero = action.payload;
        break;
      case 'POINTS_ACCOUNT_ZERO_ERROR':
        draft.ZeroError = action.payload;
        break;

      default:
        return state;
    }
  });

export default EstadoDeCuentaReducer;

export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const SET_PRODUCT_DATA = 'SET_PRODUCT_DATA';
export const SET_LOADING_DATA_ACCOUNT = 'SET_LOADING_DATA_ACCOUNT';
export const SET_PRODUCT_CORPORATE = 'SET_PRODUCT_CORPORATE';
export const RESET_STATE = 'RESET_STATE';
export const SET_ALL_MOVEMENTS = 'SET_ALL_MOVEMENTS';
export const SET_LOADING_MOVEMENTS = 'SET_LOADING_MOVEMENTS';
export const SET_ERROR_MOVEMENTS = 'SET_ERROR_MOVEMENTS';

export const setSelectedProduct = productId => ({
  type: SET_SELECTED_PRODUCT,
  payload: productId,
});

export const setProductData = (productId, data) => ({
  type: SET_PRODUCT_DATA,
  payload: {productId, data},
});

export const setLoadingDataAccount = data => ({
  type: SET_LOADING_DATA_ACCOUNT,
  payload: {data},
});

export const setProductCoporate = data => ({
  type: SET_PRODUCT_CORPORATE,
  payload: {data},
});

export const resetState = () => ({
  type: RESET_STATE,
});

//Movimientos
export const setAllMovements = data => ({
  type: SET_ALL_MOVEMENTS,
  payload: {data},
});

export const setLoadingMovements = data => ({
  type: SET_LOADING_MOVEMENTS,
  payload: {data},
});

export const setErrorMovements = data => ({
  type: SET_ERROR_MOVEMENTS,
  payload: {data},
});

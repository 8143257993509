import {Box} from '@mui/material'
import React from 'react'

function Icon({checked, color}) {
  return (
    <Box sx={{display: 'flex', justifyContent: 'center'}}>
      {checked ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24">
          <rect
            width="18"
            height="18"
            x="3"
            y="3"
            fill={color ? '#55ac00' : '#101828'}
            rx="6"></rect>
          <g fill="#fff" clipPath="url(#clip0_1642_8772)">
            <path d="M10.5 14.084L8.415 12l-.71.705L10.5 15.5l6-6-.705-.705-5.295 5.29z"></path>
            <path
              fillRule="evenodd"
              d="M8.415 12l2.085 2.084 5.295-5.29.705.705-6 6-2.795-2.795.71-.705zm2.085 1.518l5.295-5.29 1.27 1.271-6.565 6.566-3.362-3.362 1.278-1.269L10.5 13.52z"
              clipRule="evenodd"></path>
          </g>
          <defs>
            <clipPath id="clip0_1642_8772">
              <path
                fill="#fff"
                d="M0 0H12V12H0z"
                transform="translate(6 6)"></path>
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24">
          <rect
            width="16.4"
            height="16.4"
            x="3.8"
            y="3.8"
            stroke='#101828CC'
            strokeOpacity="0.8"
            strokeWidth="1.6"
            rx="5.2"></rect>
        </svg>
      )}
    </Box>
  )
}

export default Icon

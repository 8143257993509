import {styled} from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import SwipeableDrawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BreakPointTheme} from 'src/theme/breakpoints';
import {FONT_PRIMARY} from 'src/theme/typography';
import CloseButton from '../../assets/images/cmf_linea/closeButton.svg';
import CustomStyledBox from '../Box/BoxCustom';
import {LoadingButton} from '@mui/lab';
import Switch from '@mui/material/Switch';
import security from 'src/assets/icons/cmf_linea/security.svg';
import {
  CardBlocking,
  CardUnblocking,
} from 'src/store/actions/ActiveProductAction';
import {NotificationManager} from 'react-notifications';
import {PRODUCTS_TYPES} from 'src/utils/Constants';
import {useNavigate} from 'react-router-dom';
const StyleBox = styled('div')(({type}) => ({
  ...(type === 'Button' && {
    [BreakPointTheme.breakpoints.up('xxs')]: {
      display: 'flex',
      width: '100%',
    },
  }),
}));

const BlockSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => ({
  width: 64,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(32px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#026E18',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#026E18',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 28,
    height: 28,
    padding: 5,
  },
  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
export default function BlockCards({
  openDrawer,
  closeDrawer,
  types,
  loading,
  imageCardBackground,
  status,
  lastFourDigitCard,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {selectedProduct, loadingBlockCard} = useSelector(
    state => state.ActiveProductReducer,
  );
  const detailsById = useSelector(state => state.productReducer.productData);
  const dataCurrent = detailsById[selectedProduct?.customerProductId];
  const [open, setOpen] = useState(false);
  const [actualStep, setActualStep] = useState(0);
  const toggleDrawer = open => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    if (open === false) {
      setActualStep(0);
    }
    setOpen(openDrawer)
  };

  const onSuccessBlock = success => {
    if (success) {
      setActualStep(actualStep + 1);
    } else {
      NotificationManager.error(
        'Opps algo ha salido mal, por favor intentelo de nuevo.',
      );
    }
  };
  const onSuccessUnblock = success => {
    if (success) {
      navigate('/home');
    } else {
      NotificationManager.error(
        'Opps algo ha salido mal, por favor intentelo de nuevo.',
      );
    }
  };
  const handleBlockCard = () => {
    dispatch(CardBlocking(selectedProduct.customerProductId, onSuccessBlock));
  };
  const handleUnblockCard = () => {
    dispatch(
      CardUnblocking(selectedProduct.customerProductId, onSuccessUnblock),
    );
  };
  const checkedBlock = e => {
    setTimeout(() => {
      if (e) {
        setActualStep(actualStep + 1);
      }
    }, 500);
  };
  const checkedUnblock = e => {
    setTimeout(() => {
      if (e) {
        handleUnblockCard();
      }
    }, 500);
  };
  const textToShow =
    selectedProduct?.productTypeId?.toUpperCase() === PRODUCTS_TYPES.PREPAGO
      ? 'Tarjeta Prepago'
      : 'Tarjeta de Crédito';

  const OptionsCard = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
          alignSelf: 'stretch',
          fontFamily: FONT_PRIMARY,
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
          letterSpacing: '-0.36px',
        }}>
        Opciones de Tarjeta
        <img
          role="button"
          alt="Close button"
          src={CloseButton}
          onClick={() => {
            closeDrawer();
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '16px 24px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          gap: '8px',
          alignSelf: 'stretch',
          height: '100%',
        }}>
        <Box>
          <Typography variant="blockedText">
            {textToShow} - **** {lastFourDigitCard}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            marginBottom: '24px',
          }}>
          <Typography variant="blockedText">
            <img
              style={{width: '283.94px', height: '180px', borderRadius: '14px'}}
              src={imageCardBackground}
              alt="card"
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            flexDirection: 'row',
          }}>
          <Typography
            variant="blockedText"
            sx={{display: 'flex', flexDirection: 'row'}}>
            <img src={security} alt="security" style={{padding: '0 10px'}} />
            Bloquear Tarjeta
          </Typography>
          <BlockSwitch onChange={e => checkedBlock(e)} />
        </Box>
        <Divider />
      </Box>
      <Divider />
    </CustomStyledBox>
  );
  const BlockedCard = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
          alignSelf: 'stretch',
          fontFamily: FONT_PRIMARY,
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
          letterSpacing: '-0.36px',
        }}>
        Bloquear Tarjeta
        <img
          role="button"
          alt="Close button"
          src={CloseButton}
          onClick={() => {
            if (!loadingBlockCard) {
              closeDrawer();
            }
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '16px 24px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          alignSelf: 'stretch',
          height: '100%',
        }}>
        <Box>
          <Typography variant="blockedText">
            {textToShow} - **** {lastFourDigitCard}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            marginBottom: '24px',
          }}>
          <Typography variant="blockedText">
            <img
              style={{width: '283.94px', height: '180px', borderRadius: '14px'}}
              src={imageCardBackground}
              alt="card"
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            flexDirection: 'row',
          }}>
          <Typography variant="description">
            Por favor confirma que quieres bloquear esta tarjeta temporalmente.
            Podrás desbloquearla en cualquier momento que desees.
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            padding: '16px',
            marginTop: 'auto',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            alignSelf: 'stretch',
            height: '50%',
            justifyContent: 'flex-end',
          }}>
          <LoadingButton
            loading={loadingBlockCard}
            variant="contained"
            sx={{
              display: 'flex',
              height: '56px',
              padding: '0px 16px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              alignSelf: 'stretch',
              borderRadius: '100px',
            }}
            onClick={() => {
              handleBlockCard();
            }}>
            <Typography variant="buttonStyle">Sí, bloquear tarjeta</Typography>
          </LoadingButton>
        </Box>
      </Box>
    </CustomStyledBox>
  );
  const BlockedSuccess = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
          alignSelf: 'stretch',
          fontFamily: FONT_PRIMARY,
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
          letterSpacing: '-0.36px',
        }}>
        Bloquear Tarjeta
        <img
          role="button"
          alt="Close button"
          src={CloseButton}
          onClick={() => {
            navigate('/home');
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '16px 24px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          alignSelf: 'stretch',
          height: '100%',
        }}>
        <Box>
          <Typography variant="blockedText">
            {textToShow} - **** {lastFourDigitCard}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            marginBottom: '24px',
          }}>
          <Typography variant="blockedText">
            <img
              style={{
                width: '283.94px',
                height: '180px',
                borderRadius: '14px',
                filter: 'grayscale(100%)',
              }}
              src={imageCardBackground}
              alt="card"
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            flexDirection: 'row',
          }}>
          <Typography variant="description">
            Tu tarjeta ha sido bloqueada en este momento. No podrás hacer
            compras, pagos o recargas hasta que la vuelvas a desbloquear.
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            padding: '16px',
            marginTop: 'auto',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            alignSelf: 'stretch',
            height: '50%',
            justifyContent: 'flex-end',
          }}>
          <LoadingButton
            variant="contained"
            sx={{
              display: 'flex',
              height: '56px',
              padding: '0px 16px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              alignSelf: 'stretch',
              borderRadius: '100px',
            }}
            onClick={() => {
              navigate('/home');
            }}>
            <Typography variant="buttonStyle">Finalizar</Typography>
          </LoadingButton>
        </Box>
      </Box>
    </CustomStyledBox>
  );
  const StepperBlocked = [
    {
      screen: 'BlockedCard',
      title: 'Opciones de Tarjeta',
      component: OptionsCard,
    },
    {
      screen: 'BlockedCard',
      title: 'Bloquear Tarjeta',
      component: BlockedCard,
    },
    {
      screen: 'BlockedCard',
      title: 'Tarjeta Bloqueada',
      component: BlockedSuccess,
    },
  ];
  const OptionsBlockedCard = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
          alignSelf: 'stretch',
          fontFamily: FONT_PRIMARY,
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
          letterSpacing: '-0.36px',
        }}>
        Opciones de Tarjeta
        <img
          role="button"
          alt="Close button"
          src={CloseButton}
          onClick={() => {
            closeDrawer();
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '16px 24px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          gap: '8px',
          alignSelf: 'stretch',
          height: '100%',
        }}>
        <Box>
          <Typography variant="blockedText">
            {textToShow} - **** {lastFourDigitCard}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            marginBottom: '24px',
          }}>
          <Typography variant="blockedText">
            <img
              style={{
                width: '283.94px',
                height: '180px',
                borderRadius: '14px',
                filter: 'grayscale(100%)',
              }}
              src={imageCardBackground}
              alt="card"
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            flexDirection: 'row',
          }}>
          <Typography
            variant="blockedText"
            sx={{display: 'flex', flexDirection: 'row'}}>
            <img src={security} alt="security" style={{padding: '0 10px'}} />
            Desbloquear Tarjeta
          </Typography>
          <BlockSwitch onChange={e => checkedUnblock(e)} />
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            flexDirection: 'row',
          }}>
          <Typography variant="description">
            Tu tarjeta ha sido bloqueada en este momento. No podrás hacer
            compras, pagos o recargas hasta que la vuelvas a desbloquear.
          </Typography>
        </Box>
      </Box>
      <Divider />
    </CustomStyledBox>
  );
  const StepperUnblocked = [
    {
      screen: 'BlockedCard',
      title: 'Opciones de Tarjeta',
      component: OptionsBlockedCard,
    },
  ];

  return (
    <>
      <StyleBox type={types}>
        {['right'].map(anchor => (
          <React.Fragment key={anchor}>
            <SwipeableDrawer
              sx={{
                '& .MuiDrawer-paper': {
                  boxShadow: 'none',
                  backgroundColor: '#FFF',
                  borderRadius: '32px 0px 0px 32px',
                  height: '100%',
                  [BreakPointTheme.breakpoints.down('sm')]: {
                    width: '100vw',
                    borderRadius: 0,
                    height: '100%',
                    minHeight: '-webkit-fill-available',
                  },
                },
              }}
              anchor="right"
              open={openDrawer}
              onOpen={toggleDrawer(true)}
              // onClose={toggleDrawer(false)}
              >
              {status
                ? StepperUnblocked[actualStep]['component'](anchor)
                : StepperBlocked[actualStep]['component'](anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </StyleBox>
    </>
  );
}

BlockCards.propTypes = {
  openDrawer: PropTypes.any,
  closeDrawer: PropTypes.any,
  icon: PropTypes.any,
  loading: PropTypes.any,
  mode: PropTypes.any,
  state: PropTypes.shape({
    ActiveProductReducer: PropTypes.any,
    AuthenticationReducer: PropTypes.any,
    productReducer: PropTypes.shape({
      productData: PropTypes.any,
    }),
  }),
  texto: PropTypes.any,
  types: PropTypes.string,
  imageCardBackground: PropTypes.any,
  status: PropTypes.bool,
  lastFourDigitCard: PropTypes.any,
};
/*
- cardType: string
- imageCard
- Name Card
*/

import { styled } from '@material-ui/core/styles';
import { NotificationsActive } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import './SessionExpired.css';

const Title = styled('h1')(({}) => ({
  fontFamily: 'Red Hat Text',
  color: '#101828',
  fontWeight: '500',
  fontSize: '30px',
  display: 'flex',
  justifyContent: 'center',
}));

const GradientIcon = styled(NotificationsActive)(({theme}) => ({
  fontSize: '40px',
  color: '#026e18',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));

ModalCloseLogin.propTypes = {
  open: PropTypes.bool,
};

export default function ModalCloseLogin({open}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    dispatch({
      type: 'SHOW_SCREEN_LOGIN',
      payload: true,
    });
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        sx: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          [theme.breakpoints.up('md')]: {
            width: '40%',
            height: '40%',
          },
        },
      }}>
      <GradientIcon />
      <Title>Sesión finalizada</Title>
      <p>Tu sesión se ha terminado por motivos de seguridad.</p>
      <LoadingButton
        variant="contained"
        sx={{
          marginBottom: 3,
          marginTop: 1,
        }}
        onClick={() =>
          dispatch({
            type: 'SHOW_SCREEN_LOGIN',
            payload: false,
          })
        }>
        Aceptar
      </LoadingButton>
      <strong>¿Necesitas asistencia en tu proceso? </strong>
      <div>
        Contáctanos a
        <a
          style={{color: '#000', paddingLeft: 5, fontWeight: '600'}}
          href="mailto:servicioalcliente@cmf.com.pa">
          servicioalcliente@cmf.com.pa
        </a>
      </div>
    </Dialog>
  );
}

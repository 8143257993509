import styled from '@emotion/styled';
import { createTheme } from '@mui/material/styles';
import React from 'react';
import { BreakPointTheme } from 'src/theme/breakpoints';
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
const theme = createTheme()
const Aside = styled('aside')(({positionY}) => ({
  /*all: 'unset',*/
  height: '100%',
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    alignSelf: 'center',
    justifyContent: 'end',
  },
  ...(positionY === 'bottom' && {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'end',
      justifyContent: 'end',
    },
  }),
}))

const Image = styled('img')(({type, positionY}) => ({
  ...(type === 'success' && {
    width: '100%',
    height: '100%',
    paddingTop: 64,
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1,
    [BreakPointTheme.breakpoints.down('xl1')]: {
      height: 'initial',
    },
    [BreakPointTheme.breakpoints.down('xl')]: {
      margin: 'auto',
      width: '100%',
      height: 'initial',
    },
    [BreakPointTheme.breakpoints.down('lg1')]: {
      margin: 'auto',
      width: '100%',
      height: 'initial',
    },
    [BreakPointTheme.breakpoints.down('lg')]: {
      margin: 'auto',
      width: '100%',
    },
    [BreakPointTheme.breakpoints.down('md')]: {
      width: '100%',
    },
    [BreakPointTheme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '13.75rem',
    },
    [BreakPointTheme.breakpoints.down('ax')]: {
      width: '100%',
      maxWidth: '13.75rem',
      height: '40vh',
    },
  }),
  ...(type === 'welcome' && {
    width: '100%',
    height: '71%',
    paddingTop: 6,
    position: 'relative',
    // justifyContent: 'center',
    // alignItems: 'center',
    // flexDirection: 'column',
    zIndex: 1,
    [theme.breakpoints.up('lg')]: {
      margin: 'auto',
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [BreakPointTheme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '28px',
      maxWidth: '200px',
      height: '334px',
    },
    [BreakPointTheme.breakpoints.down('ax')]: {
      width: 'initial',
      // maxWidth: '13.75rem',
      height: isIOS ? '35vh' : '40vh',
    },
  }),
  ...(type === 'stand' && {
    width: 325,
    bottom: 8,
    right: '5%',
    position: 'absolute',
  }),
  objectFit: 'contain',
  ...(type === 'standing' && {
    width: '100%',
    height: '100%',
    paddingTop: '32.04vh',
    position: 'relative',
  }),
  ...(positionY === 'bottom' && {
    [BreakPointTheme.breakpoints.down('sm')]: {
      paddingTop: 0,
      maxWidth: '10rem',
    },
    [BreakPointTheme.breakpoints.down('ax')]: {
      paddingTop: 0,
      maxWidth: '7rem',
    },
  }),
}))

const Line = styled('div')(({colorLine, positionY, positionX, adaptative}) => ({
  ...(colorLine === 'white' && {
    background: 'rgb(249, 249, 249)',
  }),
  ...(colorLine === 'green' && {
    background: '#026E18',
  }),
  ...(colorLine === 'transparent' && {
    background: 'rgba(0,0,0,0)',
  }),

  ...(positionY === 'bottom' && {
    width: '100%',
    height: '3.125rem',
    marginTop: 'auto',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
    [BreakPointTheme.breakpoints.down('md')]: {
      height: '1.25rem',
    },
  }),
  ...(positionX === 'right' && {
    marginLeft: 'auto',
    width: '28px',
    height: '100%',
    [BreakPointTheme.breakpoints.down('md')]: {
      width: '8.5%',
    },
    [BreakPointTheme.breakpoints.down('sm')]: {
      width: '20px',
      position: 'absolute',
      height: '100%',
      top: 0,
      right: 0,
      zIndex: 0,
    },
  }),
  ...(adaptative === true && {
    [BreakPointTheme.breakpoints.down('xsm')]: {
      width: '2.4vh',
    },
  }),
}))

const DivImage = styled('div')(({positionX, positionY, action}) => ({
  position: 'absolute',
  ...(positionX === 'right' && {
    ...((action = 'end')
      ? {
          height: '100%',
          [BreakPointTheme.breakpoints.up('md')]: {
            right: 0,
            left: 'calc(100% - 304px)'
          },
          [theme.breakpoints.down('sm')]: {
            height: '40%',
          },
        }
      : {
          height: '100%',
          [theme.breakpoints.down('sm')]: {
            height: 'auto',
          },
        }),
  }),
  ...(positionY === 'bottom' && {
    bottom: 0,
    zIndex: 1,
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      display: 'flex',
      right: 0,
      bottom: 'unset',
      alignItems: 'end',
    },
  }),
}))

export default ({
  type,
  positionY,
  positionX,
  color,
  style,
  action,
  adaptative,
}) => {
  const renderImage = () => {
    switch (type) {
      // case 'stand':
      //   return (
      //     <DivImage
      //       positionX={positionX}
      //       positionY={positionY}
      //       style={{width: '100%', height: '100%'}}>
      //       <Image positionY={positionY} type={type} src={Stand} alt="Promociones" />
      //     </DivImage>
      //   )

      case 'success':
        return (
          <DivImage positionX={positionX} positionY={positionY} action={action}>
            <Image
              positionY={positionY}
              type={type}
              src="DigitalHost_Welcome.webp"
              alt="Promociones"
            />
          </DivImage>
        )
      // case 'standing':
      //   return (
      //     <DivImage positionX={positionX} positionY={positionY}>
      //       <Image positionY={positionY} type={type} src="DigitalHost_Welcome.webp" alt="Promociones" />
      //     </DivImage>
      //   )
      case 'welcome':
        return (
          <DivImage positionX={positionX} positionY={positionY}>
            <Image
              positionY={positionY}
              type={type}
              src="DigitalHost_Welcome.webp"
            />
            {/* <Image positionY={positionY} type={type} src={Success} alt="Promociones" /> */}
          </DivImage>
        )

        default: return null;
    }
  }

  return (
    <Aside positionY={positionY} positionX={positionX} style={style}>
      {renderImage()}
      <Line
        positionY={positionY}
        positionX={positionX}
        colorLine={color}
        adaptative={adaptative}
      />
    </Aside>
  )
}

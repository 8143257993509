import Lottie from 'react-lottie';
import NotFound404 from 'src/assets/Lotties/NotFound.json';
import SubtitleResponsive from 'src/components/TitleInput/SubtitleResponsive';
import TitleResponsive from 'src/components/TitleInput/TitleResponsive';
import './NotFound.css';
const NotFound = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: NotFound404,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div className="not-found">
      <Lottie options={defaultOptions} height="30%" width="30%" />
      <TitleResponsive
        minSize={35}
        maxSize={65}
        text="Página no encontrada"
        color="#026E18"
        fontFamily="Red Hat Text"
      />
      <SubtitleResponsive
        minSize={22}
        maxSize={35}
        text="Parece que la página que estás buscando no existe. Por favor, verifica la dirección URL
              e intenta nuevamente."
        color="#000"
        fontFamily="Red Hat Text"
      />
      <a href="/site">Volver a la página de inicio</a>
    </div>
  );
};
export default NotFound;

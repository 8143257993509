import Grid from '@material-ui/core/Grid';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { LoadingButton } from '@mui/lab';
import { Box, Paper, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ChubbDBanner from 'src/assets/images/cmf_linea/ChubbD.png';
import ChubbDBannerM from 'src/assets/images/cmf_linea/ChubbM.png';
import check from 'src/assets/images/cmf_linea/checks-circle.svg';
import icona_4 from 'src/assets/images/cmf_linea/icona-4/icon_a4.png';
import icona_5 from 'src/assets/images/cmf_linea/icona-5/icon_a5.png';
import icona from 'src/assets/images/cmf_linea/icona-6/icon_a6.png';
import icona_7 from 'src/assets/images/cmf_linea/icona-7/icon_a7.png';
import CHUBBLogo from 'src/assets/images/cmf_linea/logo/CHUBB_Logo_DarkGrey_RGB.png';
import TitleResponsive from 'src/components/TitleInput/SubtitleResponsive';
import { sendInsuranceActivate } from 'src/store/actions/ActiveProductAction';
import { BreakPointTheme } from 'src/theme/breakpoints';
import { reloadInsurancePage } from 'src/utils/Functions';
import { ContainerMaterial } from '../Pages/ContainerHome';
import { SecurityScreenList } from './SecurityList';
const arr = [
  {
    img: icona,
    imgClasses: 'h-[70px]',
    title: 'No dejes desamparada a tu familia',
    subTitle:
      'Te otorgamos hasta $7,800 para ayudar a cubrir cualquier necesidad si falleces accidentalmente.',
  },
  {
    img: icona_5,
    imgClasses: 'h-[70px]',
    title: 'Los accidentes suceden cuando menos los esperas',
    subTitle: 'Recibe  $780 de reembolso por tus gastos medicos por accidente.',
  },
  {
    img: icona_7,
    imgClasses: 'h-[55px]',

    title: 'Servicio de Telemedicina',
    subTitle:
      'Asistencia médica telefónica 24/7 donde te ayudamos a evaluar tu estado de salud actual.',
  },
  {
    img: icona_4,
    imgClasses: 'h-[55px] w-[55px]',
    title: 'Pago automático',
    subTitle: 'Se carga automáticamente a tu tarjeta de crédito CMF.',
  },
];
const Insurance = () => {
  const dispatch = useDispatch();
  const [successPay, setsuccessPay] = useState(false);
  const isLargeScreen = useMediaQuery(BreakPointTheme.breakpoints.up('sm'));
  const loadInsurance = useSelector(
    state => state.ActiveProductReducer.loadInsurance,
  );
  const { selectedProduct } = useSelector(state => state.ActiveProductReducer);
  const { isCorporate } = useSelector(
    state => state.ActiveProductReducer.selectedProduct,
  );
  const navigate = useNavigate();
  const onSuccess = ok => {
    if (ok) {
      setsuccessPay(true);
    }
  };
  const sendInsurance = async () => {
    dispatch(sendInsuranceActivate(onSuccess));
  };
  
  useEffect(() => {
    if (isCorporate) {
      navigate('/home');
    }
  }, [selectedProduct]);

  return (
    <ContainerMaterial maxWidth="xl">
      <Stack
        spacing={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // backgroundColor: '#FFF',
          [BreakPointTheme.breakpoints.only('xxs')]: {
            width: '97%',
            margin: '5px',
          },
          [BreakPointTheme.breakpoints.only('sm')]: {
            width: '80%',
            margin: '5px',
          },
          [BreakPointTheme.breakpoints.only('md')]: {
            width: '100%',
          },
          [BreakPointTheme.breakpoints.only('lg')]: {
            width: '80%',
          },
          [BreakPointTheme.breakpoints.up('lg')]: {
            width: '80%',
          },
        }}>
        {!successPay && (
          <TitleResponsive
            fontSize="24px"
            text="Seguros"
            color="#000"
            textAlign="left"
            fontWeitgh={500}
          />
        )}
        {successPay ? (
          <Grid container spacing={2} style={{ width: '100%', margin: 'auto' }}>
            <Grid item style={{ margin: 'auto' }}>
              <img className="success_chubb" src={check} alt="Success" />
            </Grid>
            <Grid item>
              <Typography
                style={{
                  fontSize: '23px',
                  color: '#011E41',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}>
                Tu seguro de Accidentes Personales ya se encuentra activo{' '}
                {isLargeScreen ? <br /> : ''}
                por $4 mensuales
              </Typography>
              <Grid container>
                <Grid item xs={12}>
                  <Paper
                    elevation={3}
                    style={{
                      padding: '16px',
                      boxShadow: 'none',
                      background: 'transparent',
                    }}>
                    <Typography
                      style={{
                        font: 'normal normal normal 17px/31px Red Hat Text',
                        letterSpacing: '0px',
                        color: '#707070',
                        textAlign: 'center',
                        opacity: 1,
                      }}>
                      Para cualquier consulta, contáctanos en cualquiera de
                      nuestros canales de atención de lunes a sábado de 7:00
                      a.m. a 6:00 p.m. y los domingos de 9:00 a.m. a 6:00 p.m.
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Paper
                    elevation={3}
                    style={{
                      // padding: '16px',
                      textAlign: 'center',
                      boxShadow: 'none',
                      background: 'transparent',
                    }}>
                    <EmailIcon
                      style={{
                        width: '20.107px',
                        height: '20px',
                        color: '#026E18',
                      }}
                    />
                    <Typography variant="body1">
                      servicioalcliente@cmf.com.pa
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Paper
                    elevation={3}
                    style={{
                      // padding: '16px',
                      textAlign: 'center',
                      boxShadow: 'none',
                      background: 'transparent',
                    }}>
                    <PhoneIcon
                      style={{
                        width: '20.107px',
                        height: '20px',
                        color: '#026E18',
                      }}
                    />
                    <Typography variant="body1">302-4460</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6} md={4} sm={2}>
                  <Paper
                    elevation={3}
                    style={{
                      // padding: '16px',
                      textAlign: 'center',
                      boxShadow: 'none',
                      background: 'transparent',
                    }}>
                    <WhatsAppIcon
                      style={{
                        width: '20.107px',
                        height: '20px',
                        color: '#026E18',
                      }}
                    />
                    <Typography variant="body1">6597-8134</Typography>
                  </Paper>
                </Grid>
              </Grid>
              <div
                className="div_exit"
                style={{
                  margin: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '3rem',
                }}>
                <Link to="/Home">
                  <LoadingButton
                    id="button-insurance"
                    variant="contained"
                    sx={{
                      display: 'flex',
                      height: '48px',
                      width: '15rem',
                      padding: '0px 16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '8px',
                      alignSelf: 'stretch',
                      borderRadius: '100px',
                      // [BreakPointTheme.breakpoints.between('sm', 'lg')]: {
                      //   width: '50%',
                      // },
                      // [BreakPointTheme.breakpoints.down('sm')]: {
                      //   width: '95%',
                      // },
                    }}
                    onClick={() => {
                      reloadInsurancePage();
                      navigate('/home');
                    }}>
                    <Typography variant="buttonStyle">Salir</Typography>
                  </LoadingButton>
                </Link>
              </div>
            </Grid>
          </Grid>
        ) : (
          <Box>
            <Grid
              item
              xs={12}
              style={{
                margin: '4px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '14px',
              }}>
              {isLargeScreen ? (
                <img
                  src={ChubbDBanner}
                  alt=""
                  style={{
                    objectFit: 'cover',
                    maxWidth: '100%',
                    borderRadius: '20px',
                  }}
                />
              ) : (
                <img
                  src={ChubbDBannerM}
                  alt=""
                  style={{
                    objectFit: 'cover',
                    maxWidth: '100%',
                    borderRadius: '20px',
                  }}
                />
              )}
              <Box
                sx={{
                  [BreakPointTheme.breakpoints.down('sm')]: {
                    height: '17px',
                    margin: '.5rem 0',
                  },
                }}>
                <img
                  src={CHUBBLogo}
                  alt=""
                  style={{
                    objectFit: 'cover',
                    height: '17px',
                  }}
                />
              </Box>
              <Typography
                style={{
                  fontSize: '23px',
                  color: '#011E41',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}>
                Te presentamos el seguro de Accidentes Personales
              </Typography>
              {/* <div className="flex item-center justify-center px-5 lg:mt-0 mt-4"> */}
              <Paper
                elevation={0}
                sx={{
                  border: '1px solid #011E41',
                  width: '720px',
                  marginBottom: '1rem',
                  [BreakPointTheme.breakpoints.down('sm')]: {
                    width: '90%',
                  },
                }}
              />
              {/* </div> */}
            </Grid>
            <div
              style={{
                gridColumn: 'span 12 / span 12',
              }}>
              <Box
                sx={{
                  display: 'grid',
                  marginTop: '1rem',
                  gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
                  columnGap: '0',
                  rowGap: '1.25rem',
                  [BreakPointTheme.breakpoints.between('sm', 'lg')]: {
                    columnGap: '1rem',
                  },
                  [BreakPointTheme.breakpoints.up('sm')]: {
                    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                  },
                  [BreakPointTheme.breakpoints.up('lg')]: {
                    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
                    columnGap: '0.75rem',
                    rowGap: '0',
                  },
                }}>
                {arr?.map((v, k) => (
                  <>
                    <SecurityScreenList v={v} index={k} />
                  </>
                ))}
              </Box>
            </div>
            <Box
              sx={{
                boxSizing: 'border-box',
                display: 'flex',
                paddingTop: '3rem',
                paddingBottom: '3rem',
                marginTop: '1.25rem',
                flexDirection: 'row',
                gridColumn: 'span 12 / span 12',
                justifyContent: 'space-between',
                alignItems: 'center',
                // height: '4rem',
                background: '#F2F4F7',
                '@media (min-width: 1224px)': {
                  paddingTop: '0.25rem',
                  paddingBottom: '0.25rem',
                },
                [BreakPointTheme.breakpoints.between('sm', 'lg')]: {
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                },
                [BreakPointTheme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                  gap: '.5rem',
                },
              }}>
              <Typography
                sx={{
                  fontSize: '14px !important',
                  '@media (min-width: 1300px)': {
                    width: '60%',
                  },
                  [BreakPointTheme.breakpoints.down('sm')]: {
                    textAlign: 'center',
                  },
                }}>
                <strong>Importante:</strong>
                <span style={{ color: '#B0B0B0', marginLeft: 5 }}>
                  Los clientes mayores de 65 años de edad no están cubiertos por
                  este seguro.
                </span>
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                  width: '100%',
                  fontSize: '1.875rem',
                  lineHeight: '2.25rem',
                  fontWeight: 500,
                  color: '#011E41',
                  gap: '.5rem',
                  '@media (min-width: 1300px)': {
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: 'auto',
                    fontSize: '1.25rem',
                    lineHeight: '1.75rem',
                  },
                  [BreakPointTheme.breakpoints.down('sm')]: {},
                  [BreakPointTheme.breakpoints.down('sm')]: {
                    gap: '0',
                    alignItems: 'center',
                  },
                }}>
                <Typography
                  sx={{
                    fontSize: '25px !important',
                    lineHeight: '1.75rem !important',
                    color: '#9CA3AF !important',
                    '@media (min-width: 1024px)': { marginRight: '0.5rem' },
                    [BreakPointTheme.breakpoints.down('sm')]: {
                      fontSize: '24px !important',
                    },
                  }}>
                  Solo pagas{' '}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: '#011e41',
                    fontSize: '28px !important',
                    fontWeight: '600 !important',
                    [BreakPointTheme.breakpoints.down('sm')]: {
                      fontSize: '28px !important',
                    },
                  }}>
                  $4 por mes
                </Typography>
              </Box>
            </Box>
            <Box
              container
              spacing={5}
              alignItems="center"
              justify="center"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                gap: '1rem',
                margin: '1rem 0',
                [BreakPointTheme.breakpoints.between('sm', 'lg')]: {
                  width: '100%',
                  justifyContent: 'space-between',
                },
                [BreakPointTheme.breakpoints.down('sm')]: {
                  width: '100%',
                  flexDirection: 'column-reverse',
                },
              }}>
              <LoadingButton
                variant="borderContained"
                data-testid="no_insurance"
                onClick={() => {
                  navigate('/home');
                }}
                sx={{
                  display: 'flex',
                  height: '48px',
                  padding: '0px 16px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                  alignSelf: 'stretch',
                  borderRadius: '100px',
                  width: '15rem',
                  [BreakPointTheme.breakpoints.between('sm', 'lg')]: {
                    width: '50%',
                  },
                  [BreakPointTheme.breakpoints.down('sm')]: {
                    width: '95%',
                  },
                }}>
                {' '}
                <Typography
                  variant="buttonStyle"
                  style={{
                    color: '#026E18',
                  }}>
                  No en este momento
                </Typography>
              </LoadingButton>
              <LoadingButton
                onClick={sendInsurance}
                disabled={loadInsurance}
                data-testid="yes_insurance"
                loading={loadInsurance}
                variant="contained"
                sx={{
                  display: 'flex',
                  height: '48px',
                  width: '15rem',
                  padding: '0px 16px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                  alignSelf: 'stretch',
                  borderRadius: '100px',
                  [BreakPointTheme.breakpoints.between('sm', 'lg')]: {
                    width: '50%',
                  },
                  [BreakPointTheme.breakpoints.down('sm')]: {
                    width: '95%',
                  },
                }}>
                <Typography variant="buttonStyle">Si, lo quiero</Typography>
              </LoadingButton>
            </Box>
            <Typography
              sx={{
                [BreakPointTheme.breakpoints.down('sm')]: {
                  marginTop: '1rem !important',
                },
                fontSize: '12px !important',
                marginTop: '4px',
                padding: '4px',
                marginLeft: '5px',
                color: '#B0B0B0',
                marginBottom: '1rem !important',
              }}>
              Regulado y supervisado por la Superintendencia de Seguros y
              Reaseguros de Panamá. Este seguro es ofrecido por Chubb Seguros
              Panamá, S.A. Lo descrito es un resumen de las condiciones del
              seguro por lo que no debe ser interpretado como una representación
              de los términos, condiciones y exclusiones de la póliza. Por favor
              referirse a la póliza para conocer los términos y condiciones
              aplicables. CMF no es ni será responsable por cualquier
              reclamación o cualquier asunto relacionado o derivado del seguro
              Renta Segura.
            </Typography>
          </Box>
        )}
      </Stack>
    </ContainerMaterial>
  );
};
export default Insurance;

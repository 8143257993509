import {Box, Button, Card, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import AppStore from 'src/assets/images/cmf_linea/Apple.svg'
import Vector from 'src/assets/images/cmf_linea/Vector.svg'
import AppBanner from 'src/assets/images/cmf_linea/app-banner.png'
import PlayStore from 'src/assets/images/cmf_linea/playStore.svg'
import {BreakPointTheme} from 'src/theme/breakpoints'
import {URL_APP} from './../../utils/Constants'
import './styledApp.css'
const InfoBanner = ({onClick, type}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  // Función que actualiza el estado del ancho de la pantalla cuando cambia el tamaño de la ventana
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }

  // Agregar un efecto para escuchar los cambios en el tamaño de la ventana
  useEffect(() => {
    // Agregar un event listener para el evento 'resize'
    window.addEventListener('resize', updateWindowWidth)

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', updateWindowWidth)
    }
  }, []) // El segundo argumento vacío [] asegura que este efecto se ejecute solo una vez al montar el componente

  return (
    <Card
      sx={{
        flex: 1,
        width: '282px',
        position: 'relative',
        borderRadius: '20px',
        border: '0.7px solid #eaecf0',
        boxSizing: 'border-box',
        height: '170px',
        overflow: 'hidden',
        flexShrink: 0,
        textAlign: 'left',
        fontSize: '16px',
        color: '#026e18',
        fontFamily: 'Red Hat Text',
        [BreakPointTheme.breakpoints.down('sm')]: {
          width: `${windowWidth - 42}px`,
          height: '210px',
        },
      }}>
      {/* <div className="cardBlack1" /> */}
      <img className="vectorIcon" alt="" src={Vector} />
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          [BreakPointTheme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end',
          },
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            bottom: '0',
          }}>
          <Box
            sx={{
              display: 'flex',
              width: '117px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '8px',
              padding: '1rem',
            }}>
            <Typography
              style={{
                alignSelf: 'stretch',
                color: '#026E18',
                fontFamily: 'Red Hat Text',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '18px /* 112.5% */',
              }}>
              Optimiza el manejo <br /> de tu dinero con <br /> CMF App
            </Typography>
            <Typography variant="app">Descárgala en:</Typography>
          </Box>

          <img className="webAppCardMarketingChild" alt="" src={AppBanner} />
        </Box>
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'flex-start',
            gap: '8px',
            paddingLeft: '1rem',
            [BreakPointTheme.breakpoints.down('sm')]: {
              paddingBottom: '1rem',
            },
          }}>
          <Button
            onClick={() => {
              window.open(URL_APP.android, '_blank')
            }}
            style={{
              display: 'flex',
              padding: '6px',
              alignItems: 'center',
              gap: '8px',
              borderRadius: '8px',
              background: '#101828',
              color: '#FFF',
              textAlign: 'center',
              fontFamily: 'Red Hat Text',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '16px /* 160% */',
              letterSpacing: '-0.2px',
              flexDirection: 'row',
            }}>
            <img src={PlayStore} alt="Play store" />
            {/*  */}
            Google Play
          </Button>
          <Button
            onClick={() => {
              window.open(URL_APP.apple, '_blank')
            }}
            style={{
              display: 'flex',
              padding: '6px',
              alignItems: 'center',
              gap: '8px',
              borderRadius: '8px',
              background: '#101828',
              color: '#FFF',
              textAlign: 'center',
              fontFamily: 'Red Hat Text',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '16px /* 160% */',
              letterSpacing: '-0.2px',
              flexDirection: 'row',
            }}>
            <img src={AppStore} alt="App store" />
            {/*  */}
            App Store
          </Button>
        </Box>
      </Box>
    </Card>
  )
}
export default InfoBanner

import {styled} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import WrapperSide from 'src/components/Aside/Wrapper'
import {BreakPointTheme} from 'src/theme/breakpoints'
import MultiSelectBar from '../../../components/MultiStepBar'
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}))
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'scroll',
}))
const WrapperDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '85%',
  alignSelf: 'center',
  marginBottom: '25px',
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '90%',
  },
}))

const DivMain = styled('div')(() => ({
  padding: '0 10%',
  width: '100%',
  margin: '5rem auto',
  [BreakPointTheme.breakpoints.down('xl')]: {
    width: '100%',
    // marginBottom: '3rem',
  },

  [BreakPointTheme.breakpoints.down('lg')]: {
    width: 'auto',
    // marginBottom: '4rem',
  },
  [BreakPointTheme.breakpoints.down('bg')]: {
    width: 'auto',
    // marginBottom: '4rem',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    width: '100%',
    margin: '2rem auto',
    padding: 'initial',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '2rem auto',
    padding: '0 0',
  },
}))
/**
 * The `LayoutMultiSelect` component renders a multi-step form layout with a sidebar, a multi-select
 * bar, and a section for displaying the current step's component.
 * @category Layout Helper
 * @param {number} verificationStep - The current step of the multi-step form.
 * @param {Object.<{number: {name: string,component: React.Component}>}} steps - An object containing the configuration for each step in the multi-step form.
 * @returns The `LayoutMultiSelect` component is returning a JSX element.
 */
const LayoutMultiSelect = ({verificationStep = 0, steps = null}) => {
  const stepsNames =
    steps !== null ? Object.values(steps).map(({name}) => name) : []

  return (
    <Container>
      <WrapperSide background="linear-gradient(#003a11, #016116, #4fa70a)" />
      <Wrapper style={{flex: 1, height: '100%', background: '#F2F4F7'}}>
        <DivMain>
          <MultiSelectBar
            verificationStep={verificationStep}
            steps={stepsNames}
          />
        </DivMain>
        {steps[verificationStep]?.component}
      </Wrapper>
    </Container>
  )
}

LayoutMultiSelect.prototype = {
  verificationStep: PropTypes.number.required,
  steps: PropTypes.object.required,
}

export default LayoutMultiSelect

/**
 * @flow
 * @type {string}
 */
const CHANGE_CURRENT_STEP = 'CHANGE_CURRENT_STEP';
const CHANGE_LOCALE_LANGUAGE = 'CHANGE_LOCALE_LANGUAGE';
const CHANGE_ENVIRONMENT = 'CHANGE_ENVIRONMENT';
const CHANGE_VERSION = 'CHANGE_VERSION';
const LOADING_SHOW = 'LOADING_SHOW';
const LOADING_HIDE = 'LOADING_HIDE';
const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
const LOGIN_LOADING_SHOW = 'LOGIN_LOADING_SHOW';
const LOGIN_LOADING_HIDE = 'LOGIN_LOADING_HIDE';
const SET_HEADER_SUCCESS = 'SET_HEADER_SUCCESS';
const SET_HEADER_ERROR = 'SET_HEADER_ERROR';
const GET_GENERAL_INFORMATION_REQUEST = 'GET_GENERAL_INFORMATION_REQUEST';
const GET_GENERAL_INFORMATION_SUCCESS = 'GET_GENERAL_INFORMATION_SUCCESS';
const GET_GENERAL_INFORMATION_ERROR = 'GET_GENERAL_INFORMATION_ERROR';
const GET_GENERAL_INFORMATION_LOADING_SHOW =
  'GET_GENERAL_INFORMATION_LOADING_SHOW';
const GET_GENERAL_INFORMATION_LOADING_HIDE =
  'GET_GENERAL_INFORMATION_LOADING_HIDE';
const GET_FINANCIAL_INSTITUTION_SUCCESS = 'GET_FINANCIAL_INSTITUTION_SUCCESS';
const GET_TENANTS_SUCCESS = 'GET_TENANTS_SUCCESS';
const GET_TENANTS_ERROR = 'GET_TENANTS_ERROR';
const GET_CREDITCARD_SUCCESS = 'GET_CREDITCARD_SUCCESS';
const GET_CREDITCARD_ERROR = 'GET_CREDITCARD_ERROR';
const GET_CREDITCARD_LOADING_SHOW = 'GET_CREDITCARD_LOADING_SHOW';
const GET_CREDITCARD_LOADING_HIDE = 'GET_CREDITCARD_LOADING_HIDE';
const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS';
const GET_BALANCE_ERROR = 'GET_BALANCE_ERROR';
const GET_BALANCE_LOADING_SHOW = 'GET_BALANCE_LOADING_SHOW';
const GET_BALANCE_LOADING_HIDE = 'GET_BALANCE_LOADING_HIDE';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const LOGOUT_LOADING_HIDE = ' LOGOUT_LOADING_HIDE';
const PUSH_NEW_PATH = 'PUSH_NEW_PATH';
const POP_PATH = 'POP_PATH';
const CLEAR_ALL_PATHS = 'CLEAR_ALL_PATHS';
const GET_CREDIT_BALANCE_SUCCESS = 'GET_CREDIT_BALANCE_SUCCESS';
const GET_CREDIT_BALANCE_LOADING_SHOW = 'GET_CREDIT_BALANCE_LOADING_SHOW';
const GET_CREDIT_BALANCE_LOADING_HIDE = 'GET_CREDIT_BALANCE_LOADING_HIDE';
const EDIT_REQUEST_CARD_FORM = 'EDIT_REQUEST_CARD_FORM';
const RESET_REQUEST_CARD_FORM = 'RESET_REQUEST_CARD_FORM';
const GET_VALIDATE_MINIMAL_AGE = 'GET_VALIDATE_MINIMAL_AGE';
const GET_TYPE_OF_IDENTITY = 'GET_TYPE_OF_IDENTITY';
const GET_TYPE_OF_PHONE = 'GET_TYPE_OF_PHONE';
const GET_TYPE_OF_IMAGE = 'GET_TYPE_OF_IMAGE';
const GET_STATUS_ONBOARDING = 'GET_STATUS_ONBOARDING';
const GET_PROFESSIONS = 'GET_PROFESSIONS';
const GET_OCCUPATIONS = 'GET_OCCUPATIONS';
const GET_NATIONALITIES = 'GET_NATIONALITIES';
const GET_NATIONALITIES_TRANSLATE = 'GET_NATIONALITIES_TRANSLATE';
const GET_COUNTRIES_BIRTH_TRANSLATE = 'GET_COUNTRIES_BIRTH_TRANSLATE';
const GET_COUNTRIES_BIRTH = 'GET_COUNTRIES_BIRTH';
const GET_COUNTRIES = 'GET_COUNTRIES';
const GET_PROVINCES = 'GET_PROVINCES';
const GET_DISTRICTS = 'GET_DISTRICTS';
const GET_TOWNSHIPS = 'GET_TOWNSHIPS';
const GET_ACTIVITY_TYPES = 'GET_ACTIVITY_TYPES';
const GET_GENDERS = 'GET_GENDERS';
const GET_MARITAL_STATUS = 'GET_MARITAL_STATUS';
const SET_REQUEST_CARD_LOADING = 'SET_REQUEST_CARD_LOADING';
const GET_PDF_BALANCE_SUCCESS = 'GET_PDF_BALANCE_SUCCESS';
const GET_PDF_BALANCE_ERROR = 'GET_PDF__BALANCEERROR';
const GET_PDF_BALANCE_LOADING_SHOW = 'GET_PDF_BALANCE_LOADING_SHOW';
const GET_PDF_BALANCE_LOADING_HIDE = 'GET_PDF_BALANCE_LOADING_HIDE';
const GET_API_KEY = 'GET_API_KEY';
const DOCUMENT_UPLOADED_SUCCESS = 'DOCUMENT_UPLOADED_SUCCESS';
const DOCUMENT_UPLOADED_ERROR = 'DOCUMENT_UPLOADED_ERROR';
const SET_REQUEST_VIDEO_ID = 'SET_REQUEST_VIDEO_ID';
const ADD_IDENTITY_EID_SUCCESS = 'ADD_IDENTITY_EID_SUCCESS';
const ADD_IDENTITY_EID_ERROR = 'ADD_IDENTITY_EID_ERROR';
const UPDATE_SIGNATURE_REQUEST = 'UPDATE_SIGNATURE_REQUEST';
const GET_PDF_TO_SIGN_SUCCESS = 'GET_PDF_TO_SIGN_SUCCESS';
const GET_LOAD_TO_PDF = 'GET_LOAD_TO_PDF';
const GET_LOAD_TO_PDF_HIDE = 'GET_LOAD_TO_PDF_HIDE';
const GET_CLIENTE_DATA = 'GET_CLIENTE_DATA';
const DIALOG_MESSAGE = 'DIALOG_MESSAGE';
const ADD_IDENTITY_BATCH = 'ADD_IDENTITY_BATCH';
const FORM_REQUEST_SUCCESS = 'FORM_REQUEST_SUCCESS';
const FORM_REQUEST_FAILED = 'FORM_REQUEST_FAILED';
const FORM_REQUEST_UPDATE = 'FORM_REQUEST_UPDATE';
const ACTIVATE_PRODUCT = 'ACTIVATE_PRODUCT';
const SEND_EMAIL = 'SEND_EMAIL';
const ACTIVATE_ONBOARDING = 'ACTIVATE_ONBOARDING';
const CLEAR_PDF = 'CLEAR_PDF';
const GET_SIGNID_DATA = 'GET_SIGNID_DATA';
const GET_CUSTOMERT_ID = 'GET_CUSTOMERT_ID';
const GET_REQUEST_ACCESS = 'GET_REQUEST_FLOW';
const FORM_REQUEST_UPDATE_CREDI = 'FORM_REQUEST_UPDATE_CREDI';
const SET_LATIN_DATA_SUCCESS = 'SET_LATIN_DATA_SUCCESS';
const SET_APC_ID = 'SET_APC_ID';
const DATA_SCORE_MATRIX_SUCCESS = 'DATA_SCORE_MATRIX_SUCCESS';
const GET_SCORE_ONBOARDING_SUCCES = 'GET_SCORE_ONBOARDING_SUCCES';
const ACTIVATE_PRODUCTCRED = 'ACTIVATE_PRODUCTCRED';
const ACTIVE_PROD_STATUS_SHOW = 'ACTIVE_PROD_STATUS_SHOW';
const ACTIVE_PROD_STATUS_HIDE = 'ACTIVE_PROD_STATUS_HIDE';
const DATA_MATRIZ_SIGN = 'DATA_MATRIZ_SIGN';
const ORIGEN_SIGN_ID = 'ORIGEN_SIGN_ID';
const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';
const SET_DATA_CLEAN = 'SET_DATA_CLEAN';
const RESET_STATE_SCREEN = 'RESET_ STATE_SCREEN';
const LOAD_SIGN_UPDATE = 'LOAD_SIGN_UPDATE';
const SET_LOAD_SIGN = 'SET_LOAD_SIGN';
const GET_ACTIVE_BANNER = 'GET_ACTIVE_BANNER';
const GET_CLEAN_BANNER = 'GET_CLEAN_BANNER';
const ONBOARDING_ACTIVE = 'ONBOARDING_ACTIVE'
/* Max allowed loan limit */
const GET_MAX_LOAN_LIMIT = 'GET_MAX_LOAN_LIMIT';
export {
  CHANGE_CURRENT_STEP,
  RESET_STATE_SCREEN,
  CHANGE_LOCALE_LANGUAGE,
  CHANGE_ENVIRONMENT,
  CHANGE_VERSION,
  LOADING_SHOW,
  LOADING_HIDE,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGIN_LOADING_SHOW,
  LOGIN_LOADING_HIDE,
  GET_GENERAL_INFORMATION_SUCCESS,
  GET_GENERAL_INFORMATION_ERROR,
  GET_GENERAL_INFORMATION_LOADING_SHOW,
  GET_GENERAL_INFORMATION_LOADING_HIDE,
  GET_GENERAL_INFORMATION_REQUEST,
  GET_TENANTS_SUCCESS,
  GET_TENANTS_ERROR,
  GET_CREDITCARD_SUCCESS,
  GET_CREDITCARD_ERROR,
  GET_CREDITCARD_LOADING_SHOW,
  GET_CREDITCARD_LOADING_HIDE,
  GET_BALANCE_SUCCESS,
  GET_BALANCE_ERROR,
  GET_BALANCE_LOADING_SHOW,
  GET_BALANCE_LOADING_HIDE,
  LOGOUT_SUCCESS,
  LOGOUT_LOADING_HIDE,
  PUSH_NEW_PATH,
  GET_COUNTRIES_BIRTH_TRANSLATE,
  POP_PATH,
  CLEAR_ALL_PATHS,
  EDIT_REQUEST_CARD_FORM,
  RESET_REQUEST_CARD_FORM,
  GET_VALIDATE_MINIMAL_AGE,
  GET_TYPE_OF_IDENTITY,
  GET_TYPE_OF_PHONE,
  GET_TYPE_OF_IMAGE,
  GET_STATUS_ONBOARDING,
  GET_PROFESSIONS,
  GET_OCCUPATIONS,
  GET_NATIONALITIES,
  GET_NATIONALITIES_TRANSLATE,
  GET_COUNTRIES_BIRTH,
  GET_COUNTRIES,
  GET_PROVINCES,
  GET_DISTRICTS,
  GET_TOWNSHIPS,
  GET_ACTIVITY_TYPES,
  GET_GENDERS,
  GET_MARITAL_STATUS,
  SET_REQUEST_CARD_LOADING,
  GET_CREDIT_BALANCE_SUCCESS,
  GET_CREDIT_BALANCE_LOADING_SHOW,
  GET_CREDIT_BALANCE_LOADING_HIDE,
  GET_PDF_BALANCE_SUCCESS,
  GET_PDF_BALANCE_ERROR,
  GET_PDF_BALANCE_LOADING_SHOW,
  GET_PDF_BALANCE_LOADING_HIDE,
  GET_API_KEY,
  SET_HEADER_SUCCESS,
  SET_HEADER_ERROR,
  DOCUMENT_UPLOADED_SUCCESS,
  DOCUMENT_UPLOADED_ERROR,
  SET_REQUEST_VIDEO_ID,
  ADD_IDENTITY_EID_SUCCESS,
  ADD_IDENTITY_EID_ERROR,
  UPDATE_SIGNATURE_REQUEST,
  GET_LOAD_TO_PDF,
  GET_PDF_TO_SIGN_SUCCESS,
  GET_LOAD_TO_PDF_HIDE,
  GET_CLIENTE_DATA,
  DIALOG_MESSAGE,
  ADD_IDENTITY_BATCH,
  FORM_REQUEST_SUCCESS,
  FORM_REQUEST_FAILED,
  FORM_REQUEST_UPDATE,
  ACTIVATE_PRODUCT,
  ACTIVATE_ONBOARDING,
  SEND_EMAIL,
  CLEAR_PDF,
  GET_SIGNID_DATA,
  GET_CUSTOMERT_ID,
  GET_REQUEST_ACCESS,
  GET_TERMS_SUCCESS,
  FORM_REQUEST_UPDATE_CREDI,
  SET_LATIN_DATA_SUCCESS,
  SET_APC_ID,
  DATA_SCORE_MATRIX_SUCCESS,
  GET_SCORE_ONBOARDING_SUCCES,
  ACTIVATE_PRODUCTCRED,
  ACTIVE_PROD_STATUS_SHOW,
  ACTIVE_PROD_STATUS_HIDE,
  DATA_MATRIZ_SIGN,
  ORIGEN_SIGN_ID,
  GET_FINANCIAL_INSTITUTION_SUCCESS,
  SET_DATA_CLEAN,
  LOAD_SIGN_UPDATE,
  SET_LOAD_SIGN,
  GET_ACTIVE_BANNER,
  GET_CLEAN_BANNER,
  ONBOARDING_ACTIVE,
  GET_MAX_LOAN_LIMIT,
};

import { styled } from '@material-ui/core/styles';
import { Container } from '@mui/material';
import { BreakPointTheme } from 'src/theme/breakpoints';

/**
 * CONTENEDOR UTILIZADO EN LAS PANTALLAS PRINCIPALES
 */
export const ContainerMaterial = styled(Container)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [BreakPointTheme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '5px 16px 30px 16px',
  },
  [BreakPointTheme.breakpoints.between('sm', 'md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  [BreakPointTheme.breakpoints.between('md', 'lg')]: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  [BreakPointTheme.breakpoints.up('xl')]: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '30px',
  },
}));
export const ContainerData = styled('div')(({theme}) => ({
  [theme.breakpoints.only('xs')]: {
    width: '100%',
  },
  [BreakPointTheme.breakpoints.up('xxs')]: {
    width: '100%',
  },
  [BreakPointTheme.breakpoints.between('sm', 'sm1')]: {
    width: '90%',
  },
  [BreakPointTheme.breakpoints.only('md')]: {
    width: '80%',
  },
  [BreakPointTheme.breakpoints.between('lg', 'lg1')]: {
    width: '70%',
  },
  [BreakPointTheme.breakpoints.between('lg1', 'xl')]: {
    width: '60%',
  },
  [BreakPointTheme.breakpoints.up('xl')]: {
    width: '55%',
  },
  [BreakPointTheme.breakpoints.up('xxl')]: {
    width: '55%',
  },
}));

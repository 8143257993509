import React from 'react'
import styled from '@emotion/styled'

export default ({step, title}) => {
    return(
        <Wrapper>
            <StyledWrapper>
                <StyledStep>{step}</StyledStep>
            </StyledWrapper>
            <StyledTitle>{title}</StyledTitle>
        </Wrapper>
    )
}

const Wrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
}))

const StyledWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'var(--color-primary-main) 0% 0% no-repeat padding-box',
    padding: 8,
    borderRadius: 50,
    width: 35,
    height: 35
}))

const StyledStep = styled('span')(() => ({
    color: 'white',
    fontSize: '1rem',
}))

const StyledTitle = styled('span')(() => ({
    fontSize: '1rem',
    color: 'black',
    fontWeight: 'bold',
    paddingLeft: 8
}))
import {styled} from '@material-ui/core/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {LoadingButton} from '@mui/lab';
import {Skeleton, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import SwipeableDrawer from '@mui/material/Drawer';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import {useFormik} from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {NumericFormat} from 'react-number-format';
import {useDispatch, useSelector} from 'react-redux';
import {BreakPointTheme} from 'src/theme/breakpoints';
import {FONT_PRIMARY} from 'src/theme/typography';
import {formatoMoneda} from 'src/utils/Functions';
import * as yup from 'yup';
import {WALLET_API} from '../../Data/Api';
import CrediCardGreen from '../../assets/icons/cmf_linea/svgs/credi-card';
import CrediCardWhite from '../../assets/icons/cmf_linea/svgs/credi-cardWhite';
import Monetizacion from '../../assets/icons/cmf_linea/svgs/monetizacion';
import arrowBack from '../../assets/images/cmf_linea/arrowBack.svg';
import CheckCircle from '../../assets/images/cmf_linea/checks-circle.svg';
import CloseButton from '../../assets/images/cmf_linea/closeButton.svg';
import MonetizacionCircle from '../../assets/images/cmf_linea/monetizationCircle.svg';
import * as container from '../../containers/cmf_linea/auth/login.cmf.style';
import CustomStyledBox from '../Box/BoxCustom';
import ButtonBackModal from '../Button/ButtonBackModal';
import {ButtonCheckbox} from '../Button/ButtonCheckbox';
import CreditCardPay from './CreditCardPay';
const StyleBox = styled('div')(({type}) => ({
  ...(type === 'Button' && {
    [BreakPointTheme.breakpoints.up('xxs')]: {
      display: 'flex',
      width: '100%',
    },
  }),
}));
const TableContent = styled(TableContainer)(({theme}) => ({
  width: '100%',
  margin: '0',
  padding: '0',
}));
const ButtonPay = styled(Button)(({theme}) => ({
  marginTop: '24px',
  borderRadius: '50px',
  height: '3.5rem',
  color: theme.palette.common.white,
  fontFamily: FONT_PRIMARY,
  fontWeight: 1,
  fontSize: '1rem',
  // margin: 'auto 20px',
  width: '100%',
}));
const CustomAmountTextField = styled(props => (
  <TextField InputProps={{disableUnderline: true}} {...props} />
))(({theme}) => ({
  '& .MuiInputAdornment-root': {
    marginTop: '0!important',
  },
  '& .MuiFormLabel-root-MuiInputLabel-root': {
    left: '30px',
  },
  '& .MuiInputBase-input-MuiFilledInput-input': {
    paddingLeft: '3px !important',
  },
  '& .MuiInputLabel-root': {
    position: 'absolute',
    left: '30px',
    marginLeft: '14px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
  },
  '& .MuiFilledInput-root': {
    borderRadius: '16px',
    border: '1px solid var(--specific-light-stroke-l, #D0D5DD)',
    background: 'var(--specific-light-background-white, #FFF)',
    overflow: 'hidden',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main,
    },
  },
}));
const validationSchema = yup.object({
  email: yup
    .string('Introduce tu correo electrónico')
    .email('Introduzca un correo electrónico válido')
    .required('Correo electrónico es requerido'),
  password: yup
    .string('Ingresa tu contraseña')
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
    .required('La contraseña es requerida'),
  amount: yup
    .number('Ingresa un valor numérico')
    .required('El monto es requerido')
    .min(1, 'El monto debe ser mayor a $1')
    .max(9999, 'El monto no puede ser mayor a $9999'),
});
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const [value, setValue] = useState('');

  useEffect(() => {
    // Restablecer el valor cuando el componente se monta
    setValue('');

    // Puedes agregar más lógica de reinicio aquí si es necesario
  }, []);
  const {onChange, ...other} = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      value={value} // Utiliza el valor del estado local
      onValueChange={values => {
        setValue(values.value); // Actualiza el estado local con el nuevo valor
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowEmptyFormatting
      valueIsNumericString
      allowNegative={false}
      decimalScale={2}
      prefix="$"
    />
  );
});
export default function SwipeableTemporaryDrawer({
  texto,
  state,
  mode,
  icon,
  types,
  payMin,
  pagoContado,
  loading,
  disabled,
}) {
  const dispatch = useDispatch();
  const {selectedProduct} = useSelector(state => state.ActiveProductReducer);
  const detailsById = useSelector(state => state.productReducer.productData);
  const dataCurrent = detailsById[selectedProduct?.customerProductId];
  const [infoTable, setInfoTable] = useState(null);
  const {CurrentUser} = useSelector(state => state.AuthenticationReducer);
  const [loadingPay, setLoadingModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [style, stepStyle] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [actualStep, setActualStep] = useState(0);
  const [tempOption, setTempOption] = useState(null);

  const [MainSelectedOption, setMainSelectedOption] = useState(null);

  const [amountPay, setAmountPay] = useState(0);
  const handleCheckboxChange = (index, amount) => {
    setAmountPay(parseFloat(amount) || 0);
    setSelectedOption(index);
  };
  const OnCloseDrawer = () => {
    setOpen(false);
    setMainSelectedOption(null);
    setSelectedOption(null);
    setActualStep(0);
    setLoadingModal(false);
    setTempOption(null);
    setAmountPay(0);
  };
  function createData(details, data) {
    return {details, data};
  }
  const formik = useFormik({
    initialValues: {
      amountPay: 0,
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      // dispatch(LoginAuthentication(values, navigate('/loading')))
      // setAmountPay(values)
    },
  });
  const optionsPayCard = [
    {
      icon: <CrediCardGreen />,
      title: 'Saldo Total Adeudado',
      subtitle: `$${pagoContado?.toFixed(2)}`,
      isDisabled: pagoContado === undefined || pagoContado < 1,
      amount: pagoContado,
      color: false,
    },
    {
      icon: <CrediCardGreen />,
      title: 'Pago Mínimo',
      subtitle: `$${payMin?.toFixed(2)}`,
      isDisabled: payMin === undefined || payMin < 1,
      amount: payMin,
      color: false,
    },
    {
      icon: <CrediCardWhite />,
      title: 'Otro Monto',
      subtitle: 'Introduce el monto personalizado',
      isDisabled: false,
      color: true,
    },
  ];
  const optionsPaidMethod = [
    // {
    //   icon: <CrediCardGreen />,
    //   title: 'Mastercard / Visa',
    //   color: true,
    // },
    {
      icon: <Monetizacion />,
      title: 'Yappy',
      color: true,
    },
    {
      icon: <Monetizacion />,
      title: 'e-Clave',
      color: true,
    },
    {
      icon: <Monetizacion />,
      title: 'Pago con ACH',
      color: true,
    },
  ];
  const toggleDrawer = open => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    if (open === false) {
      setActualStep(0);
    }
    setOpen(open);
  };
  const handleNextStepInitial = e => {
    e.preventDefault();
    if (
      selectedOption != null &&
      actualStep < StepperMetrobank.length - 1 &&
      amountPay >= 1 &&
      amountPay <= 9999
    ) {
      setActualStep(actualStep + 1);
      stepStyle(true);
    }
  };
  const handleAmountChange = e => {
    setAmountPay(parseFloat(e.target.value) || 0);
    formik.handleChange(e);
  };

  const handlePreviousStep = e => {
    e.preventDefault();
    if (actualStep > 0) {
      setActualStep(actualStep - 1);
    } else if (actualStep === 0) {
      setMainSelectedOption(null);
      setSelectedOption(null);
      setTempOption(null);
    }
  };
  const handlePreviousStepYappy = e => {
    e.preventDefault();
    if (actualStep > 0) {
      setActualStep(0);
      setMainSelectedOption(null);
      setSelectedOption(null);
      setTempOption(null);
      setAmountPay(0);
    }
  };
  const handlePreviousMetroBank = () => {
    setActualStep(1);
    setMainSelectedOption(null);
    setSelectedOption(null);
    setTempOption(null);
  };
  const handlePreviousStepACH = e => {
    e.preventDefault();
    if (actualStep > 0) {
      setActualStep(1);
      setMainSelectedOption(null);
      setSelectedOption(null);
      setTempOption(null);
    }
  };
  // Payment with Yappy
  async function paymentYappy(amount) {
    setLoadingModal(true);
    try {
      const response = await axios.post(
        `${WALLET_API}api/yappy`,
        {
          phone: '',
          amount: Number(amount),
          userId: selectedProduct?.customerId,
          isWeb: true,
          card: selectedProduct?.customerAccountCards[0]?.card,
        },
        {
          headers: {
            Authorization: `Bearer ${CurrentUser?.tokenCMF}`,
            'content-type': 'application/json',
          },
        },
      );
      if (response.data.success) {
        dispatch({type: 'RECHARGE_MESSAGE', payload: false});
        window.location.href = response.data.url;
      } else {
        NotificationManager.error(
          '¡Algo ha salido mal!, La solicitud de pago no pudo ser procesada.',
        );
      }
    } catch (error) {
      setLoadingModal(false);
      NotificationManager.error(
        '¡Algo ha salido mal!, La solicitud de pago no pudo ser procesada.',
      );
    } finally {
      setLoadingModal(false);
    }
  }
  const PayAmountYappy = () => {
    if (amountPay >= 1) {
      paymentYappy(amountPay);
    } else {
      NotificationManager.error('El monto debe ser mayor o igual a $1.00');
    }
  };
  const AmountToPaid = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
          fontFamily: FONT_PRIMARY,
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
          letterSpacing: '-0.36px',
        }}>
        Pagar tarjeta{/*  */}
        <img
          role="button"
          alt="Close button"
          src={CloseButton}
          onClick={() => {
            OnCloseDrawer();
          }}
        />
      </Box>
      {dataCurrent && Object.keys(dataCurrent).length === 0 ? (
        <div> Spin</div>
      ) : (
        <Box
          sx={{
            display: 'flex',
            padding: '16px 24px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            alignSelf: 'stretch',
          }}>
          {actualStep == 0 ? null : (
            <Box
              sx={{
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
                backgroundColor: 'var(--global-gray-10)',
                padding: '15px',
                borderRadius: '16px',
                cursor: 'pointer',
              }}
              onClick={handlePreviousStep}>
              <img src={arrowBack} alt="arrowBack" />
            </Box>
          )}

          <Typography
            variant="subtitle4"
            style={{
              marginBottom: '6px',
            }}>
            Selecciona el monto
          </Typography>
          {optionsPayCard.map((option, index) => (
            <ButtonCheckbox
              key={index}
              icon={option.icon}
              title={option.title}
              subtitle={option.subtitle}
              color={option.color}
              isSelected={selectedOption === index}
              isDisabled={option.isDisabled}
              onCheckboxChange={() =>
                handleCheckboxChange(index, option.amount)
              }
            />
          ))}
          {selectedOption === 2 ? (
            <container.divFiel>
              <CustomAmountTextField
                fullWidth
                label="Ingresa el monto personalizado"
                autoComplete="off"
                id="reddit-input"
                variant="filled"
                style={{marginTop: 11}}
                placeholder="1000.00"
                name="amount"
                value={formik.values.amount}
                onChange={handleAmountChange}
                onBlur={formik.handleBlur}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        alt="Monetizacion circulo"
                        src={MonetizacionCircle}
                        style={{width: '48px', height: '48px'}}></img>
                    </InputAdornment>
                  ),
                }}
              />
            </container.divFiel>
          ) : (
            ''
          )}
        </Box>
      )}
      <Divider />
      <Box
        sx={{
          display: 'flex',
          padding: '16px',
          marginTop: 'auto',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          alignSelf: 'stretch',
          height: '50%',
          justifyContent: 'flex-end',
        }}>
        <LoadingButton
          variant="contained"
          disabled={amountPay <= 0}
          sx={{
            display: 'flex',
            height: '56px',
            padding: '0px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
            borderRadius: '100px',
          }}
          onClick={handleNextStepInitial}>
          <Typography variant="buttonStyle">Continuar</Typography>
        </LoadingButton>
      </Box>
    </CustomStyledBox>
  );
  const paySelectedRedirect = index => {
    // if (index === 0) {
    //   setMainSelectedOption(index)
    //   setActualStep(index)
    // } else
    if (index === 0) {
      PayAmountYappy();
    } else if (index === 1) {
      eclave();
    } else {
      setMainSelectedOption(index);
    }
  };

  const PaidMethod = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
          alignSelf: 'stretch',
          fontFamily: FONT_PRIMARY,
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
          letterSpacing: '-0.36px',
        }}>
        Pagar tarjeta{/*  */}
        <img
          role="button"
          alt="Close button"
          src={CloseButton}
          onClick={() => {
            OnCloseDrawer();
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '16px 24px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          alignSelf: 'stretch',
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}>
          <Box
            sx={{
              display: 'flex',
              width: '48px',
              height: '48px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '16px',
              background: '#F9FAFB',
            }}>
            <ArrowBackIcon
              onClick={handlePreviousStepYappy}
              sx={{
                width: '24px',
                height: '24px',
                flexShrink: 0,
                color: '#026E18',
              }}
            />
          </Box>
          <Typography
            variant="H5"
            sx={{
              color: 'rgba(16, 24, 40, 0.54)',
            }}>
            Volver
          </Typography>
        </Box>
        <Typography
          variant="subtitle4"
          sx={{
            padding: '20px 0',
          }}>
          Escoge el método de pago
        </Typography>
        {optionsPaidMethod.map((option, index) => (
          <ButtonCheckbox
            step={style}
            color={option.color}
            key={index}
            iconStyle={{
              width: '2.5rem',
              height: '2.5rem',
              marginTop: 4,
              marginBottom: 4,
              marginRight: 10,
            }}
            icon={option.icon}
            title={option.title}
            subtitle={option.subtitle}
            isSelected={tempOption === index}
            onCheckboxChange={() => setTempOption(index)}
          />
        ))}
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          padding: '16px',
          marginTop: 'auto',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          alignSelf: 'stretch',
          height: '50%',
          justifyContent: 'flex-end',
        }}>
        <LoadingButton
          variant="contained"
          loading={loadingPay}
          disabled={tempOption === null}
          sx={{
            display: 'flex',
            height: '56px',
            padding: '0px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
            borderRadius: '100px',
          }}
          onClick={() => {
            paySelectedRedirect(tempOption);
          }}>
          <Typography variant="buttonStyle">Continuar</Typography>
        </LoadingButton>
      </Box>
    </CustomStyledBox>
  );
  const onClose = () => {
    setActualStep(1);
    setMainSelectedOption(0);
  };
  const rows = [
    createData('Método de Pago', 'Tarjeta de Crédito'),
    createData(
      'Código generado',
      infoTable && infoTable?.order[infoTable?.order?.length - 1],
    ),
    createData('Monto Pagado', `${formatoMoneda(amountPay)}`),
    createData(
      'Destinatario',
      `Tarjeta CMF *${dataCurrent?.Tarjeta_Titular ?? ''}`,
    ),
    createData('No. de transacción', infoTable?.authorize ?? ''),
    createData(
      'Fecha',
      infoTable && moment(infoTable?.date).format('DD/MM/YYYY'),
    ),
  ];
  const PaymentGateway = anchor => (
    <CreditCardPay
      onClose={onClose}
      dataResult={setInfoTable}
      amount={amountPay}
      handlePreviousStep={handlePreviousMetroBank}
      OnCloseDrawer={OnCloseDrawer}
    />
  );
  const PaymentSuccess = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
          alignSelf: 'stretch',
          fontFamily: FONT_PRIMARY,
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
          letterSpacing: '-0.36px',
        }}>
        Pagar tarjeta{/*  */}
        <img
          role="button"
          alt="Close"
          src={CloseButton}
          onClick={() => {
            window.location.reload();
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '16px 24px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '16px',
          alignSelf: 'stretch',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '32px',
            }}>
            <img
              alt="Check circle"
              src={CheckCircle}
              style={{width: '60px', height: '60px'}}></img>
            <Typography variant="subtitlePay">¡Pago realizado!</Typography>
            <Typography variant="PaySuccess">
              Haz pagado tu Tarjeta CMF exitosamente
            </Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <TableContent component={Paper}>
              {loading ? (
                <Skeleton variant="rectangular" width="100%">
                  <div style={{height: '15rem'}} />
                </Skeleton>
              ) : (
                <Table
                  sx={{
                    width: '100%',
                    borderRadius: '20px',
                    backgroundColor: '#F9FAFB',
                  }}
                  aria-label="simple table">
                  <TableBody>
                    {rows.map(row => (
                      <TableRow
                        key={row.name}
                        sx={{
                          '&:last-child td, &:last-child th': {border: 0},
                        }}>
                        <TableCell
                          align="left"
                          style={{
                            color: '#101828A3',
                            fontFamily: FONT_PRIMARY,
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '18px',
                          }}>
                          <Typography variant="H4">{row.details}</Typography>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            color: '#026E18',
                            textAlign: 'right',
                            fontFamily: FONT_PRIMARY,
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '18px',
                          }}>
                          {row.data}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </TableContent>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          padding: '16px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          alignSelf: 'stretch',
          height: '50%',
          justifyContent: 'flex-end',
        }}>
        <LoadingButton
          variant="contained"
          sx={{
            display: 'flex',
            height: '56px',
            padding: '0px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
            borderRadius: '100px',
          }}
          onClick={() => {
            window.location.reload();
          }}>
          <Typography variant="buttonStyle">Finalizar</Typography>
        </LoadingButton>
      </Box>
    </CustomStyledBox>
  );

  //Modal para realizar pago con eClave
  const eclave = async () => {
    setLoadingModal(true);
    try {
      const response = await axios.post(
        `${WALLET_API}api/e-clave/paymentUrl`,
        {
          amount: Number(amountPay),
          userId: selectedProduct?.customerId,
          isWeb: true,
          card: selectedProduct?.customerAccountCards[0]?.card,
        },
        {
          headers: {
            Authorization: `Bearer ${CurrentUser?.tokenCMF}`,
            'content-type': 'application/json',
          },
        },
      );
      if (response.data.data.redirect_url) {
        dispatch({type: 'RECHARGE_MESSAGE', payload: false});
        window.location.href = response.data.data.redirect_url;
      } else {
        setLoadingModal(false);
        NotificationManager.error(
          '¡Algo ha salido mal!, La solicitud de pago no pudo ser procesada.',
        );
      }
    } catch (error) {
      setLoadingModal(false);
      NotificationManager.error(
        '¡Algo ha salido mal!, La solicitud de pago no pudo ser procesada.',
      );
    } finally {
      setLoadingModal(false);
    }
  };
  const ACHPaid = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
          alignSelf: 'stretch',
          fontFamily: FONT_PRIMARY,
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
          letterSpacing: '-0.36px',
        }}>
        Pagar tarjeta{/*  */}
        <img
          role="button"
          alt="Close button"
          src={CloseButton}
          onClick={() => {
            OnCloseDrawer();
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '16px 24px',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '16px',
          alignSelf: 'stretch',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <ButtonBackModal
            types="Modal"
            onClick={handlePreviousStepACH}
            texto="Volver"
          />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '32px',
            }}>
            <Typography
              variant="subtitlePay"
              style={{
                fontSize: '1.4rem',
              }}>
              Pago por ACH
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'flex-start',
            }}>
            <Typography
              variant="description"
              sx={{
                textAlign: 'start',
              }}>
              1. Ir al menú de transferencias a terceros de tu banca en línea
            </Typography>
            <br></br>
            <Typography variant="description">
              2. Ingresa los siguientes datos:
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '16px 24px',
                '& li': {
                  color:
                    'var(--specific-light-content-64, var(--specific-light-content-64, rgba(16, 24, 40, 0.64)))',
                  /* Detail/M */
                  fontFamily: FONT_PRIMARY,
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '18px',
                },
                '& span': {
                  color:
                    'var(--specific-light-content-64, var(--specific-light-content-64, rgba(16, 24, 40, 0.64)))',
                  /* Detail/M */
                  fontFamily: FONT_PRIMARY,
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '18px',
                },
              }}>
              <ul
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '6px',
                  textAlign: 'start',
                }}>
                <li>
                  Banco destinatario: <span>Banco General</span>
                </li>
                <li>
                  Nombre: <span>CM Financiera</span>
                </li>
                <li>
                  Tipo de cuenta: <span>Cuenta Corriente</span>
                </li>
                <li>
                  Número de cuenta: <span>03-72-01-124106-0</span>
                </li>
                <li>En la descripción coloca tu número de cédula</li>
              </ul>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          padding: '16px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          alignSelf: 'stretch',
          height: '50%',
          justifyContent: 'flex-end',
        }}>
        <LoadingButton
          variant="contained"
          sx={{
            display: 'flex',
            height: '56px',
            padding: '0px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
            borderRadius: '100px',
          }}
          onClick={OnCloseDrawer}>
          Finalizar
        </LoadingButton>
      </Box>
    </CustomStyledBox>
  );
  //Steppers
  //Ach flow
  const StepperCustomACHPaid = [
    {
      screen: 'PaidMethod',
      title: 'Pagar Tarjeta',
      component: PaidMethod,
    },
    {
      screen: 'ACHPaid',
      title: 'Pagar Tarjeta',
      component: ACHPaid,
    },
  ];
  //Initial step
  const StepperInial = [
    {
      screen: 'AmountToPaid',
      title: 'Pagar Tarjeta',
      component: AmountToPaid,
    },
    {
      screen: 'PaidMethod',
      title: 'Pagar Tarjeta',
      component: PaidMethod,
    },
  ];
  const StepperMetrobank = [
    {
      screen: 'PaymentGateway',
      title: 'Pagar Tarjeta',
      component: PaymentGateway,
    },
    {
      screen: 'PaymentSuccess',
      title: 'Pagar Tarjeta',
      component: PaymentSuccess,
    },
  ];
  const StepperCustomAmount = [
    {
      screen: 'PaidMethod',
      title: 'Pagar Tarjeta',
      component: PaidMethod,
    },
  ];
  const StepperCustomEClave = [
    {
      screen: 'PaidMethod',
      title: 'Pagar Tarjeta',
      component: PaidMethod,
    },
  ];
  return (
    <>
      {loading ? (
        <StyleBox type={types}>
          <ButtonPay variant="contained">
            <Skeleton
              variant="rounded"
              sx={{
                marginTop: 3,
              }}
            />
          </ButtonPay>
        </StyleBox>
      ) : (
        <StyleBox type={types}>
          {['right'].map(anchor => (
            <React.Fragment key={anchor}>
              {types === 'Button' ? (
                <ButtonPay
                  data-testid="button-pay"
                  variant="contained"
                  onClick={() => {
                    setOpen(true);
                  }}>
                  {texto}
                </ButtonPay>
              ) : (
                <Box
                  onClick={() => {
                    if (!disabled) {
                      setOpen(true);
                    }
                  }}
                  data-testid="button-pay"
                  sx={{
                    display: 'flex',
                    width: '120px',
                    height: '120px',
                    padding: '12px',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    flexShrink: 0,
                    borderRadius: '20px',
                    background: disabled
                      ? 'gray'
                      : 'var(--specific-light-background-white, #FFF)',
                    alignItems: 'initial',
                    cursor: disabled ? 'not-allowed' : 'pointer',
                    opacity: disabled ? 0.5 : 1, // Cambiar la opacidad cuando esté deshabilitado
                  }}
                  disabled={disabled}>
                  <div className="actions">
                    <div className="icon-container">
                      <Box
                        sx={{
                          display: 'flex',
                          padding: '9px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '52px',
                          background: '#F1F8F3',
                          width: '40px',
                        }}>
                        {icon}
                      </Box>
                    </div>
                  </div>
                  <div className="text-content">
                    <div className="title-description">
                      {/* <div className="title"> */}
                      <Typography
                        variant="textGreen"
                        sx={{
                          display: 'flex',
                        }}>
                        {texto}
                      </Typography>
                      {/* </div> */}
                    </div>
                  </div>
                </Box>
              )}
              <SwipeableDrawer
                sx={{
                  '& .MuiDrawer-paper': {
                    boxShadow: 'none',
                    backgroundColor: '#00000000',
                  },
                }}
                anchor="right"
                open={open}
                onOpen={toggleDrawer(true)}>
                {// MainSelectedOption === 0
                //   ? StepperMetrobank[actualStep]['component'](anchor)
                //   :
                MainSelectedOption === 0
                  ? StepperCustomAmount[actualStep]['component'](anchor)
                  : MainSelectedOption === 1
                  ? StepperCustomEClave[actualStep]['component'](anchor)
                  : MainSelectedOption === 2
                  ? StepperCustomACHPaid[actualStep]['component'](anchor)
                  : StepperInial[actualStep]['component'](anchor)}
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </StyleBox>
      )}
    </>
  );
}

SwipeableTemporaryDrawer.propTypes = {
  icon: PropTypes.any,
  loading: PropTypes.any,
  mode: PropTypes.any,
  pagoContado: PropTypes.number,
  payMin: PropTypes.number,
  state: PropTypes.shape({
    ActiveProductReducer: PropTypes.any,
    AuthenticationReducer: PropTypes.any,
    productReducer: PropTypes.shape({
      productData: PropTypes.any,
    }),
  }),
  texto: PropTypes.any,
  types: PropTypes.string,
  disabled: PropTypes.bool,
};

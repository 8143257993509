import React from 'react';
import ButtonRender from './render';

function ButtonLogic({
  borderRadius = 'rounded-full',
  label,
  textColor = 'text-btnPrimary',
  bg = 'bg-white border-2 border-[#53A600]',
  py = 'py-3',
  className = '',
  onClick = () => {},
}) {

  const buttonClass = `${
    borderRadius 
  } font-normal ${textColor ? textColor : 'text-btnPrimary'} ${
    bg ? bg : 'bg-white'
  } ${bg ? '' : 'border-2 border-[#53A600]'} w-full  ${
    py ? py : 'py-3'
  } lg:text-lg text-xl lg:font-normal font-semibold  cursor-pointer ${className}`

  return (
    <ButtonRender
      label={label}
      className={buttonClass}
      handleButton={onClick}
    />
  );
}

export default ButtonLogic;

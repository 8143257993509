import styles from './style.module.css'

/**
 * ContactContainer Component displays a contact item with an icon and text.
 * 
 * This component renders a contact item with an icon and corresponding text.
 * 
 * @component
 * @param {string} source - URL of the icon image.
 * @param {string} alt - Alternate text for the icon image.
 * @param {string} text - Text content for the contact item.
 * @example
 * return (
 *   <ContactContainer
 *     source="/path/to/icon.png"
 *     alt="Icon"
 *     text="Contact information"
 *   />
 * )
 */
const ContactContainer = ({ source, alt, text }) => {
    return (
        <div className={styles.contactContainer}>
            <img src={source} alt={alt} />
            <span>{text}</span>
        </div>
    );
}

export default ContactContainer;
import {Fragment} from 'react'
import styles from './style.module.css'
import {useSelector} from 'react-redux'
import {ORIGIN} from 'src/utils/Constants'
import appleStore from 'src/assets/images/onboardingCredito/appleStore.svg'
import playStore from 'src/assets/images/onboardingCredito/playStore.svg'
import appGallery from 'src/assets/images/onboardingCredito/appGallery.svg'
import IconStore from './IconStore'
import CongratsSignSecondSection from './CongratsSignSecondSection'
/**
 * CongratsSignFirstSection Component displays the first section of a congratulatory sign.
 *
 * This component renders the first section of a congratulatory sign, including main text and a button.
 *
 * @component
 * @param {function} onClick - Function to handle click event on the button.
 * @example
 * return (
 *   <CongratsSignFirstSection onClick={() => handleButtonClick()} />
 * )
 */
const CongratsSignFirstSection = ({onClick = () => {}}) => {
  const {origin} = useSelector(state => state.RequestCardReducer)
  return (
    <Fragment>
      {/* Main text and Button */}
      <div className={styles.firstSection}>
        <h1>Tu contrato ha sido firmado</h1>
        <h2>Hemos finalizado el proceso</h2>
        <p>
          Te hemos enviado un correo electrónico con información importante.
        </p>
        <p>
          Encontrarás las instrucciones para que puedas descargar el App y tener
          acceso a tu{' '}
          {origin === ORIGIN.CREDITO
            ? 'tarjeta digital CMF'
            : 'tarjeta prepago CMF'}{' '}
          y una copia de tu contrato.
        </p>
        <div className={styles.storeSection}>
          <p>Descarga nuestra aplicación ahora!</p>
          <p className={styles.disponible}>Disponible en:</p>
          <div className={styles.iconContainer}>
            <IconStore
              url="https://play.google.com/store/apps/details?id=com.cmfinanciera.wallet"
              icons={playStore}
              alt="Play Store"
            />
            <IconStore
              url="https://apps.apple.com/us/app/cmf-app/id6451213803"
              icons={appleStore}
              alt="Apple Store"
            />
            <IconStore
              url="https://appgallery.huawei.com/app/C110471835"
              icons={appGallery}
              alt="App Gallery"
            />
          </div>
        </div>
        <CongratsSignSecondSection />
      </div>
    </Fragment>
  )
}

export default CongratsSignFirstSection

import '@ant-design/compatible/assets/index.css'
import {CloseCircleOutlined} from '@ant-design/icons'
import styled from '@emotion/styled'
import {Button, Checkbox, Form, Modal, Select, Typography} from 'antd'
import {Content} from 'antd/lib/layout/layout'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import AsideLogo from '../../components/Aside/AsideLogo'
import ButtonNext from '../../components/Button/ButtonNext'
import FooterOnboar from '../../components/Footer/FooterOnboarding'
import {
  createOnboadingEvaluation,
  updateOnboaringTE,
} from '../../store/actions/phoneAction'
import {
  editFormRequest,
  getActivityTypes,
  logsOnboarding,
} from '../../store/actions/requestCard.actions'
import {
  BRANCH_OFFICES,
  GENDER_TYPE,
  ONBOARDING_STATUS,
  ORIGIN,
  SELLER_ID_TYPE,
  SYSTEM_TYPES,
} from '../../utils/Constants'
import {formFields} from './FormRequest.config'
import canReturnMobile from './canReturnMobile'
import {SCREENS} from './screens'
import {checkIdentityTE} from 'src/Data/Client'
const {Option} = Select
const ApcTerms = props => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const {onboardingId, base64, checkTe, requestVideoId} = useSelector(
    state => state.RequestCardReducer,
  )
  const formReque = useSelector(state => state.RequestCardReducer.form)
  const [terms, setTerms] = useState(false)
  const [form] = Form.useForm()
  const previousStep = async () => {
    await form.validateFields()

    setLoading(true)
    const forms = {
      id: onboardingId,
      typeIdentityId: '8F3C2EF0-F0D2-4FF0-9863-218D3D494D56',
      branchOfficeId: BRANCH_OFFICES.COCHEZ,
      sellerId: SELLER_ID_TYPE,
      approvedBy: SELLER_ID_TYPE,
      approvedDate: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
      genderId: formReque.gender === 1 ? GENDER_TYPE.MALE : GENDER_TYPE.FEMALE,
      countryId: formReque.country,
      countryBirthId: formReque.countryOfBirth,
      nationalityId: formReque.nationality,
      systemId: SYSTEM_TYPES.CMF,
      identityNumber: formReque.identity,
      firstName: formReque.firstName,
      secondName: formReque.secondName || null,
      firstSurname: formReque.lastName,
      secondSurname: formReque.secondLastName,
      dateOfBirth: formReque.birthdate,
      IDExpirationDate: formReque.IDExpirationDate,
      statusId: ONBOARDING_STATUS.IN_PROCESS,
      images: [
        {
          id: '1',
          typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
          description: 'Cedula Frontal',
          link: formReque.frontIdentityImage,
          thumbnails: null,
          lastModifiedDate: new Date(),
          createdDate: new Date(),
          createdBy: 'CMF',
          lastModifiedBy: null,
        },
        {
          id: '2',
          typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
          description: 'Cedula Reverso',
          link: formReque.backIdentityImage,
          thumbnails: null,
          lastModifiedDate: new Date(),
          createdDate: new Date(),
          createdBy: 'CMF',
          lastModifiedBy: null,
        },
        {
          id: '3',
          typeImageId: 'A7398CE9-96CA-41AE-9513-AE233C6FEB02',
          description: 'Biometrico',
          link: formReque.faceImage,
          thumbnails: null,
          lastModifiedDate: new Date(),
          createdDate: new Date(),
          createdBy: 'CMF',
          lastModifiedBy: null,
        },
      ],
      createdBy: 'CMF',
      LastModifiedBy: 'CMF',
    }
    dispatch(createOnboadingEvaluation(forms, onNext.bind(this)))
  }
  const onNextScreen = success => {
    setLoading(false)
    if (success) {
      props.goToStep(SCREENS.ResultEvaluation)
    }
  }
  const onNext = async success => {
    if (success === true) {
      if (checkTe) {
        try {
          const response = await checkIdentityTE(
            formReque?.identity,
            base64,
            ORIGIN.CREDIMOVIL,
            onboardingId,
            `${formReque?.firstName} ${formReque.lastName}`,
          )
          const {IdentityVerification} = JSON.parse(response.data.jsonAnswer)
          dispatch({type: 'RESULT_TE', payload: IdentityVerification})
          if (IdentityVerification) {
            props.goToStep(SCREENS.ResultEvaluation)
            setLoading(true)
          } else {
            const payload = {
              id: onboardingId,
              typeIdentityId: '8F3C2EF0-F0D2-4FF0-9863-218D3D494D56',
              branchOfficeId: BRANCH_OFFICES.COCHEZ,
              sellerId: SELLER_ID_TYPE,
              approvedBy: SELLER_ID_TYPE,
              approvedDate: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
              genderId:
                formReque.gender === 1 ? GENDER_TYPE.MALE : GENDER_TYPE.FEMALE,
              countryId: formReque.country,
              countryBirthId: formReque.countryOfBirth,
              nationalityId: formReque.nationality,
              systemId: SYSTEM_TYPES.CMF,
              identityNumber: formReque.identity,
              firstName: formReque.firstName,
              secondName: formReque.secondName || null,
              firstSurname: formReque.lastName,
              secondSurname: formReque.secondLastName,
              dateOfBirth: formReque.birthdate,
              statusId: ONBOARDING_STATUS.REJECTED,
              images: [
                {
                  id: '1',
                  typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
                  description: 'Cedula Frontal',
                  link: formReque.frontIdentityImage,
                  thumbnails: null,
                  lastModifiedDate: new Date(),
                  createdDate: new Date(),
                  createdBy: 'CMF',
                  lastModifiedBy: null,
                },
                {
                  id: '2',
                  typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
                  description: 'Cedula Reverso',
                  link: formReque.backIdentityImage,
                  thumbnails: null,
                  lastModifiedDate: new Date(),
                  createdDate: new Date(),
                  createdBy: 'CMF',
                  lastModifiedBy: null,
                },
                {
                  id: '3',
                  typeImageId: 'A7398CE9-96CA-41AE-9513-AE233C6FEB02',
                  description: 'Biometrico',
                  link: formReque.faceImage,
                  thumbnails: null,
                  lastModifiedDate: new Date(),
                  createdDate: new Date(),
                  createdBy: 'CMF',
                  lastModifiedBy: null,
                },
              ],
              createdBy: 'CMF',
              LastModifiedBy: 'CMF',
              electronicVideoId: requestVideoId,
              SourceOnboarding: 'PHONE',
              Origin: 'PHONE',
            }
            dispatch(updateOnboaringTE(payload, onNextScreen.bind(this)))
            logsOnboarding(
              onboardingId,
              'PHONE',
              'VALIDATE_IDENTITY_TE',
              form.identity,
              false,
            )
          }
        } catch (error) {
          setLoading(false)
          await logsOnboarding(
            onboardingId,
            ORIGIN.CREDIMOVIL,
            'RESPONSE_WS_TE',
            error.response,
            'PHONE',
            false,
          )
        }
      } else {
        props.goToStep(SCREENS.ResultEvaluation)
      }
    } else if (success === 3) {
      props.goToStep(SCREENS.ResultEvaluation)
    } else {
      setLoading(false)
    }
  }
  const {activityTypes} = useSelector(state => state.RequestCardReducer)
  useEffect(() => {
    dispatch(getActivityTypes())
  }, [])
  const renderModal = () => {
    return (
      <Modal
        closable={true}
        centered
        open={modalOpen}
        footer={false}
        maskClosable={false}
        closeIcon={<CloseCircleOutlined onClick={() => setModalOpen(false)} />}
        onCancel={() => setModalOpen(false)}>
        <ModalContainer>
          <Title>Indica cuál es tu actividad laboral</Title>
          <InfoWrapper>
            <Form name="basic" form={form} style={{width: '90%'}}>
              <Form.Item
                name="activityType"
                rules={formFields?.activityType?.rules}>
                <SelectMain
                  id={'activityType'}
                  data-testid="select-activity"
                  onSelect={value => {
                    dispatch(editFormRequest({'activityType': value}))
                  }}
                  placeholder={'Selecciona'}>
                  {activityTypes?.map(item => {
                    return (
                      <Option value={item.value} label={item.label}>
                        {item.label}
                      </Option>
                    )
                  })}
                </SelectMain>
              </Form.Item>
            </Form>
          </InfoWrapper>
        </ModalContainer>

        <ButtonsContainer>
          <Buttons
            button_type="solid"
            data-testid="accept-apc"
            loading={loading}
            onClick={previousStep}>
            Aceptar
          </Buttons>
        </ButtonsContainer>
      </Modal>
    )
  }

  return (
    <Container>
      {renderModal()}
      <Wrapper style={{flex: 0.2, background: 'rgb(41, 164, 56)'}}>
        <AsideLogo
          positionX="left"
          positionY="top"
          color="white"
          hugColor="#FFF"
        />
      </Wrapper>

      <Wrapper style={{flex: 1, height: '100%', justifyContent: 'center'}}>
        <TypographyTitle>
          Aprueba la revisión de tus datos en la APC
        </TypographyTitle>
        <ContentMain>
          <TypographyMain>
            <b>PRIMERO:</b> Por este medio autorizo(amos) expresamente a CM
            FINANCIERA, S.A., sus subsidiarias y/o afiliadas, cesionarios o
            sucesoras, así como cualquier compañía que por operación de cesión,
            compra de cartera o administración, adquiera los derechos de mi
            crédito, a que de conformidad con lo expresado en el artículo 24 y
            demás disposiciones aplicables de la Ley 24 de 22 de mayo de 2002,
            solicite, consulte, recopile, intercambie y transmita a cualquier
            agencia de información de datos, bancos o agentes económicos
            informaciones relacionadas con obligaciones o transacciones
            crediticias que mantengo o pudiera mantener con dichos agentes
            económicos de la localidad, sobre mi (nuestro) historial de crédito
            y relaciones con los acreedores.
          </TypographyMain>
          <br></br>
          <TypographyMain>
            <b>SEGUNDO:</b> Autorizo a CM FINANCIERA, S.A., y/o afiliadas,
            cesionarios o sucesoras, así como cualquier compañía que por
            operación de cesión, administración o compra de cartera, adquiera
            los derechos de mi crédito, a que solicite y obtenga información de
            instituciones gubernamentales relacionadas con las obligaciones o
            transacciones crediticias arriba referidas. Asimismo exonero(amos)
            de cualquier consecuencia o responsabilidad a CM FINANCIERA, S.A., a
            sus compañías afiliadas, subsidiarias, cesionarios y/o sucesoras, a
            sus empleados, ejecutivos, directores dignatarios o apoderados, así
            como a cualquier compañía que por una operación de cesión,
            administración o compra de cartera, adquiera los derechos de mi
            crédito. CM FINANCIERA, S.A., queda obligado a mantener a
            disposición del interesado, y le hará entrega a su requerimiento, de
            toda la información que reciba, mantenga o maneje en base a la
            autorización emitida por éste.
          </TypographyMain>
          <br></br>
          <TypographyMain>
            <b>TERCERO:</b> CM FINANCIERA, S.A y APC BURÓ, S.A no utilizarán los
            datos recopilados de acuerdo con este documento para fines distintos
            a los establecidos en este documento ni para fines incompatibles con
            aquellos para los cuales los datos fueron recopilados y pudiendo ser
            portados a otro agente económico a solicitud de mi persona en lo que
            respecta a aquellos datos que son regulados por la Ley 81 del 26 de
            marzo de 2019 sobre protección de datos.
          </TypographyMain>
          <br></br>
          <TypographyMain>
            <b>CUARTO:</b> Autorizo de forma expresa a CM FINANCIERA, S.A. a
            compartir con sus subsidiarias y/o afiliadas, cesionarios o
            sucesoras toda mi información y datos personales necesarios para ser
            incluidos en las bases de datos de estas compañías.
          </TypographyMain>
          <br></br>
          <TypographyMain>
            <b>QUINTO:</b> Todos los datos que el Usuario proporcione a CM
            FINANCIERA, S.A. se encontrarán sujetos a nuestro control conforme a
            la legislación aplicable de la República de Panamá. Los datos que
            proporcione el Usuario serán de uso exclusivo e interno de CM
            FINANCIERA (y sus subsidiarias), y no se hará uso de dichos datos
            para efectos de comercialización o divulgación a ningún otro ente.
          </TypographyMain>
          <TypographyMain>
            <b>SEXTO:</b> Las partes expresamente reconocen la validez de todos
            los documentos firmados en formato electrónico, mediante firma
            digital o firma electrónica. Las partes acuerdan que para todos los
            propósitos se considerará válido si se procede con la ejecución de
            contratos en formato electrónico. Las partes acuerdan que para todos
            los propósitos las firmas electrónicas y firmas digitales se
            considerarán firmas válidas. También acuerdan otorgar validez a las
            autorizaciones digitales de solicitud y trámites relacionados con
            los productos que ofrece CM Financiera, S.A., que sean solicitados
            por el Cliente. El deudor declara que la información que ha
            suministrado para la obtención del crédito aquí documentada es
            verídica.
          </TypographyMain>
        </ContentMain>
        <AcceptTerms>
          <Checkbox
            data-testid="terms"
            onChange={e => setTerms(e.target.checked)}>
            Acepto los Términos y Condiciones
          </Checkbox>
        </AcceptTerms>
        <FooterOnboar
          back={props.canReturn ? canReturnMobile(props, 3) : null}
          next={
            <ButtonNext
              background="green"
              border="green"
              data-testid="next-apc"
              disabled={!terms}
              size="large"
              onClick={() => setModalOpen(true)}>
              Continuar
            </ButtonNext>
          }
        />
      </Wrapper>
    </Container>
  )
}

const SelectMain = styled(Select)(() => ({
  width: '85%',
  paddingBottom: '0 !important',
  '.ant-select-selector': {
    width: ' 100%',
    height: '3.5rem !important',
    padding: ' 0 11px',
    borderRadius: '4px !important',
  },
  '.ant-select-selection-search-input': {
    height: '3rem !important',
    margin: 'auto !important',
  },
  '.ant-select-arrow ': {
    color: 'var(--color-primary-main)',
    // borderWidth: ' 520px',

    'svg ': {
      display: ' inline-block',
      strokeWidth: ' 17%',
      stroke: 'var(--color-primary-main)',
      color: 'var(--color-primary-main)',
    },
  },
  '  .ant-select-selection__rendered': {
    display: 'contents',
    padding: '5px',
  },
  '.ant-select-focused .ant-select-single': {
    margin: 'auto',
  },
  '.ant-select-selector .ant-select-selection-placeholder': {
    margin: 'auto',
  },
  '.ant-select-selector .ant-select-selection-item': {
    margin: 'auto',
  },
  '.ant-select-selection-selected-value ': {
    width: ' 86% !important',
    position: ' absolute',
    top: ' 50%',
    right: ' 9px',
    left: ' 15px',
    maxWidth: ' 100%',
    height: ' 20px',
    marginTop: ' -10px',
    overflow: ' hidden',
    lineHeight: ' 20px',
    whiteSpace: ' nowrap',
    textAlign: ' left',
    textOverflow: ' ellipsis',
  },
  '@media (min-width: 1201px) and (max-width: 1535px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 901px) and (max-width: 1200px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 601px) and (max-width: 900px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '4em !important',
    },
  },
  '@media (max-width:600px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '3rem !important',
    },
  },
}))

const Buttons = styled(Button)(({button_type}) => ({
  all: 'unset',
  height: ' 3.5rem',
  padding: ' 0 11px',
  fontFamily: 'Gilroy-Light',
  backgroundColor:
    button_type === 'solid' ? '#13A438 !important' : 'transparent',
  borderColor: button_type === 'border' ? '#13A438' : 'transparent',
  borderStyle: 'solid',
  borderWidth: 2,
  color: button_type === 'border' ? '#13A438 ' : 'white !important',
  width: 175,
  textAlign: 'center',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  borderRadius: 6,
  cursor: 'pointer',
}))

const ButtonsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  width: '100%',
  paddingBottom: 25,
}))

const InfoWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  marginTop: 0,
  paddingBottom: 0,
}))

const Title = styled('h1')(() => ({
  fontSize: '1.4rem',
  textAlign: 'center',
  marginTop: 45,
  fontFamily: 'Gilroy',
}))

const ModalContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const TypographyTitle = styled(Typography)(() => ({
  font: 'normal normal 900 2.2rem/45px Gilroy',
  width: '80%',
  margin: '15vh auto 2vh auto',
  color: 'var(--color-primary-main)',
  '@media (max-width:600px)': {
    font: 'normal normal normal 1.1rem/21px Gilroy',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '4vh auto',
    width: '87%',
    textAlign: 'center',
  },
}))
const TypographyMain = styled(Typography)(() => ({
  font: 'normal normal normal 17px/23px Arial',
  textAlign: 'justify',
  width: '95%',
  '@media (max-width:600px)': {
    font: 'normal normal normal 0.8rem/20px Arial',
    color: 'var(--color-secundario-Grey)',
  },
}))
const ContentMain = styled(Content)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  overflowY: 'scroll',
  width: '80%',
  margin: 'auto',
  '@media (max-width:600px)': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    overflowY: 'scroll',
    width: '80%',
    margin: 'auto',
  },
}))

const AcceptTerms = styled.div(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '80%',
  margin: '2vh auto',
  '@media (max-width:575.98px)': {
    font: 'normal normal normal 1.1rem/21px Gilroy',
    color: 'var(--color-primary-main)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '3vh auto',
    width: '100%',
    textAlign: 'center',
  },
}))

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}))

export default ApcTerms

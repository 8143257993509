import {QuestionCircleOutlined} from '@ant-design/icons'
import styled from '@emotion/styled'
import {Col, Form, Input, Popover, Radio, Row, Select} from 'antd'
import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import AsideLogo from '../../components/Aside/AsideLogo.js'
import ButtonNext from '../../components/Button/ButtonNext.js'
import FooterOnboar from '../../components/Footer/FooterOnboarding.js'
import ProgressBar from '../../components/ProgressBar'
import {updateOnboadingPhones} from '../../store/actions/phoneAction.js'
import {editFormRequest} from '../../store/actions/requestCard.actions'
import {SELLER_ID_TYPE} from '../../utils/Constants'
import {MsgPEP, MsgColab} from '../../utils/Popover.js'
import {fieldTypeOptions, formFields} from './FormRequest.config'
import canReturnMobile from './canReturnMobile.js'
import {SCREENS} from './screens'

const SelectMain = styled(Select)(() => ({
  '.ant-select-selector': {
    width: ' 100%',
    height: '3.5rem !important',
    padding: ' 0 11px',
  },
  '.ant-select-selection-search-input': {
    height: '3rem !important',
    margin: 'auto !important',
  },
  '.ant-select-arrow ': {
    color: ' var(--color-primary-main)',
    // borderWidth: ' 520px',

    'svg ': {
      display: ' inline-block',
      strokeWidth: ' 17%',
      stroke: ' var(--color-primary-main)',
      color: ' var(--color-primary-main)',
    },
  },
  '  .ant-select-selection__rendered': {
    display: 'contents',
    padding: '5px',
  },
  '.ant-select-focused .ant-select-single': {
    margin: 'auto',
  },
  '.ant-select-selector .ant-select-selection-placeholder': {
    margin: 'auto',
  },
  '.ant-select-selector .ant-select-selection-item': {
    margin: 'auto',
  },
  '.ant-select-selection-selected-value ': {
    width: ' 86% !important',
    position: ' absolute',
    top: ' 50%',
    right: ' 9px',
    left: ' 15px',
    maxWidth: ' 100%',
    height: ' 20px',
    marginTop: ' -10px',
    overflow: ' hidden',
    lineHeight: ' 20px',
    whiteSpace: ' nowrap',
    textAlign: ' left',
    textOverflow: ' ellipsis',
  },
  '@media (min-width: 1201px) and (max-width: 1535px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 901px) and (max-width: 1200px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 601px) and (max-width: 900px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '4em !important',
    },
  },
  '@media (max-width:600px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '3rem !important',
    },
  },
}))
const InputMain = styled(Input)(() => ({
  '&.ant-input': {
    height: ' 3.5rem !important',
    padding: ' 0 11px',
  },
  '&.ant-input[disabled]': {
    color: 'rgba(0, 0, 0, 0.25) !important',
    backgroundColor: '#f5f5f5 !important',
    borderColor: '#d9d9d9',
    boxShadow: 'none',
    cursor: 'not-allowed',
    opacity: 1,
  },
  '@media (min-width: 1201px) and (max-width: 1535px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 901px) and (max-width: 1200px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 601px) and (max-width: 900px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '4em !important',
    },
  },
  '@media (max-width:600px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '3rem !important',
    },
  },
}))
const FormMain = styled(Form)(() => ({
  '&.ant-form': {
    padding: '0 5%',
    margin: '0 0 auto 0',
  }
}))
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}))
const {Option} = Select
const valueRadio = 2;
const valueRadioRelation = 2;
const valueRadioResident = 2;
const PoliticalInfoForm = props => {
  const [loading, setLoading] = useState(false)
  const {onboardingPhone} = useSelector(state => state.Phone)
  const formRequest = useSelector(state => state.RequestCardReducer.form)
  const {countriesBirthTranslate} = useSelector(
    state => state.RequestCardReducer,
  )
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 300)
  }, [])

  const onFinish = () => {
   
  }
  const onSuccess = success => {
    setLoading(false)
    if (success === 2) {
      props.goToStep(SCREENS.FilePDF)
    } else if (success === 1) {
      props.goToStep(SCREENS.RequestInRevision)
    }
  }

  const handleChange = e => {
    dispatch(editFormRequest({[e.target.id]: e.target.value}))
  }
  const handleChangeSelect = value => {
    dispatch(editFormRequest({'externalResidents': value}))
  }
  const onCheck = async () => {
    try {
      await form.validateFields()
      let pep = formRequest.isPolitician
      let relpep = formRequest.relationPep
      let crs = formRequest.externalPanamaResident
      let payload = {
        answers: [],
        isCrs: relpep == fieldTypeOptions.YES ? true : false,
        isFatca: crs == fieldTypeOptions.YES ? true : false,
        isPep: pep == fieldTypeOptions.YES ? true : false,
      }

      setLoading(true)
      if (pep) {
        payload.answers.push(
          {
            id: 2,
            groups: 'PEP',
            question: formFields.isPolitician.label,
            typeAnwswer: 'truefalse',
            answer: formRequest.isPolitician ? 'si' : 'no',
            createdBy: SELLER_ID_TYPE,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
          },
          {
            id: 5,
            groups: 'PEP',
            question: formFields.politicalJob.label,
            typeAnwswer: 'text',
            answer: formRequest.politicalJob || 'N/A',
            createdBy: SELLER_ID_TYPE,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
          },
        )
      }

      if (relpep) {
        payload.answers.push(
          {
            id: 6,
            groups: 'Relacionado PEP',
            question: formFields.relationPep.label,
            typeAnwswer: 'truefalse',
            answer: formRequest.relationPep ? 'si' : 'no',
            createdBy: SELLER_ID_TYPE,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
          },
          {
            id: 8,
            groups: 'Relacionado PEP',
            question: formFields.nameRelationPep.label,
            typeAnwswer: 'text',
            answer: formRequest.nameRelationPep || 'N/A',
            createdBy: SELLER_ID_TYPE,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
          },
          {
            id: 10,
            groups: 'Relacionado PEP',
            question: formFields.jobRelationPep.label,
            typeAnwswer: 'text',
            answer: formRequest.jobRelationPep || 'N/A',
            createdBy: SELLER_ID_TYPE,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
          },
        )
      }

      if (crs) {
        payload.answers.push(
          {
            id: 15,
            groups: 'CRS',
            question: formFields.externalPanamaResident.label,
            typeAnwswer: 'truefalse',
            answer: formRequest.externalPanamaResident ? 'si' : 'no',
            createdBy: SELLER_ID_TYPE,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
          },
          {
            id: 17,
            groups: 'CRS',
            question: formFields.externalResidents.label,
            typeAnwswer: 'test',
            answer: formRequest.externalResidents.join(', ') || 'N/A',
            createdBy: SELLER_ID_TYPE,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
          },
          {
            id: 19,
            groups: 'CRS',
            question: formFields.taxID.label,
            typeAnwswer: 'text',
            answer: formRequest.taxId || 'N/A',
            createdBy: SELLER_ID_TYPE,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
          },
          {
            id: 20,
            groups: 'CRS',
            question: formFields.descNonTax.label,
            typeAnwswer: 'text',
            answer: formRequest.descNonTax || 'N/A',
            createdBy: SELLER_ID_TYPE,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
          },
        )
      }
      dispatch(
        updateOnboadingPhones(
          onboardingPhone.id,
          onboardingPhone.origin,
          payload,
          onSuccess,
        ),
      )
    } catch (errorInfo) {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Wrapper style={{flex: 0.2, background: 'rgb(41, 164, 56)'}}>
        <AsideLogo
          positionX="left"
          positionY="top"
          color="white"
          hugColor="#FFF"
        />
      </Wrapper>

      <Wrapper
        style={{
          flex: 1,
          height: '100%',
          justifyContent: 'center',
        }}>
        <ProgressBar active={4} />
        <FormMain
          form={form}
          autoComplete="off"
          initialValues={{
            isPolitician: formRequest?.isPolitician,
            relationPep: formRequest?.relationPep,
            externalPanamaResident: formRequest?.externalPanamaResident,
            politicalJob: formRequest?.politicalJob,
            nameRelationPep: formRequest?.nameRelationPep,
            jobRelationPep: formRequest?.jobRelationPep,
            externalResidents: formRequest?.externalResidents,
            taxId: formRequest?.taxId,
            descNonTax: formRequest?.descNonTax,
          }}
          onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col xs={{span: 24}} sm={{span: 20}} md={{span: 18}} lg={24}>
              <label className="text-muted">
                ¿Eres una persona expuesta políticamente?
                <Popover placement="right" content={MsgPEP} trigger="hover">
                  <QuestionCircleOutlined className="IconQuestion" />
                </Popover>
              </label>
              <div style={{marginLeft: '20px'}}>
                <Form.Item
                  name="isPolitician"
                  rules={formFields.isPolitician?.rules}>
                  <Radio.Group
                    onChange={e =>
                      dispatch(
                        editFormRequest({'isPolitician': e.target.value}),
                      )
                    }
                    value={valueRadio}>
                    <Radio value={fieldTypeOptions.YES}>Si</Radio>
                    <Radio value={fieldTypeOptions.NO} data-testid="btn-no-rel">
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Col>

            {formRequest.isPolitician === fieldTypeOptions.YES && (
              <Col xs={24} sm={{span: 20}} md={{span: 18}} lg={24}>
                <Form.Item
                  name="politicalJob"
                  rules={formFields.politicalJob?.rules}>
                  <InputMain
                    onChange={handleChange}
                    placeholder="Que cargo desempenas?"
                  />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={[16, 16]}>
            <Col
              xs={{span: 20}}
              sm={{span: 20}}
              md={{span: 18}}
              lg={{span: 18}}>
              <span className="text-muted">
                ¿Es familiar cercano o estrecho colaborador de una Persona
                Expuesta Políticamente? *
                <Popover placement="right" content={MsgColab} trigger="hover">
                  <QuestionCircleOutlined className="IconQuestion" />
                </Popover>
              </span>
              <div style={{marginLeft: '20px'}}>
                <Form.Item
                  name="relationPep"
                  rules={formFields.relationPep?.rules}>
                  <Radio.Group
                    // onChange={onChangeRadioRelation}
                    onChange={e =>
                      dispatch(
                        editFormRequest({'relationPep': e.target.value}),
                      )
                    }
                    value={valueRadioRelation}>
                    <Radio value={fieldTypeOptions.YES}>Si</Radio>
                    <Radio
                      value={fieldTypeOptions.NO}
                      data-testid="btn-pep-rel">
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Col>
          </Row>

          {formRequest.relationPep === fieldTypeOptions.YES && (
            <Row gutter={[16, 16]}>
              <Col
                xs={{span: 24}}
                sm={{span: 20}}
                md={{span: 18}}
                lg={{span: 12}}>
                <Form.Item
                  name="nameRelationPep"
                  rules={formFields.nameRelationPep?.rules}>
                  <InputMain
                    onChange={handleChange}
                    placeholder="Cuál es el nombre de la persona expuesta políticamente?"
                  />
                </Form.Item>
              </Col>

              <Col
                xs={{span: 24}}
                sm={{span: 20}}
                md={{span: 18}}
                lg={{span: 12}}>
                <Form.Item
                  name="jobRelationPep"
                  rules={formFields.jobRelationPep?.rules}>
                  <InputMain
                    onChange={handleChange}
                    placeholder="Que cargo desempeña?"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={[16, 16]}>
            <Col
              xs={{span: 20}}
              sm={{span: 20}}
              md={{span: 18}}
              lg={{span: 18}}>
              <span className="text-muted">
                ¿Eres considerado residente fiscal en cualquier otro país
                diferente a Panamá?
              </span>
              <div style={{marginLeft: '20px'}}>
                <Form.Item
                  name="externalPanamaResident"
                  rules={formFields.externalPanamaResident?.rules}>
                  <Radio.Group
                    // onChange={onChangeRadioResident}
                    onChange={e =>
                      dispatch(
                        editFormRequest({
                          'externalPanamaResident': e.target.value,
                        }),
                      )
                    }
                    value={valueRadioResident}>
                    <Radio value={fieldTypeOptions.YES}>Si</Radio>
                    <Radio
                      value={fieldTypeOptions.NO}
                      data-testid="btn-ext-rel">
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Col>

            {formRequest.externalPanamaResident === fieldTypeOptions.YES && (
              <Col xs={24} sm={{span: 20}} md={{span: 18}} lg={24}>
                <Form.Item
                  name="externalResidents"
                  rules={formFields.externalResidents?.rules}>
                  <SelectMain
                    mode="multiple"
                    onChange={handleChangeSelect}
                    placeholder="Cuáles son sus países de residencia fiscal?">
                    {countriesBirthTranslate?.map(item => {
                      return (
                        <Option value={item.label} label={item.label}>
                          {item.label}
                        </Option>
                      )
                    })}
                  </SelectMain>
                </Form.Item>
              </Col>
            )}
          </Row>

          {formRequest.externalPanamaResident === fieldTypeOptions.YES && (
            <Row gutter={8}>
              <Col xs={24} sm={{span: 20}} md={{span: 18}} lg={24}>
                <Form.Item name="taxId" rules={formFields.taxID?.rules}>
                  <InputMain
                    onChange={handleChange}
                    placeholder="Indique su ID tributario en el país de residencia fiscal."
                  />
                </Form.Item>
              </Col>

              {!formRequest.taxId && (
                <Col xs={24} sm={{span: 20}} md={{span: 18}} lg={24}>
                  <Form.Item
                    name="descNonTax"
                    rules={formFields.descNonTax?.rules}>
                    <InputMain
                      onChange={handleChange}
                      placeholder="Si no tiene número de ID Tributario, indique el motivo."
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          )}
        </FormMain>
        <FooterOnboar
          back={props.canReturn ? canReturnMobile(props, 6) : null}
          next={
            <ButtonNext
              background="green"
              border="green"
              loading={loading}
              disabled={false}
              size="large"
              data-testid="btn-next-pep"
              onClick={onCheck}>
              Siguiente
            </ButtonNext>
          }
        />
      </Wrapper>
    </Container>
  )
}

export default PoliticalInfoForm

import { CheckCircleFilled } from '@ant-design/icons'
import styled from '@emotion/styled'
import { createTheme } from '@mui/material/styles'
import { Modal, Select, message } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkDeviceImei } from '../../Data/Client.js'
import QR from '../../assets/images/onboarding/QRNuovopay.png'
import AsideLogo from '../../components/Aside/AsideLogo.js'
import ButtonNext from '../../components/Button/ButtonNext.js'
import InputMessage from '../../components/Calculator/InputMessage.js'
import FooterOnboar from '../../components/Footer/FooterOnboarding.js'
import ImeiScan from '../../components/ImeiScan'
import InputWithIcon from '../../components/InputWithIcon/index.jsx'
import Text from '../../components/Typography/Text.js'
import { GetCatalogsOffices } from '../../store/actions/GeneralInformationAction.js'
import { logsOnboarding } from '../../store/actions/KO.Actions.js'
import { getBannerActive } from '../../store/actions/requestCard.actions.js'
import { openNotificationWithIcon } from '../../utils/Functions.js'
import { SelectMain } from '../CardRequest/ActividadLaboral.js'
import canReturnMobile from './canReturnMobile.js'
import { SCREENS } from './screens.js'
const theme = createTheme()

message.config({
  top: 30,
  duration: 4,
  maxCount: 1,
})

export default props => {
  const imeiScanner = useRef(null)

  const [loading, setLoading] = useState(false)
  const [isValidImei, setIsValidImei] = useState(null)
  const [registrationMessage, setRegistrationMessage] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const {onboardingActive} = useSelector(state => state.Phone)
  const {jsonAnswer} = onboardingActive
  const {onboardingPhoneActive} = useSelector(
    state => state.Phone.onboardingActive,
  )
  const {onboardingId} = useSelector(state => state.RequestCardReducer)

  const [deviceImei, setDeviceImei] = useState('')
  const [branchOfficeId, setBranchOfficeId] = useState('')
  const {Option} = Select

  const officesLoading = useSelector(
    state => state.GeneralInformationReducer.LoadingInformation,
  )
  if (onboardingPhoneActive === true) {
    var respuesta
    respuesta = JSON.parse(jsonAnswer)
    var {Id} = respuesta
  }
  const includeBranches = ['novey', 'cochez']

  const branches = useSelector(state =>
    state.GeneralInformationReducer.offices.filter(x =>
      includeBranches.includes(x.value.toLowerCase()),
    ),
  )

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: phone,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // }
  const dispatch = useDispatch()
  // const [form] = Form.useForm()
  useEffect(() => {
    dispatch(getBannerActive())
    dispatch(GetCatalogsOffices())
  }, [])

  const onCheck = async () => {
    if (onboardingPhoneActive === true) {
      if (isValidImei && branchOfficeId) {
        props.goToStep(SCREENS.FilePDF)
      }
    } else if (onboardingPhoneActive === false) {
      if (isValidImei && branchOfficeId) {
        props.goToStep(SCREENS.VerifyInfo)
      }
    }
    if (!branchOfficeId) {
      openNotificationWithIcon('error', 'Elije el comercio para continuar')
    }
  }

  const onChangeBranchOfficeId = value => {
    if (value) {
      setBranchOfficeId(value)
      dispatch({type: 'SELECT_BRANCH_OFFICE', payload: value})
    }
  }

  const onChangeDeviceImei = value => {
    setIsValidImei(null)
    setRegistrationMessage('')
    if (/^\d+$/.test(value) || value === '') {
      setDeviceImei(value)
      if (value === '') {
        setIsValidImei(null)
      }
    }
  }

  const handleOk = () => {
    setModalVisible(false)
    // setDeviceImei('')
    setRegistrationMessage('')
  }
  const handleCancel = () => {
    setModalVisible(false)
  }
  const onCheckDeviceImei = async () => {
    setIsValidImei(null)
    /* setIsNotRegisteredImei(null) Not used*/
    setLoading(true)
    checkDeviceImei(deviceImei, onboardingPhoneActive ? Id : onboardingId)
      .then(response => {
        const {
          provider,
          nuovopay_device_id,
          data: {
            exist = false
          },
        } = response.data
        renderImeiMessage(response?.data)
        if (
          exist ===
          true /*&&
          (is_locked || is_released || is_financed) === false*/
        ) {
          setIsValidImei(true)
          dispatch({type: 'SET_DEVICE_ID', payload: nuovopay_device_id})
        } else {
          /* setIsNotRegisteredImei(true) Not used */
        }
        if (exist === false && provider === 'Nuovopay') {
          setModalVisible(true)
          setRegistrationMessage('')
        }
        setLoading(false)
      })
      .catch(error => {
        renderImeiMessage(error?.response?.data)
        logsOnboarding(
          onboardingId,
          'PHONE',
          'PROCESS_VALIDATE_IMEI',
          error.response,
          false,
        )
        setIsValidImei(false)
        setLoading(false)
      })
  }

  const renderImeiMessage = response => {
    const {error, data} = response
    if (error) {
      if (error === 'Owner is not authorised to use this TAC.') {
        setRegistrationMessage('IMEI no soportado')
      } else if (error.includes('Invalid IMEI')) {
        setRegistrationMessage('Formato de IMEI incorrecto')
      } else {
        setRegistrationMessage(error)
      }
    } /* else if (data?.is_locked) {
      setRegistrationMessage(`El imei se encuentra bloqueado`)
    } else if (data?.is_released) {
      setRegistrationMessage(`El imei se encuentra liberado`)
    } else if (data?.is_financed) {
      setRegistrationMessage(`El imei ya se encuentra con financiamiento`)
    }*/ else if (
      data?.exist
    ) {
      setRegistrationMessage(`El IMEI se encuentra registrado`)
    } else {
      setRegistrationMessage(`El IMEI no es valido`)
    }
  }

  return (
    <>
      <Container>
        <Wrapper style={{flex: 0.2, background: 'rgb(41, 164, 56)'}}>
          <AsideLogo
            positionX="left"
            positionY="top"
            color="white"
            hugColor="#FFF"
          />
        </Wrapper>
        <Wrapper style={{flex: 1, height: '100%'}}>
          <Wrapper style={{width: '75%', marginTop: 64, alignSelf: 'center'}}>
            <Text textType="title" style={{textAlign: 'left', lineHeight: 1}}>
              Esta pantalla es de uso exclusivo
            </Text>
            <Text textType="title" style={{textAlign: 'left', lineHeight: 1}}>
              del vendedor
            </Text>
            <Text
              textType="paragraphs"
              style={{
                fontFamily: 'Gilroy-Light',
                textAlign: 'left',
                fontSize: '1.35rem',
                marginBottom: 0,
                marginTop: 15,
                flexGrow: 1,
              }}>
              Puedes utilizar una de estas opciones para registrar el equipo
            </Text>
            <WrapperGroup style={{flexWrap: 'nowrap'}}>
              <Text
                textType="paragraphs"
                style={{
                  fontFamily: 'Nunito',
                  textAlign: 'left',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  marginTop: 0,
                  marginBottom: 0,
                  flexGrow: 1,
                }}>
                Escanea el código IMEI
              </Text>
              <ButtonNext
                background="green"
                border="green"
                data-testid="btn-valid-id"
                style={{
                  height: '100%',
                  minWidth: '160px',
                  minHeight: '60px',
                  maxWidth: '160px',
                  fontSize: '1rem',
                  alignItems: 'center',
                }}
                onClick={() => imeiScanner.current.startScan()}>
                Escanear aquí
              </ButtonNext>
            </WrapperGroup>
            <WrapperGroup>
              <Text
                textType="paragraphs"
                style={{
                  fontFamily: 'Nunito',
                  textAlign: 'left',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  width: '100%',
                  marginBottom: 0,
                }}>
                Digita el código IMEI
              </Text>
              <Wrapper
                style={{
                  flexDirection: 'row',
                  width: '100%',
                  marginTop: 8,
                  backgroundColor: '#F9F9F9',
                  alignItems: 'center',
                  justifyItems: 'center',
                  maxWidth: '998px',
                  gap: '6px',
                }}>
                <InputWithIcon
                  type="text"
                  value={deviceImei}
                  onChange={e => onChangeDeviceImei(e.target.value)}
                  maxLength={18}
                  suffix={
                    isValidImei === true && (
                      <CheckCircleFilled
                        style={{
                          color: '#3DAE2B',
                          fontSize: '30px',
                        }}
                      />
                    )
                  }
                />
                <ButtonNext
                  loading={loading}
                  background="green"
                  border="green"
                  data-testid="btn-valid-id"
                  style={{
                    height: '100%',
                    minWidth: '160px',
                    maxWidth: '160px',
                    fontSize: '1rem',
                    minHeight: '60px',
                    alignItems: 'center',
                  }}
                  disabled={isValidImei}
                  onClick={onCheckDeviceImei}>
                  Validar
                </ButtonNext>
              </Wrapper>
            </WrapperGroup>
            {registrationMessage && (
              <InputMessage
                style={{
                  width: '75%',
                  marginTop: 16,
                  borderRadius: 4,
                  ...{
                    background: !isValidImei ? 'rgb(255, 243, 243)' : undefined,
                  },
                }}
                message={registrationMessage}
              />
            )}
            <WrapperGroup style={{marginBottom: 20}}>
              <Text
                textType="paragraphs"
                style={{
                  fontFamily: 'Nunito',
                  textAlign: 'left',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  width: '100%',
                  marginBottom: 0,
                }}>
                Escoge el comercio
              </Text>
              <SelectMain
                style={{
                  flexGrow: 1,
                  width: '100%',
                  marginRight: '4px',
                  marginTop: '8px',
                }}
                autocomplete="off"
                placeholder="Comercio"
                loading={officesLoading}
                require={true}
                onChange={value => onChangeBranchOfficeId(value)}>
                {branches &&
                  branches.map(item => {
                    return (
                      <Option value={item.code} label={item.value}>
                        {item.value}
                      </Option>
                    )
                  })}
              </SelectMain>
            </WrapperGroup>
          </Wrapper>

          <FooterOnboar
            back={props.canReturn ? canReturnMobile(props, 10) : null}
            {...(isValidImei
              ? {
                  next: (
                    <ButtonNext
                      data-testid="btn-valid-next"
                      background="green"
                      border="green"
                      onClick={onCheck}>
                      Siguiente
                    </ButtonNext>
                  ),
                }
              : undefined)}
          />
        </Wrapper>
      </Container>
      <ImeiScan ref={imeiScanner} onSelected={onChangeDeviceImei} single />
      <Modal
        open={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        title="Escanea el código QR">
        <DivContent>
          <p>Enrolar con Nuovopay</p>
          <Img src={QR}></Img>
        </DivContent>
      </Modal>
    </>
  )
}

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const WrapperGroup = styled(Wrapper)(({style = {}}) => ({
  flexDirection: 'row',
  width: '100%',
  marginTop: 20,
  backgroundColor: '#F9F9F9',
  padding: '20px 30px 20px 40px',
  alignItems: 'center',
  justifyItems: 'center',
  maxWidth: '998px',
  flexWrap: 'wrap',
  gap: '6px',
  [theme.breakpoints.down('lg')]: {
    padding: '20px 30px 20px 40px',
  },
  ...style,
}))

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  minHeight: '100vh',
}))

const Img = styled('img')(() => ({
  width: '300px',
}))
const DivContent = styled.div(() => ({
  flexDirection: 'column',
  background: 'var(--fondo-blanco)',
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'center',
  justifyItems: 'center',
  alignContent: 'center',
  alignItems: 'center',
}))

import {Box} from '@mui/material'
import {styled} from '@mui/material/styles'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Modal from 'react-responsive-modal'
// import Modal from '@mui/material/Modal'
import TitleResponsiveOnboarding from 'src/components/TitleInput/TitleResponsiveOnboarding'
import {BreakPointTheme} from 'src/theme/breakpoints'
import {LoadingButton} from '@mui/lab'
import moment from 'moment'
import {openNotificationWithIcon} from 'src/utils/Functions'
import {setStep} from 'src/store/actions/Steps.Action'
import BackArrow from 'src/assets/images/onboardingCredito/backArrow.svg'
import checkMark from 'src/assets/images/onboardingCredito/checkMark.svg'
import {CreateLatinData} from 'src/store/actions/KO.Actions'
import {SEONEvaluation} from 'src/store/actions/requestCard.actions'
import SCREENS from '../screens'
import { ORIGIN } from 'src/utils/Constants'
const InfoData = styled('p')(() => ({
  font: 'normal normal normal 1vw/30px Red Hat Text',
  textAlign: 'left',
  marginBottom: 8,
  [BreakPointTheme.breakpoints.down('xl')]: {
    fontSize: '1.4vw',
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    fontSize: '1.9vw',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    fontSize: '2.5vw',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    font: 'normal normal normal 4vw/6.47vw  Red Hat Text',
  },
}))
const InfoWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: 15,
  paddingBottom: 15,
}))
const ContainerData = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '5px 60px',
  [BreakPointTheme.breakpoints.down('md')]: {
    padding: '5px 20px',
    width: '100%',
  },
}))
const ModalIdentityConfirm = ({open, onClose, props}) => {
  useEffect(() => {
    window.seon.getBase64Session(function(data) {
      if (data) {
        setSessionData(data)
      } else {
        console.log('Failed to retrieve session data.')
      }
    })
  }, [])
  const {
    onboardingId,
    origin,
    form,
    countriesBirthTranslate,
    nationalitiesTranslate,
    base64,
    checkSeon,
  } = useSelector(state => state.RequestCardReducer)

  const dispatch = useDispatch()

  const [code, setCode] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [showDiv, setShowDiv] = useState(true)
  const [loading, setLoading] = useState(false)
  const [loadingVerify, setLoadingVerify] = useState(false)
  const [sessionData, setSessionData] = useState('')
  var nacimientoSEON = ''
  const nacionalidad = nationalitiesTranslate?.filter(function(value) {
    return value?.value?.toUpperCase() === form?.nationality?.toUpperCase()
  })
  const paisNacimiento = countriesBirthTranslate?.filter(function(value) {
    return value?.value?.toUpperCase() === form?.countryOfBirth?.toUpperCase()
  })
  const nacimiento = paisNacimiento[0]?.label
  const nacionalidadData = nacionalidad[0]?.label
  nacimientoSEON = nacimiento
  const dateOfBirth = moment(form?.birthdate).format('DD-MMM-YYYY')
  let resNaci
  if (JSON.stringify(nacionalidad) == '[]') {
    resNaci = false
  }
  let res
  if (JSON.stringify(paisNacimiento) == '[]') {
    res = false
  }
  const hide = () => {
    setIsModalOpen(false)
    // props.goToStep(SCREENS.ScanID)
  }
  const handleIdentityVerify = () => {
    if (form?.isDocumentNotExpired === false) {
      openNotificationWithIcon(
        'warning',
        'No puedes continuar con el proceso porque tu documento de identidad está expirado.',
      )
    } else {
      setLoadingVerify(true)
      if (origin !== ORIGIN.PREPAGO) {
        dispatch(CreateLatinData(onComplete.bind(this)));
      } else {
        onComplete(true);
      }

      setLoading(true)
      if (checkSeon) {
        setLoading(false)
        dispatch(
          SEONEvaluation(onboardingId, origin, sessionData, nacimientoSEON),
        )
        dispatch(setStep(SCREENS.ApcTerms))
        props.goToStep(SCREENS.ApcTerms)
      } else {
        dispatch(setStep(SCREENS.ApcTerms))
        props.goToStep(SCREENS.ApcTerms)
      }
    }
  }
  const onComplete = success => {
    setLoadingVerify(false)
    if (success) {
      setIsModalOpen(false)
      setShowDiv(true)
    } else {
      dispatch(setStep(SCREENS.FinancialError))
      props.goToStep(SCREENS.FinancialError)
      setLoadingVerify(false)
    }
  }
  const onSuccessSEON = success => {
    setLoading(false)
    if (success === 1 || success === 2) {
      dispatch(setStep(SCREENS.ApcTerms))
      props.goToStep(SCREENS.ApcTerms)
    } else {
      dispatch(setStep(SCREENS.FinancialError))
      props.goToStep(SCREENS.FinancialError)
    }
  }


  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        classNames={{
          modal: 'identity__confirmed',
        }}
        center>
        <div
          onClick={() => {
            return
          }}
          className="cursor-pointer md:hidden absolute left-5 bg-[#F2F4F7] inline-block p-2 text-white rounded-lg">
          <img src={BackArrow} alt="BackArrow" />
        </div>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '0px', // This sets the margin-top to 0 pixels
            '@media (min-width: 768px)': {
              // This is an example breakpoint for 'md:' in Tailwind CSS
              marginTop: '0px',
            },
            [BreakPointTheme.breakpoints.down('sm')]: {
              height: '83%',
              justifyContent: 'space-evenly',
            },
          }}
          style={{margin: '1rem 0 .3rem 0'}}>
          <div className="w-[90%] md:w-[90%] flex items-center justify-center flex-col mt-[0.3em]!">
            <img
              alt="checkMark"
              src={checkMark}
              style={{
                height: '59px',
                width: '59px',
              }}
            />
            <TitleResponsiveOnboarding
              minSize={30}
              maxSize={35}
              text="Confirma que tus datos son los correctos"
              color="#011E41"
              fontFamily="Red Hat Text"
            />
            <InfoWrapper>
              <ContainerData>
                <InfoData>
                  <strong>Nombres:</strong> {form?.firstName ?? ''}{' '}
                  {form?.secondName ?? ''}
                </InfoData>
                <InfoData>
                  <strong>Apellidos:</strong> {form?.lastName ?? ''}{' '}
                  {form?.secondLastName ?? ''}
                </InfoData>
                <InfoData>
                  <strong> Número de cédula:</strong> {form?.identity ?? ''}
                </InfoData>
                <InfoData>
                  <strong> Fecha de nacimiento:</strong>{' '}
                  {dateOfBirth && dateOfBirth.toUpperCase()}
                </InfoData>
                {res === false ? (
                  <div></div>
                ) : (
                  <InfoData>
                    <strong> País de nacimiento: </strong> {nacimiento ?? ''}
                  </InfoData>
                )}
                {resNaci === false ? (
                  <div></div>
                ) : (
                  <InfoData>
                    <strong> Nacionalidad: </strong> {nacionalidadData ?? ''}
                  </InfoData>
                )}
              </ContainerData>
            </InfoWrapper>
            <Box
              sx={{
                marginTop: '32px', // Equivalent to mt-[32px]
                width: '100%', // Equivalent to w-full

                // Media queries for different breakpoints
                [BreakPointTheme.breakpoints.up('md')]: {
                  marginTop: '1rem',
                },
              }}>
              <div className="flex justify-center gap-[14px]">
                <LoadingButton
                  loading={loadingVerify}
                  sx={{
                    outline: 'none', // Equivalent to outline-none
                    color: 'white', // Equivalent to text-white
                    borderRadius: '9999px', // Equivalent to rounded-full
                    display: 'flex', // Equivalent to flex
                    alignItems: 'center', // Equivalent to items-center
                    justifyContent: 'center', // Equivalent to justify-center
                    height: '60px', // Default height, equivalent to h-[60px]
                    fontSize: '20px', // Default font size, equivalent to text-[20px]
                    width: '100%', // Default width, equivalent to w-full
                    font: 'normal normal normal 25px/22px Red Hat Text',
                    // Media queries for different breakpoints
                    [BreakPointTheme.breakpoints.up('md')]: {
                      fontSize: '25px', // md:text-[25px]
                      height: '76px', // md:h-[76px]
                      width: '50%', // md:w-[50%]
                    },
                    [BreakPointTheme.breakpoints.up('lg')]: {
                      width: '30%', // lg:w-[30%]
                    },
                  }}
                  data-testid="yes-confirmed-identity"
                  style={{background: 'var(--linear-bg)'}}
                  onClick={handleIdentityVerify}>
                  Si
                </LoadingButton>
                <LoadingButton
                  sx={{
                    outline: 'none', // Equivalent to outline-none
                    backgroundColor: 'white', // Equivalent to bg-white
                    border: '2px solid',
                    borderColor: 'var(--light-green)', // Make sure to define --light-green in your CSS
                    color: '#026E18', // Equivalent to the given hex color
                    borderRadius: '9999px', // Equivalent to rounded-full
                    display: 'flex', // Equivalent to flex
                    alignItems: 'center', // Equivalent to items-center
                    justifyContent: 'center', // Equivalent to justify-center
                    height: '60px', // Default height, equivalent to h-[60px]
                    fontSize: '20px', // Default font size, equivalent to text-[20px]
                    width: '100%', // Default width, equivalent to w-full
                    font: 'normal normal normal 25px/22px Red Hat Text',
                    // Media queries for different breakpoints
                    [BreakPointTheme.breakpoints.up('md')]: {
                      fontSize: '25px', // md:text-[25px]
                      height: '76px', // md:h-[76px]
                      width: '50%', // md:w-[50%]
                    },
                    [BreakPointTheme.breakpoints.up('lg')]: {
                      width: '30%', // lg:w-[30%]
                    },
                  }}
                  onClick={onClose}>
                  No
                </LoadingButton>
              </div>
            </Box>
          </div>
        </Box>
        <p
          style={{
            marginTop: ' 20px',
            marginBottom: '20px',
            color: 'rgba(2, 110, 24, 1)',
            textAlign: ' center',
            letterSpacing: ' 0px',
            opacity: ' 1',
          }}>
          <strong>¿Necesitas asistencia en tu proceso? </strong>
          <br></br> contáctanos a servicioalcliente@cmf.com.pa
        </p>
      </Modal>
    </>
  )
}

export default ModalIdentityConfirm

import { styled } from '@mui/material/styles'
import React from 'react'
import { messageCMF } from '../../theme/constants'
import * as footer from './footer.component.style'
export default function FooterComponent() {
  const Container = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    bottom: 0,
    textAlign: 'center',
    background: 'var(--fondo-blanco)',
    padding: 0,
    marginTop: '1.5rem',
    // [theme.breakpoints.up('sm')]: {
    //   // position: 'fixed',
    // },
    // [theme.breakpoints.up('md')]: {
    //   position: 'relative',
    //   left: '10px',
    //   marginTop:'1.5rem'
    // },
  }))
  return (
    <Container>
      <footer.footerContaint>
        <footer.footerTC>
          <div style={{display: 'flex'}}>
            <div
              style={{
                height: '2.5rem',
                gap: '8px',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
              }}>
              <footer.a
                href="https://www.cmfinanciera.com/s/terminos-y-condiciones"
                target="_blank">
                Términos y condiciones
              </footer.a>
            </div>
            <div
              style={{
                height: '2.5rem',
                gap: '8px',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
              }}>
              <footer.a
                href="https://www.cmfinanciera.com/s/privacidad"
                target="_blank">
                Políticas de privacidad
              </footer.a>
            </div>
          </div>
        </footer.footerTC>
      </footer.footerContaint>
      <footer.footerContainer>
        <footer.footerCopy>
          <footer.colfooterI>
            <footer.pCopy>{messageCMF.copyright}</footer.pCopy>
          </footer.colfooterI>
          {/* <footer.colfooterImage>
            <div>
              <img src={logoMini} className="ImagenMini" alt="logo-mini" />
            </div>

            <div>
              <img src={LogosBanco} className="logoBanco" alt="logo-banco" />
            </div>
          </footer.colfooterImage> */}
        </footer.footerCopy>
      </footer.footerContainer>
    </Container>
  )
}

import { Box, Drawer } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// hooks
import useResponsive from '../../../../hooks/useResponsive'
// components
import NavSection from '../../../../components/nav-section'
import Scrollbar from '../../../../components/scrollbar'
//
import { BreakPointTheme } from 'src/theme/breakpoints'
import LogoCMFWhite from './../../../../assets/icons/cmf_linea/cmfWhite.svg'
import navConfig from './config'
// ----------------------------------------------------------------------
const NAV_WIDTH = 280

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
}

export default function Nav({openNav, onCloseNav}) {
  const {pathname} = useLocation()

  const isDesktop = useResponsive('up', 'md')

  useEffect(() => {
    if (openNav) {
      onCloseNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <Scrollbar
      sx={{
        position: 'fixed',
        backgroundColor: '#2C3645',
        borderRadius: '40px',
        flexGrow: 1,
        overflow: 'hidden',
        width: '280px',
        height: 'auto',
        top: '16px',
        left: '16px',
        bottom: '16px',
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
        [BreakPointTheme.breakpoints.down(1025)]: {
          width: '25%',
        },
      }}>
      <Box
        sx={{
          display: 'inline-flex',
          padding: '40px 0 0 40px',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}>
        <Box sx={{px: 2.5, py: 1, display: 'inline-flex'}}>
          <img
            alt='Logo CMF White'
            src={LogoCMFWhite}
            style={{
              width: '85.99px',
              // height: '32px',
            }}
          />
        </Box>
        <NavSection data={navConfig} />
      </Box>
    </Scrollbar>
  )

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: {md: 0},
        width: {md: NAV_WIDTH},
        [BreakPointTheme.breakpoints.between('md', 'lg')]: {
          width: '25%',
        },
      }}>
      {isDesktop && (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              backgroundColor: '#F9FAFB',
              padding: '16px',
              borderRightStyle: 'none',
              position: 'absolute',
              [BreakPointTheme.breakpoints.between('md', 'lg')]: {
                width: '25%',
              },
              // background:'primary.background'
            },
          }}>
          {renderContent}
        </Drawer>
      )}
    </Box>
  )
}

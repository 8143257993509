import {Layout} from 'antd'
import React from 'react'
import logoCMF from '../../assets/images/onboarding/logo-cmf.webp'
import './bannerStyle.scss'
const {Sider} = Layout
/**
 * Sider de la página web
 * @param {*} props
 * @returns
 */
export default function Banner(props) {
  const {name} = props
  switch (name) {
    case 'verificacion':
      return <Sider className="verificacion"> </Sider>
    case 'instrucciones':
      return <Sider className="instrucciones"> </Sider>
    case 'felicidades':
      return <Sider className="felicidades"> </Sider>
    case 'paso1':
      return <Sider className="paso1"> </Sider>
    case 'paso2':
      return <Sider className="paso2"> </Sider>
    case 'paso3':
      return <Sider className="paso3"> </Sider>
    case 'paso4':
      return <Sider className="paso4"> </Sider>
    case 'apc':
      return <Sider className="apc"> </Sider>
    case 'chubb':
      return <Sider className="chubb"> </Sider>
    case 'pdf':
      return <Sider className="pdf"> </Sider>
    default:
      return (
        <Sider className="siderStyle">
          <center>
            <img className="logoCMF" src={logoCMF} alt="Logo CMF" />
          </center>
        </Sider>
      )
  }
}

import React from 'react'
import {Row, Col} from 'antd'
import '../containers/CardRequest/styles/responsive.css'
export const MsgPEP = (
  <div className="poperClass">
    <p
      style={{
        textAlign: 'left',
        font: 'normal normal normal 11px/14px Arial',
        letterSpacing: '0px',
        color: ' #545454',
      }}>
      <strong> PEP:</strong> Personas nacionales o extranjeras que cumplen
      funciones públicas destacadas de alto nivel o con mando o jurisdicción en
      un Estado, como (pero sin límites) los jefes de Estado o de un gobierno,
      los políticos de alto perfil, los funcionarios gubernamentales, judiciales
      o militares de alta jerarquía, los altos ejecutivos de empresas o
      corporaciones estatales, los funcionarios públicos que ocupen cargos de
      elección popular, entre otros que ejerzan la toma de decisiones en las
      entidades públicas; personas que cumplen o a quienes se les han confiado
      funciones importantes por una organización internacional, como los
      miembros de la alta gerencia, es decir, directores, subdirectores y
      miembros de la junta directiva o funciones equivalentes.
    </p>
  </div>
)
export const MsgColab = (
  <div className="poperClassColab">
    <p
      style={{
        textAlign: 'left',
        font: 'normal normal normal 11px/14px Arial',
        letterSpacing: '0px',
        color: ' #545454',
      }}>
      <strong>Familiares Cercanos:</strong> Únicamente, el cónyuge, los padres,
      los hermanos y los hijos de la persona expuesta políticamente.
      <br></br>
      <br></br>
      <strong> Estrecho Colaborador:</strong> Persona conocida por su íntima
      relación con respecto a la persona expuesta políticamente, esto incluye a
      quienes están en posición de realizar transacciones financieras,
      comerciales o de cualquier naturaleza, ya sean locales e internacionales,
      en nombre de la persona expuesta políticamente.
    </p>
  </div>
)
export const PagoMinimo = (
  <div style={{width: '250px'}}>
    <p style={{textAlign: 'left'}}>
      Si decides no pagar el total que debes, el pago mínimo es el valor más
      bajo que debes pagar para mantener tu tarjeta de crédito al día y no
      manchar tu historial de crédito.
    </p>
  </div>
)

export const FechaPago = (
  <div style={{width: '250px'}}>
    <p style={{textAlign: 'left'}}>
      Es el último día que te da la financiera para que pagues todo lo que
      compraste compraste en un mes, antes de empezar a cobrarte intereses.
    </p>
  </div>
)

export const DíaCorte = (
  <div style={{width: '250px'}}>
    <p style={{textAlign: 'left'}}>
      Es el día en el que la financiera pone fin al registro de todas las
      compras que realizaste durante un mes.
    </p>
  </div>
)
export const LimiteTarjeta = (
  <div style={{width: '250px'}}>
    <p style={{textAlign: 'left'}}>
      Es la suma máxima de dinero que te presta la financiera.
    </p>
  </div>
)

export const NumeroCuenta = (
  <div style={{width: '250px'}}>
    <p style={{textAlign: 'left'}}>
      Es el número que identifica a tu tarjeta de crédito
    </p>
  </div>
)

export const SaldoAnterior = (
  <div>
    <p>Es la suma de dinero que tenías el mes anterior a la fecha de corte.</p>
  </div>
)

export const SaldoCorte = (
  <div>
    <p>
      Cantidad de dinero que debes a la fecha de corte, inIncluye todos tus
      gastos en un mes.
    </p>
  </div>
)
export const CréditoDisponible = (
  <div>
    <p>
      Cantidad de dinero con la que dispones para tus gastos con la tarjeta de
      crédito.
    </p>
  </div>
)

export const PagoTotal = (
  <div>
    <p>
      Suma de dinero que comprende todo lo que gastaste a la fecha de corte.
    </p>
  </div>
)

export const TasaInteres = (
  <div>
    <p>Porcentaje que le pagas a la financiera por prestarte dinero</p>
  </div>
)
export const Comisiones = (
  <div>
    <p>
      Valor que le pagas a la financiera por concepto de servicios, recibidos
      con el uso de la tarjeta de crédito.
    </p>
  </div>
)
export const DetalleTransacciones = (
  <div>
    <p>Todas las operaciones bancarias que realizaste en el mes.</p>
  </div>
)
export const SaldoVencido = (
  <div>
    <p>
      Cantidad de dinero que dejaste de pagar el mes anterior en la fecha límite
      de pago.
    </p>
  </div>
)
export const TiposFraude = (
  <ul style={{paddingLeft: '5%'}}>
    <li>Ingeniería Social</li>
    <br></br>
    Concepto: <p></p>Es una práctica delictiva cibernética en la que se busca
    obtener información confidencial manipulando o engañando a las personas a
    través de diferentes métodos. <p></p> ¿Cómo ocurre?: <p></p>La ingeniería
    social se puede dar de diferentes maneras:
    <ul style={{paddingLeft: '5%'}}>
      <li>
        El delincuente puede llamarlo y hacerse pasar por un ejecutivo de la
        financiera.
      </li>
      <li>
        Puede recibir correos engañosos pidiendo contraseñas (phishing) o
        información confidencial. También pueden ser correos pidiendo su tarjeta
        para una donación a una persona necesitada.
      </li>
      <li>Incluso se puede dar cara a cara través del engaño.</li>
    </ul>
    <p></p>
    Prevención: <p></p>
    Nunca comparta información confidencial y sensitiva por correo electrónico o
    por teléfono, especialmente si usted no inició el intercambio. Las entidades
    financieras no piden información confidencial por internet. No seleccione
    vínculos, ni descargue programas archivos adjuntos a correos electrónicos o
    al navegar el Internet sin verificar que sean legítimos y confiables.<p></p>
    <li>Pharming </li>
    <p></p>
    Concepto:<p></p>Es una práctica delictiva cibernética que usualmente ocurre
    cuando una computadora de un usuario es infectada por un programa
    malintencionado llamado “troyano o virus” que lo desvía de un sitio web de
    un banco a uno fraudulento para robar sus datos sensitivos y cometer un
    fraude.
    <p></p>
    ¿Cómo ocurre?:<p></p> Al descargar imágenes y programas asociados a un
    correo electrónico fraudulento, navegar por páginas indebidas, abrir
    archivos adjuntos o vínculos en correos electrónicos de dudosa procedencia.
    <p></p> Al descargar a su computadora fotos, videos, programas gratuitos,
    juegos o canciones de fuentes no conocidas en Internet.<p></p> Prevención:
    <p></p>
    No seleccione vínculos, ni descargue programas archivos adjuntos a correos
    electrónicos o al navegar el Internet sin verificar que sean legítimos y
    confiables.<p></p> Mantenga siempre instalado paredes cortafuegos
    (Firewalls) y programas antivirus que le permita protegerse de cualquier
    violación cibernética.<p></p> <li>Phishing</li>
    <p></p>
    Concepto:<p></p> El phishing es un tipo de engaño que usualmente inicia con
    la recepción de un correo electrónico falso que redirige al cliente a una
    copia o imitación de una página Web legítima, para así obtener información
    personal y confidencial que es usada para cometer fraude. <p></p>¿Cómo
    ocurre?:<p></p>Inicia con un correo electrónico en donde se indica por
    ejemplo que su cuenta o servicio financiero en línea está por ser bloqueado
    o deshabilitado o que tiene algún problema, por lo que se le solicita que
    reingrese sus datos urgentemente para actualizarlos, y así evitar un posible
    fraude u otra excusa similar.También el correo electrónico puede indicar que
    se ha ganado algún premio y que debe llenar información para reclamarlo.
    <p></p> La mayoría de estos mensajes incluyen un enlace a una página web
    aparentemente legítima y muy similar a la de un banco o institución
    financiera, pero por detrás el enlace lo lleva a otra página web, en la que
    se solicita que complete un formulario facilitando detalles de su cuenta,
    números de tarjetas o de identificación personal.<p></p> También puede
    ocurrir por medio de una ventana emergente con un mensaje (pop up) al
    navegar en Internet que de igual forma le lleva a una página que no es del
    banco.
    <p></p>
    Prevención:<p></p>Evite ser víctima de fraude y siga los siguientes
    consejos:
    <p></p>Omita mensajes fraudulentos con estos encabezados: “Su cuenta se debe
    confirmar”, “Usuarios de la financiera advierten”, “Actualización de la
    seguridad de la Financiera XX.” o “Usted se ha ganado un Premio de la
    Financiera XX”.<p></p> Recuerde siempre acceder a nuestra página por medio
    de la dirección{' '}
    <a href="https://www.cmfinanciera.com" target="_blank" rel="noreferrer">
      www.cmfinanciera.com
    </a>{' '}
    y nunca a través de enlaces que soliciten algún tipo de información
    financiera o personal. Además, recuerde verificar la fecha de expiración del
    certificado de seguridad, el cual puede encontrar al realizar doble clic en
    el candado de seguridad que se encuentra en la barra superior de navegación
    o en la parte inferior derecha del explorador, dependiendo de la versión del
    explorador que utilice su computadora. <p></p>Evite responder mensajes de
    correo electrónico donde le soliciten sus datos personales, tales como
    números de cuenta de cualquier tipo, usuario, contraseña, etc.<p></p> Nunca
    proporcione información cuando las solicitudes le exigen urgencia o
    establecen consecuencias por no actuar de inmediato
  </ul>
)
export const PagarCuotas = (
  <div>
    <p>
      Puedes realizar tus pagos en las Tiendas Cochez y Novey, por Yappy, ACH o
      Banca en Línea.
    </p>

    <Row style={{width: '80%', margin: '15px auto'}} type="flex">
      <Col>
        <img
          src="https://www.bgeneral.com/wp-content/uploads/2019/05/yappy-BIG-Logo.png"
          width="50"
          alt='Yappy big logo'
        />
      </Col>

      <Col>
        Yappy Banco General:<br></br> @cmfinanciera
      </Col>
    </Row>

    <Row style={{width: '80%', margin: '15px auto'}} type="flex">
      <Col>
        <img
          src="https://www.bgeneral.com/wp-content/uploads/2020/06/estrella-sin-rayas-noticias.png"
          width="50"
          alt='Estrella sin rayas noticias'
        />
      </Col>

      <Col>
        ACH Banco General:<br></br> Nombre de la cuenta: CM Financiera, S.A.{' '}
        <br></br>Número de cuenta: 03-72-01-124106-0 <br></br>Tipo de cuenta:
        Cuenta corriente
      </Col>
    </Row>

    <Row style={{width: '80%', margin: '15px auto'}} type="flex">
      <Col>
        <img
          src="https://www.bgeneral.com/wp-content/uploads/2020/06/estrella-sin-rayas-noticias.png"
          width="50"
          alt='Estrella sin rayas noticias'
        />
      </Col>

      <Col>
        Banca en línea Banco General: <br></br>Pago a tercero, CM Financiera
      </Col>
    </Row>
  </div>
)

import produce from 'immer';
import {CHANGE_CURRENT_STEP} from '../../constrants/reducers';

const initState = {
  currentStep: 0,
};

const StepReducer = (state = initState, action) =>
  produce(state, draft => {
    switch (action.type) {
        case CHANGE_CURRENT_STEP:
            draft.currentStep = action.step;
        break;
    default:
    return state;
    }
});

export default StepReducer;

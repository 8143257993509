export const Cmf_Base = process.env.REACT_APP_CMF_BASE;
export const CMF_MANAGEMENT = process.env.REACT_APP_CMF_MANAGEMENT;
export const Cmf_Base02 = process.env.REACT_APP_CMF_BASE02;
export const USER_MANAGMENT_API = process.env.REACT_APP_USER_MANAGMENT_API;
export const USER_MANAGMENT_TOKEN = process.env.REACT_APP_USER_MANAGMENT_TOKEN;
export const ActiveProducts = process.env.REACT_APP_ACTIVE_PRODUCTS;
export const ProductCatalogue = process.env.REACT_APP_PRODUCTS_CATALOGUE;
export const AccountApi = process.env.REACT_APP_ACCOUNT_API;
export const PointsApi = process.env.REACT_APP_POINTS_API;
export const ExtraFinanApi = process.env.REACT_APP_EXTRA_FINAN_API;
export const movementApi = process.env.REACT_APP_MOVEMENT_API;
export const EID_Base = process.env.REACT_APP_ELECTRONIC_ID_BASE;
export const TOKEN = process.env.REACT_APP_TOKEN;
export const ENCRYPT_KEY = process.env.REACT_APP_ENCRYPT_KEY;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const SUBS_KEY = process.env.REACT_APP_SUBS_KEY;
export const CMF_KEY = process.env.REACT_APP_API_KEY_CMF;
export const MDL30 = process.env.REACT_APP_MDL30;
export const WALLET_API = process.env.REACT_APP_API_WALLET;
export const CENTINEL = process.env.REACT_APP_CENTINEL;
export const VOLCAN = process.env.REACT_APP_API_VOLCAN;
export const VOLCAN_KEY = process.env.REACT_APP_KEY_VOLCAN;

import Box from '@mui/material/Box'
import React from 'react'
import { BreakPointTheme } from 'src/theme/breakpoints'

const CustomStyledBox = props => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '384px',
        height: '100%',
        flexDirection: 'column',
        justifyContent:'space-around',
        alignItems: 'flex-start',
        flexShrink: 0,
        borderRadius: '32px 0px 0px 32px',
        background: 'var(--white, #FFF)',
        [BreakPointTheme.breakpoints.down('sm')]: {
          width: '100vw',
          borderRadius: 0,
          height: 'auto',
          minHeight: '-webkit-fill-available',
         
        },
      }}
      {...props}>
      {props.children}
    </Box>
  )
}

export default CustomStyledBox

import PropTypes from 'prop-types';
import React from 'react';
import grupo3 from '../../../assets/images/onboardingCredito/Enmascarar grupo 3.png';
import MobileImg from '../../../assets/images/onboardingCredito/Grupo 8143@2x.png';
import Button from 'src/components/Button/ButtonCommon';

function SupportScreen({
  heading,
  title,
  subtitle1,
  buttonText,
  subtitleMobile,
  showDiv,
  buttonClasses = '',
  onConfirm = () => {},
}) {
  const show = showDiv || 'lg:flex hidden';
  return (
    <>
      <div className="grid grid-cols-12 min-h-[100vh]">
        <div className="lg:col-span-10 col-span-12  flex flex-col items-center lg:justify-center justify-start  lg:mt-0 mt-24  lg:px-4 md:px-44 sm:20 px-8 ">
          <div className="lg:text-4xl text-[#011E41] text-3xl font-bold   text-center tracking-wider mb-1 text-cente">
            {heading}
          </div>
          <h1
            className="lg:flex flex-col item-center justify-center  lg:text-3xl text-[22px] font-semibold text-[#011e41] text-center lg:mt-3 mt-5 tracking-wide "
            style={{lineHeight: '3rem'}}>
            {title}
          </h1>
          <p
            className={`${show} item-center justify-center  lg:text-[23px]  font-light text-[#030303] text-center lg:py-10 pt-6 pb-5`}>
            {subtitle1}
          </p>
          {subtitleMobile && (
            <p className="max-lg:flex hidden item-center justify-center  lg:text-[23px]  font-light text-[#030303] text-center lg:py-10 pt-6 pb-5">
              {subtitleMobile}
            </p>
          )}
          <div className="flex item-center w-full justify-center lg:w-48 md:w-96 w-full ">
            <Button
              className={buttonClasses}
              onClick={onConfirm}
              label={buttonText}
              textColor="text-white"
              bg="greenBtnBg"
            />
          </div>
        </div>
        <div className="col-span-12 sm:hidden flex  items-end">
          <div>
            <img src={MobileImg} alt="" className="w-full" />
          </div>
        </div>
      </div>
      <div className="absolute  ml-auto sm:flex hidden text-white justify-end  ml-10 bottom-0 right-4 z-[99]  w-64 lg:h-[32rem] h-64 col-span-2">
        <img src={grupo3} alt="" className="object-cover	" />
      </div>
      <div className="absolute sm:flex hidden bg-secondary min-w-full lg:py-[17px] py-[10px]  bottom-0 "></div>
    </>
  );
}

SupportScreen.propTypes = {
  buttonText: PropTypes.any,
  heading: PropTypes.any,
  onConfirm: PropTypes.func,
  showDiv: PropTypes.any,
  subtitle1: PropTypes.any,
  subtitleMobile: PropTypes.any,
  title: PropTypes.any,
};

export default SupportScreen;

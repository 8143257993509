import produce from 'immer';
const initState: initState = {
  data: [],
  errorMessage: '',
};

const tenantReducer = (state = initState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'GET_TENANTS_SUCCESS':
        draft.data = action.payload;
        break;
      case 'GET_TENANTS_ERROR':
        draft.errorMessage = action.payload;
        break;
      default:
        return state;
    }
  });
export default tenantReducer;

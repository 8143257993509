import produce from 'immer';

const initialState = {
  onboarding: {},
  onboardings: [],
  onboardingError: false,
};

const cuentaReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'FETCH_CUENTA_SUCCESS':
        draft.onboardings = action.payload;
        break;
      case 'FETCH_CUENTA_ERROR':
        draft.onboardingError = action.payload;
        break;
      default:
        return state;
    }
  });

export default cuentaReducer;

const isRequired = true

export const fieldType = {
  TEXT: 'TEXT',
  BOOLEAN: 'BOOLEAN',
  YES_NO: 'YES_NO',
  GENDER: 'GENDER',
  DROPDOWN: 'DROPDOWN',
  MULTIPLE: 'MULTIPLE',
  DATE: 'DATE',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
}

export const fieldTypeOptions = {
  YES: 1,
  NO: 0,
  MASCULIN: 1,
  FEMENIN: 2,
}

const cellPhoneRule = fieldName => [
  {
    required: true,
    message: `${fieldName} es requerida`,
  },
  {
    pattern: /^[0-9]{8}$/,
    message: 'Formato de Teléfono invalido',
  },
]

const residencialPhoneRule = fieldName => [
  {
    required: false,
    message: `${fieldName} es requerida`,
  },
  {
    pattern: /^[0-9]{7}$/,
    message: 'Formato de Teléfono invalido',
  },
]

const localPhoneRule = fieldName => [
  {
    required: true,
    message: `${fieldName} es requerida`,
  },
  {
    pattern: /^[0-9]{7}$/,
    message: 'Formato de Teléfono invalido',
  },
]

const regularTextRule = (fieldName, isRequired, length = 50) => [
  {
    required: isRequired,
    message: `${fieldName} es requerido`,
  },
  {
    max: length,
    message: `No debe exceder de ${length} caracteres`,
  },
]

const requiredRule = fieldName => [
  {
    required: true,
    message: `${fieldName} es requerido`,
  },
]

const validationYesNo = [
  {
    validator: (rule, value, callback) => {
      if (!(value === 1 || value === 0)) {
        callback('Debes seleccionar una opción')
      } else {
        callback()
      }
    },
  },
]

const leastOne = [
  {
    validator: (rule, value, callback) => {
      if (value === undefined || value.length < 1) {
        callback('Debe indicar al menos un País')
      } else {
        callback()
      }
    },
  },
]

export const formFields = {
  firstName: {
    required: true,
    readonly: true,
    label: 'Primer nombre',
    placeholder: 'Primer Nombre ',
    rules: regularTextRule('Nombre', isRequired, 25),
    type: 'text',
  },
  secondName: {
    required: false,
    readonly: true,
    label: 'Segundo nombre',
    placeholder: 'Segundo Nombre',
    rules: regularTextRule('Segundo Nombre', !isRequired, 25),
    type: 'text',
  },
  lastName: {
    required: true,
    readonly: true,
    label: 'Apellido',
    placeholder: 'Primer apellido ',
    rules: regularTextRule('Apellido', isRequired, 25),
    type: 'text',
  },
  secondLastName: {
    required: false,
    readonly: true,
    label: 'Segundo Apellido',
    placeholder: 'Segundo apellido',
    rules: regularTextRule('Segundo Apellido', false, 25),
    type: 'text',
  },
  identity: {
    required: true,
    readonly: false,
    label: 'Cédula',
    placeholder: 'E-000-00000',
    rules: [
      {
        required: true,
        message: 'Cédula es requerida',
      },
      {
        pattern: /^(PE|E|N|[23456789](?:AV|PI)?|1[0123]?(?:AV|PI)?)-(\d{1,4})-(\d{1,6})$/i,
        message: 'Formato de cédula panameña no válido',
      },
    ],
    type: 'text',
  },
  identityVendedor: {
    required: true,
    readonly: false,
    label: 'Cédula',
    placeholder: 'E-000-00000',
    rules: [
      {
        required: false,
        message: 'Cédula es requerida',
      },
      {
        pattern: /^(PE|E|N|[23456789](?:AV|PI)?|1[0123]?(?:AV|PI)?)-(\d{1,4})-(\d{1,6})$/i,
        message: 'Formato de cédula panameña no válido',
      },
    ],
    type: 'text',
  },
  cellphone: {
    required: true,
    label: 'Teléfono Celular',
    placeholder: 'Ingresa tu número de celular ',
    rules: cellPhoneRule('Teléfono Celular'),
    type: 'text',
  },
  phone: {
    required: false,
    label: 'Teléfono residencial',
    placeholder: 'Teléfono residencial',
    rules: residencialPhoneRule('Teléfono Residencial'),
  },
  email: {
    required: true,
    label: 'Correo Electrónico',
    placeholder: 'Correo electrónico ',
    rules: [
      {
        required: true,
        message: 'Correo Electrónico es requerida',
      },
      // {
      //   type: 'email',
      //   message: 'Correo electrónico invalido',
      // },
      {
        pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
        message: 'Correo electrónico invalido',
      },
    ],
    type: 'text',
  },
  birthdate: {
    required: true,
    readonly: true,
    label: 'Fecha de nacimiento',
    placeholder: 'Fecha de nacimiento ',
    type: fieldType.DATE,
  },
  countryOfBirth: {
    required: true,
    label: 'País de nacimiento',
    placeholder: 'País de nacimiento ',
    rules: requiredRule('País de nacimiento'),
    type: fieldType.DROPDOWN,
  },
  gender: {
    required: true,
    label: 'Género',
    placeholder: 'Género ',
    rules: requiredRule('Genero'),
    type: fieldType.GENDER,
  },
  nationality: {
    required: true,
    label: 'Nacionalidad',
    placeholder: 'Nacionalidad ',
    rules: requiredRule('Nacionalidad'),
    type: fieldType.DROPDOWN,
  },
  occupation: {
    required: true,
    label: 'Ocupación',
    placeholder: 'Ocupación ',
    rules: requiredRule('Ocupación'),
    type: fieldType.DROPDOWN,
  },
  profession: {
    required: true,
    label: 'Profesión',
    placeholder: 'Profesión ',
    rules: requiredRule('Profesión'),
    type: fieldType.DROPDOWN,
  },
  activityType: {
    required: true,
    label: 'Tipo de Actividad',
    placeholder: 'Tipo de actividad ',
    rules: requiredRule('Tipo de Actividad'),
    type: fieldType.DROPDOWN,
  },
  company: {
    required: true,
    label: 'Empresa donde laboras',
    placeholder: 'Empresa donde laboras ',
    rules: requiredRule('Empresa donde laboras'),
  },
  companyPhone: {
    required: true,
    label: 'Teléfono de la empresa',
    placeholder: 'Teléfono de la empresa ',
    rules: localPhoneRule('Teléfono de la empresa'),
  },
  addressCompany: {
    required: true,
    label: 'Dirección de la empresa',
    placeholder: 'Dirección de la empresa ',
    rules: regularTextRule('Dirección de la empresa', isRequired, 100),
  },
  companyDateAdmissionYear: {
    required: true,
    label: 'Año',
    placeholder: 'Año ',
    rules: regularTextRule('Año', isRequired, 100),
    type: fieldType.YEAR,
  },
  companyDateAdmissionMonth: {
    required: true,
    label: 'Mes',
    placeholder: 'Mes ',
    rules: regularTextRule('Mes', isRequired, 100),
    type: fieldType.MONTH,
  },
  salary: {
    required: true,
    label: 'Ingreso mensual ',
    placeholder: 'Ingreso mensual',
    rules: [
      {
        required: true,
        message: 'El salario es requerido',
      },
      {
        pattern: /^\$?[\d]+(\.\d*)?$/,
        message: 'Debe ser un valor númerico',
      },
    ],
  },
  civilState: {
    required: true,
    label: 'Estado civil',
    placeholder: 'Estado civil ',
    rules: requiredRule('Estado civil'),
    type: fieldType.DROPDOWN,
  },
  country: {
    required: true,
    label: 'País de residencia',
    placeholder: 'País de residencia ',
    rules: requiredRule('País de residencia'),
    type: fieldType.DROPDOWN,
  },
  province: {
    required: true,
    label: 'Provincia',
    placeholder: 'Provincia ',
    rules: requiredRule('Provincia'),
    type: fieldType.DROPDOWN,
  },
  district: {
    required: true,
    label: 'Distrito',
    placeholder: 'Distrito ',
    rules: requiredRule('Distrito'),
    type: fieldType.DROPDOWN,
  },
  township: {
    required: true,
    label: 'Corregimiento',
    placeholder: 'Corregimiento ',
    rules: requiredRule('Corregimiento'),
    type: fieldType.DROPDOWN,
  },
  address: {
    required: true,
    label: 'Dirección de residencia ',
    placeholder: 'Dirección de residencia ',
    rules: regularTextRule('Dirección de residencia', isRequired, 100),
  },
  build: {
    required: true,
    label: 'Nombre de edificio, piso, apartamento o número de casa ',
    placeholder: 'Nombre de edificio, piso, apartamento o número de casa ',
    rules: regularTextRule('Nombre de edificio, piso..', isRequired, 100),
  },
  isPolitician: {
    required: true,
    label: '¿Eres una persona expuesta políticamente? ',
    placeholder: '¿Eres una persona expuesta políticamente? ',
    type: fieldType.YES_NO,
    rules: validationYesNo,
  },
  politicalJob: {
    required: true,
    label: '¿Qué cargo desempeñas?',
    placeholder: '¿Qué cargo desempeñas? ',
    rules: regularTextRule('Cargo', isRequired, 50),
  },
  relationPep: {
    required: true,
    label: '¿Estás relacionado con una persona expuesta políticamente?',
    placeholder: '¿Estás relacionado con una persona expuesta políticamente?',
    type: fieldType.YES_NO,
    rules: validationYesNo,
  },
  nameRelationPep: {
    required: true,
    label: '¿Cuál es el nombre de la persona expuesta políticamente? ',
    placeholder: '¿Cuál es el nombre de la persona expuesta políticamente? ',
    rules: regularTextRule('Nombre', isRequired, 50),
  },
  jobRelationPep: {
    required: true,
    label: '¿Qué cargo desempeña?',
    placeholder: '¿Qué cargo desempeña?',
    rules: regularTextRule('Cargo', isRequired, 50),
  },
  externalPanamaResident: {
    required: true,
    label:
      '¿Eres considerado residente fiscal en cualquier otro país diferente a Panamá? ',
    placeholder:
      '¿Eres considerado residente fiscal en cualquier otro país diferente a Panamá? ',
    type: fieldType.YES_NO,
    rules: validationYesNo,
  },
  externalResidents: {
    required: true,
    label: '¿Cuáles son tus países de residencia fiscal?',
    placeholder: '¿Cuáles son tus países de residencia fiscal?',
    rules: leastOne,
    type: fieldType.MULTIPLE,
  },
  taxID: {
    label:
      '¿Cuál es tu número de ID Tributario, TIN o SSN de los otros países diferentes a Panamá, donde eres considerado residente fiscal?',
    // 'Indique su(s) ID(s) tributario(s) / TIN / SSN en el(los) pais(es) de residencia.',
    placeholder:
      '¿Cuál es tu número de ID Tributario, TIN o SSN de los otros países diferentes a Panamá, donde eres considerado residente fiscal?',
    rules: regularTextRule('', false, 50),
  },
  descNonTax: {
    required: true,
    label: 'Si no tienes ID Tributario, TIN o SSN, indica el motivo',
    placeholder: 'Si no tienes ID Tributario, TIN o SSN, indica el motivo',
    rules: regularTextRule('Motivo', isRequired, 50),
  },
}

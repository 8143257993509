import React from 'react';
import {BreakPointTheme} from 'src/theme/breakpoints';
import styled from 'styled-components';

export const Container = React.memo(styled('div')`
  display: flex;
  height: auto;
  min-height: 100vh;
  flex: 1;
  background: #f2f4f7;
`);
export const DivWelcome = React.memo(styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  align-items: center;
  @media (min-width: 600px) {
    margin: 0;
  }
  @media (min-width: 1024px) {
    margin: 1vh 0 0 0;
  }
`);
export const TitleSpan = React.memo(styled.div`
  font-size: 26px;
  color: #011e41;
  font-weight: 500;
  line-height: 52px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-self: center;
  align-items: center;
  justify-self: center;
  justify-content: center;
  @media (min-width: ${BreakPointTheme.breakpoints.values.xs}px) {
    font-size: 22px;
    line-height: 22px;
    width: 100%;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.sm}px) {
    font-size: 35px;
    line-height: 32px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.lg}px) {
    font-size: 48px;
    line-height: 50px;
    width: 90%;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.xxl}px) {
    font-size: 65px;
    line-height: 80px;
  }
`);
export const ImgLogo = React.memo(styled.img`
  margin: 0px 10px;
  width: 65px;
  @media (min-width: ${BreakPointTheme.breakpoints.values.sm}px) {
    width: 115px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.lg}px) {
    width: 125px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.xxl}px) {
    width: 170px;
  }
`);
export const SubtitleSpan = React.memo(styled.span`
  width: 95%;
  color: #011e41;
  font-size: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;
  @media (min-width: ${BreakPointTheme.breakpoints.values.xs}px) {
    font-size: 15px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.lg}px) {
    font-size: 17px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.xl}px) {
    font-size: 20px;
    padding-bottom: 20px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.xxl}px) {
    padding-bottom: 20px;
    font-size: 30px;
  }
`);
export const SubtitleSpanBold = React.memo(styled.span`
  font-weight: 700;
  color: #011e41;
  font-size: 30px;
  margin: 0px 10px;
  text-align: center;
  @media (min-width: ${BreakPointTheme.breakpoints.values.xs}px) {
    margin: 0px 5px;
    font-size: 15px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.lg}px) {
    font-size: 17px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.xl}px) {
    font-size: 20px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.xxl}px) {
    font-size: 30px;
  }
`);
export const CardText = React.memo(styled.div`
  width: 75%;
  background: #026e184d;
  border-radius: 10px;
  height: 100%;
  padding: 5px;
  @media (min-width: ${BreakPointTheme.breakpoints.values.xs}px) {
    width: 90%;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.sm1}px) {
    width: 80%;
  }
`);
export const CardTextPrepaid = React.memo(styled.div`
  width: 75%;
  background: #009ea866;
  border-radius: 10px;
  height: 100%;
  padding: 5px;
  @media (min-width: ${BreakPointTheme.breakpoints.values.xs}px) {
    width: 90%;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.sm1}px) {
    width: 80%;
  }
`);

export const ListContainer = React.memo(styled.div`
  font-size: 20px;
  height: 100%;
  color: #011e41;
  padding: 20px 30px;
  line-height: 30px;
  opacity: 1;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  ul {
    padding-left: 20px;
    list-style: none;
  }
  li {
    display: flex;
    align-items: start;
    gap: 10px;
  }
  li::before {
    content: '•';
    color: #011e41;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.xs}px) {
    font-size: 10px;
    line-height: 16px;
  }
  @media (max-width: ${BreakPointTheme.breakpoints.values.sm}px) {
    padding: 7px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.sm1}px) {
    font-size: 14px;
    line-height: 16px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.lg}px) {
    font-size: 14px;
    line-height: 18px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.xl}px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.xxl}px) {
    font-size: 20px;
    line-height: 26px;
  }
`);

export const TitleCardText = React.memo(styled.p`
  font-size: 25px;
  color: #011e41;
  font-weight: 600;
  margin: 0;
  padding: 0px 0px 8px;
  ${BreakPointTheme.breakpoints.up('xs')} {
    font-size: 14px;
  }
  ${BreakPointTheme.breakpoints.up('sm1')} {
    font-size: 16px;
  }
  ${BreakPointTheme.breakpoints.up('xl')} {
    font-size: 25px;
  }
`);
export const ImagePhone = React.memo(styled.img`
  width: auto;
  height: auto;
  @media (min-width: ${BreakPointTheme.breakpoints.values.xs}px) {
    height: 65px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.xsm}px) {
    height: 100px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.sm1}px) {
    height: 135px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.md}px) {
    height: 155px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.lg}px) {
    height: 165px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.xxl}px) {
    height: 250px;
  }
`);
export const BlockContainer = React.memo(styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: ${BreakPointTheme.breakpoints.values.md}px) {
    flex-direction: column;
  }
`);
export const OnboardingContainer = React.memo(styled.div`
  display: flex;flex-direction: column
  width: 100%;justify-content : space-between;align-items: center;`);
export const RequirementsDiv = React.memo(styled.div`
  color: #fff;
  font-size: 24px;
  width: 90%;
  background-color: #3f9600;
  padding: 20px;
  border-radius: 10px;
  @media (max-width: ${BreakPointTheme.breakpoints.values.xs}px) {
    padding: 10px;
  }
  @media (max-width: ${BreakPointTheme.breakpoints.values.sm}px) {
    padding: 10px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values
      .xs}px) and (max-width: ${BreakPointTheme.breakpoints.values.sm1}px) {
    font-size: 10px;
    line-height: 14px;
    font-weight: 100;
    padding: 7px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values
      .sm1}px) and (max-width: ${BreakPointTheme.breakpoints.values.xxl}px) {
    font-size: 15px;
    line-height: 20px;
    padding: 15px;
    margin: 20px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.xxl}px) {
    font-size: 24px;
    line-height: 26px;
    padding: 20px;
    margin: 35px;
  }
`);
export const ButtonWelcome = React.memo(styled('button')`
  width: 60%;
  min-height: 76px;
  height: 76px;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  @media (min-width: ${BreakPointTheme.breakpoints.values.xs}px) {
    width: 85%;
    min-height: 35px;
    height: 35px;
    font-size: 17px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.xsm}px) {
    width: 85%;
    min-height: 40px;
    height: 40px;
    font-size: 17px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.sm1}px) {
    width: 30%;
    min-height: 50px;
    height: 50px;
    font-size: 17px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.lg1}px) {
    width: 30%;
    min-height: 60px;
    height: 60px;
    font-size: 20px;
  }
  @media (min-width: ${BreakPointTheme.breakpoints.values.xxl}px) {
    width: 30%;
    min-height: 76px;
    height: 76px;
    font-size: 25px;
  }
`);
export const ContainerModal = React.memo(styled.div`
  font-family: 'Red Hat Text';
  background-color: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  width: 90%;
  height: auto;
  border-radius: 15px;
  padding: 30px 0;
  @media (min-width: ${BreakPointTheme.breakpoints.values.sm1}px) {
    width: 60%;
    height: auto;
  }
`);
export const ContainerData = React.memo(styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Red Hat Text';
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
`);
export const DataWebView = React.memo(styled.h1`
  font-family: 'Red Hat Text';
  color: #000;
  font-size: clamp(14px, 1vw, 20px);
  margin-bottom: 0;
  font-weight: 500;
`);

import React from 'react'
import { connect } from 'react-redux'
import { logsOnboarding } from 'src/store/actions/requestCard.actions'
class ErrorHandler extends React.Component {
  constructor(props) {
    super(props)
    this.state = {errorOccurred: false}
  }
  componentDidCatch(error, info) {
    this.setState({errorOccurred: true})
    this.props.logsOnboarding(0, 'WEB', 'CRASHED_WEB', error, info, true) // this.props.logsOnboarding({error, info})
  }

  render() {
    return <>{this.props.children}</>
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logsOnboarding: (id, origin, commentary, resp, problem, complete) =>
      dispatch(logsOnboarding(id, origin, commentary, resp, problem, complete)),
  }
}
const ErrorHand = connect(mapStateToProps, mapDispatchToProps)(ErrorHandler)
export default ErrorHand

import styled from '@emotion/styled';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import WrapperSide from 'src/components/Aside/Wrapper.js';
import ButtonNextOnboarding from 'src/components/Button/ButtonNextOnboarding.js';
import CMFLogo from 'src/components/Logos/CMF.js';
import SubtitleResponsiveOnboarding from 'src/components/TitleInput/SubtitleResponsiveOnboarding.js';
import TitleResponsiveOnboarding from 'src/components/TitleInput/TitleResponsiveOnboarding.js';
import { BreakPointTheme } from 'src/theme/breakpoints.js';

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}));
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
const ContainerInfo = styled('div')(() => ({
  display: 'flex',
  height: '50%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  [BreakPointTheme.breakpoints.down('sm')]: {
    margin: '15vh auto auto auto',
    width: '90%',
    height: '40vh',
  },
}));

const titleText = {
  CREDI: 'Gracias por tu interés en la tarjeta digital CMF',
  PPAGO: 'Gracias por tu interés en la tarjeta Prepago CMF',
  PHONE: 'No podemos continuar con tu solicitud porque',
};

const subtitleText = {
  CREDI:
    'No podemos continuar con tu solicitud porque ya tienes una tarjeta aprobada',
  PPAGO:
    'No podemos continuar con tu solicitud porque ya tienes una tarjeta aprobada',
  PHONE: 'ya tienes un crédito móvil CMF',
};
const ProductExist = () => {
  const {existProduct} = useSelector(state => state.KOReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClose = () => {
    navigate('/cardrequest');
    dispatch({
      type: 'CLEAN_DIALOG',
    });
    dispatch({
      payload: null,
      type: 'EXIST_PRODUCT',
    });
  };

  return (
    <Container>
      <WrapperSide background="rgb(255, 255, 255)" />
      <Wrapper
        style={{
          flex: 1,
          height: '100%',
          justifyContent: 'center',
          background:
            'radial-gradient(circle at top left, #024114, transparent 30%),radial-gradient(circle at top right, #017617, transparent 30%),radial-gradient(circle at bottom left, #026D17, transparent 30%),radial-gradient(circle at bottom right, #026D17, transparent 30%),   linear-gradient(135deg, #026D17 0%, #0B7021 20%, #026C18 40%, #015517 60%, #024315 80%, #025918 100%)',
        }}>
        <center>
          <CMFLogo />
        </center>

        <ContainerInfo>
          <TitleResponsiveOnboarding
            minSize={28}
            maxSize={65}
            text={titleText[existProduct] || titleText.CREDI}
            color="#fff"
            fontFamily="Red Hat Text"
            style={{marginBottom: 0}}
          />
          <SubtitleResponsiveOnboarding
            minSize={18}
            maxSize={35}
            text={subtitleText[existProduct] || subtitleText.CREDI}
            color="#fff"
            fontFamily="Red Hat Text"
          />
          <ButtonNextOnboarding
            variant="onboardingOutline"
            success
            onClick={onClose.bind(this)}>
            Salir
          </ButtonNextOnboarding>
        </ContainerInfo>
      </Wrapper>
    </Container>
  );
};

export default ProductExist;

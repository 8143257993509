// import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
// // @mui
// import {Box, List, ListItemText} from '@mui/material'
// //
// import {StyledNavItem, StyledNavItemIcon} from './styles'

// ----------------------------------------------------------------------
import HomeIcon from '@mui/icons-material/Home';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PaymentIcon from '@mui/icons-material/Payment';
import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import * as React from 'react';
import { useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import CardsTypesLateralBar from 'src/components/CreditCard/CardsTypesLateralBar';
import {
  getDetailsAccount,
  resetMovementReduce,
} from 'src/store/actions/MovimientoAction';
import { setProductCoporate } from 'src/store/actions/actions';
import { PRODUCTS_TYPES } from 'src/utils/Constants';

export default function NavSection({data = [], ...other}) {
  return (
    <Box {...other}>
      <NavItem />
    </Box>
  );
}
const styles = {
  display: 'flex',
  padding: '16px',
  alignItems: 'center',
  gap: '14px',
  alignSelf: 'stretch',
  fontWeight: 400,
  width: '219px',

  '&.MuiListItemButton-root:hover': {
    backgroundColor: '#98A2B329',
    color: '#98A2B3',
  },
  '&.Mui-selected': {
    backgroundColor: 'transparent',
    color: '#FFF',
    fontWeight: '500 !important',
  },
  '&.Mui-selected:hover': {
    color: '#98A2B3',
    backgroundColor: '#98A2B329',
  },
};
function NavItem() {
  const dispatch = useDispatch();
  const {dataInsuranse} = useSelector(state => state.ActiveProductReducer);
  const {listCustomerProducts} = useSelector(
    state => state.ActiveProductReducer,
  );
  const [hasFetched, setHasFetched] = React.useState(false);
  const {errors} = dataInsuranse;
  useEffect(() => {
    if (errors?.Error === 'false') {
      setInsuranceActive(true);
    }
  }, [errors?.Error]);
  const urlActive = window.location.pathname;
  const [insuranceActive, setInsuranceActive] = React.useState(false);
  const location = useLocation();

  const isValidRoute = location.pathname !== '/site/home/movements';
  //Verifica si existe una cuenta corporativa
  const existsCorporateTrue = listCustomerProducts?.some(
    obj => obj.isCorporate === true,
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (insuranceActive && location.pathname === '/home/insurance') {
      navigate('/home', {replace: true});
    }
  }, [location.pathname, insuranceActive, navigate]);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const {selectedProduct} = useSelector(state => state.ActiveProductReducer);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlLink = urlParams.get('success');
  const handleListItemClick = (event, index) => {
    localStorage.setItem('selectedIndex', index);
    setSelectedIndex(index);
  };
  const handleListItemInsuranse = (index, insuranceActiveValue) => {
    if (insuranceActiveValue) {
      NotificationManager.warning(
        'Tu seguro de Accidentes Personales ya se encuentra activo.',
      );
    } else {
      localStorage.setItem('selectedIndex', index);
      setSelectedIndex(index);
    }
  };

  useEffect(() => {
    if (urlLink === null) {
      const savedIndex = parseInt(localStorage.getItem('selectedIndex'));
      if (!isNaN(savedIndex)) {
        setSelectedIndex(savedIndex);
      }
    } else {
      setSelectedIndex(0);
      localStorage.setItem('selectedIndex', 0);
    }
  }, []);

  const getMovements = async e => {
    dispatch(resetMovementReduce());
    dispatch({
      type: 'CLEAN_V',
    });
    dispatch({
      type: 'SELECTED_PRODUCT',
      payload: e,
    });
    dispatch({
      type: 'FLOAT_AMOUNT',
      payload: 0,
    });
    dispatch({type: 'GENERALVTC_ACCOUNT_STATEMENT_CLEAN'});
    if (isValidRoute) {
      dispatch(getDetailsAccount(e?.productAccount, e?.customerProductId));
    }
  };

  const handleClick = async e => {
    dispatch(setProductCoporate(e));
    getMovements(e);
  };

  useEffect(() => {
    if (selectedProduct?.customerId && !hasFetched) {
      getMovements(selectedProduct);
      setHasFetched(true); // Marca que la operación ha sido realizada
    }
  }, [selectedProduct, hasFetched]);

  return (
    <List
      component="nav"
      aria-label="main mailbox folders"
      sx={{
        overflow: 'auto',
        maxHeight: '80vh',
      }}>
      <ListItemButton
        id="id-home-item"
        disableRipple
        selected={selectedIndex === 0}
        onClick={event => handleListItemClick(event, 0)}
        component={RouterLink}
        to="/home"
        sx={{
          ...styles,
        }}>
        <ListItemIcon
          sx={{
            color: selectedIndex === 0 ? '#93D500' : '#98A2B3',
            '&.MuiListItemIcon-root': {
              minWidth: 'auto',
              maxWidth: '24px',
            },
          }}>
          <HomeIcon />
        </ListItemIcon>
        <Typography variant="nav">Inicio</Typography>
      </ListItemButton>
      {!selectedProduct?.isCorporate &&
        selectedProduct?.productTypeId?.toUpperCase() !==
          PRODUCTS_TYPES.PREPP && (
          <React.Fragment>
            <ListItemButton
              id="id-products-item"
              disableRipple
              selected={selectedIndex === 1}
              onClick={event => handleListItemClick(event, 1)}
              component={RouterLink}
              to="/home/products"
              sx={{
                ...styles,
              }}>
              <ListItemIcon
                sx={{
                  color: selectedIndex === 1 ? '#93D500' : '#98A2B3',
                  '&.MuiListItemIcon-root': {
                    minWidth: 'auto',
                    maxWidth: '24px',
                  },
                }}>
                <PaymentIcon />
              </ListItemIcon>
              <Typography variant="nav">Pagar</Typography>
            </ListItemButton>
            <ListItemButton
              id="id-promotions-item"
              disableRipple
              selected={selectedIndex === 2}
              onClick={event => handleListItemClick(event, 2)}
              component={RouterLink}
              to="/home/promotions"
              sx={{
                ...styles,
              }}>
              <ListItemIcon
                sx={{
                  color: selectedIndex === 2 ? '#93D500' : '#98A2B3',
                  '&.MuiListItemIcon-root': {
                    minWidth: 'auto',
                    maxWidth: '24px',
                  },
                }}>
                <NewReleasesIcon sx={{width: '28px', height: '28px'}} />
              </ListItemIcon>
              <Typography variant="nav">Promociones</Typography>
            </ListItemButton>
          </React.Fragment>
        )}
      {!selectedProduct?.isCorporate &&
        selectedProduct?.productTypeId?.toUpperCase() !==
          PRODUCTS_TYPES.PREPP && (
          <ListItemButton
            id="id-chubb-item"
            disableRipple
            selected={selectedIndex === 3}
            onClick={() => handleListItemInsuranse(3, insuranceActive)}
            component={RouterLink}
            to={insuranceActive ? urlActive : '/home/insurance'}
            sx={{
              ...styles,
            }}>
            <ListItemIcon
              sx={{
                color: selectedIndex === 3 ? '#93D500' : '#98A2B3',
                '&.MuiListItemIcon-root': {
                  minWidth: 'auto',
                  maxWidth: '24px',
                },
              }}>
              <MonitorHeartIcon sx={{width: '28px', height: '28px'}} />
            </ListItemIcon>
            <Typography variant="nav">Seguros</Typography>
          </ListItemButton>
        )}
      {existsCorporateTrue && (
        <React.Fragment>
          <Divider textAlign="left" sx={{width: '100%', color: '#98A2B3'}}>
            Tarjetas
          </Divider>

          {listCustomerProducts &&
            listCustomerProducts
              .filter(data => data.isCorporate)
              .map((data, index) => (
                <CardsTypesLateralBar
                  key={index}
                  onClick={() => handleClick(data)}
                  data={data}
                />
              ))}
        </React.Fragment>
      )}
    </List>
  );
}

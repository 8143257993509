import { styled } from '@mui/material'
import React from 'react'
import { BiArrowBack } from 'react-icons/bi'
import WrapperSide from 'src/components/Aside/Wrapper'
import TitleResponsiveOnboarding from 'src/components/TitleInput/TitleResponsiveOnboarding'
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}))
export const TermsAndConditionsAPC = ({
  handleShowTermsAndConditions = () => {
    return
  },
}) => {
  return (
    <Container>
      <WrapperSide background="linear-gradient(#003a11, #016116, #4fa70a)" />
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div>
          <button
            onClick={() => {
              return handleShowTermsAndConditions(false)
            }}
            className="relative top-5 left-5 text-green-700  bg-gray-100 p-3 text-2xl cursor-pointer border-0">
            <BiArrowBack className="p-0.2" />
          </button>
        </div>

        <div className="lg:mt-20 mt-16 lg:px-40 p-4 flex flex-col item-center justify-center relative">
          <TitleResponsiveOnboarding
            minSize={28}
            maxSize={45}
            color="#011E41"
            text="Aprueba la revisión de tus datos en la APC, firma electrónica y la creación en el programa de Puntos Gordos"
            fontFamily="Red Hat Text"
            textAlign="center"
          />

          <div className="lg:max-h-[70vh] max-h-[75vh] overflow-y-scroll  px-2 ">
            <p className="font-light flex flex-col items-center ">
              <span className="pt-4 text-[15px] font-normal text-justify">
                <span className="font-medium">PRIMERO:</span>
                Por este medio autorizo(amos) expresamente a{' '}
                <span className="font-medium"> CM FINANCIERA.S.A., </span>
                sus subsidiarias y/o afiliadas, cesionarios o sucesoras, a
                solicitar, consultar, recopilar, intercambiar y transmitir a
                cualquier agencia de información de datos, bancos o agentes
                económicos informaciones relacionadas con obligaciones o
                transacciones comerciales económicas, financieras, bancarias o
                de cualquier otra naturaleza análoga, que mantuve, mantengo o
                pudiera mantener con dichos agentes económicos de la localidad o
                del exterior, sobre mi historial de crédito y relaciones con
                acreedores las veces que{' '}
                <span className="font-medium">
                  <span className="font-medium">CM FINANCIERA.S.A. </span>,{' '}
                </span>
                sus subsidiarias y/o afiliadas, cesionarios o sucesoras; lo
                requiera, de conformidad con las disposiciones legales
                aplicables contempladas en el artículo 24 y demás disposiciones
                de la Ley 24 de 22 de mayo de 2002, modificada por la Ley 14 de
                18 de mayo 2006 y la Ley 135 de 31 de diciembre de 2013. <br />{' '}
                <br /> También queda facultada{' '}
                <span className="font-medium"> CM FINANCIERA.S.A. </span>, sus
                subsidiarias y/o afiliadas, cesionarios o sucesoras, para que
                soliciten y obtengan información de instituciones
                gubernamentales relacionadas con mi persona. De esta forma,
                exonero a{' '}
                <span className="font-medium"> CM FINANCIERA.S.A. </span>, sus
                subsidiarias y/o afiliadas, cesionarios o sucesoras; así como a
                sus empleados, ejecutivos, directores, dignatarios o apoderados,
                de cualquier consecuencia o responsabilidad resultante del
                ejercicio de solicitar, consultar, recopilar, intercambiar,
                transmitir y suministrar información o por razón de cualesquiera
                autorizaciones contenidas en el presente documento.  CM
                FINANCIERA, S.A., queda obligado a mantener a disposición del
                interesado, y le hará entrega a su requerimiento, de toda la
                información que reciba, mantenga o maneje en base a la
                autorización emitida por éste. <br /> <br />{' '}
                <span className="font-medium">CM FINANCIERA, S.A.</span>, sus
                subsidiarias y/o afiliadas, cesionarios o sucesoras, no
                utilizarán los datos recopilados de acuerdo con este documento
                para fines distintos e os establecidos en este documento ni para
                fines incompatibles con aquellos para los cuales los datos
                fueron recopilados.
              </span>
              <span className="pt-4 text-[15px] font-normal text-justify">
                <span className="font-medium">SEGUNDO:</span> Autorizo de forma
                expresa a CM FINANCIERA, S.A. a compartir con sus subsidiarias
                y/o afiliadas, cesionarios o sucesoras toda mi información y
                datos personales necesarios para ser incluidos en las bases de
                datos de estas compañías, y en el programa de lealtad Puntos
                Gordos, sin mediar restricción alguna sobre el uso de la misma.
              </span>
              <span className="pt-4 text-[15px] font-normal text-justify">
                <span className="font-medium">TERCERO:</span>{/*  */}
                Todos los datos que el Usuario proporcione a CM FINANCIERA, S.A.
                se encontrarán sujetos a nuestro control conforme a la
                legislación aplicable de la República de Panamá. Los datos que
                proporcione el Usuario serán de uso exclusivo e interno de CM
                FINANCIERA (y sus ubsidiarias), y no se hará uso de dichos datos
                para efectos de comercialización o divulgación a ningún otro
                ente.
              </span>
              <span className="pt-4 text-[15px] font-normal text-justify">
                <span className="font-medium">CUARTO: </span> Las partes
                expresamente reconocen la validez de todos los documentos
                firmados en formato electrónico, mediante firma digital o firma
                electrónica. Las partes acuerdan que para todos los propósitos
                se considerará válido si se procede con la ejecución de
                contratos en formato electrónico. Las partes acuerdan que para
                todos los propósitos las firmas electrónicas y firmas digitales
                se considerarán firmas válidas. También acuerdan otorgar validez
                a las autorizaciones digitales de solicitud y trámites
                relacionados con los productos que ofrece CM Financiera, S.A.,
                que sean solicitados por el Cliente. <br /> <br /> El deudor
                declara que la información que ha suministrado para la obtención
                del crédito aquí documentada es verídica.
              </span>
            </p>
          </div>
        </div>
      </div>
    </Container>
  )
}

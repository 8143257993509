import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import {makeStyles} from '@material-ui/core/styles'
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import {BreakPointTheme} from 'src/theme/breakpoints'
const useStyles = makeStyles(theme => ({
  dialogTitle: {
    width: '80%',
    textAlign: 'center',
    paddingTop: theme.spacing(7),
    [BreakPointTheme.breakpoints.down('sm1')]: {
      paddingTop: theme.spacing(12),
      width: '95%',
    },
  },
  backButton: {
    display: 'inline-block',
    position: 'absolute',
    top: '0',
    left: '0.75rem',
    marginTop: theme.spacing(1),
    cursor: 'pointer',
    [BreakPointTheme.breakpoints.up('sm1')]: {
      display: 'none',
    },
  },
  closeButton: {
    position: 'absolute',
    display: 'inline-block',
    top: '0',
    right: '1.25rem',
    cursor: 'pointer',
    marginTop: theme.spacing(3),
    [BreakPointTheme.breakpoints.down('sm1')]: {
      display: 'none',
    },
  },
}))

const ModalHeaderButton = ({onHide}) => {
  const classes = useStyles()
  return (
    <DialogTitle className={classes.dialogTitle}>
      <IconButton className={classes.backButton} onClick={onHide}>
        <ArrowBackIcon
          sx={{
            width: '2.625rem',
            height: '2.625rem',
            fontSize: '2.5rem',
            borderRadius: '0.5rem',
            color: '#026E18',
            background: '#F2F4F7',
            padding: '0.5rem',
          }}
        />
      </IconButton>
      <IconButton className={classes.closeButton} onClick={onHide}>
        <CloseIcon
          sx={{
            width: '2.938rem',
            height: '2.938rem',
            fontSize: '2.938rem',
            padding: '0.5rem',
            borderRadius: '9999px',
            color: '#fff',
            background: '#666B76',
          }}
        />
      </IconButton>
    </DialogTitle>
  )
}

export default ModalHeaderButton

import { Box } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import { BreakPointTheme } from 'src/theme/breakpoints'
import {
    BASE_COLORS,
    FONT_SIZE,
    FONT_WEIGHT
} from '../../../theme/constants'
export const Containt = styled('div')(({theme}) => ({
  height: '100vh',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'space-between',
  justifyContent: 'space-between',
  fontSize: FONT_SIZE.xs,
  fontFamily: 'Red Hat Text',
  overflow: 'none',
  backgroundColor: BASE_COLORS.background,
}))

export const text = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'none',
}))

export const p = styled('p')(({theme}) => ({
  width: '100%',
  fontFamily: 'Red Hat Text',
  fontSize: FONT_SIZE.xxxl,
  fontWeight: FONT_WEIGHT.medium,
  margin: '5px 0px',
  lineHeight: '32px',
  letterSpacing: '-0.56px',
  alignSelf: 'stretch',
  [theme.breakpoints.between('sm', 'xxl')]: {
    fontSize: FONT_SIZE.xxxxl,
  },
}))

export const textReset = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'none',
}))

export const labelReset = styled('label')(({theme}) => ({
  color: BASE_COLORS.gray,
  fontFamily: 'Red Hat Text',
  fontWeight: FONT_WEIGHT.regular,
  fontSize: FONT_SIZE.md,
}))

export const aReset = styled('a')(({theme}) => ({
  textDecoration: 'none',
  color: BASE_COLORS.darkgreen,
  fontSize: FONT_SIZE.md,
  fontFamily: 'Red Hat Text',
  fontWeight: FONT_WEIGHT.medium,
  marginLeft: '15px',
  '&:hover': {
    color: BASE_COLORS.darkgreen,
    fontSize: FONT_SIZE.md,
    fontFamily: 'Red Hat Text',
    fontWeight: FONT_WEIGHT.medium,
  },
}))

export const ResetContainer = styled('div')(({theme}) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'space-between',
  fontSize: FONT_SIZE.xs,
  fontFamily: 'Red Hat Text',
  overflow: 'none',
  [theme.breakpoints.between('xxs', 'md')]: {
    justifyContent: 'flex-start',
    height: '90vh',
    width: '100%',
  },
  [theme.breakpoints.between('lg', 'xxl')]: {
    justifyContent: 'space-between',
  },
}))

export const LoginDescription = styled('div')(({theme}) => ({
  margin: '1.875rem',
  color: 'var(--text-main-color)',
  fontSize: FONT_SIZE.xl,
  [theme.breakpoints.only('xs')]: {
    marginBottom: '1.5625rem',
    fontWeight: FONT_WEIGHT.bold,
  },
  [theme.breakpoints.only('md')]: {
    marginBottom: '1.75rem',
    fontWeight: FONT_WEIGHT.regular,
  },
  [theme.breakpoints.only('xl')]: {
    marginBottom: '1.875rem',
  },
}))

export const formPassword = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: FONT_SIZE.xs,
  fontFamily: 'Red Hat Text',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  justifyItem: 'center',
  overflow: 'none',
  fontWeight: FONT_WEIGHT.medium,
  color: BASE_COLORS.black,
  [theme.breakpoints.between('xs', 'sm')]: {
    padding: '0vh 1vh 0px 1vh',
  },
  [theme.breakpoints.between('md', 'xl')]: {
    padding: '6vh 8vh 0px 8vh',
  },
}))

export const FormL = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  height: '50vh',
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '95%',
    gap: '8px',
    marginTop: '48px',
  },
  [BreakPointTheme.breakpoints.only('sm')]: {
    width: '55%',
    gap: '15px',
  },
  [BreakPointTheme.breakpoints.only('md')]: {
    width: '40%',
    gap: '15px',
  },
  [BreakPointTheme.breakpoints.up('lg')]: {
    width: '35%',
    gap: '15px',
  },
}))

export const VolverTypo = styled(Typography)(({theme}) => ({
  fontSize: '18px',
  fontStyle: 'normal',
  margin: '0px 15px',
  color: '#1018288A',
  fontWeight: 500,
  lineHeight: '22px',
  letterSpacing: '-0.36px',
}))

export const ContainerInput = styled('div')(({theme}) => ({
  width: '100%',
}))

export const InputReset = styled(OutlinedInput)(({theme}) => ({
  width: '100%',
  margin: '20px 0px',
}))

export const ContainerButton = styled('div')(({theme}) => ({
  width: '100%',
}))

export const ButtonReset = styled(LoadingButton )(({theme}) => ({
  width: '100%',
  borderRadius: '50px',
  height: '3em',
  fontFamily: 'Red Hat Text',
  fontWeight: FONT_WEIGHT.medium,
  fontSize: FONT_SIZE.md,
  gap: '24px'
}))

export const ButtonBack = styled(Button)(({theme}) => ({
  minWidth: '48px',
  height: '48px',
  borderRadius: '16px',
  color: BASE_COLORS.green,
  backgroundColor: BASE_COLORS.white,

  '&:hover': {
    fontSize: FONT_SIZE.md,
    color: BASE_COLORS.green,
    background: BASE_COLORS.white,
    fontFamily: 'Red Hat Text',
    fontWeight: FONT_WEIGHT.medium,
  },
  '& span': {
    color: BASE_COLORS.green,
    fontFamily: 'Red Hat Text',
    fontWeight: FONT_WEIGHT.medium,
    fontSize: FONT_SIZE.lg,
  },
}))

export const divCopy = styled('div')(({theme}) => ({
  width: '100%',
  display: 'flex',
  padding: '2rem 2rem 0.5rem',
  justifyContent: 'flex-start',
  textAlign: 'center',
  [theme.breakpoints.only('xs')]: {
    padding: '0rem 1rem 1rem',
    justifyContent: 'center',
  },
  [theme.breakpoints.down('xs')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.up('lg')]: {
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.up('xl')]: {
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.up('xxl')]: {
    justifyContent: 'flex-start',
  },
}))
export const CopyContainer = styled('div')(({theme}) => ({
  [theme.breakpoints.only('xs')]: {
    width: '84%',
  },
  [theme.breakpoints.only('sm')]: {
    width: '84%',
  },
  [theme.breakpoints.only('md')]: {
    width: '84%',
  },
}))
export const pCopy = styled('p')(({theme}) => ({
  display: 'flex',
  fontSize: FONT_SIZE.xxs,
  fontFamily: 'Red Hat Text',
  fontWeight: FONT_WEIGHT.regular,
  color: BASE_COLORS.gray,
  alignSelf: 'stretch',
  fontStyle: 'normal',
  lineHeight: '16px',
  [theme.breakpoints.between('xxs', 'md')]: {
    fontSize: FONT_SIZE.xxs,
  },
}))

export const ReenviarContaint = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '1rem 0.5rem',
}))

export const PasswordRules = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
}))

export const Rules = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '5px',
}))

export const FormP = styled(FormControl)(({theme}) => ({
  width: '100%',
}))

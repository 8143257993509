import React from 'react'
import styles from './style.module.css'
const IconStore = ({url, icons, alt}) => {
  return (
    <a
      className={styles.divContains}
      href={url}
      target="_blank"
      rel="noopener noreferrer">
      <div>
        <img src={icons} alt={alt} />
      </div>
    </a>
  )
}

export default IconStore

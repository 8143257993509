import {Box} from '@mui/material';
import React from 'react';

function IconBlock({status}) {
  return (
    <Box
      sx={{
        width: '40px',
        height: '40px',
        filter: 'none',
      }}>
      {status ? (
        <svg
          width="40"
          height="40"
          viewBox="25 12 26 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_dddd_3670_12904)">
            <rect x="18" y="12.5" width="40" height="40" rx="20" fill="white" />
            <path
              d="M35.9999 32.5C35.9999 31.3954 36.8953 30.5 37.9999 30.5C39.1045 30.5 39.9999 31.3954 39.9999 32.5C39.9999 33.6046 39.1045 34.5 37.9999 34.5C36.8953 34.5 35.9999 33.6046 35.9999 32.5Z"
              fill="#026E18"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M37.9999 24.5C35.1382 24.5 32.8053 25.7647 31.0767 27.2074C29.3504 28.6482 28.1669 30.3143 27.5741 31.2528L27.55 31.2908C27.4324 31.476 27.2797 31.7164 27.2025 32.0532C27.1402 32.3251 27.1402 32.6749 27.2025 32.9468C27.2797 33.2836 27.4324 33.524 27.55 33.7092L27.5741 33.7472C28.1669 34.6857 29.3504 36.3518 31.0767 37.7926C32.8053 39.2353 35.1382 40.5 37.9999 40.5C40.8616 40.5 43.1946 39.2353 44.9232 37.7926C46.6494 36.3518 47.833 34.6857 48.4257 33.7472L48.4498 33.7092C48.5674 33.524 48.7201 33.2837 48.7973 32.9468C48.8597 32.6749 48.8597 32.3251 48.7973 32.0532C48.7201 31.7163 48.5674 31.476 48.4498 31.2908L48.4257 31.2528C47.833 30.3143 46.6494 28.6482 44.9232 27.2074C43.1946 25.7647 40.8616 24.5 37.9999 24.5ZM37.9999 28.5C35.7908 28.5 33.9999 30.2909 33.9999 32.5C33.9999 34.7091 35.7908 36.5 37.9999 36.5C40.209 36.5 41.9999 34.7091 41.9999 32.5C41.9999 30.2909 40.209 28.5 37.9999 28.5Z"
              fill="#026E18"
            />
          </g>
          <defs>
            <filter
              id="filter0_dddd_3670_12904"
              x="0"
              y="0.5"
              width="76"
              height="76"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_3670_12904"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.04 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_dropShadow_3670_12904"
                result="effect2_dropShadow_3670_12904"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="2"
                operator="dilate"
                in="SourceAlpha"
                result="effect3_dropShadow_3670_12904"
              />
              <feOffset dy="6" />
              <feGaussianBlur stdDeviation="8" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.04 0"
              />
              <feBlend
                mode="normal"
                in2="effect2_dropShadow_3670_12904"
                result="effect3_dropShadow_3670_12904"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="17" />
              <feGaussianBlur stdDeviation="3.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.01 0"
              />
              <feBlend
                mode="normal"
                in2="effect3_dropShadow_3670_12904"
                result="effect4_dropShadow_3670_12904"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect4_dropShadow_3670_12904"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      ) : (
        <svg
          width="40"
          height="40"
          viewBox="25 12 29 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_dddd_3345_15602)">
            <rect x="20" y="14" width="40" height="40" rx="20" fill="#101828" />
            <rect
              x="19.2"
              y="13.2"
              width="41.6"
              height="41.6"
              rx="20.8"
              stroke="url(#paint0_linear_3345_15602)"
              stroke-width="1.6"
            />
            <path
              d="M37.9999 34C37.9999 32.8954 38.8953 32 39.9999 32C41.1045 32 41.9999 32.8954 41.9999 34C41.9999 35.1046 41.1045 36 39.9999 36C38.8953 36 37.9999 35.1046 37.9999 34Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M39.9999 26C37.1382 26 34.8053 27.2647 33.0767 28.7074C31.3504 30.1482 30.1669 31.8143 29.5741 32.7528L29.55 32.7908C29.4324 32.976 29.2797 33.2164 29.2025 33.5532C29.1402 33.8251 29.1402 34.1749 29.2025 34.4468C29.2797 34.7836 29.4324 35.024 29.55 35.2092L29.5741 35.2472C30.1669 36.1857 31.3504 37.8518 33.0767 39.2926C34.8053 40.7353 37.1382 42 39.9999 42C42.8616 42 45.1946 40.7353 46.9232 39.2926C48.6494 37.8518 49.833 36.1857 50.4257 35.2472L50.4498 35.2092C50.5674 35.024 50.7201 34.7837 50.7973 34.4468C50.8597 34.1749 50.8597 33.8251 50.7973 33.5532C50.7201 33.2163 50.5674 32.976 50.4498 32.7908L50.4257 32.7528C49.833 31.8143 48.6494 30.1482 46.9232 28.7074C45.1946 27.2647 42.8616 26 39.9999 26ZM39.9999 30C37.7908 30 35.9999 31.7909 35.9999 34C35.9999 36.2091 37.7908 38 39.9999 38C42.209 38 43.9999 36.2091 43.9999 34C43.9999 31.7909 42.209 30 39.9999 30Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_dddd_3345_15602"
              x="0.399902"
              y="0.400391"
              width="79.2002"
              height="79.1992"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_3345_15602"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.04 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_dropShadow_3345_15602"
                result="effect2_dropShadow_3345_15602"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="2"
                operator="dilate"
                in="SourceAlpha"
                result="effect3_dropShadow_3345_15602"
              />
              <feOffset dy="6" />
              <feGaussianBlur stdDeviation="8" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.04 0"
              />
              <feBlend
                mode="normal"
                in2="effect2_dropShadow_3345_15602"
                result="effect3_dropShadow_3345_15602"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="17" />
              <feGaussianBlur stdDeviation="3.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.01 0"
              />
              <feBlend
                mode="normal"
                in2="effect3_dropShadow_3345_15602"
                result="effect4_dropShadow_3345_15602"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect4_dropShadow_3345_15602"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_3345_15602"
              x1="54.2003"
              y1="-14.7548"
              x2="22.8049"
              y2="-5.78469"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#93D500" />
              <stop offset="1" stop-color="#0099A8" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </Box>
  );
}

export default IconBlock;

import React from 'react'
import styled from 'styled-components'

const Title = styled.div`
  position: relative;
  line-height: 1.13rem;
  font-weight: 500;
  font-Family: "Red Hat Text", sans-serif;
`

const TopFrame1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const SectionTitle3 = styled.div`
  width: 43.13rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: .5rem 0;
  box-sizing: border-box;
  gap: var(--gap-9xs);
  
`

const SeccionTitle = ({title}) => {
  return (
    <SectionTitle3>
      <TopFrame1>
        <Title>{title}</Title>
        {/* <Button1>
          <ButtonBase>
            <Text1>{`Editar `}</Text1>
            <SettingsIcon alt="" src="/settings2.svg" />
          </ButtonBase>
        </Button1> */}
      </TopFrame1>
    </SectionTitle3>
  )
}
export default SeccionTitle

import styled from '@emotion/styled'
import { Col, Form, Input, Row, Select } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AsideLogo from '../../components/Aside/AsideLogo.js'
import ButtonNext from '../../components/Button/ButtonNext.js'
import FooterOnboar from '../../components/Footer/FooterOnboarding.js'
import ProgressBar from '../../components/ProgressBar/index.js'
import { editFormRequest } from '../../store/actions/KO.Actions.js'
import { updateOnboadingPhones } from '../../store/actions/phoneAction.js'
import { SELLER_ID_TYPE } from '../../utils/Constants.js'
import { formFields } from './FormRequest.config.js'
import canReturnMobile from './canReturnMobile.js'
import { SCREENS } from './screens.js'
const {Option} = Select
const SelectMain = styled(Select)(() => ({
  '.ant-select-selector': {
    width: ' 100%',
    height: '3.5rem !important',
    padding: ' 0 11px',
  },
  '.ant-select-selection-search-input': {
    height: '3rem !important',
    margin: 'auto !important',
  },
  '.ant-select-arrow ': {
    color: ' var(--color-primary-main)',
    // borderWidth: ' 520px',

    'svg ': {
      display: ' inline-block',
      strokeWidth: ' 17%',
      stroke: ' var(--color-primary-main)',
      color: ' var(--color-primary-main)',
    },
  },
  '  .ant-select-selection__rendered': {
    display: 'contents',
    padding: '5px',
  },
  '.ant-select-focused .ant-select-single': {
    margin: 'auto',
  },
  '.ant-select-selector .ant-select-selection-placeholder': {
    margin: 'auto',
  },
  '.ant-select-selector .ant-select-selection-item': {
    margin: 'auto',
  },
  '.ant-select-selection-selected-value ': {
    width: ' 86% !important',
    position: ' absolute',
    top: ' 50%',
    right: ' 9px',
    left: ' 15px',
    maxWidth: ' 100%',
    height: ' 20px',
    marginTop: ' -10px',
    overflow: ' hidden',
    lineHeight: ' 20px',
    whiteSpace: ' nowrap',
    textAlign: ' left',
    textOverflow: ' ellipsis',
  },
  '@media (min-width: 1201px) and (max-width: 1535px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 901px) and (max-width: 1200px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 601px) and (max-width: 900px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '4em !important',
    },
  },
  '@media (max-width:600px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '3rem !important',
    },
  },
}))
const InputMain = styled(Input)(() => ({
  '&.ant-input': {
    height: ' 3.5rem !important',
    padding: ' 0 11px',
  },
  '&.ant-input-disabled': {
    color: ' var( --unamed-color-f5f5f5)',
    backgroundColor: ' var( --unamed-color-f5f5f5)',
    borderColor: ' var( --unamed-color-f5f5f5)',
    boxShadow: 'none',
    cursor: 'not - allowed',
    opacity: '1',
  },
  '@media (min-width: 1201px) and (max-width: 1535px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 901px) and (max-width: 1200px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 601px) and (max-width: 900px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '4em !important',
    },
  },
  '@media (max-width:600px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '3rem !important',
    },
  },
}))
const FormMain = styled(Form)(() => ({
  '&.ant-form': {
    padding: '0 5%',
    margin: '0 0 auto 0',
  },
  //   '@media (max-width:600px)': {
  //     // '.ant-select-selection--single': {
  //     //   height: '3rem ',
  //     // },
  //   },
}))
const InfoText = styled.p(() => ({
  color: '#B7B6B6',
  fontSize: '14px',
  fontFamily: 'Arial, Helvetica, sans-serif',
  fontStyle: 'oblique',
  margin: '0',
  padding: '0',
  span: {
    fontWeight: 'bold',
  },
  '@media (max-width:600px)': {
    fontSize: '12px',
  },
}))
const InfoTextAntiguedad = styled.p(() => ({
  color: '#B7B6B6',
  fontSize: '14px',
  fontFamily: 'Arial, Helvetica, sans-serif',
  margin: '0',
  padding: '0',
  position: 'relative',
  top: '20%',
  span: {
    fontStyle: 'oblique',
  },
  '@media (max-width:600px)': {
    fontSize: '12px',
  },
}))
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}))
const allYears = () => {
  const years = []
  const dateStart = moment()
  const dateEnd = moment().subtract(40, 'y')
  while (dateEnd.diff(dateStart, 'years') <= 0) {
    years.push(dateStart.format('YYYY'))
    dateStart.subtract(1, 'year')
  }
  return years
}
const ProfessionalInformation = props => {
  const [loading, setLoading] = useState(false)
  const {onboardingPhone} = useSelector(state => state.Phone)
  const formRequest = useSelector(state => state.RequestCardReducer.form)
  const dispatch = useDispatch()
  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 300)
  }, [])
  const handleChange = e => {
    dispatch(editFormRequest({[e.target.id]: e.target.value}))
  }
  const onSuccess = success => {
    setLoading(false)
    if (success) {
      props.goToStep(SCREENS.PoliticalInfoForm)
    }
  }
  const [form] = Form.useForm()
  const onCheck = async () => {
    try {
      await form.validateFields()
      setLoading(true)
      const new_date =
        formRequest.companyDateAdmissionYear +
        '-' +
        formRequest.companyDateAdmissionMonth
      const admissionDate =
        formRequest.activityType === 'JU'
          ? null
          : moment(new_date, 'YYYY-MM').format('YYYY-MM-DD')
      const employment = [
        {
          professionId: formRequest.profession,
          occupationId: formRequest.occupation,
          countryId: formRequest.country,
          provinceId: formRequest.province,
          districtId: formRequest.district,
          townshipId: formRequest.township,
          address: formRequest.addressCompany,
          typeWorkActivity: formRequest.activityType,
          salary: parseFloat(formRequest.salary),
          companyName: formRequest.company,
          workPhone: formRequest.companyPhone,
          dateAdmission: admissionDate,
          createdBy: SELLER_ID_TYPE,
          lastModifiedDate: new Date(),
          createdDate: new Date(),
        },
      ]
      dispatch(
        updateOnboadingPhones(
          onboardingPhone.id,
          onboardingPhone.origin,
          {employment: employment},
          onSuccess,
        ),
      )
    } catch (errorInfo) {
      setLoading(false)
    }
  }
  const {activityType} = useSelector(state => state.RequestCardReducer.form)
  const {professions, occupations} = useSelector(
    state => state.RequestCardReducer,
  )
  let noAvailable = activityType === 'JU' ? true : false
  return (
    <Container>
      <Wrapper style={{flex: 0.2, background: 'rgb(41, 164, 56)'}}>
        <AsideLogo
          positionX="left"
          positionY="top"
          color="white"
          hugColor="#FFF"
        />
      </Wrapper>

      <Wrapper
        style={{
          flex: 1,
          height: '100%',
          justifyContent: 'center',
        }}>
        <ProgressBar active={3} />
        <FormMain
          name="nest-messages"
          layout="vertical"
          form={form}
          autoComplete="off"
          initialValues={{
            profession: formRequest?.profession,
            occupation: formRequest?.occupation,
            company: formRequest?.company,
            companyPhone: formRequest?.companyPhone,
            addressCompany: formRequest?.addressCompany,
            companyDateAdmissionMonth: formRequest?.companyDateAdmissionMonth,
            companyDateAdmissionYear: formRequest?.companyDateAdmissionYear,
            salary: formRequest?.salary,
          }}>
          <Row gutter={[16, 16]}>
            <Col xl={12} xs={24} lg={12}>
              <Form.Item
                name="profession"
                rules={
                  activityType === 'JU' ? null : formFields.profession.rules
                }>
                <SelectMain
                  placeholder="Profesión"
                  disabled={noAvailable}
                  onSelect={value =>
                    dispatch(editFormRequest({'profession': value}))
                  }>
                  {professions?.map(item => {
                    return (
                      <Option value={item.value} label={item.label}>
                        {item.label}
                      </Option>
                    )
                  })}
                </SelectMain>
              </Form.Item>
            </Col>
            <Col xl={12} xs={24} lg={12}>
              <Form.Item
                name="occupation"
                rules={
                  activityType === 'JU' ? null : formFields.occupation.rules
                }>
                <SelectMain
                  placeholder="Ocupación"
                  disabled={noAvailable}
                  onSelect={value =>
                    dispatch(editFormRequest({'occupation': value}))
                  }>
                  {occupations?.map(item => {
                    return (
                      <Option value={item.value} label={item.label}>
                        {item.label}
                      </Option>
                    )
                  })}
                </SelectMain>
              </Form.Item>
            </Col>
            <Col xl={12} xs={24} lg={12}>
              <Form.Item
                name="company"
                rules={activityType === 'JU' ? null : formFields.company.rules}>
                <InputMain
                  onChange={handleChange}
                  id="company"
                  disabled={noAvailable}
                  placeholder="Empresa donde laboras"
                />
              </Form.Item>
              <InfoText>
                *Si no tienes empresa registrada, escribe{' '}
                <span>Independiente</span>
              </InfoText>
            </Col>
            <Col xl={12} xs={24} lg={12}>
              <Form.Item
                name="companyPhone"
                rules={
                  activityType === 'JU' ? null : formFields.companyPhone.rules
                }>
                <InputMain
                  disabled={noAvailable}
                  onChange={handleChange}
                  id="companyPhone"
                  placeholder="Teléfono de la empresa"
                  maxLength={7}
                  minLength={7}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="addressCompany"
                rules={
                  activityType === 'JU' ? null : formFields.addressCompany.rules
                }>
                <InputMain
                  onChange={handleChange}
                  disabled={noAvailable}
                  id="addressCompany"
                  placeholder="Dirección de la empresa"
                />
              </Form.Item>
              <InfoTextAntiguedad>
                Antigüedad laboral{' '}
                <span>(Selecciona la fecha de inicio laboral)</span>
              </InfoTextAntiguedad>
            </Col>
            <Col xl={6} xs={24} lg={6}>
              <Form.Item
                name="companyDateAdmissionMonth"
                rules={
                  activityType === 'JU'
                    ? null
                    : formFields.companyDateAdmissionMonth?.rules
                }>
                <SelectMain
                  placeholder="Mes"
                  onSelect={value =>
                    dispatch(
                      editFormRequest({
                        'companyDateAdmissionMonth': value,
                      }),
                    )
                  }>
                  {[
                    '01',
                    '02',
                    '03',
                    '04',
                    '05',
                    '06',
                    '07',
                    '08',
                    '09',
                    '10',
                    '11',
                    '12',
                  ].map(item => {
                    return (
                      <Option value={item} label={item}>
                        {item}
                      </Option>
                    )
                  })}
                </SelectMain>
              </Form.Item>
            </Col>
            <Col xl={6} xs={24} lg={6}>
              <Form.Item
                name="companyDateAdmissionYear"
                rules={
                  activityType === 'JU'
                    ? null
                    : formFields.companyDateAdmissionYear?.rules
                }>
                <SelectMain
                  placeholder="Año"
                  onSelect={value =>
                    dispatch(
                      editFormRequest({
                        'companyDateAdmissionYear': value,
                      }),
                    )
                  }>
                  {allYears().map(item => {
                    return (
                      <Option value={item} label={item}>
                        {item}
                      </Option>
                    )
                  })}
                </SelectMain>
              </Form.Item>
            </Col>
            <Col xl={12} xs={24} lg={12}>
              <Form.Item name="salary" rules={formFields.salary?.rules}>
                <InputMain
                  id="salary"
                  placeholder="Ingreso mensual"
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </FormMain>
        <FooterOnboar
          back={props.canReturn ? canReturnMobile(props, 5) : null}
          next={
            <ButtonNext
              background="green"
              border="green"
              loading={loading}
              disabled={false}
              data-testid="btn-next-professional"
              size="large"
              onClick={onCheck}>
              Siguiente
            </ButtonNext>
          }
        />
      </Wrapper>
    </Container>
  )
}

export default ProfessionalInformation

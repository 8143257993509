import React from 'react'
import {Button} from 'antd'
import styled from '@emotion/styled'
import {createTheme} from '@mui/material/styles'
const theme = createTheme()
const ButtonMainOK = styled(Button)(() => ({
  background: 'var(--color-primary-main)',
  border: '1px solid var(--color-primary-main)',
  color: 'var(--fondo-blanco)',
  textTransform: ' uppercase',
  borderRadius: '0px',
  [theme.breakpoints.up('xl')]: {
    width: '156px',
    height: '53px',
    font: 'normal normal normal 1.2rem/22px Arial',
  },
  [theme.breakpoints.down('xl')]: {
    width: '15%',
    height: '48px',
    font: 'normal normal normal 1.1rem/20px Arial',
    marginBottom: '1rem',
  },
  [theme.breakpoints.down('lg')]: {
    width: '20%',
    height: '45px',
    font: 'normal normal normal 1rem/18px Arial',
    marginBottom: '2.4rem',
  },
  [theme.breakpoints.down('md')]: {
    height: '42px',
    marginBottom: '2.4rem',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    height: '38px',
    margin: 'auto',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  '&:hover,&:focus': {
    borderColor: 'var(--color-primary-main)',
    color: 'var(--color-primary-main)'
  }
}))
const ButtonOk = props => {
  return (
    <ButtonMainOK onClick={props.onClick} {...props}>
      {props.data}
    </ButtonMainOK>
  )
}
export default ButtonOk

import React from 'react'
import {Button} from 'antd'
import styled from '@emotion/styled'
const ButtonMainOK = styled(Button)(({background, border}) => ({
  height: ' 3.5rem',
  padding: 0,
  borderRadius: 5,
  textAlign: 'center',
  fontSize: '1.2rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  ...(background === 'green' &&
    border === 'green' && {
      backgroundColor: 'var(--color-primary-main)',
      color: 'var(--fondo-blanco)',
      border: '2px solid var(--color-primary-main)',
      ':hover': {
        backgroundColor: 'var(--color-primary-main)',
        color: 'var(--fondo-blanco)',
        border: '2px solid var(--color-primary-main)',
      },
      ':focus': {
        backgroundColor: 'var(--color-primary-main)',
        color: 'var(--fondo-blanco)',
        border: '2px solid var(--color-primary-main)',
      },
    }),
  ...(background === 'green' &&
    border === 'white' && {
      backgroundColor: 'var(--color-primary-main)',
      color: 'var(--fondo-blanco)',
      border: '2px solid var(--fondo-blanco)',
      ':hover': {
        backgroundColor: 'var(--color-primary-main)',
        color: 'var(--fondo-blanco)',
        border: '2px solid var(--fondo-blanco)',
      },
      ':focus': {
        backgroundColor: 'var(--color-primary-main)',
        color: 'var(--fondo-blanco)',
        border: '2px solid var(--fondo-blanco)',
      },
    }),
  ...(background === 'white' &&
    border === 'green' && {
      backgroundColor: 'var(--fondo-blanco)',
      color: 'var(--color-primary-main) ',
      border: '2px solid var(--color-primary-main)',
      ':hover': {
        backgroundColor: 'var(--fondo-blanco)',
        color: 'var(--color-primary-main) ',
        border: '2px solid var(--color-primary-main)',
      },
      ':focus': {
        backgroundColor: 'var(--fondo-blanco)',
        color: 'var(--color-primary-main) ',
        border: '2px solid var(--color-primary-main)',
      },
    }),
  ...(background === 'white' &&
    border === 'white' && {
      backgroundColor: 'var(--fondo-blanco)',
      color: 'var(--color-primary-main) ',
      border: '2px solid var(--fondo-blanco)',
      ':hover': {
        backgroundColor: 'var(--fondo-blanco)',
        color: 'var(--color-primary-main) ',
        border: '2px solid var(--fondo-blanco)',
      },
      ':focus': {
        backgroundColor: 'var(--fondo-blanco)',
        color: 'var(--color-primary-main) ',
        border: '2px solid var(--fondo-blanco)',
      },
    }),
  width: 200,
  span: {
    all: 'unset',
    fontFamily: 'Gilroy-Regular !important',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
}))
const ButtonNext = props => {
  return (
    <ButtonMainOK loading={props.loadings} onClick={props.onClick} {...props}>
      {props.children}
    </ButtonMainOK>
  )
}
export default ButtonNext

import styled from '@emotion/styled'
import {Modal} from 'antd'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import AsideLogo from '../../components/Aside/AsideLogo.js'
import ButtonNext from '../../components/Button/ButtonNext.js'
import InputError from '../../components/Calculator/InputError.js'
import Result from '../../components/Calculator/Result.js'
import TagPickerItem from '../../components/Calculator/TagPickerItem.js'
import Label from '../../components/Calculator/label'
import Text from '../../components/Typography/Text.js'
import {saveQuoterDate, sendInfoPhone} from '../../store/actions/phoneAction.js'
import {SYSTEM_TYPES} from '../../utils/Constants.js'
import {openNotificationWithIcon} from '../../utils/Functions.js'
import info from './../../assets/icons/info.png'
import {SCREENS} from './screens'
// import {RESET_TRIGGER_BAD_STEP} from '../../constrants/reducers.js'
import IntlCurrencyInput from 'react-intl-currency-input'
import {logsOnboarding} from '../../store/actions/requestCard.actions.js'
const inputDefaultValue = 0.0
const currencyConfig = {
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}
const CotizadorPhone = props => {
  const dispatch = useDispatch()
  const {loadSendPrice, getDataPhone, onboardingPhone} = useSelector(
    state => state.Phone,
  )
  const {activityType} = useSelector(state => state.RequestCardReducer.form)
  const [price, setPrice] = useState(null)
  const [InitialPayment, setInitialPayment] = useState(null)
  const [InitialQuote, setInitialQuote] = useState(null)
  const [Quotes, setQuotes] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [disabled, setDisabled] = useState('')
  const [inputError, setInputError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [month, setMonth] = useState(0)
  const {Id, Employment} = useSelector(state => state.Phone?.json)
  const {onboardingPhoneActive} = useSelector(
    state => state.Phone?.onboardingActive,
  )
  useEffect(() => {
    if (getDataPhone && getDataPhone.calc.length > 0) {
      setInitialPayment(getDataPhone.calc[0])
      setQuotes(getDataPhone.calc[0].quote)
      if (month !== 0) {
        setInitialQuote(
          getDataPhone.calc[0].quote.find(item => item.limit === month),
        )
      } else {
        setInitialQuote(getDataPhone.calc[0].quote[0])
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataPhone])

  const handleOnValueChange = (event, value, maskedValue) => {
    event.preventDefault()
    if (!value) {
      setSearchTerm('')
      setInputError(null)
      setDisabled(true)
      return
    }
    const val = value
    const num = val
    if (num <= 0) {
      setInputError(`El valor debe ser superior a $0`)
      setDisabled(true)
    } else {
      setInputError(null)
      setDisabled(false)
    }
    if (value.length > 2) {
      setSearchTerm(parseFloat(searchTerm).toFixed(2))
      return
    } else {
      setSearchTerm(num)
    }
  }

  const onSucces = success => {
    setLoading(false)
    if (success) {
      setOpenModal(true)
    }
  }

  const onSearchTerm = () => {
    if (!searchTerm) {
      setInputError(`El valor debe ser superior a $0`)
    }
    const price = parseFloat(searchTerm)
    if (!isNaN(price) || price > 0) {
      setPrice(price)
      dispatch(
        sendInfoPhone(
          onboardingPhone.id,
          onboardingPhone.origin,
          price,
          onboardingPhoneActive
            ? Employment[0]?.TypeWorkActivity
            : activityType,
        ),
      )
    }
  }

  useEffect(() => {
    dispatch({type: 'GET_PHONE_DATA_CLEAN'})
    // dispatch(checkStep(8))
  }, [])

  const onCheck = async () => {
    setLoading(true)
    try {
      if (searchTerm !== price) {
        openNotificationWithIcon('error', 'Debes volver a cotizar el teléfono')
        throw new Error()
      }
      if (!searchTerm) {
        openNotificationWithIcon('error', 'El precio no puede estar vacio')
        throw new Error()
      }
      if (!getDataPhone) {
        openNotificationWithIcon('error', 'Debes calcular la cotización')
        throw new Error()
      }
      const payload = {
        OnboardingId: onboardingPhoneActive ? Id : onboardingPhone?.id,
        subTotal: getDataPhone.subtotal,
        Total: InitialPayment.total,
        Fee: InitialPayment.fee,
        ValueFee: InitialPayment.valueFee,
        amountToFinance: InitialPayment?.amountToFinance,
        cashierPayment: InitialPayment?.cashierPayment,
        UniqueCode: InitialQuote.uniqueCode,
        Price: price,
        quote: InitialQuote.limit,
        quoteValue: InitialQuote.quoteValue,
        CreatedBy: SYSTEM_TYPES.CMF,
        ModifiedBy: SYSTEM_TYPES.CMF,
      }
      dispatch(
        saveQuoterDate(
          onboardingPhone.id,
          onboardingPhone.origin,
          payload,
          onSucces,
        ),
      )
    } catch (errorInfo) {
      logsOnboarding(
        onboardingPhone?.id,
        onboardingPhone?.origin,
        'COTIZADOR',
        errorInfo,
        `${price}`,
        false,
      )
    }
    setLoading(false)
  }

  return (
    <Container>
      <ModalMain
        centered
        maskClosable={false}
        open={openModal}
        footer={false}
        onCancel={() => setOpenModal(false)}>
        <div
          style={{
            widows: '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Img src={info} />
          <Text
            textType="paragraphs"
            style={{margin: 0, lineHeight: 1, textAlign: 'center !important'}}>
            Para continuar el proceso
          </Text>
          <Text
            textType="paragraphs"
            style={{textAlign: 'center !important', marginTop: 0}}>
            solicita ayuda a un vendedor
          </Text>
          <ButtonNext
            background="green"
            border="green"
            data-testid="btn-help"
            style={{height: 60, width: 200, marginLeft: 6}}
            onClick={() => props.goToStep(SCREENS.EnrollDevice)}>
            Continuar
          </ButtonNext>
        </div>
      </ModalMain>

      <Wrapper style={{flex: 0.2, background: 'rgb(41, 164, 56)'}}>
        <AsideLogo
          positionX="left"
          positionY="top"
          color="white"
          hugColor="#FFF"
        />
      </Wrapper>

      <Wrapper style={{flex: 1, height: '100%'}}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignSelf: 'center',
            maxWidth: 1080,
            padding: 45,
          }}>
          <InputMainContainer>
            <InputWrapper>
              <Label
                step="1"
                title="Ingresa el precio del celular que deseas"
              />
              <Input
                placeholder="Valor"
                currency="BRL"
                // disabled={Price}
                data-testid="price-phone"
                // allowNegativeValue={false}
                // decimalsLimit={2}
                onValueChange={handleOnValueChange}
                // type="number"
                value={searchTerm}
                config={currencyConfig}
                max="10000"
                defaultValue={inputDefaultValue}
                // prefix="$"
                // allowNegativeValue={false}
                // decimalsLimit={2}
                // allowDecimals={true}
                onChange={handleOnValueChange}
                // onValueChange={(e) => handleOnValueChange(e.target.value) }
              />
              {inputError && (
                <InputError style={{marginLeft: 35}} message={inputError} />
              )}
            </InputWrapper>
            {/* {getDataPhone && (
              <InputWrapper>
                <Label step="2" title="¿Deseas hacer un abono inicial?" />
                <TagsWrapper style={{paddingBottom: 8}}>
                  {getDataPhone.calc.map((data, index) => (
                    <TagPickerItem
                      selected={data.fee === InitialPayment?.fee}
                      key={index.toString()}
                      text={`${data.fee}%`}
                      onClick={() => {
                        setInitialPayment(getDataPhone.calc[index])
                        setInitialQuote(
                          getDataPhone.calc[index].quote[
                            getDataPhone.calc[index].quote.findIndex(
                              q => q.limit === InitialQuote.limit,
                            )
                          ],
                        )
                        setQuotes(getDataPhone.calc[index].quote)
                      }}
                    />
                  ))}
                </TagsWrapper>
                {InitialPayment && (
                  <InputMessage
                    style={{marginLeft: 35}}
                    message={`Has elegido un abono inicial de: ${format(
                      InitialPayment.valueFee,
                    )}`}
                  />
                )}
                {InitialPayment && (
                  <InputMessage
                    style={{marginLeft: 35}}
                    // message={`Pago en caja (${
                    //   InitialPayment.fee
                    // }% del teléfono +\n diferencia con límite financiamiento ($350)) = ${format(
                    //   InitialPayment.cashierPayment,
                    // )}`}
                    message={`Pago en caja: ${format(
                      InitialPayment.cashierPayment,
                    )}`}
                  />
                )}
              </InputWrapper>
            )} */}
            {getDataPhone && Quotes && (
              <InputWrapper>
                <Label step="2" title="Selecciona un plazo de pago" />
                <TagsWrapper
                  style={{
                    maxWidth: 400,
                  }}>
                  {Quotes?.map((data, index) => {
                    return (
                      <TagPickerItem
                        selected={data.uniqueCode === InitialQuote?.uniqueCode}
                        key={index.toString()}
                        text={`${data.limit} cuotas`}
                        onClick={() => {
                          setInitialQuote(
                            Quotes[
                              Quotes.findIndex(
                                q => q.uniqueCode === data.uniqueCode,
                              )
                            ],
                          )
                          setMonth(data.limit)
                        }}
                      />
                    )
                  })}
                </TagsWrapper>
              </InputWrapper>
            )}
            <InputWrapper style={{alignItems: 'center'}}>
              <ButtonNext
                disabled={disabled}
                loading={loadSendPrice}
                type="primary"
                background="green"
                border="green"
                data-testid="btn-cotizador"
                onClick={onSearchTerm}>
                Cotiza tu celular
              </ButtonNext>
            </InputWrapper>
          </InputMainContainer>
          <DataContainer>
            {getDataPhone && (
              <Result
                loading={loading}
                onSend={() => onCheck()}
                data={{
                  price: price,
                  itbms: getDataPhone?.taxItbms,
                  initial: InitialPayment?.valueFee,
                  amountToFinance: InitialPayment?.amountToFinance,
                  financing: InitialQuote ? InitialQuote.financing : 0,
                  quotas: InitialQuote ? InitialQuote.limit : 0,
                  quoteValue: InitialQuote ? InitialQuote.quoteValue : 0,
                  InitialPayment: InitialPayment?.cashierPayment,
                  priceEnter: searchTerm,
                }}
              />
            )}
          </DataContainer>
        </div>
      </Wrapper>
    </Container>
  )
}
const TagsWrapper = styled('div')(() => ({
  display: 'flex',
  // flex: 1,
  whiteSpace: 'nowrap',
  flexDirection: 'row',
  marginLeft: 35,
}))
const InputMainContainer = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: '0px 35px',
  marginRight: 16,
}))

const Input = styled(IntlCurrencyInput)(() => ({
  all: 'unset',
  // width: '100%',
  height: 14,
  padding: 14,
  marginLeft: 35,
  marginTop: 8,
  borderColor: '#aaa',
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 3,
}))

const InputWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 45,
}))

const Img = styled('img')(() => ({
  width: 50,
  marginBottom: 24,
}))

const DataContainer = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  marginTop: 45,
  marginLeft: 16,
  // justifyContent: 'center',
}))

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const ModalMain = styled(Modal)(() => ({
  width: '50% !important',
  '.ant-modal-content': {
    // width: '50vw',
    borderRadius: '0px !important',
    height: 'calc(60vh - 20px) !important',
    margin: 'auto',
  },
  '.ant-modal-body': {
    margin: ' auto',
    display: ' flex',
    height: ' 100%',
    justifyContent: ' center',
    alignItems: ' center',
  },
  '.ant-modal-centered .ant-modal': {
    width: '50% !important',
  },
}))

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}))

export default CotizadorPhone

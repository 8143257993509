import produce from 'immer';
import {LOADING_HIDE, LOADING_SHOW} from '../../constrants/reducers';

// type initStateProps = {
//   active: boolean,
//   text: string,
//   styleBlock: string,
//   source: string,
// };

const initState = {
  active: false,
  text: '',
  styleBlock: '',
  source: '',
};

const LoadingReducer = (state = initState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOADING_SHOW:
        draft.active = true;
        draft.text = action.text;
        draft.styleBlock = action.styleBlock;
        draft.source = action.source;
        break;
      case LOADING_HIDE:
        draft.active = false;
        draft.text = '';
        draft.styleBlock = '';
        draft.Lottie = '';
        draft.source = '';
        break;
      default:
        return state;
    }
  });

export default LoadingReducer;

import {Layout} from 'antd'
import React from 'react'
import logoChubb from '../../assets/images/logos/topLogoChubb.png'
import logoCMF from '../../assets/images/onboarding/logo-cmf.webp'
import './headerStyle.scss'
const {Header} = Layout
/**
 * header del onboarding de la aplicación
 * @param {*} props
 * @returns
 */
export default function HeaderComponent(props) {
  const {name} = props

  switch (name) {
    case 'instrucciones':
      return <Header className="instruccionesHead"> </Header>
    case 'verificacion':
      return <Header className="verificacionHead"> </Header>
    case 'felicidades':
      return <Header className="felicidadesHead"> </Header>
    case 'paso1':
      return <Header className="paso1Head"> </Header>
    case 'paso2':
      return <Header className="paso2Head"> </Header>
    case 'paso3':
      return <Header className="paso3Head"> </Header>
    case 'paso4':
      return <Header className="paso4Head"> </Header>
    case 'apc':
      return <Header className="apcHead"> </Header>
    case 'chubb':
      return (
        <Header className="chubbHead">
          <img className="logo_cmf" src={logoChubb} alt='Logo Chubb' />{' '}
        </Header>
      )
    case 'pdf':
      return <Header className="pdfHead"> </Header>
    default:
      return (
        <Header className="headerStyled">
          <img className="logo_cmf" src={logoCMF} alt='Logo CMF' />
        </Header>
      )
  }
}

export const SCREENS = {
  WelcomeScreenComponent: 1,
  ScanID: 2,
  IdentityConfirmed: 3,
  VerifyInfo: 4,
  PersonalInformation: 5,
  ProfessionalInformation: 6,
  PoliticalInfoForm: 7,
  EnrollDevice: 8,
  ApcTerms: 9,
  CotizadorPhone: 10,
  FilePDF: 11,
  FinancialError: 12,
  RequestInRevision: 13,
  Congrats: 14,
  ResultEvaluation: 15,
  ThanksError:16,
  ActividadLaboral:17,
}

import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import PermCameraMicIcon from '@mui/icons-material/PermCameraMic';
import TungstenIcon from '@mui/icons-material/Tungsten';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useMemo } from 'react';
import { FaRegAddressCard } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import phoneMail from 'src/assets/images/onboardingCredito/PhoneMail.webp';
import {
  countryBirth,
  countryBirthTranslate,
  getActivityTypes,
  getCountries,
  getGenders,
  getMaritalStatus,
  getNacionalizacion,
  getNationalities,
  getOccupations,
  getProfessions,
} from 'src/store/actions/requestCard.actions';
import { BreakPointTheme } from 'src/theme/breakpoints';
import { setStep } from '../../store/actions/Steps.Action';
import LayoutForm from './Layout/LayoutForm';
import LayoutPageForm from './Layout/LayoutPageForm';
import SCREENS from './screens';

const TextSub = styled('p')(({theme}) => ({
  fontFamily: 'Red Hat Text',
  fontSize: '25px',
  marginBottom: 0,

  [theme.breakpoints.down('md')]: {
    marginLeft: '0.5rem',
    fontSize: '18px',
  },
}));

const Img = styled('img')(() => ({
  color: '#fff',
  width: '31px',
  margin: 'auto',
  // marginRight: '1.5rem',
  [BreakPointTheme.breakpoints.down('sm')]: {
    marginRight: '0.3rem',
    width: '21px',
    height: '1rem',
    margin: 'auto',
  },
}));

const items = [
  {
    title: 'Conéctate a una red de internet con buena velocidad.',
    icon: <NetworkCheckIcon />,
    alt: 'Card',
    contentType: 'icon',
  },
  {
    title: 'Ubícate en un lugar con una buena iluminación.',
    icon: <TungstenIcon />,
    alt: 'Phone',
    contentType: 'icon',
  },
  {
    title: 'Danos permiso para acceder a la cámara de tu dispositivo.',
    icon: <PermCameraMicIcon />,
    alt: 'MicCam',
    contentType: 'icon',
  },
  {
    title: 'Ten a mano tu cédula y asegúrate que esté en buen estado.',
    icon: (
      <FaRegAddressCard
        style={{
          width: '1.5rem',
          height: '1.5rem',
        }}
      />
    ),
    alt: 'Identity',
    contentType: 'icon',
  },
  {
    title: 'Asegúrate de tener acceso a tu celular y correo electrónico.',
    icon: phoneMail,
    alt: 'PhoneMail',
    contentType: 'image',
  },
];

const Recommendation = props => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => [
      getProfessions(),
      getOccupations(),
      getActivityTypes(),
      getGenders(),
      getMaritalStatus(),
      getCountries(),
      getNationalities(),
      countryBirth(),
      countryBirthTranslate(),
      getNacionalizacion(),
    ],
    [],
  );

  useEffect(() => {
    actions.forEach(action => dispatch(action));
  }, [dispatch, actions]);

  useEffect(() => {
    try {
      if (window?.dataLayer)
        window.dataLayer.push({
          event: 'stepComplete-page3',
          step: '1',
        });
    } catch (error) {
      console.warn({error});
    }

    // Crear un nuevo script element
    const script = document.createElement('script');

    // Establecer el src del script para apuntar al script externo
    script.src = 'https://cdn.seondf.com/js/v5/agent.js';
    script.async = true;

    // Añadir el script al body
    document.body.appendChild(script);

    // Opcionalmente, puedes limpiar al desmontar el componente
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const renderImage = ({icon, alt, contentType}) => {
    if (contentType === 'icon') {
      return icon;
    } else if (contentType === 'image') {
      return (
        <Img
          src={icon}
          alt={alt}
          style={{
            height: '1.5rem',
            width: '1.5rem',
          }}
        />
      );
    } else {
      return null; // O manejar un caso por defecto según tu lógica
    }
  };

  const renderItems = () => {
    return items.map((item, index) => (
      <Grid
        container
        sx={{
          [BreakPointTheme.breakpoints.up('900')]: {
            gap: '10px',
          },
          [BreakPointTheme.breakpoints.up('lg1')]: {
            gap: '12px',
          },
        }}
        spacing={0}
        justifyContent="center"
        alignItems="center"
        gap={0.5}>
        <Grid container item spacing={0} key={index} mb={'25px'}>
          <Grid
            item
            xs={2}
            md={1}
            xl={1}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {renderImage(item)}
          </Grid>
          <Grid item xs={10} md={11} xl={11}>
            <TextSub>{item.title}</TextSub>
          </Grid>
        </Grid>
      </Grid>
    ));
  };

  const handleContinueClick = () => {
    dispatch(setStep(SCREENS.VerifyMailPhone));
    props.goToStep(SCREENS.VerifyMailPhone);
  };

  const handleBackClick = () => {
    dispatch(setStep(SCREENS.Welcome));
    props.goToStep(SCREENS.Welcome);
  };

  return (
    <LayoutPageForm>
      <LayoutForm
        hasNextButton={true}
        hasBackButton={true}
        handleSubmit={handleContinueClick}
        handleBackSubmit={handleBackClick}
        props={props}
        data="rcm">
        <Box
          // marginTop={{xs: '50px', sm: '100px', lg: '140px '}}
          sx={{
            '@media (max-width: 399px)': {
              marginTop: '10px',
            },
            '@media (min-width: 400px) and (max-width: 599px)': {
              marginTop: '50px',
            },
            ' @media (min-width: 600px) and (max-width: 960px)': {
              /* Small (sm) */
              marginTop: '100px',
            },
            '@media (min-width: 960px)': {
              /* Large (lg) */
              marginTop: '100px',
            },
            '@media (min-width: 1280px)': {
              /* Large (lg) */
              marginTop: '140px',
            },
          }}>
          <h3 className="pt-3 lg:text-5xl text-sans text-3xl md:text-3xl md:text-left text-center text-[#011E41]">
            Sigue estas recomendaciones antes de comenzar
          </h3>
          {renderItems()}
        </Box>
      </LayoutForm>
    </LayoutPageForm>
  );
};

export default Recommendation;

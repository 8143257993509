import React from 'react';
import { Modal } from 'react-responsive-modal';

const ModalRender = ({
  backClassName,
  backButtonImage,
  bodyClassName,
  bodyProps,
  classNames,
  closeButtonImage,
  closeClassName,
  children,
  isOpen,
  modalProps,
  onHide,
  showBackButton,
  showCloseButton,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onHide}
      classNames={classNames}
      center
      closeOnOverlayClick={false}
      {...modalProps}>
      {showCloseButton && (
        <div role='button' tabIndex={'0'} onClick={onHide} className={closeClassName}>
          <img src={closeButtonImage} alt="Close" />
        </div>
      )}
      {showBackButton && (
        <div role='button' tabIndex={'1'} onClick={onHide} className={backClassName}>
          <img src={backButtonImage} alt="Back" />
        </div>
      )}
      <div className={bodyClassName} {...bodyProps}>
        {children}
      </div>
    </Modal>
  );
};

export default ModalRender;

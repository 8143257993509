import produce from 'immer';
import {CHANGE_LOCALE_LANGUAGE} from '../../constrants/reducers';

export const initState = {
  locale: 'es',
};

const language = (state = initState, action) =>
  produce(state, draft => {
    if (action.type  === CHANGE_LOCALE_LANGUAGE) {
      draft.locale = action.locale;
    } else {
      return state;
    }
  });

export default language;

import axios from 'axios';
import { APP } from '../utils/Constants';
import { API_KEY, Cmf_Base, EID_Base, WALLET_API } from './Api';
const Client = axios.create({baseURL: Cmf_Base});
const ClientWallet = axios.create({baseURL: WALLET_API});
const EID = axios.create({baseURL: EID_Base});
const headers = {
  headers: {
    Apikey: API_KEY,
    'content-type': 'application/json',
  },
};
const headerEID = {
  headers: {
    Authorization: APP.electronicId.tokenAuth,
    'content-type': 'application/json',
  },
};

export const checkMobileBalance = (identityNumber, phoneNumber) =>
  Client.get(
    `api/Customers/GetInfoBalanceByPhoneAndIdentityNumber/${identityNumber}/${phoneNumber}`,
    headers,
  );
export const checkDeviceId = deviceId =>
  Client.post(
    `api/nuovopay/device`,
    {deviceId: deviceId, createdBy: 'CMF'},
    headers,
  );

export const validateReferredCode = code =>
  Client.get(`api/referral/getReferrerByCode?code=${code}`, headers);

export const checkDeviceImei = (imei, onboardingId) =>
  Client.get(`api/onboarding/${onboardingId}/device-status/${imei}`, headers);

export const getIdentityId = identityId =>
  EID.get(
    `${APP.electronicId.endpoints.getIdentityId}?nif=${identityId}`,
    headerEID,
  );

export const createOnsite = payload =>
  EID.post(`${APP.electronicId.endpoints.onsite}`, payload, headerEID);

export const checkIdentityTE = (identityNumber, base64, origin, id, fullName) =>
  Client.post(
    `api/ElectoralTribunal/BiometricVerification`,
    {
      IdentityNumber: identityNumber,
      FacePic: base64,
      Origin: origin,
      OnboardingId: id,
      FullName: fullName,
    },
    headers,
  );

export const WalletClient = payload =>
  ClientWallet.post(`api/metrobank/payment`, payload);

export const riskAssessment = async (id, origin) => {
  Client.post(
    `api/onboarding/OnbEvaluationCustomerRiskLevel?onboarding=${id}&origin=${origin}`,
    {},
    headers,
  );
};

export const sendSmsService = payload =>
  EID.post(`${APP.electronicId.endpoints.signatureSms}`, payload, headerEID);

export const verifySmsService = payload =>
  EID.post(`${APP.electronicId.endpoints.signSms}`, payload, headerEID);

export const sendCallService = payload =>
  EID.post(`${APP.electronicId.endpoints.signatureCall}`, payload, headerEID);

export const verifyCallService = payload =>
  EID.post(`${APP.electronicId.endpoints.signByCall}`, payload, headerEID);

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { CameraOutlined } from '@ant-design/icons'
import { Button, Col, Input, Modal, Row, message } from 'antd'
import axios from 'axios'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { API_KEY, Cmf_Base } from '../../Data/Api'
import AsideLogo from '../../components/Aside/AsideLogo'
import ButtonNext from '../../components/Button/ButtonNext'
import ElectronicaID from '../../components/ElectronicaID'
import { ELECTRONIC_SCAN_TYPE } from '../../components/ElectronicaID/index'
import FooterOnboar from '../../components/Footer/FooterOnboarding'
import Text from '../../components/Typography/Text'
import { setStep } from '../../store/actions/Steps.Action'
import {
  onboardingActive,
  verifyBatchOnboarding,
} from '../../store/actions/phoneAction'
import {
  UpdateOnboardingCrePre,
  editFormRequest,
  setBase64,
  setRequestVideoId
} from '../../store/actions/requestCard.actions'
import { APP, COUNTRIES_TYPES } from '../../utils/Constants'
import {
  ConsoleHelper,
  openNotificationWithIcon
} from '../../utils/Functions'
import { OnCompleteVideo } from '../../utils/onCompleteVideo'
import { VideoFake } from './../CardRequest/fake/videoComplete'
import './../CardRequest/styles/form-style.scss'
import './../CardRequest/styles/index.scss'
import './../CardRequest/styles/scan.scss'
import { fieldType, fieldTypeOptions, formFields } from './FormRequest.config'
import { SCREENS } from './screens'

const FormItem = Form.Item
message.config({
  top: 30,
  duration: 4,
  maxCount: 1,
})
const CustomItem = styled(FormItem)(() => ({
  '.has-error .ant-legacy-form-explain, .has-error .ant-legacy-form-split': {
    color: 'white',
  },
}))
const InputMain = styled(Input)(() => ({
  '&.ant-input': {
    height: ' 3.5rem !important',
    padding: ' 0 11px',
  },
  '@media (min-width: 1201px) and (max-width: 1535px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 901px) and (max-width: 1200px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 601px) and (max-width: 900px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '4em !important',
    },
  },
  '@media (max-width:600px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '3rem !important',
    },
  },
}))
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}))
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))
const DivFooterBotton = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignContent: 'center',
  width: '100%',
  margin: 'auto',
}))

const StyleModal = styled(Modal)(() => ({
  '.ant-modal-content': {
    borderRadius: '0px !important',
  },
}))

class ScanIDDefault extends Component {
  constructor(props) {
    super(props)

    this.state = {
      width: 0,
      height: 0,
      visible: false,
      identityType: null,
      initIdentity: null,
      identityNumbers: null,
      nextActive: false,
      failIdentity: false,
      error: null,
      browser: false,
      loadingUpt: false,
      accessCamera: false,
      windowHeight: undefined,
      windowWidth: undefined,
      nationality: '',
      dimensions: {
        width: 0,
        height: 0,
      },
      isFake: APP.fakeScan,
      ruta: null,
      formFields: {
        identity: {
          ...formFields.identity,
          // value: this.props.formRequest.identity,
        },
      },
    }
    this.handleClick = this.handleClick.bind(this)
  }

  componentWillMount() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent,
      )
    ) {
      this.setState({ isMobile: true })
    }
  }

  handleResize = () =>
    this.setState({
      windowHeight: window.innerHeight * 0.7,
      windowWidth: window.innerWidth * 0.9,
    })

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  async componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
    this.setState({
      identityType: null,
      identityNumbers: null,
      dimensions: {
        width: window.innerWidth * 0.8,
        height: window.innerHeight * 0.7,
      },
    })

    // Fake simulator scan completed for electronic ID
    await new Promise(resolve => setTimeout(resolve, 10000)) // Wait for 10 seconds

    if (this.state.isFake) {
      const { countriesData, nationalitiesCatalog } = this.props
      let fake = await OnCompleteVideo(
        VideoFake,
        nationalitiesCatalog,
        countriesData,
        this.state.initIdentity,
        this.props,
      )
      if (fake !== null) {
        this.props.setRequestVideoId(fake.video.id)
        this.props.EditForm(fake.formDataRequest)
        // this.props.UpdataAndVerifyIdentity(this.OnContinue.bind(this))
        // this.props.verifyBatchPre(personalNumber, this.onNext.bind(this))
        this.props.setBase64(fake.base64)
        this.props.verifyBatchPre(
          fake.personalNumber,
          this.onNext.bind(this),
          fake.typeIdentity,
        )
      } else {
        this.setState({ nationality: null })
        this.onNext(4)
      }
    }
  }

  nextStep() {
    this.props.nextStep()
  }

  getGender(gender) {
    try {
      const masculin = fieldTypeOptions.MASCULIN
      const femenin = fieldTypeOptions.FEMENIN
      ConsoleHelper('GENDER----->', gender)
      if (gender === 'M') {
        return masculin
      } else if (gender === 'F') {
        return femenin
      }
      return null
    } catch (e) {
      return null
    }
  }

  getNationality(nationality) {
    try {
      if (this.state.identityType === ELECTRONIC_SCAN_TYPE.CEDULA_NACIONAL)
        return COUNTRIES_TYPES.PANAMA
      let filter = this.props.nationalitiesCatalog.filter(
        nat => nationality.toLowerCase() === nat.label.toLowerCase(),
      )
      if (filter.length > 0) return filter[0].value
      return null
    } catch (error) {
      return null
    }
  }

  getCountry(country) {
    // try {
    //   if (this.state.initIdentity !== ('P' || 'N' || 'E'))
    return COUNTRIES_TYPES.PANAMA
    // let filter = this.props.countriesCatalog.filter(
    //   ct => country.toLowerCase() === ct.label.toLowerCase(),
    // );
    // if (filter.length > 0) return filter[0].value;
    // return null;
    // } catch (error) {
    //   return null;
    // }
  }

  async uploadFileTestPhone(name, fbase64) {
    const base64 = fbase64
    const blob = await (await fetch(base64)).blob()
    const formData = new FormData()
    const _file = new File([blob], name)
    formData.append('SourceFile', _file)
    formData.append('FileIdentifier', name)

    const res = await axios.post(`${Cmf_Base}api/blob/upload`, formData, {
      headers: {
        Apikey: API_KEY,
        'Content-Type': 'multipart/form-data',
      },
    })

    const file_upload = res?.data?.fileUrl
    if (file_upload) {
      return file_upload
    } else {
      return false
    }
  }
  nextStep1() {
    this.props.goToStep(SCREENS.ProductExist)
  }
  nextStepCredi() {
    this.props.goToStep(SCREENS.IdentityConfirmed)
  }
  prevStepInitial() {
    this.props.goToStep(SCREENS.ScanID)
  }
  nextStepOnboardingActive() {
    this.props.goToStep(SCREENS.CotizadorPhone)
  }

  async onNext(success) {
    switch (success) {
      case true:
        message.success('Identificación aceptada')
        this.props.goToStep(SCREENS.IdentityConfirmed)
        break
      case false:
        this.props.goToStep(SCREENS.RequestInRevision)
        break
      case 4:
        this.setState({ visible: false, failIdentity: true })
        break
      case 5:
        this.props.goToStep(SCREENS.RequestInRevision)
        break
      case 6:
        this.setState({ visible: success === 6, failIdentity: success === 4 })
        this.prevStepInitial()
        break
      default:
        // Handle other cases if needed
        break
    }
  }
  /**
   * Modal para cuando la personalNumber escaneado no conicide con el numero introducido
   * @returns
   */
  renderModalFailID() {
    const abrirCorreo = () => {
      const correo = 'servicioalcliente@cmf.com.pa'
      const subject = encodeURIComponent(
        'La nacionalidad no está en el catálogo',
      )
      const body = encodeURIComponent(`Hola servicio al cliente CMF,
  
  Mi nacionalidad no está en el catálogo y no puedo obtener la tarjeta digital CMF.
  
  Gracias`)

      window.open(`mailto:${correo}?subject=${subject}&body=${body}`);
    }
    const handleEmail = () => {
      if (this.state.nationality !== null) {
        this.setState({ failIdentity: false, visible: true })
      } else {
        abrirCorreo()
      }
    }
    return (
      <StyleModal
        centered
        visible={this.state.failIdentity}
        destroyOnClose={true}
        onOk={() => { }}
        wrapClassName="invalid__Identity"
        onCancel={() => this.setState({ failIdentity: false })}
        okButtonProps={{ disabled: true }}
        footer={null}>
        <div className="icon__info"></div>
        <br></br>
        <p
          className="reject_text"
          style={{ textAlign: 'center', fontSize: '16px' }}>
          {this.state.nationality === null
            ? 'La nacionalidad capturada no se encuentra identificada en nuestro catálogo. '
            : 'El número de cédula ingresado no coincide con el número capturado enel proceso de validación.'}
        </p>
        <p
          className="reject_text_second"
          style={{ textAlign: 'center', fontSize: '16px' }}>
          {this.state.nationality === null
            ? 'Envíanos un correo con este detalle dando click al siguiente botón.'
            : ' Por favor intenta de nuevo el proceso de validación'}
        </p>

        <div className="btnVerify" style={{ padding: '2rem 0' }}>
          <ButtonNext
            loading={this.state.loadingSignButton}
            disabled={false}
            background="white"
            border="green"
            onClick={() => this.setState({ failIdentity: false })}>
            Cancelar
          </ButtonNext>
          <ButtonNext
            loading={this.state.loadingSignButton}
            disabled={false}
            background="green"
            border="green"
            onClick={handleEmail}>
            {this.state.nationality === null ? 'Reporta a CMF' : 'Reintentar'}
          </ButtonNext>
        </div>
        <Text
          textType="paragraphs"
          style={{
            textAlign: 'center',
            fontSize: '0.8rem',
            fontFamily: 'Nunito',
            lineHeight: 1.2,
            marginTop: 35,
            marginBottom: 0,
          }}>
          <strong>¿Necesitas asistencia en tu proceso?</strong>
          <br />
          Solicita ayuda de un vendedor o
          <br />
          contáctanos a servicioalcliente@cmf.com.pa
        </Text>
      </StyleModal>
    )
  }
  /**
   * Modal de acceso a camara(no hace ninguna funcion solo es para mostrar al usurio que debe dar acceso a la camara)
   */
  renderModalAccessCamera() {
    return (
      <>
        {this.renderModal()}
        <Modal
          centered
          visible={this.state.accessCamera}
          destroyOnClose={true}
          onOk={() => { }}
          wrapClassName="invalid__Identity"
          onCancel={() => this.setState({ accessCamera: false })}
          okButtonProps={{ disabled: true }}
          footer={null}>
          <div className="icon__camera">
            <CameraOutlined style={{ fontSize: '41px', color: '#13A438' }} />
          </div>
          <br></br>
          <p className="reject_text">
            Permite el acceso a la cámara de tu dispositivo <br></br> Este paso
            es requerido para que puedas tomarte una foto y realizar la
            verificación biométrica.
          </p>
          <div className="btnVerify">
            <ButtonNext
              background="white"
              border="green"
              loading={this.state.loadingSignButton}
              onClick={() => this.setState({ accessCamera: false })}>
              Cancelar
            </ButtonNext>
            <Button
              loading={this.state.loadingSignButton}
              disabled={false}
              className="btnNo"
              size="large"
              onClick={() =>
                this.setState({ accessCamera: false, visible: true })
              }>
              Aceptar
            </Button>
          </div>
        </Modal>
      </>
    )
  }

  async onCompleteVideo(video) {
    const { countriesData, nationalitiesCatalog } = this.props
    let response = await OnCompleteVideo(
      video,
      nationalitiesCatalog,
      countriesData,
      this.state.initIdentity,
      this.props,
    )
    this.props.EditForm(response.formDataRequest)
    this.props.setRequestVideoId(response.video.id)
    this.props.verifyBatchPre(response.personalNumber, this.onNext.bind(this))
    this.props.setBase64(response.base64)
  }

  onErrorVideo(err) {
    if (err.display) {
      this.setState({
        visible: false,
        failIdentity: true,
        error: { message: err.message },
      })
    } else {
      const notification = err?.notification
      const errorMessage =
        `Sistema biométrico, ${notification?.data?.texts?.title ?? err?.message ?? "error al escanear documento, favor intenta nuevamente."}`;

      message.error(errorMessage)
    }
  }

  searchValue(value) {
    if (value === 'E') {
      return ELECTRONIC_SCAN_TYPE.CEDULA_EXTRANJERA
    } else {
      return ELECTRONIC_SCAN_TYPE.CEDULA_NACIONAL
    }
  }

  renderModal() {
    return (
      <>
        <Modal
          centered
          wrapClassName="container-scan"
          open={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
          width={!this.state.isMobile ? this.state.windowWidth : null}
          maskClosable={false}
          destroyOnClose={true}
          footer={
            <DivFooterBotton>
              <ButtonNext
                background="white"
                border="green"
                loading={this.state.loadingSignButton}
                onClick={() => this.setState({ visible: false })}>
                Cancelar
              </ButtonNext>
              <ButtonNext
                background="green"
                border="white"
                loading={this.state.loadingSignButton}
                disabled={true}
                onClick={() => { }}>
                Aceptar
              </ButtonNext>
            </DivFooterBotton>
          }>
          <div className="scan-header">
            <p>
              Acerca tu cédula de identidad a la pantalla para ser escaneada
            </p>
          </div>
          <div className="scan-devices-container">
            <ElectronicaID
              keyAuth={APP.electronicId.tokenAuth}
              documentType={this.state.identityType}
              onComplete={this.onCompleteVideo.bind(this)}
              onError={this.onErrorVideo.bind(this)}
              height={
                this.state.windowHeight < 600 ? this.state.windowHeight : 600
              }
              width={this.state.windowWidth}
            />
          </div>
        </Modal>
      </>
    )
  }

  previousStep() {
    this.props.previousStep()
  }

  renderBackButton() {
    return this.props.canReturn ? (
      <ButtonNext
        background="green"
        border="white"
        onClick={this.previousStep.bind(this)}>
        Volver
      </ButtonNext>
    ) : (
      <div></div>
    )
  }
  async handleChange(e) {
    this.setState({ identityType: e.target.value.charAt(0) })
  }
  handleChangeInput = e => {
    this.setState({ identityNumbers: e.target.value.toUpperCase() })
  }
  onSuccess(success) {
    this.setState({ loadingUpt: false })
    if (success) {
      this.setState({
        visible: true,
      })
      // this.setState({visible: true});
    }
  }
  onRedirect(success) {
    if (success === true) {
      this.props.goToStep(SCREENS.CotizadorPhone)
    } else if (success === false) {
      if (this.state.identityNumbers != null) {
        this.setState({ initIdentity: this.state.identityNumbers.charAt(0) })
        this.setState({
          identityType: this.searchValue(this.state.identityNumbers.charAt(0)),
        })
        this.setState({ loadingUpt: true })
        this.props.LoadFalse(this.state.identityNumbers)
        this.props.UpdateOnboardingCrePre(this.onSuccess.bind(this))
      }

      //   this.onNext.bind(this)
    } else if (success === 1) {
      if (this.state.identityNumbers != null) {
        this.setState({ initIdentity: this.state.identityNumbers.charAt(0) })
        this.setState({
          identityType: this.searchValue(this.state.identityNumbers.charAt(0)),
        })
        this.setState({ loadingUpt: true })
        this.props.LoadFalse(this.state.identityNumbers)
        this.props.UpdateOnboardingCrePre(this.onSuccess.bind(this))
      }
    }
  }

  handleClick() {
    if (formFields.identity.rules[1].pattern.test(this.state.identityNumbers)) {
      this.props.onboardingActive(
        this.state.identityNumbers,
        this.onRedirect.bind(this),
      )
    } else {
      openNotificationWithIcon('error', formFields.identity.rules[1].message)
    }
  }
  handleChange(e) {
    e.target.value = e.target.value.toUpperCase()
    this.setState({
      identityNumbers: e.target.value.toUpperCase(),
    })
    // this.props.EditForm({ [e.target.id]: e.target.value });
  }
  _renderField(group, key) {
    const onchange = this.handleChange.bind(this)
    switch (formFields[key]?.type) {
      case fieldType.TEXT:
        return (
          <input
            className="input"
            required={this.state[group][key].required}
            type="text"
            id={key}
            onChange={onchange}
            onClick={this.state[group][key]?.handleClick}
            readOnly={this.state[group][key]?.readonly ? true : false}
            name={key}
            placeholder={this.state[group][key]?.placeholder || ''}
          />
        )
      default:
        return (
          <InputMain
            autoComplete="off"
            // className="input"
            type="text"
            style={{ width: '20rem', borderRadius: 3 }}
            required={this.state[group][key].required}
            id={key}
            onChange={onchange}
            onClick={this.state[group][key]?.handleClick}
            name={key}
            placeholder={this.state[group][key]?.placeholder || ''}
            readOnly={this.state[group][key]?.readonly ? true : false}
          />
        )
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <Container>
        {this.renderModalFailID()}
        {this.renderModal()}
        <Wrapper style={{ flex: 0.2 }}>
          <AsideLogo positionX="left" positionY="top" color="green" hugColor='#011E41' />
        </Wrapper>
        <Wrapper
          style={{
            flex: 1,
            background: 'rgb(41, 164, 56)',
            height: '100%',
            justifyContent: 'center',
          }}>
          <div className="main__verification">
            <H1>Verificación de identidad</H1>
            <Text
              textType="paragraphs"
              style={{
                textAlign: 'center !important',
                color: 'var(--fondo-blanco)',
                marginBottom: '1rem',
                marginTop: '1rem',
              }}>
              Ingresa tu número de cédula como en tu documento, <br></br>
              incluyendo los guiones.
            </Text>
            <Row>
              <Col flex={2}>
                <CustomItem key={'K_identity'}>
                  {getFieldDecorator(
                    'identity',
                    this.state.formFields['identity'].value
                      ? {
                        initialValue: this.state.formFields['identity'].value,
                        rules: formFields['identity']?.rules,
                      }
                      : { rules: formFields['identity']?.rules },
                  )(this._renderField('formFields', 'identity'))}
                </CustomItem>
              </Col>
            </Row>
          </div>
          <FooterOnboar
            back={this.renderBackButton()}
            next={
              <ButtonNext
                background="white"
                border="white"
                data-testid="next-scandid"
                loading={this.state.loadingUpt}
                //  disabled={!this.state.identityType}
                // onClick={() => this.setState({visible: true})}
                onClick={this.handleClick}>
                Continuar
              </ButtonNext>
            }
          />
        </Wrapper>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const { dateId, cedulaState } = state.RequestCardReducer
  return {
    onboardingPhoneActive: state.Phone.onboardingActive.onboardingPhoneActive,
    nationalitiesCatalog: state.RequestCardReducer.nationalitiesTranslate,
    countriesCatalog: state.RequestCardReducer.countries,
    // countriesBirthTranslate
    countriesData: state.RequestCardReducer.countriesBirthTranslate,
    dateId,
    cedulaState,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onboardingActive: (identity, active) =>
      dispatch(onboardingActive(identity, active)),
    EditForm: formFields => dispatch(editFormRequest(formFields)),
    setRequestVideoId: videoId => dispatch(setRequestVideoId(videoId)),
    verifyBatchPre: (identityNumber, onSuccess, typeIdentity) =>
      dispatch(verifyBatchOnboarding(identityNumber, onSuccess, typeIdentity)),
    LoadFalse: identityNumbers =>
      dispatch({ type: 'CEDULA_NUMBER', payload: identityNumbers }),
    UpdateOnboardingCrePre: onComplete =>
      dispatch(UpdateOnboardingCrePre(onComplete)),
    setStep: step => dispatch(setStep(step)),
    setBase64: (img) => dispatch(setBase64(img)),
  }
}

const ScanID = connect(mapStateToProps, mapDispatchToProps)(ScanIDDefault)

export default Form.create()((ScanID))

const H1 = styled.div`
  font-family: 'Gilroy', sans-serif;
  font-size: calc(16px + (800vw - 3200px) / 400) !important;
  font-weight: 300;
  line-height: 1.2em;
  color: var(--fondo-blanco);
  margin-bottom: 15px;
  @media (max-width: 768px) {
    font: normal normal 900 25px/21px Gilroy !important;
  }import { primaryNames } from './../../utils/Functions';

`

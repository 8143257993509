import {CircularProgress} from '@mui/material';
import React, {
  lazy,
  memo,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {useDispatch} from 'react-redux';
import TarjetaCredito from 'src/assets/images/logos/Tarjeta-digital.webp';
import TarjetaPrepago from 'src/assets/images/logos/Tarjeta-prepago.webp';
import TarjetaCreditoCutMin from 'src/assets/images/logos/tarjeta-card-cut-min.webp';
import TarjetaCreditoCut from 'src/assets/images/logos/tarjeta-card-cut.webp';
import TarjetaPrepagoCutMin from 'src/assets/images/logos/tarjeta-prepago-cut-min.webp';
import TarjetaPrepagoCut from 'src/assets/images/logos/tarjeta-prepago-cut.webp';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import {
  CreateOnboardingCrePre,
  CreateOnboardingPago,
} from 'src/store/actions/requestCard.actions';
import {
  BlockContainer,
  ButtonWelcome,
  CardText,
  CardTextPrepaid,
  ImagePhone,
  ListContainer,
  OnboardingContainer,
  TitleCardText,
} from './Welcome.style';
import preloadImage from './preloadImage';
preloadImage(TarjetaCredito);
preloadImage(TarjetaPrepago);
const ModalPersonalData = lazy(() => import('./Modals/ModalPersonalData'));
const imageMappingsTDC = {
  small: TarjetaCreditoCutMin,
  medium: TarjetaCreditoCut,
  large: TarjetaCredito,
};
const imageMappingsPPAGO = {
  small: TarjetaPrepagoCutMin,
  medium: TarjetaPrepagoCut,
  large: TarjetaPrepago,
};
const getSizeCategory = width => {
  if (width <= 376) return 'small';
  if (width < 768) return 'medium';
  return 'large';
};
const useRenderImage = width => {
  return useMemo(() => {
    const sizeCategory = getSizeCategory(width);
    return {
      tdc: imageMappingsTDC[sizeCategory],
      ppago: imageMappingsPPAGO[sizeCategory],
    };
  }, [width]);
};
const CardsOnboarding = memo(({valid, modalPersonaDataRef}) => {
  const dispatch = useDispatch();
  const {width} = useWindowDimensions();
  const {tdc, ppago} = useRenderImage(width);
  const [loading, setLoading] = useState(false);
  const [loadingPrepago, setLoadingPrepago] = useState(false);

  const onSuccess = useCallback(() => {
    setLoading(false);
    modalPersonaDataRef.current.show();
  }, [modalPersonaDataRef]);

  const onSuccessPrepago = useCallback(() => {
    setLoadingPrepago(false);
    modalPersonaDataRef.current.show();
  }, [modalPersonaDataRef]);

  const handleClick = useCallback(() => {
    setLoading(true);
    dispatch(CreateOnboardingCrePre('', onSuccess, 'CARD'));
  }, [dispatch, onSuccess]);

  const handleClickPrepago = useCallback(() => {
    setLoadingPrepago(true);
    dispatch(CreateOnboardingPago('', onSuccessPrepago, 'PPAGO'));
    dispatch({type: 'ONBOARDING_PREPAGO', payload: true});
  }, [dispatch, onSuccessPrepago]);

  return (
    <>
      <link rel="preload" href={TarjetaCredito} as="image" type="image/webp" />
      <link rel="preload" href={TarjetaPrepago} as="image" type="image/webp" />
      <BlockContainer>
        <OnboardingContainer id="container-TDC">
          <ImagePhone src={tdc} alt="tarjeta-credito" />
          <CardText>
            <ListContainer>
              <TitleCardText>Tarjeta digital CMF</TitleCardText>
              <li>
                Compra en hasta 36 cuotas en Novey, Cochez y Kohler Signature
                Store by Cochez
              </li>
              <li>
                Obtén cupón de $10 para utilizar en tu primera compra de $50.00
              </li>
              <li>Descuentos en más de 100 productos mensuales</li>
              <li>Comprar desde la app, escaneando el código QR</li>
            </ListContainer>
          </CardText>
          <ButtonWelcome
            style={{backgroundColor: '#4FA201', color: '#FFF'}}
            onClick={handleClick}
            disabled={!valid || loading}>
            {!loading ? (
              'Aplica aquí'
            ) : (
              <CircularProgress
                color="inherit"
                style={{width: 20, height: 20}}
              />
            )}
          </ButtonWelcome>
        </OnboardingContainer>
        <OnboardingContainer id="container-PPAGO">
          <ImagePhone src={ppago} alt="tarjeta-prepago" />
          <CardTextPrepaid>
            <ListContainer>
              <TitleCardText>Tarjeta Prepago CMF Mastercard®</TitleCardText>
              <li>Compra en cualquier comercio nacional o internacional</li>
              <li>Recarga dinero desde el app de CMF</li>
              <li>Paga fácil y seguro</li>
              <li>
                Realiza pagos sin contacto con Apple Pay o el wallet de CMF en
                Android
              </li>
            </ListContainer>
          </CardTextPrepaid>
          <ButtonWelcome
            style={{backgroundColor: '#011E41', color: '#FFF'}}
            onClick={handleClickPrepago}
            disabled={!valid || loadingPrepago}>
            {!loadingPrepago ? (
              'Solicitar aquí'
            ) : (
              <CircularProgress
                color="inherit"
                style={{width: 20, height: 20}}
              />
            )}
          </ButtonWelcome>
        </OnboardingContainer>
      </BlockContainer>
      <Suspense fallback={<CircularProgress color="inherit" />}>
        <ModalPersonalData ref={modalPersonaDataRef} />
      </Suspense>
    </>
  );
});

export default CardsOnboarding;

import { useEffect, useState } from 'react'

function useForm(initialData, schema, hasDefaultValues = false) {
  const [formData, setFormData] = useState(initialData)
  const [formErrors, setFormErrors] = useState({})
  const [touchedFields, setTouchedFields] = useState({})
  const [fieldVisibility, setFieldVisibility] = useState({})

  const getValue = name => formData[name]?.value || ''
  const getError = name => formErrors[name]
  const isTouched = name => !!touchedFields[name]
  const showError = name => isTouched(name) && !!getError(name)

  const clearForm = () => {
    setFormData(initialData)
    setFormErrors({})
    setTouchedFields({})
  }

  const disabledFileds = names => {
    const newFormData = {...formData}
    if (names?.length === 0) return
    names.forEach(name => {
      newFormData[name] = {
        ...newFormData[name],
        disabled: true,
      }
    })
    setFormData(newFormData)
  }

  const setInitialValues = values => {
    const newFormData = {...formData}
    if (values?.length === 0) return
    values.forEach(item => {
      newFormData[item.name] = {
        ...newFormData[item.name],
        value: item.value,
      }
    })
    setFormData(newFormData)
  }

  useEffect(() => {
    for (const name in schema.fields) {
      if (initialData[name]) {
        const defaultValue =
          formData[name]?.value ||
          schema.fields[name]?.default() ||
          initialData[name]?.value ||
          ''
        setFormData(prevData => ({
          ...prevData,
          [name]: {
            ...prevData[name],
            value: defaultValue,
          },
        }))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData, schema])

  useEffect(() => {
    const visibility = {}
    for (const fieldName in initialData) {
      visibility[fieldName] = getShow(fieldName)
    }
    setFieldVisibility(visibility)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData, formData])

  const validateField = async (name, callback = null, value = null) => {
    const field = initialData[name]
    const _value = value || formData[name]?.value

    let validated = false
    if (field?.showValidations) {
      let val = false
      for (const validation of field.showValidations) {
        const {name: dependencyName, isNumber} = validation
        const validationValue = isNumber
          ? parseInt(formData[dependencyName]?.value)
          : formData[dependencyName]?.value || ''
        const newValue = isNumber ? parseInt(_value) : _value || ''

        if (validationValue !== newValue) {
          val = true
        }
      }
      validated = val
    }

    if (!validated && formData[name]?.disabled) {
      validated = true
    }

    if (!validated && initialData[name]?.isOptional && !_value) {
      validated = true
    }

    try {
      if (!validated) {
        await schema.fields[name]?.validate(_value)
      }
      setFormErrors(prevErrors => ({
        ...prevErrors,
        [name]: null,
      }))
      if (callback) {
        callback()
      }
    } catch (error) {
      setFormErrors(prevErrors => ({
        ...prevErrors,
        [name]: error.message,
      }))
    }
  }

  const handleBlur = name => {
    const value = formData[name]?.value
    setTouchedFields(prevTouched => ({
      ...prevTouched,
      [name]: true,
    }))
    validateField(name, null, value)
    if (initialData[name]?.delateDoubleSpace) {
      const newValue = value?.replace(/\s\s+/g, ' ')
      handleChange(name, newValue)
    }
  }

  const handleChange = (name, value) => {
    const newFormData = {
      ...formData,
      [name]: {
        ...formData[name],
        value,
      },
    }

    const visibility = {...fieldVisibility}
    for (const fieldName in initialData) {
      visibility[fieldName] = getShow(fieldName, newFormData)
    }
    const names = initialData[name]?.onChancheClear

    if (names?.length > 0) {
      names.forEach(name => {
        newFormData[name] = {
          ...newFormData[name],
          value: '',
        }
      })
    }

    setFormData(newFormData)
    setFieldVisibility(visibility)

    validateField(name, null, value)

    const changedValue = newFormData[name]?.onChange
    if (changedValue && value !== formData[name]?.value) {
      changedValue(value)
    }
  }

  const handleSubmit = async callback => {
    const errors = {}

    for (const name in schema.fields) {
      const value = formData[name]?.value
      let validated = false
      try {
        setTouchedFields(prevTouched => ({
          ...prevTouched,
          [name]: true,
        }))
        if (initialData[name]?.showValidations) {
          let val = false
          for (const validation of initialData[name].showValidations) {
            const {
              name: dependencyName,
              value,
              defaultValue,
              isNumber,
            } = validation
            const validationValue = isNumber
              ? parseInt(formData[dependencyName]?.value)
              : formData[dependencyName]?.value || ''
            const newValue = isNumber ? parseInt(value) : value || ''

            if (
              validationValue !== newValue &&
              validationValue !== defaultValue
            ) {
              val = true
            }
          }
          validated = val
        }
        if (!validated && formData[name]?.disabled) {
          validated = true
        }
        if (!validated && initialData[name]?.isOptional && !value) {
          validated = true
        }
        if (!validated) {
          await schema.fields[name].validate(value)
        }
        setFormErrors(prevErrors => ({
          ...prevErrors,
          [name]: null,
        }))
      } catch (error) {
        errors[name] = error.message
        setFormErrors(prevErrors => ({
          ...prevErrors,
          [name]: error.message,
        }))
      }
    }

    if (Object.keys(errors).length === 0 && callback) {
      callback()
    } else {
      return errors
    }
  }

  const getShow = (fieldName, newFormData = null) => {
    const data = newFormData || formData
    const field = data[fieldName]
    let validated = true
    if (field.showValidations) {
      for (const validation of field.showValidations) {
        const {name, value, isNumber} = validation
        const validationValue = isNumber
          ? parseInt(data[name]?.value)
          : data[name]?.value || ''
        const newValue = isNumber ? parseInt(value) : value || ''
        validated = validationValue === newValue && validated
      }
    }
    return validated
  }

  const getVisibility = fieldName => fieldVisibility[fieldName] || false

  const setOnChangeEvents = events => {
    events.forEach(event => {
      const {name, onChange} = event
      setFormData(prevData => ({
        ...prevData,
        [name]: {
          ...prevData[name],
          onChange,
        },
      }))
    })
  }

  return {
    formData,
    clearForm,
    showError,
    getValue,
    getError,
    isTouched,
    handleChange,
    handleBlur,
    handleSubmit,
    getVisibility,
    validateField,
    disabledFileds,
    setOnChangeEvents,
    setInitialValues,
  }
}

export default useForm

import { Typography } from '@material-ui/core'
import { Box, styled } from '@mui/material'
import React from 'react'
import useResponsive from 'src/hooks/useResponsive'
import { BreakPointTheme } from 'src/theme/breakpoints'

const Title = styled(Typography)(({theme}) => ({
  fontSize: '26px',
  color: '#011e41',
  fontWeight: 'bold',
  marginBottom: '0.5em',
  textAlign: 'center',
}))
export function SecurityScreenList({v, index}) {
  const isDesktop = useResponsive('up', 'md')
  return (
    <>
      {!isDesktop ? (
        <Box
          style={{
            display: 'grid',
            marginTop: '1.25rem',
            gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
            gap: '0.75rem',
            [BreakPointTheme.breakpoints.up('lg')]: {display: 'none'},
          }}>
          <div
            style={{
              display: 'flex',
              gridColumn: 'span 3 / span 3',
              justifyContent: 'center',
            }}>
            <img
              src={v?.img}
              alt=""
              style={{
                height: '45px',
              }}
            />
          </div>
          <div
            style={{
              width: '215px',
            }}>
            <Title
              variant="h5"
              sx={{
                fontSize: '18px !important',
                fontWeight: '600 !important',
                textAlign: 'center !important'
              }}>
              {v?.title}
            </Title>
            <Typography
              style={{
                font: 'normal normal normal 14px/18px Red Hat Text',
                letterSpacing: '0px',
                color: '#707070',
                textAlign: 'left',
                opacity: 1,
              }}>
              {v?.subTitle}
            </Typography>
          </div>
        </Box>
      ) : (
        <Box
          style={{
            padding: '1rem 0.5rem',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            display: 'flex',
            background: '#FFFFFF',
            borderRadius: '20px',
            [BreakPointTheme.breakpoints.up('lg')]: {
              display: 'flex',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              borderWidth: '2px',
            },
          }}>
          <span
            style={{
              display: 'flex',
              marginTop: '1.5rem',
              justifyContent: 'center',
            }}>
            <img
              src={v?.img}
              alt=""
              style={{
                height: '45px',
              }}
            />
          </span>
          <Title
            variant="h5"
            sx={{
              fontSize: '18px !important',
              fontWeight: '600 !important',
            }}>
            {v?.title}
          </Title>
          <Typography
            style={{
              font: 'normal normal normal 14px/18px Red Hat Text',
              letterSpacing: '0px',
              color: '#707070',
              textAlign: 'center',
              opacity: 1,
            }}>
            {v?.subTitle}
          </Typography>
        </Box>
      )}
    </>
  )
}

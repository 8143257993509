import validator from "validator"
import creditCardType, { types } from "credit-card-type"

const customProviders = [
  {
    niceType: "Visa with old support",
    type: types.VISA,
    patterns: [4],
    gaps: [4, 8, 12, 16],
    lengths: [13, 16, 19],
    code: {
      name: "CVV",
      size: 3
    }
  }
]

const defualtCode = {
  name: "CVV",
  size: 3
}

export default {
  cardAlias: cardholder => {
    return validator.isAlphanumeric(cardholder, "es-ES", { ignore: "s" })
  },
  cardHolder: cardholder => {
    return validator.isAlpha(cardholder, "es-ES", { ignore: "s" })
  },
  cardNumber: cardnumber => {
    customProviders.forEach(provider => creditCardType.addCard(provider))

    const type = creditCardType(cardnumber)
    if (cardnumber.length > 1 && type.length > 0 && type[0]["type"]) {
      if (type[0].type === "visa" || type[0].type === "mastercard") {
        const cleanedInput = cardnumber.replace(/\D/g, "")

        const digits = cleanedInput.split("").map(Number)
        const validLength = type[0].lengths.find(l => digits.length === l)
        if (validLength === undefined) {
          return {
            type: type[0].type,
            valid: false,
            code: type[0].code,
            length:
              type[0].lengths[type[0].lengths.length - 1] + type[0].gaps.length
          }
        }

        // Double every second digit from the right
        for (let i = digits.length - 2; i >= 0; i -= 2) {
          digits[i] *= 2
          if (digits[i] > 9) {
            digits[i] -= 9
          }
        }

        // Calculate the sum
        const sum = digits.reduce((acc, digit) => acc + digit, 0)

        // Check if the sum end with 0
        return {
          type: type[0].type,
          valid: sum % 10 === 0,
          code: type[0].code,
          length:
            type[0].lengths[type[0].lengths.length - 1] + type[0].gaps.length
        }
      }
      return {
        type: null,
        valid: false,
        code: defualtCode,
        length: 16 + 3 // default gaps + spaces
      }
    }
    return {
      type: null,
      valid: false,
      code: defualtCode,
      length: 16 + 3 // default gaps + spaces
    }
  },
  expirationDate: (expirationdate, currentDate) => {},
  code: code => {}
}

import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const ArrowCommon = () => {
  return (
    <div className="mx-2">
      <KeyboardArrowDownIcon
        color="#011E41"
        sx={{
          color: '#011E41',
          cursor: 'pointer',
        }}
      />
    </div>
  )
}

export default ArrowCommon

import React, {useState} from 'react'
import {Document, Page, pdfjs} from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
export default function AllPages(props) {
  const [numPages, setNumPages] = useState(null)
  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages)
  }
  const {pdf} = props
  let scaleSize
  if (window.screen.width > 1224) {
    scaleSize = 2
  }
  return (
    <>
      <Document
        file={pdf}
        renderMode="canvas"
        options={{workerSrc: '/pdf.worker.js'}}
        onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={scaleSize}
          />
        ))}
      </Document>
    </>
  )
}

import React from 'react'
import { useNavigate } from 'react-router-dom'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import CommonPage from './Layout/LayoutPage'

const Maintenance = ({message}) => {
  const {width} = useWindowDimensions()

  const isDesktop = width > '600'

  const navigate = useNavigate()

  const handleButton = () => {
    navigate('/')
  }

  return (
    <CommonPage
      headingClasses={
        'lg:text-5xl lg:font-bold font-medium text-[24px] lg:-mb-0 -mb-3 lg:mr-0.5 mr-4 '
      }
      heading={
        <div className="">
          <div
            style={{
              fontSize: 'clamp(26px,3vw,65px)',
              fontWeight: 'bold',
              color: '#011E41',
              letterSpacing: '0',
            }}>
            Gracias por tu interés en la
          </div>
          <div
            style={{
              fontSize: 'clamp(26px,3vw,65px)',
              fontWeight: 'bold',
              color: '#011E41',
              letterSpacing: '0',
            }}>
            tarjeta digital CMF
          </div>
        </div>
      }
      subtitle2Classes={'lg:text-[32px]'}
      subtitle2={
        <>
          <div
            className="sm:flex flex-col item-center justify-center  mt-5 lg:pt-0 pt-3 "
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <span
              className="text-2xl md:text-lg"
              style={{
                color: '#011E41',
                fontSize: isDesktop ? '' : '1.375rem',
                fontWeight: 400,
                width: isDesktop ? '50%' : '100%',
              }}>
              {message}
            </span>
          </div>
        </>
      }
      buttonClasses="font-thin"
      buttonText={'Salir'}
      buttonOnClick={handleButton}
    />
  )
}
export default Maintenance

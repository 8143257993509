import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, Input, Modal, message } from 'antd'
import Axios from 'axios'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { API_KEY } from '../../Data/Api'
import emailHeaderLogoSend from '../../assets/images/onboarding/Email-send.svg'
import emailHeaderLogoSuccess from '../../assets/images/onboarding/succes-icon-check.svg'
import {
  AddLogProccess,
  editFormRequest,
  validateEmail,
} from '../../store/actions/requestCard.actions'
import { APP } from '../../utils/Constants'
import { formFields } from './FormRequest.config'
const FormItem = Form.Item

const secondsToExpire = 120
var test = 0
message.config({
  top: 30,
  duration: 4,
  maxCount: 1,
})

const StyledH1 = styled('h1')(() => ({
  marginBottom: '10px !important',
  fontSize: '20px !important',
}))

const StyledP = styled('p')(() => ({
  marginTop: 0,
  color: '#309c54',
  fontSize: 16,
  lineHeight: 1.4,
}))

const StyledInput = styled(Input)(() => ({
  '&.ant-input': {
    height: 49,
    width: '80%',
    lineHeight: 1,
    '@media (max-width:600px)': {
      height: 51,
      width: '100%',
    },
  },
}))

class ValidateEmailDefault extends Component {
  state = {
    loading: false,
    loadingResend: false,
    cellphone: this.props.initialValue || '',
    verify_code: '',
    isValidating: false,
    countDown: 0,
    timerDown: false,
    confirmed: false,
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible) {
      this.setState({
        loading: false,
        loadingResend: false,
        isValidating: false,
        verify_code: '',
        cellphone: this.props.initialValue,
        countDown: 0,
        timerDown: false,
        confirmed: false,
      })
    }
  }
  async isValidForm() {
    try {
      await this.props.form.validateFields()
      return true
    } catch (e) {
      return false
    }
  }

  async onPressSubmit(e) {
    e.preventDefault()
    if (!this.state.isValidating) {
      const isValid = await this.isValidForm()
      if (isValid) {
        this.setState({loading: true})
        this.sendEmail()
      } else {
        message.error('Ingrese un correo electronico valido')
      }
    } else {
      this.checkEmail()
    }
  }

  async sendEmail() {
    const {onboardingId, origin, cedulaState} = this.props
    try {
      /* only for test email */
      if (APP.fakeEmail) {
        this.setState({loading: false, confirmed: true})
        return
      }

      this.setState({loadingResend: true})
      await Axios.post(
        `${APP.cmf.endpoints.sendMailVerify}`,
        {
          to: this.state.email,
          username: this.props.fullName || '',
          // email: this.state.email,
          // fullName: this.props.fullName || '',
          // createdBy: 'CMF',
        },
        {
          headers: {
            Apikey: API_KEY,
            'content-type': 'application/json',
          },
        },
      ).then(res => {
        this.start()
        this.setState({
          isValidating: true,
          loading: false,
          loadingResend: false,
        })
        var obj = {email: this.state.email}
        var obj2 = {username: this.props.fullName}
        var obj3 = {cedula: cedulaState}
        var ErrorRes = Object.assign(obj, obj2, obj3)
        this.props.AddLogProccess(
          'OnboardingConfirmEmail',
          origin,
          onboardingId,
          res.data.problemPublic,
          ErrorRes,
          true,
        )
      })

      test++
    } catch (e) {
      var obj = {email: this.state.email}
      var obj2 = {username: this.props.fullName}
      var obj3 = {cedula: cedulaState}
      var ErrorRes = Object.assign(obj, obj2, obj3)
      this.props.AddLogProccess(
        'OnboardingConfirmEmail',
        origin,
        onboardingId,
        e?.response?.data?.problemPublic,
        ErrorRes,
        false,
      )
      if (e?.response?.data?.problemPublic) {
        message.error(e?.response?.data?.problemPublic)
      } else {
        message.error('No hemos podido enviar el email')
      }
      this.setState({loading: false, loadingResend: false})
    }
  }
  actualizarState = e => {
    const esValido = e.target.validity.valid

    if (esValido) {
      this.setState({
        verify_code: e.target.value,
      })
    }
  }

  async checkEmail() {
    const {onboardingId, origin, cedulaState} = this.props

    try {
      this.setState({loading: true})
      const res = await Axios.post(
        `${APP.cmf.endpoints.confirmMailVerify}`,
        {
          to: this.state.email,
          code: this.state.verify_code,
        },
        {
          headers: {
            Apikey: API_KEY,
            'content-type': 'application/json',
          },
        },
      )

      if (res.data.complete === true) {
        this.setState({loading: false, confirmed: true})
        let obj = {email: this.state.email}
        let obj2 = {code: this.state.verify_code}
        let obj3 = {cedula: cedulaState}
        let ErrorRes = Object.assign(obj, obj2, obj3)
        this.props.AddLogProccess(
          'OnboardingGetConfirmEmail',
          origin,
          onboardingId,
          res.data.problemPublic,
          ErrorRes,
          true,
        )
      }
    } catch (err) {
      let obj = {email: this.state.email}
      let obj2 = {username: this.props.fullName}
      let obj3 = {cedula: cedulaState}
      let ErrorRes = Object.assign(obj, obj2, obj3)
      this.props.AddLogProccess(
        'OnboardingGetConfirmEmail',
        origin,
        onboardingId,
        err?.response?.data?.problemPublic,
        ErrorRes,
        false,
      )
      if (err?.response?.data?.problemPublic) {
        message.error(err?.response?.data?.problemPublic)
      } else {
        message.error('El código ingresado es inválido')
      }
      this.setState({loading: false})
    }
  }

  async onCompleteEmail() {
    this.props.ValidateEmail(this.state.email)
    this.props.onClose()
  }

  start() {
    const _time = moment().add(secondsToExpire, 'seconds')
    this.setState({countDown: _time, isValidated: true, loading: false}, () =>
      this.tick(),
    )
  }

  tick() {
    const {countDown} = this.state
    if (!countDown.diff) {
      return false
    }
    const diff = countDown.diff(moment())
    const timerDown = moment.utc(diff)
    this.setState({timerDown: timerDown, diff})
    if (diff > 0) {
      setTimeout(this.tick.bind(this), 1000)
    } else {
      message.error('El tiempo ha expirado, por favor intenta nuevamente')
      this.setState({
        countDown: 0,
        timerDown: false,
        isValidated: false,
      })
    }
  }

  onSubmit() {}
  onCancel() {
    /* TODO -- on submit cellphone */
    this.props.EditForm({email: ''})
    this.props.onClose()
  }
  onEdit() {
    this.setState({isValidating: false, countDown: 0, timerDown: 0})
  }
  render() {
    const {countDown, timerDown} = this.state
    const {getFieldDecorator} = this.props.form
    const {
      actualizarState,
      state: {verify_code},
    } = this

    return (
      <Modal
        loading={this.state.loading}
        centered
        visible={this.props.visible}
        // visible
        destroyOnClose={false}
        maskClosable={false}
        onOk={this.onPressSubmit.bind(this)}
        onCancel={this.onCancel.bind(this)}
        cancelButtonProps={{disabled: countDown > 0 && timerDown}}
        closable={!(countDown > 0 && timerDown) && !this.state.confirmed}
        wrapClassName="container-validation"
        width={390}
        footer={false}>
        {!this.state.isValidating && !this.state.confirmed && (
          <div className="validation-container">
            <center>
              {' '}
              <img
                src={emailHeaderLogoSend}
                alt='Email Send Header Logo'
                style={{width: '46px', height: '46px', marginBottom: '10px'}}
              />
            </center>
            <StyledH1>Validación de correo electrónico</StyledH1>
            <FormItem style={{marginBottom: 16, lineHeight: 1}}>
              {getFieldDecorator('email', {
                initialValue: this.state.email,
                rules: formFields.email?.rules || [],
                validateTrigger: 'onPressSubmit',
              })(
                <StyledInput
                  type="text"
                  data-testid="input-email"
                  id={'email'}
                  name={'email'}
                  onChange={e => this.setState({email: e.target.value})}
                  placeholder={'Ingresa tu correo electrónico'}
                />,
              )}
            </FormItem>
            <StyledP style={{fontStyle: 'italic', paddingBottom: 16}}>
              Ingresa a la bandeja de entrada de tu correo electrónico, abre el
              mensaje que recibiste de CMF y valida tu dirección de correo con
              el código que te enviaremos.
            </StyledP>
            {test < 3 ? (
              <Button
                success
                data-testid="btn-send-email"
                loading={this.state.loading}
                disabled={false}
                className="Button__Email"
                size="large"
                onClick={this.onPressSubmit.bind(this)}>
                Aceptar
              </Button>
            ) : (
              <Button
                success
                disabled={true}
                className="Button__Email"
                size="large">
                Aceptar
              </Button>
            )}
          </div>
        )}
        {this.state.isValidating && !this.state.confirmed && (
          <div className="validation-container">
            <center>
              {' '}
              <img src={emailHeaderLogoSend} alt='Email Send Header Logo'/>
            </center>
            <StyledH1 style={{paddingBottom: 8}}>
              Validación de correo electrónico
            </StyledH1>

            <FormItem style={{marginBottom: 16, lineHeight: 1}}>
              <StyledInput
                id="verify_code"
                type="text"
                name="verify_code"
                placeholder={'Ingresa tu código de verificación.'}
                value={verify_code}
                pattern="[0-9]{0,6}"
                onChange={actualizarState}
              />
            </FormItem>
            <StyledP>
              El correo electrónico fue enviado.
              <br /> Revisa la bandeja de entrada de tu correo y confírmalo.
            </StyledP>
            <div style={{marginBottom: 8, display: 'block', fontSize: 16}}>
              {(() => {
                if (test === 3)
                  return (
                    <strong style={{color: '#13A438'}}>
                      Intentos maximos alcanzados.{' '}
                      {countDown > 1 && timerDown && (
                        <strong style={{color: '#13A438'}}>
                          {timerDown.format('mm')}:{timerDown.format('ss')}
                        </strong>
                      )}
                    </strong>
                  )
                else if (test <= 2)
                  return (
                    <strong style={{color: '#13A438'}}>
                      Se reenviará el correo en:{' '}
                      {countDown > 1 && timerDown && (
                        <strong style={{color: '#13A438'}}>
                          &nbsp;{timerDown.format('mm')}:
                          {timerDown.format('ss')}
                        </strong>
                      )}
                    </strong>
                  )
              })()}
            </div>
            <br></br>
            <Button
              id="btnEnviar"
              success
              loading={this.state.loading}
              disabled={false}
              className="Button__Email"
              size="large"
              onClick={this.onPressSubmit.bind(this)}>
              Aceptar
            </Button>
            <br></br>
            <br></br>
            <p>
              <a role='button' tabIndex={0} onClick={this.onEdit.bind(this)}>
                <u> ¿Deseas editar tu correo electrónico?</u>
              </a>
            </p>
          </div>
        )}

        {this.state.confirmed && (
          <div className="validation-container">
            <center>
              <img src={emailHeaderLogoSuccess} alt='Email Header Logo Success' />
            </center>
            <StyledH1>Validación de correo electrónico</StyledH1>
            <StyledP
              style={{fontStyle: 'italic', paddingTop: 16, paddingBottom: 8}}>
              El correo electrónico fue confirmado.
            </StyledP>
            <Button
              style={{marginBottom: 8}}
              success
              loading={this.state.loading}
              disabled={false}
              data-testid="btn-continue-email"
              className="Button__Email__Continue"
              size="large"
              onClick={this.onCompleteEmail.bind(this)}>
              <span>CONTINUAR CON LA SOLICITUD</span>
            </Button>
          </div>
        )}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  const {origin, form, cedulaState} = state.RequestCardReducer
  return {
    origin: origin,
    AnswEID: form,
    cedulaState: cedulaState,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ValidateEmail: email => dispatch(validateEmail(email)),
    EditForm: formFields => dispatch(editFormRequest(formFields)),
    AddLogProccess: (
      process,
      uniqueCode,
      newCode,
      problemPublic,
      stringToSave,
      isComplete,
    ) =>
      dispatch(
        AddLogProccess(
          process,
          uniqueCode,
          newCode,
          problemPublic,
          stringToSave,
          isComplete,
        ),
      ),
  }
}

const ValidateEmail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ValidateEmailDefault)
export default Form.create()(ValidateEmail)

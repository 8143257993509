// import SimpleBar from 'simplebar-react';
// @mui
import { alpha, styled } from '@mui/material/styles'

// ----------------------------------------------------------------------

export const StyledContainer = styled('div')(({theme}) => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
  backgroundColor: '#FFF',
  padding: '16px',
}))

export const StyledRootScrollbar = styled('div')(({theme}) => ({
  flexGrow: 1,
  overflow: 'hidden',
  backgroundColor: '#2C3645',
  borderRadius: '40px',
  position: 'fixed',
  // width: '25%',
  height: 'auto',
  top: '16px',
  left: '16px',
  bottom: '16px',
}))

export const StyledScrollbar = styled('div')(({theme}) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 10,
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6,
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}))

import {Box} from '@mui/material'
import React from 'react'

function Monetizacion({color}) {
  return (
    <Box sx={{display: 'flex', justifyContent: 'center'}}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.99984 1.66675C5.39984 1.66675 1.6665 5.40008 1.6665 10.0001C1.6665 14.6001 5.39984 18.3334 9.99984 18.3334C14.5998 18.3334 18.3332 14.6001 18.3332 10.0001C18.3332 5.40008 14.5998 1.66675 9.99984 1.66675ZM11.1748 15.0751V15.5584C11.1748 16.1667 10.6748 16.6667 10.0665 16.6667H10.0582C9.44984 16.6667 8.94984 16.1667 8.94984 15.5584V15.0584C7.8415 14.8251 6.85817 14.2167 6.4415 13.1917C6.24984 12.7334 6.60817 12.2251 7.10817 12.2251H7.30817C7.6165 12.2251 7.8665 12.4334 7.98317 12.7251C8.22484 13.3501 8.85817 13.7834 10.0748 13.7834C11.7082 13.7834 12.0748 12.9667 12.0748 12.4584C12.0748 11.7667 11.7082 11.1167 9.84984 10.6751C7.78317 10.1751 6.3665 9.32508 6.3665 7.61675C6.3665 6.18342 7.52484 5.25008 8.95817 4.94175V4.44175C8.95817 3.83341 9.45817 3.33341 10.0665 3.33341H10.0748C10.6832 3.33341 11.1832 3.83341 11.1832 4.44175V4.95841C12.3332 5.24175 13.0582 5.95842 13.3748 6.84175C13.5415 7.30008 13.1915 7.78342 12.6998 7.78342H12.4832C12.1748 7.78342 11.9248 7.56675 11.8415 7.26675C11.6498 6.63342 11.1248 6.22508 10.0748 6.22508C8.82484 6.22508 8.07484 6.79175 8.07484 7.59175C8.07484 8.29175 8.6165 8.75008 10.2998 9.18342C11.9832 9.61675 13.7832 10.3417 13.7832 12.4417C13.7665 13.9667 12.6248 14.8001 11.1748 15.0751Z"
          fill="#026E18"
        />
      </svg>
    </Box>
  )
}

export default Monetizacion

import styled from '@emotion/styled'
import {LoadingButton} from '@mui/lab'
import React from 'react'
import {BreakPointTheme} from 'src/theme/breakpoints'
const ButtonMainOK = styled(LoadingButton)(({props}) => ({
  height: '7vh',
  width: '184px',
  fontSize: '25px',
  fontFamily: 'Nunito',
  padding: '15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'none',
  ...props?.style,
  [BreakPointTheme.breakpoints.down('xl')]: {
    width: '10rem',
    height: '60px',
    fontSize: '23px',
    borderRadius: '38px',
  },
  [BreakPointTheme.breakpoints.down('lg1')]: {
    width: '8rem',
    height: '56px',
    fontSize: '20px',
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    width: '200px',
    height: '56px',
    fontSize: '20px',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    width: '22vw',
    height: '60px',
    fontSize: '1.2em',
    marginBottom: '2rem',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '100%',
    height: '8vh',
    maxHeight: '60px',
    fontSize: '1.2em',
    borderRadius: '30px',
    marginBottom: '0',
  },
}))
const ButtonModals = props => {
  return (
    <ButtonMainOK onClick={props.onClick} {...props}>
      {props.children}
    </ButtonMainOK>
  )
}
export default ButtonModals

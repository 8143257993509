import styled from '@emotion/styled'
import {
  BASE_COLORS,
  media,
  FONT_SIZE,
  FONT_WEIGHT,
} from '../../theme/constants'

export const footerContaint = styled.div`
  background-color: ${BASE_COLORS.black};
  display: flex;
`
export const footerContainer = styled.div`
  background-color: ${BASE_COLORS.white};
`
export const footerTC = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: auto;
  background-color: ${BASE_COLORS.black};
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 50%;
  display: 'flex';
`
export const a = styled.a`
  margin: 15px;
  font-size: 12px;
  color: #ffff;
  font-family: 'Red Hat Text', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-decoration: none;
`
export const footerCopy = styled.div`
  display: flex;
  width: 80%;
  margin: auto;
  background-color: ${BASE_COLORS.white};
  justify-content: center;
  align-items: center;
  align-content: center;
  @media only screen and ${media.xxs} {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
  @media only screen and ${media.xs} {
    justify-content: center;
    flex-direction: column;
  }
  @media only screen and ${media.sm} {
    justify-content: center;
    flex-direction: column;
  }
  @media only screen and ${media.md} {
    justify-content: center;
    flex-direction: column;
  }
  @media only screen and ${media.lg} {
    flex-direction: row;
  }
  @media only screen and ${media.xl} {
    flex-direction: row;
  }
  @media only screen and ${media.xxl} {
    flex-direction: row;
  }
`

export const colfooterI = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and ${media.xxs} {
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  @media only screen and ${media.xs} {
    justify-content: center;
    flex-direction: column;
  }
  @media only screen and ${media.sm} {
    justify-content: center;
    flex-direction: column;
  }
  @media only screen and ${media.md} {
    justify-content: center;
    flex-direction: column;
  }
  @media only screen and ${media.lg} {
    flex-direction: row;
  }
  @media only screen and ${media.xl} {
    flex-direction: row;
  }
  @media only screen and ${media.xxl} {
    flex-direction: row;
  }
`
export const colfooterImage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media only screen and ${media.xxs} {
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  @media only screen and ${media.xs} {
    justify-content: center;
    flex-direction: column;
    flex-direction: row;
  }
  @media only screen and ${media.sm} {
    justify-content: center;
    flex-direction: row;
  }
  @media only screen and ${media.md} {
    justify-content: center;
    flex-direction: row;
  }
  @media only screen and ${media.lg} {
    flex-direction: row;
  }
  @media only screen and ${media.xl} {
    flex-direction: row;
  }
  @media only screen and ${media.xxl} {
    flex-direction: row;
  }
`

export const pCopy = styled.p`
  display: flex;
  justify-content: center;
  font-size: ${FONT_SIZE.xxs};
  font-family: 'Red Hat Text', sans-serif;
  font-weight: ${FONT_WEIGHT.regular};
  color: ${BASE_COLORS.gray};
  padding: 1vh;
  line-height: 16px;
`

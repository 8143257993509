import {Box, Skeleton} from '@mui/material'
import React from 'react'
import {ContainerData, ContainerMaterial} from './ContainerHome'
import {BreakPointTheme} from 'src/theme/breakpoints'
const SkeletonHome = () => {
  return (
    <ContainerMaterial maxWidth="xl">
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          flexDirection: 'column',
        }}> */}
        <ContainerData>
          <Skeleton width="20%" />
          <Skeleton
            variant="rectangular"
            width={282}
            height={160}
            sx={{
              borderRadius: '20px',
            }}
          />
        </ContainerData>
        <br />
        <br />
        <ContainerData>
          <Skeleton width="20%" />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              overflowX: 'auto',
              height: 'fit-content',
              width: 'fit-content',
            }}>
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: '20px',
                border: 'none',
                padding: '10px',
                margin: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                flex: '1 0 0',
                [BreakPointTheme.breakpoints.down('sm')]: {
                  height: '120px',
                  width: '137px',
                },
                [BreakPointTheme.breakpoints.up('sm')]: {
                  height: '116px',
                  width: '156px',
                },
              }}
            />
            <Skeleton
              variant="rectangular"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                borderRadius: '20px',
                border: 'none',
                padding: '10px',
                flex: '1 0 0',
                margin: '10px',
                [BreakPointTheme.breakpoints.down('sm')]: {
                  height: '120px',
                  width: '137px',
                },
                [BreakPointTheme.breakpoints.up('sm')]: {
                  height: '116px',
                  width: '156px',
                },
              }}
            />
          </Box>
        </ContainerData>
        <br />
        <ContainerData>
          <Skeleton width="20%" />
          <Skeleton variant="rectangular" width="100%">
            <div style={{height: '15rem'}} />
          </Skeleton>
        </ContainerData>
      {/* </Box> */}
    </ContainerMaterial>
  )
}
export default SkeletonHome

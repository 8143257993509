import {styled} from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import SwipeableDrawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {BreakPointTheme} from 'src/theme/breakpoints';
import {FONT_PRIMARY} from 'src/theme/typography';
import CloseButton from '../../assets/images/cmf_linea/closeButton.svg';
import CustomStyledBox from '../Box/BoxCustom';
import {
  BenefitsCardTDC,
  BenefitsCardPrepago,
  BenefitsCardTDCMastercard,
  BenefitsCardTDCMastercardBlack,
} from 'src/utils/BenefitsCardText';
import {PRODUCT_ID} from 'src/utils/Constants';
const StyleBox = styled('div')(({type}) => ({
  ...(type === 'Button' && {
    [BreakPointTheme.breakpoints.up('xxs')]: {
      display: 'flex',
      width: '100%',
    },
  }),
}));
const TableContent = styled(TableContainer)(({theme}) => ({
  width: '100%',
  margin: '0',
  padding: '0',
}));

export default function BenefitsDrawer({
  openDrawer,
  closeDrawer,
  types,
  loading,
  item,
}) {
  const {selectedProduct} = useSelector(state => state.ActiveProductReducer);
  const detailsById = useSelector(state => state.productReducer.productData);
  const dataCurrent = detailsById[selectedProduct?.customerProductId];
  const imageCardBackground = item?.customerAccountCards[0]?.cardImage;

  const [actualStep, setActualStep] = useState(0);
  const getProductCategory = productId => {
    if (Object.values(PRODUCT_ID.isCardTDC).includes(productId.toUpperCase())) {
      return 'isCardTDC';
    } else if (
      Object.values(PRODUCT_ID.isCardPrepago).includes(productId.toUpperCase())
    ) {
      return 'isCardPrepago';
    } else if (
      Object.values(PRODUCT_ID.isCardTDCMastercard).includes(
        productId.toUpperCase(),
      )
    ) {
      return 'isCardTDCMastercard';
    } else if (
      Object.values(PRODUCT_ID.isCardTDCMastercardBlack).includes(
        productId.toUpperCase(),
      )
    ) {
      return 'isCardTDCMastercardBlack';
    } else {
      return 'Unknown Category';
    }
  };
  const renderBenefits = () => {
    switch (getProductCategory(selectedProduct?.productId)) {
      case 'isCardTDC':
        return BenefitsCardTDC;
      case 'isCardPrepago':
        return BenefitsCardPrepago;
      case 'isCardTDCMastercard':
        return BenefitsCardTDCMastercard;
      case 'isCardTDCMastercardBlack':
        return BenefitsCardTDCMastercardBlack;
      default:
        return BenefitsCardTDC;
    }
  };
  const imageShow = imageCardBackground
    ? imageCardBackground
    : renderBenefits()[0].imageCard;
  const toggleDrawer = open => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    if (open === false) {
      setActualStep(0);
    }
  };

  const BenefitsText = anchor => (
    <CustomStyledBox>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
          fontFamily: FONT_PRIMARY,
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
          letterSpacing: '-0.36px',
        }}>
        Beneficios de la Tarjeta
        <img
          role="button"
          alt="Close button"
          src={CloseButton}
          onClick={() => {
            closeDrawer();
          }}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          padding: '16px',
          marginTop: 'auto',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          // alignSelf: 'stretch',
          height: '100%',
          justifyContent: 'flex-start',
        }}>
        <Box
          sx={{
            height: '132px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '16px',
          }}>
          <img
            style={{height: '100px', width: '158px'}}
            alt="card"
            src={imageShow}
          />
        </Box>
        <Box>
          <Typography variant="BenefitsTitleH1">
            {renderBenefits()[1].TitleH1}
          </Typography>
          <br />
          <Typography variant="BenefitsTitleH2">
            {renderBenefits()[1].TitleH2}
          </Typography>
        </Box>
        <Box>
          <>
            <TableContent component={Paper}>
              <Table
                sx={{
                  width: '100%',
                  borderRadius: '20px',
                  backgroundColor: '#FFF',
                }}
                aria-label="simple table">
                <TableBody>
                  {renderBenefits()
                    .slice(2) // Ignorar el primer elemento que contiene los títulos
                    .map(row => (
                      <TableRow
                        key={row.key}
                        sx={{
                          backgroundColor: '#FFF',
                          '& td, & th': {
                            border: 0,
                          },
                        }}>
                        <TableCell
                          size="small"
                          align="left"
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            width: '50px',
                            padding: '20px 0px',
                          }}>
                          <div
                            style={{
                              width: '50px',
                              height: '50px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-start',
                            }}>
                            <img
                              alt="Credit"
                              style={{
                                width: 'auto',
                                height: 'auto',
                              }}
                              src={row.icon}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            textAlign: 'left',
                          }}>
                          <Typography variant="BenefitsTitle">
                            {row.title}
                          </Typography>
                          <br />
                          <Typography variant="BenefitsSubtitle">
                            {row.subtitle}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContent>
          </>
        </Box>
      </Box>
    </CustomStyledBox>
  );
  const StepperBenefit = [
    {screen: 'Benefit', title: 'Pagar Tarjeta', component: BenefitsText},
  ];

  return (
    <>
      <StyleBox type={types}>
        {['right'].map(anchor => (
          <React.Fragment key={anchor}>
            <SwipeableDrawer
              sx={{
                '& .MuiDrawer-paper': {
                  boxShadow: 'none',
                  backgroundColor: '#FFF',
                  borderRadius: '32px 0px 0px 32px',
                  height: '100%',
                  [BreakPointTheme.breakpoints.down('sm')]: {
                    width: '100vw',
                    borderRadius: 0,
                    height: '100%',
                    minHeight: '-webkit-fill-available',
                  },
                },
              }}
              anchor="right"
              open={openDrawer}
              onOpen={toggleDrawer(true)}
              onClose={toggleDrawer(false)}>
              {StepperBenefit[actualStep]['component'](anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </StyleBox>
    </>
  );
}

BenefitsDrawer.propTypes = {
  openDrawer: PropTypes.any,
  closeDrawer: PropTypes.any,
  icon: PropTypes.any,
  loading: PropTypes.any,
  mode: PropTypes.any,
  state: PropTypes.shape({
    ActiveProductReducer: PropTypes.any,
    AuthenticationReducer: PropTypes.any,
    productReducer: PropTypes.shape({
      productData: PropTypes.any,
    }),
  }),
  texto: PropTypes.any,
  types: PropTypes.string,
  item: PropTypes.any,
};
/*
- cardType: string
- imageCard
- Name Card
*/

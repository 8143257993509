import { sendSmsService, verifySmsService } from 'src/Data/Client';
import { openNotificationWithIcon } from 'src/utils/Functions';
import { logsOnboarding } from './requestCard.actions';

/**
 * @description: Para firmar un documento se debe solicitar una firma con DNI y DNI válidos.
 * Dependiendo del tipo de proceso de firma, En este caso se envia un SMS en la que el usuario recibira un codigo de 4 digitos.
 * @param {*} payload Trae todo los datos necesarios para el envio del SMS para la firma
 * @returns requestId: id de la solicitud de firma
 */
export const sendSmsEid = payload => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {onboardingId, origin} = RequestCardReducer;
    try {
      const res = await sendSmsService(payload);
      await logsOnboarding(
        onboardingId,
        origin,
        'SUCCESS_SEND_SMS_EID',
        res?.data,
        payload,
        true,
      );
      return res;
    } catch (e) {
      const error = e?.response?.data?.message;
      openNotificationWithIcon('error', `Sistema biométrico, ${error}`);
      await logsOnboarding(
        onboardingId,
        origin,
        'ERROR_SEND_SMS_EID',
        e?.response?.data?.message,
        e,
        false,
      );
    }
  };
};

/**
 * @description: Para firmar un documento se debe solicitar una firma con DNI y DNI válidos.
 * Dependiendo del tipo de proceso de firma, En este caso se envia un SMS en la que el usuario recibira un codigo de 4 digitos.
 * @param {*} payload Trae todo los datos necesarios para el envio del SMS para la firma
 * @returns requestId: id de la solicitud de firma
 */
export const verifySmsEid = payload => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {onboardingId, origin} = RequestCardReducer;
    try {
      const res = await verifySmsService(payload);
      logsOnboarding(
        onboardingId,
        origin,
        'SUCCESS_VERIFY_SMS_EID',
        res,
        payload,
        true,
      );
      return res;
    } catch (e) {
      const error = e?.response?.data?.message;
      openNotificationWithIcon('error', `Sistema biométrico, ${error}`);
      logsOnboarding(
        onboardingId,
        origin,
        'ERROR_VERIFY_SMS_EID',
        e?.response?.data?.message,
        error,
        false,
      );
    }
  };
};

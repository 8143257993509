import { Box } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import bannerChubb from 'src/assets/images/onboardingCredito/chubb/bannerChubb.png';
import family from 'src/assets/images/onboardingCredito/chubb/familyChubb.png';
import logoChubb from 'src/assets/images/onboardingCredito/chubb/logoChubb.png';
import money from 'src/assets/images/onboardingCredito/chubb/moneyChubb.png';
import person from 'src/assets/images/onboardingCredito/chubb/personChubb.png';
import phone from 'src/assets/images/onboardingCredito/chubb/phoneChubb.png';
import { SCREENS } from 'src/containers/OnboardingCredito/screens';
import { includeSafe } from 'src/store/actions/KO.Actions';
import { setStep } from 'src/store/actions/Steps.Action';
import { ORIGIN } from 'src/utils/Constants';
import style from './stylechubb.module.css';

const ChubbScreen = props => {
  const dispatch = useDispatch();
  const {Matrix} = useSelector(state => state.KOReducer);
  const {form, origin, configGlobal, levelRisk} = useSelector(
    state => state.RequestCardReducer,
  );
  const isPep = configGlobal ? true : form.isPolitician === 1 ? true : false;
  const isCrs = form.relationPep === 1 ? true : false;

  const nextStep = () => {
    if (origin === ORIGIN.PREAPROBADO) {
      if (!isPep && !isCrs && levelRisk !== 'ALTO') {
        /* continue the sign document */
        dispatch(setStep(SCREENS.CreditApproved));
        props.goToStep(SCREENS.CreditApproved);
      } else {
        /* go to revision */
        dispatch(setStep(SCREENS.RequestInRevision));
        props.goToStep(SCREENS.RequestInRevision);
      }
    } else if (origin === ORIGIN.CREDITO || origin === ORIGIN.PRECALIFICADO) {
      if (
        Matrix.result === 'APROBADO' &&
        Matrix.isPep === false &&
        !isPep &&
        !isCrs &&
        levelRisk !== 'ALTO'
      ) {
        /* continue the sign document */
        dispatch(setStep(SCREENS.CreditApproved));
        props.goToStep(SCREENS.CreditApproved);
      } else if (
        (Matrix.result === 'APROBADO' && Matrix.isPep === true) ||
        isPep ||
        isCrs ||
        levelRisk === 'ALTO'
      ) {
        /* go to revision */
        dispatch(setStep(SCREENS.RequestInRevision));
        props.goToStep(SCREENS.RequestInRevision);
      }
    }
  };
  const btnClick = () => {
    dispatch(includeSafe(4, onSuccess.bind(this)));
  };
  const onSuccess = success => {
    if (origin === ORIGIN.PREAPROBADO) {
      if (success) {
        if (!isPep && !isCrs && levelRisk !== 'ALTO') {
          /* continue the sign document */
          dispatch(setStep(SCREENS.CreditApproved));
          props.goToStep(SCREENS.CreditApproved);
        } else {
          /* go to revision */
          dispatch(setStep(SCREENS.RequestInRevision));
          props.goToStep(SCREENS.RequestInRevision);
        }
      }
    } else if (origin === ORIGIN.CREDITO || origin === ORIGIN.PRECALIFICADO) {
      if (success) {
        if (
          Matrix.result === 'APROBADO' &&
          Matrix.isPep === false &&
          !isPep &&
          !isCrs &&
          levelRisk !== 'ALTO'
        ) {
          /* continue the sign document */
          dispatch(setStep(SCREENS.CreditApproved));
          props.goToStep(SCREENS.CreditApproved);
        } else if (
          (Matrix.result === 'APROBADO' && Matrix.isPep === true) ||
          isPep ||
          isCrs ||
          levelRisk === 'ALTO'
        ) {
          /* go to revision */
          dispatch(setStep(SCREENS.RequestInRevision));
          props.goToStep(SCREENS.RequestInRevision);
        }
      }
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflowY: 'auto',
        minHeight: '100vh',
        height: '100vh',
      }}>
      <header className={style.mainheader}>
        <img
          src={bannerChubb}
          alt="Banner Chubb"
          className={style.bannerImage}
        />
        <img src={logoChubb} alt="Logo Chubb" className={style.chubbImage} />
        <span className={style.text}>
          Te presentamos el seguro de Accidentes Personales
        </span>
        <div className={style.separator}></div>
      </header>

      <div className={style.detailsInsurance}>
        <div>
          <div className={style.imageContainer}>
            <img src={family} alt={`title:`} />
          </div>
          <div className={style.textContainer}>
            <span>No dejes desamparada a tu familia</span>
            <span>
              Te otorgamos hasta $7,800 para ayudar a cubrir cualquier necesidad
              si falleces accidentalmente.
            </span>
          </div>
        </div>
        <div>
          <div className={style.imageContainer}>
            <img src={phone} alt={`title:`} />
          </div>
          <div className={style.textContainer}>
            <span>Los accidentes suceden cuando menos los esperas</span>
            <span>
              Recibe $780 de reembolso por tus gastos medicos por accidente.
            </span>
          </div>
        </div>
        <div>
          <div className={style.imageContainer}>
            <img src={person} alt={`title: `} />
          </div>
          <div className={style.textContainer}>
            <span>Servicio de Telemedicina</span>
            <span>
              Asistencia médica telefónica 24/7 donde te ayudamos a evaluar tu
              estado de salud actual.
            </span>
          </div>
        </div>
        <div>
          <div className={style.imageContainer}>
            <img src={money} alt={`title: `} />
          </div>
          <div className={style.textContainer}>
            <span>Pago automático</span>
            <span>Se carga automáticamente a tu tarjeta de crédito CMF.</span>
          </div>
        </div>
      </div>

      <div className={style.mobileTextContainer}>
        <span>Importante:</span>
        <span className={style.mobileTextSecond}>
          los clientes mayores de 65 años de edad no están cubiertos por este
          seguro.
        </span>
      </div>
      <div className={style.desktopContainer}>
        <div className={style.desktopSubContainer}>
          <span className={style.desktopContainer__text}>
            <span>Importante:</span>
            <span>
              los clientes mayores de 65 años de edad no están cubiertos por
              este seguro.
            </span>
          </span>
          <span className={style.desktopContainer__price}>
            <span>Solo pagas </span>
            <span> $4 por mes</span>
          </span>
        </div>
      </div>
      <div className={style.buttonChubb}>
        <button
          data-testid="yes-button-chubb"
          className="greenBtnBg"
          onClick={() => btnClick()}>
          Si, lo quiero
        </button>
        <button data-testid="no-button-chubb" onClick={() => nextStep()}>
          No en este momento
        </button>
      </div>
      <div className={style.termsContainer}>
        Regulado y supervisado por la Superintendencia de Seguros y Reaseguros
        de Panamá. Este seguro es ofrecido por Chubb Seguros Panamá, S.A. Lo
        descrito es un resumen de las condiciones del seguro por lo que no debe
        ser interpretado como una representación de los términos, condiciones y
        exclusiones de la póliza. Por favor referirse a la póliza para conocer
        los términos y condiciones aplicables. CMF no es ni será responsable por
        cualquier reclamación o cualquier asunto relacionado o derivado del
        seguro Renta Segura.
      </div>
    </Box>
  );
};

export default ChubbScreen;

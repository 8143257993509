import styled from '@emotion/styled'
import { Col, Form, Input, Row, Select } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AsideLogo from '../../components/Aside/AsideLogo.js'
import ButtonNext from '../../components/Button/ButtonNext.js'
import FooterOnboar from '../../components/Footer/FooterOnboarding.js'
import usePrevious from '../../components/Modals/usePrevious.js'
import ProgressBar from '../../components/ProgressBar/index.js'
import { updateOnboadingPhone } from '../../store/actions/phoneAction.js'
import {
  editFormRequest,
  getDistricts,
  getMaritalStatus,
  getOccupations,
  getProfessions,
  getProvinces,
  getTownships,
} from '../../store/actions/requestCard.actions.js'
import {
  GENDER_TYPE,
  ONBOARDING_STATUS,
  SELLER_ID_TYPE,
  SYSTEM_TYPES,
} from '../../utils/Constants.js'
import { formFields } from './FormRequest.config.js'
import canReturnMobile from './canReturnMobile.js'
import { SCREENS } from './screens.js'
const SelectMain = styled(Select)(() => ({
  '.ant-select-selector': {
    width: ' 100%',
    height: '3.5rem !important',
    padding: ' 0 11px',
  },
  '.ant-select-selection-search-input': {
    height: '3rem !important',
    margin: 'auto !important',
  },
  '.ant-select-arrow ': {
    color: ' var(--color-primary-main)',
    // borderWidth: ' 520px',

    'svg ': {
      display: ' inline-block',
      strokeWidth: ' 17%',
      stroke: ' var(--color-primary-main)',
      color: ' var(--color-primary-main)',
    },
  },
  '  .ant-select-selection__rendered': {
    display: 'contents',
    padding: '5px',
  },
  '.ant-select-focused .ant-select-single': {
    margin: 'auto',
  },
  '.ant-select-selector .ant-select-selection-placeholder': {
    margin: 'auto',
  },
  '.ant-select-selector .ant-select-selection-item': {
    margin: 'auto',
  },
  '.ant-select-selection-selected-value ': {
    width: ' 86% !important',
    position: ' absolute',
    top: ' 50%',
    right: ' 9px',
    left: ' 15px',
    maxWidth: ' 100%',
    height: ' 20px',
    marginTop: ' -10px',
    overflow: ' hidden',
    lineHeight: ' 20px',
    whiteSpace: ' nowrap',
    textAlign: ' left',
    textOverflow: ' ellipsis',
  },
  '@media (min-width: 1201px) and (max-width: 1535px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 901px) and (max-width: 1200px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 601px) and (max-width: 900px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '4em !important',
    },
  },
  '@media (max-width:600px)': {
    '.ant-select-selector': {
      width: ' 100%',
      height: '3rem !important',
    },
  },
}))
const InputMain = styled(Input)(() => ({
  '&.ant-input': {
    height: ' 3.5rem !important',
    padding: ' 0 11px',
  },
  '@media (min-width: 1201px) and (max-width: 1535px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 901px) and (max-width: 1200px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '3.5rem !important',
    },
  },
  '@media (min-width: 601px) and (max-width: 900px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '4em !important',
    },
  },
  '@media (max-width:600px)': {
    '&.ant-input': {
      width: ' 100%',
      height: '3rem !important',
    },
  },
}))
const FormMain = styled(Form)(() => ({
  '&.ant-form': {
    padding: '0 5%',
    margin: '0 0 auto 0',
  },
  //   '@media (max-width:600px)': {
  //     // '.ant-select-selection--single': {
  //     //   height: '3rem ',
  //     // },
  //   },
}))
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}))
const {Option} = Select
const PersonalInformation = props => {
  const dispatch = useDispatch()
  const {
    onboardingPhone,
    formUpdate,
    deviceId,
    sellerIdentification,
  } = useSelector(state => state.Phone)
  const formRequest = useSelector(state => state.RequestCardReducer.form)
  const {
    countries,
    provinces,
    districts,
    maritalStatus,
    townships
  } = useSelector(state => state.RequestCardReducer)
  const [initialValues, setInitialValues] = useState({
    country: undefined,
    province: undefined,
    district: undefined,
    township: undefined,
    address: undefined,
    build: undefined,
    phone: undefined,
    civilState: undefined,
  })

  const [loading, setLoading] = useState(false)
  const branchOfficeId = useSelector(
    state => state.GeneralInformationReducer.selectedBranchOfficeId,
  )
  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 300)
    dispatch(getOccupations())
    dispatch(getProfessions())
    dispatch(getMaritalStatus())
  }, [])
  const [form] = Form.useForm()
  const onFinish = values => {}
  const onSuccess = success => {
    setLoading(false)
    if (success) {
      props.goToStep(SCREENS.ProfessionalInformation)
    }
  }
  const onCheck = async () => {
    try {
      await form.validateFields()
      setLoading(true)
      let phones = []
      if (formRequest.cellphone) {
        phones.push({
          id: 1,
          typePhone: 'CE',
          area: '507',
          phone: formRequest.cellphone,
          isDefault: true,
          createdBy: SELLER_ID_TYPE,
          extension: null,
          lastModifiedBy: null,
        })
      }

      if (formRequest.phone) {
        phones.push({
          typePhone: 'CA',
          area: '507',
          phone: formRequest.phone,
          isDefault: false,
          createdBy: SELLER_ID_TYPE,
        })
      }
      const payload = {
        ...formUpdate,
        typeIdentityId: '8F3C2EF0-F0D2-4FF0-9863-218D3D494D56',
        branchOfficeId: branchOfficeId,
        sellerId: SELLER_ID_TYPE,
        approvedBy: SELLER_ID_TYPE,
        approvedDate: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
        genderId:
          formRequest.gender === 1 ? GENDER_TYPE.MALE : GENDER_TYPE.FEMALE,
        countryId: formRequest.country,
        countryBirthId: formRequest.countryOfBirth,
        nationalityId: formRequest.nationality,
        systemId: SYSTEM_TYPES.CMF,
        identityNumber: formRequest.identity,
        firstName: formRequest.firstName,
        secondName: formRequest.secondName || null,
        firstSurname: formRequest.lastName,
        secondSurname: formRequest.secondLastName,
        dateOfBirth: formRequest.birthdate,
        idExpirationDate: formRequest.IDExpirationDate,
        phones: phones,
        maritalStatusId: formRequest.civilState,
        emails: [
          {
            typeEmail: 'PE',
            email: formRequest.email,
            isDefault: true,
            createdBy: SELLER_ID_TYPE,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
          },
        ],
        images: [
          {
            typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
            description: 'Cedula Frontal',
            link: formRequest.frontIdentityImage,
            thumbnails: null,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
            createdBy: 'CMF',
          },
          {
            typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
            description: 'Cedula Reverso',
            link: formRequest.backIdentityImage,
            thumbnails: null,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
            createdBy: 'CMF',
          },
          {
            typeImageId: 'A7398CE9-96CA-41AE-9513-AE233C6FEB02',
            description: 'Biometrico',
            link: formRequest.faceImage,
            thumbnails: null,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
            createdBy: 'CMF',
          },
        ],
        address: [
          {
            countryId: formRequest.country,
            provinceId: formRequest.province,
            districtId: formRequest.district,
            townshipId: formRequest.township,
            address: formRequest.address,
            createdBy: SELLER_ID_TYPE,
            buildingHouse: formRequest.build,
            lastModifiedDate: new Date(),
            createdDate: new Date(),
          },
        ],
        statusId: ONBOARDING_STATUS.IN_PROCESS,
        createdBy: SELLER_ID_TYPE,
        deviceId: Number(deviceId),
        IdentityVendor: sellerIdentification,
      }
      dispatch(
        updateOnboadingPhone(
          onboardingPhone.id,
          onboardingPhone.origin,
          payload,
          onSuccess,
        ),
      )
    } catch (errorInfo) {
      setLoading(false)
    }
  }
  const handleChange = e => {
    dispatch(editFormRequest({[e.target.id]: e.target.value}))
  }
  
  const prevCount = usePrevious(formRequest)
  useEffect(() => {
    if (formRequest.country != prevCount?.country) {
      dispatch(getProvinces(formRequest.country))
    }
    if (formRequest.province != prevCount?.province) {
      dispatch(getDistricts(formRequest.province))
    }
    if (formRequest.district != prevCount?.district) {
      dispatch(getTownships(formRequest.district))
    }
  }, [formRequest])

  useEffect(() => {
    if (formRequest) {
      setInitialValues({
        country: formRequest.country,
        province: formRequest.province,
        district: formRequest.district,
        township: formRequest.township,
        address: formRequest.address,
        build: formRequest.build,
        phone: formRequest.phone,
        civilState: formRequest.civilState,
      })
    }
  }, [formRequest])
  useEffect(() => {
    if (
      formRequest.province != prevCount?.province &&
      prevCount?.province !== undefined
    ) {
      setInitialValues({
        country: formRequest.country,
        province: formRequest.province,
        district: undefined,
        township: undefined,
        address: formRequest.address,
        build: formRequest.build,
        phone: formRequest.phone,
        civilState: formRequest.civilState,
      })
      dispatch(editFormRequest({'district': undefined}))
      dispatch(editFormRequest({'township': undefined}))
    }
    if (
      formRequest.district != prevCount?.district &&
      prevCount?.district !== undefined
    ) {
      setInitialValues({
        country: formRequest.country,
        province: formRequest.province,
        district: formRequest.district,
        township: undefined,
        address: formRequest.address,
        build: formRequest.build,
        phone: formRequest.phone,
        civilState: formRequest.civilState,
      })
      dispatch(editFormRequest({'township': undefined}))
    }
    if (formRequest.township != prevCount?.township) {
      setInitialValues({
        country: formRequest.country,
        province: formRequest.province,
        district: formRequest.district,
        township: formRequest.township,
        address: formRequest.address,
        build: formRequest.build,
        phone: formRequest.phone,
        civilState: formRequest.civilState,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRequest])
  form.setFieldsValue(initialValues)
  const handleChangePhone = e => {
    const valor = e.target.value

    const patronNumeros = /^[0-9]+$/

    if (patronNumeros.test(valor)) {
      dispatch(editFormRequest({[e.target.id]: e.target.value}))
    }
  }
  return (
    <Container>
      <Wrapper style={{flex: 0.2, background: 'rgb(41, 164, 56)'}}>
        <AsideLogo positionX="left" positionY="top" color="white" hugColor='#FFF'/>
      </Wrapper>

      <Wrapper
        style={{
          flex: 1,
          height: '100%',
          justifyContent: 'center',
        }}>
        <ProgressBar active={2} />
        <FormMain
          name="basic"
          form={form}
          autoComplete="off"
          initialValues={initialValues}
          onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col xl={6} xs={24} lg={6}>
              <Form.Item name="country" rules={formFields.country?.rules}>
                <SelectMain
                  placeholder="País de residencia"
                  onSelect={value =>
                    dispatch(editFormRequest({'country': value}))
                  }>
                  {countries?.map(item => {
                    return (
                      <Option value={item.value} label={item.label}>
                        {item.label}
                      </Option>
                    )
                  })}
                </SelectMain>
              </Form.Item>
            </Col>
            <Col xl={6} xs={24} lg={6}>
              <Form.Item name="province" rules={formFields.province?.rules}>
                <SelectMain
                  placeholder="Provincia"
                  onSelect={value => {
                    dispatch(editFormRequest({'province': value}))
                  }}>
                  {provinces?.map(item => {
                    return (
                      <Option
                        data-testid="provin"
                        value={item.value}
                        label={item.label}>
                        {item.label}
                      </Option>
                    )
                  })}
                </SelectMain>
              </Form.Item>
            </Col>
            <Col xl={6} xs={24} lg={6}>
              <Form.Item name="district" rules={formFields.district?.rules}>
                <SelectMain
                  placeholder="Distrito"
                  onSelect={value =>
                    dispatch(editFormRequest({'district': value}))
                  }>
                  {districts?.map(item => {
                    return (
                      <Option
                        data-testid="distric"
                        value={item.value}
                        label={item.label}>
                        {item.label}
                      </Option>
                    )
                  })}
                </SelectMain>
              </Form.Item>
            </Col>
            <Col xl={6} xs={24} lg={6}>
              <Form.Item name="township" rules={formFields.township?.rules}>
                <SelectMain
                  placeholder="Corregimiento"
                  onSelect={value =>
                    dispatch(editFormRequest({'township': value}))
                  }>
                  {townships?.map(item => {
                    return (
                      <Option
                        data-testid="town"
                        value={item.value}
                        label={item.label}>
                        {item.label}
                      </Option>
                    )
                  })}
                </SelectMain>
              </Form.Item>
            </Col>
            <Col xl={12} xs={24} lg={12}>
              <Form.Item name="address" rules={formFields.address?.rules}>
                <InputMain
                  id="address"
                  onChange={handleChange}
                  placeholder="Dirección de residencia"
                />
              </Form.Item>
            </Col>
            <Col xl={12} xs={24} lg={12}>
              <Form.Item name="build" rules={formFields.build?.rules}>
                <InputMain
                  id="build"
                  onChange={handleChange}
                  placeholder="Nombre de edificio, piso, apartamento o número de casa"
                />
              </Form.Item>
            </Col>
            <Col xl={12} xs={24} lg={12}>
              <Form.Item name="phone" rules={formFields.phone?.rules}>
                <InputMain
                  id="phone"
                  onChange={handleChangePhone}
                  placeholder={formFields.phone.placeholder}
                  maxLength={7}
                  minLength={7}
                />
              </Form.Item>
            </Col>
            <Col xl={12} xs={24} lg={12}>
              <Form.Item name="civilState" rules={formFields.civilState?.rules}>
                <SelectMain
                  data-testid="selected"
                  placeholder="Estado civil"
                  onSelect={value =>
                    dispatch(editFormRequest({'civilState': value}))
                  }>
                  {maritalStatus?.map((item, index) => {
                    return (
                      <Option
                        data-testid="civil"
                        value={item.value}
                        label={item.label}>
                        {item.label}
                      </Option>
                    )
                  })}
                </SelectMain>
              </Form.Item>
            </Col>
          </Row>
        </FormMain>
        <FooterOnboar
          back={props.canReturn ? canReturnMobile(props, 4) : null}
          next={
            <ButtonNext
              background="green"
              border="green"
              data-testid="btn-next-personal"
              loading={loading}
              size="large"
              onClick={onCheck}>
              Siguiente
            </ButtonNext>
          }
        />
      </Wrapper>
    </Container>
  )
}

export default PersonalInformation

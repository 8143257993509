//icons Benefits
import compra from 'src/assets/icons/cmf_linea/Benefits/compra.svg';
import precio from 'src/assets/icons/cmf_linea/Benefits/precio.svg';
import calendar from 'src/assets/icons/cmf_linea/Benefits/calendar.svg';
import puntoGordo from 'src/assets/icons/cmf_linea/Benefits/puntoGordo.svg';
import phoneApp from 'src/assets/icons/cmf_linea/Benefits/phoneApp.svg';
import CompraSinContacto from 'src/assets/icons/cmf_linea/Benefits/compraSinContacto.svg';
import security from 'src/assets/icons/cmf_linea/Benefits/security.svg';
import enviorecibo from 'src/assets/icons/cmf_linea/Benefits/enviorecibo.svg';
import retiro from 'src/assets/icons/cmf_linea/Benefits/retiro.svg';
import concierge from 'src/assets/icons/cmf_linea/Benefits/concierge.svg';
import shield from 'src/assets/icons/cmf_linea/Benefits/shield.svg';
import wifi from 'src/assets/icons/cmf_linea/Benefits/wifi.svg';
import garantia from 'src/assets/icons/cmf_linea/Benefits/garantia.svg';
import servicoviajero from 'src/assets/icons/cmf_linea/Benefits/servicioviajero.svg';
import restaurant from 'src/assets/icons/cmf_linea/Benefits/restaurant.svg';
import medical from 'src/assets/icons/cmf_linea/Benefits/medical.svg';
import car from 'src/assets/icons/cmf_linea/Benefits/car.svg';
import ruta from 'src/assets/icons/cmf_linea/Benefits/ruta.svg';
import maleta from 'src/assets/icons/cmf_linea/Benefits/maleta.svg';
import avion from 'src/assets/icons/cmf_linea/Benefits/avion.svg';
// Image Cards
import TarjetaDigital from 'src/assets/icons/cmf_linea/Benefits/TarjetaDigital.webp';
import TarjetaDigitalMastercard from 'src/assets/icons/cmf_linea/Benefits/TarjetaDigitalMastercard.webp';
import TarjetaMastercardBlack from 'src/assets/icons/cmf_linea/Benefits/TarjetaMastercardBlack.webp';
import TarjetaPrepago from 'src/assets/icons/cmf_linea/Benefits/TarjetaPrepago.webp';
// Benefits
export const BenefitsCardTDC = [
  {
    imageCard: TarjetaDigital,
  },
  {
    TitleH1: 'Tarjeta Digital CMF',
    TitleH2: 'Sin costos de membresía o anualidad',
  },
  {
    key: '1',
    icon: compra,
    title: 'Comercios Aliados',
    subtitle:
      'Realizar compras en comercios aliados Novey, Cochez y Kohler Signature Store by Cochez',
  },
  {
    key: '2',
    icon: precio,
    title: 'Precios CMF',
    subtitle:
      'Descuentos mensuales en más de 100 productos al comprar con tu tarjeta en comercios aliados',
  },
  {
    key: '3',
    icon: calendar,
    title: 'Compra en cuotas',
    subtitle: 'Realizar compras en cuotas fijas de 6 a 36 meses',
  },
  {
    key: '4',
    icon: puntoGordo,
    title: 'Puntos Gordos',
    subtitle:
      'Por cada compra en Cochez y Novey pagando con tu tarjeta CMF, acumulas doble puntaje',
  },
  {
    key: '5',
    icon: phoneApp,
    title: 'App CMF',
    subtitle: 'Ten el control de tu tarjeta en la palma de tu mano',
  },
];
export const BenefitsCardPrepago = [
  {
    imageCard: TarjetaPrepago,
  },
  {
    TitleH1: 'Tarjeta Prepago CMF Mastercard',
    TitleH2: 'Sin costos de membresía o anualidad',
  },
  {
    key: '1',
    icon: compra,
    title: 'Compras',
    subtitle:
      'Realizar compras en todos los comercios a nivel nacional o en el extranjero de forma presencial y por internet',
  },
  {
    key: '2',
    icon: CompraSinContacto,
    title: 'Compra sin contacto',
    subtitle: 'Paga sin contacto con Apple Pay o el wallet de CMF en Android',
  },
  {
    key: '3',
    icon: security,
    title: 'Seguridad',
    subtitle: 'Mayor seguridad al no tener que llevar efectivo contigo',
  },
  {
    key: '4',
    icon: precio,
    title: 'Precios CMF',
    subtitle:
      'Descuentos mensuales en más de 100 productos al comprar con tu tarjeta en comercios aliados',
  },
  {
    key: '5',
    icon: phoneApp,
    title: 'App CMF',
    subtitle:
      'Realiza las recargas y ten el control de tu tarjeta con el app de CMF',
  },
  {
    key: '6',
    icon: enviorecibo,
    title: 'Envío y recibo de dinero',
    subtitle:
      'Enviar y recibir dinero de la red de amigos que tengan su Tarjeta Prepago CMF Mastercard',
  },
  {
    key: '7',
    icon: retiro,
    title: 'Retiro de efectivo',
    subtitle:
      'Realizar retiros de efectivo en las cajas de los comercios aliados (Novey y Cochez)',
  },
];
export const BenefitsCardTDCMastercard = [
  {
    imageCard: TarjetaDigitalMastercard,
  },
  {
    TitleH1: 'Tarjeta Digital CMF Mastercard',
    TitleH2: 'Sin costos de membresía o anualidad',
  },
  {
    key: '1',
    icon: compra,
    title: 'Compras',
    subtitle:
      'Realizar compras en todos los comercios a nivel nacional o en el extranjero de forma presencial y por internet',
  },
  {
    key: '2',
    icon: CompraSinContacto,
    title: 'Compra sin contacto',
    subtitle: 'Paga sin contacto con Apple Pay o el wallet de CMF en Android',
  },
  {
    key: '3',
    icon: calendar,
    title: 'Compra en cuotas',
    subtitle:
      'Realizar compras en cualquier comercio y conviértelas en cuotas fijas de 6 a 36 meses ',
  },
  {
    key: '4',
    icon: precio,
    title: 'Precios CMF',
    subtitle:
      'Descuentos mensuales en más de 100 productos al comprar con tu tarjeta en comercios aliados',
  },
  {
    key: '5',
    icon: phoneApp,
    title: 'App CMF',
    subtitle:
      'Realiza las recargas y ten el control de tu tarjeta con el app de CMF',
  },
];
export const BenefitsCardTDCMastercardBlack = [
  {
    imageCard: TarjetaMastercardBlack,
  },
  {
    TitleH1: 'Tarjeta Digital CMF Mastercard Black',
    TitleH2: 'Sin costos de membresía o anualidad',
  },
  {
    key: '1',
    icon: compra,
    title: 'Compras',
    subtitle:
      'Realizar compras en todos los comercios a nivel nacional o en el extranjero de forma presencial y por internet',
  },
  {
    key: '2',
    icon: CompraSinContacto,
    title: 'Compra sin contacto',
    subtitle: 'Paga sin contacto con Apple Pay o el wallet de CMF en Android',
  },
  {
    key: '3',
    icon: calendar,
    title: 'Compra en cuotas',
    subtitle:
      'Realizar compras en cualquier comercio y conviértelas en cuotas fijas de 6 a 36 meses ',
  },
  {
    key: '4',
    icon: precio,
    title: 'Precios CMF',
    subtitle:
      'Descuentos mensuales en más de 100 productos al comprar con tu tarjeta en comercios aliados',
  },
  {
    key: '5',
    icon: phoneApp,
    title: 'App CMF',
    subtitle:
      'Realiza las recargas y ten el control de tu tarjeta con el app de CMF',
  },
  {
    key: '6',
    icon: concierge,
    title: 'Servicio de Concierge',
    subtitle:
      'Ten tu asistente personal las 24 horas del día, los 7 días de la semana',
  },
  {
    key: '7',
    icon: shield,
    title: 'Compra protegida',
    subtitle:
      'Protección de la mayoría de las compras realizadas con tu tarjeta CMF Mastercard ',
  },
  {
    key: '8',
    icon: wifi,
    title: 'Boingo ilimitado',
    subtitle:
      'WiFi con acceso ilimitado gratuito a más de 1 millón de hotspots alrededor del mundo con una velocidad de banda ancha hasta 4 veces mayor que el promedio global',
  },
  {
    key: '9',
    icon: garantia,
    title: 'Garantía extendida',
    subtitle:
      'Extiende el período de garantía original del fabricante o el de la marca de la tienda garantizando hasta (1) año completo para los artículos que compres que estén cubiertos. Una compra cubierta debe tener un período de garantía mínimo de (3) meses, sin exceder el periodo máximo de garantía de tres (3) años',
  },
  {
    key: '10',
    icon: servicoviajero,
    title: 'Servicio de asistencia al viajero',
    subtitle:
      'Proporcionan información sobre el destino previo al viaje, referencias para emergencias médicas y legales, rastreo de equipaje perdido y más',
  },
  {
    key: '11',
    icon: restaurant,
    title: 'Mastercard Travel Services',
    subtitle:
      'Reciba upgrades, desayuno gratuito, early check-in/late check-out y servicios especiales en más de 1,300 hoteles y resorts de 4 y 5 estrellas alrededor del mundo',
  },
  {
    key: '12',
    icon: medical,
    title: 'MasterAssist Black',
    subtitle:
      'En caso de sufrir una lesión o enfermedad y necesitar atención médica mientras está fuera de su país de residencia durante su viaje cubierto, su tarjeta Mastercard Black le brindará coordinación y asistencia para atender cualquier problema',
  },
  {
    key: '13',
    icon: car,
    title: 'Master Rental',
    subtitle:
      'Al alquilar un vehículo utilizando su tarjeta Mastercard Black, usted recibirá cobertura global por daños causados en caso de colisión contra otro objeto, robo, vandalismo, incendio accidental y daño físico relacionado con el clima',
  },
  {
    key: '14',
    icon: ruta,
    title: 'Inconvenientes de viaje',
    subtitle:
      'Planea tranquilo tu viaje sabiendo que los gastos de viaje y alojamiento pagados por adelantado están cubiertos si un viaje se cancela de manera inesperada o se retrasa',
  },
  {
    key: '15',
    icon: maleta,
    title: 'Pérdida o atraso de equipaje',
    subtitle:
      'Planee su viaje tranquilo, sabiendo que los gastos derivados de la pérdida o demora de su equipaje están cubiertos',
  },
  {
    key: '16',
    icon: avion,
    title: 'Mastercard Airport Experiences',
    subtitle:
      'Ingresa directamente a más de 1,100 salas VIP a nivel mundial directamente con tu Mastercard Black. Además, disfruta experiencias de ofertas seleccionadas en restaurantes, spas y tiendas en más de 600 aeropuertos',
  },
];

import React, { useState } from 'react'
import LayoutMultiSelect from './Layout/LayoutMultiSelect'
import PersonalForm from './PersonalForm'
import PoliticalForm from './PoliticalForm'
import ProfessionalForm from './ProfessionalForm'

/**
 * The function `ActividadLaboral` returns a component that renders a multi-step form with different
 * sections for data verification, personal information, professional information, and political
 * questions.
 * @returns The `ActividadLaboral` component is returning a `LayoutMultiSelect` component with the
 * `verificationStep` and `steps` props passed to it.
 */
const ActividadLaboral = (props) => {
  const [verificationStep, setVerificationStep] = useState(0)

  const stepProps = {
    verificationStep,
    setVerificationStep,
    props
  }

  const steps = {
    0: {
      name: 'INFORMACIÓN PERSONAL',
      component: <PersonalForm {...stepProps} />,
    },
    1: {
      name: 'INFORMACIÓN PROFESIONAL',
      component: <ProfessionalForm {...stepProps} />,
    },
    2: {
      name: 'PREGUNTAS DE SELECCIÓN',
      component: <PoliticalForm {...stepProps} />,
    },
  }
  return (
    <LayoutMultiSelect verificationStep={verificationStep} steps={steps} />
  )
}

export default ActividadLaboral

import React from 'react'
import {styled} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import PropTypes from 'prop-types'

const ColorlibStepIconRoot = styled('div')(({theme, ownerState}) => ({
  zIndex: 1,
  color: '#fff',
  width: '55px',
  height: '55px',
  fontSize: 24,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#9EA1A8',
  ...(ownerState.active && {
    backgroundColor: '#026E18',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    //background: `url(${checkImage}) no-repeat center center`,
    //backgroundSize: 'center',
    //backgroundColor: '#9EA1A8 !important',
    //color: 'transparent',
    '& .MuiStepLabel-label': {},
  }),

  // for small size
  '@media (max-width: 600px)': {
    width: '38px',
    height: '38px',
    font: 'normal normal normal 20px/39px Red Hat Text',
  },
}))

const ColorlibStepIcon = props => {
  const {active, completed, className, title} = props

  return (
    <ColorlibStepIconRoot
      ownerState={{completed, active}}
      className={className}>
      {completed ? <CheckIcon /> : title}
    </ColorlibStepIconRoot>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
}

export default ColorlibStepIcon
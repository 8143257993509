import PropTypes from 'prop-types';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ORIGIN} from 'src/utils/Constants';
import {setStep} from '../../store/actions/Steps.Action.js';
import CommonPage from './Layout/LayoutPageCommon.js';
import SCREENS from './screens.js';

const CreditApproved = props => {
  const {origin, limite, onboardingPrepago} = useSelector(
    state => state.RequestCardReducer,
  );
  const {Matrix, planChubb} = useSelector(state => state.KOReducer);
  const dispatch = useDispatch();
  const nextStep = () => {
    if (onboardingPrepago) {
      dispatch(setStep(SCREENS.ReadPdfPrepago));
      props.goToStep(SCREENS.ReadPdfPrepago);
    } else {
      dispatch(setStep(SCREENS.ReadAndSignPDF));
      props.goToStep(SCREENS.ReadAndSignPDF);
    }
  };
  /**
   * Safely rounds a value to the nearest integer.
   * If the input is not a number, returns a default value.
   *
   * @param {number} value - The value to be rounded.
   * @param {number} [defaultValue=0] - The default value to return if input is not a number.
   * @return {number} - The rounded number or default value.
   */
  const safeRound = (value, defaultValue = 0) => {
    if (typeof value === 'number' && !isNaN(value)) {
      return Math.round(value);
    }
    return defaultValue;
  };

  // Assuming Matrix is an object that may have an 'amountApproved' property
  const limitValue = safeRound(
    origin === ORIGIN.PREAPROBADO ? limite : Matrix?.amountApproved,
  );

  const labels = {
    headingLabel: '¡Has finalizado el proceso!',
    titleLabel: `Tu tarjeta tendrá un límite de $${limitValue}`,
    subtitleLabel:
      'Tu seguro de Accidentes Personales ya se encuentra activo \npor $4 mensuales',
    subtitleLabel2:
      'Procede a firmar el contrato de la tarjeta digital CMF seleccionando el siguiente enlace',
    buttonLabel: 'Firmar contrato',
  };
  const labelsPrepago = {
    headingLabel: '¡Felicitaciones!',
    titleLabel: 'Tu Tarjeta Prepago CMF fue aprobada',
    subtitleLabel2: (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          textAlign: 'initial',
        }}>
        <li>Compra en cualquier comercio nacional o internacional</li>
        <li>Recarga dinero desde el app de CMF</li>
        <li>Paga fácil y seguro</li>
        <li>
          Realiza pagos sin contacto con Apple Pay o el wallet de CMF en Android
        </li>
      </div>
    ),
    subtitleLabel3:
      'Procede a firmar el contrato de tu Tarjeta Prepago CMF seleccionando el siguiente enlace',

    buttonLabel: 'Firmar contrato',
  };

  return (
    <div className="min-h-[100vh]">
      <CommonPage
        buttonOnClick={nextStep}
        labels={onboardingPrepago ? labelsPrepago : labels}
        buttonClasses="font-thin mr-10"
        buttonText={'Firmar contrato'}
        planChubb={planChubb}
      />
    </div>
  );
};

CreditApproved.propTypes = {
  goToStep: PropTypes.func,
};

export default CreditApproved;

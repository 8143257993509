// ----------------------------------------------------------------------

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({xxs, ax, am, sm1, sm, md, lg, xl, xxl}) {
  return {
    '@media (min-width:234px)': {
      fontSize: pxToRem(xxs),
    },
    '@media (min-width:375px)': {
      fontSize: pxToRem(ax),
    },
    '@media (min-width:412px)': {
      fontSize: pxToRem(am),
    },
    '@media (min-width:599px)': {
      fontSize: pxToRem(sm1),
    },
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
    '@media (min-width:1536px)': {
      fontSize: pxToRem(xl),
    },
    '@media (min-width:1921px)': {
      fontSize: pxToRem(xxl),
    },
  };
}

// ----------------------------------------------------------------------

export const FONT_PRIMARY = 'Red Hat Text';
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 800,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({sm: 52, md: 58, lg: 64}),
  },
  h2: {
    fontWeight: 800,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({sm: 40, md: 44, lg: 48}),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({sm: 26, md: 30, lg: 32}),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({sm: 20, md: 24, lg: 24}),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({sm: 19, md: 20, lg: 20}),
  },
  h6: {
    fontWeight: 500,
    lineHeight: 28 / 18,
    color: '#000000',
    fontSize: pxToRem(14),
    ...responsiveFontSizes({sm: 14, md: 14, lg: 14}),
  },
  h7: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: 28 / 18,
    color: '#026E18',
    fontSize: pxToRem(14),
    ...responsiveFontSizes({sm: 14, md: 14, lg: 14}),
  },
  prom: {
    color: '#FFF',
    fontFamily: FONT_PRIMARY,
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px /* 120% */',
    letterSpacing: '-0.4px',
  },
  titleModal: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    color: '#101828',
    fontSize: pxToRem(16),
    letterSpacing: '-0.36px',
    lineHeight: '22px',
    ...responsiveFontSizes({sm: 14, md: 14, lg: 18}),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  subtitle3: {
    fontWeight: 500,
    lineHeight: 22 / 14,
    fontSize: pxToRem(19),
  },
  subtitle4: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(16),
    alignSelf: 'stretch',
    color: ' #101828',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px ',
    letterSpacing: '-0.16px',
  },
  subtitle5: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(18),
    alignSelf: 'stretch',
    color: ' #101828CC',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '-0.02em',
  },
  subtitle6: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(18),
    alignSelf: 'stretch',
    color: ' #026E18',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '-0.02em',
  },
  subtitle7: {
    paddingTop: '1rem',
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(18),
    color: '#1018288A',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '-0.02em',
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    ...responsiveFontSizes({sm: 12, md: 14, lg: 14}),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  moneyCard: {
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '-0.48px',
    lineHeight: '28px',
    fontSize: pxToRem(24),
    fontFamily: FONT_PRIMARY,
  },
  textCard: {
    textAlign: 'center',
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(12),
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '-0.2px',
    fontFeatureSettings: 'normal',
    fontVariant: 'normal',
  },

  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
  // use typography
  H1: {
    fontWeight: 500,
    lineHeight: 22 / 14,
    fontSize: pxToRem(19),
  },
  H2: {
    fontFamily: FONT_PRIMARY,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '28px /* 128.571% */',
    color: '#101828CC',
  },
  H4: {
    fontWeight: 400,
    lineHeight: '18px',
    fontSize: '1rem',
    fontFamily: FONT_PRIMARY,
    color: 'rgba(16, 24, 40, 0.64)',
    textTransform: 'none',
  },
  H5: {
    fontFamily: FONT_PRIMARY,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px /* 112.5% */',
    textTransform: 'none',
  },
  H6: {
    color: '#101828',
    fontFamily: 'Red Hat Text',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px /* 128.571% */',
    textTransform: 'none',
  },
  titleLogin: {
    alignSelf: 'stretch',
    fontFamily: 'Red Hat Text',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '32px /* 114.286% */',
    letterSpacing: '-0.56px',
    textTransform: 'none',
  },
  //Adicional text
  textGray: {
    alignSelf: 'stretch',
    color: 'rgba(16, 24, 40, 0.64)',
    fontFamily: FONT_PRIMARY,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px /* 125% */',
    letterSpacing: '-0.16px',
    textTransform: 'none',
  },
  textGreen: {
    alignSelf: 'stretch',
    color:
      'var(--specific-light-content-80, var(--specific-light-content-80, rgba(16, 24, 40, 0.80)))',
    fontFamily: 'Red Hat Text',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px /* 128.571% */',
    textTransform: 'none',
  },
  BenefitsTitleH1: {
    alignSelf: 'stretch',
    color:
      'var(--specific-light-content-80, var(--specific-light-content-80, #101828))',
    fontFamily: 'Red Hat Text',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '28px /* 128.571% */',
    textTransform: 'none',
  },
  BenefitsTitleH2: {
    alignSelf: 'stretch',
    color:
      'var(--specific-light-content-64, var(--specific-light-content-80, #101828A3))',
    fontFamily: 'Red Hat Text',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px /* 128.571% */',
    textTransform: 'none',
  },
  BenefitsTitle: {
    alignSelf: 'stretch',
    color:
      'var(--specific-light-content-64, var(--specific-light-content-80, #101828CC))',
    fontFamily: 'Red Hat Text',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px /* 128.571% */',
    textTransform: 'none',
  },
  BenefitsSubtitle: {
    alignSelf: 'stretch',
    color:
      'var(--specific-light-content-64, var(--specific-light-content-64, #101828A3))',
    fontFamily: 'Red Hat Text',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px /* 128.571% */',
    textTransform: 'none',
  },
  titleBanner: {
    alignSelf: 'stretch',
    color:
      'var(--specific-light-content-black, var(--specific-light-content-black, #101828))',
    fontFamily: 'Red Hat Text',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '22px /* 122.222% */',
    letterSpacing: '-0.36px',
    textTransform: 'none',
  },
  subtitleBanner: {
    alignSelf: 'stretch',
    color:
      'var(--specific-light-content-64, var(--specific-light-content-64, rgba(16, 24, 40, 0.64)))',
    fontFamily: 'Red Hat Text',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px /* 128.571% */',
    textTransform: 'none',
  },
  amountStyle: {
    color: 'var(--specific-light-semantic-brand-secondary-verde, #026E18)',
    textAlign: 'center',
    fontFamily: 'Red Hat Text',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: 460,
    lineHeight: '50px /* 104.167% */',
    letterSpacing: '-2px',
    textTransform: 'none',
  },
  detailsExtra: {
    color:
      'var(--specific-light-content-64, var(--specific-light-content-64, rgba(16, 24, 40, 0.64)))',
    fontFamily: 'Red Hat Text',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px /* 128.571% */',
    textTransform: 'none',
  },
  detailsAmount: {
    color: 'var(--specific-light-semantic-brand-secondary-verde, #026E18)',
    textAlign: 'right',
    fontFamily: 'Red Hat Text',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px /* 128.571% */',
    textTransform: 'none',
  },
  buttonStyle: {
    color: '#FFF',
    fontFamily: 'Red Hat Text',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px /* 150% */',
    letterSpacing: '-0.16px',
    textTransform: 'none',
  },
  alertTitle: {
    alignSelf: 'stretch',
    color:
      'var(--specific-light-content-black, var(--specific-light-content-black, #101828))',
    textAlign: 'center',
    fontFamily: 'Red Hat Text',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px /* 120% */',
    letterSpacing: '-0.4px',
    textTransform: 'none',
  },
  alertSubtitle: {
    alignSelf: 'stretch',
    color:
      'var(--specific-light-content-64, var(--specific-light-content-64, rgba(16, 24, 40, 0.64)))',
    textAlign: 'center',
    fontFamily: 'Red Hat Text',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px /* 128.571% */',
    textTransform: 'none',
  },
  subtitleAmountDetails: {
    color:
      'var(--specific-light-content-54, var(--specific-light-content-54, rgba(16, 24, 40, 0.54)))',
    textAlign: 'center',
    fontFamily: 'Red Hat Text',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px /* 125% */',
    letterSpacing: '-0.16px',
    textTransform: 'none',
  },
  subtitlePay: {
    fontFamily: FONT_PRIMARY,
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    textTransform: 'none',
  },
  PaySuccess: {
    fontFamily: FONT_PRIMARY,
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    alignSelf: 'stretch',
    textAlign: 'center',
    lineHeight: '24px',
    letterSpacing: '-0.4px',
    textTransform: 'none',
  },
  nav: {
    lineHeight: '18px',
    fontSize: pxToRem(16),
    fontFamily: FONT_PRIMARY,
    ...responsiveFontSizes({sm: 16, md: 15, lg: 18}),
    color: '#98A2B3',
    textTransform: 'none',
  },
  description: {
    color:
      'var(--specific-light-content-64, var(--specific-light-content-64, rgba(16, 24, 40, 0.64)))',
    /* Detail/M */
    fontFamily: FONT_PRIMARY,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    textTransform: 'none',
  },
  infoTermsDisplay: {
    alignSelf: 'stretch',
    color: '#737373',
    textAlign: 'center',
    fontFamily: 'Red Hat Text',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px /* 133.333% */',
    textTransform: 'none',
  },
  errors: {
    alignSelf: 'stretch',
    color: '#FF4842',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: '16px /* 133.333% */',
    marginLeft: '1rem !important',
    textTransform: 'none',
  },
  chubbSubtitle: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 'normal',
    fontSize: '14px',
    letterSpacing: '0px',
    color: '#707070',
    textAlign: 'center',
    opacity: 1,
  },
  app: {
    alignSelf: 'stretch',
    color:
      'var(--specific-light-content-64, var(--specific-light-content-64, rgba(16, 24, 40, 0.64)))',
    fontFamily: 'Red Hat Text',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px /* 133.333% */',
    letterSpacing: '-0.2px',
  },

  blockedText: {
    fontWeight: 500,
    fontFamily: FONT_PRIMARY,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.2px',
    color: '#101828',
    textAlign: 'start',
    opacity: 1,
  },
  verificationInfo: {
    color: '#000000',
    lineHeight: '32px',
    fontFamily: FONT_PRIMARY,
    ...responsiveFontSizes({
      xxs: 18,
      ax: 18,
      am: 25,
      sm1: 25,
      sm: 25,
      md: 25,
      lg: 28,
      xl: 30,
      xxl: 35,
    }),
    textAlign: 'center',
  },

  //Modal title
  modalTitle: {
    color: '#011E41',
    fontFamily: 'Red Hat Text',
    fontWeight: '600',
    fontSize: '50px',
    ...responsiveFontSizes({
      xxs: 25,
      ax: 25,
      am: 28,
      sm1: 28,
      sm: 35,
      md: 40,
      lg: 45,
      xl: 50,
      xxl: 50,
    }),
  },
  modalSubtitle: {
    color: '#000000',
    fontWeight: '400',
    // fontSize: '35px',
    ...responsiveFontSizes({
      xxs: 18,
      ax: 18,
      am: 18,
      sm: 20,
      sm1: 25,
      md: 25,
      lg: 27,
      xl: 35,
      xxl: 35,
    }),
  },
};

export default typography;

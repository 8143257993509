import PropTypes from 'prop-types'
import React from 'react'
import Button from 'src/components/Button/ButtonCommon'
import MobileImg from '../../../../assets/images/onboardingCredito/Enmascarar grupo 3.png'
import VirtualAssistantImage from '../../../../assets/images/onboardingCredito/Enmascarar grupo 3@2x.png'

export const RejectedScreen = ({
  heading,
  title = '',
  subtitle1,
  buttonText,
  subtitleMobile,
  buttonOnClick,
  classNameSubtitleMobile = '',
  showDiv = 'lg:flex hidden',
}) => {
  return (
    <>
      <div className='grid grid-cols-12 min-h-[100vh]  w-full bg-[#F3F4F7]'>
        <div className='lg:col-span-10 col-span-12  flex flex-col items-center lg:justify-center justify-start  lg:mt-0 mt-12  lg:px-4 md:px-44 sm:20 px-8 '>
          <div className='lg:text-4xl text-[#011E41] text-3xl font-bold   text-center tracking-wider mb-1 text-center'>
            {heading}
          </div>
          <h1 className='lg:flex flex-col items-center justify-center lg:text-3xl text-[22px] font-semibold text-[#011e41] text-center lg:mt-3 mt-5 tracking-wide leading-6'>
            {title}
          </h1>
          <p
            className={`lg:flex text-[18px] item-center justify-center  lg:text-[23px]  font-light text-[#030303] text-center lg:py-10 pt-6 pb-6`}>
            {subtitle1}
          </p>
          <div
            id='buttonExit'
            className='flex item-center w-full justify-center lg:w-48 md:w-96 w-full pt'>
            <Button
              label={buttonText}
              textColor='text-white'
              bg='greenBtnBg'
              onClick={buttonOnClick}
            />
          </div>
        </div>
        <div
          className='col-span-12 sm:hidden block fixed bottom-0 right-0 h-[328px] w-[184px] z-9 items-end'
          style={{width: '184px', height: '328px', zIndex: '99' }}>
          <div className='w-full h-full'>
            <img
              src={VirtualAssistantImage}
              alt=''
              className='w-full h-full object-contain'
            />
          </div>
        </div>
        <div
          className='absolute flex sm:hidden bg-secondary min-w-full lg:py-[17px] py-[10px] bottom-0'
          style={{height: '24px'}}></div>
      </div>
      <div className='absolute ml-auto sm:flex hidden text-white justify-end  ml-10 bottom-0 right-4 z-[99]  w-64 lg:h-[32rem] h-64 col-span-2 w-auto'>
        <img src={MobileImg} alt='' className='object-cover	' />
      </div>
      <div
        className='absolute sm:flex hidden bg-secondary min-w-full lg:py-[17px] py-[10px] bottom-0'
        style={{height: '50.06px'}}></div>
    </>
  )
}

RejectedScreen.propTypes = {
  buttonText: PropTypes.any,
  heading: PropTypes.any,
  showDiv: PropTypes.string,
  subtitle1: PropTypes.any,
  subtitleMobile: PropTypes.any,
  title: PropTypes.string,
}

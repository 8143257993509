import produce from 'immer';

const initialState = {
  product: {},
  products: [],
  Loadingproduct: false,
  productError: false,
  catologue: {},
  catologues: [],
  Loadingcatologue: false,
  catalogueError: false,
  general: {},
  generals: [],
  LoadingGeneral: false,
  generalError: false,
  generalvtc: {},
  generalvtcs: [],
  LoadingGeneralvtc: false,
  generalvtcError: false,

  estadoCuenta: {},
  estadoCuentas: [],
  LoadingestadoCuenta: false,
  estadoCuentaError: false,
  extraCredit: {},
  extraCreditComplete: false,
  loadTransaction: false,
  loadAmount: false,
  calculator: null,
  loadCreateExtraCredit: false,
  loadInsurance: false,
  loadDataInsurance: {},
  verifyActivate: false,
  amount: null,
  isActive: false,
  listActiveProduct: [],
  loadingList: false,
  Extra: false,
  systemId: null,
  listCustomerProducts: null,
  selectedProduct: null,
  loadV: false,
  dataInsuranse: [],
  floatAmount: null,
  statusBlockCard: false,
  blockedCard: null,
  unblockedCard: null,
  loadingBlockCard: false,
  loadingUnblockCard: false,
};

const ActiveProductReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'ACTIVEPRODUCT_SUCCESS':
        draft.products = action.payload;
        break;
      case 'ACTIVEPRODUCT_ERROR':
        draft.productError = action.payload;
        break;
      case 'ACTIVEPRODUCT_SHOW':
        draft.Loadingproduct = true;
        break;
      case 'ACTIVEPRODUCT_HIDE':
        draft.Loadingproduct = false;
        break;
      case 'PRODUCTCATALOGUE_SHOW':
        draft.Loadingcatologue = true;
        break;
      case 'PRODUCTCATALOGUE_SUCCESS':
        draft.catologues = action.payload;
        break;
      case 'PRODUCTCATALOGUE_HIDE':
        draft.Loadingcatologue = false;
        break;
      case 'PRODUCTCATALOGUE_ERROR':
        draft.catalogueError = action.payload;
        break; // aqui te dejo este comentario irving
      case 'GENERAL_ACCOUNT_STATEMENT_SHOW': // si a este case no le pones el break el otro se ejecutara
        draft.LoadingGeneral = true; // por lo tanto este loading general queda activo cuando hay catologue error
        break; // Pdt. esto estaba asi solo lo note no se si se lo pondras xd
      case 'GENERAL_ACCOUNT_STATEMENT_SUCCESS':
        draft.general = action.payload;
        break;
      case 'GENERAL_ACCOUNT_STATEMENT_ERROR':
        draft.generalError = action.payload;
        break;
      case 'GENERAL_ACCOUNT_STATEMENT_HIDE':
        draft.LoadingGeneral = false;
        break;
      case 'GENERAL_ACCOUNT_STATEMENT_CLEAN':
        draft.general = {};
        break;
      case 'GENERAL_ACCOUNT_GROUP_SUCCESS':
        draft.generals = [...draft.generals, action.payload];
        break;
      case 'GENERAL_ACCOUNT_GROUP_CLEAN':
        draft.generals = [];
        break;
      case 'GENERALVTC_ACCOUNT_STATEMENT_SHOW':
        draft.LoadingGeneralvtc = true;
        break;
      case 'GENERALVTC_ACCOUNT_STATEMENT_SUCCESS':
        draft.generalvtc = action.payload;
        break;
      case 'GENERALVTC_ACCOUNT_STATEMENT_ERROR':
        draft.generalvtcError = action.payload;
        break;
      case 'GENERALVTC_ACCOUNT_STATEMENT_HIDE':
        draft.LoadingGeneralvtc = false;
        break;

      case 'ESTADOCUENTA_STATUS_SUCCESS':
        draft.estadoCuenta = action.payload;
        break;
      case 'ESTADOCUENTA_STATUS_ERROR':
        draft.estadoCuentaError = action.payload;
        break;
      case 'ESTADOCUENTA_STATUS_SHOW':
        draft.LoadingestadoCuenta = true;
        break;
      case 'ESTADOCUENTA_STATUS_HIDE':
        draft.LoadingestadoCuenta = false;
        break;
      case 'EXTRA_CREDIT_SUCCESS':
        draft.extraCredit = action.payload;
        break;
      case 'EXTRA_CREDIT_COMPLETE':
        draft.extraCreditComplete = action.payload;
        break;
      case 'LOAD_TRANSACTION':
        draft.loadTransaction = action.payload;
        break;
      case 'LOAD_AMOUNT':
        draft.loadAmount = action.payload;
        break;
      case 'CALCULATOR':
        draft.calculator = action.payload;
        break;
      case 'CALCULATOR_CLEAN':
        draft.calculator = null;
        break;
      case 'LOAD_CREATE_EXTRA_CREDIT':
        draft.loadCreateExtraCredit = action.payload;
        break;
      case 'ACTIVA_INSURANCE_SHOW':
        draft.loadInsurance = true;
        break;
      case 'ACTIVA_INSURANCE_HIDE':
        draft.loadInsurance = false;
        break;
      case 'ISVERIFY_INSURANCE_SUCCESS':
        draft.verifyActivate = action.payload;
        break;
      case 'INSERT_AMOUNT':
        draft.amount = action.payload;
        break;
      case 'ACTIVE_MODAL':
        draft.isActive = action.payload;
        break;
      //branding
      case 'LIST_ACTIVE_PRODUCT':
        draft.listActiveProduct = action.payload;
        break;
      case 'LIST_LOADING_MODAL':
        draft.loadingList = action.payload;
        break;
      case 'EXTRA':
        draft.Extra = action.payload;
        break;
      case 'SYSTEM_ID':
        draft.systemId = action.payload;
        break;
      case 'CARD_DSC':
        draft.card_dscp = action.payload;
        break;
      case 'LIST_CUSTOMERS_PRODUCTS':
        draft.listCustomerProducts = action.payload;
        break;
      case 'SELECTED_PRODUCT':
        draft.selectedProduct = action.payload;
        break;
      case 'CLEAN_V':
        draft.generalvtc = {};
        break;
      case 'LOAD_V':
        draft.loadV = action.payload;
        break;
      case 'GET_INFO_INSURANSE':
        draft.dataInsuranse = action.payload;
        break;
      case 'FLOAT_AMOUNT':
        draft.floatAmount = action.payload;
        break;
      case 'GET_INFO_CARD_BLOCKING':
        draft.statusBlockCard = action.payload;
        break;
      case 'BLOCK_CARD':
        draft.blockedCard = action.payload;
        break;
      case 'UNBLOCK_CARD':
        draft.unblockedCard = action.payload;
        break;
      case 'BLOCK_CARD_LOADING':
        draft.loadingBlockCard = action.payload;
        break;
      case 'UNBLOCK_CARD_LOADING':
        draft.loadingUnblockCard = action.payload;
        break;
      case 'RESET_ACTIVE_PRODUCT':
        return initialState;
      default:
        return state;
    }
  });

export default ActiveProductReducer;

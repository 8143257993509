import React from 'react'
import {createTheme} from '@mui/material/styles'
import styled from '@emotion/styled'
import {PRODUCTS_TYPES, URL_PHONE} from '../../utils/Constants'
import Text from '../../components/Typography/Text.js'
import ButtonNext from '../../components/Button/ButtonNext.js'
import {useSelector} from 'react-redux'
import AsideLogo from '../../components/Aside/AsideLogo'
import AsideCharacter from '../../components/Aside/AsideCharacter'
import {Col, Row} from 'antd'
import emailIcon from '../../assets/images/onboarding/email_white.svg'
import phoneIcon from '../../assets/images/onboarding/phone_in_talk.svg'
import whatsappIcon from '../../assets/images/onboarding/whatsappalternative.svg'
const theme = createTheme()

const RequestInRevision = () => {
  const {isPep, isCrs} = useSelector(state => state.Phone.formUpdate)
  const {typeError} = useSelector(state => state.Phone)
  const {TypeProductId} = useSelector(state => state.RequestCardReducer)
  const onClose = () => {
    window.location.href = `/${URL_PHONE}`
  }

  const renderMessage = () => {
    if (TypeProductId?.toUpperCase() === PRODUCTS_TYPES.TEKY.toUpperCase()) {
      return (
        <DivContainer style={{marginTop: '20%'}}>
          <Text textType="title" style={{color: 'white', lineHeight: 1}}>
            No podemos continuar con tu solicitud porque
          </Text>
          <Text textType="title" style={{color: 'white', lineHeight: 1}}>
            ya tienes un crédito móvil CMF
          </Text>
          <Text
            style={{
              marginTop: 32,
              color: 'white',
              width: '80%',
              lineHeight: 1.2,
            }}
            textType="paragraphs">
            Por políticas de crédito no puede tener más de un crédito a la vez
          </Text>
          <ButtonNext
            background="white"
            border="white"
            onClick={onClose.bind(this)}
            style={{
              marginTop: 42,
              backgroundColor: 'var(--fondo-blanco)',
              color: 'var(--color-primary-main)',
            }}>
            Salir
          </ButtonNext>
        </DivContainer>
      )
    } else if (typeError === 1 || typeError === 2) {
      return (
        <DivContainer style={{marginTop: '20%'}}>
          <Text textType="title" style={{color: 'white', lineHeight: 1}}>
            No podemos continuar con tu solicitud porque{' '}
          </Text>
          <Text textType="title" style={{color: 'white', lineHeight: 1}}>
            tienes un crédito móvil{' '}
            {typeError === 1 ? 'pendiente de aprobación' : 'aprobado'}
          </Text>
          <Text
            style={{
              marginTop: 32,
              color: 'white',
              width: '80%',
              lineHeight: 1.2,
            }}
            textType="paragraphs">
            Para cualquier consulta, contáctanos en cualquiera de nuestros
            canales de atención de lunes a sábado de 7:00 a.m. a 6:00 p.m. y los
            domingos de 9:00 a.m. a 6:00 p.m.
          </Text>
          <RowImg gutter={[16, 16]}>
            <ColEmail xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <ImageIcon src={emailIcon} preview={false} />
              <Font>servicioalcliente@cmf.com.pa</Font>
            </ColEmail>
            <ColPhone xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
              <ImageIcon src={phoneIcon} preview={false} />
              <Font>302-4460</Font>
            </ColPhone>
            <ColCell xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
              <ImageIcon src={whatsappIcon} preview={false} />
              <Font>6597-8134</Font>
            </ColCell>
          </RowImg>
          <ButtonNext
            background="white"
            border="white"
            onClick={onClose.bind(this)}
            style={{
              marginTop: 42,
              backgroundColor: 'var(--fondo-blanco)',
              color: 'var(--color-primary-main)',
            }}>
            Salir
          </ButtonNext>
        </DivContainer>
      )
    }
    return (
      <DivContainer style={{marginTop: '20%'}}>
        <Text textType="title" style={{color: 'white', lineHeight: 1}}>
          Vemos que ya tienes una tarjeta digital,
        </Text>
        <Text textType="title" style={{color: 'white', lineHeight: 1}}>
          te invitamos a usarla para comprar tu celular
        </Text>
        <Text
          style={{
            margin: 0,
            marginTop: 50,
            color: 'white',
            width: '90%',
            lineHeight: 1.2,
          }}
          textType="paragraphs">
          Si requiere un aumento de límite para poder comprar un nuevo celular,
        </Text>
        <Text
          style={{color: 'white', margin: 0, width: '90%', lineHeight: 1.2}}
          textType="paragraphs">
          comuníquese con nosotros al correo electrónico
          servicioalcliente@cmf.com.pa
        </Text>
        <Text
          style={{color: 'white', margin: 0, width: '90%', lineHeight: 1.2}}
          textType="paragraphs">
          e indique el monto que necesita para realizar su compra.
        </Text>
        <ButtonNext
          background="white"
          border="white"
          onClick={onClose.bind(this)}
          style={{
            marginTop: 42,
            backgroundColor: 'var(--fondo-blanco)',
            color: 'var(--color-primary-main)',
          }}>
          Salir
        </ButtonNext>
      </DivContainer>
    )
  }

  const renderContainer = () => {
    if (isPep || isCrs) {
      return (
        <Container style={{flexDirection: 'column'}}>
          <Wrapper style={{flex: 1, height: '100%'}}>
            <DivContainer>
              <Text textType="title">¡Gracias por tu preferencia!</Text>
              <Text textType="subtitle">
                Tu solicitud de crédito será revisada para aprobación
              </Text>
              <Text style={{marginTop: 50, width: '85%'}} textType="paragraphs">
                Basados en la información que nos has proporcionado, revisaremos
                tu solicitud y te estaremos contactando en las siguientes 48
                horas hábiles.
              </Text>
              <ButtonNext
                background="green"
                border="green"
                href={`/${URL_PHONE}`}
                style={{marginTop: 75, zIndex: 100}}>
                Salir
              </ButtonNext>
            </DivContainer>
          </Wrapper>

          <Wrapper
            style={{
              width: '100%',
              height: 300,
              bottom: 0,
              position: 'absolute',
            }}>
            <AsideCharacter type="stand" color="white" positionY="bottom" />
          </Wrapper>
        </Container>
      )
    }
    return (
      <Container style={{flexDirection: 'row'}}>
        <Wrapper style={{flex: 0.2, background: 'var(--fondo-blanco)'}}>
          <AsideLogo positionX="left" positionY="top" color="green" />
        </Wrapper>

        <Wrapper
          style={{
            flex: 1,
            height: '100%',
            background: 'var(--color-primary-main)',
          }}>
          {renderMessage()}
        </Wrapper>
      </Container>
    )
  }

  return renderContainer()
}

const DivContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: '15%',
}))

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const Container = styled('div')(() => ({
  display: 'flex',
  height: '100vh',
}))
const Font = styled('span')(() => ({
  textAlign: 'center',
  color: 'var(--fondo-blanco)',
  [theme.breakpoints.up('xl')]: {
    font: 'normal normal normal 1.3rem/40px Arial',
  },
  [theme.breakpoints.down('xl')]: {
    font: 'normal normal normal 1.2rem/35px Arial',
  },
  [theme.breakpoints.down('lg')]: {
    font: 'normal normal normal 1.1rem/32px Arial',
  },
  [theme.breakpoints.down('md')]: {
    font: 'normal normal normal 1rem/27px Arial',
  },
  [theme.breakpoints.down('sm')]: {
    font: 'normal normal normal 16px/22px Arial',
  },
}))
const ColPhone = styled(Col)(() => ({
  textAlign: 'center',
  display: 'flex',
  [theme.breakpoints.up('xl')]: {
    font: 'normal normal normal 1.5rem/40px Arial',
  },
  [theme.breakpoints.down('md')]: {
    textAlign: 'right',
  },
}))
const ColCell = styled(Col)(() => ({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  [theme.breakpoints.up('xl')]: {},
  [theme.breakpoints.down('lg')]: {
    textAlign: 'left',
    justifyContent: 'initial',
  },
  [theme.breakpoints.down('md')]: {
    textAlign: 'left',
    justifyContent: 'initial',
  },
}))
const RowImg = styled(Row)(() => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    // marginTop: '2%',
    flexWrap: 'wrap',
  },
  [theme.breakpoints.down('lg')]: {
    alignItems: 'center',
    // marginTop: '2%',
    fontSize: '0.8rem',
  },
  [theme.breakpoints.down('xl')]: {
    alignItems: 'center',
    marginTop: '2%',
    width: '100%',
    fontSize: '1rem',
  },
  [theme.breakpoints.up('xl')]: {
    alignItems: 'center',
    marginTop: '2%',
    width: '100%',
  },
}))
const ImageIcon = styled('img')(() => ({
  margin: '2%',
  // filter: 'brightness(5000%)',
  [theme.breakpoints.up('xl')]: {
    height: '25px',
    width: '25px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '18px',
    height: '18px',
  },
}))
const ColEmail = styled(Col)(() => ({
  textAlign: 'center',
  justifyContent: 'center',
  display: 'flex',
  [theme.breakpoints.up('xl')]: {
    font: 'normal normal normal 2.5vh/40px Arial',
  },
  [theme.breakpoints.down('xl')]: {
    font: 'normal normal normal 2.5vh/40px Arial',
    alignItems: 'center',
  },
  [theme.breakpoints.down('md')]: {
    font: 'normal normal normal 1.3rem/20px Arial',
  },
  [theme.breakpoints.down('sm')]: {
    font: 'normal normal normal 16px/20px Arial',
    alignItems: 'center',
  },
}))
export default RequestInRevision

import React, {useEffect, useState} from 'react'

function SpaceBr({minSize}) {
  const [active, setActive] = useState(false)
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth
      if (screenWidth <= minSize) {
        setActive(true)
      } else if (screenWidth >= minSize) {
        setActive(false)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [minSize])
  return <>{active && <br />}</>
}

export default SpaceBr

import React from 'react';
import {useSelector} from 'react-redux';
import WrapperSide from 'src/components/Aside/Wrapper';
import ButtonNextOnboarding from 'src/components/Button/ButtonNextOnboarding';
import CMFLogo from 'src/components/Logos/CMF';
import SpaceBr from 'src/components/Space/space';
import SubtitleResponsiveOnboarding from 'src/components/TitleInput/SubtitleResponsiveOnboarding';
import TitleResponsiveOnboarding from 'src/components/TitleInput/TitleResponsiveOnboarding';
import {BreakPointTheme} from 'src/theme/breakpoints';
import styled from 'styled-components';
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}));
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
const ContainerInfo = styled('div')(() => ({
  display: 'flex',
  height: '33%',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  [BreakPointTheme.breakpoints.down('sm')]: {
    margin: '15vh auto auto auto',
    width: '90%',
    height: '40vh',
  },
}));
const ErrorScreen = () => {
  const onClose = () => {
    window.location.reload();
  };
  const {onboardingPrepago} = useSelector(state => state.RequestCardReducer);
  const labelTDC = 'Gracias por tu interés en el crédito CMF';
  const labelPrepaid = 'Gracias por tu interés en la tarjeta Prepago CMF';
  return (
    <Container>
      <WrapperSide background="rgb(255, 255, 255)" />
      <Wrapper
        style={{
          flex: 1,
          height: '100%',
          justifyContent: 'center',
          background:
            'linear-gradient(rgb(3 37 12), rgb(6 73 21), rgb(11 113 29))',
        }}>
        <center>
          <CMFLogo />
        </center>

        <ContainerInfo>
          <TitleResponsiveOnboarding
            minSize={25}
            maxSize={65}
            text={onboardingPrepago ? labelPrepaid : labelTDC}
            color="#fff"
            fontFamily="Red Hat Text"
          />
          <SpaceBr minSize={545} />
          <SubtitleResponsiveOnboarding
            minSize={18}
            maxSize={35}
            text="No podemos proceder con tu solicitud en estos momentos."
            color="#fff"
            fontFamily="Gilroy-Light"
          />
          <ButtonNextOnboarding
            variant="onboardingOutline"
            success
            loading={false}
            disabled={false}
            onClick={onClose.bind(this)}>
            Salir
          </ButtonNextOnboarding>
        </ContainerInfo>
      </Wrapper>
    </Container>
  );
};

export default ErrorScreen;

import { Typography } from '@mui/material'
import React from 'react'
import {
  CantidadTotalEnPorcentaje,
  formatoMoneda,
  ocultarNumero,
} from 'src/utils/Functions'
import { ContainerData, ContainerMaterial } from '../Pages/ContainerHome'
import CardContainer from './CardContainer'
import cardGreen from './img/card-green.png'

const CardsAvailable = ({CuentaDesen, DisponibleML, LimCreditoML, onClick}) => {
  return (
    <ContainerMaterial maxWidth="xl">
      <ContainerData>
        <Typography
          variant="H5"
          sx={{
            padding: '8px',
          }}>
          Escoge tu tarjeta
        </Typography>
        <CardContainer
          imageUrl={cardGreen}
          money={formatoMoneda(DisponibleML)}
          porcentaje={CantidadTotalEnPorcentaje(DisponibleML, LimCreditoML)}
          dataCard={`**** **** ${ocultarNumero(
            CuentaDesen,
          )}`}
          onClick={onClick}
          // onClick={goToDetailsCard}
        />
      </ContainerData>
      {/* <CardContainer
        imageUrl={cardBlack}
        money={'$1940.00'}
        dataCard={'Tarjeta de Crédito **** **** **** 5451'}
        // onClick={goToDetailsCard}
      />
      <CardContainer
        imageUrl={cardBlue}
        money={'$1357.00'}
        dataCard={'Tarjeta de Crédito **** **** **** 5451'}
        // onClick={goToDetailsCard}
      />
      <CardContainer
        imageUrl={cardGray}
        money={'$1951.00'}
        dataCard={'Tarjeta de Crédito **** **** **** 5451'}
        // onClick={goToDetailsCard}
      /> */}
    </ContainerMaterial>
  )
}
export default CardsAvailable

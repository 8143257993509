import styled from '@emotion/styled'
import React from 'react'
import { BreakPointTheme } from 'src/theme/breakpoints'
const FooterPhone = styled.div(({screen, direction}) => ({
  display: 'flex',
  margin: 'auto',
  ...(screen === 'right'
    ? {
        justifyContent: 'flex-end',
      }
    : {
        justifyContent: 'space-between',
      }),
  ...(screen === 'endline' && {
    height: '100%',
    alignItems: 'end',
  }),
  ...(screen === 'rightEnd' && {
    height: '100%',
    alignItems: 'end',
    justifyContent: 'flex-end',
  }),
  marginBottom: '6em',
  width: '90%',
  '@media (max-width:1200px)': {
    justifyContent: 'space-between',
    width: '90%',
    ...(screen === 'right'
      ? {
          justifyContent: 'flex-end',
        }
      : {
          justifyContent: 'space-between',
        }),
  },
  ...(screen === 'endline' && {
    height: '100%',
    alignItems: 'end',
  }),
  ...(screen === 'rightEnd' && {
    height: '100%',
    alignItems: 'end',
    justifyContent: 'flex-end',
  }),
  [BreakPointTheme.breakpoints.down('xl')]: {
    marginBottom: '2.5rem',
  },
  [BreakPointTheme.breakpoints.down('lg1')]: {
    marginBottom: '2.5rem',
    ...(screen === 'rightEnd' && {
      height: '100%',
      alignItems: 'end',
      justifyContent: 'flex-end',
    }),
  },
  [BreakPointTheme.breakpoints.down('sm1')]: {
    ...(screen === 'rightEnd' && {
      height: '100%',
      alignItems: 'end',
      justifyContent: 'flex-end !important',
    }),
    ...(screen === 'right'
      ? {
          justifyContent: 'flex-start',
        }
      : {
          justifyContent: 'flex-start',
        }),
    justifyContent: 'space-between',
    width: '90%',
    padding: '2rem',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    ...(direction === 'end'
      ? {
          display: 'flex',
          alignSelf: 'flex-end',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'end',
          flexDirection: 'column-reverse',
          padding: 'initial',
          marginBottom: '1rem',
          width: '100%',
        }
      : {
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
          flexDirection: 'column-reverse',
          margin: '0rem',
          padding: 'initial',
        }),
  },
}))
const FooterOnboar = ({back, next, screen, direction}) => {
  return (
    <FooterPhone screen={screen} direction={direction}>
      {back}
      {next}
    </FooterPhone>
  )
}
export default FooterOnboar

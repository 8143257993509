import { LoadingButton } from '@mui/lab'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import React from 'react'
import LoadingIcons from 'src/assets/images/cmf_linea/loading'
import { BreakPointTheme } from 'src/theme/breakpoints'
const ModalError = props => {
  const onClick = () => {
    window.location.reload()
  }
  return (
    <>
      <Dialog
        open={props.open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            margin: '0',
          },
        }}>
        <Box
          sx={{
            display: 'flex',
            width: '420px',
            padding: '32px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '16px',
            borderRadius: '32px',
            background: '#FFF',
            [BreakPointTheme.breakpoints.down('sm')]: {
              width: '95vw',
            },
          }}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              padding: 0,
            }}>
            <p
              style={{
                color:
                  'var(--specific-light-content-black, var(--specific-light-content-black, #101828))',
                fontFamily: 'Red Hat Text',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '22px /* 122.222% */',
                letterSpacing: '-0.36px',
                textAlign: 'center',
                paddingTop: '32px',
              }}>
              Disculpa...
            </p>

            <p
              style={{
                width: '241px',
                color:
                  'var(--specific-light-content-black, var(--specific-light-content-black, #101828))',
                textAlign: 'center',
                fontFamily: 'Red Hat Text',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '18px /* 128.571% */',
              }}>
              Estamos teniendo inconvenientes con nuestro sistema.
            </p>
          </DialogTitle>
          <DialogContent
            sx={{
              textAlign: 'center',
              padding: 0,
            }}>
            <p
              style={{
                alignSelf: 'stretch',
                color: 'rgba(16, 24, 40, 0.64)',
                textAlign: 'center',
                fontFamily: 'Red Hat Text',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '18px /* 128.571% */',
                marginBotton: '0 !important',
              }}>
              Puedes volver a cargar la página en tu navegador o volver a
              intentar nuevamente en unos minutos.
            </p>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              width: '90%',
              [BreakPointTheme.breakpoints.down('sm')]: {
                width: '90vw',
              },
            }}>
            <LoadingButton
              variant="contained"
              sx={{
                display: 'flex',
                height: '36px',
                padding: '0px 16px',
                fontWeight: '500',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                borderRadius: '100px',
                textTransform: 'none',
                width: '60%',
              }}
              onClick={onClick}
              endIcon={<LoadingIcons/>}>
              Cargar Página
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}

export default ModalError

import styles from './style.module.css'
import grupo3 from 'src/assets/images/onboardingCredito/Enmascarar grupo 3.png'

/**
 * CongratsSignAvatar Component displays an avatar image.
 * 
 * This component renders an avatar image sign.
 * 
 * @component
 * @example
 * return (
 *   <CongratsSignAvatar />
 * )
 */
const CongratsSignAvatar = () => {
  return (
    <div className={styles.thirdSection}>
      <img src={grupo3} alt={''} />
    </div>
  );
}

export default CongratsSignAvatar;
/**
 * @flow
 */
import produce from 'immer';
import {
  PUSH_NEW_PATH,
  POP_PATH,
  CLEAR_ALL_PATHS,
} from '../../constrants/reducers';

export type breadcrumbObj = {
  name: string,
  linkto: string,
};
export type authProps = {
  paths: Array<breadcrumbObj>,
};

const initState: authProps = {
  paths: [],
};

const paths = (state: authProps = initState, action: any) =>
  produce(state, draft => {
    switch (action.type) {
      case CLEAR_ALL_PATHS:
        draft.paths = [];

        break;
      case PUSH_NEW_PATH:
        const _paths = action.path
          .split('/')
          .filter(v => v !== '' && v !== ' ' && v[0] !== ':');
        draft.paths = _paths;
        break;
      case POP_PATH:
        draft.paths.pop();
        break;

      default:
        return state;
    }
  });
export default paths;

// import styled from 'styled-components';
import {styled} from '@material-ui/core/styles'
import {
  BASE_COLORS,
  FONT_SIZE,
  FONT_WEIGHT,
} from '../../../theme/constants'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import {BreakPointTheme} from 'src/theme/breakpoints'
import {LoadingButton} from '@mui/lab'
export const Containt = styled('div')(({theme}) => ({
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'space-between',
  justifyContent: 'space-around',
  fontSize: FONT_SIZE.xs,
  fontFamily: 'Red Hat Text',
  overflow: 'none',
  backgroundColor: BASE_COLORS.background,
}))

export const LoginContainer = styled('div')(({theme}) => ({
  display: 'flex',
  width: '70%',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'center',
  fontSize: FONT_SIZE.xs,
  fontFamily: 'Red Hat Text',
  overflow: 'none',
  backgroundColor: BASE_COLORS.background,
  [theme.breakpoints.between('xs', 'md')]: {
    width: '100%',
    height: '100vh',
  },
}))

export const LoginDescription = styled('div')(({theme}) => ({
  margin: '1.875rem',
  color: 'var(--text-main-color)',
  fontSize: FONT_SIZE.xl,
  [theme.breakpoints.only('xs')]: {
    marginBottom: '1.5625rem',
    fontWeight: FONT_WEIGHT.bold,
  },
  [theme.breakpoints.only('md')]: {
    marginBottom: '1.75rem',
    fontWeight: FONT_WEIGHT.regular,
  },
  [theme.breakpoints.only('xl')]: {
    marginBottom: '1.875rem',
  },
}))

export const formCMF = styled('div')(({theme}) => ({
  height: '77vh',
  display: 'flex',
  flexDirection: 'column',
  fontSize: FONT_SIZE.xs,
  fontFamily: 'Red Hat Text',
  alignContent: 'center',
  alignItems: 'center',
  justifyItem: 'center',
  overflow: 'none',
  fontWeight: FONT_WEIGHT.medium,
  color: BASE_COLORS.black,
  [BreakPointTheme.breakpoints.between('xs')]: {
    justifyContent: 'space-between',
    padding: '0vh 2vh 0px 2vh',
  },
  [BreakPointTheme.breakpoints.between('sm')]: {
    justifyContent: 'center',
    padding: '0.5vh 0vh 0px 0vh',
  },
  [BreakPointTheme.breakpoints.between('sm1', 'md')]: {
    justifyContent: 'center',
    padding: '0.5vh 8vh 0px 8vh',
  },
  [BreakPointTheme.breakpoints.between('lg', 'lg1')]: {
    justifyContent: 'center',
    padding: '0.5vh 8vh 0px 8vh',
  },
  [BreakPointTheme.breakpoints.up('lg1')]: {
    justifyContent: 'center',
    padding: '0.5vh 8vh 0px 8vh',
  },
  [BreakPointTheme.breakpoints.only('xl')]: {
    justifyContent: 'center',
    padding: '0.5vh 8vh 0px 8vh',
  },
  [BreakPointTheme.breakpoints.only('xxl')]: {
    justifyContent: 'center',
    padding: '0.5vh 8vh 0px 8vh',
  },
}))

export const FormL = styled(FormControl)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '90%',
    top: '48px',
  },
  [BreakPointTheme.breakpoints.only('sm')]: {
    width: '60%',
  },
  [BreakPointTheme.breakpoints.only('md')]: {
    width: '60%',
  },
  [BreakPointTheme.breakpoints.only('lg')]: {
    width: '60%',
  },
  [BreakPointTheme.breakpoints.between('xl', 'xxl')]: {
    width: '50%',
  },
}))

export const divFiel = styled('div')({
  marginTop: '10px',
  width: '100%',
})

export const TextFieldL = styled(OutlinedInput)({
  marginTop: '10px',
  width: '100%',
})

export const TextFieldPass = styled(OutlinedInput)({
  marginTop: '10px',
  width: '100%',
})

export const text = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: FONT_SIZE.xxl,
  fontFamily: 'Red Hat Text',
  overflow: 'none',
  height: '3rem',
})

export const p = styled('p')(({theme}) => ({
  alignSelf: 'stretch',
  fontStyle: 'normal',
  lineHeight: '32px',
  letterSpacing: '-0.56px',
  fontFamily: 'Red Hat Text',
  fontSize: FONT_SIZE.xxxl,
  fontWeight: FONT_WEIGHT.medium,
  [theme.breakpoints.only('xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl')]: {
    fontSize: FONT_SIZE.xxxl,
  },
}))

export const textWelcome = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'none',
})

export const pWelcome = styled('p')(({theme}) => ({
  color: BASE_COLORS.gray,
  fontFamily: 'Red Hat Text',
  fontWeight: FONT_WEIGHT.regular,
  fontSize: FONT_SIZE.md,
  fontStyle: 'normal',
  lineHeight: '20px ',
  letterSpacing: '-0.16px',
}))

export const aReset = styled('a')(({theme}) => ({
  textDecoration: 'none',
  color: BASE_COLORS.darkgreen,
  fontSize: FONT_SIZE.md,
  fontFamily: 'Red Hat Text',
  fontWeight: FONT_WEIGHT.medium,
  fontStyle: 'normal',
  lineHeight: '24px ',
  letterSpacing: '-0.16px',
  '&:hover': {
    color: BASE_COLORS.darkgreen,
    fontSize: FONT_SIZE.md,
    fontFamily: 'Red Hat Text',
    fontWeight: FONT_WEIGHT.medium,
  },
}))

export const divCopy = styled('div')(({theme}) => ({
  display: 'flex',
  padding: '0.5rem 2rem 0rem',
  textAlign: 'center',
  [theme.breakpoints.only('xs')]: {
    padding: '0rem 2rem 0rem',
    justifyContent: 'center',
  },
  [theme.breakpoints.between('md', 'lg', 'xl', 'xxl')]: {
    justifyContent: 'flex-start',
  },
}))

export const pCopy = styled('p')(({theme}) => ({
  display: 'flex',
  fontSize: FONT_SIZE.xxs,
  fontFamily: 'Red Hat Text',
  fontWeight: FONT_WEIGHT.regular,
  color: BASE_COLORS.gray,
  [theme.breakpoints.only('xxs', 'xs', 'sm', 'md')]: {
    fontSize: FONT_SIZE.xxs,
  },
}))

export const imageCMF = styled('div')(({theme}) => ({
  width: 'auto',
  height: '100vh',
  overflow: 'hidden',
  fontSize: FONT_SIZE.xs,
  fontFamily: 'Red Hat Text',
  display: 'none',
  [theme.breakpoints.up('md', 'lg', 'xl', 'xxl')]: {
    display: 'contents',
  },
}))

export const buttonContainer = styled('div')({
  marginTop: '20px',
  width: '100%',
})

export const buttonLogin = styled(LoadingButton)(({theme}) => ({
  width: '100%',
  borderRadius: '50px',
  height: '3rem',
  color: BASE_COLORS.white,
  fontFamily: 'Red Hat Text',
  fontWeight: FONT_WEIGHT.medium,
  fontSize: FONT_SIZE.md,
  '&:hover': {
    fontSize: FONT_SIZE.md,
    color: BASE_COLORS.white,
    fontFamily: 'Red Hat Text',
    fontWeight: FONT_WEIGHT.medium,
  },
  '& span': {
    color: BASE_COLORS.white,
    fontFamily: 'Red Hat Text',
    fontWeight: FONT_WEIGHT.medium,
    fontSize: FONT_SIZE.lg,
  },
}))
export const CopyContainer = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.only('xs')]: {
    width: '66%',
  },
  [theme.breakpoints.only('sm')]: {
    width: '66%',
  },
  [theme.breakpoints.only('md')]: {
    width: '80%',
  },
}))
export const MockupContainer = styled('div')(({theme}) => ({
  position: 'absolute',
  left: '60vw',
  top: '17%',
  [theme.breakpoints.only('md')]: {
    top: '30%',
  },
}))

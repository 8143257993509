import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import ShoppingCart from 'src/assets/images/cmf_linea/credit-card-white.svg';
import { ocultarNumero } from 'src/utils/Functions';

const CardsTypesLateralBar = ({ onClick, type, data }) => {
  const [loading, setLoading] = useState(false);

  const listElement = () => {
    const l4 = ocultarNumero(
      data?.isCorporate
        ? data?.customerAccountCards[0]?.lastFourDigitCard
        : data?.customerAccountCards[0]?.maskedCard,
    );

    if (loading) {
      return (
        <Skeleton
          variant="rectangular"
          width={'100%'}
          height={65}
          sx={{
            margin: '5px 0px',
            borderRadius: '10px',
            backgroundColor: '#00000024',
          }}
        />
      );
    }

    return (
      <ListItemButton
        onClick={onClick}
        alignItems="flex-start" sx={{ width: '100%' }}>
        <ListItemAvatar>
          <Avatar sx={{ background: 'transparent' }}>
            <img
              alt="credit card"
              style={{ width: '70%', height: '70%' }}
              src={ShoppingCart}
            />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          sx={{ color: '#98A2B3' }}
          primary={l4}
          secondary={
            <Typography
              sx={{ display: 'inline', color: '#98A2B3' }}
              component="span"
              variant="body2"
              color="text.primary">
              {data?.fullName}
            </Typography>
          }
        />
      </ListItemButton>
    );
  };

  return listElement();
};
export default CardsTypesLateralBar;

import {Fragment} from 'react'
import styles from './style.module.css'
import IconEmail from 'src/assets/images/onboardingCredito/IconEmail.svg'
import IconPhone from 'src/assets/images/onboardingCredito/IconPhone.svg'
import IconWhatsapp from 'src/assets/images/onboardingCredito/IconWhatsapp.svg'
import ContactContainer from './CongratsSignContact'

/**
 * CongratsSignSecondSection Component displays the second section of a congratulatory sign.
 *
 * This component renders the second section of a congratulatory sign, including contact information.
 *
 * @component
 * @example
 * return (
 *   <CongratsSignSecondSection />
 * )
 */
const CongratsSignSecondSection = () => {
  return (
    <Fragment>
      {/* Contact Information */}
      <div className={styles.secondSection}>
        <span className={styles.mainText}>
          Para cualquier consulta, contáctanos en cualquiera de nuestros canales
          de atención de lunes a sábado de 7:00 a.m. a 6:00 p.m. y los domingos
          de 9:00 a.m. a 6:00 p.m.
        </span>
        <div className={styles.contactElements}>
          <ContactContainer
            source={IconEmail}
            alt={'Icon Email'}
            text={'servicioalcliente@cmf.com.pa'}
          />
          <ContactContainer
            source={IconPhone}
            alt={'Icon Phone'}
            text={'302-4460'}
          />
          <ContactContainer
            source={IconWhatsapp}
            alt={'Icon Whatsapp'}
            text={'6597-8134'}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default CongratsSignSecondSection

import axios from 'axios'
import {API_KEY, Cmf_Base} from '../../Data/Api'
import {
  CreateLatinDataRange,
  OnboardingEvaluation,
  UpdateOnboardingCrePre,
  getCustomerDetails,
  getclientActive,
  updateAtomCredimovil,
  updateCredimovilOnboarding,
} from '../../Data/Phone'
import {
  ACTIVE_PROD_STATUS_HIDE,
  ACTIVE_PROD_STATUS_SHOW,
  ADD_IDENTITY_BATCH,
  DIALOG_MESSAGE,
  DOCUMENT_UPLOADED_SUCCESS,
  GET_SIGNID_DATA,
  SET_APC_ID,
} from '../../constrants/reducers'
import {formFields} from '../../containers/CardRequest/FormRequest.config'
import {
  APP,
  BRANCH_OFFICES,
  DIALOG_TYPES,
  IDENTITY_TYPES,
  MESSAGE_VALIDATION,
  ONBOARDING_STATUS,
  PDFTYPES,
  PRODUCTS_TYPES,
  SELLER_ID_TYPE,
  SYSTEM_TYPES,
  TYPES_IMG,
} from '../../utils/Constants'
import {
  openNotificationWithIcon,
  uploadFileTestPhone,
} from './../../utils/Functions'
import {logsOnboarding} from './KO.Actions'
import {headerEID} from './requestCard.actions'

const header = {
  headers: {
    Apikey: API_KEY,
    'content-type': 'application/json',
  },
}

export const sendInfoPhone = (
  id,
  origin,
  payloadPost,
  typeWord,
) => {
  return async (dispatch, getState) => {
    const payload = {
      phonePrice: payloadPost,
      TypeWorkActivity: typeWord,
    }
    dispatch({type: 'GET_LOAD_SEND_PRICE', payload: true})
    try {
      const response = await axios.post(
        `${Cmf_Base}api/onboarding/credit/quotationEngine`,
        payload,
        header,
      )
      const {data} = response

      dispatch({type: 'GET_DATA_PHONE', payload: data})
    } catch (error) {
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: 'Ha ocurrido un error obteniendo datos del onboarding',
      })
      logsOnboarding(
        id,
        origin,
        'GET_QUOTATION_ENGINE_REGISTRY',
        error.response,
        false,
      )
    } finally {
      dispatch({type: 'GET_LOAD_SEND_PRICE', payload: false})
    }
  }
}
/**
 * Ws creado para HU de cambio de celular para redireccionamiento al cotizador
 * Ws que responde onboardingPhoneActive con true o false en base si el cliente tiene un onboarding activo
 * Al igual que el JsonAnswer responde con toda la info que el cliente ingreso en el primer onboarding
 * @param {*} onSuccess valor para redireccion entre pantallas
 * @returns
 */
export const onboardingActive = async (identity, onSuccess) => {
  return async dispatch => {
    try {
      const resp = await axios.get(
        `${Cmf_Base}api/onboarding/GetOnboardingPhoneActiveByIdentity/${identity}`,
        {
          headers: {
            Apikey: API_KEY,
          },
        },
      )
      const {data} = resp
      let jsonAnswe
      jsonAnswe = JSON.parse(resp?.data?.jsonAnswer)
      if (data.onboardingPhoneActive === true) {
        onSuccess(true)
        await dispatch({type: 'ONBOARDING_ACTIVE', payload: data})
        dispatch({type: 'ONBOARDING_ACTIVE_DATA', payload: jsonAnswe})
      } else {
        onSuccess(false)
        dispatch({type: 'ONBOARDING_NEW', payload: data})
      }
    } catch (error) {
      onSuccess(1)
    }
  }
}

/**
 * Recibe datos de la api para el envio de la solicitud de creacion de onboarding y el envio de la evaluacion
 * @param {*} payload
 * @param {*} onSuccess
 * @returns
 */
export const createdOnboardingPhone = async (payload, onSuccess) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState()
    const {origin, onboardingId} = RequestCardReducer
    const currentOnboarding = {
      ...payload,
      statusId: ONBOARDING_STATUS.IN_PROCESS,
      Origin: 'CREDI',
    }

    try {
      const payloadCredi = {
        action: 'CRECRE_UPDGEN',
        OnboardingCreDTO: currentOnboarding,
      }
      const response = await axios.post(
        `${Cmf_Base}api/onboarding/UpdateOnboardingCrePre`,
        payloadCredi,
        {
          headers: {
            Apikey: API_KEY,
          },
        },
      )
      const {data} = response
      await logsOnboarding(
        onboardingId,
        origin,
        'UPDATE_ACTIVIDAD_LABORAL',
        data.jsonAnswer,
        payloadCredi,
        true,
      )
      onSuccess(true)
      return dispatch({
        type: 'CREATE_UPDATE_ONBOARDING_SUCCES',
        payload: currentOnboarding,
      })
    } catch (error) {
      let problemasEncontrados =
        error?.response?.data?.problemasEncontrados[0]?.error
      await logsOnboarding(
        onboardingId,
        origin,
        'UPDATE_ACTIVIDAD_LABORAL',
        error.response,
        currentOnboarding,
        false,
      )
      onSuccess(false)
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: problemasEncontrados
          ? problemasEncontrados
          : 'Error actualizando datos',
      })
    }
  }
}

export const createOnboadingEvaluation = (payloadPost, onSuccess) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState()
    const {
      origin,
      onboardingId,
      cedulaState,
      requestVideoId,
      form,
    } = RequestCardReducer
    const payload = {
      onboardingId: onboardingId,
      origin: origin,
      economicActivity: form?.activityType,
      lastModifiedDate: new Date(),
      createdBy: 'CMF',
      CheckApcDebts: true,
    }
    const createLatinDataRangePayload = {
      typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
      identityNumber: cedulaState,
      createdBy: SELLER_ID_TYPE,
      latindDataId: 0,
    }

    dispatch({type: 'GET_LOAD_ONBOARDING_EVALUATION', payload: true})
    try {
      const response = await CreateLatinDataRange(createLatinDataRangePayload)
      if (response.data && response.status === 200) {
        const resp = await axios.get(
          `${Cmf_Base}api/apc/score?IdentityNumber=${cedulaState}&IdentityType=1&SearchType=1&ScoreVersion=3&CreatedBy=${SELLER_ID_TYPE}`,
          {
            headers: {
              Apikey: API_KEY,
            },
          },
        )
        const {data} = resp
        let apcid = data.id
        if (apcid) {
          dispatch({
            type: SET_APC_ID,
            payload: apcid,
          })
          const payloads = {
            action: 'CRE_PRE',
            OnboardingCreDTO: {
              ...payloadPost,
              apcId: apcid,
              statusId: ONBOARDING_STATUS.IN_PROCESS,
              branchOfficeId: BRANCH_OFFICES.COCHEZ,
              Origin: 'CREDI',
            },
          }
          const _resp = await UpdateOnboardingCrePre(payloads)
          const {status} = _resp
          if (status) {
            const response = await OnboardingEvaluation(payload)
            const {data} = response
            dispatch({type: 'RESULT_EVALUATION', payload: data})
            if (data.complete && data.jsonAnswer) {
              try {
                const payload = {
                  ...payloadPost,
                  apcId: apcid,
                  statusId: ONBOARDING_STATUS.IN_PROCESS,
                  electronicVideoId: requestVideoId,
                  SourceOnboarding: 'PHONE',
                  Origin: 'PHONE',
                }
                const response = await updateAtomCredimovil(
                  onboardingId,
                  origin,
                  payload,
                )

                const {data} = response
                if (data) {
                  dispatch({
                    type: 'CREATED_ONBOARDING_PHONE',
                    payload: data.jsonAnswer,
                  })
                  dispatch({type: 'SET_DATA_UPDATE', payload: payload})
                  onSuccess(true)
                }
              } catch (error) {
                onSuccess(false)
              }
            } else if (!data.complete) {
              const payloadReject = {
                ...payloadPost,
                apcId: apcid,
                statusId: ONBOARDING_STATUS.REJECTED,
                electronicVideoId: requestVideoId,
                SourceOnboarding: 'PHONE',
                Origin: 'PHONE',
              }
              await updateAtomCredimovil(onboardingId, origin, payloadReject)
              onSuccess(3)
            }
          }
        }
        dispatch({type: 'GET_DATA_ONBOARDING_EVALUATION', payload: data})
      } else {
        onSuccess(false)
      }
    } catch (error) {
      logsOnboarding(
        Number(onboardingId),
        origin,
        'EVALUATION_CREDIMOVIL',
        error.response || null,
        error.config.data || null,
        false,
      )
      openNotificationWithIcon(
        'error',
        'Ha ocurrido un error actualizando datos de actividad laboral',
      )
      onSuccess(false)
    } finally {
      dispatch({type: 'GET_LOAD_ONBOARDING_EVALUATIONE', payload: false})
    }
  }
}

export const updateOnboadingPhone = async (id, origin, payload, onComplete) => {
  return async (dispatch, getState) => {
    try {
      const resp = await axios.put(
        `${Cmf_Base}api/onboarding/atom/${id}/${origin}`,
        payload,
        {
          headers: {
            Apikey: API_KEY,
          },
        },
      )
      const {data} = resp
      await logsOnboarding(
        id,
        origin,
        'UPDATE_ONBOARDING_PHONE',
        data,
        payload,
        true,
      )
      dispatch({type: 'SET_DATA_UPDATE', payload: payload})
      if (data) {
        onComplete(true)
      }
    } catch (error) {
      onComplete(false)
      await logsOnboarding(
        id,
        origin,
        'UPDATE_ONBOARDING_PHONE',
        error?.response,
        payload,
        false,
      )
      openNotificationWithIcon(
        'error',
        'Ha ocurrido un error guardando la información',
      )
    }
  }
}

export const updateOnboadingPhones = async (
  id,
  origin,
  dataPayload,
  onComplete,
) => {
  return async (dispatch, getState) => {
    const {Phone} = getState()
    const {formUpdate} = Phone
    let payload = {
      ...formUpdate,
      ...dataPayload,
    }
    try {
      const resp = await axios.put(
        `${Cmf_Base}api/onboarding/atom/${id}/${origin}`,
        payload,
        {
          headers: {
            Apikey: API_KEY,
          },
        },
      )
      const {data} = resp
      await logsOnboarding(
        id,
        origin,
        'UPDATE_POLITICAL_PHONE',
        data,
        payload,
        true,
      )
      dispatch({type: 'SET_DATA_UPDATE', payload: payload})

      if (dataPayload.isPep || dataPayload.isCrs) {
        let ChangeStatusPending = {
          onboardingId: id,
          statusId: ONBOARDING_STATUS.PENDING_FOR_APPROVE,
          commentary: 'Pendiente',
          createdBy: 'CMF',
          clientEmail: formUpdate?.emails[0]?.email,
          clientName: `${formUpdate?.firstName} + ${formUpdate?.firstSurname}`,
          isPep: true,
          origin: 'PHONE',
        }
        axios.post(
          `${Cmf_Base}api/onboarding/ChangeStatus`,
          ChangeStatusPending,
          {
            headers: {
              Apikey: API_KEY,
            },
          },
        )
        onComplete(1)
      } else {
        onComplete(2)
      }
    } catch (error) {
      await logsOnboarding(
        id,
        origin,
        'UPDATE_POLITICAL_PHONE',
        error?.response,
        payload,
        false,
      )
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: 'Ha ocurrido un error guardando la información',
      })
    }
  }
}
const consultCustomerProduct = async (id, origin) => {
  return axios.get(
    `${Cmf_Base}api/Customers/GetCustomerOriginbyOnboardingIdandOrigin?onboardingId=${id}&origin=${origin}`,
    {
      headers: {
        Apikey: API_KEY,
      },
    },
  )
}
/**
 * Update, activacion de onboarding y de producto del usuario
 * @param {*} id proveniente del enlace obtenido desde el correo
 * @param {*} origin proveniente del enlace obtenido desde el correo
 * @param {*} dataPayload de la data obtenida por el id de onboarding
 * @param {*} deviceId codigo del dispositivo
 * @param {*} onComplete funcion true or false
 * @returns
 */
export const updateOnboadingBeforeActivation = async (
  id,
  origin,
  dataPayload,
  selectedBranchOfficeId,
  onComplete,
) => {
  return async (dispatch, getState) => {
    const {Phone} = getState()
    const {formUpdate, productId} = Phone
    let origenOnboarding = 'CREDI';
    let payload = {
      SourceOnboarding: 'PHONE',
      branchOfficeId: selectedBranchOfficeId,
      ...formUpdate,
      ...dataPayload,
    }
    dispatch({type: ACTIVE_PROD_STATUS_SHOW})
    try {
      if (productId === null) {
        const resp = await axios.put(
          `${Cmf_Base}api/onboarding/atom/${id}/${origenOnboarding}`,
          payload,
          {
            headers: {
              Apikey: API_KEY,
            },
          },
        )
        await logsOnboarding(
          id,
          origin,
          'UPDATE_BEFORE_ACTIVATE_PHONE',
          resp?.data,
          payload,
          true,
        )
        const {data} = resp
        dispatch({type: 'SET_DATA_UPDATE', payload: payload})

        if (data) {
          const activatePayload = {
            onboardingId: Number(id),
            customerId: null,
            statusId: ONBOARDING_STATUS.ACTIVE,
            branchOfficeId: selectedBranchOfficeId,
            sellerId: SELLER_ID_TYPE,
            systemId: SYSTEM_TYPES.CMF,
            approvedBy: SELLER_ID_TYPE,
            approvedDate: new Date(),
            onboardingMotordecisionId: 0,
            isTeky: true,
          }
          const response = await axios.post(
            `${Cmf_Base}api/onboarding/ActiveOnboardingMatrix`,
            activatePayload,
            {
              headers: {
                Apikey: API_KEY,
              },
            },
          )
          if (response.data) {
            const resData = await consultCustomerProduct(id, 'PHONE')
            const {data, status} = resData
            dispatch({
              payload: data.customerProductId,
              type: 'PRODUCT_PHONE',
            })
            if (status === 200) {
              const payloadActive = {
                CustomerProductId: data.customerProductId,
                BranchOfficeActiveId: BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
                ActiveBy: SYSTEM_TYPES.CMF,
              }
              const _resp = await axios.post(
                `${Cmf_Base}api/Customers/ActiveProductCredTDC`,
                payloadActive,
                {
                  headers: {
                    Apikey: API_KEY,
                  },
                },
              )
              if (_resp.data.complete) {
                onComplete(true)
              } else {
                dispatch({type: ACTIVE_PROD_STATUS_HIDE})
                dispatch({
                  type: DIALOG_MESSAGE,
                  dialogType: DIALOG_TYPES.ERROR,
                  message:
                    _resp?.data?.problemPublic ||
                    'Ha ocurrido un error al activar producto',
                })
                onComplete(false)
              }
            }
          }
        }
      } else {
        const payloadActive = {
          CustomerProductId: productId,
          BranchOfficeActiveId: BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
          ActiveBy: SYSTEM_TYPES.CMF,
        }
        const _resp = await axios.post(
          `${Cmf_Base}api/Customers/ActiveProductCredTDC`,
          payloadActive,
          {
            headers: {
              Apikey: API_KEY,
            },
          },
        )
        if (_resp.data.complete) {
          onComplete(true)
        } else {
          dispatch({type: ACTIVE_PROD_STATUS_HIDE})
          dispatch({
            type: DIALOG_MESSAGE,
            dialogType: DIALOG_TYPES.ERROR,
            message:
              _resp?.data?.problemPublic ||
              'Ha ocurrido un error al activar producto',
          })
          onComplete(false)
        }
      }
    } catch (error) {
      await logsOnboarding(
        id,
        origin,
        'UPDATE_BEFORE_ACTIVCATE_PHONE',
        error?.response,
        payload,
        false,
      )
      let msg = error?.response?.data?.problemPublic
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: msg || 'Ha ocurrido un error guardando la información',
      })
    } finally {
      dispatch({type: ACTIVE_PROD_STATUS_HIDE})
    }
  }
}
/**
 * Recibe datos del escaneo de electronic id para la validacion de la edad, si es cliente es menor de 19 se le mostrara una notificación
 * @param {*} payload
 * @param {*} onSuccess
 * @returns
 */
export const onboardingValidateAge = async (payload, onSuccess) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState()
    const {origin, onboardingId} = RequestCardReducer
    const currentOnboarding = {
      ...payload,
      statusId: ONBOARDING_STATUS.IN_PROCESS,
      Origin: origin,
    }

    try {
      const payloadCredi = {
        action: 'CRECRE_TEKKY',
        OnboardingCreDTO: currentOnboarding,
      }
      const response = await axios.post(
        `${Cmf_Base}api/onboarding/UpdateOnboardingCrePre`,
        payloadCredi,
        {
          headers: {
            Apikey: API_KEY,
          },
        },
      )
      const {data} = response
      await logsOnboarding(
        onboardingId,
        origin,
        'UPDATE_VALIDATE_AGE',
        data.jsonAnswer,
        payloadCredi,
        true,
      )
      onSuccess(true)
      return dispatch({
        type: 'CREATE_UPDATE_ONBOARDING_SUCCES',
        payload: currentOnboarding,
      })
    } catch (error) {
      let problemasEncontrados =
        error?.response?.data?.problemasEncontrados[0]?.error
      await logsOnboarding(
        onboardingId,
        origin,
        'UPDATE_VALIDATE_AGE',
        error.response,
        currentOnboarding,
        false,
      )
      onSuccess(false)
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: problemasEncontrados
          ? problemasEncontrados
          : 'Error actualizando datos',
      })
    }
  }
}

export const createdOrGetIdentity = async () => {
  return async (dispatch, getState) => {
    const {Phone} = getState()
    const {formUpdate} = Phone
    const payload = {
      email: formUpdate?.emails[0]?.email,
      nif: formUpdate?.identityNumber,
      phone: formUpdate?.phones[0]?.phone,
      primaryName: formUpdate?.firstName,
      secondaryName: formUpdate?.firstSurname,
    }
    try {
      const response = await axios.get(
        `${APP.electronicId.endpoints.getIdentityId}?nif=${formUpdate?.identityNumber}`,
        headerEID,
      )
      const {data} = response
      if (data.id) {
        dispatch({
          singIdData: data.id,
          type: GET_SIGNID_DATA,
        })
      }
    } catch (error) {
      try {
        const res = await axios.post(
          APP.electronicId.endpoints.onsite,
          payload,
          headerEID,
        )
        dispatch({
          singIdData: res.data.id,
          type: GET_SIGNID_DATA,
        })
      } catch (e) {
        dispatch({
          type: DIALOG_MESSAGE,
          dialogType: DIALOG_TYPES.ERROR,
          message: e?.response?.data?.message || 'Error creando identidad',
        })
      }
    }
  }
}

export const saveQuoterDate = async (id, origin, payload, onSuccess) => {
  return async dispatch => {
    try {
      const resp = await axios.post(
        `${Cmf_Base}api/onboarding/save/quotationEngine`,
        payload,
        {
          headers: {
            Apikey: API_KEY,
          },
        },
      )
      if (resp.data.id) {
        onSuccess(true)
      }
    } catch (error) {
      onSuccess(false)
      logsOnboarding(
        id,
        origin,
        'SAVE_QUOTATION_ENGINE_REGISTRY',
        error.response,
        false,
      )
      openNotificationWithIcon(
        'error',
        'Ha ocurrido un error guardando la información',
      )
    }
  }
}

export const getIdAndGetData = async (id, origin) => {
  return async dispatch => {
    try {
      const resp = await axios.post(
        `${Cmf_Base}api/onboarding/getOnboardingByIdIdentityNumber/CREDI`,
        {
          Id: id,
        },
        {
          headers: {
            Apikey: API_KEY,
          },
        },
      )
      const {data} = resp

      if (data && data.status) {
        const nif = data?.jsonAnswer.identityNumber
        const payload = {
          email: data?.jsonAnswer.emails[0]?.email,
          nif: nif,
          phone: data?.jsonAnswer?.phones[0]?.phone,
          primaryName: data?.jsonAnswer.firstName,
          secondaryName: data?.jsonAnswer.firstSurname,
        }
        dispatch({type: 'SET_DATA_UPDATE', payload: data?.jsonAnswer})
        try {
          const response = await axios.get(
            `${APP.electronicId.endpoints.getIdentityId}?nif=${nif}`,
            headerEID,
          )
          const {data} = response
          if (data.id) {
            dispatch({
              singIdData: data.id,
              type: GET_SIGNID_DATA,
            })
          }
        } catch (error) {
          try {
            const res = await axios.post(
              APP.electronicId.endpoints.onsite,
              payload,
              headerEID,
            )

            dispatch({
              singIdData: res.data.id,
              type: GET_SIGNID_DATA,
            })
          } catch (e) {
            dispatch({
              type: DIALOG_MESSAGE,
              dialogType: DIALOG_TYPES.ERROR,
              message: e?.response?.data?.message || 'Error creando identidad',
            })
          }
        }
      } else {
        dispatch({
          type: DIALOG_MESSAGE,
          dialogType: DIALOG_TYPES.ERROR,
          message: data?.problemInternal
            ? data?.problemInternal
            : 'Ha ocurrido un obteniendo información',
        })
      }
    } catch (error) {
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: error?.response?.data?.problemInternal
          ? error?.response?.data?.problemInternal
          : 'Ha ocurrido un obteniendo información',
      })
    }
  }
}

export const addDocumentCotizador = (
  name,
  onboardingId,
  branchOfficeActiveId,
  origin,
) => {
  return async (dispatch, getState) => {
    const _payload = {
      onboardingId: onboardingId,
      branchOfficeActiveId: branchOfficeActiveId,
      origin: origin,
    }
    dispatch({type: 'GET_LOAD_TO_PDF_COTIZADOR', payload: true})
    try {
      const _response = await axios.post(
        APP.cmf.endpoints.getCotizadorPdf,
        _payload,
        header,
      )
      if (_response.status === 200) {
        dispatch({
          payload: _response.data.response,
          type: 'DOCUMENT_UPLOADED_COTIZACION_SUCCESS',
        })
        const payload = {
          name: name,
          document: _response.data.response,
        }
        const response = await axios.post(
          APP.electronicId.endpoints.uploadDocument,
          payload,
          headerEID,
        )
        if (response?.data?.documentId) {
          await logsOnboarding(
            onboardingId,
            origin,
            'SIGID_LOAD_COTIZADOR_DOCUMENT_ID',
            response?.data,
            name,
            true,
          )
          dispatch({
            cotizadorId: response.data.documentId,
            type: 'DOCUMENT_COTIZACIONID_SUCCESS',
          })
        }
      }
    } catch (e) {
      await logsOnboarding(
        onboardingId,
        origin,
        'SIGID_LOAD_COTIZADOR_DOCUMENT_ID',
        e?.response?.data,
        name,
        false,
      )
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: e?.response?.data?.problemPublic
          ? e?.response?.data?.problemPublic
          : 'Error al obtener contrato',
      })
    } finally {
      dispatch({type: 'GET_LOAD_TO_PDF_COTIZADOR', payload: false})
    }
  }
}

export const updateOnboadingContinueActive = async (
  id,
  origin,
  dataPayload,
  json,
  CustomerId,
  onComplete,
) => {
  return async (dispatch, getState) => {
    const {Phone} = getState()
    const {deviceId} = Phone
    let payload = {
      DeviceId: deviceId,
      ...json,
      ...dataPayload,
      lastModifiedDate: new Date(),
    }
    const objetoEnMinuscula = {}

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const lowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1)
        objetoEnMinuscula[lowerCaseKey] = payload[key]
      }
    }
    dispatch({type: ACTIVE_PROD_STATUS_SHOW})
    try {
      const resp = await axios.put(
        `${Cmf_Base}api/onboarding/atom/${id}/CREDI`,
        objetoEnMinuscula,
        {
          headers: {
            Apikey: API_KEY,
          },
        },
      )
      if (resp.status === 200) {
        await axios.post(
          `${Cmf_Base}api/onboarding/ResendEmailWithContractByCustomerPhone/${CustomerId}`,
          {},
          {
            headers: {
              Apikey: API_KEY,
            },
          },
        )
        onComplete(true)
      }
      await logsOnboarding(
        id,
        origin,
        'UPDATE_BEFORE_ACTIVATE_PHONE',
        resp?.data,
        payload,
        true,
      )
      dispatch({type: 'SET_DATA_UPDATE', payload: payload})
      // onComplete(true)
    } catch (error) {
      await logsOnboarding(
        id,
        origin,
        'UPDATE_BEFORE_ACTIVCATE_PHONE',
        error?.response,
        payload,
        false,
      )
      let msg = error?.response?.data?.problemPublic
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: msg || 'Ha ocurrido un error guardando la información',
      })
    } finally {
      dispatch({type: ACTIVE_PROD_STATUS_HIDE})
    }
  }
}
/**
 * Realiza la verificación y aprobación del proceso para continuar verificacion de datos correctamente.
 * @param {string} identityNumber - Número de identidad del solicitante.
 * @param {function} onSuccess - Función de devolución de llamada para manejar el resultado exitoso o fallido.
 * @returns {void}
 */
export const verifyBatchOnboarding = (identityNumber, onSuccess) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState()
    const {
      branchOfficeId,
      routeCreateUser,
      cedulaState,
      form,
    } = RequestCardReducer
    const newIdOnb = Number(routeCreateUser.newCode)
    let payload = {
      action: 'CRECRE_COMCED',
      OnboardingCreDTO: {
        id: newIdOnb,
        statusId: ONBOARDING_STATUS.PREAPROBADO,
        branchOfficeId:
          branchOfficeId.length > 3
            ? branchOfficeId
            : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
        createdBy: SELLER_ID_TYPE,
        lastModifiedBy: 'CMF_AZURE',
        typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
        identityNumber: cedulaState,
      },
      VideoConfirmDTO: {
        ...form,
        typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
        Origin: 'CREDI',
      },
    }

    if (formFields.identity.rules[1].pattern.test(identityNumber)) {
      try {
        await getclientActive(identityNumber)
        const response = await UpdateOnboardingCrePre(payload)

        if (response.data.status) {
          const onboardingId = Number(response?.data?.newCode)
          dispatch({
            payload: onboardingId,
            type: 'GET_NEW_CODE',
          })
          const _response = await getCustomerDetails(cedulaState)
          const activeProdc = _response.data?.jsonAnswer?.customerProducts
          // Filtra los elementos de la matriz "activeProdc" para encontrar productos válidos.
          let validProduct =
            activeProdc &&
            activeProdc.filter(product => {
              // Verifica si el producto no ha sido renovado.
              const isRenovated = product.isRenovated === false

              // Verifica si el producto no ha sido renovado por ningún otro producto.
              const isNotRenovatedByAny = product.renovatedBy === null

              // Verifica si el producto tiene un ciclo válido.
              const hasValidCycle = product.cycle !== null

              // Verifica si el producto es un tipo de producto de crédito o Teky.
              const isCreditOrTeky =
                product.productTypeId.toUpperCase() ===
                  PRODUCTS_TYPES.CREDIT_PRODUCTO.toUpperCase() ||
                product.productId.toUpperCase() ===
                  PRODUCTS_TYPES.TEKY.toUpperCase()

              // Devuelve "true" si todas las condiciones son verdaderas, lo que significa que el producto es válido.
              return (
                isRenovated &&
                isNotRenovatedByAny &&
                hasValidCycle &&
                isCreditOrTeky
              )
            })
          // Verifica si hay productos válidos en la matriz "validProduct".
          if (validProduct && validProduct.length > 0) {
            // Extrae el ID del producto válido, si existe, de la primera entrada de la matriz.
            const validProductId = validProduct[0]?.productId

            // Despacha una acción con el ID del producto válido y el tipo de acción 'TYPE_PRODUCT'.
            dispatch({
              type: 'TYPE_PRODUCT',
              TypeProductId: validProductId,
            })
            onSuccess(false)
          } else {
            onSuccess(true)
            dispatch({
              type: ADD_IDENTITY_BATCH,
              origin: 'CREDI',
            })
          }
        }
      } catch (error) {
        if (error?.response?.data === MESSAGE_VALIDATION.onboardingPending) {
          onSuccess(5)
          dispatch({
            type: 'ERROR',
            payload: 1,
          })
        } else if (
          error?.response?.data === MESSAGE_VALIDATION.onboardingApprove
        ) {
          onSuccess(5)
          dispatch({
            type: 'ERROR',
            payload: 2,
          })
        } else {
          onSuccess(4)
          const errorMessage =
            error?.response?.data?.problemPublic &&
            JSON.parse(error.response.data.problemPublic)[0]?.Error
          openNotificationWithIcon('error', errorMessage)
        }
      }
    } else {
      onSuccess(4)
      openNotificationWithIcon('error', formFields.identity.rules[1].message)
    }
  }
}

/**
 * Recibe datos de la api para el envio de la solicitud de creacion de onboarding y el envio de la evaluacion
 * @param {*} payload
 * @param {*} onSuccess
 * @returns
 */
export const updateVerifyInfo = async (payload, onSuccess) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer, Phone} = getState()
    const {origin, onboardingId} = RequestCardReducer
    const {formUpdate} = Phone
    const currentOnboarding = {
      ...payload,
      statusId: ONBOARDING_STATUS.IN_PROCESS,
      Origin: origin,
    }

    try {
      const respon = await axios.get(
        `${APP.electronicId.endpoints.getIdentityId}?nif=${formUpdate?.identityNumber}`,
        headerEID,
      )

      if (respon.data.id) {
        const payloadCredi = {
          action: 'CRECRE_UPDGEN',
          OnboardingCreDTO: currentOnboarding,
        }
        await UpdateOnboardingCrePre(payloadCredi)
        onSuccess(true)
        dispatch({
          type: 'CREATE_UPDATE_ONBOARDING_SUCCES',
          payload: currentOnboarding,
        })
        dispatch({
          singIdData: respon.data.id,
          type: GET_SIGNID_DATA,
        })
      }
    } catch (error) {
      if (
        error?.response?.data &&
        error?.response?.data?.errorId &&
        error?.response?.status === 400
      ) {
        try {
          const payloadIdentity = {
            email: payload.emails[0].email,
            nif: formUpdate?.identityNumber,
            phone: payload.phones[0].phone,
            primaryName: formUpdate?.firstName,
            secondaryName: formUpdate?.firstSurname,
          }
          const res = await axios.post(
            APP.electronicId.endpoints.onsite,
            payloadIdentity,
            headerEID,
          )
          const payloadCredi = {
            action: 'CRECRE_UPDGEN',
            OnboardingCreDTO: currentOnboarding,
          }
          await UpdateOnboardingCrePre(payloadCredi)
          dispatch({
            type: 'CREATE_UPDATE_ONBOARDING_SUCCES',
            payload: currentOnboarding,
          })
          dispatch({
            singIdData: res.data.id,
            type: GET_SIGNID_DATA,
          })
          onSuccess(true)
        } catch (e) {
          onSuccess(false)
          openNotificationWithIcon(
            'error',
            e?.response?.data?.problemasEncontrados
              ? e?.response?.data?.problemasEncontrados[0]?.error
              : e?.response?.data?.message
              ? e?.response?.data?.message
              : 'Error creando identidad',
          )
        }
      } else {
        onSuccess(false)

        openNotificationWithIcon(
          'error',
          error?.response?.data?.problemasEncontrados
            ? error?.response?.data?.problemasEncontrados[0]?.error
            : 'Error actualizando datos',
        )
        await logsOnboarding(
          onboardingId,
          origin,
          'UPDATE_ACTIVIDAD_LABORAL_CREDIMOVIL',
          error.response,
          currentOnboarding,
          false,
        )
      }
    }
  }
}
export const updateSignOnboardingPhone = async (
  name,
  img,
  id,
  origin,
  json,
  branch,
  onboardingActive,
  onComplete,
) => {
  return async (dispatch, getState) => {
    const {Phone} = getState()
    const {formUpdate} = Phone
    try {
      const url = await uploadFileTestPhone(name, img)
      // Determina de dónde obtener las imágenes basado en la condición
      const sourceImages = onboardingActive
        ? json?.Images?.filter(item => item.Description !== TYPES_IMG.Text)
        : formUpdate?.images?.filter(
            item => item.description !== TYPES_IMG.Text,
          )
      if (url) {
        let Images = []
        let images = []
        if (onboardingActive) {
          Images = [
            ...sourceImages,
            {
              Id: 0,
              TypeImageId: TYPES_IMG.Firma,
              Description: TYPES_IMG.Text,
              Link: url,
              Thumbnails: null,
              LastModifiedDate: new Date().toISOString(),
              CreatedDate: new Date().toISOString(),
              CreatedBy: 'CMF',
            },
          ]
        } else {
          images = [
            ...sourceImages,
            {
              id: 0,
              typeImageId: TYPES_IMG.Firma,
              description: TYPES_IMG.Text,
              link: url,
              thumbnails: null,
              lastModifiedDate: new Date().toISOString(),
              createdDate: new Date().toISOString(),
              createdBy: 'CMF',
            },
          ]
        }
        const onboardingUpdate = onboardingActive ? json : formUpdate
        let payload = Object.assign({}, onboardingUpdate)
        if (onboardingActive) {
          payload.Images = Images
        } else {
          payload.images = images
        }

        await updateCredimovilOnboarding(id, payload)
        dispatch({type: 'SET_DATA_UPDATE', payload: payload})
        const _payload = {
          onboardingId: id,
          templateName: PDFTYPES.CREDIMOVIL,
          origin: origin,
          branchOfficeId: branch,
        }

        const _response = await axios.post(
          APP.cmf.endpoints.getPDF,
          _payload,
          header,
        )
        if (_response.status === 200) {
          const payloadEID = {
            name: 'documentFile.pdf',
            document: _response.data.response,
          }
          const response = await axios.post(
            APP.electronicId.endpoints.uploadDocument,
            payloadEID,
            headerEID,
          )
          if (response?.data?.documentId) {
            onComplete(true)
            dispatch({
              documentId: response.data.documentId,
              type: DOCUMENT_UPLOADED_SUCCESS,
            })
          }
        }
      }
    } catch (error) {
      onComplete(false)
      logsOnboarding(id, origin, 'SAVE_SIGN_UPDATE', error?.response, '', false)
      openNotificationWithIcon(
        'error',
        'Se ha producido un error al guardar la firma.',
      )
    }
  }
}

/**
 * Recibe datos de la api para el envio de la solicitud de creacion de onboarding y el envio de la evaluacion
 * @param {*} payload
 * @param {*} onSuccess
 * @returns
 */
export const updateOnboaringTE = async (payload, onSuccess) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState()
    const {origin, onboardingId} = RequestCardReducer
    try {
      const response = await updateAtomCredimovil(onboardingId, origin, payload)

      const {data} = response
      if (data) {
        onSuccess(true)
      }
    } catch (error) {
      onSuccess(false)
      openNotificationWithIcon(
        'error',
        'Ha ocurrido un error enviando la solicitud.',
      )
    }
  }
}

export const SEONEvaluationPhone = (onboardingId, origin, session) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState()
    const {ipAddress, form} = RequestCardReducer
    function getcompleteName(primaryName, secondName, lastName) {
      return `${primaryName} ${secondName} ${lastName}`
    }
    const fullName = getcompleteName(
      form?.firstName,
      form?.secondName,
      form?.lastName,
    )
    function getDomain(email) {
      const regexCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!regexCorreo.test(email)) {
        return null
      }
      const partes = email.split('@')
      const dominio = partes[1]
      return dominio
    }
    const domain = getDomain(form?.email)
    const payload = {
      onboardingId: onboardingId,
      Origin: origin,
      ip: ipAddress,
      action_type: '',
      affiliate_id: '',
      affiliate_name: '',
      email: form?.email,
      email_domain: domain,
      password_hash: '',
      user_fullname: fullName,
      user_firstname: form?.firstName,
      user_middlename: form?.secondName,
      user_lastname: form?.lastName,
      user_dob: form?.birthdate,
      user_pob: '', //payload
      user_photoid_number: '',
      user_id: '',
      user_name: '',
      transaction_id: '',
      user_category: '',
      user_account_status: '',
      user_created: '',
      user_country: '', //payload
      user_city: '',
      user_region: '',
      user_zip: '',
      user_street: '',
      user_street2: '',
      session: session,
      phone_number: '+507' + form?.cellphone,
      transaction_type: '',
      bonus_campaign_id: '',
      merchant_id: '',
      details_url: '',
    }
    try {
      await axios.post(
        `${Cmf_Base}api/seon/GetTransactionFraudApi`,
        payload,
        {
          headers: {
            Apikey: API_KEY,
          },
        },
      )
    } catch (e) {
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: e?.response?.data
          ? e?.response?.data
          : 'Ha ocurrido un error enviando solicitud',
      })
      await logsOnboarding(
        onboardingId,
        origin,
        'SEON_EVALUATION',
        e?.response?.data,
        payload,
        false,
      )
    }
  }
}

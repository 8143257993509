import axios from 'axios'
import {API_KEY, Cmf_Base} from '../../Data/Api'
import {openNotificationWithIcon} from '../../utils/Functions'

export const informationByID = () => {
  return (dispatch, getState) => {
    const {AuthenticationReducer} = getState()
    const {CurrentUser} = AuthenticationReducer

    dispatch({type: 'INFORMATION_BY_ID_SHOW'})

    axios
      .get(`${Cmf_Base}api/Customers/${CurrentUser.customerId}`, {
        headers: {
          Apikey: API_KEY,
        },
      })
      .then(({data}) => {
        if (data?.complete) {
          dispatch({
            type: 'INFORMATION_BY_ID_SUCCESS',
            payload: data.jsonAnswer,
          })
        } else {
          openNotificationWithIcon('error', data.problemPublic)
        }
      })
      .catch(error =>
        dispatch({type: 'INFORMATION_BY_ID_ERROR', payload: error}),
      )
      .finally(() => dispatch({type: 'INFORMATION_BY_ID_HIDE'}))
  }
}

export const GetCatalogsOffices = () => {
  return dispatch => {
    dispatch({type: 'CATALOGS_OFFICES_SHOW'})
    axios
      .get(`${Cmf_Base}api/catalogs/GetAllBranchOffices`, {
        headers: {
          Apikey: API_KEY,
        },
      })
      .then(Response => {
        const {data, status} = Response
        if ((data, status)) {
          dispatch({type: 'CATALOGS_OFFICES_SUCCESS', payload: data})
        }
      })
      .catch(error =>
        dispatch({type: 'CATALOGS_OFFICES_ERROR', payload: error}),
      )
      .finally(() => dispatch({type: 'CATALOGS_OFFICES_HIDE'}))
  }
}
import {SettingOutlined} from '@ant-design/icons'
import styled from '@emotion/styled'
import {Button, Form, Input, Modal, Typography} from 'antd'
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import AsideCharacter from '../../components/Aside/AsideCharacter'
import ButtonNext from '../../components/Button/ButtonNext'
import Text from '../../components/Typography/Text.js'
import {setStep} from '../../store/actions/Steps.Action'
import {CreateOnboardingCrePre} from '../../store/actions/requestCard.actions'
import {openNotificationWithIcon} from '../../utils/Functions'
import {formFields} from '../CardRequest/FormRequest.config'
import {Grid} from '@mui/material'
import background from 'src/assets/images/onboardingCredito/background_onboarding.webp'
import card from 'src/assets/images/onboardingCredito/card-identity.svg'
import email from 'src/assets/images/onboardingCredito/email-icon.svg'
import phone from 'src/assets/images/onboardingCredito/phone-icon.svg'
import ButtonNextOnboarding from 'src/components/Button/ButtonNextOnboarding'
import TitleResponsiveOnboarding from 'src/components/TitleInput/TitleResponsiveOnboarding'
import {BreakPointTheme} from 'src/theme/breakpoints'
import {SCREENS} from './screens'
const DivWelcome = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  height: '100vh',
  '@media (max-width:600px)': {
    marginTop: '2rem',
    height: '80%',
  },
}))
const Img = styled('img')(() => ({
  color: '#fff',
  width: '31px',
  margin: 'auto',
  // marginRight: '1.5rem',
  [BreakPointTheme.breakpoints.down('sm')]: {
    marginRight: '0.3rem',
    width: '21px',
    height: '1rem',
    margin: 'auto',
  },
}))
const TitleJustify = styled('div')(() => ({
  width: '65%',
  [BreakPointTheme.breakpoints.down('lg1')]: {
    marginTop: 'initial',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '96%',
    marginLeft: '1rem',
    marginTop: '8vh',
  },
}))
const ImgPhone = styled('img')(() => ({
  width: '16px',
  // height: '2vh',
  // marginRight: '1.5rem',
  margin: 'auto',
  [BreakPointTheme.breakpoints.down('sm')]: {
    marginRight: '0.3rem',
    width: '16px',
    height: '25px',
    margin: 'auto',
  },
}))
const TextSub = styled('p')(() => ({
  fontFamily: 'Red Hat Text',
  color: '#FFF',
  fontSize: 'clamp(16px, 1vw, 25px)',
  marginBottom: 0,
  [BreakPointTheme.breakpoints.down('ax')]: {
    marginLeft: '0.5rem',
  },
}))
const DivContent = styled.div(() => ({
  width: '65%',
  background: '#024C16',
  textAlign: 'left',
  padding: '1rem 1rem',
  margin: '4rem',
  borderRadius: '1rem',
  fontSize: '1.563rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  [BreakPointTheme.breakpoints.down('ax')]: {
    padding: '1rem 1rem',
    marginBottom: '8vh',
    margin: '10vh 4rem 1rem 4rem',
    width: '84%',
  },
  [BreakPointTheme.breakpoints.down('ax')]: {
    padding: '1rem 1rem',
    marginBottom: '8vh',
    margin: '10vh 4rem 1rem 4rem',
    width: '84%',
  },
  [BreakPointTheme.breakpoints.down('lg1')]: {
    padding: '1rem 1rem',
    marginBottom: '8vh',
    margin: '3rem 4rem 3rem 4rem',
    width: '60%',
  },
  [BreakPointTheme.breakpoints.down('ax')]: {
    padding: '1rem 1rem',
    marginBottom: '8vh',
    margin: '10vh 4rem 1rem 4rem',
    width: '84%',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    padding: '1rem 1rem',
    marginBottom: '8vh',
    margin: '17vh 4rem 4rem 4rem',
    width: '84%',
  },
  [BreakPointTheme.breakpoints.down('ax')]: {
    padding: '1rem 1rem',
    marginBottom: '8vh',
    margin: '10vh 4rem 1rem 4rem',
    width: '84%',
  },
  [BreakPointTheme.breakpoints.down('eg')]: {
    padding: '1rem 1rem',
    marginBottom: '8vh',
    margin: '10vh 4rem 1rem 4rem',
    width: '84%',
  },
}))
const H1Title = styled(Typography)(() => ({
  color: 'var(--color-primary-main)',
  fontFamily: 'Gilroy',
  textAlign: 'center',
  '@media (min-width:1200px)': {
    fontSize: '2.4rem',
    margin: '0 auto',
    width: '100%',
    lineHeight: '46px',
  },
  '@media (max-width:1200px)': {
    fontSize: '2.2rem',
    margin: 'auto',
    marginBottom: '2rem',
    width: '100%',
  },
  '@media (max-width:992px)': {
    fontSize: '2rem',
    margin: 'auto',
    width: '75%',
  },
  '@media (max-width:768px)': {
    fontSize: '1.75rem',
    margin: '1vh auto',
    width: '60%',
    lineHeight: '34px',
  },
  '@media (max-width:576px)': {
    fontSize: '1.5rem',
    margin: '3vh auto 3% auto',
    width: '90%',
    textAlign: 'center',
    lineHeight: '24px',
  },
}))
const DivFooterBotton = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  width: '100%',
  margin: 'auto',
  marginTop: '2rem',
}))
const ModalMain = styled(Modal)(() => ({
  width: '50% !important',
  '.ant-modal-content': {
    // width: '50vw',
    borderRadius: '0px !important',
    height: 'calc(60vh - 20px) !important',
    margin: 'auto',
  },
  '.ant-modal-body': {
    margin: ' auto',
    display: ' flex',
    height: ' 100%',
    justifyContent: ' center',
    alignItems: ' center',
  },
  '.ant-modal-centered .ant-modal': {
    width: '50% !important',
  },
}))
const ModalSeller = styled(Modal)(() => ({
  width: '65% !important',
  '.ant-modal-content': {
    // width: '50vw',
    borderRadius: '0px !important',
    height: 'calc(60vh - 20px) !important',
    margin: 'auto',
  },
  '.ant-modal-body': {
    margin: ' auto',
    display: ' flex',
    height: ' 100%',
    justifyContent: ' center',
    alignItems: ' center',
  },
  '.ant-modal-centered .ant-modal': {
    width: '65% !important',
  },
}))
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
  backgroundImage: `url(${background})`,
  backgroundRepeat: 'round',
  backgroundSize: 'cover',
}))
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  opacity: 1,
  objectFit: 'cover',
  flex: 0.33,
  [BreakPointTheme.breakpoints.down('sm')]: {
    flex: 0,
    display: 'unset',
  },
}))
const WelcomeScreenComponent = props => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  var url = new URL(window.location.href)

  // Obtén los parámetros de la URL
  var params = new URLSearchParams(url.search)

  // Define la clave del parámetro en minúsculas
  var key = 'loadid'

  // Busca el parámetro sin importar mayúsculas o minúsculas
  var naosId = null
  for (var param of params.entries()) {
    if (param[0].toLowerCase() === key) {
      naosId = param[1]
      break
    }
  }

  // States
  const [loading, setLoading] = useState(false)
  const [verifyState, setVerifyState] = useState(false)
  const [isPanamanian, setIsPanamanian] = useState(false)
  const [registerSeller, setRegisterSeller] = useState(false)
  const [laodId, setLoadId] = useState(false)
  // Handlers
  const handleRegisterSeller = () => {
    setRegisterSeller(true)
  }
  const handleClick = () => {
    setLoading(true)
    dispatch(CreateOnboardingCrePre(naosId, onSuccess, 'PHONE'))
  }
  const deniedHandle = () => {
    props.goToStep(16)
  }

  const onSuccess = success => {
    setLoading(false)
    success && setIsPanamanian(true)
  }

  const nextStep = () => {
    props.goToStep(SCREENS.ScanID)
  }
  const onFinish = values => {
    setLoadId(true)
    if (values.seller) {
      openNotificationWithIcon('success', 'Se agregó identidad correctamente')
      dispatch({type: 'SET_SELLER_IDENTIFICATION', payload: values.seller})
      setTimeout(() => {
        setRegisterSeller(false)
        setLoadId(false)
      }, 2000)
    } else {
      setRegisterSeller(false)
      setLoadId(false)
    }
  }
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }
  return (
    <Container>
      {/* <CheckRequirements /> */}
      <Wrapper
        style={{
          flex: 1,
          height: '100%',
          justifyContent: 'center',
        }}>
        <Button
          style={{
            border: 'none',
            background: 'transparent',
            height: '66px',
            width: '66px',
            justifyContent: 'center',
            margin: '1rem 0 0 6rem',
          }}
          icon={
            <SettingOutlined
              style={{fontSize: '26px', color: 'var(--fondo-blanco)'}}
            />
          }
          size="large"
          onClick={handleRegisterSeller}
        />
        <DivWelcome>
          <TitleJustify>
            <TitleResponsiveOnboarding
              style={{marginBottom: 0}}
              minSize={45}
              maxSize={85}
              text={`Compra tu celular`}
              color="#fff"
              fontFamily="Red Hat Text"
            />
            <TitleResponsiveOnboarding
              minSize={45}
              maxSize={85}
              text={`y págalo en cuotas fijas`}
              color="#fff"
              fontFamily="Red Hat Text"
            />
          </TitleJustify>

          <DivContent>
            <p
              style={{
                fontFamily: 'Red Hat Text',
                color: '#FFF',
                fontSize: 'clamp(20px, 1vw, 25px)',
                marginBottom: 0,
              }}>
              IMPORTANTE:
            </p>
            <Grid
              container
              sx={{
                [BreakPointTheme.breakpoints.up('900')]: {
                  gap: '10px',
                },
                [BreakPointTheme.breakpoints.up('lg1')]: {
                  gap: '12px',
                },
              }}
              spacing={0}
              justifyContent="center"
              alignItems="center"
              gap={0.5}>
              <Grid container item spacing={0}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  xl={1}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Img src={card} />
                </Grid>
                <Grid item xs={10} md={11} xl={11}>
                  <TextSub>
                    Ten a mano tu cédula y asegúrate de que esté limpia
                  </TextSub>
                </Grid>
              </Grid>
              <Grid container item spacing={0}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  xl={1}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <ImgPhone src={phone} alt="Mail" />
                </Grid>
                <Grid item xs={10} md={11} xl={11}>
                  <TextSub>Asegúrate de tener acceso a tu celular</TextSub>
                </Grid>
              </Grid>
              <Grid container item spacing={0}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  xl={1}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Img src={email} alt="Mail" />
                </Grid>
                <Grid item xs={10} md={11} xl={11}>
                  <TextSub>
                    Asegúrate de tener acceso a tu correo electrónico
                  </TextSub>
                </Grid>
              </Grid>
            </Grid>
          </DivContent>
          <ButtonNextOnboarding
            style={{
              fontWeight: '600 !impotant',
              background: '#FFF 0% 0% no-repeat padding-box !important',
              border: '2px solid #53a600',
              color: '#026E18',
            }}
            variant="onboardingOutline"
            loading={loading}
            disabled={false}
            onClick={handleClick}>
            Solicitar aquí
          </ButtonNextOnboarding>
        </DivWelcome>
        <ModalMain
          centered
          open={isPanamanian}
          footer={false}
          // onOk={() => setOpen(false)}
          onCancel={() => setIsPanamanian(false)}>
          <div style={{widows: '50%'}}>
            <H1Title>Datos de ciudadanía</H1Title>
            <Text
              textType="paragraphs"
              style={{textAlign: 'center !important'}}>
              ¿Eres panameño o extranjero con residencia permanente en Panamá?
            </Text>
            <DivFooterBotton>
              <ButtonNext
                background="green"
                border="green"
                data-testid="yes-Welcome"
                style={{height: 60, width: 135, marginRight: 6}}
                onClick={() => {
                  setVerifyState(true)
                  setIsPanamanian(false)
                  dispatch(setStep(1))
                }}>
                Si
              </ButtonNext>

              <ButtonNext
                background="white"
                border="green"
                style={{height: 60, width: 135, marginLeft: 6}}
                onClick={deniedHandle}>
                No
              </ButtonNext>
            </DivFooterBotton>
          </div>
        </ModalMain>
        <ModalMain
          centered
          open={verifyState}
          footer={false}
          // onOk={() => setOpen(false)}
          onCancel={() => setVerifyState(false)}>
          <div style={{widows: '50%'}}>
            <Text
              textType="paragraphs"
              style={{textAlign: 'center !important'}}>
              Primero verificaremos tu identidad, <br></br>por favor sigue las
              instrucciones paso a paso
            </Text>
            <DivFooterBotton>
              <ButtonNext
                data-testid="next-welcome"
                background="green"
                border="white"
                onClick={nextStep}>
                Continuar
              </ButtonNext>
            </DivFooterBotton>
          </div>
        </ModalMain>
        <ModalSeller
          centered
          open={registerSeller}
          footer={false}
          // onOk={() => setOpen(false)}
          onCancel={() => setRegisterSeller(false)}>
          <div style={{widows: '80%', width: '65%'}}>
            <Text
              textType="title"
              style={{
                textAlign: 'center !important',
                display: 'flex',
                justifyContent: 'center',
              }}>
              Registro de venta
            </Text>
            <Text
              textType="paragraphs_bold"
              style={{
                textAlign: 'center !important',
              }}>
              Esta pantalla es de uso exclusivo del vendedor
            </Text>

            <Form
              form={form}
              name="basic"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 32,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off">
              <Text
                textType="paragraphs_bold"
                style={{
                  textAlign: 'left !important',
                  display: 'flex',
                  marginTop: '2rem',
                }}>
                Digita el número de cédula del vendedor
              </Text>
              <Form.Item
                name="seller"
                rules={formFields.identityVendedor?.rules}>
                <Input
                  id="seller"
                  style={{height: '3.5rem'}}
                  placeholder="Ingresa aquí"
                />
              </Form.Item>
              <Form.Item
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                <ButtonNext
                  data-testid="next-welcome"
                  background="green"
                  border="white"
                  htmlType="submit"
                  loading={laodId}>
                  Guardar
                </ButtonNext>
              </Form.Item>
            </Form>
          </div>
        </ModalSeller>
      </Wrapper>
      <Wrapper style={{flex: 0.3}}>
        <AsideCharacter type="success" color="green" positionX="right" />
      </Wrapper>
    </Container>
  )
}

export default WelcomeScreenComponent

import React from 'react'
import styled from '@emotion/styled'

export default ({message, style}) => {
    return(
        <Wrapper style={style ? style : null}>
            <StyledMessage>{message}</StyledMessage>
        </Wrapper>
    )
}

const Wrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgb(240, 249, 227)'
}))

const StyledMessage = styled('span')(() => ({
    fontSize: '0.8rem',
    color: 'black',
    padding: 16
}))
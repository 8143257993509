import styled from '@emotion/styled'
import React from 'react'
import {useSelector} from 'react-redux'
import AsideCharacter from '../../components/Aside/AsideCharacter'
import ButtonNext from '../../components/Button/ButtonNext'
import Text from '../../components/Typography/Text'
import { URL_PHONE } from '../../utils/Constants'

import './../CardRequest/styles/index.scss'
export default () => {
  const indentify = useSelector(
    state => state.RequestCardReducer?.naosOnboarding?.CellularIdentifier,
  )
  const Url = useSelector(
    state => state.RequestCardReducer?.naosOnboarding?.Url,
  )
  const {id} = useSelector(state => state.Phone.onboardingPhone)
  const params = new URLSearchParams()
  params.append('onboardingId', Number(id))
  params.append('successful', true)
  const onClose = () => {
    if (indentify && Url) {
      window.location.href = `${Url}?${params.toString()}`
    } else {
      window.location.href = `/${URL_PHONE}`
    }
  }
  return (
    <Container>
      <Wrapper
        style={{
          flex: 1,
          background: 'rgb(41, 164, 56)',
          height: '100%',
          justifyContent: 'center',
        }}>
        <Flex>
          <Text
            textType="title"
            style={{
              fontSize: '3rem',
              lineHeight: 1.5,
              color: 'var(--fondo-blanco)',
            }}>
            Tu contrato ha sido firmado <br />
            Hemos finalizado el proceso
          </Text>
          <Text
            textType="paragraphs"
            style={{
              lineHeight: 1.5,
              marginTop: 24,
              color: 'var(--fondo-blanco)',
            }}>
            Te hemos enviado un correo electrónico con información importante.
          </Text>
          <Text
            textType="paragraphs"
            style={{
              lineHeight: 1,
              marginBottom: 8,
              color: 'var(--fondo-blanco)',
            }}>
            Encontrarás detalle de tu credimóvil CMF, copia
          </Text>
          <Text
            textType="paragraphs"
            style={{lineHeight: 1, color: 'var(--fondo-blanco)'}}>
            de tu contrato y cotización firmada
          </Text>
          <ButtonNext
            style={{width: 200, height: 65, marginTop: 32, borderRadius: 5}}
            background="white"
            border="white"
            onClick={() => onClose()}>
            Salir
          </ButtonNext>
        </Flex>
      </Wrapper>
      <Wrapper style={{flex: 0.3}}>
        <AsideCharacter type="success" color="green" positionX="right" />
      </Wrapper>
    </Container>
  )
}

const Flex = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  height: '100vh',
  background: 'var(--color-primary-main)',
  '@media (max-width:600px)': {
    marginTop: '2rem',
    height: '80%',
  },
}))

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  background: 'var(--color-primary-main)',
  opacity: 1,
}))

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}))

import {QuestionCircleOutlined} from '@ant-design/icons'
import styled from '@emotion/styled'
import {Popover} from 'antd'
import React from 'react'
import {format} from '../../utils/currency.js'
import ButtonNext from '../Button/ButtonNext.js'

export default ({data, onSend, loading}) => {
  const content = (
    <div
      style={{
        lineHeight: '0.8',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
      <p
        style={{
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        Pago en caja (50% del teléfono +{' '}
      </p>
      <br />
      <p
        style={{
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        diferencia con límite financiamiento ({format(data.amountToFinance)}))
      </p>
    </div>
  )

  return (
    <Container>
      <Wrapper style={{paddingTop: 8}}>
        <LeftText>{'Precio del celular'}</LeftText>
        <RightText>{format(data.price)}</RightText>
      </Wrapper>
      <Wrapper>
        <LeftText>{'ITBMS'}</LeftText>
        <RightText>{format(data.itbms)}</RightText>
      </Wrapper>
      <Wrapper>
        <LeftText>{'Abono inicial'}</LeftText>
        <RightText>{format(data.initial)}</RightText>
      </Wrapper>
      <Wrapper>
        <LeftText>{'Monto a Financiar'}</LeftText>
        <RightText>{format(data.amountToFinance)}</RightText>
      </Wrapper>

      <Wrapper>
        <LeftText>{'Plazo de pago'}</LeftText>
        <RightText>{`${data.quotas} cuotas`}</RightText>
      </Wrapper>

      <Wrapper style={{paddingBottom: 32}}>
        <LeftText style={{fontWeight: 'bold'}}>
          {'Pago en caja:'}
          <Popover placement="right" content={content} trigger="hover">
            <QuestionCircleOutlined className="IconQuestion" />
          </Popover>
        </LeftText>
        <RightText style={{fontWeight: 'bold'}}>{`${format(
          data.InitialPayment,
        )}`}</RightText>
      </Wrapper>

      <Divider />
      <Wrapper style={{paddingBottom: 16}}>
        <LeftText style={{fontWeight: 'bold'}}>{'Cuota mensual'}</LeftText>
        <RightText style={{fontWeight: 'bold'}}>
          {format(data.quoteValue)}
        </RightText>
      </Wrapper>
      <Divider />
      <Wrapper
        style={{justifyContent: 'center', paddingBottom: 16, marginTop: 16}}>
        <ButtonNext
          data-testid="btn-accept-quote"
          background="white"
          border="green"
          disabled={data.priceEnter !== data.price}
          loading={loading}
          onClick={() => (onSend ? onSend() : null)}>
          Sí, lo quiero
        </ButtonNext>
      </Wrapper>
    </Container>
  )
}

const Divider = styled('div')(() => ({
  width: '100%',
  height: 1,
  background: 'rgb(204, 235, 200)',
}))

const LeftText = styled('p')(() => ({
  flex: 1,
  marginBottom: 0,
  fontSize: '1rem',
  lineHeight: 1,
}))

const RightText = styled('p')(() => ({
  flex: 1,
  marginBottom: 0,
  fontSize: '1rem',
  textAlign: 'right',
  lineHeight: 1,
}))

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 16,
}))

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  background: 'rgb(240, 249, 227)',
  padding: 32,
  borderRadius: 4,
  fontFamily: ['Gilroy-Ligth', 'sans-serif']
}))

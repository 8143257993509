import React from 'react'

export default ({hug,color}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150.99}
    height={56.19}
    viewBox="0 0 150.99 56.19"
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rect\xE1ngulo 2857"
          fill="none"
          d="M0 0h14.19v14.529H0z"
        />
      </clipPath>
      <clipPath id="b">
        <path
          data-name="Rect\xE1ngulo 2859"
          fill="none"
          d="M0 0h11.947v20.034H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Grupo 8159">
      <path
        data-name="Trazado 4340"
        d="M24.352 20.168c-6.956 0-12.24 5.475-12.24 12.694s5.284 12.822 12.24 12.822a16.208 16.208 0 0 0 10.024-4.177 1.672 1.672 0 0 1 2.321.108l4.658 5.008a1.663 1.663 0 0 1-.082 2.352 26.888 26.888 0 0 1-17.565 7.211C10.113 56.181 0 46.26 0 32.986s10.373-22.81 24.158-22.81a26.1 26.1 0 0 1 17.1 6.621 1.666 1.666 0 0 1 .145 2.289l-4.627 5.546a1.648 1.648 0 0 1-2.42.137 14.851 14.851 0 0 0-10.011-4.605"
        fill={color}
      />
      <path
        data-name="Trazado 4341"
        d="M130.477 26.114v3.1a1.211 1.211 0 0 0 1.211 1.211h14.342a1.211 1.211 0 0 1 1.211 1.211v6.96a1.211 1.211 0 0 1-1.211 1.211h-14.342a1.211 1.211 0 0 0-1.211 1.211v9.973a4.68 4.68 0 0 1-4.686 4.682h-5.924a1.211 1.211 0 0 1-1.21-1.211v-28.6c0-9.571 6.552-15.675 16.7-15.675a42.842 42.842 0 0 1 14.875 2.52 1.216 1.216 0 0 1 .714 1.446l-2.056 7.493a1.212 1.212 0 0 1-1.6.808 32.961 32.961 0 0 0-10.835-2.313 5.631 5.631 0 0 0-5.974 5.974"
        fill={color}
      />
      <path
        data-name="Trazado 4342"
        d="M143.554 51.664a.5.5 0 0 0 0-1h-.558v1Zm.889.591.73 1.15h-1.172l-.48-.956h-.523v.956h-1.036v-3.53h1.595c.945 0 1.49.454 1.49 1.24a1.213 1.213 0 0 1-.6 1.14Zm1.81-.488a2.807 2.807 0 0 0-2.811-2.825 2.84 2.84 0 0 0-2.825 2.847 2.8 2.8 0 0 0 2.8 2.824 2.846 2.846 0 0 0 2.837-2.846Zm1.036 0a3.877 3.877 0 0 1-3.87 3.883 3.872 3.872 0 1 1 3.87-3.883"
        fill={color}
      />
      <path
        data-name="Trazado 4343"
        d="M99.678 10.179A15.576 15.576 0 0 0 85.76 18.19a.784.784 0 0 1-1.389-.027 13.714 13.714 0 0 0-12.881-7.978 15.756 15.756 0 0 0-12.063 5.373.786.786 0 0 1-1.385-.511v-.748a3.743 3.743 0 0 0-3.743-3.743h-7.177a.968.968 0 0 0-.969.967v43.413a.788.788 0 0 0 .791.79h10.308a.79.79 0 0 0 .79-.79V31.167c0-5.78 3.574-10 8.64-10.072 4.61 0 7.535 3.446 7.535 8.705v25.136a.788.788 0 0 0 .791.79h10.436a.788.788 0 0 0 .791-.79V31.167c0-5.78 3.572-10 8.7-10.072 4.61 0 7.471 3.446 7.471 8.705v21.188a4.735 4.735 0 0 0 4.734 4.734h6.493a.791.791 0 0 0 .79-.79V26.941c0-10.072-5.977-16.762-14.746-16.762"
        fill={color}
      />
      <path
        data-name="Trazado 4344"
        d="m31.003 15.2-9.325-9.66a2.062 2.062 0 0 1 .051-2.915l.343-.33a8.185 8.185 0 0 1 11.575.2l7.035 7.286a8.187 8.187 0 0 1-.2 11.576l-.99.956-5.275 5.1a8.251 8.251 0 0 1-11.664-.2l-.492-.507a2.062 2.062 0 0 1 .051-2.915l8.9-8.589Z"
        fill={hug}
      />
      <g
        data-name="Grupo 8151"
        opacity={0.8}
        style={{
          mixBlendMode: "multiply",
          isolation: "isolate",
        }}
      >
        <g data-name="Grupo 8150">
          <g
            data-name="Grupo 8149"
            clipPath="url(#a)"
            transform="translate(21.479 15.314)"
          >
            <g data-name="Grupo 8148">
              <g data-name="Grupo 8147" clipPath="url(#a)">
                <path
                  data-name="Trazado 4345"
                  d="m1.071 12.013-.49-.507a2.06 2.06 0 0 1 .05-2.915l8.9-8.589 2.64 2.733a7.213 7.213 0 0 1 .162 9.846 8.245 8.245 0 0 1-11.258-.571"
                  fill={hug}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g
        data-name="Grupo 8156"
        opacity={0.4}
        style={{
          mixBlendMode: "multiply",
          isolation: "isolate",
        }}
      >
        <g data-name="Grupo 8155">
          <g
            data-name="Grupo 8154"
            clipPath="url(#b)"
            transform="translate(31.191 7.751)"
          >
            <g data-name="Grupo 8153">
              <g data-name="Grupo 8152" clipPath="url(#b)">
                <path
                  data-name="Trazado 4346"
                  d="m7.712 0 1.921 1.994a8.252 8.252 0 0 1-.2 11.665l-.946.914-5.276 5.1c-.13.126-.266.25-.406.364a7.211 7.211 0 0 0-.162-9.845L0 7.455 7.712.004Z"
                  fill={hug}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  )
}

import { message } from 'antd';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { PRODUCTS_TYPES } from 'src/utils/Constants';
import { Cmf_Base, ExtraFinanApi, movementApi } from '../../Data/Api';
import { openNotificationWithIcon } from '../../utils/Functions';
import {
  setAllMovements,
  setErrorMovements,
  setLoadingDataAccount,
  setLoadingMovements,
  setProductData,
} from './actions';

export const movementStatus = (index, anyo = 0, mes = 0) => {
  return async (dispatch, getState) => {
    const {ActiveProductReducer, AuthenticationReducer} = getState();
    const {products} = ActiveProductReducer;
    const {CurrentUser} = AuthenticationReducer;
    const token = CurrentUser?.tokenCMF;
    dispatch({type: 'MOVEMENT_STATUS_SHOW'});
    dispatch({type: 'MOVEMENT_STATUS_CLEAN'});
    try {
      const response = await axios.get(`${Cmf_Base}${movementApi}`, {
        params: {
          anyo,
          mes,
          cuenta: products && products[0].productAccount,
          CreatedBy: products && products[0].productAccount,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const {data, status} = response;
      if (status && status === 200 && data) {
        dispatch({
          type: 'MOVEMENT_STATUS_SUCCESS',
          payload: JSON.parse(data.jsonAnswer),
        });
      } else {
        message.error('statusText');
      }
    } catch (error) {
      dispatch({type: 'MOVEMENT_STATUS_ERROR', payload: error});
      openNotificationWithIcon(
        'error',
        error.response.data.problemPublic
          ? error.response.data.problemPublic
          : 'Error al obtener los movimientos',
      );
    } finally {
      dispatch({type: 'MOVEMENT_STATUS_HIDE'});
    }
  };
};
/**
 * Meotodo para obtener los planes de intrafinanciamiento del producto
 * @param {*} index
 * @param {*} anyo
 * @param {*} mes
 * @returns
 */
export const intrafinanciamiento = (index = 0, anyo = 0, mes = 0) => {
  return async (dispatch, getState) => {
    const {ActiveProductReducer, AuthenticationReducer} = getState();
    const {products} = ActiveProductReducer;
    const {CurrentUser} = AuthenticationReducer;
    const token = CurrentUser?.tokenCMF;

    dispatch({type: 'EXTRAF_STATUS_SHOW'});
    dispatch({type: 'EXTRAF_STATUS_CLEAN'});

    try {
      const response = await axios.get(`${Cmf_Base}${ExtraFinanApi}`, {
        params: {
          anyo,
          mes,
          cuenta: products && products[index].productAccount,
          CreatedBy: products && products[index].productAccount,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const {data, status} = response;
      if (status && status === 200 && data) {
        dispatch({
          type: 'EXTRAF_STATUS_SUCCESS',
          payload: JSON.parse(data.jsonAnswer),
        });
      } else {
        message.error('statusText');
      }
    } catch (error) {
      dispatch({type: 'EXTRAF_STATUS_ERROR', payload: error});
    } finally {
      dispatch({type: 'EXTRAF_STATUS_HIDE'});
    }
  };
};

export const resetMovementReduce = () => {
  return async dispatch => {
    dispatch({
      type: 'MOVEMENT_STATUS_SUCCESS',
      payload: [],
    });
  };
};

export const getMovementsByDate = (selectedProduct, year, month) => {
  return async (dispatch, getState) => {
    const {AuthenticationReducer} = getState();
    const {CurrentUser} = AuthenticationReducer;
    const token = CurrentUser?.tokenCMF;
    dispatch(setLoadingMovements(true));
    dispatch(setAllMovements([]));
    dispatch(setErrorMovements(false));
    try {
      const isPrepaidCustomer =
        selectedProduct.productTypeId.toUpperCase() ===
        PRODUCTS_TYPES.PREPP.toUpperCase();

      const fetchMovements = async tipe_mov => {
        try {
          const response = await axios.get(
            `${Cmf_Base}api/Versatec/VtcObtenerMovimientosCuenta`,
            {
              params: {
                anyo: year,
                mes: month,
                cuenta: selectedProduct?.productAccount,
                CreatedBy: CurrentUser?.accountSF[0]?.Name,
                tipo_mov: tipe_mov,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          if (response.status !== 200) {
            throw new Error(
              `Error en la petición de movimientos: ${response.status}`,
            );
          }
          return JSON.parse(response.data.jsonAnswer);
        } catch (error) {
          throw error;
        }
      };

      let movementsF = [];
      let movementsE = [];
      if (
        selectedProduct.productTypeId.toUpperCase() !==
        PRODUCTS_TYPES.PREPP.toUpperCase()
      ) {
        try {
          movementsF = await fetchMovements('F');
        } catch (error) {
          if (error.response?.status === 404) {
            movementsE = await fetchMovements('E');
          } else {
            throw error;
          }
        }
      } else {
        try {
          movementsE = await fetchMovements('E');
        } catch (error) {
          if (
            error.response?.data?.problemInternal ===
              'No existen movimientos.' ||
            (error.response?.data?.problemInternal?.includes(
              'NO EXISTEN MOVIMIENTOS A CONSULTAR',
            ) &&
              !error.response?.data?.complete)
          ) {
            dispatch(setErrorMovements(false));
          } else {
            dispatch(setErrorMovements(true));
          }
          throw error;
        }
      }

      if (movementsE.length === 0 && !isPrepaidCustomer) {
        try {
          movementsE = await fetchMovements('E');
        } catch (error) {
          if (
            error.response?.data?.problemInternal ===
              'No existen movimientos.' ||
            (error.response?.data?.problemInternal?.includes(
              'NO EXISTEN MOVIMIENTOS A CONSULTAR',
            ) &&
              !error.response?.data?.complete)
          ) {
            dispatch(setErrorMovements(false));
          } else {
            dispatch(setErrorMovements(true));
          }
          throw error;
        }
      }

      let totalMtoTraF = movementsF.reduce(
        (sum, movement) => sum + movement.MtoTra,
        0,
      );

      dispatch(
        setAllMovements(
          movementsF
            .concat(movementsE)
            .sort((a, b) => b.Secuencia - a.Secuencia),
        ),
      );

      dispatch({
        type: 'FLOAT_AMOUNT',
        payload: totalMtoTraF,
      });
    } catch (error) {
      if (
        error?.response?.data?.problemInternal === 'No existen movimientos.' ||
        (error?.response?.data?.problemInternal?.includes(
          'NO EXISTEN MOVIMIENTOS A CONSULTAR',
        ) &&
          !error?.response?.data?.complete)
      ) {
        dispatch(setAllMovements([]));
        dispatch(setErrorMovements(false));
      } else {
        dispatch(setErrorMovements(true));
        NotificationManager.error(
          'Ha ocurrido un error obteniendo los movimientos',
        );
      }
    } finally {
      dispatch(setLoadingMovements(false));
    }
  };
};

export const getDetailsAccount = (productAccount, customerProductId) => {
  return async (dispatch, getState) => {
    const {AuthenticationReducer} = getState();
    const {CurrentUser} = AuthenticationReducer;
    const token = CurrentUser?.tokenCMF;
    dispatch(setLoadingDataAccount(true));
    try {
      const response = await axios.get(
        `${Cmf_Base}api/Versatec/VtcInfoEstCtaTH?anyo=0&Mes=0&Cuenta=${encodeURIComponent(
          productAccount,
        )}&CreatedBy=${CurrentUser?.accountSF[0]?.Name}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const setDataAccount = JSON.parse(response?.data.jsonAnswer);
      dispatch(setProductData(customerProductId, setDataAccount));
    } catch (error) {
      NotificationManager.error('Ha ocurrido un error obteniendo información');
    } finally {
      dispatch(setLoadingDataAccount(false));
    }
  };
};

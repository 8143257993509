import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, Input, Modal, message } from 'antd'
import Axios from 'axios'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  AddLogProccess,
  editFormRequest,
  validatePhone,
} from '../../store/actions/requestCard.actions'
import { APP } from '../../utils/Constants'
import { formFields } from './FormRequest.config'

import { API_KEY } from '../../Data/Api'
import phoneHeaderLogoSuccess from '../../assets/images/onboarding/succes-icon-check.svg'
import phoneHeaderLogo from '../../assets/images/phone_talk.png'
import './styles/inputs.scss'
const FormItem = Form.Item

const secondsToExpire = 240

message.config({
  top: 30,
  duration: 4,
  maxCount: 1,
})

class ValidateCellphoneDefault extends Component {
  state = {
    loading: false,
    loadingResend: false,
    cellphone: this.props.initialValue || '',
    code: '',
    isValidating: false,
    countDown: 0,
    timerDown: false,
    confirmed: false,
    isValidatingCall: false,
    visiblePhone: false,
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible) {
      this.setState({
        loading: false,
        loadingResend: false,
        isValidating: false,
        isValidatingCall: false,
        code: '',
        cellphone: this.props.initialValue,
        countDown: 0,
        timerDown: false,
        confirmed: false,
        visiblePhone: false,
      })
    }
  }
  async isValidForm() {
    try {
      await this.props.form.validateFields()
      return true
    } catch (e) {
      return false
    }
  }

  async onPressSubmit(e) {
    e.preventDefault()
    if (!this.state.isValidating) {
      const isValid = await this.isValidForm()
      if (isValid) {
        this.setState({loading: true, visiblePhone: true})
        this.sendSMS()
      } else {
        message.error('Ingrese un número de teléfono valido')
      }
    } else {
      this.checkEmail()
    }
  }
  async onPressSend(e) {
    e.preventDefault()
    if (!this.state.isValidatingCall) {
      const isValid = await this.isValidForm()
      if (isValid) {
        this.sendCall()
      } else {
        message.error('Ingrese un número de teléfono valido')
      }
    } else {
      this.verifiCall()
    }
  }
  async sendSMS() {
    const {onboardingId, origin, cedulaState} = this.props
    try {
      /* only for test email */
      if (APP.fakePhone) {
        this.setState({loading: false, confirmed: true})
        return
      }

      this.setState({loadingResend: true})
      await Axios.post(
        `${APP.cmf.endpoints.sendVerify}`,
        {
          to: '+507' + this.state.cellphone,
        },
        {
          headers: {
            Apikey: API_KEY,
            'content-type': 'application/json',
          },
        },
      ).then(res => {
        var obj = {phone: this.state.cellphone}
        var obj2 = {code: this.state.code}
        var obj3 = {cedula: cedulaState}
        var ErrorRes = Object.assign(obj, obj2, obj3)
        this.props.AddLogProccess(
          'OnboardingConfirmTelephone',
          origin,
          onboardingId,
          res.data.problemPublic,
          ErrorRes,
          true,
        )
      })
      this.start()

      this.setState({isValidating: true, loading: false, loadingResend: false})
    } catch (e) {
      message.error('No hemos podido enviar el SMS')
      var obj = {phone: this.state.cellphone}
      var obj2 = {code: this.state.code}
      var obj3 = {cedula: cedulaState}
      var ErrorRes = Object.assign(obj, obj2, obj3)
      this.props.AddLogProccess(
        'OnboardingConfirmTelephone',
        origin,
        onboardingId,
        e?.response?.data?.problemPublic,
        ErrorRes,
        false,
      )
      this.setState({loading: false, loadingResend: false})
    }
  }

  async sendCall() {
    try {
      /* only for test email */
      if (APP.fakePhone) {
        this.setState({loading: false, confirmed: true})
        return
      }

      this.setState({loadingResend: true})
      await Axios.post(
        `${APP.cmf.endpoints.sendCallVerify}`,
        {
          to: '+507' + this.state.cellphone,
        },
        {
          headers: {
            Apikey: API_KEY,
            'content-type': 'application/json',
          },
        },
      )
      this.setState({
        visiblePhone: false,
        isValidatingCall: true,
        loading: false,
        loadingResend: false,
      })
    } catch (e) {
      message.error('No se ha podido llamar')
      this.setState({visiblePhone: true, loading: false, loadingResend: false})
    }
  }
  async verifiCall() {
    try {
      this.setState({loading: true})
      const res = await Axios.post(
        `${APP.cmf.endpoints.confirmCallVerify}`,
        {
          to: '+507' + this.state.cellphone,
          code: this.state.code,
        },
        {
          headers: {
            Apikey: API_KEY,
            'content-type': 'application/json',
          },
        },
      )

      if (res.data.complete === true) {
        this.setState({loading: false, confirmed: true})
      }
    } catch (err) {
      this.setState({loading: false})
      message.error('El código ingresado es inválido')
    }
  }
  async checkEmail() {
    const {onboardingId, origin, cedulaState} = this.props
    try {
      this.setState({loading: true})
      const res = await Axios.post(
        `${APP.cmf.endpoints.confirmVerify}`,
        {
          to: '+507' + this.state.cellphone,
          code: this.state.code,
        },
        {
          headers: {
            Apikey: API_KEY,
            'content-type': 'application/json',
          },
        },
      )

      if (res.data.complete === true) {
        this.setState({loading: false, confirmed: true})
        var obj = {phone: this.state.cellphone}
        var obj2 = {code: this.state.code}
        var obj3 = {cedula: cedulaState}
        var ErrorRes = Object.assign(obj, obj2, obj3)
        this.props.AddLogProccess(
          'OnboardingGetConfirmTelephone',
          origin,
          onboardingId,
          res.data.problemPublic,
          ErrorRes,
          true,
        )
      }
    } catch (err) {
      this.setState({loading: false})
      this.props.AddLogProccess(
        'OnboardingGetConfirmTelephone',
        origin,
        onboardingId,
        err?.response?.data?.problemPublic,
        ErrorRes,
        false,
      )
      message.error('El código ingresado es inválido')
    }
  }

  async onCompletePhone() {
    this.props.validatePhone(this.state.cellphone)
    this.props.onClose()
  }

  start() {
    const _time = moment().add(secondsToExpire, 'seconds')
    this.setState({countDown: _time, isValidated: true, loading: false}, () =>
      this.tick(),
    )
  }

  tick() {
    const {countDown} = this.state
    if (!countDown.diff) {
      return false
    }
    const diff = countDown.diff(moment())
    const timerDown = moment.utc(diff)
    this.setState({timerDown: timerDown, diff})
    if (diff > 0) {
      setTimeout(this.tick.bind(this), 1000)
    } else {
      message.error('El tiempo ha expirado, por favor intenta nuevamente')
      this.setState({
        countDown: 0,
        timerDown: false,
        isValidated: false,
      })
    }
  }

  onSubmit() {}
  onCancel() {
    /* TODO -- on submit cellphone */
    this.props.EditForm({cellphone: ''})
    this.props.onClose()
  }
  onEdit() {
    this.setState({isValidating: false, countDown: 0, timerDown: 0})
  }
  render() {
    const {countDown, timerDown} = this.state
    const {getFieldDecorator} = this.props.form
    return (
      <Modal
        loading={this.state.loading}
        centered
        open={this.props.visible}
        destroyOnClose={false}
        maskClosable={false}
        onOk={this.onPressSubmit.bind(this)}
        onCancel={this.onCancel.bind(this)}
        cancelButtonProps={{disabled: countDown > 0 && timerDown}}
        closable={!this.state.confirmed}
        wrapClassName="container-validation"
        width={390}
        footer={false}>
        {!this.state.isValidating && !this.state.confirmed && (
          <div className="validation-container">
            <center>
              <img
                alt='Phone header logo'
                src={phoneHeaderLogo}
                style={{width: '39px', height: '39px', marginBottom: '10px'}}
              />
            </center>
            <h1 style={{marginBottom: '10px'}}>
              Validación de teléfono celular
            </h1>
            <FormItem>
              {getFieldDecorator('email', {
                initialValue: this.state.cellphone,
                rules: formFields.cellphone?.rules || [],
                validateTrigger: 'onPressSubmit',
              })(
                <Input
                  type="number"
                  data-testid="inp-cell"
                  id={'cellphone'}
                  name={'cellphone'}
                  onChange={e => this.setState({cellphone: e.target.value})}
                  placeholder={'Ingresa tu número de celular sin guiones'}
                />,
              )}
            </FormItem>
            <p className="description__modal">
              Te enviaremos un mensaje de texto para su confirmación.
            </p>
            <Button
              success
              loading={this.state.loading}
              disabled={false}
              data-testid="btn-send-sms"
              className="Button__Email"
              size="large"
              onClick={this.onPressSubmit.bind(this)}>
              Aceptar
            </Button>
          </div>
        )}

        {/* Validacion de codigo de sms */}
        {this.state.visiblePhone === true &&
        this.state.isValidatingCall === false ? (
          <>
            {this.state.isValidating && !this.state.confirmed && (
              <div className="validation-container">
                <center>
                  <img src={phoneHeaderLogo} alt='Phone Header Logo' />
                </center>
                <h1 style={{marginBottom: '10px', display: 'table-cell'}}>
                  Te enviamos un mensaje de texto a tu celular con tu código de
                  verificación
                </h1>
                {/* <a onClick={this.onPressSend.bind(this)}>
                  No has recibido tu codigo, prueba con una llamada
                </a>
                <p>
                  <a href="#" onClick={this.onEdit.bind(this)}>
                    ¿Deseas editar este número?
                  </a>
                </p> */}
                <FormItem>
                  {getFieldDecorator('code', {
                    initialValue: '',
                  })(
                    <Input
                      type="text"
                      id={'code'}
                      name={'code'}
                      onChange={e => this.setState({code: e.target.value})}
                      placeholder={'Ingresa el código de verificación'}
                    />,
                  )}
                </FormItem>

                <Button
                  success
                  loading={this.state.loading}
                  disabled={false}
                  className="Button__Email"
                  size="large"
                  onClick={this.onPressSubmit.bind(this)}>
                  Aceptar
                </Button>
                <br></br>
                <br></br>
                <p style={{marginBottom: '10px'}}>
                  <a href="#" onClick={this.onEdit.bind(this)}>
                    <u> ¿Deseas editar este número?</u>
                  </a>
                  <a> o </a>
                  <a onClick={this.onPressSend.bind(this)}>
                    <u>prueba con una llamada</u>
                  </a>
                </p>
              </div>
            )}
          </>
        ) : (
          <>
            {/* validacion de codigo de llamada */}
            {this.state.isValidatingCall && !this.state.confirmed && (
              <div className="validation-container">
                <center>
                  <img src={phoneHeaderLogo} alt='Phone Header Logo' />
                </center>
                <h1 style={{marginBottom: '10px'}}>
                  Recibiras una llamada con un <br /> código a tu celular
                </h1>
                {/* <p>
              <a href="#" onClick={this.onEdit.bind(this)}>
                ¿Deseas editar este número?
              </a>
            </p> */}
                <FormItem>
                  {getFieldDecorator('code', {
                    initialValue: '',
                  })(
                    <Input
                      type="text"
                      id={'code'}
                      name={'code'}
                      onChange={e => this.setState({code: e.target.value})}
                      placeholder={'Ingresa el código de verificación'}
                    />,
                  )}
                </FormItem>
                {/* <div style={{ marginBottom: '10px', display: 'block' }}>
              <p className="label-muted">
                Reenviar código{' '}
                {countDown > 1 && timerDown && (
                  <span>
                    &nbsp;en {timerDown.format('mm')}:{timerDown.format('ss')}
                  </span>
                )}
              </p>
            </div> */}
                <Button
                  success
                  loading={this.state.loading}
                  disabled={false}
                  className="Button__Email"
                  size="large"
                  onClick={this.onPressSend.bind(this)}>
                  Aceptar
                </Button>
              </div>
            )}
          </>
        )}

        {this.state.confirmed && (
          <div className="validation-container">
            <center>
              <img
                alt='Phonne success logo Header'
                src={phoneHeaderLogoSuccess}
                style={{width: '39px', height: '39px', marginBottom: '10px'}}
              />
            </center>
            <h1 style={{marginBottom: '10px'}}>
              Validación de teléfono celular
            </h1>
            <p className="description" style={{marginBottom: '15%'}}>
              El teléfono fue confirmado.
            </p>
            <Button
              success
              loading={this.state.loading}
              disabled={false}
              className="Button__Email"
              size="large"
              data-testid="btn-valid-ok"
              onClick={this.onCompletePhone.bind(this)}>
              Aceptar
            </Button>
          </div>
        )}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  const {origin, form, cedulaState} = state.RequestCardReducer
  return {
    origin: origin,
    AnswEID: form,
    cedulaState: cedulaState,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    AddLogProccess: (
      process,
      uniqueCode,
      newCode,
      problemPublic,
      stringToSave,
      isComplete,
    ) =>
      dispatch(
        AddLogProccess(
          process,
          uniqueCode,
          newCode,
          problemPublic,
          stringToSave,
          isComplete,
        ),
      ),
    validatePhone: cellphone => dispatch(validatePhone(cellphone)),
    EditForm: formFields => dispatch(editFormRequest(formFields)),
  }
}

const ValidateCellphone = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ValidateCellphoneDefault)
export default Form.create()(ValidateCellphone)

import {Checkbox, FormControl, FormLabel, styled} from '@mui/material'
import moment from 'moment'
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {checkIdentityTE} from 'src/Data/Client'
import WrapperSide from 'src/components/Aside/Wrapper'
import SubtitleResponsiveOnboarding from 'src/components/TitleInput/SubtitleResponsiveOnboarding'
import TitleResponsiveOnboarding from 'src/components/TitleInput/TitleResponsiveOnboarding'
import {setStep} from 'src/store/actions/Steps.Action'
import {
  editFormRequest,
  evalutionService,
  getActivityTypes,
  getApc,
  getMaritalStatus,
  getProvinces,
  getUpdateTE,
  logsOnboarding,
  getOccupations,
  getSwitchStatusMotor2,
  getApcPrepaid
} from 'src/store/actions/requestCard.actions'
import {BreakPointTheme} from 'src/theme/breakpoints'
import {
  GENDER_TYPE,
  ORIGIN,
  SELLER_ID_TYPE,
  SYSTEM_TYPES,
  SYSTEM
} from 'src/utils/Constants'
import ModalProcessing from '../Modals/ModalProcessing'
import {TermsAndConditionsAPC} from '../TermsAndConditionsAPC'
import SCREENS from '../screens'
import {FormHelperText} from '@mui/material'
import ArrowCommon from 'src/components/ArrowCommon'
import {
  MyBox,
  MyBoxAPC,
  MyInputLabel,
  MyMenuItem,
  SelectComponent,
  TextFieldComponent,
  CurrencyInput,
  FormControlData,
} from 'src/containers/OnboardingCredito/Component/FieldsForms'
import LayoutForm from 'src/containers/OnboardingCredito/Layout/LayoutForm'
import useForm from 'src/hooks/useForms'
import {COUNTRIES_TYPES} from 'src/utils/Constants'
import * as Yup from 'yup'
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
}))
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
  overflowX: 'hidden',
  overflowY: 'auto',
  background: '#F2F4F7',
}))
const ContainerInfo = styled('div')(() => ({
  height: '100%',
  [BreakPointTheme.breakpoints.down('sm')]: {
    height: '100%',
  },
}))
const ContainerForm = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '85%',
  margin: '0px auto',
  height: 'calc(100% - 30%)',
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

const DivTitle = styled('div')(() => ({
  width: '85%',
  margin: '5% auto 2% auto',
  padding: '0 5%',
  h1: {
    textAlign: 'left !important',
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    margin: '5% auto 2% auto',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    margin: '5% auto 2% auto',
    textAlign: 'center',
    h1: {
      textAlign: 'center !important',
    },
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    margin: '10px auto 0px',
    width: '100%',
    textAlign: 'center',
  },
}))

const CheckWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  margin: '2rem 0rem 3%',
  width: '100%',
  padding: '2rem',
  background: '#F9F9F9',
  [BreakPointTheme.breakpoints.down('lg')]: {
    padding: '0.5rem',
    margin: '2rem 0rem 3%',
  },
  [BreakPointTheme.breakpoints.down('md')]: {
    padding: '0.5rem',
    margin: '1rem 0rem 0rem',
  },
}))
const CheckContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}))

const initialData = {
  activityType: {
    name: 'activityType',
    label: 'Actividad Laboral',
    errorMessage: 'Actividad Laboral es requerido',
    delateDoubleSpace: true,
  },
  province: {
    name: 'province',
    label: 'Provincia',
    errorMessage: 'Provincia es requerido',
    delateDoubleSpace: true,
    // onChancheClear: ['district', 'township'],
  },
  civilStatus: {
    name: 'civilState',
    label: 'Estado civil',
    errorMessage: 'Estado civil es requerido',
    delateDoubleSpace: true,
  },
  occupation: {
    name: 'occupation',
    delateDoubleSpace: true,
    label: 'Ocupación',
    errorMessage: 'Ocupación es requerido',
  },
  monthlyIncome: {
    name: 'monthlyIncome',
    delateDoubleSpace: true,
    label: 'Ingreso mensual',
    errorMessage: 'Ingreso mensual es requerido',
  },
}
const validationSchema = Yup.object().shape({
  activityType: Yup.string()
    .required(initialData.activityType.errorMessage)
    .default(''),
  province: Yup.string()
    .required(initialData.province.errorMessage)
    .default(''),
  civilStatus: Yup.string()
    .required(initialData.civilStatus.errorMessage)
    .default(''),
  occupation: Yup.string()
    .required(initialData.occupation.errorMessage)
    .default(''),
  monthlyIncome: Yup.number()
    .required(initialData.monthlyIncome.errorMessage)
    .min(300, 'Salario inválido, debe ser igual o superior a $300.00')
    .max(20000, 'Salario inválido, debe ser igual o inferior a $20,000.00')
    .transform(value => (isNaN(value) ? 0 : value))
    .default(0),
})
export const DataReviewAPC = props => {
  useEffect(() => {
    dispatch(getSwitchStatusMotor2())
  }, [])
  const {phoneForm, emailForm, StatusMotorCreditoV2} = useSelector(
    state => state.RequestCardReducer,
  )
  const {Active} = StatusMotorCreditoV2
  const countryPanama = COUNTRIES_TYPES.PANAMA
  useEffect(() => {
    updateInitialValues()
    setOnChangeEventsForm()
    dispatch(getMaritalStatus())
    dispatch(getOccupations())
    dispatch(getProvinces(countryPanama))
  }, [])
  // HOOKS - STATE
  const {
    formData,
    getValue,
    handleChange,
    handleBlur,
    handleSubmit,
    showError,
    getError,
    setOnChangeEvents,
    setInitialValues,
  } = useForm(initialData, validationSchema)
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(false)
  const [loading, setLoading] = useState(false)

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false)
  const [selectedOption, setSelectedOption] = useState('Selecciona')
  const [activitySelected, setActivitySelected] = useState(false)
  const [pronvinceSelected, setPronviceForm] = useState(false)
  const [civilStatusSelected, setCivilStatusForm] = useState(false)
  const [occupationSelected, setOccupationForm] = useState(false)
  const [monthlyIncomeSelected, SetMonthlyIncomeSelected] = useState(false)
  // QUERIES

  const {
    provinces,
    activityTypes,
    origin,
    onboardingId,
    form,
    checkTe,
    base64,
    maritalStatus,
  } = useSelector(state => state.RequestCardReducer)
  const formRequest = useSelector(state => state.RequestCardReducer.form)

  const setOnChangeEventsForm = useCallback(() => {
    const events = [
      {
        name: 'activityType',
        onChange: value => onChangeActivityType(value),
      },
      {
        name: 'province',
        onChange: value => onChangeProvince(value),
      },
      {
        name: 'civilStatus',
        onChange: value => dispatch(editFormRequest({civilState: value})),
      },
      {
        name: 'occupation',
        onChange: value => dispatch(editFormRequest({occupation: value})),
      },
      {
        name: 'monthlyIncome',
        onChange: value => dispatch(editFormRequest({salary: value})),
      },
    ]
    setOnChangeEvents(events)
  })
  const onChangeProvince = useCallback(async province => {
    if (province) {
      dispatch(editFormRequest({province: province}))
      setPronviceForm(true)
    }
  }, [])
  const onChangeActivityType = useCallback(async activityType => {
    if (!activityType) return setSelectedOption('Selecciona')
    if (activityType) {
      setSelectedOption(activityType)
      setActivitySelected(true)
      dispatch(editFormRequest({activityType: activityType}))
      return setSelectedOption(activityType)
    }
  }, [])
  const updateInitialValues = useCallback(() => {
    const initialValues = [
      {
        name: 'activityType',
        value: formRequest?.activityType,
      },
      {
        name: 'province',
        value: formRequest?.province,
      },
      {
        name: 'civilStatus',
        value: formRequest?.civilState,
      },
      {
        name: 'occupation',
        value: formRequest?.occupation,
      },
      {
        name: 'monthlyIncome',
        value: formRequest?.salary,
      },
    ]
    setInitialValues(initialValues)
  })

  // HANDLESS
  const handleShowTermsAndConditions = useCallback(
    state => {
      setShowTermsAndConditions(state)
    },
    [showTermsAndConditions],
  )

  const handleCheck = e => {
    const {checked} = e.target
    setChecked(checked)
  }

  const handleNext = async () => {
    setLoading(true)
    await onSubmit()
  }

  const selectedPayload = () => {
    if (Active) {
      const payloadWithMotor = {
        onboardingId: onboardingId,
        origin: origin,
        economicActivity: formRequest.activityType,
        ProfessionId: null,
        occupationid: formRequest.occupation,
        provinceId: formRequest.province,
        maritalStatusId: formRequest.civilState,
        createdBy: 'CMF',
        CheckApcDebts: false,
      }
      return payloadWithMotor
    } else {
      const payloadWithOutMotor = {
        onboardingId: onboardingId,
        origin: origin,
        economicActivity: formRequest.activityType,
        createdBy: 'CMF',
        CheckApcDebts: false,
      }
      return payloadWithOutMotor
    }
  }
  const onSubmit = async () => {
    try {
      dispatch({
        type: 'SET_ACTIVITYTYPE_FORM',
        payload: formRequest.activityType,
      })
      dispatch({
        type: 'SET_PROVINCE_FORM',
        payload: formRequest.province,
      })
      dispatch({
        type: 'SET_CIVILSTATUS_FORM',
        payload: formRequest.civilState,
      })
      dispatch({
        type: 'SET_SALARY_FORM',
        payload: formRequest.salary,
      })
      selectedOption !== 'JU' &&
        dispatch({
          type: 'SET_OCCUPATION_FORM',
          payload: formRequest.occupation,
        })
      if (!checked) {
        return false
      }
      setLoading(true)
      if (origin === ORIGIN.PREAPROBADO  || origin === ORIGIN.PREPAGO) {
        const createdBy = SELLER_ID_TYPE
        const branchOfficeId = 'BCC30BB5-2C99-4A34-84CE-01141397FC58'
        let phones = []
        if (phoneForm) {
          phones.push({
            id: 1,
            typePhone: 'CE',
            area: '507',
            phone: phoneForm,
            isDefault: true,
            createdBy: createdBy,
            extension: null,
            lastModifiedBy: null,
          })
        }
        if (formRequest.phone) {
          phones.push({
            typePhone: 'CA',
            area: '507',
            phone: formRequest.phone,
            isDefault: false,
            createdBy: createdBy,
          })
        }
        const formApc = {
          id: onboardingId,
          typeIdentityId: '8F3C2EF0-F0D2-4FF0-9863-218D3D494D56',
          branchOfficeId: branchOfficeId,
          sellerId: SELLER_ID_TYPE,
          approvedBy: SELLER_ID_TYPE,
          approvedDate: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
          genderId:
            formRequest.gender === 1 ? GENDER_TYPE.MALE : GENDER_TYPE.FEMALE,
          countryId: formRequest.country,
          countryBirthId: formRequest.countryOfBirth,
          nationalityId: formRequest.nationality,
          systemId: SYSTEM.VOLCAN,
          identityNumber: formRequest.identity,
          firstName: formRequest.firstName,
          secondName: formRequest.secondName || null,
          firstSurname: formRequest.lastName,
          secondSurname: formRequest.secondLastName,
          dateOfBirth: formRequest.birthdate,
          IDExpirationDate: formRequest.IDExpirationDate,
          phones: phones,
          emails: [
            {
              typeEmail: 'PE',
              email: emailForm,
              isDefault: true,
              createdBy: createdBy,
            },
          ],
          images: [
            {
              typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
              description: 'Cedula Frontal',
              link: formRequest.frontIdentityImage,
              thumbnails: null,
              createdBy: 'CMF',
            },
            {
              typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
              description: 'Cedula Reverso',
              link: formRequest.backIdentityImage,
              thumbnails: null,
              createdBy: 'CMF',
            },
            {
              typeImageId: 'A7398CE9-96CA-41AE-9513-AE233C6FEB02',
              description: 'Biometrico',
              link: formRequest.faceImage,
              thumbnails: null,
              createdBy: 'CMF',
            },
          ],
          createdBy: 'CMF',
          LastModifiedBy: 'CMF',
        }
        if (origin === ORIGIN.PREPAGO) {
          dispatch(getApcPrepaid(formApc, onFinish.bind(this)));
        } else {
          dispatch(getApc(formApc, onFinish.bind(this)));
        }
      } else if (origin === ORIGIN.CREDITO || origin === ORIGIN.PRECALIFICADO) {
        const createdBy = SELLER_ID_TYPE
        const branchOfficeId = 'BCC30BB5-2C99-4A34-84CE-01141397FC58'
        const payload = selectedPayload()
        let phones = []
        if (phoneForm) {
          phones.push({
            id: 1,
            typePhone: 'CE',
            area: '507',
            phone: phoneForm,
            isDefault: true,
            createdBy: createdBy,
            extension: null,
            lastModifiedBy: null,
          })
        }

        if (formRequest.phone) {
          phones.push({
            typePhone: 'CA',
            area: '507',
            phone: formRequest.phone,
            isDefault: false,
            createdBy: createdBy,
          })
        }
        const payloadApc = {
          id: onboardingId,
          typeIdentityId: '8F3C2EF0-F0D2-4FF0-9863-218D3D494D56',
          branchOfficeId: branchOfficeId,
          sellerId: SELLER_ID_TYPE,
          approvedBy: SELLER_ID_TYPE,
          approvedDate: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
          genderId:
            formRequest.gender === 1 ? GENDER_TYPE.MALE : GENDER_TYPE.FEMALE,
          countryId: formRequest.country,
          countryBirthId: formRequest.countryOfBirth,
          nationalityId: formRequest.nationality,
          systemId: SYSTEM_TYPES.CMF,
          identityNumber: formRequest.identity,
          firstName: formRequest.firstName,
          secondName: formRequest.secondName || null,
          firstSurname: formRequest.lastName,
          secondSurname: formRequest.secondLastName,
          dateOfBirth: formRequest.birthdate,
          IDExpirationDate: formRequest.IDExpirationDate,
          salary: parseFloat(formRequest.salary),
          phones: phones,
          emails: [
            {
              typeEmail: 'PE',
              email: emailForm,
              isDefault: true,
              createdBy: createdBy,
            },
          ],
          images: [
            {
              typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
              description: 'Cedula Frontal',
              link: formRequest.frontIdentityImage,
              thumbnails: null,
              createdBy: 'CMF',
            },
            {
              typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
              description: 'Cedula Reverso',
              link: formRequest.backIdentityImage,
              thumbnails: null,
              createdBy: 'CMF',
            },
            {
              typeImageId: 'A7398CE9-96CA-41AE-9513-AE233C6FEB02',
              description: 'Biometrico',
              link: formRequest.faceImage,
              thumbnails: null,
              createdBy: 'CMF',
            },
          ],
          createdBy: 'CMF',
          LastModifiedBy: 'CMF',
        }
        dispatch(evalutionService(payloadApc, payload, onFinish.bind(this)))
      } else {
        setLoading(false)
      }
    } catch {
      setLoading(false)
    }
  }
  const onSuccess = success => {
    setLoading(false)
    if (success) {
      dispatch(setStep(SCREENS.FinancialError))
      props.goToStep(SCREENS.FinancialError)
    }
  }
  const validateTE = async () => {
    if (checkTe) {
      try {
        const response = await checkIdentityTE(
          form?.identity,
          base64,
          origin,
          onboardingId,
          `${form?.firstName}` + ' ' + `${form.lastName}`,
        )
        const {IdentityVerification} = JSON.parse(response.data.jsonAnswer)
        if (IdentityVerification) {
          if (origin === ORIGIN.CREDITO || origin === ORIGIN.PRECALIFICADO) {
            props.goToStep(SCREENS.ApprovedRequest)
          } else if (
            origin === ORIGIN.PREAPROBADO ||
            origin === ORIGIN.PREPAGO
          ) {
            props.goToStep(SCREENS.ActividadLaboral);
          }
          setLoading(false)
        } else {
          dispatch(getUpdateTE(form?.identity, onSuccess.bind(this)))
          logsOnboarding(
            onboardingId,
            origin,
            'VALIDATE_IDENTITY_TE',
            form.identity,
            false,
          )
        }
      } catch (error) {
        setLoading(false)
        await logsOnboarding(
          onboardingId,
          origin,
          'RESPONSE_WS_TE',
          error.response,
          'CARD',
          false,
        )
      }
    } else {
      if (origin === ORIGIN.CREDITO || origin === ORIGIN.PRECALIFICADO) {
        props.goToStep(SCREENS.ApprovedRequest)
      } else if (origin === ORIGIN.PREAPROBADO || origin === ORIGIN.PREPAGO) {
        props.goToStep(SCREENS.ActividadLaboral);
      }
    }
  }
  const onFinish = async success => {
    if (origin === ORIGIN.CREDITO || origin === ORIGIN.PRECALIFICADO) {
      if (success === 3) {
        validateTE()
      } else if (success === 2) {
        props.goToStep(SCREENS.FinancialError)
      } else if (success === 1) {
        setLoading(false)
      }
    } else if (origin === ORIGIN.PREAPROBADO || origin === ORIGIN.PREPAGO) {
      if (success === true) {
        validateTE()
      } else if (success === 2) {
        props.goToStep(SCREENS.FinancialError)
      } else {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    dispatch(getActivityTypes())
  }, [])
  const {occupations} = useSelector(state => state.RequestCardReducer)
  const selectedApc = () => {
    if (selectedOption === 'JU') {
      return (
        checked &&
        activitySelected &&
        pronvinceSelected &&
        civilStatusSelected &&
        monthlyIncomeSelected
      )
    } else {
      return (
        checked &&
        activitySelected &&
        pronvinceSelected &&
        civilStatusSelected &&
        occupationSelected &&
        monthlyIncomeSelected
      )
    }
  }
  if (showTermsAndConditions) {
    return (
      <TermsAndConditionsAPC
        handleShowTermsAndConditions={handleShowTermsAndConditions}
      />
    )
  }
  const renderDropdownOccupation = () => {
    return (
      <MyBoxAPC flexDirection={'column'}>
        <FormLabel
          sx={{
            font: 'normal normal normal 16px/16px Red Hat Text!important',
            color: '#000000',
          }}>
          Selecciona tu ocupación
        </FormLabel>
        <FormControl
          fullWidth
          error={showError('occupation')}
          disabled={formData.occupation.disabled}>
          <MyInputLabel>{formData.occupation.label}</MyInputLabel>
          <SelectComponent
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
            IconComponent={ArrowCommon}
            id="occupation"
            label={formData.occupation.label}
            value={getValue('occupation')}
            onChange={e => {
              handleChange('occupation', e.target.value)
              setOccupationForm(true)
            }}
            onBlur={() => handleBlur('occupation')}>
            {occupations?.map((option, index) => (
              <MyMenuItem
                key={index}
                value={option.value}
                data-testid={option.value}>
                {option.label}
              </MyMenuItem>
            ))}
          </SelectComponent>
          {showError('occupation') && (
            <FormHelperText>{getError('occupation')}</FormHelperText>
          )}
        </FormControl>
      </MyBoxAPC>
    )
  }
  const renderInputMonthlyIncome = () => {
    return (
      <MyBoxAPC flexDirection={'column'}>
        <FormLabel
          sx={{
            font: 'normal normal normal 16px/16px Red Hat Text!important',
            color: '#000000',
          }}>
          Indica tu ingreso mensual
        </FormLabel>
        <FormControlData fullWidth error={showError('monthlyIncome')}>
          <TextFieldComponent
            placeholder={formData.monthlyIncome.label}
            InputProps={{
              inputComponent: CurrencyInput,
              inputProps: {
                'data-testid': 'monthlyIncome',
              },
            }}
            error={showError('monthlyIncome')}
            value={getValue('monthlyIncome')}
            onChange={e => {
              handleChange('monthlyIncome', e.target.value)
              SetMonthlyIncomeSelected(true)
            }}
            onBlur={() => handleBlur('monthlyIncome')}
          />
          {showError('monthlyIncome') && (
            <FormHelperText>{getError('monthlyIncome')}</FormHelperText>
          )}
        </FormControlData>
      </MyBoxAPC>
    )
  }
  return (
    <>
      <Container>
        <WrapperSide background="linear-gradient(#003a11, #016116, #4fa70a)" />
        {loading && (
          <ModalProcessing
            isOpen={loading}
            headingText="Estamos procesando tu solicitud."
            bodyText={'Aguarda un momento.'}
          />
        )}
        <Wrapper style={{flex: 1, height: '100%'}}>
          <ContainerInfo>
            <DivTitle>
              <TitleResponsiveOnboarding
                minSize={35}
                maxSize={65}
                color="#011E41"
                text="Completa los siguientes datos y aprueba la revisión de tus datos en la APC"
                fontFamily="Red Hat Text"
                textAlign="center"
              />
            </DivTitle>
            <ContainerForm>
              <LayoutForm
                hasNextButton={true}
                handleSubmit={onSubmit}
                disabled={!selectedApc()}
                loading={loading}
                props={props}>
                <MyBox
                  sx={{
                    justifyContent: 'start',
                    flexDirection: 'column',
                    minWidth: '100%',
                  }}>
                  <MyBox>
                    <MyBoxAPC flexDirection={'column'}>
                      <FormLabel
                        sx={{
                          font:
                            'normal normal normal 16px/16px Red Hat Text!important',
                          color: '#000000',
                        }}>
                        Selecciona tu actividad laboral
                      </FormLabel>
                      <FormControl
                        fullWidth
                        error={showError('activityType')}
                        disabled={formData.activityType.disabled}>
                        <MyInputLabel>
                          {formData.activityType.label}
                        </MyInputLabel>
                        <SelectComponent
                          MenuProps={{
                            style: {
                              maxHeight: 400,
                            },
                          }}
                          IconComponent={ArrowCommon}
                          id="activityType"
                          label={formData.activityType.label}
                          value={getValue('activityType')}
                          onChange={e =>
                            handleChange('activityType', e.target.value)
                          }
                          onBlur={() => handleBlur('activityType')}>
                          {activityTypes?.map((option, index) => (
                            <MyMenuItem
                              key={index}
                              value={option.value}
                              data-testid={option.value}>
                              {option.label}
                            </MyMenuItem>
                          ))}
                        </SelectComponent>
                        {showError('activityType') && (
                          <FormHelperText>
                            {getError('activityType')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </MyBoxAPC>
                    <MyBoxAPC flexDirection={'column'}>
                      <FormLabel
                        sx={{
                          font:
                            'normal normal normal 16px/16px Red Hat Text!important',
                          color: '#000000',
                        }}>
                        Selecciona tu provincia de residencia
                      </FormLabel>

                      <FormControl fullWidth error={showError('province')}>
                        <MyInputLabel>{formData.province.label}</MyInputLabel>
                        <SelectComponent
                          MenuProps={{
                            style: {
                              maxHeight: 400,
                            },
                          }}
                          id="select-province"
                          IconComponent={ArrowCommon}
                          label={formData.province.label}
                          value={getValue('province')}
                          onChange={e =>
                            handleChange('province', e.target.value)
                          }
                          onBlur={() => handleBlur('province')}
                          className="">
                          {provinces?.map((option, index) => (
                            <MyMenuItem
                              key={index}
                              value={option.value}
                              data-testid={option.value}>
                              {option.label}
                            </MyMenuItem>
                          ))}
                        </SelectComponent>
                        {showError('province') && (
                          <FormHelperText>
                            {getError('province')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </MyBoxAPC>
                  </MyBox>
                  <MyBox>
                    <MyBoxAPC flexDirection={'column'}>
                      <FormLabel
                        sx={{
                          font:
                            'normal normal normal 16px/16px Red Hat Text!important',
                          color: '#000000',
                        }}>
                        Selecciona cuál estado civil
                      </FormLabel>
                      <FormControl error={showError('civilStatus')}>
                        <MyInputLabel>
                          {formData.civilStatus.label}
                        </MyInputLabel>
                        <SelectComponent
                          MenuProps={{
                            style: {
                              maxHeight: 400,
                            },
                          }}
                          id="civilStatus"
                          IconComponent={ArrowCommon}
                          label={formData.civilStatus.label}
                          value={getValue('civilStatus')}
                          onChange={e => {
                            handleChange('civilStatus', e.target.value)
                            setCivilStatusForm(true)
                          }}
                          onBlur={() => handleBlur('civilStatus')}>
                          {maritalStatus?.map((option, index) => (
                            <MyMenuItem
                              key={index}
                              value={option.value}
                              data-testid={option.value}>
                              {option.label}
                            </MyMenuItem>
                          ))}
                        </SelectComponent>
                        {showError('civilStatus') && (
                          <FormHelperText>
                            {getError('civilStatus')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </MyBoxAPC>
                    <MyBoxAPC>
                      <MyBox>
                        {selectedOption === 'JU'
                          ? renderInputMonthlyIncome()
                          : renderDropdownOccupation()}
                      </MyBox>
                    </MyBoxAPC>
                  </MyBox>
                  <MyBox
                    sx={{
                      width: '49%',
                      '@media (max-width: 1024px)': {
                        width: '100%',
                      },
                    }}>
                    {selectedOption !== 'JU' && renderInputMonthlyIncome()}
                  </MyBox>
                </MyBox>
                <MyBox>
                  <CheckWrapper>
                    <SubtitleResponsiveOnboarding
                      minSize={20}
                      maxSize={35}
                      color="#000000"
                      text="Aprueba la revisión de tus datos en la APC y otros"
                      fontFamily="Red Hat Text"
                      style={{margin: '0px !important', fontWeight: '600'}}
                    />
                    <SubtitleResponsiveOnboarding
                      minSize={18}
                      maxSize={25}
                      color="#000000"
                      text="Es necesario aceptar los términos y condiciones para continuar"
                      fontFamily="Red Hat Text"
                      style={{margin: '0px !important'}}
                    />
                    <CheckContainer>
                      <Checkbox
                        onClick={e => handleCheck(e)}
                        value={checked}
                        checked={checked}
                        name="checked"
                        sx={{
                          borderRadius: '4px',
                          padding: '9px 0px',
                          color: '#026E18',
                          '&.Mui-checked': {
                            color: '#026E18',
                          },
                        }}
                      />
                      <div>
                        <label
                          style={{
                            color: '#000000',
                            fontFamily: 'Red Hat Text',
                            fontWeight: 'normal',
                            lineHeight: '15px',
                            fontSize: 'clamp(14px, 1.5vw, 15px)',
                          }}>
                          He leído y acepto{' '}
                          <span
                            className="text-[#015DC7] underline mx-1"
                            onClick={() => {
                              return handleShowTermsAndConditions(true)
                            }}>
                            Términos y Condiciones
                          </span>
                          de la evaluación crediticia
                        </label>
                      </div>
                    </CheckContainer>
                  </CheckWrapper>
                </MyBox>
              </LayoutForm>
              {/* <FooterOnboar
                back={props.canReturn ? canReturn(props, 5) : null}
                next={
                  <ButtonNextOnboarding
                    variant="onboarding"
                    data-testid="next-apc"
                    loading={loading}
                    disabled={!selectedApc()}
                    onClick={onSubmit.bind(this)}>
                    Continuar
                  </ButtonNextOnboarding>
                }
                screen={props.canReturn ? 'endline' : 'rightEnd'}
              /> */}
            </ContainerForm>
          </ContainerInfo>
        </Wrapper>
      </Container>
    </>
  )
}

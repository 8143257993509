import produce from 'immer'

const initState = {
  Headers: {
    'Api-Key': '',
  },
  Authenticated: false,
  CurrentUser: {},
  SelectedBranchOffice: {},
  LoadingAuth: false,

  ResetPassword: {},
  ResetPasswords: [],
  ResetLoading: false,
  ResetError: false,

  ReenvioCredenciales: [],
  ReenvioLoading: false,
  ReenvioError: false,

  LoadFactoringLogin: false,
  loadDataFactoringLoging: [],
  emailUser: null,

  rechargeMessage: null,
}

const AuthenticationReducer = (state = initState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'CLEAN_LOGIN':
        draft.CurrentUser = {}
        draft.Authenticated = false
        draft.Headers = {}
        break
      case 'PROCCESS_LOGIN':
        draft.Authenticated = action.payload
        break
      case 'LOGIN_AUTHENTICATION_SUCCESS':
        draft.CurrentUser = action.user
        // draft.Authenticated = true
        draft.Headers = {
          ...draft.Headers,
          'Api-Key': action.ApiKey,
        }
        break
      case 'CUSTOMER_ID_SET':
        draft.CurrentUser = {
          ...state.CurrentUser,
          customerId: action.customerId,
        }
        break
      case 'LOGIN_AUTHENTICATION_ERROR':
        draft.Authenticated = false
        draft.CurrentUser = {}
        break
      case 'START_LOADING':
        draft.LoadingAuth = true
        break
      case 'STOP_LOADING':
        draft.LoadingAuth = false
        break
      case 'LOGOUT_SUCCESS':
        draft.Authenticated = false
        draft.SelectedBranchOffice = {}
        if (draft.Headers['Api-Key']) {
          delete draft.Headers['Api-Key']
        }
        break

      case 'RESETPASSWORD_STATUS_SUCCESS':
        draft.ResetPasswords = action.payload
        break
      case 'RESETPASSWORD_STATUS_ERROR':
        draft.ResetError = action.payload
        break
      case 'RESETPASSWORD_STATUS_SHOW':
        draft.ResetLoading = true
        break
      case 'RESETPASSWORD_STATUS_HIDE':
        draft.ResetLoading = false
        break

      case 'REENVIO_STATUS_SUCCESS':
        draft.ReenvioCredenciales = action.payload
        break
      case 'REENVIO_STATUS_ERROR':
        draft.ReenvioError = action.payload
        break
      case 'REENVIO_STATUS_SHOW':
        draft.ReenvioLoading = true
        break
      case 'REENVIO_STATUS_HIDE':
        draft.ReenvioLoading = false
        break

      case 'LOAD_FACTORING_LOGIN':
        draft.LoadFactoringLogin = action.payload
        break
      case 'EMAIL':
        draft.emailUser = action.payload
        break
      case 'LOAD_DATA_LOGIN_FACTORING':
        draft.loadDataFactoringLoging = action.payload
        draft.Authenticated = true
        break
      case 'RECHARGE_MESSAGE':
          draft.rechargeMessage = action.payload;
          break;
      case 'RESET_LOGIN':
        return initState // Este caso restablece el estado a su valor inicial
      default:
        return state
    }
  })

export default AuthenticationReducer

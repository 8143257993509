import React, {useMemo, useCallback} from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import {Box, Button, Modal, Typography} from '@mui/material';
import {ContainerData, ContainerModal, DataWebView} from './Welcome.style';

const ModalWebView = React.memo(function ModalWebView({
  visibleWebViewIOS,
  openModalWebView,
  copyLinkURL,
}) {
  // Memoize the message content based on the visibility of WebView on iOS
  const webViewMessage = useMemo(() => {
    return visibleWebViewIOS
      ? 'Identificamos que estás usando un navegador no compatible. Te invitamos a copiar el enlace y usarlo desde tu navegador Safari o Chrome para solicitar tu tarjeta digital CMF'
      : 'Identificamos que estás usando un navegador no compatible. Te invitamos a copiar el enlace y usarlo desde tu navegador Chrome o Firefox para solicitar tu tarjeta digital CMF';
  }, [visibleWebViewIOS]);

  // Use callback for the copy link functionality
  const handleCopyLink = useCallback(() => {
    copyLinkURL();
  }, [copyLinkURL]);

  return (
    <Modal
      open={openModalWebView}
      onClose={false}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ContainerModal>
        <WarningIcon sx={{color: '#ffe600', fontSize: '5rem'}} />
        <ContainerData>
          <DataWebView>{webViewMessage}</DataWebView>
        </ContainerData>
        <Box
          sx={{
            display: 'flex',
            alignSelf: 'stretch',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '200px',
            backgroundColor: 'var(--specific-light-background-20)',
            flexDirection: 'row',
          }}>
          <Button
            sx={{
              borderRadius: '100px',
              width: '10rem !important',
              height: '2.25rem',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0px 2px',
              boxSizing: 'border-box',
              gap: '8px',
              textAlign: 'left',
              fontSize: 'var(--detail-m-size)',
            }}
            variant="contained"
            onClick={handleCopyLink}>
            <Typography
              sx={{
                color: '#FFF',
                fontFamily: 'Red Hat Text',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '22px /* 157.143% */',
                letterSpacing: '-0.14px',
              }}>
              Copiar Enlace
            </Typography>
          </Button>
        </Box>
      </ContainerModal>
    </Modal>
  );
});

export default ModalWebView;

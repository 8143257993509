import React, {useState, useEffect} from 'react';
import './App.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {useSelector} from 'react-redux';

function ButtonFloat() {
  const {btnWts} = useSelector(state => state.RequestCardReducer);
  const [position, setPosition] = useState({x: 15, y: 15});
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({x: 0, y: 0});
  const [isMobile, setIsMobile] = useState(false);
  
  const startDrag = (x, y) => {
    setIsDragging(true);
    setOffset({
      x: x - position.x,
      y: y - position.y,
    });
  };

  const onDrag = (x, y) => {
    if (isDragging) {
      const newX = x - offset.x;
      const newY = y - offset.y;

      const maxX = window.innerWidth - 60;
      const maxY = window.innerHeight - 100;

      const constrainedX = Math.max(0, Math.min(newX, maxX));
      const constrainedY = Math.max(0, Math.min(newY, maxY));

      setPosition({x: constrainedX, y: constrainedY});
    }
  };

  const stopDrag = () => {
    setIsDragging(false);
  };

  const handleMouseDown = e => {
    e.preventDefault(); // Evita cualquier acción predeterminada iniciada por el navegador
    startDrag(e.clientX, e.clientY);
  };

  const handleMouseMove = e => {
    e.preventDefault(); // Detiene al navegador de interpretar esto como un evento de desplazamiento
    onDrag(e.clientX, e.clientY);
  };

  const handleMouseUp = e => {
    e.preventDefault();
    stopDrag();
  };

  const handleTouchStart = e => {
    e.preventDefault(); // Evita que el evento táctil se propague
    const touch = e.touches[0];
    startDrag(touch.clientX, touch.clientY);
  };

  const handleTouchMove = e => {
    e.preventDefault(); // Esto es crucial para prevenir el desplazamiento mientras se arrastra
    const touch = e.touches[0];
    onDrag(touch.clientX, touch.clientY);
  };

  const handleTouchEnd = e => {
    e.preventDefault();
    stopDrag();
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const handleClick = () => {
    window.open(
      'https://api.whatsapp.com/send/?phone=50765978134&text=Hola.+Necesito+asistencia&type=phone_number&app_absent=0',
      '_blank',
    );
  };

  useEffect(() => {
    // Detecta si el dispositivo es móvil
    const userAgent = navigator.userAgent.toLowerCase();
    const mobile = /iphone|ipad|ipod|android/.test(userAgent);
    setIsMobile(mobile);
  }, []);

  return (
    <React.Fragment>
      {btnWts && (
        <div
          className="whatsapp"
          style={{left: `${position.x}px`, top: `${position.y}px`}}
          onMouseDown={isMobile ? null : handleMouseDown}
          onTouchStart={isMobile ? handleTouchStart : null}>
          <WhatsAppIcon
            onClick={isMobile ? null : handleClick} // Asigna handleClick solo si no es móvil
            onTouchEnd={isMobile ? handleClick : null} // Asigna handleClick solo si es móvil
          />
        </div>
      )}
    </React.Fragment>
  );
}

export default ButtonFloat;

import produce from 'immer';
import { CHANGE_ENVIRONMENT} from '../../constrants/reducers';
const initState = {
  //env: 'dev',
  env: process.env.REACT_APP_ENVIRONMENT,
};
const environment = (state = initState, action) =>
  produce(state, draft => {
    if (action.type === CHANGE_ENVIRONMENT) {
      draft.env = action.payload;
    } else {
      return state;
    }
  });
export default environment;

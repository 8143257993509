import { AlarmOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetMovementReduce } from 'src/store/actions/MovimientoAction';

function ModalTimeOut({isNotified, remaining, handleStillHere}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formatTime = seconds => {
    const secs = seconds % 60;
    return secs < 10 ? `0${secs}` : `${secs}`;
  };

  const closeSession = () => {
    navigate('/');
    localStorage.removeItem('selectedIndex');
    dispatch({type: 'RESET_ACTIVE_PRODUCT'});
    dispatch(resetMovementReduce());
    dispatch({
      type: 'CLEAN_LOGIN',
    });
  };

  return (
    <Dialog
      open={isNotified}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 2,
        }}>
        <AlarmOutlined
          sx={{
            fontSize: '40px',
            color: '#026e18',
          }}
        />
      </Box>

      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
        id="responsive-dialog-title">
        {'Aviso de cierre de sesión'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tu sesión en CMF finalizará en {formatTime(remaining)} segundos por
          razones de seguridad. Si deseas continuar, haz clic en "Continuar
          sesión" para permanecer conectado.
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}>
        <LoadingButton
          variant="outlined"
          autoFocus
          style={{textTransform: 'none'}}
          onClick={() => closeSession()}>
          Finalizar sesión
        </LoadingButton>
        <LoadingButton
          variant="contained"
          autoFocus
          style={{textTransform: 'none'}}
          onClick={() => handleStillHere()}>
          Continuar sesión
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

ModalTimeOut.propTypes = {
  isNotified: PropTypes.bool.isRequired,
  remaining: PropTypes.string,
  handleStillHere: PropTypes.func,
};

export default ModalTimeOut;

import moment from 'moment';
import { logsOnboarding, setBase64 } from '../store/actions/requestCard.actions';
import { COUNTRIES_TYPES, PAN_TYPES } from './Constants';
import {
  Coincidencia,
  CoincidenciaMap,
  alphaCode,
  getGender,
  getNationality,
  removeAccents,
  uploadFileTest,
} from './Functions';
/**
 * Funcion que toma los datos Fake(VideoComplete.js) para que se pueda hacer todo el proceso sin necesidad de hacer scanner biometrico
 * @returns {[any]}
 */

export const OnCompleteVideo = async (
  video,
  nationalitiesCatalog,
  countriesData,
  initIdentity,
  mappingData,
) => {
  return new Promise(async resolve => {
    const { document = {}, biometrics = {} } = video;
    const { subject = {}, front, back, expiryDateTS } = document;
    const expiryDateDocument = new Date(expiryDateTS);
    let IDExpirationDate = moment(expiryDateDocument).format('YYYY-MM-DD');
    const isDocumentNotExpired = expiryDateTS
      ? new Date().getTime() < expiryDateTS
      : true;
    const { face = {} } = biometrics;
    const typeIdentity = document?.classification;

    const nationalityEid =
      typeIdentity === PAN_TYPES.PAN2023 || typeIdentity === PAN_TYPES.PANE2023
        ? alphaCode(subject.nationality)
        : typeIdentity === PAN_TYPES.PANE2010
          ? document.issuer
          : subject.nationality;

    const birthPlaceEid =
    (typeIdentity === PAN_TYPES.PAN2023 || typeIdentity === PAN_TYPES.PANE2023)
    ? (alphaCode(subject.birthPlace) || subject.birthPlace)
    : subject.birthPlace;

    // ADVERTENCIA
    // La función isNaN() determina si el valor dado es un número o un número ilegal (Not-a-Number).
    // La función genera como Verdadero para un valor NaN y devuelve Falso para un valor numérico válido.
    let placeBirthInfo;
    if (birthPlaceEid && isNaN(initIdentity)) {
      var resultBirthPlace = Coincidencia(birthPlaceEid, countriesData, 0.9);
      if (resultBirthPlace.length > 0) {
        placeBirthInfo = resultBirthPlace[0].value;
      } else {
        placeBirthInfo = null;
      }
    } else if (!isNaN(initIdentity)) {
      placeBirthInfo = COUNTRIES_TYPES.PANAMA;
    } else {
      placeBirthInfo = null;
    }

    let nacionalityInfo;
    if (nationalityEid && isNaN(initIdentity)) {
      let resultNationality = CoincidenciaMap(nationalityEid, mappingData, 0.7);
      if (resultNationality.length > 0) {
        nacionalityInfo = resultNationality[0].value;
      } else {
        nacionalityInfo = null;
      }
    } else if (!isNaN(initIdentity)) {
      nacionalityInfo = COUNTRIES_TYPES.PANAMA;
    } else if (!nationalityEid) {
      nacionalityInfo = getNationality(
        subject?.nationality,
        nationalitiesCatalog,
        initIdentity,
      );
    } else {
      nacionalityInfo = null;
    }
    let personalNumber = subject?.personalNumber
      ? subject?.personalNumber
      : document?.documentNumber
        ? document?.documentNumber
        : '';
    const SpecialName = [
      'da',
      'de',
      'del',
      'la',
      'las',
      'los',
      'mac',
      'mc',
      'van',
      'von',
      'y',
      'san',
      'santa',
    ];

    let primaryNames =
      typeIdentity === PAN_TYPES.PAN2023 || typeIdentity === PAN_TYPES.PANE2023
        ? subject?.alternativePrimaryName
          ? subject?.alternativePrimaryName
          : subject?.primaryName
        : subject?.primaryName;
    const Pnames = [];
    const tok = (primaryNames ?? '')?.split(' ');
    let prevN = '';
    tok.forEach(function (name) {
      const nameAux = name.toLowerCase();
      if (SpecialName.indexOf(nameAux) !== -1) {
        prevN += name + ' ';
      } else {
        Pnames.push(prevN + name);
        prevN = '';
      }
    });
    let secondaryNames =
      typeIdentity === PAN_TYPES.PAN2023 || typeIdentity === PAN_TYPES.PANE2023
        ? subject?.alternativeSecondaryName
          ? subject?.alternativeSecondaryName
          : subject?.secondaryName
        : subject?.secondaryName;
    const names = [];
    const tokens = (secondaryNames ?? '').split(' ');
    let prev = '';
    tokens.forEach(function (name) {
      const nameAux = name.toLowerCase();
      if (SpecialName.indexOf(nameAux) !== -1) {
        prev += name + ' ';
      } else {
        names.push(prev + name);
        prev = '';
      }
    });
    const frontFile = await uploadFileTest(
      `${personalNumber}.front.jpeg`,
      front,
    );
    const backFile = await uploadFileTest(`${personalNumber}.back.jpeg`, back);
    const faceFile = await uploadFileTest(
      `${personalNumber}.face.jpeg`,
      face.image,
    );
    // dispacth.setBase64(face?.image)
    setBase64(face?.image ?? '');
    var newdate = new Date();
    const year = newdate
      .getFullYear()
      .toString()
      .substr(2, 2);
    moment.parseTwoDigitYear = function (yearString) {
      return (
        parseInt(yearString) +
        (parseInt(yearString) > parseInt(year) ? 1900 : 2000)
      );
    };
    let formDataRequest = {
      birthdate: subject?.birthDate
        ? moment(subject?.birthDate, 'YYMMDD').format('YYYY-MM-DD')
        : null,
      firstName: removeAccents(Pnames[0]) || '',
      secondName: removeAccents(Pnames[1]) || '',
      lastName: removeAccents(names[0]) || '',
      secondLastName: removeAccents(names[1]) || '',
      identity: personalNumber || '',
      countryOfBirth: placeBirthInfo ? placeBirthInfo : null,
      gender: getGender(subject?.sex) || '',
      nationality: nacionalityInfo ? nacionalityInfo : null,
      /* Face Image Data */
      frontIdentityImage: frontFile,
      backIdentityImage: backFile,
      faceImage: faceFile,
      isDocumentNotExpired,
      IDExpirationDate,
    };

    delete video?.biometrics?.face;
    delete video?.document?.back;
    delete video?.document?.front;
    delete video?.document?.subject?.photo;
    delete video?.document?.subject?.signature;

    logsOnboarding(
      personalNumber,
      'Not Defined',
      'EID_VIDEO_RECORDED',
      video,
      video.id,
      true,
    );
    let datos = {
      video: video,
      personalNumber: personalNumber,
      typeIdentity: typeIdentity,
      formDataRequest: formDataRequest,
      base64: face?.image ?? '',
    };
    resolve(datos);
    if (nacionalityInfo === null) {
      return nacionalityInfo;
    } else {
      return datos;
    }
  });
};

import React, {useEffect, useState} from 'react';
// @mui
import {
  Avatar,
  Box,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
// mocks_
import {MonitorHeartOutlined, NewReleases} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import ShieldIcon from '@mui/icons-material/Shield';
import axios from 'axios';
import {NotificationManager} from 'react-notifications';
import {useDispatch, useSelector} from 'react-redux';
import profile from 'src/assets/images/cmf_linea/logo/abrazoLight.png';
import CardsTypesLateralBar from 'src/components/CreditCard/CardsTypesLateralBar';
import {Cmf_Base, PointsApi} from 'src/Data/Api';
import useResponsive from 'src/hooks/useResponsive';
import {resetState} from 'src/store/actions/actions';
import {BreakPointTheme} from 'src/theme/breakpoints';
import {Name, convertirFecha, getName} from 'src/utils/Functions';
import arrowDown from './../../../../assets/icons/cmf_linea/arrow-down.png';
import LogoCMF from './../../../../assets/icons/cmf_linea/cmf.png';
import {PRODUCTS_TYPES} from 'src/utils/Constants';
// ----------------------------------------------------------------------
export default function AccountPopover() {
  return <AccountPopoverNav />;
}

const MENU_OPTIONS = [
  {
    label: 'Datos personales',
    icon: <PersonIcon />,
    LinkTo: '/home/Personal',
  },
  {
    label: 'Seguridad',
    LinkTo: '/home/Security',
    icon: <ShieldIcon />,
  },
];

const PointFrame = styled('div')(({theme}) => ({
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 5),
  zIndex: 1,
}));

const KeyboardArrowDownWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1), // Cambia 'var(--padding-xs)' a 'theme.spacing(0, 1)'
  zIndex: 0,
}));

const ArrowBackIcon = styled('img')(({theme}) => ({
  position: 'relative',
  width: '1.25rem',
  height: '1.25rem',
  overflow: 'hidden',
  flexShrink: 0, // Cambia 'flex-shrink' a 'flexShrink'
}));
const PopoverProfile = styled(Popover)(({theme}) => ({
  // p: 0,
  // mt: 1.5,
  // ml: 0.75,
  // width: 336,
  // borderRadius: '20px',
  // background: '#fff',
  // boxShadow:
  //   '0px 0px 0px 0px rgba(16, 24, 40, 0.08), 0px 4px 9px 0px rgba(16, 24, 40, 0.07), 0px 16px 16px 0px rgba(16, 24, 40, 0.06), 0px 36px 21px 0px rgba(16, 24, 40, 0.04), 0px 64px 25px 0px rgba(16, 24, 40, 0.01), 0px 99px 28px 0px rgba(16, 24, 40, 0.00)',
  // '& .MuiMenuItem-root': {
  //   typography: 'body2',
  //   borderRadius: 0.75,
  // },
  [BreakPointTheme.breakpoints.down('sm')]: {
    '& .MuiPopover-paper': {
      left: '0 !important',
      top: '0 !important',
      width: '100%',
      maxWidth: '100vw',
      height: '-webkit-fill-available',
      margin: 0,
    },
  },
}));

function AccountPopoverNav() {
  const {dataInsuranse} = useSelector(state => state.ActiveProductReducer);
  const {errors} = dataInsuranse;
  const location = useLocation();
  const isValidRoute = location.pathname !== '/home/movements';
  const {CurrentUser} = useSelector(state => state.AuthenticationReducer);

  const [insuranceActive, setInsuranceActive] = React.useState(false);
  useEffect(() => {
    if (errors?.Error === 'false') {
      setInsuranceActive(true);
    }
  }, [errors?.Error]);
  const urlActive = window.location.pathname;
  const widthMobile = window.innerWidth;
  const navigate = useNavigate();
  useEffect(() => {
    if (insuranceActive && location.pathname === '/home/insurance') {
      navigate('/home', {replace: true});
    }
  }, [location.pathname, insuranceActive, navigate]);

  const handleCloseInsuranse = insuranceActive => {
    if (insuranceActive) {
      NotificationManager.warning(
        'Tu seguro de Accidentes Personales ya se encuentra activo.',
      );
    } else {
      setOpen(null);
    }
  };
  const {userSF, accountSF} = useSelector(
    state => state.AuthenticationReducer?.CurrentUser,
  );
  const {listCustomerProducts} = useSelector(
    state => state.ActiveProductReducer,
  );
  const {selectedProduct} = useSelector(state => state.ActiveProductReducer);
  const isDesktop = useResponsive('up', 'md');
  const [open, setOpen] = useState(null);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(null);
  };

  //Verifica si existe una cuenta corporativa
  const existsCorporateTrue = listCustomerProducts?.some(
    obj => obj.isCorporate === true,
  );

  const handleClick = async e => {
    localStorage.removeItem('movements');
    dispatch({
      type: 'SELECTED_PRODUCT',
      payload: e,
    });
    if (isValidRoute) {
      try {
        const response = await axios.get(
          `${Cmf_Base}${PointsApi}?anyo=0&Mes=0&Cuenta=${encodeURIComponent(
            e?.productAccount,
          )}&CreatedBy=${e?.CustomerId}`,
          {
            headers: {
              Authorization: `Bearer ${CurrentUser?.tokenCMF}`,
            },
          },
        );
        dispatch({
          type: 'GENERALVTC_ACCOUNT_STATEMENT_SUCCESS',
          payload: JSON.parse(response?.data.jsonAnswer),
        });
      } catch (error) {
        NotificationManager.error(
          'Ha ocurrido un error obteniendo información',
        );
      }
    }
  };

  const MENU_OPTIONS_MOBILE =
    selectedProduct?.isCorporate ||
    selectedProduct?.productTypeId?.toUpperCase() === PRODUCTS_TYPES.PREPP
      ? [
          {
            label: 'Inicio',
            icon: <HomeIcon />,
            LinkTo: '/home',
          },
          {
            label: 'Configuracion de Perfil',
            icon: <ShieldIcon />,
            LinkTo: '/home/Security',
          },
          {
            label: 'Datos personales',
            icon: <PersonIcon />,
            LinkTo: '/home/Personal',
          },
          {
            label: 'Seguridad',
            icon: <ShieldIcon />,
            LinkTo: '/home/Security',
          },
        ]
      : [
          {
            label: 'Inicio',
            icon: <HomeIcon />,
            LinkTo: '/home',
            onClick: handleClose,
          },
          {
            label: 'Pagar',
            icon: <CreditCardIcon />,
            LinkTo: '/home/products',
            onClick: handleClose,
          },
          {
            label: 'Promociones',
            icon: <NewReleases />,
            LinkTo: '/home/promotions',
            onClick: handleClose,
          },
          {
            label: 'Configuracion de Perfil',
            icon: <ShieldIcon />,
            LinkTo: '/home/Security',
            onClick: handleClose,
          },
          {
            label: 'Datos personales',
            icon: <PersonIcon />,
            LinkTo: '/home/Personal',
            onClick: handleClose,
          },
          {
            label: 'Seguridad',
            icon: <ShieldIcon />,
            LinkTo: '/home/Security',
            onClick: handleClose,
          },
          {
            label: 'Seguros',
            icon: <MonitorHeartOutlined />,
            LinkTo: insuranceActive ? urlActive : '/home/insurance',
            onClick: () => handleCloseInsuranse(insuranceActive),
          },
        ];
  const handleLogout = async () => {
    try {
      navigate('/');
      dispatch({type: 'RESET_ACTIVE_PRODUCT'});
      localStorage.removeItem('selectedIndex');
      dispatch(resetState());
      dispatch({
        type: 'CLEAN_LOGIN',
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleOpen = event => {
    setOpen(event.currentTarget);
  };
  return (
    <>
      <IconButton
        id="id-button-config-user"
        onClick={handleOpen}
        sx={{
          p: 0,
          display: 'flex',
          padding: '4px',
          alignItems: 'center',
          gap: '8px',
          borderRadius: '40px',
          height: '56px',
          border: '1px solid #EAECF0, #EAECF0',
          background: '#FFFFFF',
          backgroundColor: '#FFFFFF !important',
          '&:hover': {
            background: '#FFFFFF',
          },
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 4px 4px 0px rgba(16, 24, 40, 0.04), 0px 6px 16px 2px rgba(16, 24, 40, 0.04), 0px 17px 7px 0px rgba(16, 24, 40, 0.01), 0px 27px 7px 0px rgba(16, 24, 40, 0.00)',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              // bgcolor: theme => alpha(theme.palette.grey[900], 0.8),
            },
            '&:hover': {
              background: '#FFFFFF',
            },
          }),
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            gap: '8px',
          }}>
          <Avatar
            sx={{
              width: '48px',
              height: '48px',
              borderRadius: '100px',
            }}
            alt="Cindy Baker"
            src={profile}
          />
          {isDesktop && (
            <>
              <PointFrame>
                {
                  <Typography variant="h6">
                    Hola, {Name(accountSF[0]?.Name)}
                  </Typography>
                }
              </PointFrame>

              <KeyboardArrowDownWrapper>
                <ArrowBackIcon alt="" src={arrowDown} />
              </KeyboardArrowDownWrapper>
            </>
          )}
        </Box>
      </IconButton>

      <PopoverProfile
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 336,
            borderRadius: '20px',
            background: '#fff',
            boxShadow:
              '0px 0px 0px 0px rgba(16, 24, 40, 0.08), 0px 4px 9px 0px rgba(16, 24, 40, 0.07), 0px 16px 16px 0px rgba(16, 24, 40, 0.06), 0px 36px 21px 0px rgba(16, 24, 40, 0.04), 0px 64px 25px 0px rgba(16, 24, 40, 0.01), 0px 99px 28px 0px rgba(16, 24, 40, 0.00)',
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}>
        {!isDesktop && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '90%',
              margin: 'auto',
            }}>
            <img src={LogoCMF} alt="Logo CMF" />
            <CloseIcon
              onClick={handleClose}
              sx={{
                display: 'flex',
                width: '28px',
                height: '28px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                borderRadius: '8px',
                border: '1.6px solid var(--specific-light-stroke-l, #D0D5DD)',
              }}
            />
          </Box>
        )}
        {isDesktop && (
          <Box
            sx={{
              my: 1.5,
              px: 2.5,
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Typography variant="h6" noWrap>
              Configuración de perfil
            </Typography>
            <Box
              style={{
                display: 'flex',
                width: '28px',
                height: '28px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                flexShrink: 0,
                borderRadius: '8px',
                border: '1.6px solid var(--specific-light-stroke-l, #D0D5DD)',
              }}>
              X
            </Box>
          </Box>
        )}
        <Box sx={{my: 1.5, px: 2.5}}>
          <Typography variant="h6" noWrap>
            {accountSF[0]?.Name && getName(accountSF[0]?.Name)}
          </Typography>
          <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
            Último ingreso: {userSF && convertirFecha(userSF[0]?.LastLoginDate)}
          </Typography>
        </Box>
        <Stack sx={{p: 1}}>
          {widthMobile > 500 ? (
            <>
              {MENU_OPTIONS.map(option => (
                <MenuItem
                  sx={{
                    display: 'flex',
                    padding: '16px 8px',
                    alignItems: 'center',
                    gap: '150px',
                    alignSelf: 'stretch',
                    borderRadius: '16px',
                    background: '#F9FAFB',
                  }}
                  key={option.label}
                  to={option.LinkTo}
                  component={RouterLink}
                  onClick={handleClose}>
                  <Box
                    sx={{display: 'flex', alignItems: 'center', mr: 2, gap: 2}}>
                    {option.icon}
                    <Typography variant="H4">{option.label}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </>
          ) : (
            <>
              {MENU_OPTIONS_MOBILE.map(option => (
                <MenuItem
                  sx={{
                    display: 'flex',
                    padding: '16px 8px',
                    alignItems: 'center',
                    gap: '150px',
                    alignSelf: 'stretch',
                    borderRadius: '16px',
                    background: '#F9FAFB',
                  }}
                  id={`id-${option.label}`}
                  key={option.label}
                  to={option.LinkTo}
                  component={RouterLink}
                  onClick={option.onClick}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mr: 2,
                      gap: 2,
                      '&:hover': {color: '#93D500!important'},
                    }}>
                    {option.icon}{' '}
                    <Typography variant="H4">{option.label}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </>
          )}
        </Stack>
        {widthMobile < 600 && existsCorporateTrue && (
          <React.Fragment>
            {listCustomerProducts &&
              listCustomerProducts.map((data, index) => (
                <CardsTypesLateralBar
                  key={data?.customerId}
                  onClick={() => handleClick(data)}
                  data={data}
                />
              ))}
          </React.Fragment>
        )}
        <MenuItem
          id="id-logout-desktop"
          onClick={handleLogout}
          sx={{m: 1, p: 1}}>
          <Box sx={{display: 'flex', alignItems: 'center', mr: 2, gap: 2}}>
            <LogoutIcon /> Cerrar sesion
          </Box>
        </MenuItem>
      </PopoverProfile>
    </>
  );
}

// IdleHandler.js
import { PropTypes } from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';

PropTypes.IdleHandler = {
  onIdle: PropTypes.func.isRequired,
};

const IdleHandler = ({onIdle}) => {
  const {reset, getLastActiveTime} = useIdleTimer({
    timeout: 420000, // 7 minutos en milisegundos
    onIdle: onIdle,
  });

  return null;
};

export default IdleHandler;

import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {setStep} from 'src/store/actions/Steps.Action'
import CommonPage from './Layout/LayoutPage'
import ModalVerifyInformation from './Modals/ModalVerifyInformation'
import {SCREENS} from './screens'

const ApprovedRequest = props => {
  const dispatch = useDispatch()
  const [screenPersonal, setScreenPersonal] = useState(false)
  const onClick = () => {
    setScreenPersonal(true)
  }
  const nextStep = () => {
    dispatch(setStep(SCREENS.ActividadLaboral))
    props.goToStep(SCREENS.ActividadLaboral)
  }
  const onHide = () => {
    setScreenPersonal(false)
  }

  return (
    <>
      <div className="min-h-[100vh]">
        <CommonPage
          buttonOnClick={onClick}
          buttonClasses="md:font-semibold font-normal"
          buttonText="Continuar"
          heading="¡Felicitaciones!"
          title="Tu tarjeta digital"
          subtitle1=" fue aprobada"
          subtitle2={
            <div className="lg:flex flex-col items-center justify-center">
              <span className="text-030303" style={{fontWeight: '400'}}>
                Podrás realizar compras regulares, en cuotas, hacer retiro de
                efectivo y
              </span>
              <span className="text-030303" style={{fontWeight: '400'}}>
                obtener doble puntaje en Puntos Gordos
              </span>
            </div>
          }
          styleSubtitle3={{fontWeight: '400'}}
          subtitle3="Estás a pocos minutos de finalizar el proceso"
        />
      </div>
      <ModalVerifyInformation
        open={screenPersonal}
        onHide={onHide}
        onConfirm={nextStep}
        stepPage={2}
      />
    </>
  )
}

export default ApprovedRequest

import React from 'react';
import WrapperSide from 'src/components/Aside/Wrapper';
import CMFLogo from 'src/components/Logos/CMF';
import SpaceBr from 'src/components/Space/space';
import SubtitleResponsiveOnboarding from 'src/components/TitleInput/SubtitleResponsiveOnboarding';
import TitleResponsiveOnboarding from 'src/components/TitleInput/TitleResponsiveOnboarding';
import {BreakPointTheme} from 'src/theme/breakpoints';
import styled from 'styled-components';
import styles from 'src/containers/OnboardingCredito/CongratsSign/style.module.css';

import appleStore from 'src/assets/images/onboardingCredito/appleStore.svg';
import playStore from 'src/assets/images/onboardingCredito/playStore.svg';
import appGallery from 'src/assets/images/onboardingCredito/appGallery.svg';
import IconStore from 'src/containers/OnboardingCredito/CongratsSign/IconStore';
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}));
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
const ContainerInfo = styled('div')(() => ({
  display: 'flex',
  height: '50%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  width: '70%',
  [BreakPointTheme.breakpoints.down('sm')]: {
    margin: '15vh auto auto auto',
    width: '90%',
    height: '40vh',
  },
}));
const ContainerInfoSub = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  width: '100%',
  [BreakPointTheme.breakpoints.down('sm')]: {
    margin: '15vh auto auto auto',
    width: '90%',
    height: '40vh',
  },
}));

const PrepagoRedirectApp = () => {
  return (
    <Container>
      <WrapperSide background="rgb(255, 255, 255)" />
      <Wrapper
        style={{
          flex: 1,
          height: '100%',
          justifyContent: 'center',
          background:
            'radial-gradient(circle at top left, #024114, transparent 30%),radial-gradient(circle at top right, #017617, transparent 30%),radial-gradient(circle at bottom left, #026D17, transparent 30%),radial-gradient(circle at bottom right, #026D17, transparent 30%),   linear-gradient(135deg, #026D17 0%, #0B7021 20%, #026C18 40%, #015517 60%, #024315 80%, #025918 100%)',
        }}>
        <center>
          <CMFLogo />
        </center>

        <ContainerInfo>
          <TitleResponsiveOnboarding
            minSize={45}
            maxSize={75}
            text="Vemos que ya tienes una tarjeta de crédito activa"
            color="#fff"
            fontFamily="Red Hat Text"
            style={{marginBottom: '40px'}}
          />
          <SpaceBr minSize={545} />
          <ContainerInfoSub>
            <SubtitleResponsiveOnboarding
              minSize={35}
              maxSize={55}
              text="Puedes solicitarla tu tarjeta prepago desde la App CMF"
              color="#fff"
              fontFamily="Red Hat Text"
              style={{marginBottom: '40px'}}
            />
          </ContainerInfoSub>
          <div className={styles.storeSection}>
            <p style={{color: '#fff'}}>Descarga nuestra aplicación ahora!</p>
            <p className={styles.disponible} style={{color: '#fff'}}>
              Disponible en:
            </p>
            <div className={styles.iconContainer}>
              <IconStore
                url="https://play.google.com/store/apps/details?id=com.cmfinanciera.wallet"
                icons={playStore}
                alt="Play Store"
              />
              <IconStore
                url="https://apps.apple.com/us/app/cmf-app/id6451213803"
                icons={appleStore}
                alt="Apple Store"
              />
              <IconStore
                url="https://appgallery.huawei.com/app/C110471835"
                icons={appGallery}
                alt="App Gallery"
              />
            </div>
          </div>
        </ContainerInfo>
      </Wrapper>
    </Container>
  );
};

export default PrepagoRedirectApp;

import {FormHelperText, FormLabel} from '@mui/material';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {useDispatch, useSelector} from 'react-redux';
import useForm from 'src/hooks/useForms';
import {UpdateOnboardingCredit} from 'src/store/actions/KO.Actions';
import {SELLER_ID_TYPE} from 'src/utils/Constants';
import * as Yup from 'yup';
import ArrowCommon from '../../components/ArrowCommon';
import {
  createOrUpdate,
  editFormRequest,
  UpdateOnboardingCrePPAGO,
} from 'src/store/actions/requestCard.actions';
import {
  CurrencyInput,
  FormControlData,
  MyBox,
  MyInputLabel,
  MyMenuItem,
  NumericResidencialInput,
  SelectComponent,
  TextFieldComponent,
} from './Component/FieldsForms';
import LayoutForm from './Layout/LayoutForm';
import {selectOptions8} from './utils/options';
import {ORIGIN} from 'src/utils/Constants';

const initialData = {
  profession: {
    name: 'profession',
    delateDoubleSpace: true,
    label: 'Profesión',
    errorMessage: 'Profesión es requerido',
  },
  occupation: {
    name: 'occupation',
    delateDoubleSpace: true,
    label: 'Ocupación',
    errorMessage: 'Ocupación es requerido',
  },
  company: {
    name: 'company',
    delateDoubleSpace: true,
    label: 'Empresa donde laboras',
    errorMessage: 'Empresa donde laboras es requerido',
  },
  companyPhone: {
    name: 'companyPhone',
    delateDoubleSpace: true,
    label: 'Teléfono de la empresa',
    errorMessage: 'Teléfono de la empresa es requerido',
  },
  addressCompany: {
    name: 'addressCompany',
    delateDoubleSpace: true,
    label: 'Dirección de la empresa',
    errorMessage: 'Dirección de la empresa es requerido',
  },
  month: {
    name: 'month',
    delateDoubleSpace: true,
    label: 'Mes',
    errorMessage: 'Mes es requerido',
  },
  year: {
    name: 'year',
    delateDoubleSpace: true,
    label: 'Año',
    errorMessage: 'Año es requerido',
  },
  monthlyIncome: {
    name: 'monthlyIncome',
    delateDoubleSpace: true,
    label: 'Ingreso mensual',
    errorMessage: 'Ingreso mensual es requerido',
  },
};
const regex = /^(?!.*\.\.)(?!^\s*$)[A-Za-z0-9\sñÑáéíóúÁÉÍÓÚ,.]+$/;
const validationSchema = Yup.object().shape({
  profession: Yup.string()
    .required(initialData.profession.errorMessage)
    .default(''),
  occupation: Yup.string()
    .required(initialData.occupation.errorMessage)
    .default(''),
  company: Yup.string()
    .required(initialData.company.errorMessage)
    .matches(regex, 'Campos inválidos')
    .min(1, 'No se permiten espacios en blanco')
    .max(50, 'No se permiten mas de 50 caracteres')
    .default(''),
  companyPhone: Yup.string()
    .matches(
     /^(?!.*(.)\1{6})(([0-57-9]\d{6})|\s*)$/,
      'Formato de teléfono incorrecto.',
    ),
  addressCompany: Yup.string()
    .required(initialData.addressCompany.errorMessage)
    .matches(regex, 'Campos inválidos')
    .min(1, 'No se permiten espacios en blanco')
    .max(50, 'No se permiten mas de 50 caracteres')
    .default(''),
  month: Yup.string()
    .required(initialData.month.errorMessage)
    .default(''),
  year: Yup.string()
    .required(initialData.year.errorMessage)
    .default(''),
  monthlyIncome: Yup.number()
    .required(initialData.monthlyIncome.errorMessage)
    .min(300, 'Salario invalido, debe ser igual o superior a $300.00')
    .max(20000, 'Salario invalido, debe ser igual o inferior a $20,000.00')
    .default(0),
});

const allYears = () => {
  const years = [];
  const dateStart = moment();
  const dateEnd = moment().subtract(40, 'y');
  while (dateEnd.diff(dateStart, 'years') <= 0) {
    years.push(dateStart.format('YYYY'));
    dateStart.subtract(1, 'year');
  }
  return years;
};

const ProfessionalForm = ({verificationStep, setVerificationStep, props}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    formData,
    getValue,
    handleChange,
    handleBlur,
    handleSubmit,
    showError,
    getError,
    disabledFileds,
    setOnChangeEvents,
    setInitialValues,
  } = useForm(initialData, validationSchema);
  const formRequest = useSelector(state => state.RequestCardReducer.form);
  const {origin, occupationForm, salaryForm} = useSelector(
    state => state.RequestCardReducer,
  );
  const setOnChangeEventsForm = useCallback(() => {
    const events = [
      {
        name: 'profession',
        onChange: value => dispatch(editFormRequest({profession: value})),
      },
      {
        name: 'occupation',
        onChange: value =>
          dispatch(editFormRequest({occupation: occupationForm})),
      },
      {
        name: 'company',
        onChange: value => dispatch(editFormRequest({company: value})),
      },
      {
        name: 'companyPhone',
        onChange: value => dispatch(editFormRequest({companyPhone: value})),
      },
      {
        name: 'addressCompany',
        onChange: value => dispatch(editFormRequest({addressCompany: value})),
      },
      {
        name: 'month',
        onChange: value => dispatch(editFormRequest({month: value})),
      },
      {
        name: 'year',
        onChange: value => dispatch(editFormRequest({year: value})),
      },
      {
        name: 'monthlyIncome',
        onChange: value => dispatch(editFormRequest({salary: salaryForm})),
      },
    ];
    setOnChangeEvents(events);
  });
  useEffect(() => {
    updateInitialValues();
  }, []);
  useEffect(() => {
    if (formRequest?.activityType === 'JU') {
      disabledFileds([
        'profession',
        'occupation',
        'company',
        'companyPhone',
        'addressCompany',
        'month',
        'year',
        'monthlyIncome'
      ]);
    }
  }, [formRequest]);

  useEffect(() => {
    setOnChangeEventsForm();
  }, []);

  const {professions, occupations} = useSelector(
    state => state.RequestCardReducer,
  );

  const handleContinueClick = async () => {
    await handleSubmit(handleSubmitForm);
  };

  const handleSubmitForm = () => {
    try {
      setLoading(true);
      const createdBy = SELLER_ID_TYPE;
      const new_date = formRequest.year + '-' + formRequest.month;
      const actualDate = Date();
      const formateActual = moment(actualDate).format('YYYY-MM-DD');
      const admissionDate =
        formRequest.activityType === 'JU'
          ? null
          : moment(new_date, 'YYYY-MM').format('YYYY-MM-DD');
      if (admissionDate > formateActual) {
        setLoading(false);
        NotificationManager.error(
          'Selecciona una fecha de antigüedad laboral menor a la fecha actual',
        );
      } else {
        const employment = [
          {
            professionId: formRequest.profession,
            occupationId: formRequest.occupation,
            countryId: formRequest.country,
            provinceId: formRequest.province,
            districtId: formRequest.district,
            townshipId: formRequest.township,
            address: formRequest.addressCompany,
            typeWorkActivity: formRequest.activityType,
            salary: parseFloat(formRequest.salary),
            companyName: formRequest.company,
            workPhone: formRequest.companyPhone,
            dateAdmission: admissionDate,
            createdBy: createdBy,
          },
        ];
        if (origin === 'PREAP' || origin === 'PRECA') {
          dispatch(createOrUpdate({employment: employment}, onSuccess));
          // this.createOrUpdate({employment: employment})
        } else if (origin === 'CREDI') {
          dispatch(
            UpdateOnboardingCredit(
              {employment: employment},
              onSuccess,
              'UPDATE_PROFESSIONAL_INFO',
            ),
          );
          // this.UpdateOnboardingCredit({employment: employment})
        } else if (origin === ORIGIN.PREPAGO) {
          dispatch(
            UpdateOnboardingCrePPAGO(
              {employment: employment},
              onSuccess,
              'UPDATE_PROFESSIONAL_INFO',
            ),
          );
        }
      }
    } catch (errorInfo) {
      setLoading(false);
    }
  };
  const onSuccess = success => {
    setLoading(false);
    if (success) {
      navigateToNextStep();
    }
  };
  const navigateToNextStep = () => {
    setLoading(false);
    setVerificationStep(verificationStep + 1);
  };

  const handleBackClick = () => {
    setVerificationStep(verificationStep - 1);
  };

  const updateInitialValues = useCallback(() => {
    const initialValues = [
      {
        name: 'profession',
        value: formRequest?.profession,
      },
      {
        name: 'occupation',
        value: formRequest?.occupation
          ? formRequest?.occupation
          : occupationForm,
      },
      {
        name: 'company',
        value: formRequest?.company,
      },
      {
        name: 'companyPhone',
        value: formRequest?.companyPhone,
      },
      {
        name: 'addressCompany',
        value: formRequest?.addressCompany,
      },
      {
        name: 'month',
        value: formRequest?.month,
      },
      {
        name: 'year',
        value: formRequest?.year,
      },
      {
        name: 'monthlyIncome',
        value: formRequest?.salary ? formRequest?.salary : salaryForm,
      },
    ];
    setInitialValues(initialValues);
  });

  return (
    <LayoutForm
      hasNextButton={true}
      hasBackButton={true}
      handleSubmit={handleContinueClick}
      handleBackSubmit={handleBackClick}
      loading={loading}
      props={props}>
      {' '}
      <MyBox
        sx={{
          justifyContent: 'space-between',
          flexDirection: 'column',
          minWidth: '100%',
        }}>
        <MyBox>
          <FormControlData
            fullWidth
            error={showError('profession')}
            disabled={formData.profession.disabled}>
            <MyInputLabel>{formData.profession.label}</MyInputLabel>
            <SelectComponent
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}
              IconComponent={ArrowCommon}
              id="profession"
              label={formData.profession.label}
              value={getValue('profession')}
              onChange={e => handleChange('profession', e.target.value)}
              onBlur={() => handleBlur('profession')}>
              {professions?.map((option, index) => (
                <MyMenuItem key={index} value={option.value}>
                  {option.label}
                </MyMenuItem>
              ))}
            </SelectComponent>
            {showError('profession') && (
              <FormHelperText>{getError('profession')}</FormHelperText>
            )}
          </FormControlData>
          <FormControlData
            fullWidth
            error={showError('occupation')}
            disabled={formData.occupation.disabled}>
            <MyInputLabel>{formData.occupation.label}</MyInputLabel>
            <SelectComponent
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}
              IconComponent={ArrowCommon}
              id="occupation"
              label={formData.occupation.label}
              value={getValue('occupation')}
              disabled={true}
              // onChange={e => handleChange('occupation', e.target.value)}
              onBlur={() => handleBlur('occupation')}
              className="">
              {occupations?.map((option, index) => (
                <MyMenuItem
                  key={index}
                  value={option.value}
                  defaultValue={occupationForm}>
                  {option.label}
                </MyMenuItem>
              ))}
            </SelectComponent>
            {showError('occupation') && (
              <FormHelperText>{getError('occupation')}</FormHelperText>
            )}
          </FormControlData>
        </MyBox>
        <MyBox>
          <MyBox
            flexDirection={'column'}
            sx={{
              gap: '2px',
            }}>
            <FormControlData fullWidth error={showError('company')}>
              <TextFieldComponent
                disabled={formData.company.disabled}
                placeholder={formData.company.label}
                value={getValue('company')}
                error={showError('company')}
                InputProps={{
                  inputProps: {
                    'data-testid': 'company', // Aquí agregamos el data-testid al componente de entrada
                  },
                }}
                onChange={e => handleChange('company', e.target.value)}
                onBlur={() => handleBlur('company')}
              />
              {showError('company') && (
                <FormHelperText>{getError('company')}</FormHelperText>
              )}
            </FormControlData>
            <FormHelperText
              sx={{
                font: 'normal normal normal 14px/22px Red Hat Text',
                color: ' #707070',
              }}>
              *Si no tienes empresa registrada, escribe Independiente
            </FormHelperText>
          </MyBox>
          <MyBox>
            <FormControlData fullWidth error={showError('companyPhone')}>
              <TextFieldComponent
                placeholder={formData.companyPhone.label}
                disabled={formData.companyPhone.disabled}
                error={showError('companyPhone')}
                value={getValue('companyPhone')}
                onChange={e => handleChange('companyPhone', e.target.value)}
                onBlur={() => handleBlur('companyPhone')}
                InputProps={{
                  inputComponent: NumericResidencialInput,

                  inputProps: {
                    'data-testid': 'companyPhone', // Aquí agregamos el data-testid al componente de entrada
                  },
                }}
              />
              {showError('companyPhone') && (
                <FormHelperText>{getError('companyPhone')}</FormHelperText>
              )}
            </FormControlData>
          </MyBox>
        </MyBox>
        <MyBox>
          <FormControlData fullWidth error={showError('addressCompany')}>
            <TextFieldComponent
              InputProps={{
                inputProps: {
                  'data-testid': 'addressCompany', // Aquí agregamos el data-testid al componente de entrada
                },
              }}
              disabled={formData.addressCompany.disabled}
              placeholder={formData.addressCompany.label}
              error={showError('addressCompany')}
              value={getValue('addressCompany')}
              onChange={e => handleChange('addressCompany', e.target.value)}
              onBlur={() => handleBlur('addressCompany')}
            />
            {showError('addressCompany') && (
              <FormHelperText>{getError('addressCompany')}</FormHelperText>
            )}
          </FormControlData>
        </MyBox>
        <MyBox flexDirection={'column'} mt={2} sx={{}}>
          <FormLabel
            sx={{
              font: 'normal normal normal 14px/16px Red Hat Text!important',
              color: '#000000',
            }}>
            Antigüedad laboral{' '}
            <span
              style={{
                font: 'normal normal normal 14px/16px Red Hat Text!important',
                color: '#707070',
              }}>
              (Selecciona la fecha de inicio laboral)
            </span>
          </FormLabel>
          <MyBox>
            <MyBox noColumn>
              <FormControlData
                fullWidth
                error={showError('month')}
                disabled={formData.month.disabled}>
                <MyInputLabel>{formData.month.label}</MyInputLabel>
                <SelectComponent
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                    },
                  }}
                  id="month-select"
                  IconComponent={ArrowCommon}
                  label={formData.month.label}
                  value={getValue('month')}
                  onChange={e => handleChange('month', e.target.value)}
                  onBlur={() => handleBlur('month')}>
                  {selectOptions8?.map((option, index) => (
                    <MyMenuItem key={index} value={option.value}>
                      {option.label}
                    </MyMenuItem>
                  ))}
                </SelectComponent>
                {showError('month') && (
                  <FormHelperText>{getError('month')}</FormHelperText>
                )}
              </FormControlData>
              <FormControlData
                fullWidth
                error={showError('year')}
                disabled={formData.year.disabled}>
                <MyInputLabel>{formData.year.label}</MyInputLabel>
                <SelectComponent
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                    },
                  }}
                  id="year-select"
                  IconComponent={ArrowCommon}
                  label={formData.year.label}
                  value={getValue('year')}
                  onChange={e => handleChange('year', e.target.value)}
                  onBlur={() => handleBlur('year')}>
                  {allYears()?.map((item, index) => (
                    <MyMenuItem key={index} value={item}>
                      {item}
                    </MyMenuItem>
                  ))}
                </SelectComponent>
                {showError('year') && (
                  <FormHelperText>{getError('year')}</FormHelperText>
                )}
              </FormControlData>
            </MyBox>
            <MyBox>
              <FormControlData fullWidth>
                <TextFieldComponent
                  placeholder={formData.monthlyIncome.label}
                  disabled={true}
                  InputProps={{
                    inputComponent: CurrencyInput,
                    inputProps: {
                      'data-testid': 'monthlyIncome', // Aquí agregamos el data-testid al componente de entrada
                    },
                  }}
                  value={salaryForm}
                  onChange={e => handleChange('monthlyIncome', e.target.value)}
                  onBlur={() => handleBlur('monthlyIncome')}
                />
              </FormControlData>
            </MyBox>
          </MyBox>
        </MyBox>
      </MyBox>
    </LayoutForm>
  );
};

export default ProfessionalForm;

import React from 'react'

function CrediCardWhite({color}) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="white" />
      <path
        d="M32 16H16C14.89 16 14.01 16.89 14.01 18L14 30C14 31.11 14.89 32 16 32H32C33.11 32 34 31.11 34 30V18C34 16.89 33.11 16 32 16ZM31 30H17C16.45 30 16 29.55 16 29V24H32V29C32 29.55 31.55 30 31 30ZM32 20H16V18H32V20Z"
        fill="#026E18"
      />
    </svg>
  )
}

export default CrediCardWhite

import produce from 'immer'

const initialState = {
  information: {},
  informations: {},
  LoadingInformation: false,
  informationError: false,
  office: {},
  offices: [],
  LoadingOffices: false,
  officesError: false,
  selectedBranchOfficeId: '',
}

const GeneralInformationReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'INFORMATION_BY_ID_SHOW':
        draft.LoadingInformation = true
        break
      case 'INFORMATION_BY_ID_SUCCESS':
        draft.informations = action.payload
        break
      case 'INFORMATION_BY_ID_ERROR':
        draft.informationError = action.payload
        break
      case 'INFORMATION_BY_ID_HIDE':
        draft.LoadingInformation = false
        break

      case 'CATALOGS_OFFICES_SHOW':
        draft.LoadingOffices = true
        break
      case 'CATALOGS_OFFICES_SUCCESS':
        draft.offices = action.payload
        break
      case 'CATALOGS_OFFICES_ERROR':
        draft.officesError = action.payload
        break
      case 'CATALOGS_OFFICES_HIDE':
        draft.LoadingOffices = false
        break
      case 'SELECT_BRANCH_OFFICE':
        draft.selectedBranchOfficeId = action.payload
        break
      default:
        return state
    }
  })

export default GeneralInformationReducer

import PropTypes from "prop-types"
import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'

const H1 = styled('h1')(({style}) => ({
  ...style,
}))

function TitleResponsiveOnboarding({
  minSize,
  maxSize,
  text,
  color,
  fontFamily,
  textAlign = 'center',
  style = {},
}) {
  const [fontSize, setFontSize] = useState(null)
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth
      let calculatedFontSize = null
  
      if (screenWidth <= 300) {
        calculatedFontSize = minSize
      } else if (screenWidth >= 1920) {
        calculatedFontSize = maxSize
      } else {
        // Linear interpolation between minSize and maxSize
        const ratio = (screenWidth - 300) / (1920 - 300)
        calculatedFontSize = minSize + ratio * (maxSize - minSize)
      }
  
      setFontSize(calculatedFontSize)
    }
  
    handleResize()
  
    window.addEventListener('resize', handleResize)
  
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [minSize, maxSize])
  const fontSizeInEm = fontSize / 19
  return (
    <H1
      style={{
        fontSize: `${fontSizeInEm}em`,
        fontFamily: fontFamily,
        color: color,
        textAlign: textAlign,
        ...style,
      }}>
      {text}
    </H1>
  )
}

TitleResponsiveOnboarding.propTypes = {
  color: PropTypes.any,
  fontFamily: PropTypes.any,
  maxSize: PropTypes.any,
  minSize: PropTypes.any,
  style: PropTypes.object,
  text: PropTypes.any,
  textAlign: PropTypes.string
}

export default TitleResponsiveOnboarding

import styled from '@emotion/styled'
import {
  Button,
  Checkbox,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Spin,
  Tabs,
  Typography,
  message,
} from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SignaturePad from 'react-signature-canvas'
import phoneHeaderLogo from '../../assets/images/onboarding/paper-plane.svg'
import SuccesIcon from '../../assets/images/onboarding/succes-icon-check.svg'
import AsideLogo from '../../components/Aside/AsideLogo'
import ButtonNext from '../../components/Button/ButtonNext'
import SkeletonCMF from '../../components/Loading/skeleton'
import PDFView from '../../components/PDFViewPhone'
import {
  GET_SIGNID_DATA,
  UPDATE_SIGNATURE_REQUEST,
} from '../../constrants/reducers'
import {
  addDocumentCotizador,
  updateOnboadingBeforeActivation,
  updateOnboadingContinueActive,
  updateSignOnboardingPhone,
} from '../../store/actions/phoneAction'
import {
  addDocument,
  getBannerActive,
  logsOnboarding,
  sendSignByVoice,
} from '../../store/actions/requestCard.actions'
import { APP, ORIGIN, PDFTYPES, STATUS_SIGNATURES } from '../../utils/Constants'
import { ConsoleHelper, openNotificationWithIcon } from '../../utils/Functions'
import canReturnMobile from './canReturnMobile'
import { SCREENS } from './screens'
const {Content} = Layout
export const headerEID = {
  headers: {
    Authorization: APP.electronicId.tokenAuth,
    'content-type': 'application/json',
  },
}
const RowMain = styled(Row)(() => ({
  display: ' flex',
  // justifyContent: 'flex-end',
  width: '100%',
  flexDirection: 'row',
  margin: '5vh auto',
  '@media (max-width:600px)': {
    font: ' normal normal normal 1.1rem/21px Gilroy',
    color: ' var(--color-primary-main)',
    display: ' flex',
    justifyContent: 'initial',
    alignItems: 'flex-start',
    margin: '1 rem auto',
    width: '85%',
    marginTop: '0',
    marginBottom: '0',
  },
}))
const FooterMain = styled('div')(() => ({
  display: ' flex',
  justifyContent: ' space-between',
  width: ' 100%',
  marginBottom: ' 5em',
  '@media (max-width:600px)': {
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column-reverse',
    bottom: '0%',
    margin: 'auto',
  },
}))
const ContentMain = styled(Content)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '80%',
  margin: 'auto',
  '@media (min-width:601px) and (max-width:900px)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'scroll',
    width: '80%',
    margin: 'auto',
  },
  '@media (max-width:600px)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'scroll',
    width: '100%',
    margin: 'auto',
  },
}))
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))
const ContainerPdf = styled('div')(() => ({
  width: '100%',
  overflow: 'hidden',
  height: '42vh',
}))
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}))
const ButtonsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  width: '100%',
}))
/**
 * Componente que permite continuar con la finalizacion del onboarding
 * Inicia en la pantalla de enroll
 * @param {*} props
 * @returns
 */
const isEmpty = null;
const loadingModal = false;
const FilePDF = props => {
  const {TabPane} = Tabs
  const [terms, setTerms] = useState(false)
  const [activeKey, setActiveKey] = useState('1')
  const [activeSMS, setActiveSMS] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [code, setCode] = useState(null)
  const [requestId, setRequestId] = useState(null)
  const [loadingSignButton, setLoadingSignButton] = useState(false)
  const [smsValidated, setsmsValidated] = useState(false)
  const [signDocument, setsignDocument] = useState(null)
  const [signQuoter, setsignDocumentQuoter] = useState(null)
  const [openSign, setOpenSign] = useState(false)
  const [addSignLoad, setAddSignLoad] = useState(false)
  const sigPadRef = useRef(null)
  const [sigWrapperWidth, setSigWrapperWidth] = useState(null)
  const [visibleIndication, setVisibleIndication] = useState(false)
  const {
    documentBase64,
    loadToPdf,
    documentId,
    singIdData,
    LoadingactivePro,
    form,
  } = useSelector(state => state.RequestCardReducer)
  const {
    onboardingPhone,
    cotizadorId,
    loadCotizador,
    cotizadorBase64,
    onboardingActive,
    formUpdate,
  } = useSelector(state => state.Phone)

  const {Origin, Id, Phones, CustomerId} = useSelector(
    state => state.Phone.json,
  )
  const {json} = useSelector(state => state.Phone)
  const {onboardingPhoneActive, jsonInput, complete} = useSelector(
    state => state.Phone.onboardingActive,
  )
  const {origin, id} = useSelector(state => state.Phone.onboardingPhone)
  const {selectedBranchOfficeId} = useSelector(
    state => state.GeneralInformationReducer,
  )
  const dispatch = useDispatch()
  if (onboardingPhoneActive) {
    var phoneActive = Phones[0].Phone
  }
  useEffect(() => {
    if (onboardingPhoneActive === true) {
      dispatch(
        addDocument(
          'User',
          Number(Id),
          ORIGIN.CREDIMOVIL,
          PDFTYPES.CREDIMOVIL,
          selectedBranchOfficeId,
        ),
      )
      dispatch(getBannerActive())
      dispatch(
        addDocumentCotizador(
          'Cotizacion',
          Number(Id),
          selectedBranchOfficeId,
          ORIGIN.CREDIMOVIL,
        ),
      )
      getIdentityId()
    } else {
      dispatch(
        addDocument(
          'User',
          Number(id),
          ORIGIN.CREDIMOVIL,
          PDFTYPES.CREDIMOVIL,
          selectedBranchOfficeId,
        ),
      )
      dispatch(getBannerActive())
      dispatch(
        addDocumentCotizador(
          'Cotizacion',
          Number(id),
          selectedBranchOfficeId,
          ORIGIN.CREDIMOVIL,
        ),
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderPDF = () => {
    return <PDFView document={documentBase64} />
  }

  const renderCotizadorPDF = () => {
    return <PDFView document={cotizadorBase64} />
  }
  const handleSignatureEnd = () => {
    SignatureCanvasIsEmpty()
  }
  const SignatureCanvasIsEmpty = () => {
    // Implementa la lógica para verificar si la firma está vacía o no
  }
  const clear = () => {
    sigPadRef.current.clear()
  }
  const onContinue = state => {
    if (state) {
      setAddSignLoad(false)
      setOpenSign(false)
      setVisibleIndication(true)
    } else {
      setAddSignLoad(false)
    }
  }
  const renderSignPaint = () => {
    const {Title} = Typography

    const handleClick = () => {
      setAddSignLoad(true)
      if (!sigPadRef.current.isEmpty()) {
        const trimmedCanvas = sigPadRef.current.getTrimmedCanvas() // Get the trimmed canvas
        const imageDataURL = trimmedCanvas.toDataURL('image/png') // Convert trimmed canvas to PNG data URL
        dispatch(
          updateSignOnboardingPhone(
            `${complete ? Phones[0]?.Phone : form?.cellphone}.sign.jpeg`,
            imageDataURL,
            onboardingPhoneActive ? Id : id,
            ORIGIN.CREDIMOVIL,
            json,
            selectedBranchOfficeId,
            onboardingPhoneActive,
            onContinue.bind(this),
          ),
        )
      } else {
        openNotificationWithIcon('warn', 'Para continuar, requerimos tu firma.')
        setAddSignLoad(false)
      }
    }
    return (
      <Modal
        centered
        open={openSign}
        footer={null}
        maskClosable={false}
        closable={true}
        onCancel={() => setOpenSign(false)}
        className="modal__Sign">
        <Title level={4} className="typoGraphy">
          Añadir firma digital
        </Title>
        <div>
          <div
            ref={sigWrapperRef => {
              if (
                sigWrapperRef &&
                sigWrapperRef.getBoundingClientRect().width - 2 !==
                  sigWrapperWidth
              ) {
                setSigWrapperWidth(
                  sigWrapperRef.getBoundingClientRect().width - 2,
                )
              }
            }}
            className="sigContainer"
            style={{width: '100%'}} // Asegura que el contenedor tome el ancho completo
          >
            <SignaturePad
              penColor="black"
              canvasProps={{
                width: sigWrapperWidth,
                height: 200,
              }}
              ref={sigPadRef}
              onEnd={handleSignatureEnd}
            />
          </div>
          <div className="sign__paint">
            <Button className="buttons_clear" onClick={clear}>
              Limpiar
            </Button>
            <Button
              disabled={isEmpty}
              className="buttons__Ok"
              // loading={signCapture}
              onClick={handleClick}>
              Aceptar
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
  const nextSignEID = () => {
    const handleClick = () => {
      setVisibleIndication(false)
      setModalVisible(true)
      setActiveSMS(true)
      sendSMS()
    }

    return (
      <Modal
        open={visibleIndication}
        footer={null}
        centered
        maskClosable={false}
        closable={true}
        onCancel={() => setVisibleIndication(false)}
        className="ModalIndi">
        <center>
          <img src={SuccesIcon} alt='Success Icon' className="icon_success" />
        </center>
        <div className="typoGraphy_title">
          Tú firma digital se ha agregado al siguiente documento:
        </div>
        <ul className="ul_indication">
          <li>Carta de descuento directo</li>
        </ul>
        <Button
          className="button_next_sign"
          style={{fontSize: '17px'}}
          onClick={handleClick}>
          Continuar con firma electronica
        </Button>
      </Modal>
    )
  }
  const nextStep = () => {
    setOpenSign(true)
  }
  const getIdentityId = async () => {
    try {
      const response = await axios.get(
        `${APP.electronicId.endpoints.getIdentityId}?nif=${jsonInput}`,
        headerEID,
      )
      const {data} = response
      if (data.id) {
        dispatch({
          singIdData: data.id,
          type: GET_SIGNID_DATA,
        })
      }
    } catch (err) {}
  }

  /* send the validation signature message */
  const sendSMS = async () => {
    const payload = {
      documentsId: [documentId, cotizadorId],
      identityId: singIdData,
      message: {
        phone: onboardingPhoneActive
          ? '+507' + phoneActive
          : '+507' + form?.cellphone,
        text:
          'Firme utilizando el siguiente codigo: *|challengeCode|* ,Valido por *|ttl|*.',
        from: APP.companyName,
      },
      challengeCode: {
        charset: 'alphanumeric',
        length: 4,
      },
      ttl: 240,
      ttlUnit: 's',
    }
    try {
      const res = await axios.post(
        APP.electronicId.endpoints.signatureSms,
        payload,
        headerEID,
      )
      if (res.data.status === STATUS_SIGNATURES.AwaitingConfirmation) {
        setRequestId(res.data.requestId)
        dispatch({
          type: UPDATE_SIGNATURE_REQUEST,
          payload: res.data.requestId,
        })
      }
      const response = await axios.get(
        `${APP.electronicId.endpoints.getIdentityId}?nif=${onboardingActive?.jsonInput}`,
        headerEID,
      )
      const {data} = response
      if (data.id) {
        dispatch({
          singIdData: data.id,
          type: GET_SIGNID_DATA,
        })
      }
    } catch (e) {
      openNotificationWithIcon('error', e.response.data.message)
    }
  }

  const validateSMS = async () => {
    const payload = {
      requestId,
      challengeCode: code,
      stamps: [
        {
          documentId: documentId,
          location: {
            page: 5,
            left: 80,
            top: 130,
            width: 220,
            height: 60,
          },
          placeholder: {
            text: '__________-',
            width: 220,
            height: 60,
          },
        },
        {
          documentId: cotizadorId,
          location: {
            page: 1,
            left: 80,
            top: 659,
            width: 220,
            height: 60,
          },
        },
      ],
    }
    try {
      setLoadingSignButton(true)
      const res = await axios.post(
        `${APP.electronicId.endpoints.signSms}`,
        payload,
        headerEID,
      )
      logsOnboarding(
        id ? id : Id,
        origin ? origin : Origin,
        'SIGID_CODE_SMS_PHONE',
        res.data,
        payload,
        true,
      )
      if (res.data.id) {
        setsmsValidated(true)
        setsignDocument(res.data.documents[0].signedDocument.documentId)
        setsignDocumentQuoter(res.data.documents[1].signedDocument.documentId)
        setModalVisible(false)
      }
    } catch (err) {
      const error = err?.response?.data?.message
      logsOnboarding(
        id ? id : Id,
        origin ? origin : Origin,
        'SIGID_CODE_SMS_PHONE',
        err?.response?.data,
        payload,
        false,
      )
      setLoadingSignButton(false)
      message.error(
        error
          ? `Sistema biométrico, ${error}`
          : 'El código ingresado es inválido',
      )
    }
  }
  const validateCall = async () => {
    const payload = {
      requestId,
      challengeCode: code,
      stamps: [
        {
          documentId: documentId,
          location: {
            page: 5,
            left: 80,
            top: 130,
            width: 220,
            height: 60,
          },
          placeholder: {
            text: '__________-',
            width: 220,
            height: 60,
          },
        },
        {
          documentId: cotizadorId,
          location: {
            page: 1,
            left: 80,
            top: 659,
            width: 220,
            height: 60,
          },
        },
      ],
    }
    try {
      setLoadingSignButton(true)
      const res = await axios.post(
        `${APP.electronicId.endpoints.signByCall}`,
        payload,
        headerEID,
      )
      logsOnboarding(
        id ? id : Id,
        origin ? origin : Origin,
        'SIGID_CODE_CALL_PHONE',
        res.data,
        payload,
        true,
      )

      if (res.data.id) {
        setsmsValidated(true)
        setsignDocument(res.data.documents[0].signedDocument.documentId)
        setsignDocumentQuoter(res.data.documents[1].signedDocument.documentId)
        setModalVisible(false)
      }
    } catch (err) {
      const error = err?.response?.data?.message
      logsOnboarding(
        id ? id : Id,
        origin ? origin : Origin,
        'SIGID_CODE_CALL_PHONE',
        err?.response?.data,
        payload,
        false,
      )
      setLoadingSignButton(false)
      message.error(
        error
          ? `Sistema biométrico, ${error}`
          : 'El código ingresado es inválido',
      )
    }
  }
  /**
   * @description: Funcion que se encarga de enviar Signature by Voice
   */
  const sendSignByVoiceCall = async () => {
    const payload = {
      documentsId: [documentId, cotizadorId],
      identityId: singIdData,
      message: {
        // phone: '+507' + form.cellphone,
        phone: onboardingPhoneActive
          ? '+507' + phoneActive
          : '+507' + form?.cellphone,
        text:
          'Hola, tu código para firmar el contrato CMF es: *|challengeCode|*. Repito*|challengeCode|*. Repito*|challengeCode|*.',
        from: '+50765978134',
        language: 'es',
      },
      challengeCode: {
        charset: 'alphanumeric',
        length: 4,
      },
      ttl: 2,
      ttlUnit: 'm',
    }
    try {
      dispatch(sendSignByVoice(payload))
    } catch (e) {
      ConsoleHelper('error', e)
    }
  }
  const renderModalSign = () => {
    return (
      <>
        <Modal
          centered
          open={true}
          maskClosable={false}
          closeIcon={false}
          closable={false}
          destroyOnClose={false}
          wrapClassName="container-validation"
          footer={false}
          onCancel={() => setModalVisible(false)}
          width={400}>
          {activeSMS ? (
            <div className="validation-container">
              <center>
                <img src={phoneHeaderLogo} alt='Phone header logo' />
              </center>
              <h1 style={{marginBottom: '10px'}}>
                Hemos enviado un mensaje de texto con un código a tu celular
                para validar la firma electrónica de tu contrato.
              </h1>
              <Form name="basic" autoComplete="off">
                <Form.Item
                  name="code"
                  rules={[
                    {
                      required: false,
                      message: 'Please input your username!',
                    },
                  ]}>
                  <Input
                    className="InputFirmaCode"
                    type="text"
                    id={'code'}
                    autoFocus={true}
                    placeholder="Ingresa el código de verificación"
                    onChange={e => {
                      setCode(e.target.value.toLocaleLowerCase())
                      e.target.value = e.target.value.toLowerCase()
                    }}
                  />
                </Form.Item>
              </Form>
              <ButtonsContainer>
                <Button
                  style={{
                    marginTop: '10%',
                    background: 'white',
                    color: '#13a438',
                    border: '1px solid #13a438',
                    borderRadius: '5px',
                  }}
                  success
                  disabled={loadingSignButton}
                  className="Button__Email"
                  size="large"
                  onClick={() => setModalVisible(false)}>
                  Cancelar
                </Button>
                <Button
                  style={{
                    marginTop: '10%',
                    background: '#13a438',
                    color: 'white',
                    border: '1px solid white',
                    borderRadius: '5px',
                  }}
                  success
                  loading={loadingSignButton}
                  disabled={false}
                  className="Button__Email"
                  size="large"
                  onClick={validateSMS}>
                  Aceptar
                </Button>
              </ButtonsContainer>
              <br></br>
              <br></br>
              <a onClick={sendSignByVoiceCall}>
                {' '}
                <u> No has recibido tu sms, prueba con una llamada</u>
              </a>
            </div>
          ) : (
            <div className="validation-container">
              <center>
                <img src={phoneHeaderLogo} alt='Phone header logo' />
              </center>
              <h1 style={{marginBottom: '10px'}}>
                Recibiras una llamada con un código a tu celular para validar la
                firma electrónica de tu contrato.
              </h1>
              <Form.Item
                name="code"
                rules={[
                  {
                    required: false,
                    message: 'Please input your username!',
                  },
                ]}>
                <Input
                  type="text"
                  id={'code'}
                  autoFocus={true}
                  onChange={e => {
                    setCode(e.target.value.toLocaleLowerCase())
                    e.target.value = e.target.value.toLowerCase()
                  }}
                  placeholder={'Ingresa el código de verificación'}
                />
              </Form.Item>
              <ButtonsContainer
                style={{
                  marginBottom: 10,
                }}>
                <Button
                  style={{
                    marginTop: '10%',
                    background: 'white',
                    color: '#13a438',
                    border: '1px solid #13a438',
                    borderRadius: '5px',
                  }}
                  success
                  disabled={loadingSignButton}
                  className="Button__Email"
                  size="large"
                  onClick={() => setModalVisible(false)}>
                  Cancelar
                </Button>
                <Button
                  style={{
                    marginTop: '10%',
                    background: '#13a438',
                    color: 'white',
                    border: '1px solid white',
                    borderRadius: '5px',
                  }}
                  success
                  loading={loadingSignButton}
                  disabled={false}
                  className="Button__Email"
                  size="large"
                  onClick={validateCall}>
                  Aceptar
                </Button>
              </ButtonsContainer>
            </div>
          )}
        </Modal>
      </>
    )
  }
  const onSuccess = success => {
    if (success === true) {
      props.goToStep(SCREENS.Congrats)
    }
  }
  const activateProduct = async () => {
    const payload = {
      isSignatureConfirm: true,
      electronicDocumentId: documentId,
      electronicSignId: signDocument,
      SignQuotationId: signQuoter,
      DocumentQuotationId: cotizadorId,
    }
    if (onboardingPhoneActive === true) {
      await dispatch(
        updateOnboadingContinueActive(
          Id,
          Origin,
          payload,
          formUpdate,
          CustomerId,
          onSuccess.bind(this),
        ),
      )
    } else {
      await dispatch(
        updateOnboadingBeforeActivation(
          onboardingPhone.id,
          onboardingPhone.origin,
          payload,
          selectedBranchOfficeId,
          onSuccess.bind(this),
        ),
      )
    }
  }

  const onChange = activeKey => {
    // const {onboardingId, origin} = this.props
    // this.setState({activeKey})
    setActiveKey(activeKey)
    // switch (activeKey) {
    //   case '1':
    //     this.props.loadPDF(onboardingId, origin, 'CTR')
    //     break
    //   case '2':
    //     this.props.loadPDFTerms(onboardingId, origin, 'TYC')
    //     break
    //   default:
    // }
  }

  return (
    <Container>
      <Wrapper style={{flex: 0.2, background: 'rgb(41, 164, 56)'}}>
        <AsideLogo
          positionX="left"
          positionY="top"
          color="white"
          hugColor="#FFF"
        />
      </Wrapper>
      {addSignLoad && (
        <Modal centered open={addSignLoad} closeIcon={true} footer={false}>
          <div className="validation-container">
            <Spin size="large" style={{display: 'block', marginTop: '7%'}} />
            <h3>Estamos guardando tu firma.</h3>
            <h3>Aguarda un momento.</h3>
            <br></br>
          </div>
        </Modal>
      )}
      <Wrapper
        style={{
          flex: 1,
          height: '100%',
          justifyContent: 'center',
        }}>
        <h1 className="Pdf_className">
          Revisa y firma tu contrato de credimóvil CMF
        </h1>
        <ContentMain>
          {LoadingactivePro === true ? (
            <Modal
              centered
              open={LoadingactivePro}
              closeIcon={true}
              footer={false}>
              <div className="validation-container">
                <Spin
                  size="large"
                  style={{display: 'block', marginTop: '7%'}}
                />
                <h3>Estamos validando tu firma electrónica.</h3>
                <h3>Aguarda un momento.</h3>
                <br></br>
              </div>
            </Modal>
          ) : (
            <div></div>
          )}
          {loadToPdf && loadCotizador ? (
            <div className="terms-and-pdf-phone">
              <SkeletonCMF />
              <Modal centered open={true} closeIcon={true} footer={false}>
                <div className="validation-container">
                  <Spin
                    size="large"
                    style={{display: 'block', marginTop: '7%'}}
                  />
                  <h3>Estamos procesando tu contrato.</h3>
                  <h3>Aguarda un momento.</h3>
                  <br></br>
                </div>
              </Modal>
            </div>
          ) : (
            <Tabs
              style={{width: '100%', height: '60%'}}
              onChange={onChange}
              defaultActiveKey="1"
              activeKey={activeKey}>
              <TabPane tab="Contrato " key="1">
                <ContainerPdf>{documentBase64 && renderPDF()}</ContainerPdf>
              </TabPane>
              <TabPane tab="Cotización" key="2">
                <ContainerPdf>
                  {cotizadorBase64 && renderCotizadorPDF()}
                </ContainerPdf>
              </TabPane>
            </Tabs>
          )}
          {!loadToPdf ? (
            <>
              <RowMain gutter={[16, 8]}>
                <Checkbox
                  data-testid="terms-check-id"
                  onChange={e => setTerms(e.target.checked)}>
                  Acepto los Términos y Condiciones
                </Checkbox>
              </RowMain>

              <FooterMain>
                {onboardingPhoneActive
                  ? props.canReturn
                    ? canReturnMobile(props, 8)
                    : null
                  : props.canReturn
                  ? canReturnMobile(props, 7)
                  : null}

                {!modalVisible &&
                  documentBase64 &&
                  cotizadorBase64 &&
                  !smsValidated && (
                    <ButtonNext
                      background="green"
                      border="green"
                      loading={loadingModal}
                      disabled={!terms}
                      size="large"
                      data-testid="btn-sign-contrat"
                      onClick={nextStep.bind(this)}>
                      Firmar contrato
                    </ButtonNext>
                  )}
                {smsValidated && signDocument && (
                  <ButtonNext
                    background="green"
                    border="green"
                    loading={LoadingactivePro}
                    disabled={LoadingactivePro}
                    size="large"
                    data-testid="btn-finish"
                    onClick={activateProduct}>
                    Enviar
                  </ButtonNext>
                )}
              </FooterMain>
            </>
          ) : (
            ''
          )}
        </ContentMain>
        {modalVisible && renderModalSign()}
        {openSign && renderSignPaint()}
        {visibleIndication && nextSignEID()}
      </Wrapper>
    </Container>
  )
}
export default FilePDF

import {styled} from '@material-ui/core/styles';
import {Box, CircularProgress, Skeleton, Typography} from '@mui/material';
import axios from 'axios';
import {ContentImage} from 'src/containers/cmf_linea/pagar_cmf/CardContainer';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Cmf_Base, PointsApi} from 'src/Data/Api';
import {setProductData} from 'src/store/actions/actions';
import {BreakPointTheme} from 'src/theme/breakpoints';
import {skeletonSx} from 'src/theme/constants';
import {PRODUCTS_TYPES} from 'src/utils/Constants';
import logoCMFWhite from 'src/assets/images/cmf_linea/logoCMF_White.svg';
import {
  CantidadTotalEnPorcentaje,
  formatoMoneda,
  ocultarNumero,
} from 'src/utils/Functions';

const Cicular = styled(CircularProgress)(({theme}) => ({
  color: theme.palette.common.white,
  width: '28px !important',
  position: 'absolute',
  '& .MuiCircularProgress': {
    backgroundSize: '100% 100%',
    border: '4px solid',
    borderColor: '#d0d5dd29',
    borderRadius: '14.25px',
    height: '28px',
    left: '1px',
    position: 'relative',
    top: '1px',
    width: '28px',
  },
}));

function PrepaipFullSize({item, isSelected, onClick, click}) {
  const {CurrentUser} = useSelector(state => state.AuthenticationReducer);
  const [dataAccount, setBalance] = useState(null);
  const [loading, setLoading] = useState(false);
  const imageCardBackground = item?.customerAccountCards[0]?.cardImage;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  useEffect(() => {
    // Función para obtener el saldo del servicio web
    const fetchBalance = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${Cmf_Base}${PointsApi}?anyo=0&Mes=0&Cuenta=${encodeURIComponent(
            item?.productAccount,
          )}&CreatedBy=${CurrentUser?.accountSF[0]?.Name}`,
          {
            headers: {
              Authorization: `Bearer ${CurrentUser?.tokenCMF}`,
            },
          },
        );
        const setDataAccount = JSON.parse(response?.data.jsonAnswer);
        setBalance(setDataAccount);
        dispatch(setProductData(item.customerProductId, setDataAccount));
      } catch (error) {
        console.error('Error fetching balance:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBalance();
  }, [item.customerProductId]);

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={'100%'}
          height={116}
          sx={skeletonSx}
        />
      ) : (
        <Box
          onClick={() => {
            if (click) {
              onClick(item);
            }
          }}
          sx={{
            backgroundImage: `url(${imageCardBackground})`,
            backgroundSize: 'cover',
            backgroundPosition: 'bottom',
            borderRadius: '20px',
            height: '116px',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            filter: `brightness(${isSelected ? '1.0' : '0.5'})`,
            transform: `scale(${isSelected ? '1.0' : '0.95'})`,
            [BreakPointTheme.breakpoints.down('sm')]: {
              width: `${windowWidth - 42}px`,
              height: '116px',
            },
          }}>
          <Box
            sx={{
              alignItems: 'flex-start',
              display: 'inline-flex',
              gap: item?.isCorporate ? '0px' : '8px',
              padding: item?.isCorporate ? '22px 1vw' : '16px',
              position: 'absolute',
              top: 0,
              [BreakPointTheme.breakpoints.up('sm')]: {
                left: '230px',
              },
              [BreakPointTheme.breakpoints.down('sm')]: {
                right: 0,
                padding: item?.isCorporate ? '30px 1rem' : '16px',
              },
            }}>
            <div className="div">
              <Typography
                variant="moneyCard"
                sx={{
                  fontSize: item?.isCorporate ? '18px' : '20px',
                  color: '#FFF',
                }}>
                {formatoMoneda(dataAccount?.DisponibleML ?? 0)}
              </Typography>
              <Typography variant="H4" sx={{color: '#FFFFFF8A'}}>
                Disponible
              </Typography>
            </div>
          </Box>
          <Box
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              justifyContent: 'space-between',
              left: '-1px',
              padding: '16px',
              position: 'absolute',
              bottom: '5px',
              width: '100%',
              height: '100%',
              flexDirection: 'column',
              [BreakPointTheme.breakpoints.down('sm')]: {
                width: '100%',
              },
            }}>
            <ContentImage>
              <img src={logoCMFWhite} alt="Logo CMF White"></img>
            </ContentImage>
            <Typography color="#FFF" variant="textCard" sx={{fontSize: '18px'}}>
              {' '}
              Tarjeta Prepago |
              {ocultarNumero(item?.customerAccountCards[0]?.lastFourDigitCard)}
            </Typography>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}

PrepaipFullSize.propTypes = {
  click: PropTypes.any,
  isSelected: PropTypes.any,
  item: PropTypes.shape({
    customerAccountCards: PropTypes.any,
    customerProductId: PropTypes.any,
    isCorporate: PropTypes.any,
    productAccount: PropTypes.any,
    productTypeId: PropTypes.shape({
      toUpperCase: PropTypes.func,
    }),
  }),
  onClick: PropTypes.func,
};

export default PrepaipFullSize;

import { styled } from '@mui/material'
import React from 'react'
import WrapperSide from 'src/components/Aside/Wrapper'
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}))
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'scroll',
}))

const LayoutPageForm = ({children}) => {
  return (
    <Container>
      <WrapperSide background="linear-gradient(#003a11, #016116, #4fa70a)" />
      <Wrapper style={{flex: 1, height: '100%'}}>
        {children}
      </Wrapper>
    </Container>
  )
}

export default LayoutPageForm
